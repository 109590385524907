import {
  FormControl,
  FormLabel,
  forwardRef,
  HStack,
  Input,
  Link,
  Text,
  TextProps,
} from '@chakra-ui/react';
import { Nullable } from '@main/shared/types';
import { FilterMode } from '@main/shared/url-helpers';
import { CellContext, ColumnDef, Row, SortDirection } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { AutoResizeTextarea } from '../../autoresize-textarea';
import { EditablePopover } from '../../editable';
import { OverflowContainer } from '../../overflow-tooltip';
import { createFilterHelper } from '../filters';
import { FilterModeSelector } from '../filters/filter-mode';
import { CellContainer } from '../shared/cell';

export type TextVariant = 'standard' | 'link';

type EditProps<TData> = {
  onChange: (row: Row<TData>, value: string) => void;
  canEditGuard?: (row: Row<TData>) => boolean;
  defaultIsEditing?: (row: Row<TData>) => boolean;
};
type TextColumnConfig<TData, TFilter, TSort> = {
  header: string;
  edit?: EditProps<TData>;
  variant?: TextVariant;
  isMultiline?: boolean;
  getGlobalFilterCondition?(filterValue: string): Nullable<TFilter>;
  getColumnFilterCondition?(filterMode: FilterMode, filterValue: string): Nullable<TFilter>;
  getColumnSort?(sortDirection: SortDirection): TSort;
};

const urlSchema = z.string().url();

const filterHelper = createFilterHelper({
  filterSchema: z.string(),
  filterFn,
});

function filterFn(
  value: string | undefined,
  filterValue: string | null,
  filterMode?: FilterMode,
): boolean {
  if (!filterValue) {
    return true;
  }

  const isContainingValue = !!value?.toLowerCase().includes(filterValue.toLowerCase());

  if (filterMode === FilterMode.Excludes) {
    return !isContainingValue;
  }

  return isContainingValue;
}

export function text<TData, TFilter, TSort>() {
  return ({
    edit,
    variant = 'standard',
    isMultiline = false,
    ...columnDef
  }: ColumnDef<TData, string | undefined> & TextColumnConfig<TData, TFilter, TSort>): ColumnDef<
    TData,
    string | undefined
  > => {
    return {
      enableGlobalFilter: false,
      enableColumnFilter: false,
      enableSorting: false,
      sortDescFirst: false,

      ...columnDef,

      cell: (context) => {
        const value = context.getValue();
        const cellRender =
          columnDef.cell && typeof columnDef.cell === 'function' ? columnDef.cell : undefined;

        if (edit && (!edit.canEditGuard || edit.canEditGuard(context.row))) {
          return (
            <EditableCell
              edit={edit}
              context={context}
              variant={variant}
              isMultiline={isMultiline}
              render={cellRender}
            />
          );
        }

        return (
          <CellContainer cell={context.cell} p={0}>
            <OverflowContainer>
              <OverflowContainer.Tooltip
                label={value}
                hasArrow
                placement="top"
                fontSize="sm"
                openDelay={500}
              >
                <RenderText
                  value={value}
                  context={context}
                  variant={variant}
                  isMultiline={isMultiline}
                  render={cellRender}
                />
              </OverflowContainer.Tooltip>
            </OverflowContainer>
          </CellContainer>
        );
      },

      filterFn: filterHelper.filterFn,

      meta: {
        ...columnDef.meta,
        name: columnDef.header,
        globalFilterFn: filterFn,
        // Tanstack table filters are untyped. Type safety is enforced via filter/column helpers
        getGlobalFilterCondition: columnDef.getGlobalFilterCondition as never,
        getColumnFilterCondition: columnDef.getColumnFilterCondition as never,
        getColumnSort: columnDef.getColumnSort as never,
        isMultiline,

        filter: {
          filterSchema: filterHelper.filterSchema,

          DisplayFilter: ({ column }) => {
            const { t } = useTranslation('ui');

            return (
              <span>
                {filterHelper.getFilterMode(column) === FilterMode.Excludes &&
                  t('table.filter.notLabel')}
                {filterHelper.getFilterValue(column)}
              </span>
            );
          },

          EditFilter: ({ column }) => {
            const { t } = useTranslation('ui');

            const isExcluding = filterHelper.getFilterMode(column) === FilterMode.Excludes;
            const value = filterHelper.getFilterValue(column);

            return (
              <FormControl p={3}>
                <FormLabel fontWeight="medium">
                  <HStack spacing={1}>
                    <Text color="gray.600" _dark={{ color: 'whiteAlpha.800' }}>
                      {columnDef.header}
                    </Text>
                    <FilterModeSelector {...filterHelper} column={column} />
                  </HStack>
                </FormLabel>
                <Input
                  autoFocus
                  size="sm"
                  placeholder={t('table.column.text.filterPlacehoder')}
                  borderRadius="base"
                  value={value || ''}
                  aria-label={`${columnDef.header} ${
                    isExcluding ? t('table.filter.excludes') : t('table.filter.includes')
                  }`}
                  onChange={(event) =>
                    filterHelper.setFilterValue(column, event.target.value || null)
                  }
                />
              </FormControl>
            );
          },
        },
      },
    };
  };
}

interface RenderTextProps extends TextProps {
  value: string | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: CellContext<any, string | undefined>;
  variant: TextVariant;
  isMultiline: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (context: CellContext<any, string | undefined>) => JSX.Element;
}

const RenderText = forwardRef<RenderTextProps, 'p'>(
  ({ value, context, variant, isMultiline, render, ...props }, ref) =>
    render ? (
      render(context)
    ) : (
      <Text
        isTruncated={!isMultiline}
        p={4}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        style={isMultiline ? ({ textWrap: 'wrap' } as any) : {}}
        ref={ref}
        {...props}
      >
        {variant === 'link' && urlSchema.safeParse(value).success ? (
          <Link color="purple.400" href={value} isExternal>
            {value}
          </Link>
        ) : (
          value
        )}
      </Text>
    ),
);

type EditableCellProps<TData> = {
  edit: EditProps<TData>;
  context: CellContext<TData, string | undefined>;
  variant: TextVariant;
  isMultiline: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  render?: (context: CellContext<any, string | undefined>) => JSX.Element;
};

function EditableCell<TData>({
  edit: { onChange, defaultIsEditing },
  context,
  variant,
  isMultiline,
  render,
}: EditableCellProps<TData>) {
  const cellValue = context.getValue();
  const [value, setValue] = useState(cellValue || '');
  useEffect(() => setValue(cellValue || ''), [cellValue]);

  const onCellUpdate = () => {
    if (value !== cellValue) {
      onChange(context.row, value);
    }
  };

  return (
    <EditablePopover
      onClose={onCellUpdate}
      defaultIsEditing={defaultIsEditing && defaultIsEditing(context.row)}
    >
      <EditablePopover.Reference>
        <CellContainer cell={context.cell} p={0} data-group>
          <OverflowContainer justify="space-between">
            <OverflowContainer.Tooltip
              label={cellValue}
              placement="bottom-start"
              fontSize="sm"
              openDelay={500}
            >
              <RenderText
                value={cellValue}
                context={context}
                variant={variant}
                isMultiline={isMultiline}
                render={render}
              />
            </OverflowContainer.Tooltip>
            <EditablePopover.Trigger>
              <OverflowContainer.EditTrigger />
            </EditablePopover.Trigger>
          </OverflowContainer>
        </CellContainer>
      </EditablePopover.Reference>
      <EditablePopover.Content bgColor="gray.25" _dark={{ bgColor: 'gray.700' }}>
        {
          // Textarea autoresize happens during layout, before the floating popover
          // element is repositioned. This sometimes results in textarea being taller
          // than the contents.
          // Using render props, to force rerender when the EditablePopover context changes
          () => (
            <AutoResizeTextarea
              autoFocus
              size="sm"
              variant="unstyled"
              p={4}
              minH="50px"
              outline="2px solid"
              outlineColor="chakra-border-color"
              defaultValue={value}
              onChange={(event) => setValue(event.target.value)}
              blurOnEnter
              /* This stops triggering click event on cell itself when clicking on the textarea */
              onClick={(event) => event.stopPropagation()}
            />
          )
        }
      </EditablePopover.Content>
    </EditablePopover>
  );
}
