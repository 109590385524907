import {
  Button,
  Center,
  Flex,
  forwardRef,
  Icon,
  Tab as ChakraTab,
  TabList,
  TabListProps,
  TabPanel,
  TabPanels as ChakraTabPanels,
  TabProps,
  Tabs as ChakraTabs,
  Tag,
  TagProps,
  Text,
  Tooltip,
  useColorModeValue,
  useTab,
} from '@chakra-ui/react';
import { renderWithState, severityColorScheme, useUnmountOnce } from '@main/shared/utils';
import { useSearch } from '@tanstack/react-router';
import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';

import { hideScrollbar } from '../../utils';
import { TabsSkeleton } from '../skeleton';
import { getSubstring } from './helper';
import { ActiveTabNamespaceSuffix, DefaultTabNameSpace, TabId } from './types';
import { TabBade, TabItem, useTabs, UseTabsProps } from './use-tabs';

export interface TabsProps<TId extends TabId = TabId> extends Omit<UseTabsProps<TId>, 'tabs'> {
  tabs: TabItem<TId>[];
  isLazy?: boolean;
  children?: ReactNode;
}

interface ContextProps {
  tabs: TabItem<TabId>[];
}

const Context = createContext<ContextProps | null>(null);

function useTabsContext() {
  const context = useContext(Context);

  if (!context) {
    throw new Error('Panels and List components should be wrapped in <Tabs />');
  }

  return { context };
}

export function Tabs<TId extends TabId = TabId>(props: TabsProps<TId>) {
  const tabNameSpace = props.namespace
    ? `${props.namespace}${ActiveTabNamespaceSuffix}`
    : DefaultTabNameSpace;

  const searchParam = useSearch({
    strict: false,
    select: (search: Record<string, TId>) => search[tabNameSpace],
  });
  const tabs = useMemo(() => props.tabs.filter((tab) => !tab.isHidden), [props.tabs]);
  const tabsService = useTabs({ ...props, tabs });
  const [tabIndex, setTabIndex] = useState(-1);

  useEffect(() => setTabIndex(tabsService.getActiveIndex()), [tabsService, searchParam]);

  useUnmountOnce(() => tabNameSpace !== DefaultTabNameSpace && tabsService.deactivate());

  return (
    <Context.Provider value={{ tabs: tabs as unknown as TabItem<TabId>[] }}>
      <ChakraTabs
        isLazy={props.isLazy ?? true}
        index={tabIndex}
        onChange={(index) => tabsService.activateTabIndex(index)}
        display="flex"
        flexDirection="column"
      >
        {props.children ? (
          props.children
        ) : (
          <>
            <StandardTabList />
            <TabPanels />
          </>
        )}
      </ChakraTabs>
    </Context.Provider>
  );
}

type TabPanelsProps = {
  renderPanel?: (tab: TabItem<TabId>, content: ReactNode) => ReactNode;
  showPanelTitle?: boolean;
};
const TabPanels = (props: TabPanelsProps) => {
  const { context } = useTabsContext();

  return (
    <ChakraTabPanels display="flex" flexGrow="1" flexDir="column">
      {context.tabs.map((tab) => {
        if (props.renderPanel) {
          return props.renderPanel(tab, renderWithState(tab.panel, tab));
        }

        return (
          <TabPanel key={tab.id} px={0} flexGrow="1">
            {props.showPanelTitle && tab.id !== 'details' ? (
              <Text fontSize="md" fontWeight="semibold">
                {tab.label}
              </Text>
            ) : null}
            {renderWithState(tab.panel, tab)}
          </TabPanel>
        );
      })}
    </ChakraTabPanels>
  );
};

const StandardTabList = (props: { renderTab?: (tab: TabItem<TabId>) => ReactNode }) => {
  const { context } = useTabsContext();

  const renderTab =
    props.renderTab ??
    ((tab) => (
      <PlainTab key={tab.id}>
        <Flex gap={2} alignItems="center" w="full">
          <Icon as={tab.icon} boxSize={5} />
          <Text>{renderWithState(tab.label, tab)}</Text>
          <TabBadge badge={tab.badge} w="18px" h="18px" rounded="md" fontSize="10px" />
        </Flex>
      </PlainTab>
    ));

  return <PlainTabList>{context.tabs.map(renderTab)}</PlainTabList>;
};

export function PlainTabList({ children, ...props }: TabListProps) {
  const tabBorderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <TabList
      flexShrink={0}
      borderBottom="1px"
      borderColor={tabBorderColor}
      color="gray.400"
      maxH="44px"
      overflowY="hidden"
      {...props}
    >
      {children}
    </TabList>
  );
}

export function PlainTab({ children, ...props }: TabProps) {
  const tabColor = useColorModeValue('black', 'white');
  const tabIndicatorColor = useColorModeValue('blue.500', 'blue.200');

  return (
    <ChakraTab
      borderBottomWidth="4px"
      whiteSpace="nowrap"
      _selected={{ color: tabColor, borderColor: tabIndicatorColor }}
      fontSize="sm"
      py={1}
      {...props}
    >
      {children}
    </ChakraTab>
  );
}

const SidebarTabList = (props: { renderTab?: (tab: TabItem<TabId>) => ReactNode }) => {
  const { context } = useTabsContext();
  const textDefaultColor = useColorModeValue('gray.400', 'gray.400');
  const textActiveColor = useColorModeValue('blue.500', 'purple.300');
  const iconDefaultColor = useColorModeValue('gray.500', 'gray.400');
  const iconActiveColor = useColorModeValue('blue.400', 'purple.200');
  const bgActiveColor = useColorModeValue('blue.50', 'rgba(214, 188, 250, 0.16)');
  const bgHoverColor = useColorModeValue('blue.25', 'rgba(214, 188, 250, 0.10)');

  const renderTab =
    props.renderTab ??
    ((tab) => (
      <SidebarTab
        key={tab.id}
        render={(isSelected) => {
          return (
            <Flex direction="column" alignItems="center" gap={1}>
              <Tooltip label={renderWithState(tab.label, tab)} placement="left" openDelay={500}>
                <Center
                  role="group"
                  _hover={{
                    bg: isSelected ? bgActiveColor : bgHoverColor,
                  }}
                  w="35px"
                  h="35px"
                  bg={isSelected ? bgActiveColor : 'transparent'}
                  rounded={6}
                  position="relative"
                >
                  <Icon
                    as={tab.icon}
                    boxSize="18px"
                    color={isSelected ? iconActiveColor : iconDefaultColor}
                    _groupHover={{
                      color: iconActiveColor,
                    }}
                  />
                  <TabBadge badge={tab.badge} position="absolute" top="-2px" right={-1} />
                </Center>
              </Tooltip>
              <Text
                color={isSelected ? textActiveColor : textDefaultColor}
                fontSize="9px"
                lineHeight="10px"
                fontWeight={isSelected ? 'semibold' : 'normal'}
              >
                {getSubstring(tab.label)}
              </Text>
            </Flex>
          );
        }}
      />
    ));

  return (
    <TabList
      role="navigation"
      border={0}
      borderLeft="1px"
      borderColor={useColorModeValue('gray.200', 'gray.600')}
      h="calc(100vh - 48px)"
      minW="60px"
      maxW="60px"
      paddingTop={4}
      position="sticky"
      top="48px"
      flexDirection="column"
      alignItems="center"
      gap={3}
      overflowY="scroll"
      overflowX="hidden"
      css={hideScrollbar}
    >
      {context.tabs.length > 0 ? context.tabs.map(renderTab) : <TabsSkeleton />}
    </TabList>
  );
};

type SidebarTabProps = TabProps & { render: (isSelected: boolean) => ReactNode };
const SidebarTab = forwardRef<SidebarTabProps, 'button'>(({ render, ...props }, ref) => {
  const tabProps = useTab({ ...props, ref });
  const isSelected = !!tabProps['aria-selected'];

  return (
    <Button variant="unstyled" h="fit-content" rounded="sm" {...tabProps}>
      {render(isSelected)}
    </Button>
  );
});

type TabBadgeProps = { badge?: TabBade } & TagProps;
const TabBadge = ({ badge, ...props }: TabBadgeProps) => {
  if (!badge || !badge.count || badge.severity === undefined) {
    return null;
  }

  return (
    <Tag
      colorScheme={severityColorScheme(badge.severity)}
      rounded="base"
      minW="18px"
      minH="18px"
      p={0}
      as={Flex}
      justifyContent="center"
      fontSize="8px"
      {...props}
    >
      {badge.count}
    </Tag>
  );
};

Tabs.Panels = TabPanels;
Tabs.List = StandardTabList;
Tabs.Sidebar = SidebarTabList;
