import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgId } from '../user/slice';
import { api as getRiskApi } from './get-risk.generated';
import { useUpdateRiskMutation } from './update-risk.generated';

export function useUpdateRiskHandler() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const refetchRiskQueries = useRefetchRiskQueries();

  return useCallback(
    async (resultPromise: ReturnType<ReturnType<typeof useUpdateRiskMutation>[0]>) => {
      const riskId = resultPromise.arg.originalArgs.id;

      dispatch(
        getRiskApi.util.updateQueryData(
          'GetRisks',
          { organization_id: organization.id },
          (draft) => {
            for (const risk of draft.risks) {
              if (risk.id === resultPromise.arg.originalArgs.id) {
                Object.assign(risk, resultPromise.arg.originalArgs.risk_input);
              }
            }
          },
        ),
      );

      try {
        await resultPromise.unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.risk') }));
        datadogLogs.logger.error('Risk update failed', {}, toError(error));
      } finally {
        await refetchRiskQueries(riskId);
      }
    },
    [dispatch, organization.id, refetchRiskQueries, t],
  );
}

export function useRefetchRiskQueries() {
  const dispatch = useAppDispatch();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  return useCallback(
    async (riskId?: string) => {
      try {
        await Promise.all([
          dispatch(
            getRiskApi.endpoints.GetRisks.initiate(
              { organization_id: orgId },
              { subscribe: false, forceRefetch: true },
            ),
          ),
          riskId &&
            dispatch(
              getRiskApi.endpoints.GetRisk.initiate(
                { risk_id: riskId },
                { subscribe: false, forceRefetch: true },
              ),
            ),
        ]);
      } catch (error) {
        datadogLogs.logger.error('Risk list refetch failed', { orgId, riskId }, toError(error));
      }
    },
    [dispatch, orgId],
  );
}
