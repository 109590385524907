import {
  Button,
  Card,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { createColumnHelper, Table } from '@main/ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import { POLICY_APPROVAL_STATUS_COLOR } from '../shared/status-color';
import { getCurrentOrgUsersMap } from '../user/slice';
import { ApprovalUsers } from './approvers-table';
import { useGetPolicyQuery } from './manage-policies.generated';
import { getApprovalRatio, getMappedPolicy } from './slice';

type PolicyApproval = ReturnType<typeof getMappedPolicy>['approvals'][number];

export const PolicyApprovalsTab = ({ policyId }: { policyId: string }) => {
  const { t } = useTranslation();
  const { isLoading } = useGetPolicyQuery({
    id: policyId,
  });
  const [selectedApprovalId, setSelectedApprovalId] = useState<string | undefined>(undefined);
  const { isOpen: isDialogOpen, onOpen: openDialog, onClose: closeDialog } = useDisclosure();

  const columns = useApprovalsTableColumns();
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.approval').toLowerCase(),
      plural: t('entities.plural.approvals').toLowerCase(),
    };
  }, [t]);
  const policy = useAppSelector((state) => getMappedPolicy(state, policyId));
  const openApproval = useCallback(
    (row: { original: PolicyApproval }) => {
      setSelectedApprovalId(row.original.id);
      openDialog();
    },
    [openDialog],
  );

  return (
    <Flex direction="column" justify="center" gap="8">
      <Card variant="table-styles">
        <Table
          minW="400px"
          data={policy.approvals}
          isLoading={isLoading}
          columns={columns}
          itemName={tableItemName}
          onRowClick={openApproval}
        />
      </Card>
      <Modal isOpen={isDialogOpen} onClose={closeDialog} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('policies.approval.details.approvers')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedApprovalId && (
              <ApprovalUsers policyId={policyId} approvalId={selectedApprovalId} />
            )}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="gray" onClick={closeDialog}>
              {t('buttons.close')}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

function useApprovalsTableColumns() {
  const { t } = useTranslation();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);

  return useMemo(() => {
    const columnHelper = createColumnHelper<PolicyApproval>();
    return [
      columnHelper.columns.tag({
        id: 'versionId',
        header: t('policies.approval.tableColumns.version'),
        accessorFn: ({ version }) => {
          return {
            value: version.formattedVersionId,
            colorScheme: 'purple',
          };
        },
        size: 80,
      }),

      columnHelper.columns.status({
        id: 'status',
        header: t('policies.approval.tableColumns.status'),
        accessorFn: ({ status }) => {
          return {
            value: t(`policies.approval.details.status.${status}`),
            colorScheme: POLICY_APPROVAL_STATUS_COLOR[status],
          };
        },
        size: 160,
      }),

      columnHelper.columns.date({
        id: 'dueDate',
        header: t('policies.approval.tableColumns.dueDate'),
        accessorFn: ({ due_date }) => due_date,
        size: 200,
      }),

      columnHelper.columns.text({
        id: 'approversCount',
        header: t('policies.approval.tableColumns.approvals'),
        accessorFn: ({ policy_approval_users }) => getApprovalRatio(policy_approval_users),
      }),

      columnHelper.columns.avatar({
        id: 'createdBy',
        header: t('policies.approval.tableColumns.createdBy'),
        accessorFn: ({ created_by }) => {
          if (!created_by) {
            return COMPLYANCE_USER;
          }

          const user = currentOrgUsers[created_by];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 100,
      }),
    ];
  }, [currentOrgUsers, t]);
}
