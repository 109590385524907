/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type DocumentsPageGetDocumentsQueryVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
}>;

export type DocumentsPageGetDocumentsQuery = {
  __typename?: 'query_root';
  documents: Array<{
    __typename?: 'documents';
    id: string;
    name?: string;
    type: Types.Document_Type_Enum;
    file: { __typename?: 'files'; id: string; name?: string; size?: number };
  }>;
};

export type DocumentsPageUploadGoldenQuestionnaireMutationVariables = Types.Exact<{
  organizationId: Types.Scalars['uuid']['input'];
  fileId: Types.Scalars['uuid']['input'];
  name: Types.Scalars['String']['input'];
}>;

export type DocumentsPageUploadGoldenQuestionnaireMutation = {
  __typename?: 'mutation_root';
  update_documents?: {
    __typename?: 'documents_mutation_response';
    returning: Array<{ __typename?: 'documents'; id: string }>;
  };
  insert_documents_one?: { __typename?: 'documents'; id: string };
};

export type DocumentsPageDeleteDocumentMutationVariables = Types.Exact<{
  documentId: Types.Scalars['uuid']['input'];
}>;

export type DocumentsPageDeleteDocumentMutation = {
  __typename?: 'mutation_root';
  update_documents?: {
    __typename?: 'documents_mutation_response';
    returning: Array<{ __typename?: 'documents'; id: string }>;
  };
};

export const DocumentsPageGetDocumentsDocument = `
    query DocumentsPageGetDocuments($organizationId: uuid!) {
  documents(
    where: {organization_id: {_eq: $organizationId}, is_current: {_eq: true}}
  ) {
    id
    name
    type
    file {
      id
      name
      size
    }
  }
}
    ` as string &
  TypedDocumentNode<DocumentsPageGetDocumentsQuery, DocumentsPageGetDocumentsQueryVariables>;
export const DocumentsPageUploadGoldenQuestionnaireDocument = `
    mutation DocumentsPageUploadGoldenQuestionnaire($organizationId: uuid!, $fileId: uuid!, $name: String!) {
  update_documents(
    where: {organization_id: {_eq: $organizationId}, type: {_eq: Golden_questionnaire}}
    _set: {is_current: false}
  ) {
    returning {
      id
    }
  }
  insert_documents_one(
    object: {type: Golden_questionnaire, organization_id: $organizationId, file_id: $fileId, name: $name, is_current: true}
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    DocumentsPageUploadGoldenQuestionnaireMutation,
    DocumentsPageUploadGoldenQuestionnaireMutationVariables
  >;
export const DocumentsPageDeleteDocumentDocument = `
    mutation DocumentsPageDeleteDocument($documentId: uuid!) {
  update_documents(where: {id: {_eq: $documentId}}, _set: {is_current: false}) {
    returning {
      id
    }
  }
}
    ` as string &
  TypedDocumentNode<
    DocumentsPageDeleteDocumentMutation,
    DocumentsPageDeleteDocumentMutationVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DocumentsPageGetDocuments: build.query<
      DocumentsPageGetDocumentsQuery,
      DocumentsPageGetDocumentsQueryVariables
    >({
      query: (variables) => ({ document: DocumentsPageGetDocumentsDocument, variables }),
    }),
    DocumentsPageUploadGoldenQuestionnaire: build.mutation<
      DocumentsPageUploadGoldenQuestionnaireMutation,
      DocumentsPageUploadGoldenQuestionnaireMutationVariables
    >({
      query: (variables) => ({
        document: DocumentsPageUploadGoldenQuestionnaireDocument,
        variables,
      }),
    }),
    DocumentsPageDeleteDocument: build.mutation<
      DocumentsPageDeleteDocumentMutation,
      DocumentsPageDeleteDocumentMutationVariables
    >({
      query: (variables) => ({ document: DocumentsPageDeleteDocumentDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useDocumentsPageGetDocumentsQuery,
  useLazyDocumentsPageGetDocumentsQuery,
  useDocumentsPageUploadGoldenQuestionnaireMutation,
  useDocumentsPageDeleteDocumentMutation,
} = injectedRtkApi;
