import { FieldError } from 'react-hook-form';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ErrorToMessage extends Partial<Record<string, ErrorMessage>> {}

export type ErrorMessage = string | ((error: FieldError) => string);

export const defaultErrorToMessage: ErrorToMessage = {
  required: 'validation.required',
};
