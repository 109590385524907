import { Finding_Causes_Enum, Integration_Names_Enum } from '@main/graphql/types.generated';
import { exhaustiveCheck, Severity } from '@main/shared/utils';

import { findingTypeSeverity } from '../../findings/finding-type';
import type { ControlEvidence, FindingAPIData } from '../slice';

export type ControlFindingsWithMetadata = {
  highestSeverity: Severity;
  findings: ControlFinding[];
  countWithoutIgnored: number;
  countWithoutRecommendationAndIgnored: number;
};

export type ControlFinding =
  | ControlFindingGeneric
  | ControlFindingEvidenceStatus
  | ControlFindingIntegrationError
  | ControlFindingIntegrationCheck
  | ControlFindingPolicyError
  | ControlFindingManual;

type ControlFindingBase = {
  finding: FindingAPIData;
  severity: Severity;
};

export type ControlFindingGeneric = ControlFindingBase & {
  cause: Finding_Causes_Enum.AiRecommendation;
  title: string;
  description?: string;
};

export type ControlFindingEvidenceStatus = ControlFindingBase & {
  cause: Finding_Causes_Enum.AtRiskEvidence | Finding_Causes_Enum.ExpiredEvidence;
  evidence: ControlEvidence;
};

export type ControlFindingIntegrationError = ControlFindingBase & {
  cause: Finding_Causes_Enum.IntegrationError;
  providerName: Integration_Names_Enum;
  evidenceName: string;
  errorMessage: string;
};

export type ControlFindingIntegrationCheck = ControlFindingBase & {
  cause: Finding_Causes_Enum.IntegrationCheck;
  integrationRunId: string;
  providerName: Integration_Names_Enum;
  evidenceName: string;
  checkFailureMessage: string;
};

export type ControlFindingPolicyError = ControlFindingBase & {
  cause: Finding_Causes_Enum.PolicyError;
  errorMessage: string;
};

export type ControlFindingManual = ControlFindingBase & {
  cause:
    | Finding_Causes_Enum.ManualIssue
    | Finding_Causes_Enum.ManualRecommendation
    | Finding_Causes_Enum.ManualConsideration;
  title: string;
  description?: string;
  createdBy?: string;
  createdAt: string;
};

export function parseControlFinding({
  finding,
  controlEvidenceMap,
}: {
  finding: FindingAPIData;
  controlEvidenceMap: Record<string, ControlEvidence>;
}): ControlFinding | null {
  const severity = findingTypeSeverity(finding.type);

  switch (finding.cause) {
    case Finding_Causes_Enum.AiRecommendation: {
      if (!finding.control_ai_review_assessment) return null;
      const title = finding.control_ai_review_assessment.criteria_heading;
      const description = finding.control_ai_review_assessment.assessment;
      return {
        cause: finding.cause,
        severity,
        finding,
        title,
        description,
      };
    }

    case Finding_Causes_Enum.AtRiskEvidence:
    case Finding_Causes_Enum.ExpiredEvidence: {
      if (!finding.control_evidence_id) return null;
      const evidence = controlEvidenceMap[finding.control_evidence_id];
      if (!evidence) return null;
      return {
        cause: finding.cause,
        severity,
        finding,
        evidence,
      };
    }

    case Finding_Causes_Enum.IntegrationError: {
      const providerName =
        finding.integration_run?.evidence_integration.organization_integration.integration.name;
      if (!providerName) return null;
      const errorMessage = finding.integration_run?.error_message;
      if (!errorMessage) return null;
      const evidenceName =
        finding.integration_run?.evidence_integration.evidence.name ||
        finding.integration_run?.evidence_integration.evidence.internal_id ||
        '';
      return {
        cause: finding.cause,
        severity,
        finding,
        providerName,
        evidenceName,
        errorMessage,
      };
    }

    case Finding_Causes_Enum.IntegrationCheck: {
      const integrationRunId = finding.integration_run?.id;
      const providerName =
        finding.integration_run?.evidence_integration.organization_integration.integration.name;
      const checkFailureMessage = finding.integration_run?.check_failure_message;
      if (!integrationRunId || !providerName || !checkFailureMessage) {
        return null;
      }
      const evidenceName =
        finding.integration_run?.evidence_integration.evidence.name ||
        finding.integration_run?.evidence_integration.evidence.internal_id ||
        '';
      return {
        cause: finding.cause,
        severity,
        finding,
        integrationRunId,
        providerName,
        evidenceName,
        checkFailureMessage,
      };
    }

    case Finding_Causes_Enum.PolicyError: {
      const errorMessage = finding.evidence_policy?.error_message;
      if (!errorMessage) return null;
      return {
        cause: finding.cause,
        severity,
        finding,
        errorMessage,
      };
    }

    case Finding_Causes_Enum.ManualIssue:
    case Finding_Causes_Enum.ManualRecommendation:
    case Finding_Causes_Enum.ManualConsideration: {
      if (!finding.manual_finding) return null;
      const title = finding.manual_finding.title;
      const description = finding.manual_finding.description;
      const createdBy = finding.manual_finding.created_by;
      const createdAt = finding.manual_finding.created_at;
      return {
        cause: finding.cause,
        severity,
        finding,
        title,
        description,
        createdBy,
        createdAt,
      };
    }

    default: {
      return exhaustiveCheck(finding.cause);
    }
  }
}
