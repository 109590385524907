import { Button, Card, Flex, Icon, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { useInsertTaskMutation } from '@main/graphql/mutations/InsertTask.generated';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast, NoPermissionPlaceholder, successToast } from '@main/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { EntitySearch } from '../link-entity/entity-search';
import { useLinkTaskToControlMutation } from '../link-entity/link-entity.generated';
import { LinkEntityModal } from '../link-entity/link-entity-modal';
import { useEntitySearch } from '../link-entity/use-entity-search';
import { LinkedEntityTasksTable } from '../tasks/linked-entity-tasks-table';
import { useGetTasksSubscription } from '../tasks/tasks-subscription';
import { useLinkTaskHandler } from '../tasks/use-link-task-handler';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';

export const ControlTasksTab = ({ controlId }: { controlId: string }) => {
  const { t } = useTranslation();
  const [newTaskId, setNewTaskId] = useState<string>();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateTask = userRole.permissionMap?.write_tasks;
  const canViewTask = userRole.permissionMap.read_tasks;
  const [createTask, { isLoading: isTaskCreationLoading }] = useInsertTaskMutation();

  const taskSearch = useEntitySearch({
    entities: [SearchEntitiesEnum.Tasks],
  });
  const [linkTaskToControl, { isLoading: isLinkingTask }] = useLinkTaskToControlMutation();
  const linkHandler = useLinkTaskHandler({ callback: () => taskSearch.disclosure.onClose?.() });

  const { data, isLoading: isTasksLoading } = useGetTasksSubscription({
    where: {
      controls: {
        control_id: {
          _eq: controlId,
        },
      },
    },
  });

  const createTaskHandler = async () => {
    try {
      const { insert_control_tasks_one } = await createTask({
        object: {
          control_id: controlId,
          task: {
            data: {
              organization_id: orgId,
            },
          },
        },
      }).unwrap();

      setNewTaskId(insert_control_tasks_one?.task_id);
      successToast(t('successMessages.createSucceeded', { entity: t('entities.task') }));
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.task') }));
      datadogLogs.logger.error('Task creation failed', { controlId, orgId }, toError(error));
    }
  };

  if (!canViewTask) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <Flex direction="column" justify="center" gap="6">
      <Card variant="table-styles">
        <LinkedEntityTasksTable
          tasks={data?.tasks ?? []}
          isTasksLoading={isTasksLoading}
          entityId={controlId}
          linkedEntity="control"
          newTaskId={newTaskId}
        />
      </Card>
      {canCreateTask && (
        <Menu>
          <MenuButton
            as={Button}
            isLoading={isTaskCreationLoading}
            rightIcon={<Icon as={ChevronDownIcon} />}
            colorScheme="blue"
            alignSelf="end"
          >
            {t('tasks.addTask')}
          </MenuButton>
          <MenuList>
            <MenuItem onClick={createTaskHandler}>{t('tasks.addNewTask')}</MenuItem>
            <MenuItem onClick={() => taskSearch.disclosure.onOpen?.()}>
              {t('tasks.linkExistingTask')}
            </MenuItem>
          </MenuList>
        </Menu>
      )}

      <LinkEntityModal
        isOpen={taskSearch.disclosure.isOpen}
        onClose={() => taskSearch.disclosure.onClose?.()}
        entityName="task"
        onLinkEntity={() =>
          linkHandler(
            linkTaskToControl({
              input: taskSearch.selectedResults.map((result) => ({
                control_id: controlId,
                task_id: result.id,
              })),
            }),
          )
        }
        isLinkingEntity={isLinkingTask}
      >
        <EntitySearch
          input={{
            placeholder: t('shared.linkModal.placeholder', {
              entity: t('entities.task').toLowerCase(),
            }),
          }}
          {...taskSearch}
        />
      </LinkEntityModal>
    </Flex>
  );
};
