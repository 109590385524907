import { Outlet, Route } from '@tanstack/react-router';

import { organizationSettingsLayoutRoute } from '../layout';

const TrustLayout = () => {
  return <Outlet />;
};

export const organizationTrustSettingsLayoutRoute = new Route({
  getParentRoute: () => organizationSettingsLayoutRoute,
  path: 'trust',
  component: TrustLayout,
});
