/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as ClientTypes from './client-scalars';
export type Maybe<T> = T;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  AiAutocompleteEntities: {
    input: ClientTypes.AiAutocompleteEntities;
    output: ClientTypes.AiAutocompleteEntities;
  };
  AiAutocompleteEntityOutputExtra: {
    input: ClientTypes.AiAutocompleteEntityOutputExtra;
    output: ClientTypes.AiAutocompleteEntityOutputExtra;
  };
  AiCreateEntityInput: {
    input: ClientTypes.AiCreateEntityInput;
    output: ClientTypes.AiCreateEntityInput;
  };
  AuditTableActions: {
    input: ClientTypes.AuditTableActions;
    output: ClientTypes.AuditTableActions;
  };
  AuditTableHasuraUser: {
    input: ClientTypes.AuditTableHasuraUser;
    output: ClientTypes.AuditTableHasuraUser;
  };
  ClientQquestionMetadata: {
    input: ClientTypes.ClientQquestionMetadata;
    output: ClientTypes.ClientQquestionMetadata;
  };
  ColumnFiltersState: {
    input: ClientTypes.ColumnFiltersState;
    output: ClientTypes.ColumnFiltersState;
  };
  CommentsFragment: { input: any; output: any };
  ControlChangedFields: {
    input: ClientTypes.ControlChangedFields;
    output: ClientTypes.ControlChangedFields;
  };
  ControlDetails: { input: any; output: any };
  ControlEvidenceStatus: {
    input: ClientTypes.ControlEvidenceStatus;
    output: ClientTypes.ControlEvidenceStatus;
  };
  ControlHistoryTables: {
    input: ClientTypes.ControlHistoryTables;
    output: ClientTypes.ControlHistoryTables;
  };
  ControlRowData: { input: ClientTypes.ControlRowData; output: ClientTypes.ControlRowData };
  ControlStatus: { input: ClientTypes.ControlStatus; output: ClientTypes.ControlStatus };
  ControlTags: { input: ClientTypes.ControlTags; output: ClientTypes.ControlTags };
  DateFieldValue: { input: any; output: any };
  EmailFieldValue: { input: any; output: any };
  EvidenceIntegrationConfig: {
    input: ClientTypes.EvidenceIntegrationConfig;
    output: ClientTypes.EvidenceIntegrationConfig;
  };
  EvidenceProgram: { input: any; output: any };
  EvidenceStatus: { input: ClientTypes.EvidenceStatus; output: ClientTypes.EvidenceStatus };
  FieldConfig: { input: any; output: any };
  FieldValue: { input: any; output: any };
  FileInfoFragment: { input: any; output: any };
  FormConfig: { input: ClientTypes.FormConfig; output: ClientTypes.FormConfig };
  NotificationItem: { input: any; output: any };
  NotificationParams: {
    input: ClientTypes.NotificationParams;
    output: ClientTypes.NotificationParams;
  };
  NumberFieldValue: { input: any; output: any };
  OrgIntegrationConfig: {
    input: ClientTypes.OrgIntegrationConfig;
    output: ClientTypes.OrgIntegrationConfig;
  };
  OrganizationDetailsFragment: { input: any; output: any };
  OrganizationEvidence: { input: any; output: any };
  OrganizationUserDetails: { input: any; output: any };
  PolicyChangedFields: {
    input: ClientTypes.PolicyChangedFields;
    output: ClientTypes.PolicyChangedFields;
  };
  PolicyDrawerFragment: { input: any; output: any };
  PolicyHistoryTables: {
    input: ClientTypes.PolicyHistoryTables;
    output: ClientTypes.PolicyHistoryTables;
  };
  PolicyRowData: { input: ClientTypes.PolicyRowData; output: ClientTypes.PolicyRowData };
  ReviewDetails: { input: any; output: any };
  RiskAssessmentStatus: {
    input: ClientTypes.RiskAssessmentStatus;
    output: ClientTypes.RiskAssessmentStatus;
  };
  RiskChangedFields: {
    input: ClientTypes.RiskChangedFields;
    output: ClientTypes.RiskChangedFields;
  };
  RiskHistoryTables: {
    input: ClientTypes.RiskHistoryTables;
    output: ClientTypes.RiskHistoryTables;
  };
  RiskRowData: { input: ClientTypes.RiskRowData; output: ClientTypes.RiskRowData };
  RoleDetails: { input: any; output: any };
  SelectFieldConfig: { input: any; output: any };
  SelectFieldOption: { input: any; output: any };
  SelectFieldValue: { input: any; output: any };
  SortingState: { input: ClientTypes.SortingState; output: ClientTypes.SortingState };
  TaskDrawerFragment: { input: any; output: any };
  TaskStatus: { input: ClientTypes.TaskStatus; output: ClientTypes.TaskStatus };
  TaskTableFragment: { input: any; output: any };
  TextFieldValue: { input: any; output: any };
  UrlFieldValue: { input: any; output: any };
  UserMetaData: { input: ClientTypes.UserMetaData; output: ClientTypes.UserMetaData };
  UserNameEmailFragment: { input: any; output: any };
  VendorAssessmentStatus: {
    input: ClientTypes.VendorAssessmentStatus;
    output: ClientTypes.VendorAssessmentStatus;
  };
  VendorChangedFields: {
    input: ClientTypes.VendorChangedFields;
    output: ClientTypes.VendorChangedFields;
  };
  VendorHistoryTables: {
    input: ClientTypes.VendorHistoryTables;
    output: ClientTypes.VendorHistoryTables;
  };
  VendorQuestionnaireFormAnswerValue: {
    input: ClientTypes.VendorQuestionnaireFormAnswerValue;
    output: ClientTypes.VendorQuestionnaireFormAnswerValue;
  };
  VendorRowData: { input: ClientTypes.VendorRowData; output: ClientTypes.VendorRowData };
  _int4: { input: any; output: any };
  bigint: { input: number; output: number };
  citext: { input: string; output: string };
  float8: { input: number; output: number };
  json: { input: any; output: any };
  jsonb: { input: any; output: any };
  numeric: { input: number; output: number };
  sparsevec: { input: any; output: any };
  timestamp: { input: any; output: any };
  timestamptz: { input: string; output: string };
  uuid: { input: string; output: string };
  vector: { input: any; output: any };
};

export type AiAutocompleteEntityInput = {
  entity: Scalars['AiAutocompleteEntities']['input'];
  entityId: Scalars['String']['input'];
};

export type AiAutocompleteEntityOutput = {
  __typename?: 'AiAutocompleteEntityOutput';
  autocomplete: Scalars['String']['output'];
  extra?: Maybe<Scalars['AiAutocompleteEntityOutputExtra']['output']>;
};

export type AiControlEvidenceSuggestion = {
  __typename?: 'AiControlEvidenceSuggestion';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['Float']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type AiControlEvidenceSuggestionsInput = {
  controlId: Scalars['uuid']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  threshold?: InputMaybe<Scalars['Float']['input']>;
};

export type AiControlEvidenceSuggestionsOutput = {
  __typename?: 'AiControlEvidenceSuggestionsOutput';
  evidences?: Maybe<Array<AiControlEvidenceSuggestion>>;
};

export type AiCreateEntityOutput = {
  __typename?: 'AiCreateEntityOutput';
  entityId: Scalars['String']['output'];
  internalId?: Maybe<Scalars['String']['output']>;
};

export type ApprovalReminderInput = {
  approvalId: Scalars['String']['input'];
  approverId: Scalars['String']['input'];
  senderId: Scalars['String']['input'];
};

export type ApprovalReminderOutput = {
  __typename?: 'ApprovalReminderOutput';
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type ChangeEvidenceOwnerInput = {
  evidence_id: Scalars['String']['input'];
  new_owner_id: Scalars['String']['input'];
};

export type CreateControlInput = {
  framework_id?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['String']['input'];
  program_id?: InputMaybe<Scalars['String']['input']>;
};

export type CreateControlOutput = {
  __typename?: 'CreateControlOutput';
  id: Scalars['String']['output'];
};

export type CreateCustomProgramRequestInput = {
  fileId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  programDescription: Scalars['String']['input'];
  programName: Scalars['String']['input'];
};

export type CreateCustomProgramRequestOutput = {
  __typename?: 'CreateCustomProgramRequestOutput';
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type CreateManualFindingInput = {
  controlId?: InputMaybe<Scalars['uuid']['input']>;
  description: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: Scalars['String']['input'];
};

export type CreateManualFindingOutput = {
  __typename?: 'CreateManualFindingOutput';
  findingId: Scalars['uuid']['output'];
};

export type CreateProgramInput = {
  description?: InputMaybe<Scalars['String']['input']>;
  frameworkType: FrameworkType;
  framework_id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organization_id: Scalars['String']['input'];
};

export type CreateQuestionnaireOutput = {
  __typename?: 'CreateQuestionnaireOutput';
  id: Scalars['String']['output'];
};

export type ExportClientQuestionnaireInput = {
  client_questionnaire_id: Scalars['uuid']['input'];
};

export type ExportClientQuestionnaireOutput = {
  __typename?: 'ExportClientQuestionnaireOutput';
  file?: Maybe<Files>;
  file_id: Scalars['uuid']['output'];
};

export type ExportPolicyInput = {
  policyId: Scalars['uuid']['input'];
  withAcknowledgementsChapter?: InputMaybe<Scalars['Boolean']['input']>;
  withApprovalsChapter?: InputMaybe<Scalars['Boolean']['input']>;
  withRevisionDetails?: InputMaybe<Scalars['Boolean']['input']>;
  withVersionsChapter?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ExportPolicyOutput = {
  __typename?: 'ExportPolicyOutput';
  fileId: Scalars['uuid']['output'];
};

export type FrameworkRequestInput = {
  additionalComments: Scalars['String']['input'];
  frameworkName: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
};

export type FrameworkRequestOutput = {
  __typename?: 'FrameworkRequestOutput';
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export enum FrameworkType {
  Custom = 'CUSTOM',
  Template = 'TEMPLATE',
}

export type GenIntegrationRunReportInput = {
  integrationRunId: Scalars['uuid']['input'];
};

export type GenIntegrationRunReportOutput = {
  __typename?: 'GenIntegrationRunReportOutput';
  reportFileId: Scalars['uuid']['output'];
};

export type GeneralActionOutput = {
  __typename?: 'GeneralActionOutput';
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
};

export type GenerateWorkosAdminLinkInput = {
  intent: WorkosAdminPortalIntent;
  orgId: Scalars['String']['input'];
};

export type GenerateWorkosAdminLinkOutput = {
  __typename?: 'GenerateWorkosAdminLinkOutput';
  link?: Maybe<Scalars['String']['output']>;
};

export type GrantEvidenceAccessInput = {
  evidence_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

export type GrantEvidenceAccessOutput = {
  __typename?: 'GrantEvidenceAccessOutput';
  evidence_acl_id: Scalars['uuid']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InviteUserInput = {
  email: Scalars['String']['input'];
  invitingUserId: Scalars['String']['input'];
  organizationId: Scalars['String']['input'];
  roleId: Scalars['String']['input'];
};

export type InviteUserOutput = {
  __typename?: 'InviteUserOutput';
  message: Scalars['String']['output'];
  status: Scalars['Int']['output'];
  type?: Maybe<UserInviteResult>;
};

export type JoinOrganizationInput = {
  invitationId: Scalars['String']['input'];
};

export type ProgramOutput = {
  __typename?: 'ProgramOutput';
  id: Scalars['String']['output'];
};

export type RevokeEvidenceAccessInput = {
  evidence_acl_id: Scalars['uuid']['input'];
};

export type RevokeEvidenceAccessOutput = {
  __typename?: 'RevokeEvidenceAccessOutput';
  evidence_acl_id: Scalars['uuid']['output'];
};

export enum SearchEntitiesEnum {
  Controls = 'controls',
  Evidences = 'evidences',
  Policies = 'policies',
  Risks = 'risks',
  Tasks = 'tasks',
  Vendors = 'vendors',
}

export type SearchInput = {
  entities: Array<InputMaybe<SearchEntitiesEnum>>;
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
  organization_id: Scalars['uuid']['input'];
  text: Scalars['String']['input'];
};

export type SearchObject = {
  __typename?: 'SearchObject';
  created_at?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  entity: SearchEntitiesEnum;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['Float']['output']>;
  updated_at?: Maybe<Scalars['String']['output']>;
};

export type SearchOutput = {
  __typename?: 'SearchOutput';
  data?: Maybe<Array<SearchObject>>;
};

export type SendVendorQuestionnaireInput = {
  customMessage?: InputMaybe<Scalars['String']['input']>;
  dueDate: Scalars['String']['input'];
  questionnaireId: Scalars['String']['input'];
  subject: Scalars['String']['input'];
  vendors?: InputMaybe<Array<SendVendorQuestionnaireVendorInput>>;
};

export type SendVendorQuestionnaireOutput = {
  __typename?: 'SendVendorQuestionnaireOutput';
  workflowId: Scalars['String']['output'];
};

export type SendVendorQuestionnaireVendorInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type SetEvidenceConfidentialInput = {
  evidence_id: Scalars['uuid']['input'];
  is_confidential: Scalars['Boolean']['input'];
};

export type SetEvidenceConfidentialOutput = {
  __typename?: 'SetEvidenceConfidentialOutput';
  evidence_id: Scalars['uuid']['output'];
};

export type SetSelectFieldValuesInput = {
  entityId: Scalars['String']['input'];
  fieldConfigId: Scalars['String']['input'];
  values: Array<Scalars['String']['input']>;
};

export type SetSelectFieldValuesOutput = {
  __typename?: 'SetSelectFieldValuesOutput';
  deletedFieldValueIds: Array<Scalars['String']['output']>;
  insertedFieldValueIds: Array<Scalars['String']['output']>;
};

export type SparseVectorOutput = {
  __typename?: 'SparseVectorOutput';
  data: Scalars['json']['output'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type ToggleRiskCloseOutOutput = {
  __typename?: 'ToggleRiskCloseOutOutput';
  id: Scalars['String']['output'];
};

export type ToggleTerminateVendorOutput = {
  __typename?: 'ToggleTerminateVendorOutput';
  id: Scalars['String']['output'];
};

export enum UserInviteResult {
  UserInvited = 'USER_INVITED',
  UserJoined = 'USER_JOINED',
}

export enum WorkosAdminPortalIntent {
  AuditLogs = 'audit_logs',
  LogStreams = 'log_streams',
}

/** Boolean expression to compare columns of type "_int4". All fields are combined with logical 'AND'. */
export type _Int4_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_int4']['input']>;
  _gt?: InputMaybe<Scalars['_int4']['input']>;
  _gte?: InputMaybe<Scalars['_int4']['input']>;
  _in?: InputMaybe<Array<Scalars['_int4']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['_int4']['input']>;
  _lte?: InputMaybe<Scalars['_int4']['input']>;
  _neq?: InputMaybe<Scalars['_int4']['input']>;
  _nin?: InputMaybe<Array<Scalars['_int4']['input']>>;
};

export type Ai_Control_Evidences_Suggestions_Args = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  semantic_weight?: InputMaybe<Scalars['float8']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

export type Ai_Controls_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store = {
  __typename?: 'ai_controls_vector_store';
  /** An object relationship */
  control: Controls;
  controls_id: Scalars['uuid']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.controls_vector_store" */
export type Ai_Controls_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Aggregate_Order_By = {
  avg?: InputMaybe<Ai_Controls_Vector_Store_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Controls_Vector_Store_Max_Order_By>;
  min?: InputMaybe<Ai_Controls_Vector_Store_Min_Order_By>;
  stddev?: InputMaybe<Ai_Controls_Vector_Store_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ai_Controls_Vector_Store_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ai_Controls_Vector_Store_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ai_Controls_Vector_Store_Sum_Order_By>;
  var_pop?: InputMaybe<Ai_Controls_Vector_Store_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ai_Controls_Vector_Store_Var_Samp_Order_By>;
  variance?: InputMaybe<Ai_Controls_Vector_Store_Variance_Order_By>;
};

/** order by avg() on columns of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ai.controls_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Controls_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Controls_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Controls_Vector_Store_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  controls_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Max_Order_By = {
  controls_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Min_Order_By = {
  controls_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "ai.controls_vector_store". */
export type Ai_Controls_Vector_Store_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  controls_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Ai_Controls_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  semantic_weight?: InputMaybe<Scalars['float8']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.controls_vector_store" */
export enum Ai_Controls_Vector_Store_Select_Column {
  /** column name */
  ControlsId = 'controls_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** order by stddev() on columns of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "ai.controls_vector_store" */
export type Ai_Controls_Vector_Store_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export type Ai_Documents_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store = {
  __typename?: 'ai_documents_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  document: Documents;
  documents_id: Scalars['uuid']['output'];
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.documents_vector_store" */
export type Ai_Documents_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Aggregate_Order_By = {
  avg?: InputMaybe<Ai_Documents_Vector_Store_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Documents_Vector_Store_Max_Order_By>;
  min?: InputMaybe<Ai_Documents_Vector_Store_Min_Order_By>;
  stddev?: InputMaybe<Ai_Documents_Vector_Store_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ai_Documents_Vector_Store_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ai_Documents_Vector_Store_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ai_Documents_Vector_Store_Sum_Order_By>;
  var_pop?: InputMaybe<Ai_Documents_Vector_Store_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ai_Documents_Vector_Store_Var_Samp_Order_By>;
  variance?: InputMaybe<Ai_Documents_Vector_Store_Variance_Order_By>;
};

/** order by avg() on columns of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ai.documents_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Documents_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Documents_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Documents_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Documents_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  document?: InputMaybe<Documents_Bool_Exp>;
  documents_id?: InputMaybe<Uuid_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  documents_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  documents_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "ai.documents_vector_store". */
export type Ai_Documents_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  document?: InputMaybe<Documents_Order_By>;
  documents_id?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Ai_Documents_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  semantic_weight?: InputMaybe<Scalars['float8']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.documents_vector_store" */
export enum Ai_Documents_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DocumentsId = 'documents_id',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** order by stddev() on columns of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ai_documents_vector_store" */
export type Ai_Documents_Vector_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Documents_Vector_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Documents_Vector_Store_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  documents_id?: InputMaybe<Scalars['uuid']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "ai.documents_vector_store" */
export type Ai_Documents_Vector_Store_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export type Ai_Evidences_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store = {
  __typename?: 'ai_evidences_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  /** An object relationship */
  evidence: Evidences;
  evidences_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Aggregate_Order_By = {
  avg?: InputMaybe<Ai_Evidences_Vector_Store_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Evidences_Vector_Store_Max_Order_By>;
  min?: InputMaybe<Ai_Evidences_Vector_Store_Min_Order_By>;
  stddev?: InputMaybe<Ai_Evidences_Vector_Store_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ai_Evidences_Vector_Store_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ai_Evidences_Vector_Store_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ai_Evidences_Vector_Store_Sum_Order_By>;
  var_pop?: InputMaybe<Ai_Evidences_Vector_Store_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ai_Evidences_Vector_Store_Var_Samp_Order_By>;
  variance?: InputMaybe<Ai_Evidences_Vector_Store_Variance_Order_By>;
};

/** order by avg() on columns of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ai.evidences_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Evidences_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Evidences_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Evidences_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidences_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidences_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidences_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "ai.evidences_vector_store". */
export type Ai_Evidences_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidences_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Ai_Evidences_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  semantic_weight?: InputMaybe<Scalars['float8']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.evidences_vector_store" */
export enum Ai_Evidences_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  EvidencesId = 'evidences_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** order by stddev() on columns of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "ai.evidences_vector_store" */
export type Ai_Evidences_Vector_Store_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export type Ai_Policies_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store = {
  __typename?: 'ai_policies_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  policies_id: Scalars['uuid']['output'];
  /** An object relationship */
  policy: Policies;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.policies_vector_store" */
export type Ai_Policies_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Aggregate_Order_By = {
  avg?: InputMaybe<Ai_Policies_Vector_Store_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Policies_Vector_Store_Max_Order_By>;
  min?: InputMaybe<Ai_Policies_Vector_Store_Min_Order_By>;
  stddev?: InputMaybe<Ai_Policies_Vector_Store_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ai_Policies_Vector_Store_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ai_Policies_Vector_Store_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ai_Policies_Vector_Store_Sum_Order_By>;
  var_pop?: InputMaybe<Ai_Policies_Vector_Store_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ai_Policies_Vector_Store_Var_Samp_Order_By>;
  variance?: InputMaybe<Ai_Policies_Vector_Store_Variance_Order_By>;
};

/** order by avg() on columns of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ai.policies_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Policies_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Policies_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Policies_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  policies_id?: InputMaybe<Uuid_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  policies_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  policies_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "ai.policies_vector_store". */
export type Ai_Policies_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  policies_id?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Ai_Policies_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  semantic_weight?: InputMaybe<Scalars['float8']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.policies_vector_store" */
export enum Ai_Policies_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PoliciesId = 'policies_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** order by stddev() on columns of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "ai_policies_vector_store" */
export type Ai_Policies_Vector_Store_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Ai_Policies_Vector_Store_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ai_Policies_Vector_Store_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  embedding?: InputMaybe<Scalars['vector']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  next_id?: InputMaybe<Scalars['String']['input']>;
  node_id?: InputMaybe<Scalars['String']['input']>;
  parent_id?: InputMaybe<Scalars['String']['input']>;
  policies_id?: InputMaybe<Scalars['uuid']['input']>;
  previous_id?: InputMaybe<Scalars['String']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  sparse?: InputMaybe<Scalars['sparsevec']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
};

/** order by sum() on columns of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "ai.policies_vector_store" */
export type Ai_Policies_Vector_Store_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export type Ai_Programs_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store = {
  __typename?: 'ai_programs_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  program: Programs;
  programs_id: Scalars['uuid']['output'];
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.programs_vector_store" */
export type Ai_Programs_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Aggregate_Order_By = {
  avg?: InputMaybe<Ai_Programs_Vector_Store_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Programs_Vector_Store_Max_Order_By>;
  min?: InputMaybe<Ai_Programs_Vector_Store_Min_Order_By>;
  stddev?: InputMaybe<Ai_Programs_Vector_Store_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ai_Programs_Vector_Store_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ai_Programs_Vector_Store_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ai_Programs_Vector_Store_Sum_Order_By>;
  var_pop?: InputMaybe<Ai_Programs_Vector_Store_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ai_Programs_Vector_Store_Var_Samp_Order_By>;
  variance?: InputMaybe<Ai_Programs_Vector_Store_Variance_Order_By>;
};

/** order by avg() on columns of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ai.programs_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Programs_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Programs_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Programs_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  program?: InputMaybe<Programs_Bool_Exp>;
  programs_id?: InputMaybe<Uuid_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  programs_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  programs_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "ai.programs_vector_store". */
export type Ai_Programs_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  program?: InputMaybe<Programs_Order_By>;
  programs_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Ai_Programs_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  semantic_weight?: InputMaybe<Scalars['float8']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.programs_vector_store" */
export enum Ai_Programs_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  ProgramsId = 'programs_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** order by stddev() on columns of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "ai.programs_vector_store" */
export type Ai_Programs_Vector_Store_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export type Ai_Risk_Controls_Suggestions_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  semantic_weight?: InputMaybe<Scalars['float8']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

export type Ai_Risks_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store = {
  __typename?: 'ai_risks_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  risk: Risks;
  risks_id: Scalars['uuid']['output'];
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.risks_vector_store" */
export type Ai_Risks_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Aggregate_Order_By = {
  avg?: InputMaybe<Ai_Risks_Vector_Store_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Risks_Vector_Store_Max_Order_By>;
  min?: InputMaybe<Ai_Risks_Vector_Store_Min_Order_By>;
  stddev?: InputMaybe<Ai_Risks_Vector_Store_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ai_Risks_Vector_Store_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ai_Risks_Vector_Store_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ai_Risks_Vector_Store_Sum_Order_By>;
  var_pop?: InputMaybe<Ai_Risks_Vector_Store_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ai_Risks_Vector_Store_Var_Samp_Order_By>;
  variance?: InputMaybe<Ai_Risks_Vector_Store_Variance_Order_By>;
};

/** order by avg() on columns of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ai.risks_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Risks_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Risks_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Risks_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risks_id?: InputMaybe<Uuid_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  risks_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  risks_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "ai.risks_vector_store". */
export type Ai_Risks_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risks_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Ai_Risks_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  semantic_weight?: InputMaybe<Scalars['float8']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.risks_vector_store" */
export enum Ai_Risks_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  RisksId = 'risks_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
}

/** order by stddev() on columns of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "ai.risks_vector_store" */
export type Ai_Risks_Vector_Store_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export type Ai_Tasks_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store = {
  __typename?: 'ai_tasks_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  /** An object relationship */
  task: Tasks;
  tasks_id: Scalars['uuid']['output'];
  text: Scalars['String']['output'];
};

/** columns and relationships of "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Aggregate_Order_By = {
  avg?: InputMaybe<Ai_Tasks_Vector_Store_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Tasks_Vector_Store_Max_Order_By>;
  min?: InputMaybe<Ai_Tasks_Vector_Store_Min_Order_By>;
  stddev?: InputMaybe<Ai_Tasks_Vector_Store_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ai_Tasks_Vector_Store_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ai_Tasks_Vector_Store_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ai_Tasks_Vector_Store_Sum_Order_By>;
  var_pop?: InputMaybe<Ai_Tasks_Vector_Store_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ai_Tasks_Vector_Store_Var_Samp_Order_By>;
  variance?: InputMaybe<Ai_Tasks_Vector_Store_Variance_Order_By>;
};

/** order by avg() on columns of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ai.tasks_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Tasks_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Tasks_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Tasks_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  tasks_id?: InputMaybe<Uuid_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  tasks_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  tasks_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "ai.tasks_vector_store". */
export type Ai_Tasks_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  tasks_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
};

export type Ai_Tasks_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  semantic_weight?: InputMaybe<Scalars['float8']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.tasks_vector_store" */
export enum Ai_Tasks_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  TasksId = 'tasks_id',
  /** column name */
  Text = 'text',
}

/** order by stddev() on columns of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "ai.tasks_vector_store" */
export type Ai_Tasks_Vector_Store_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export type Ai_Vendors_Text_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_query?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store = {
  __typename?: 'ai_vendors_vector_store';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  embedding?: Maybe<Scalars['vector']['output']>;
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  next_id?: Maybe<Scalars['String']['output']>;
  node_id?: Maybe<Scalars['String']['output']>;
  parent_id?: Maybe<Scalars['String']['output']>;
  previous_id?: Maybe<Scalars['String']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  sparse?: Maybe<Scalars['sparsevec']['output']>;
  text: Scalars['String']['output'];
  /** An object relationship */
  vendor: Vendors;
  vendors_id: Scalars['uuid']['output'];
};

/** columns and relationships of "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_StoreMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Aggregate_Order_By = {
  avg?: InputMaybe<Ai_Vendors_Vector_Store_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Ai_Vendors_Vector_Store_Max_Order_By>;
  min?: InputMaybe<Ai_Vendors_Vector_Store_Min_Order_By>;
  stddev?: InputMaybe<Ai_Vendors_Vector_Store_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Ai_Vendors_Vector_Store_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Ai_Vendors_Vector_Store_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Ai_Vendors_Vector_Store_Sum_Order_By>;
  var_pop?: InputMaybe<Ai_Vendors_Vector_Store_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Ai_Vendors_Vector_Store_Var_Samp_Order_By>;
  variance?: InputMaybe<Ai_Vendors_Vector_Store_Variance_Order_By>;
};

/** order by avg() on columns of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "ai.vendors_vector_store". All fields are combined with a logical 'AND'. */
export type Ai_Vendors_Vector_Store_Bool_Exp = {
  _and?: InputMaybe<Array<Ai_Vendors_Vector_Store_Bool_Exp>>;
  _not?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
  _or?: InputMaybe<Array<Ai_Vendors_Vector_Store_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  embedding?: InputMaybe<Vector_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  next_id?: InputMaybe<String_Comparison_Exp>;
  node_id?: InputMaybe<String_Comparison_Exp>;
  parent_id?: InputMaybe<String_Comparison_Exp>;
  previous_id?: InputMaybe<String_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  sparse?: InputMaybe<Sparsevec_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendors_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  vendors_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  vendors_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "ai.vendors_vector_store". */
export type Ai_Vendors_Vector_Store_Order_By = {
  created_at?: InputMaybe<Order_By>;
  embedding?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  next_id?: InputMaybe<Order_By>;
  node_id?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  previous_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  sparse?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendors_id?: InputMaybe<Order_By>;
};

export type Ai_Vendors_Vector_Store_Search_Args = {
  limit_number?: InputMaybe<Scalars['Int']['input']>;
  metadata_filter?: InputMaybe<Scalars['jsonb']['input']>;
  offset_number?: InputMaybe<Scalars['Int']['input']>;
  search_vector?: InputMaybe<Scalars['vector']['input']>;
  semantic_weight?: InputMaybe<Scalars['float8']['input']>;
  sparse_vector?: InputMaybe<Scalars['sparsevec']['input']>;
};

/** select columns of table "ai.vendors_vector_store" */
export enum Ai_Vendors_Vector_Store_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Embedding = 'embedding',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  NextId = 'next_id',
  /** column name */
  NodeId = 'node_id',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PreviousId = 'previous_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Sparse = 'sparse',
  /** column name */
  Text = 'text',
  /** column name */
  VendorsId = 'vendors_id',
}

/** order by stddev() on columns of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "ai.vendors_vector_store" */
export type Ai_Vendors_Vector_Store_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** columns and relationships of "audit.control_history_view" */
export type Audit_Control_History_View = {
  __typename?: 'audit_control_history_view';
  action: Scalars['AuditTableActions']['output'];
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  changed_fields?: Maybe<Scalars['ControlChangedFields']['output']>;
  /** An object relationship */
  control?: Maybe<Controls>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  row_data: Scalars['ControlRowData']['output'];
  table_name: Scalars['ControlHistoryTables']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** columns and relationships of "audit.control_history_view" */
export type Audit_Control_History_ViewChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.control_history_view" */
export type Audit_Control_History_ViewHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.control_history_view" */
export type Audit_Control_History_ViewRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "audit.control_history_view". All fields are combined with a logical 'AND'. */
export type Audit_Control_History_View_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Control_History_View_Bool_Exp>>;
  _not?: InputMaybe<Audit_Control_History_View_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Control_History_View_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "audit.control_history_view". */
export type Audit_Control_History_View_Order_By = {
  action?: InputMaybe<Order_By>;
  action_timestamp?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "audit.control_history_view" */
export enum Audit_Control_History_View_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTimestamp = 'action_timestamp',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TableName = 'table_name',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "audit_control_history_view" */
export type Audit_Control_History_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Control_History_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Control_History_View_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "audit.policy_history_view" */
export type Audit_Policy_History_View = {
  __typename?: 'audit_policy_history_view';
  action: Scalars['AuditTableActions']['output'];
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  changed_fields?: Maybe<Scalars['PolicyChangedFields']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  /** An object relationship */
  policy?: Maybe<Policies>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  row_data: Scalars['PolicyRowData']['output'];
  table_name: Scalars['PolicyHistoryTables']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "audit.policy_history_view" */
export type Audit_Policy_History_ViewChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.policy_history_view" */
export type Audit_Policy_History_ViewHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.policy_history_view" */
export type Audit_Policy_History_ViewRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "audit.policy_history_view". All fields are combined with a logical 'AND'. */
export type Audit_Policy_History_View_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Policy_History_View_Bool_Exp>>;
  _not?: InputMaybe<Audit_Policy_History_View_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Policy_History_View_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "audit.policy_history_view". */
export type Audit_Policy_History_View_Order_By = {
  action?: InputMaybe<Order_By>;
  action_timestamp?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "audit.policy_history_view" */
export enum Audit_Policy_History_View_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTimestamp = 'action_timestamp',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TableName = 'table_name',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "audit_policy_history_view" */
export type Audit_Policy_History_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Policy_History_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Policy_History_View_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.risk_history_view" */
export type Audit_Risk_History_View = {
  __typename?: 'audit_risk_history_view';
  action: Scalars['AuditTableActions']['output'];
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  changed_fields?: Maybe<Scalars['RiskChangedFields']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  /** An object relationship */
  risk?: Maybe<Risks>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  row_data: Scalars['RiskRowData']['output'];
  table_name: Scalars['RiskHistoryTables']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "audit.risk_history_view" */
export type Audit_Risk_History_ViewChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.risk_history_view" */
export type Audit_Risk_History_ViewHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.risk_history_view" */
export type Audit_Risk_History_ViewRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "audit.risk_history_view". All fields are combined with a logical 'AND'. */
export type Audit_Risk_History_View_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Risk_History_View_Bool_Exp>>;
  _not?: InputMaybe<Audit_Risk_History_View_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Risk_History_View_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "audit.risk_history_view". */
export type Audit_Risk_History_View_Order_By = {
  action?: InputMaybe<Order_By>;
  action_timestamp?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "audit.risk_history_view" */
export enum Audit_Risk_History_View_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTimestamp = 'action_timestamp',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TableName = 'table_name',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "audit_risk_history_view" */
export type Audit_Risk_History_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Risk_History_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Risk_History_View_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.vendor_history_view" */
export type Audit_Vendor_History_View = {
  __typename?: 'audit_vendor_history_view';
  action: Scalars['AuditTableActions']['output'];
  action_timestamp?: Maybe<Scalars['timestamptz']['output']>;
  changed_fields?: Maybe<Scalars['VendorChangedFields']['output']>;
  hasura_user: Scalars['AuditTableHasuraUser']['output'];
  row_data: Scalars['VendorRowData']['output'];
  table_name: Scalars['VendorHistoryTables']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** columns and relationships of "audit.vendor_history_view" */
export type Audit_Vendor_History_ViewChanged_FieldsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.vendor_history_view" */
export type Audit_Vendor_History_ViewHasura_UserArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "audit.vendor_history_view" */
export type Audit_Vendor_History_ViewRow_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "audit.vendor_history_view". All fields are combined with a logical 'AND'. */
export type Audit_Vendor_History_View_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Vendor_History_View_Bool_Exp>>;
  _not?: InputMaybe<Audit_Vendor_History_View_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Vendor_History_View_Bool_Exp>>;
  action?: InputMaybe<String_Comparison_Exp>;
  action_timestamp?: InputMaybe<Timestamptz_Comparison_Exp>;
  changed_fields?: InputMaybe<Jsonb_Comparison_Exp>;
  hasura_user?: InputMaybe<Jsonb_Comparison_Exp>;
  row_data?: InputMaybe<Jsonb_Comparison_Exp>;
  table_name?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "audit.vendor_history_view". */
export type Audit_Vendor_History_View_Order_By = {
  action?: InputMaybe<Order_By>;
  action_timestamp?: InputMaybe<Order_By>;
  changed_fields?: InputMaybe<Order_By>;
  hasura_user?: InputMaybe<Order_By>;
  row_data?: InputMaybe<Order_By>;
  table_name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "audit.vendor_history_view" */
export enum Audit_Vendor_History_View_Select_Column {
  /** column name */
  Action = 'action',
  /** column name */
  ActionTimestamp = 'action_timestamp',
  /** column name */
  ChangedFields = 'changed_fields',
  /** column name */
  HasuraUser = 'hasura_user',
  /** column name */
  RowData = 'row_data',
  /** column name */
  TableName = 'table_name',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id',
}

/** Streaming cursor of the table "audit_vendor_history_view" */
export type Audit_Vendor_History_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Vendor_History_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Vendor_History_View_Stream_Cursor_Value_Input = {
  action?: InputMaybe<Scalars['String']['input']>;
  action_timestamp?: InputMaybe<Scalars['timestamptz']['input']>;
  changed_fields?: InputMaybe<Scalars['jsonb']['input']>;
  hasura_user?: InputMaybe<Scalars['jsonb']['input']>;
  row_data?: InputMaybe<Scalars['jsonb']['input']>;
  table_name?: InputMaybe<Scalars['String']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

export enum AuthRefreshTokenTypes_Enum {
  /** Personal access token */
  Pat = 'pat',
  /** Regular refresh token */
  Regular = 'regular',
}

/** Boolean expression to compare columns of type "authRefreshTokenTypes_enum". All fields are combined with logical 'AND'. */
export type AuthRefreshTokenTypes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  _in?: InputMaybe<Array<AuthRefreshTokenTypes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  _nin?: InputMaybe<Array<AuthRefreshTokenTypes_Enum>>;
};

/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRefreshTokens = {
  __typename?: 'authRefreshTokens';
  createdAt: Scalars['timestamptz']['output'];
  expiresAt: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['jsonb']['output']>;
  type: AuthRefreshTokenTypes_Enum;
  /** An object relationship */
  user: Users;
  userId: Scalars['uuid']['output'];
};

/** User refresh tokens. Hasura auth uses them to rotate new access tokens as long as the refresh token is not expired. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type AuthRefreshTokensMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<AuthRefreshTokens_Max_Order_By>;
  min?: InputMaybe<AuthRefreshTokens_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type AuthRefreshTokens_Bool_Exp = {
  _and?: InputMaybe<Array<AuthRefreshTokens_Bool_Exp>>;
  _not?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  _or?: InputMaybe<Array<AuthRefreshTokens_Bool_Exp>>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiresAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userId?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Max_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type AuthRefreshTokens_Min_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type AuthRefreshTokens_Mutation_Response = {
  __typename?: 'authRefreshTokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<AuthRefreshTokens>;
};

/** Ordering options when selecting data from "auth.refresh_tokens". */
export type AuthRefreshTokens_Order_By = {
  createdAt?: InputMaybe<Order_By>;
  expiresAt?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userId?: InputMaybe<Order_By>;
};

/** select columns of table "auth.refresh_tokens" */
export enum AuthRefreshTokens_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'userId',
}

/** Streaming cursor of the table "authRefreshTokens" */
export type AuthRefreshTokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: AuthRefreshTokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type AuthRefreshTokens_Stream_Cursor_Value_Input = {
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  expiresAt?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<AuthRefreshTokenTypes_Enum>;
  userId?: InputMaybe<Scalars['uuid']['input']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** columns and relationships of "categories" */
export type Categories = {
  __typename?: 'categories';
  /** An array relationship */
  control_categories: Array<Control_Categories>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "categories" */
export type CategoriesControl_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Control_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Categories_Order_By>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

/** order by aggregate values of table "categories" */
export type Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Categories_Max_Order_By>;
  min?: InputMaybe<Categories_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "categories". All fields are combined with a logical 'AND'. */
export type Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Categories_Bool_Exp>>;
  _not?: InputMaybe<Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Categories_Bool_Exp>>;
  control_categories?: InputMaybe<Control_Categories_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "categories" */
export enum Categories_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  CategoriesNameOrganizationIdKey = 'categories_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  CategoriesPkey = 'categories_pkey',
}

/** input type for inserting data into table "categories" */
export type Categories_Insert_Input = {
  control_categories?: InputMaybe<Control_Categories_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "categories" */
export type Categories_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "categories" */
export type Categories_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "categories" */
export type Categories_Mutation_Response = {
  __typename?: 'categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Categories>;
};

/** input type for inserting object relation for remote table "categories" */
export type Categories_Obj_Rel_Insert_Input = {
  data: Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** on_conflict condition type for table "categories" */
export type Categories_On_Conflict = {
  constraint: Categories_Constraint;
  update_columns?: Array<Categories_Update_Column>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "categories". */
export type Categories_Order_By = {
  control_categories_aggregate?: InputMaybe<Control_Categories_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: categories */
export type Categories_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "categories" */
export enum Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "categories" */
export type Categories_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "categories" */
export type Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Categories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "categories" */
export enum Categories_Update_Column {
  /** column name */
  Name = 'name',
}

export type Categories_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Categories_Set_Input>;
  /** filter the rows which have to be updated */
  where: Categories_Bool_Exp;
};

/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']['input']>;
  _gt?: InputMaybe<Scalars['citext']['input']>;
  _gte?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['citext']['input']>;
  _in?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['citext']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['citext']['input']>;
  _lt?: InputMaybe<Scalars['citext']['input']>;
  _lte?: InputMaybe<Scalars['citext']['input']>;
  _neq?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['citext']['input']>;
  _nin?: InputMaybe<Array<Scalars['citext']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['citext']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['citext']['input']>;
};

/** Answers to a given Client Questionnaire Question */
export type Client_Questionnaire_Answers = {
  __typename?: 'client_questionnaire_answers';
  answer: Scalars['String']['output'];
  /** An object relationship */
  client_questionnaire_question: Client_Questionnaire_Questions;
  client_questionnaire_question_id: Scalars['uuid']['output'];
  comment: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  is_ai_generated: Scalars['Boolean']['output'];
  updated_by?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** order by aggregate values of table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Questionnaire_Answers_Max_Order_By>;
  min?: InputMaybe<Client_Questionnaire_Answers_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "client_questionnaire_answers". All fields are combined with a logical 'AND'. */
export type Client_Questionnaire_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Questionnaire_Answers_Bool_Exp>>;
  _not?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Questionnaire_Answers_Bool_Exp>>;
  answer?: InputMaybe<String_Comparison_Exp>;
  client_questionnaire_question?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  client_questionnaire_question_id?: InputMaybe<Uuid_Comparison_Exp>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_ai_generated?: InputMaybe<Boolean_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "client_questionnaire_answers" */
export enum Client_Questionnaire_Answers_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientQuestionnaireAnswersPkey = 'client_questionnaire_answers_pkey',
  /** unique or primary key constraint on columns "client_questionnaire_question_id", "created_at" */
  ClientQuestionnaireAnswersQuestionIdCreatedAtIdx = 'client_questionnaire_answers_question_id_created_at_idx',
}

/** input type for inserting data into table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Insert_Input = {
  answer?: InputMaybe<Scalars['String']['input']>;
  client_questionnaire_question_id?: InputMaybe<Scalars['uuid']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  is_ai_generated?: InputMaybe<Scalars['Boolean']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Max_Order_By = {
  answer?: InputMaybe<Order_By>;
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Min_Order_By = {
  answer?: InputMaybe<Order_By>;
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_Mutation_Response = {
  __typename?: 'client_questionnaire_answers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Questionnaire_Answers>;
};

/** on_conflict condition type for table "client_questionnaire_answers" */
export type Client_Questionnaire_Answers_On_Conflict = {
  constraint: Client_Questionnaire_Answers_Constraint;
  update_columns?: Array<Client_Questionnaire_Answers_Update_Column>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

/** Ordering options when selecting data from "client_questionnaire_answers". */
export type Client_Questionnaire_Answers_Order_By = {
  answer?: InputMaybe<Order_By>;
  client_questionnaire_question?: InputMaybe<Client_Questionnaire_Questions_Order_By>;
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_ai_generated?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** select columns of table "client_questionnaire_answers" */
export enum Client_Questionnaire_Answers_Select_Column {
  /** column name */
  Answer = 'answer',
  /** column name */
  ClientQuestionnaireQuestionId = 'client_questionnaire_question_id',
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsAiGenerated = 'is_ai_generated',
  /** column name */
  UpdatedBy = 'updated_by',
}

/** placeholder for update columns of table "client_questionnaire_answers" (current role has no relevant permissions) */
export enum Client_Questionnaire_Answers_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export enum Client_Questionnaire_Question_Status_Enum {
  Approved = 'Approved',
  Pending = 'Pending',
  Processing = 'Processing',
  ProcessingFailed = 'Processing_failed',
  ReadyForReview = 'Ready_for_review',
}

/** Boolean expression to compare columns of type "client_questionnaire_question_status_enum". All fields are combined with logical 'AND'. */
export type Client_Questionnaire_Question_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Client_Questionnaire_Question_Status_Enum>;
  _in?: InputMaybe<Array<Client_Questionnaire_Question_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Client_Questionnaire_Question_Status_Enum>;
  _nin?: InputMaybe<Array<Client_Questionnaire_Question_Status_Enum>>;
};

/** Set of questions extracted from a given Client Questionnaire */
export type Client_Questionnaire_Questions = {
  __typename?: 'client_questionnaire_questions';
  /** An object relationship */
  client_questionnaire: Client_Questionnaires;
  /** An array relationship */
  client_questionnaire_answers: Array<Client_Questionnaire_Answers>;
  client_questionnaire_id: Scalars['uuid']['output'];
  /** An array relationship */
  comments: Array<Comments>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  metadata?: Maybe<Scalars['ClientQquestionMetadata']['output']>;
  question: Scalars['String']['output'];
  status: Client_Questionnaire_Question_Status_Enum;
  updated_at: Scalars['timestamptz']['output'];
};

/** Set of questions extracted from a given Client Questionnaire */
export type Client_Questionnaire_QuestionsClient_Questionnaire_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answers_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

/** Set of questions extracted from a given Client Questionnaire */
export type Client_Questionnaire_QuestionsCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Set of questions extracted from a given Client Questionnaire */
export type Client_Questionnaire_QuestionsMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Aggregate = {
  __typename?: 'client_questionnaire_questions_aggregate';
  aggregate?: Maybe<Client_Questionnaire_Questions_Aggregate_Fields>;
  nodes: Array<Client_Questionnaire_Questions>;
};

export type Client_Questionnaire_Questions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Client_Questionnaire_Questions_Aggregate_Bool_Exp_Count>;
};

export type Client_Questionnaire_Questions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Aggregate_Fields = {
  __typename?: 'client_questionnaire_questions_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Client_Questionnaire_Questions_Max_Fields>;
  min?: Maybe<Client_Questionnaire_Questions_Min_Fields>;
};

/** aggregate fields of "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Questionnaire_Questions_Max_Order_By>;
  min?: InputMaybe<Client_Questionnaire_Questions_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "client_questionnaire_questions". All fields are combined with a logical 'AND'. */
export type Client_Questionnaire_Questions_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Questionnaire_Questions_Bool_Exp>>;
  _not?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Questionnaire_Questions_Bool_Exp>>;
  client_questionnaire?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  client_questionnaire_answers?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
  client_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  comments?: InputMaybe<Comments_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  question?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Client_Questionnaire_Question_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type Client_Questionnaire_Questions_Max_Fields = {
  __typename?: 'client_questionnaire_questions_max_fields';
  client_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Max_Order_By = {
  client_questionnaire_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Questionnaire_Questions_Min_Fields = {
  __typename?: 'client_questionnaire_questions_min_fields';
  client_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  question?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Min_Order_By = {
  client_questionnaire_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Mutation_Response = {
  __typename?: 'client_questionnaire_questions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Questionnaire_Questions>;
};

/** Ordering options when selecting data from "client_questionnaire_questions". */
export type Client_Questionnaire_Questions_Order_By = {
  client_questionnaire?: InputMaybe<Client_Questionnaires_Order_By>;
  client_questionnaire_answers_aggregate?: InputMaybe<Client_Questionnaire_Answers_Aggregate_Order_By>;
  client_questionnaire_id?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  question?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_questionnaire_questions */
export type Client_Questionnaire_Questions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "client_questionnaire_questions" */
export enum Client_Questionnaire_Questions_Select_Column {
  /** column name */
  ClientQuestionnaireId = 'client_questionnaire_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Question = 'question',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Set_Input = {
  status?: InputMaybe<Client_Questionnaire_Question_Status_Enum>;
};

/** Streaming cursor of the table "client_questionnaire_questions" */
export type Client_Questionnaire_Questions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Questionnaire_Questions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Questionnaire_Questions_Stream_Cursor_Value_Input = {
  client_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  question?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Client_Questionnaire_Question_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

export type Client_Questionnaire_Questions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Questionnaire_Questions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Questionnaire_Questions_Bool_Exp;
};

export enum Client_Questionnaire_Status_Enum {
  Complete = 'Complete',
  Expiring = 'Expiring',
  ExtractingCompleted = 'Extracting_completed',
  ExtractingQuestions = 'Extracting_questions',
  ExtractionFailed = 'Extraction_failed',
  InProgress = 'In_progress',
  Overdue = 'Overdue',
  Pending = 'Pending',
  ReadyForExtraction = 'Ready_for_extraction',
}

/** Boolean expression to compare columns of type "client_questionnaire_status_enum". All fields are combined with logical 'AND'. */
export type Client_Questionnaire_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Client_Questionnaire_Status_Enum>;
  _in?: InputMaybe<Array<Client_Questionnaire_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Client_Questionnaire_Status_Enum>;
  _nin?: InputMaybe<Array<Client_Questionnaire_Status_Enum>>;
};

/** Set of Security Questionnaires sent to businessess using Complyance for answering */
export type Client_Questionnaires = {
  __typename?: 'client_questionnaires';
  /** An array relationship */
  client_questionnaire_questions: Array<Client_Questionnaire_Questions>;
  /** An aggregate relationship */
  client_questionnaire_questions_aggregate: Client_Questionnaire_Questions_Aggregate;
  company: Scalars['String']['output'];
  created_at: Scalars['timestamp']['output'];
  description?: Maybe<Scalars['String']['output']>;
  due_date: Scalars['timestamptz']['output'];
  /** An object relationship */
  export_file?: Maybe<Files>;
  export_file_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  status: Client_Questionnaire_Status_Enum;
  updated_at: Scalars['timestamptz']['output'];
};

/** Set of Security Questionnaires sent to businessess using Complyance for answering */
export type Client_QuestionnairesClient_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

/** Set of Security Questionnaires sent to businessess using Complyance for answering */
export type Client_QuestionnairesClient_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

/** order by aggregate values of table "client_questionnaires" */
export type Client_Questionnaires_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Client_Questionnaires_Max_Order_By>;
  min?: InputMaybe<Client_Questionnaires_Min_Order_By>;
};

/** input type for inserting array relation for remote table "client_questionnaires" */
export type Client_Questionnaires_Arr_Rel_Insert_Input = {
  data: Array<Client_Questionnaires_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaires_On_Conflict>;
};

/** Boolean expression to filter rows from the table "client_questionnaires". All fields are combined with a logical 'AND'. */
export type Client_Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Client_Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Client_Questionnaires_Bool_Exp>>;
  client_questionnaire_questions?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  client_questionnaire_questions_aggregate?: InputMaybe<Client_Questionnaire_Questions_Aggregate_Bool_Exp>;
  company?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  export_file?: InputMaybe<Files_Bool_Exp>;
  export_file_id?: InputMaybe<Uuid_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Client_Questionnaire_Status_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_questionnaires" */
export enum Client_Questionnaires_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  ClientQuestionnairesFileIdKey = 'client_questionnaires_file_id_key',
  /** unique or primary key constraint on columns "id" */
  ClientQuestionnairesPkey = 'client_questionnaires_pkey',
}

/** input type for inserting data into table "client_questionnaires" */
export type Client_Questionnaires_Insert_Input = {
  company?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  export_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  export_file_id?: InputMaybe<Scalars['uuid']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "client_questionnaires" */
export type Client_Questionnaires_Max_Order_By = {
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  export_file_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "client_questionnaires" */
export type Client_Questionnaires_Min_Order_By = {
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  export_file_id?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "client_questionnaires" */
export type Client_Questionnaires_Mutation_Response = {
  __typename?: 'client_questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Questionnaires>;
};

/** input type for inserting object relation for remote table "client_questionnaires" */
export type Client_Questionnaires_Obj_Rel_Insert_Input = {
  data: Client_Questionnaires_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Client_Questionnaires_On_Conflict>;
};

/** on_conflict condition type for table "client_questionnaires" */
export type Client_Questionnaires_On_Conflict = {
  constraint: Client_Questionnaires_Constraint;
  update_columns?: Array<Client_Questionnaires_Update_Column>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "client_questionnaires". */
export type Client_Questionnaires_Order_By = {
  client_questionnaire_questions_aggregate?: InputMaybe<Client_Questionnaire_Questions_Aggregate_Order_By>;
  company?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  export_file?: InputMaybe<Files_Order_By>;
  export_file_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: client_questionnaires */
export type Client_Questionnaires_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "client_questionnaires" */
export enum Client_Questionnaires_Select_Column {
  /** column name */
  Company = 'company',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  ExportFileId = 'export_file_id',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "client_questionnaires" */
export type Client_Questionnaires_Set_Input = {
  company?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "client_questionnaires" */
export type Client_Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Questionnaires_Stream_Cursor_Value_Input = {
  company?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  export_file_id?: InputMaybe<Scalars['uuid']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Client_Questionnaire_Status_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "client_questionnaires" */
export enum Client_Questionnaires_Update_Column {
  /** column name */
  Company = 'company',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
}

export type Client_Questionnaires_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Client_Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Questionnaires_Bool_Exp;
};

/** columns and relationships of "comment_uploads" */
export type Comment_Uploads = {
  __typename?: 'comment_uploads';
  comment_id: Scalars['uuid']['output'];
  /** An object relationship */
  comment_uploads_comment: Comments;
  /** An object relationship */
  comment_uploads_file: Files;
  created_at: Scalars['timestamptz']['output'];
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "comment_uploads" */
export type Comment_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comment_Uploads_Max_Order_By>;
  min?: InputMaybe<Comment_Uploads_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comment_uploads" */
export type Comment_Uploads_Arr_Rel_Insert_Input = {
  data: Array<Comment_Uploads_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_Uploads_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comment_uploads". All fields are combined with a logical 'AND'. */
export type Comment_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Comment_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Comment_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Uploads_Bool_Exp>>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  comment_uploads_comment?: InputMaybe<Comments_Bool_Exp>;
  comment_uploads_file?: InputMaybe<Files_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_uploads" */
export enum Comment_Uploads_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  CommentUploadsFileIdKey = 'comment_uploads_file_id_key',
  /** unique or primary key constraint on columns "id" */
  CommentUploadsPkey = 'comment_uploads_pkey',
}

/** input type for inserting data into table "comment_uploads" */
export type Comment_Uploads_Insert_Input = {
  comment_id?: InputMaybe<Scalars['uuid']['input']>;
  comment_uploads_comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  comment_uploads_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "comment_uploads" */
export type Comment_Uploads_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "comment_uploads" */
export type Comment_Uploads_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comment_uploads" */
export type Comment_Uploads_Mutation_Response = {
  __typename?: 'comment_uploads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment_Uploads>;
};

/** input type for inserting object relation for remote table "comment_uploads" */
export type Comment_Uploads_Obj_Rel_Insert_Input = {
  data: Comment_Uploads_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_Uploads_On_Conflict>;
};

/** on_conflict condition type for table "comment_uploads" */
export type Comment_Uploads_On_Conflict = {
  constraint: Comment_Uploads_Constraint;
  update_columns?: Array<Comment_Uploads_Update_Column>;
  where?: InputMaybe<Comment_Uploads_Bool_Exp>;
};

/** Ordering options when selecting data from "comment_uploads". */
export type Comment_Uploads_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  comment_uploads_comment?: InputMaybe<Comments_Order_By>;
  comment_uploads_file?: InputMaybe<Files_Order_By>;
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** select columns of table "comment_uploads" */
export enum Comment_Uploads_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
}

/** placeholder for update columns of table "comment_uploads" (current role has no relevant permissions) */
export enum Comment_Uploads_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "comments" */
export type Comments = {
  __typename?: 'comments';
  /** An object relationship */
  client_questionnaire_question?: Maybe<Client_Questionnaire_Questions>;
  client_questionnaire_question_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  comments_comment_uploads: Array<Comment_Uploads>;
  /** An object relationship */
  comments_control?: Maybe<Controls>;
  /** An array relationship */
  comments_notifications: Array<Notifications>;
  /** An object relationship */
  comments_policy?: Maybe<Policies>;
  /** An object relationship */
  comments_risk?: Maybe<Risks>;
  /** An object relationship */
  comments_task?: Maybe<Tasks>;
  /** An object relationship */
  comments_user: Users;
  /** An object relationship */
  comments_vendor?: Maybe<Vendors>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  policy_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  questionnaire?: Maybe<Questionnaires>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  text: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  user_id: Scalars['uuid']['output'];
  vendor_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  vendor_questionnaire?: Maybe<Vendor_Questionnaires>;
};

/** columns and relationships of "comments" */
export type CommentsComments_Comment_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comment_Uploads_Order_By>>;
  where?: InputMaybe<Comment_Uploads_Bool_Exp>;
};

/** columns and relationships of "comments" */
export type CommentsComments_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** order by aggregate values of table "comments" */
export type Comments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comments_Max_Order_By>;
  min?: InputMaybe<Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comments" */
export type Comments_Arr_Rel_Insert_Input = {
  data: Array<Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comments". All fields are combined with a logical 'AND'. */
export type Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Comments_Bool_Exp>>;
  _not?: InputMaybe<Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Comments_Bool_Exp>>;
  client_questionnaire_question?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
  client_questionnaire_question_id?: InputMaybe<Uuid_Comparison_Exp>;
  comments_comment_uploads?: InputMaybe<Comment_Uploads_Bool_Exp>;
  comments_control?: InputMaybe<Controls_Bool_Exp>;
  comments_notifications?: InputMaybe<Notifications_Bool_Exp>;
  comments_policy?: InputMaybe<Policies_Bool_Exp>;
  comments_risk?: InputMaybe<Risks_Bool_Exp>;
  comments_task?: InputMaybe<Tasks_Bool_Exp>;
  comments_user?: InputMaybe<Users_Bool_Exp>;
  comments_vendor?: InputMaybe<Vendors_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  text?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** unique or primary key constraints on table "comments" */
export enum Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentsPkey = 'comments_pkey',
}

/** input type for inserting data into table "comments" */
export type Comments_Insert_Input = {
  client_questionnaire_question_id?: InputMaybe<Scalars['uuid']['input']>;
  comments_comment_uploads?: InputMaybe<Comment_Uploads_Arr_Rel_Insert_Input>;
  comments_control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  comments_policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  comments_risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  comments_task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  comments_vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "comments" */
export type Comments_Max_Order_By = {
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "comments" */
export type Comments_Min_Order_By = {
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comments" */
export type Comments_Mutation_Response = {
  __typename?: 'comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Comments>;
};

/** input type for inserting object relation for remote table "comments" */
export type Comments_Obj_Rel_Insert_Input = {
  data: Comments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** on_conflict condition type for table "comments" */
export type Comments_On_Conflict = {
  constraint: Comments_Constraint;
  update_columns?: Array<Comments_Update_Column>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "comments". */
export type Comments_Order_By = {
  client_questionnaire_question?: InputMaybe<Client_Questionnaire_Questions_Order_By>;
  client_questionnaire_question_id?: InputMaybe<Order_By>;
  comments_comment_uploads_aggregate?: InputMaybe<Comment_Uploads_Aggregate_Order_By>;
  comments_control?: InputMaybe<Controls_Order_By>;
  comments_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  comments_policy?: InputMaybe<Policies_Order_By>;
  comments_risk?: InputMaybe<Risks_Order_By>;
  comments_task?: InputMaybe<Tasks_Order_By>;
  comments_user?: InputMaybe<Users_Order_By>;
  comments_vendor?: InputMaybe<Vendors_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  text?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
};

/** primary key columns input for table: comments */
export type Comments_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "comments" */
export enum Comments_Select_Column {
  /** column name */
  ClientQuestionnaireQuestionId = 'client_questionnaire_question_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Text = 'text',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  VendorId = 'vendor_id',
}

/** input type for updating data in table "comments" */
export type Comments_Set_Input = {
  text?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "comments" */
export enum Comments_Update_Column {
  /** column name */
  Text = 'text',
}

export type Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Comments_Bool_Exp;
};

/** Set of assessments resulting for given criteria during the execution of AI review runs */
export type Control_Ai_Review_Assessments = {
  __typename?: 'control_ai_review_assessments';
  assessment: Scalars['String']['output'];
  control_ai_review_criteria_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  created_at: Scalars['timestamptz']['output'];
  criteria_content: Scalars['String']['output'];
  criteria_heading: Scalars['String']['output'];
  criteria_suggestion: Scalars['String']['output'];
  high_confidence: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  run_id: Scalars['uuid']['output'];
  template_control_ai_review_criteria_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** Set of assessments resulting for given criteria during the execution of AI review runs */
export type Control_Ai_Review_AssessmentsControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "control_ai_review_assessments". All fields are combined with a logical 'AND'. */
export type Control_Ai_Review_Assessments_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Ai_Review_Assessments_Bool_Exp>>;
  _not?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Ai_Review_Assessments_Bool_Exp>>;
  assessment?: InputMaybe<String_Comparison_Exp>;
  control_ai_review_criteria_id?: InputMaybe<Uuid_Comparison_Exp>;
  control_findings?: InputMaybe<Control_Findings_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  criteria_content?: InputMaybe<String_Comparison_Exp>;
  criteria_heading?: InputMaybe<String_Comparison_Exp>;
  criteria_suggestion?: InputMaybe<String_Comparison_Exp>;
  high_confidence?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  run_id?: InputMaybe<Uuid_Comparison_Exp>;
  template_control_ai_review_criteria_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** Ordering options when selecting data from "control_ai_review_assessments". */
export type Control_Ai_Review_Assessments_Order_By = {
  assessment?: InputMaybe<Order_By>;
  control_ai_review_criteria_id?: InputMaybe<Order_By>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria_content?: InputMaybe<Order_By>;
  criteria_heading?: InputMaybe<Order_By>;
  criteria_suggestion?: InputMaybe<Order_By>;
  high_confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  run_id?: InputMaybe<Order_By>;
  template_control_ai_review_criteria_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** columns and relationships of "control_categories" */
export type Control_Categories = {
  __typename?: 'control_categories';
  /** An object relationship */
  category: Categories;
  category_id: Scalars['uuid']['output'];
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "control_categories" */
export type Control_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Categories_Max_Order_By>;
  min?: InputMaybe<Control_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_categories" */
export type Control_Categories_Arr_Rel_Insert_Input = {
  data: Array<Control_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_categories". All fields are combined with a logical 'AND'. */
export type Control_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Categories_Bool_Exp>>;
  _not?: InputMaybe<Control_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Categories_Bool_Exp>>;
  category?: InputMaybe<Categories_Bool_Exp>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_categories" */
export enum Control_Categories_Constraint {
  /** unique or primary key constraint on columns "control_id", "category_id" */
  ControlCategoriesPkey = 'control_categories_pkey',
}

/** input type for inserting data into table "control_categories" */
export type Control_Categories_Insert_Input = {
  category?: InputMaybe<Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "control_categories" */
export type Control_Categories_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "control_categories" */
export type Control_Categories_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_categories" */
export type Control_Categories_Mutation_Response = {
  __typename?: 'control_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Categories>;
};

/** on_conflict condition type for table "control_categories" */
export type Control_Categories_On_Conflict = {
  constraint: Control_Categories_Constraint;
  update_columns?: Array<Control_Categories_Update_Column>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "control_categories". */
export type Control_Categories_Order_By = {
  category?: InputMaybe<Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
};

/** select columns of table "control_categories" */
export enum Control_Categories_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  ControlId = 'control_id',
}

/** placeholder for update columns of table "control_categories" (current role has no relevant permissions) */
export enum Control_Categories_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "control_criteria" */
export type Control_Criteria = {
  __typename?: 'control_criteria';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  /** An object relationship */
  criteria: Criteria;
  criteria_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "control_criteria" */
export type Control_Criteria_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Criteria_Max_Order_By>;
  min?: InputMaybe<Control_Criteria_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_criteria" */
export type Control_Criteria_Arr_Rel_Insert_Input = {
  data: Array<Control_Criteria_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Criteria_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_criteria". All fields are combined with a logical 'AND'. */
export type Control_Criteria_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Criteria_Bool_Exp>>;
  _not?: InputMaybe<Control_Criteria_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Criteria_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  criteria?: InputMaybe<Criteria_Bool_Exp>;
  criteria_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_criteria" */
export enum Control_Criteria_Constraint {
  /** unique or primary key constraint on columns "criteria_id", "control_id" */
  ControlCriteriaPkey = 'control_criteria_pkey',
}

/** input type for inserting data into table "control_criteria" */
export type Control_Criteria_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  criteria?: InputMaybe<Criteria_Obj_Rel_Insert_Input>;
  criteria_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "control_criteria" */
export type Control_Criteria_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  criteria_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "control_criteria" */
export type Control_Criteria_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  criteria_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_criteria" */
export type Control_Criteria_Mutation_Response = {
  __typename?: 'control_criteria_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Criteria>;
};

/** on_conflict condition type for table "control_criteria" */
export type Control_Criteria_On_Conflict = {
  constraint: Control_Criteria_Constraint;
  update_columns?: Array<Control_Criteria_Update_Column>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

/** Ordering options when selecting data from "control_criteria". */
export type Control_Criteria_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  criteria?: InputMaybe<Criteria_Order_By>;
  criteria_id?: InputMaybe<Order_By>;
};

/** select columns of table "control_criteria" */
export enum Control_Criteria_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CriteriaId = 'criteria_id',
}

/** placeholder for update columns of table "control_criteria" (current role has no relevant permissions) */
export enum Control_Criteria_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "control_evidences" */
export type Control_Evidences = {
  __typename?: 'control_evidences';
  /** An object relationship */
  control: Controls;
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  control_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  status: Scalars['ControlEvidenceStatus']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "control_evidences" */
export type Control_EvidencesControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** order by aggregate values of table "control_evidences" */
export type Control_Evidences_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Evidences_Max_Order_By>;
  min?: InputMaybe<Control_Evidences_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_evidences" */
export type Control_Evidences_Arr_Rel_Insert_Input = {
  data: Array<Control_Evidences_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Evidences_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_evidences". All fields are combined with a logical 'AND'. */
export type Control_Evidences_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Evidences_Bool_Exp>>;
  _not?: InputMaybe<Control_Evidences_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Evidences_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_findings?: InputMaybe<Control_Findings_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_evidences" */
export enum Control_Evidences_Constraint {
  /** unique or primary key constraint on columns "control_id", "evidence_id" */
  ControlEvidencesControlIdEvidenceIdKey = 'control_evidences_control_id_evidence_id_key',
  /** unique or primary key constraint on columns "id" */
  ControlEvidencesPrimaryKey = 'control_evidences_primary_key',
}

/** input type for inserting data into table "control_evidences" */
export type Control_Evidences_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "control_evidences" */
export type Control_Evidences_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "control_evidences" */
export type Control_Evidences_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_evidences" */
export type Control_Evidences_Mutation_Response = {
  __typename?: 'control_evidences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Evidences>;
};

/** on_conflict condition type for table "control_evidences" */
export type Control_Evidences_On_Conflict = {
  constraint: Control_Evidences_Constraint;
  update_columns?: Array<Control_Evidences_Update_Column>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

/** Ordering options when selecting data from "control_evidences". */
export type Control_Evidences_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "control_evidences" */
export enum Control_Evidences_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** placeholder for update columns of table "control_evidences" (current role has no relevant permissions) */
export enum Control_Evidences_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "control_findings" */
export type Control_Findings = {
  __typename?: 'control_findings';
  cause: Finding_Causes_Enum;
  /** An object relationship */
  control: Controls;
  /** An object relationship */
  control_ai_review_assessment?: Maybe<Control_Ai_Review_Assessments>;
  control_ai_review_assessment_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  control_evidence?: Maybe<Control_Evidences>;
  control_evidence_id?: Maybe<Scalars['uuid']['output']>;
  control_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  evidence_policy?: Maybe<Evidence_Policies>;
  evidence_policy_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  ignored_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  integration_run?: Maybe<Integration_Runs>;
  integration_run_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  manual_finding?: Maybe<Manual_Findings>;
  manual_finding_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  risk?: Maybe<Risks>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  type: Finding_Types_Enum;
  updated_at: Scalars['timestamptz']['output'];
};

/** order by aggregate values of table "control_findings" */
export type Control_Findings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Findings_Max_Order_By>;
  min?: InputMaybe<Control_Findings_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "control_findings". All fields are combined with a logical 'AND'. */
export type Control_Findings_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Findings_Bool_Exp>>;
  _not?: InputMaybe<Control_Findings_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Findings_Bool_Exp>>;
  cause?: InputMaybe<Finding_Causes_Enum_Comparison_Exp>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_ai_review_assessment?: InputMaybe<Control_Ai_Review_Assessments_Bool_Exp>;
  control_ai_review_assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
  control_evidence?: InputMaybe<Control_Evidences_Bool_Exp>;
  control_evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence_policy?: InputMaybe<Evidence_Policies_Bool_Exp>;
  evidence_policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ignored_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  integration_run?: InputMaybe<Integration_Runs_Bool_Exp>;
  integration_run_id?: InputMaybe<Uuid_Comparison_Exp>;
  manual_finding?: InputMaybe<Manual_Findings_Bool_Exp>;
  manual_finding_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Finding_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "control_findings" */
export type Control_Findings_Max_Order_By = {
  control_ai_review_assessment_id?: InputMaybe<Order_By>;
  control_evidence_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_policy_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  integration_run_id?: InputMaybe<Order_By>;
  manual_finding_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "control_findings" */
export type Control_Findings_Min_Order_By = {
  control_ai_review_assessment_id?: InputMaybe<Order_By>;
  control_evidence_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_policy_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  integration_run_id?: InputMaybe<Order_By>;
  manual_finding_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_findings" */
export type Control_Findings_Mutation_Response = {
  __typename?: 'control_findings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Findings>;
};

/** Ordering options when selecting data from "control_findings". */
export type Control_Findings_Order_By = {
  cause?: InputMaybe<Order_By>;
  control?: InputMaybe<Controls_Order_By>;
  control_ai_review_assessment?: InputMaybe<Control_Ai_Review_Assessments_Order_By>;
  control_ai_review_assessment_id?: InputMaybe<Order_By>;
  control_evidence?: InputMaybe<Control_Evidences_Order_By>;
  control_evidence_id?: InputMaybe<Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  evidence_policy?: InputMaybe<Evidence_Policies_Order_By>;
  evidence_policy_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  integration_run?: InputMaybe<Integration_Runs_Order_By>;
  integration_run_id?: InputMaybe<Order_By>;
  manual_finding?: InputMaybe<Manual_Findings_Order_By>;
  manual_finding_id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: control_findings */
export type Control_Findings_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "control_findings" */
export enum Control_Findings_Select_Column {
  /** column name */
  Cause = 'cause',
  /** column name */
  ControlAiReviewAssessmentId = 'control_ai_review_assessment_id',
  /** column name */
  ControlEvidenceId = 'control_evidence_id',
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidencePolicyId = 'evidence_policy_id',
  /** column name */
  Id = 'id',
  /** column name */
  IgnoredAt = 'ignored_at',
  /** column name */
  IntegrationRunId = 'integration_run_id',
  /** column name */
  ManualFindingId = 'manual_finding_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "control_findings" */
export type Control_Findings_Set_Input = {
  ignored_at?: InputMaybe<Scalars['timestamptz']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type Control_Findings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Control_Findings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Control_Findings_Bool_Exp;
};

export enum Control_Frequencies_Enum {
  Annual = 'Annual',
  Biannual = 'Biannual',
  Daily = 'Daily',
  Indefinite = 'Indefinite',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  ThreeYears = 'Three_years',
  TwoYears = 'Two_years',
  Weekly = 'Weekly',
}

/** Boolean expression to compare columns of type "control_frequencies_enum". All fields are combined with logical 'AND'. */
export type Control_Frequencies_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Control_Frequencies_Enum>;
  _in?: InputMaybe<Array<Control_Frequencies_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Control_Frequencies_Enum>;
  _nin?: InputMaybe<Array<Control_Frequencies_Enum>>;
};

/** columns and relationships of "control_groups" */
export type Control_Groups = {
  __typename?: 'control_groups';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  /** An object relationship */
  group: Groups;
  group_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "control_groups" */
export type Control_Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Groups_Max_Order_By>;
  min?: InputMaybe<Control_Groups_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_groups" */
export type Control_Groups_Arr_Rel_Insert_Input = {
  data: Array<Control_Groups_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Groups_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_groups". All fields are combined with a logical 'AND'. */
export type Control_Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Groups_Bool_Exp>>;
  _not?: InputMaybe<Control_Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Groups_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_groups" */
export enum Control_Groups_Constraint {
  /** unique or primary key constraint on columns "control_id", "group_id" */
  ControlGroupsPkey = 'control_groups_pkey',
}

/** input type for inserting data into table "control_groups" */
export type Control_Groups_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "control_groups" */
export type Control_Groups_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "control_groups" */
export type Control_Groups_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_groups" */
export type Control_Groups_Mutation_Response = {
  __typename?: 'control_groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Groups>;
};

/** on_conflict condition type for table "control_groups" */
export type Control_Groups_On_Conflict = {
  constraint: Control_Groups_Constraint;
  update_columns?: Array<Control_Groups_Update_Column>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "control_groups". */
export type Control_Groups_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
};

/** select columns of table "control_groups" */
export enum Control_Groups_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  GroupId = 'group_id',
}

/** placeholder for update columns of table "control_groups" (current role has no relevant permissions) */
export enum Control_Groups_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export enum Control_Priorities_Enum {
  P1 = 'P1',
  P2 = 'P2',
  P3 = 'P3',
  P4 = 'P4',
  P5 = 'P5',
}

/** Boolean expression to compare columns of type "control_priorities_enum". All fields are combined with logical 'AND'. */
export type Control_Priorities_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Control_Priorities_Enum>;
  _in?: InputMaybe<Array<Control_Priorities_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Control_Priorities_Enum>;
  _nin?: InputMaybe<Array<Control_Priorities_Enum>>;
};

/** columns and relationships of "control_tags" */
export type Control_Tags = {
  __typename?: 'control_tags';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  /** An object relationship */
  tag: Tags;
  tag_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "control_tags" */
export type Control_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Tags_Max_Order_By>;
  min?: InputMaybe<Control_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_tags" */
export type Control_Tags_Arr_Rel_Insert_Input = {
  data: Array<Control_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_tags". All fields are combined with a logical 'AND'. */
export type Control_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Tags_Bool_Exp>>;
  _not?: InputMaybe<Control_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Tags_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_tags" */
export enum Control_Tags_Constraint {
  /** unique or primary key constraint on columns "control_id", "tag_id" */
  ControlTagsPkey = 'control_tags_pkey',
}

/** input type for inserting data into table "control_tags" */
export type Control_Tags_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "control_tags" */
export type Control_Tags_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "control_tags" */
export type Control_Tags_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_tags" */
export type Control_Tags_Mutation_Response = {
  __typename?: 'control_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Tags>;
};

/** on_conflict condition type for table "control_tags" */
export type Control_Tags_On_Conflict = {
  constraint: Control_Tags_Constraint;
  update_columns?: Array<Control_Tags_Update_Column>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "control_tags". */
export type Control_Tags_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** select columns of table "control_tags" */
export enum Control_Tags_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  TagId = 'tag_id',
}

/** placeholder for update columns of table "control_tags" (current role has no relevant permissions) */
export enum Control_Tags_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export type Control_Tags_View = {
  __typename?: 'control_tags_view';
  key: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

/** columns and relationships of "control_tasks" */
export type Control_Tasks = {
  __typename?: 'control_tasks';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid']['output'];
};

/** aggregated selection of "control_tasks" */
export type Control_Tasks_Aggregate = {
  __typename?: 'control_tasks_aggregate';
  aggregate?: Maybe<Control_Tasks_Aggregate_Fields>;
  nodes: Array<Control_Tasks>;
};

export type Control_Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Control_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Control_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Control_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "control_tasks" */
export type Control_Tasks_Aggregate_Fields = {
  __typename?: 'control_tasks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Control_Tasks_Max_Fields>;
  min?: Maybe<Control_Tasks_Min_Fields>;
};

/** aggregate fields of "control_tasks" */
export type Control_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "control_tasks" */
export type Control_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Control_Tasks_Max_Order_By>;
  min?: InputMaybe<Control_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "control_tasks" */
export type Control_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Control_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Control_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "control_tasks". All fields are combined with a logical 'AND'. */
export type Control_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Control_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Control_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Control_Tasks_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "control_tasks" */
export enum Control_Tasks_Constraint {
  /** unique or primary key constraint on columns "control_id", "task_id" */
  ControlTasksControlIdTaskIdKey = 'control_tasks_control_id_task_id_key',
  /** unique or primary key constraint on columns "id" */
  ControlTasksPkey = 'control_tasks_pkey',
}

/** input type for inserting data into table "control_tasks" */
export type Control_Tasks_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Control_Tasks_Max_Fields = {
  __typename?: 'control_tasks_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "control_tasks" */
export type Control_Tasks_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Control_Tasks_Min_Fields = {
  __typename?: 'control_tasks_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  task_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "control_tasks" */
export type Control_Tasks_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "control_tasks" */
export type Control_Tasks_Mutation_Response = {
  __typename?: 'control_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Control_Tasks>;
};

/** on_conflict condition type for table "control_tasks" */
export type Control_Tasks_On_Conflict = {
  constraint: Control_Tasks_Constraint;
  update_columns?: Array<Control_Tasks_Update_Column>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "control_tasks". */
export type Control_Tasks_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** select columns of table "control_tasks" */
export enum Control_Tasks_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
}

/** placeholder for update columns of table "control_tasks" (current role has no relevant permissions) */
export enum Control_Tasks_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type Controls = {
  __typename?: 'controls';
  /** An object relationship */
  assignee?: Maybe<Users>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  categories: Array<Control_Categories>;
  /** An array relationship */
  control_evidences: Array<Control_Evidences>;
  /** An array relationship */
  control_tasks: Array<Control_Tasks>;
  /** An aggregate relationship */
  control_tasks_aggregate: Control_Tasks_Aggregate;
  /** An array relationship */
  controls_comments: Array<Comments>;
  /** An array relationship */
  controls_vector_stores: Array<Ai_Controls_Vector_Store>;
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  criterias: Array<Control_Criteria>;
  /** An array relationship */
  custom_tags: Array<Control_Tags>;
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  field_values: Array<Field_Values>;
  /** An array relationship */
  findings: Array<Control_Findings>;
  /** An array relationship */
  frameworks_controls: Array<Frameworks_Controls>;
  frequency: Control_Frequencies_Enum;
  /** An array relationship */
  groups: Array<Control_Groups>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  irrelevant: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  priority?: Maybe<Control_Priorities_Enum>;
  /** An array relationship */
  program_controls: Array<Program_Controls>;
  /** An aggregate relationship */
  program_controls_aggregate: Program_Controls_Aggregate;
  /** An array relationship */
  risk_controls: Array<Risk_Controls>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status: Scalars['ControlStatus']['output'];
  tags: Scalars['ControlTags']['output'];
  /** An object relationship */
  template_control?: Maybe<Template_Controls>;
  template_control_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Control_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Categories_Order_By>>;
  where?: InputMaybe<Control_Categories_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_EvidencesArgs = {
  distinct_on?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Evidences_Order_By>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_TasksArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControl_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControls_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsControls_Vector_StoresArgs = {
  distinct_on?: InputMaybe<Array<Ai_Controls_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Controls_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsCriteriasArgs = {
  distinct_on?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Criteria_Order_By>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsCustom_TagsArgs = {
  distinct_on?: InputMaybe<Array<Control_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tags_Order_By>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsFindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsFrameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Control_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Groups_Order_By>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsProgram_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsProgram_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

/** Stores programs' controls, specific to one organization. Controls are customized here. */
export type ControlsRisk_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Controls_Order_By>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

/** aggregated selection of "controls" */
export type Controls_Aggregate = {
  __typename?: 'controls_aggregate';
  aggregate?: Maybe<Controls_Aggregate_Fields>;
  nodes: Array<Controls>;
};

export type Controls_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Controls_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Controls_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Controls_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Controls_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Controls_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Controls_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Controls_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Controls_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Controls_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Controls_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Controls_Aggregate_Bool_Exp_Var_Samp>;
};

export type Controls_Aggregate_Bool_Exp_Avg = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Bool_And = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Corr = {
  arguments: Controls_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Controls_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Controls_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Controls_Aggregate_Bool_Exp_Max = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Min = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Sum = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Controls_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Controls_Select_Column_Controls_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Controls_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "controls" */
export type Controls_Aggregate_Fields = {
  __typename?: 'controls_aggregate_fields';
  avg?: Maybe<Controls_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Controls_Max_Fields>;
  min?: Maybe<Controls_Min_Fields>;
  stddev?: Maybe<Controls_Stddev_Fields>;
  stddev_pop?: Maybe<Controls_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Controls_Stddev_Samp_Fields>;
  sum?: Maybe<Controls_Sum_Fields>;
  var_pop?: Maybe<Controls_Var_Pop_Fields>;
  var_samp?: Maybe<Controls_Var_Samp_Fields>;
  variance?: Maybe<Controls_Variance_Fields>;
};

/** aggregate fields of "controls" */
export type Controls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "controls" */
export type Controls_Aggregate_Order_By = {
  avg?: InputMaybe<Controls_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Controls_Max_Order_By>;
  min?: InputMaybe<Controls_Min_Order_By>;
  stddev?: InputMaybe<Controls_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Controls_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Controls_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Controls_Sum_Order_By>;
  var_pop?: InputMaybe<Controls_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Controls_Var_Samp_Order_By>;
  variance?: InputMaybe<Controls_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Controls_Avg_Fields = {
  __typename?: 'controls_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "controls" */
export type Controls_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "controls". All fields are combined with a logical 'AND'. */
export type Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Controls_Bool_Exp>>;
  _not?: InputMaybe<Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Controls_Bool_Exp>>;
  assignee?: InputMaybe<Users_Bool_Exp>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  categories?: InputMaybe<Control_Categories_Bool_Exp>;
  control_evidences?: InputMaybe<Control_Evidences_Bool_Exp>;
  control_tasks?: InputMaybe<Control_Tasks_Bool_Exp>;
  control_tasks_aggregate?: InputMaybe<Control_Tasks_Aggregate_Bool_Exp>;
  controls_comments?: InputMaybe<Comments_Bool_Exp>;
  controls_vector_stores?: InputMaybe<Ai_Controls_Vector_Store_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  criterias?: InputMaybe<Control_Criteria_Bool_Exp>;
  custom_tags?: InputMaybe<Control_Tags_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  findings?: InputMaybe<Control_Findings_Bool_Exp>;
  frameworks_controls?: InputMaybe<Frameworks_Controls_Bool_Exp>;
  frequency?: InputMaybe<Control_Frequencies_Enum_Comparison_Exp>;
  groups?: InputMaybe<Control_Groups_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  irrelevant?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  priority?: InputMaybe<Control_Priorities_Enum_Comparison_Exp>;
  program_controls?: InputMaybe<Program_Controls_Bool_Exp>;
  program_controls_aggregate?: InputMaybe<Program_Controls_Aggregate_Bool_Exp>;
  risk_controls?: InputMaybe<Risk_Controls_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  template_control?: InputMaybe<Template_Controls_Bool_Exp>;
  template_control_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "controls" */
export enum Controls_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProgramControlsPkey = 'program_controls_pkey',
}

/** columns and relationships of "controls_history_stats" */
export type Controls_History_Stats = {
  __typename?: 'controls_history_stats';
  /** An object relationship */
  assignee?: Maybe<Users>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  at_risk_count: Scalars['Int']['output'];
  created_at: Scalars['timestamptz']['output'];
  failing_count: Scalars['Int']['output'];
  id: Scalars['uuid']['output'];
  not_relevant_count: Scalars['Int']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  pending_count: Scalars['Int']['output'];
  updated_at: Scalars['timestamptz']['output'];
  valid_count: Scalars['Int']['output'];
};

/** order by aggregate values of table "controls_history_stats" */
export type Controls_History_Stats_Aggregate_Order_By = {
  avg?: InputMaybe<Controls_History_Stats_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Controls_History_Stats_Max_Order_By>;
  min?: InputMaybe<Controls_History_Stats_Min_Order_By>;
  stddev?: InputMaybe<Controls_History_Stats_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Controls_History_Stats_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Controls_History_Stats_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Controls_History_Stats_Sum_Order_By>;
  var_pop?: InputMaybe<Controls_History_Stats_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Controls_History_Stats_Var_Samp_Order_By>;
  variance?: InputMaybe<Controls_History_Stats_Variance_Order_By>;
};

/** order by avg() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Avg_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "controls_history_stats". All fields are combined with a logical 'AND'. */
export type Controls_History_Stats_Bool_Exp = {
  _and?: InputMaybe<Array<Controls_History_Stats_Bool_Exp>>;
  _not?: InputMaybe<Controls_History_Stats_Bool_Exp>;
  _or?: InputMaybe<Array<Controls_History_Stats_Bool_Exp>>;
  assignee?: InputMaybe<Users_Bool_Exp>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  at_risk_count?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  failing_count?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  not_relevant_count?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  pending_count?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  valid_count?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Max_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  at_risk_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Min_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  at_risk_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "controls_history_stats". */
export type Controls_History_Stats_Order_By = {
  assignee?: InputMaybe<Users_Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  at_risk_count?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** select columns of table "controls_history_stats" */
export enum Controls_History_Stats_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  AtRiskCount = 'at_risk_count',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FailingCount = 'failing_count',
  /** column name */
  Id = 'id',
  /** column name */
  NotRelevantCount = 'not_relevant_count',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PendingCount = 'pending_count',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidCount = 'valid_count',
}

/** order by stddev() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Stddev_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Stddev_Pop_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Stddev_Samp_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Sum_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Var_Pop_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Var_Samp_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "controls_history_stats" */
export type Controls_History_Stats_Variance_Order_By = {
  at_risk_count?: InputMaybe<Order_By>;
  failing_count?: InputMaybe<Order_By>;
  not_relevant_count?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  valid_count?: InputMaybe<Order_By>;
};

/** input type for inserting data into table "controls" */
export type Controls_Insert_Input = {
  categories?: InputMaybe<Control_Categories_Arr_Rel_Insert_Input>;
  control_evidences?: InputMaybe<Control_Evidences_Arr_Rel_Insert_Input>;
  control_tasks?: InputMaybe<Control_Tasks_Arr_Rel_Insert_Input>;
  controls_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  criterias?: InputMaybe<Control_Criteria_Arr_Rel_Insert_Input>;
  custom_tags?: InputMaybe<Control_Tags_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  frameworks_controls?: InputMaybe<Frameworks_Controls_Arr_Rel_Insert_Input>;
  frequency?: InputMaybe<Control_Frequencies_Enum>;
  groups?: InputMaybe<Control_Groups_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<Control_Priorities_Enum>;
  program_controls?: InputMaybe<Program_Controls_Arr_Rel_Insert_Input>;
  risk_controls?: InputMaybe<Risk_Controls_Arr_Rel_Insert_Input>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_control_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Controls_Max_Fields = {
  __typename?: 'controls_max_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  template_control_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "controls" */
export type Controls_Max_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Controls_Min_Fields = {
  __typename?: 'controls_min_fields';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  template_control_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "controls" */
export type Controls_Min_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "controls" */
export type Controls_Mutation_Response = {
  __typename?: 'controls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Controls>;
};

/** input type for inserting object relation for remote table "controls" */
export type Controls_Obj_Rel_Insert_Input = {
  data: Controls_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Controls_On_Conflict>;
};

/** on_conflict condition type for table "controls" */
export type Controls_On_Conflict = {
  constraint: Controls_Constraint;
  update_columns?: Array<Controls_Update_Column>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** Ordering options when selecting data from "controls". */
export type Controls_Order_By = {
  assignee?: InputMaybe<Users_Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  categories_aggregate?: InputMaybe<Control_Categories_Aggregate_Order_By>;
  control_evidences_aggregate?: InputMaybe<Control_Evidences_Aggregate_Order_By>;
  control_tasks_aggregate?: InputMaybe<Control_Tasks_Aggregate_Order_By>;
  controls_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  controls_vector_stores_aggregate?: InputMaybe<Ai_Controls_Vector_Store_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  criterias_aggregate?: InputMaybe<Control_Criteria_Aggregate_Order_By>;
  custom_tags_aggregate?: InputMaybe<Control_Tags_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  frameworks_controls_aggregate?: InputMaybe<Frameworks_Controls_Aggregate_Order_By>;
  frequency?: InputMaybe<Order_By>;
  groups_aggregate?: InputMaybe<Control_Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  irrelevant?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  program_controls_aggregate?: InputMaybe<Program_Controls_Aggregate_Order_By>;
  risk_controls_aggregate?: InputMaybe<Risk_Controls_Aggregate_Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_control?: InputMaybe<Template_Controls_Order_By>;
  template_control_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: controls */
export type Controls_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "controls" */
export enum Controls_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Irrelevant = 'irrelevant',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Priority = 'priority',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateControlId = 'template_control_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "controls_aggregate_bool_exp_avg_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_bool_and_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Irrelevant = 'irrelevant',
}

/** select "controls_aggregate_bool_exp_bool_or_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Irrelevant = 'irrelevant',
}

/** select "controls_aggregate_bool_exp_corr_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_max_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_min_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_sum_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "controls_aggregate_bool_exp_var_samp_arguments_columns" columns of table "controls" */
export enum Controls_Select_Column_Controls_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "controls" */
export type Controls_Set_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Control_Frequencies_Enum>;
  irrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Control_Priorities_Enum>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_control_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Controls_Stddev_Fields = {
  __typename?: 'controls_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "controls" */
export type Controls_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Controls_Stddev_Pop_Fields = {
  __typename?: 'controls_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "controls" */
export type Controls_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Controls_Stddev_Samp_Fields = {
  __typename?: 'controls_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "controls" */
export type Controls_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "controls" */
export type Controls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Controls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Controls_Stream_Cursor_Value_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Control_Frequencies_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  irrelevant?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  priority?: InputMaybe<Control_Priorities_Enum>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_control_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Controls_Sum_Fields = {
  __typename?: 'controls_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "controls" */
export type Controls_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "controls" */
export enum Controls_Update_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Irrelevant = 'irrelevant',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateControlId = 'template_control_id',
}

export type Controls_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Controls_Set_Input>;
  /** filter the rows which have to be updated */
  where: Controls_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Controls_Var_Pop_Fields = {
  __typename?: 'controls_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "controls" */
export type Controls_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Controls_Var_Samp_Fields = {
  __typename?: 'controls_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "controls" */
export type Controls_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Controls_Variance_Fields = {
  __typename?: 'controls_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "controls" */
export type Controls_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** columns and relationships of "criteria" */
export type Criteria = {
  __typename?: 'criteria';
  /** An array relationship */
  control_criteria: Array<Control_Criteria>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "criteria" */
export type CriteriaControl_CriteriaArgs = {
  distinct_on?: InputMaybe<Array<Control_Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Criteria_Order_By>>;
  where?: InputMaybe<Control_Criteria_Bool_Exp>;
};

/** order by aggregate values of table "criteria" */
export type Criteria_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Criteria_Max_Order_By>;
  min?: InputMaybe<Criteria_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "criteria". All fields are combined with a logical 'AND'. */
export type Criteria_Bool_Exp = {
  _and?: InputMaybe<Array<Criteria_Bool_Exp>>;
  _not?: InputMaybe<Criteria_Bool_Exp>;
  _or?: InputMaybe<Array<Criteria_Bool_Exp>>;
  control_criteria?: InputMaybe<Control_Criteria_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "criteria" */
export enum Criteria_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  CriteriaNameOrganizationIdKey = 'criteria_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  CriteriaPkey = 'criteria_pkey',
}

/** input type for inserting data into table "criteria" */
export type Criteria_Insert_Input = {
  control_criteria?: InputMaybe<Control_Criteria_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "criteria" */
export type Criteria_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "criteria" */
export type Criteria_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "criteria" */
export type Criteria_Mutation_Response = {
  __typename?: 'criteria_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Criteria>;
};

/** input type for inserting object relation for remote table "criteria" */
export type Criteria_Obj_Rel_Insert_Input = {
  data: Criteria_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Criteria_On_Conflict>;
};

/** on_conflict condition type for table "criteria" */
export type Criteria_On_Conflict = {
  constraint: Criteria_Constraint;
  update_columns?: Array<Criteria_Update_Column>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

/** Ordering options when selecting data from "criteria". */
export type Criteria_Order_By = {
  control_criteria_aggregate?: InputMaybe<Control_Criteria_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: criteria */
export type Criteria_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "criteria" */
export enum Criteria_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "criteria" */
export type Criteria_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "criteria" */
export type Criteria_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Criteria_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Criteria_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "criteria" */
export enum Criteria_Update_Column {
  /** column name */
  Name = 'name',
}

export type Criteria_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Criteria_Set_Input>;
  /** filter the rows which have to be updated */
  where: Criteria_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC',
}

/** columns and relationships of "daily_control_stats_view" */
export type Daily_Control_Stats_View = {
  __typename?: 'daily_control_stats_view';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  organisation?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  total_at_risk_count?: Maybe<Scalars['bigint']['output']>;
  total_failing_count?: Maybe<Scalars['bigint']['output']>;
  total_not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  total_pending_count?: Maybe<Scalars['bigint']['output']>;
  total_valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** Boolean expression to filter rows from the table "daily_control_stats_view". All fields are combined with a logical 'AND'. */
export type Daily_Control_Stats_View_Bool_Exp = {
  _and?: InputMaybe<Array<Daily_Control_Stats_View_Bool_Exp>>;
  _not?: InputMaybe<Daily_Control_Stats_View_Bool_Exp>;
  _or?: InputMaybe<Array<Daily_Control_Stats_View_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  organisation?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  total_at_risk_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_failing_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_not_relevant_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_pending_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_valid_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** Ordering options when selecting data from "daily_control_stats_view". */
export type Daily_Control_Stats_View_Order_By = {
  created_at?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  total_at_risk_count?: InputMaybe<Order_By>;
  total_failing_count?: InputMaybe<Order_By>;
  total_not_relevant_count?: InputMaybe<Order_By>;
  total_pending_count?: InputMaybe<Order_By>;
  total_valid_count?: InputMaybe<Order_By>;
};

/** select columns of table "daily_control_stats_view" */
export enum Daily_Control_Stats_View_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TotalAtRiskCount = 'total_at_risk_count',
  /** column name */
  TotalFailingCount = 'total_failing_count',
  /** column name */
  TotalNotRelevantCount = 'total_not_relevant_count',
  /** column name */
  TotalPendingCount = 'total_pending_count',
  /** column name */
  TotalValidCount = 'total_valid_count',
}

/** Streaming cursor of the table "daily_control_stats_view" */
export type Daily_Control_Stats_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Daily_Control_Stats_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Daily_Control_Stats_View_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  total_at_risk_count?: InputMaybe<Scalars['bigint']['input']>;
  total_failing_count?: InputMaybe<Scalars['bigint']['input']>;
  total_not_relevant_count?: InputMaybe<Scalars['bigint']['input']>;
  total_pending_count?: InputMaybe<Scalars['bigint']['input']>;
  total_valid_count?: InputMaybe<Scalars['bigint']['input']>;
};

/** columns and relationships of "daily_control_user_stats_view" */
export type Daily_Control_User_Stats_View = {
  __typename?: 'daily_control_user_stats_view';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  total_at_risk_count?: Maybe<Scalars['bigint']['output']>;
  total_failing_count?: Maybe<Scalars['bigint']['output']>;
  total_not_relevant_count?: Maybe<Scalars['bigint']['output']>;
  total_pending_count?: Maybe<Scalars['bigint']['output']>;
  total_valid_count?: Maybe<Scalars['bigint']['output']>;
};

/** Boolean expression to filter rows from the table "daily_control_user_stats_view". All fields are combined with a logical 'AND'. */
export type Daily_Control_User_Stats_View_Bool_Exp = {
  _and?: InputMaybe<Array<Daily_Control_User_Stats_View_Bool_Exp>>;
  _not?: InputMaybe<Daily_Control_User_Stats_View_Bool_Exp>;
  _or?: InputMaybe<Array<Daily_Control_User_Stats_View_Bool_Exp>>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  total_at_risk_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_failing_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_not_relevant_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_pending_count?: InputMaybe<Bigint_Comparison_Exp>;
  total_valid_count?: InputMaybe<Bigint_Comparison_Exp>;
};

/** Ordering options when selecting data from "daily_control_user_stats_view". */
export type Daily_Control_User_Stats_View_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  total_at_risk_count?: InputMaybe<Order_By>;
  total_failing_count?: InputMaybe<Order_By>;
  total_not_relevant_count?: InputMaybe<Order_By>;
  total_pending_count?: InputMaybe<Order_By>;
  total_valid_count?: InputMaybe<Order_By>;
};

/** select columns of table "daily_control_user_stats_view" */
export enum Daily_Control_User_Stats_View_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TotalAtRiskCount = 'total_at_risk_count',
  /** column name */
  TotalFailingCount = 'total_failing_count',
  /** column name */
  TotalNotRelevantCount = 'total_not_relevant_count',
  /** column name */
  TotalPendingCount = 'total_pending_count',
  /** column name */
  TotalValidCount = 'total_valid_count',
}

/** Streaming cursor of the table "daily_control_user_stats_view" */
export type Daily_Control_User_Stats_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Daily_Control_User_Stats_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Daily_Control_User_Stats_View_Stream_Cursor_Value_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  total_at_risk_count?: InputMaybe<Scalars['bigint']['input']>;
  total_failing_count?: InputMaybe<Scalars['bigint']['input']>;
  total_not_relevant_count?: InputMaybe<Scalars['bigint']['input']>;
  total_pending_count?: InputMaybe<Scalars['bigint']['input']>;
  total_valid_count?: InputMaybe<Scalars['bigint']['input']>;
};

/** columns and relationships of "date_field_values" */
export type Date_Field_Values = {
  __typename?: 'date_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['timestamptz']['output'];
};

/** order by aggregate values of table "date_field_values" */
export type Date_Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Date_Field_Values_Max_Order_By>;
  min?: InputMaybe<Date_Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "date_field_values" */
export type Date_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Date_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Date_Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "date_field_values". All fields are combined with a logical 'AND'. */
export type Date_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Date_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Date_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Date_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "date_field_values" */
export enum Date_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  DateFieldValuesPkey = 'date_field_values_pkey',
}

/** input type for inserting data into table "date_field_values" */
export type Date_Field_Values_Insert_Input = {
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by max() on columns of table "date_field_values" */
export type Date_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "date_field_values" */
export type Date_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "date_field_values" */
export type Date_Field_Values_Mutation_Response = {
  __typename?: 'date_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Date_Field_Values>;
};

/** input type for inserting object relation for remote table "date_field_values" */
export type Date_Field_Values_Obj_Rel_Insert_Input = {
  data: Date_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Date_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "date_field_values" */
export type Date_Field_Values_On_Conflict = {
  constraint: Date_Field_Values_Constraint;
  update_columns?: Array<Date_Field_Values_Update_Column>;
  where?: InputMaybe<Date_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "date_field_values". */
export type Date_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: date_field_values */
export type Date_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "date_field_values" */
export enum Date_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "date_field_values" */
export type Date_Field_Values_Set_Input = {
  value?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "date_field_values" */
export enum Date_Field_Values_Update_Column {
  /** column name */
  Value = 'value',
}

export type Date_Field_Values_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Date_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Date_Field_Values_Bool_Exp;
};

export enum Document_Type_Enum {
  /** Document holding the golden questionnaire for AI Client QnA */
  GoldenQuestionnaire = 'Golden_questionnaire',
}

/** Boolean expression to compare columns of type "document_type_enum". All fields are combined with logical 'AND'. */
export type Document_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Document_Type_Enum>;
  _in?: InputMaybe<Array<Document_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Document_Type_Enum>;
  _nin?: InputMaybe<Array<Document_Type_Enum>>;
};

/** Set of documents related to a given organization */
export type Documents = {
  __typename?: 'documents';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  documents_vector_stores: Array<Ai_Documents_Vector_Store>;
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  is_current: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  similarity?: Maybe<Scalars['float8']['output']>;
  type: Document_Type_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** Set of documents related to a given organization */
export type DocumentsDocuments_Vector_StoresArgs = {
  distinct_on?: InputMaybe<Array<Ai_Documents_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Documents_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Documents_Vector_Store_Bool_Exp>;
};

/** order by aggregate values of table "documents" */
export type Documents_Aggregate_Order_By = {
  avg?: InputMaybe<Documents_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Documents_Max_Order_By>;
  min?: InputMaybe<Documents_Min_Order_By>;
  stddev?: InputMaybe<Documents_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Documents_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Documents_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Documents_Sum_Order_By>;
  var_pop?: InputMaybe<Documents_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Documents_Var_Samp_Order_By>;
  variance?: InputMaybe<Documents_Variance_Order_By>;
};

/** order by avg() on columns of table "documents" */
export type Documents_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "documents". All fields are combined with a logical 'AND'. */
export type Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Documents_Bool_Exp>>;
  _not?: InputMaybe<Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Documents_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  documents_vector_stores?: InputMaybe<Ai_Documents_Vector_Store_Bool_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_current?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  type?: InputMaybe<Document_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "documents" */
export enum Documents_Constraint {
  /** unique or primary key constraint on columns "type", "organization_id" */
  DocumentsIsCurrentTypeOrganizationIdKey = 'documents_is_current_type_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  DocumentsPkey = 'documents_pkey',
}

/** input type for inserting data into table "documents" */
export type Documents_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_current?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Document_Type_Enum>;
};

/** order by max() on columns of table "documents" */
export type Documents_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "documents" */
export type Documents_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "documents" */
export type Documents_Mutation_Response = {
  __typename?: 'documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Documents>;
};

/** on_conflict condition type for table "documents" */
export type Documents_On_Conflict = {
  constraint: Documents_Constraint;
  update_columns?: Array<Documents_Update_Column>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "documents". */
export type Documents_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  documents_vector_stores_aggregate?: InputMaybe<Ai_Documents_Vector_Store_Aggregate_Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_current?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: documents */
export type Documents_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "documents" */
export enum Documents_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCurrent = 'is_current',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "documents" */
export type Documents_Set_Input = {
  is_current?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Document_Type_Enum>;
};

/** order by stddev() on columns of table "documents" */
export type Documents_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "documents" */
export type Documents_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "documents" */
export type Documents_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "documents" */
export type Documents_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Documents_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Documents_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_current?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  type?: InputMaybe<Document_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by sum() on columns of table "documents" */
export type Documents_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "documents" */
export enum Documents_Update_Column {
  /** column name */
  IsCurrent = 'is_current',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
}

export type Documents_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Documents_Set_Input>;
  /** filter the rows which have to be updated */
  where: Documents_Bool_Exp;
};

/** order by var_pop() on columns of table "documents" */
export type Documents_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "documents" */
export type Documents_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "documents" */
export type Documents_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** columns and relationships of "email_field_values" */
export type Email_Field_Values = {
  __typename?: 'email_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['citext']['output'];
};

/** order by aggregate values of table "email_field_values" */
export type Email_Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Email_Field_Values_Max_Order_By>;
  min?: InputMaybe<Email_Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "email_field_values" */
export type Email_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Email_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "email_field_values". All fields are combined with a logical 'AND'. */
export type Email_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Email_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Email_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Email_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Citext_Comparison_Exp>;
};

/** unique or primary key constraints on table "email_field_values" */
export enum Email_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  EmailFieldValuesPkey = 'email_field_values_pkey',
}

/** input type for inserting data into table "email_field_values" */
export type Email_Field_Values_Insert_Input = {
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['citext']['input']>;
};

/** order by max() on columns of table "email_field_values" */
export type Email_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "email_field_values" */
export type Email_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "email_field_values" */
export type Email_Field_Values_Mutation_Response = {
  __typename?: 'email_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Email_Field_Values>;
};

/** input type for inserting object relation for remote table "email_field_values" */
export type Email_Field_Values_Obj_Rel_Insert_Input = {
  data: Email_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Email_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "email_field_values" */
export type Email_Field_Values_On_Conflict = {
  constraint: Email_Field_Values_Constraint;
  update_columns?: Array<Email_Field_Values_Update_Column>;
  where?: InputMaybe<Email_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "email_field_values". */
export type Email_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: email_field_values */
export type Email_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "email_field_values" */
export enum Email_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "email_field_values" */
export type Email_Field_Values_Set_Input = {
  value?: InputMaybe<Scalars['citext']['input']>;
};

/** update columns of table "email_field_values" */
export enum Email_Field_Values_Update_Column {
  /** column name */
  Value = 'value',
}

export type Email_Field_Values_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Email_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Email_Field_Values_Bool_Exp;
};

/** columns and relationships of "evidence_acl" */
export type Evidence_Acl = {
  __typename?: 'evidence_acl';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "evidence_acl" */
export type Evidence_Acl_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidence_Acl_Max_Order_By>;
  min?: InputMaybe<Evidence_Acl_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "evidence_acl". All fields are combined with a logical 'AND'. */
export type Evidence_Acl_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Acl_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Acl_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Acl_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "evidence_acl" */
export type Evidence_Acl_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "evidence_acl" */
export type Evidence_Acl_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "evidence_acl". */
export type Evidence_Acl_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "evidence_acl" */
export enum Evidence_Acl_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** columns and relationships of "evidence_integrations" */
export type Evidence_Integrations = {
  __typename?: 'evidence_integrations';
  config: Scalars['EvidenceIntegrationConfig']['output'];
  /** An object relationship */
  evidence: Evidences;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  organization_integration: Organization_Integrations;
};

/** order by aggregate values of table "evidence_integrations" */
export type Evidence_Integrations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidence_Integrations_Max_Order_By>;
  min?: InputMaybe<Evidence_Integrations_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "evidence_integrations". All fields are combined with a logical 'AND'. */
export type Evidence_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Integrations_Bool_Exp>>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_integration?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

/** order by max() on columns of table "evidence_integrations" */
export type Evidence_Integrations_Max_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "evidence_integrations" */
export type Evidence_Integrations_Min_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "evidence_integrations". */
export type Evidence_Integrations_Order_By = {
  evidence?: InputMaybe<Evidences_Order_By>;
  id?: InputMaybe<Order_By>;
  organization_integration?: InputMaybe<Organization_Integrations_Order_By>;
};

/** select columns of table "evidence_integrations" */
export enum Evidence_Integrations_Select_Column {
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "evidence_integrations" */
export type Evidence_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evidence_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evidence_Integrations_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "evidence_policies" */
export type Evidence_Policies = {
  __typename?: 'evidence_policies';
  created_at: Scalars['timestamptz']['output'];
  error_message?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  policy: Policies;
  policy_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  with_acknowledgements_chapter: Scalars['Boolean']['output'];
  with_approvals_chapter: Scalars['Boolean']['output'];
  with_revision_details: Scalars['Boolean']['output'];
  with_versions_chapter: Scalars['Boolean']['output'];
};

/** order by aggregate values of table "evidence_policies" */
export type Evidence_Policies_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidence_Policies_Max_Order_By>;
  min?: InputMaybe<Evidence_Policies_Min_Order_By>;
};

/** input type for inserting array relation for remote table "evidence_policies" */
export type Evidence_Policies_Arr_Rel_Insert_Input = {
  data: Array<Evidence_Policies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Policies_On_Conflict>;
};

/** Boolean expression to filter rows from the table "evidence_policies". All fields are combined with a logical 'AND'. */
export type Evidence_Policies_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Policies_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Policies_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Policies_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  error_message?: InputMaybe<String_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  with_acknowledgements_chapter?: InputMaybe<Boolean_Comparison_Exp>;
  with_approvals_chapter?: InputMaybe<Boolean_Comparison_Exp>;
  with_revision_details?: InputMaybe<Boolean_Comparison_Exp>;
  with_versions_chapter?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "evidence_policies" */
export enum Evidence_Policies_Constraint {
  /** unique or primary key constraint on columns "evidence_id" */
  EvidencePoliciesEvidenceIdKey = 'evidence_policies_evidence_id_key',
  /** unique or primary key constraint on columns "id" */
  EvidencePoliciesPkey = 'evidence_policies_pkey',
}

/** input type for inserting data into table "evidence_policies" */
export type Evidence_Policies_Insert_Input = {
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  with_acknowledgements_chapter?: InputMaybe<Scalars['Boolean']['input']>;
  with_approvals_chapter?: InputMaybe<Scalars['Boolean']['input']>;
  with_revision_details?: InputMaybe<Scalars['Boolean']['input']>;
  with_versions_chapter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by max() on columns of table "evidence_policies" */
export type Evidence_Policies_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "evidence_policies" */
export type Evidence_Policies_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "evidence_policies" */
export type Evidence_Policies_Mutation_Response = {
  __typename?: 'evidence_policies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidence_Policies>;
};

/** input type for inserting object relation for remote table "evidence_policies" */
export type Evidence_Policies_Obj_Rel_Insert_Input = {
  data: Evidence_Policies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Policies_On_Conflict>;
};

/** on_conflict condition type for table "evidence_policies" */
export type Evidence_Policies_On_Conflict = {
  constraint: Evidence_Policies_Constraint;
  update_columns?: Array<Evidence_Policies_Update_Column>;
  where?: InputMaybe<Evidence_Policies_Bool_Exp>;
};

/** Ordering options when selecting data from "evidence_policies". */
export type Evidence_Policies_Order_By = {
  created_at?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  with_acknowledgements_chapter?: InputMaybe<Order_By>;
  with_approvals_chapter?: InputMaybe<Order_By>;
  with_revision_details?: InputMaybe<Order_By>;
  with_versions_chapter?: InputMaybe<Order_By>;
};

/** primary key columns input for table: evidence_policies */
export type Evidence_Policies_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "evidence_policies" */
export enum Evidence_Policies_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ErrorMessage = 'error_message',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WithAcknowledgementsChapter = 'with_acknowledgements_chapter',
  /** column name */
  WithApprovalsChapter = 'with_approvals_chapter',
  /** column name */
  WithRevisionDetails = 'with_revision_details',
  /** column name */
  WithVersionsChapter = 'with_versions_chapter',
}

/** input type for updating data in table "evidence_policies" */
export type Evidence_Policies_Set_Input = {
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  with_acknowledgements_chapter?: InputMaybe<Scalars['Boolean']['input']>;
  with_approvals_chapter?: InputMaybe<Scalars['Boolean']['input']>;
  with_revision_details?: InputMaybe<Scalars['Boolean']['input']>;
  with_versions_chapter?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "evidence_policies" */
export enum Evidence_Policies_Update_Column {
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  WithAcknowledgementsChapter = 'with_acknowledgements_chapter',
  /** column name */
  WithApprovalsChapter = 'with_approvals_chapter',
  /** column name */
  WithRevisionDetails = 'with_revision_details',
  /** column name */
  WithVersionsChapter = 'with_versions_chapter',
}

export type Evidence_Policies_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evidence_Policies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Evidence_Policies_Bool_Exp;
};

/** columns and relationships of "evidence_tags" */
export type Evidence_Tags = {
  __typename?: 'evidence_tags';
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  tag: Organization_Evidence_Tags;
  tag_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "evidence_tags" */
export type Evidence_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidence_Tags_Max_Order_By>;
  min?: InputMaybe<Evidence_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "evidence_tags" */
export type Evidence_Tags_Arr_Rel_Insert_Input = {
  data: Array<Evidence_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "evidence_tags". All fields are combined with a logical 'AND'. */
export type Evidence_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Tags_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Tags_Bool_Exp>>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "evidence_tags" */
export enum Evidence_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  EvidenceTagsPkey = 'evidence_tags_pkey',
  /** unique or primary key constraint on columns "tag_id", "evidence_id" */
  EvidenceTagsTagIdEvidenceIdKey = 'evidence_tags_tag_id_evidence_id_key',
}

/** input type for inserting data into table "evidence_tags" */
export type Evidence_Tags_Insert_Input = {
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  tag?: InputMaybe<Organization_Evidence_Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "evidence_tags" */
export type Evidence_Tags_Max_Order_By = {
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "evidence_tags" */
export type Evidence_Tags_Min_Order_By = {
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "evidence_tags" */
export type Evidence_Tags_Mutation_Response = {
  __typename?: 'evidence_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidence_Tags>;
};

/** on_conflict condition type for table "evidence_tags" */
export type Evidence_Tags_On_Conflict = {
  constraint: Evidence_Tags_Constraint;
  update_columns?: Array<Evidence_Tags_Update_Column>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "evidence_tags". */
export type Evidence_Tags_Order_By = {
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Organization_Evidence_Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** select columns of table "evidence_tags" */
export enum Evidence_Tags_Select_Column {
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
}

/** placeholder for update columns of table "evidence_tags" (current role has no relevant permissions) */
export enum Evidence_Tags_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "evidence_version_file" */
export type Evidence_Version_File = {
  __typename?: 'evidence_version_file';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  evidence_version: Evidence_Versions;
  evidence_version_id: Scalars['uuid']['output'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
};

/** Boolean expression to filter rows from the table "evidence_version_file". All fields are combined with a logical 'AND'. */
export type Evidence_Version_File_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Version_File_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Version_File_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Version_File_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence_version?: InputMaybe<Evidence_Versions_Bool_Exp>;
  evidence_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "evidence_version_file" */
export enum Evidence_Version_File_Constraint {
  /** unique or primary key constraint on columns "evidence_version_id" */
  EvidenceVersionFileEvidenceVersionIdKey = 'evidence_version_file_evidence_version_id_key',
  /** unique or primary key constraint on columns "file_id" */
  EvidenceVersionFileFileIdKey = 'evidence_version_file_file_id_key',
  /** unique or primary key constraint on columns "file_id", "evidence_version_id" */
  EvidenceVersionFilePkey = 'evidence_version_file_pkey',
}

/** input type for inserting data into table "evidence_version_file" */
export type Evidence_Version_File_Insert_Input = {
  evidence_version?: InputMaybe<Evidence_Versions_Obj_Rel_Insert_Input>;
  evidence_version_id?: InputMaybe<Scalars['uuid']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** response of any mutation on the table "evidence_version_file" */
export type Evidence_Version_File_Mutation_Response = {
  __typename?: 'evidence_version_file_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidence_Version_File>;
};

/** input type for inserting object relation for remote table "evidence_version_file" */
export type Evidence_Version_File_Obj_Rel_Insert_Input = {
  data: Evidence_Version_File_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Version_File_On_Conflict>;
};

/** on_conflict condition type for table "evidence_version_file" */
export type Evidence_Version_File_On_Conflict = {
  constraint: Evidence_Version_File_Constraint;
  update_columns?: Array<Evidence_Version_File_Update_Column>;
  where?: InputMaybe<Evidence_Version_File_Bool_Exp>;
};

/** Ordering options when selecting data from "evidence_version_file". */
export type Evidence_Version_File_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence_version?: InputMaybe<Evidence_Versions_Order_By>;
  evidence_version_id?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "evidence_version_file" (current role has no relevant permissions) */
export enum Evidence_Version_File_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "evidence_versions" */
export type Evidence_Versions = {
  __typename?: 'evidence_versions';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  evidence: Evidences;
  evidence_id: Scalars['uuid']['output'];
  /** An object relationship */
  evidence_version_file?: Maybe<Evidence_Version_File>;
  id: Scalars['uuid']['output'];
  is_current: Scalars['Boolean']['output'];
  /** An object relationship */
  policy_version?: Maybe<Policy_Versions>;
  policy_version_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  updated_by?: Maybe<Scalars['uuid']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByUpdatedBy?: Maybe<Users>;
  validity_start: Scalars['timestamptz']['output'];
};

/** order by aggregate values of table "evidence_versions" */
export type Evidence_Versions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidence_Versions_Max_Order_By>;
  min?: InputMaybe<Evidence_Versions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "evidence_versions" */
export type Evidence_Versions_Arr_Rel_Insert_Input = {
  data: Array<Evidence_Versions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Versions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "evidence_versions". All fields are combined with a logical 'AND'. */
export type Evidence_Versions_Bool_Exp = {
  _and?: InputMaybe<Array<Evidence_Versions_Bool_Exp>>;
  _not?: InputMaybe<Evidence_Versions_Bool_Exp>;
  _or?: InputMaybe<Array<Evidence_Versions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  evidence?: InputMaybe<Evidences_Bool_Exp>;
  evidence_id?: InputMaybe<Uuid_Comparison_Exp>;
  evidence_version_file?: InputMaybe<Evidence_Version_File_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_current?: InputMaybe<Boolean_Comparison_Exp>;
  policy_version?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<Uuid_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByUpdatedBy?: InputMaybe<Users_Bool_Exp>;
  validity_start?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "evidence_versions" */
export enum Evidence_Versions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlEvidenceVersionPkey = 'control_evidence_version_pkey',
  /** unique or primary key constraint on columns "is_current", "evidence_id" */
  EvidenceVersionsCurrentIdx = 'evidence_versions_current_idx',
}

/** input type for inserting data into table "evidence_versions" */
export type Evidence_Versions_Insert_Input = {
  evidence?: InputMaybe<Evidences_Obj_Rel_Insert_Input>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  evidence_version_file?: InputMaybe<Evidence_Version_File_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_current?: InputMaybe<Scalars['Boolean']['input']>;
  policy_version?: InputMaybe<Policy_Versions_Obj_Rel_Insert_Input>;
  url?: InputMaybe<Scalars['String']['input']>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by max() on columns of table "evidence_versions" */
export type Evidence_Versions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  validity_start?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "evidence_versions" */
export type Evidence_Versions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  validity_start?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "evidence_versions" */
export type Evidence_Versions_Mutation_Response = {
  __typename?: 'evidence_versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidence_Versions>;
};

/** input type for inserting object relation for remote table "evidence_versions" */
export type Evidence_Versions_Obj_Rel_Insert_Input = {
  data: Evidence_Versions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidence_Versions_On_Conflict>;
};

/** on_conflict condition type for table "evidence_versions" */
export type Evidence_Versions_On_Conflict = {
  constraint: Evidence_Versions_Constraint;
  update_columns?: Array<Evidence_Versions_Update_Column>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "evidence_versions". */
export type Evidence_Versions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  evidence?: InputMaybe<Evidences_Order_By>;
  evidence_id?: InputMaybe<Order_By>;
  evidence_version_file?: InputMaybe<Evidence_Version_File_Order_By>;
  id?: InputMaybe<Order_By>;
  is_current?: InputMaybe<Order_By>;
  policy_version?: InputMaybe<Policy_Versions_Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByUpdatedBy?: InputMaybe<Users_Order_By>;
  validity_start?: InputMaybe<Order_By>;
};

/** primary key columns input for table: evidence_versions */
export type Evidence_Versions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "evidence_versions" */
export enum Evidence_Versions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  EvidenceId = 'evidence_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsCurrent = 'is_current',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by',
  /** column name */
  Url = 'url',
  /** column name */
  ValidityStart = 'validity_start',
}

/** input type for updating data in table "evidence_versions" */
export type Evidence_Versions_Set_Input = {
  is_current?: InputMaybe<Scalars['Boolean']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "evidence_versions" */
export type Evidence_Versions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evidence_Versions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evidence_Versions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  evidence_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_current?: InputMaybe<Scalars['Boolean']['input']>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_by?: InputMaybe<Scalars['uuid']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "evidence_versions" */
export enum Evidence_Versions_Update_Column {
  /** column name */
  IsCurrent = 'is_current',
  /** column name */
  Url = 'url',
  /** column name */
  ValidityStart = 'validity_start',
}

export type Evidence_Versions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evidence_Versions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Evidence_Versions_Bool_Exp;
};

/** columns and relationships of "evidences" */
export type Evidences = {
  __typename?: 'evidences';
  /** An array relationship */
  acl: Array<Evidence_Acl>;
  /** An array relationship */
  control_evidences: Array<Control_Evidences>;
  /** A computed field, executes function "get_evidence_status" */
  controls_aggregate_status: Scalars['EvidenceStatus']['output'];
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  evidence_integration?: Maybe<Evidence_Integrations>;
  /** An object relationship */
  evidence_policy?: Maybe<Evidence_Policies>;
  /** An array relationship */
  evidence_versions: Array<Evidence_Versions>;
  /** An array relationship */
  evidences_notifications: Array<Notifications>;
  /** An array relationship */
  evidences_vector_stores: Array<Ai_Evidences_Vector_Store>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  is_confidential: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  owner_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  tags: Array<Evidence_Tags>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** columns and relationships of "evidences" */
export type EvidencesAclArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Acl_Order_By>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesControl_EvidencesArgs = {
  distinct_on?: InputMaybe<Array<Control_Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Evidences_Order_By>>;
  where?: InputMaybe<Control_Evidences_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesEvidence_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesEvidences_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesEvidences_Vector_StoresArgs = {
  distinct_on?: InputMaybe<Array<Ai_Evidences_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Evidences_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
};

/** columns and relationships of "evidences" */
export type EvidencesTagsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Tags_Order_By>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

/** aggregated selection of "evidences" */
export type Evidences_Aggregate = {
  __typename?: 'evidences_aggregate';
  aggregate?: Maybe<Evidences_Aggregate_Fields>;
  nodes: Array<Evidences>;
};

export type Evidences_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Evidences_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Evidences_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Evidences_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Evidences_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Evidences_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Evidences_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Evidences_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Evidences_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Evidences_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Evidences_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Evidences_Aggregate_Bool_Exp_Var_Samp>;
};

export type Evidences_Aggregate_Bool_Exp_Avg = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Bool_And = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Corr = {
  arguments: Evidences_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Evidences_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Evidences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Evidences_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Evidences_Aggregate_Bool_Exp_Max = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Min = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Sum = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Evidences_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Evidences_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "evidences" */
export type Evidences_Aggregate_Fields = {
  __typename?: 'evidences_aggregate_fields';
  avg?: Maybe<Evidences_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Evidences_Max_Fields>;
  min?: Maybe<Evidences_Min_Fields>;
  stddev?: Maybe<Evidences_Stddev_Fields>;
  stddev_pop?: Maybe<Evidences_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Evidences_Stddev_Samp_Fields>;
  sum?: Maybe<Evidences_Sum_Fields>;
  var_pop?: Maybe<Evidences_Var_Pop_Fields>;
  var_samp?: Maybe<Evidences_Var_Samp_Fields>;
  variance?: Maybe<Evidences_Variance_Fields>;
};

/** aggregate fields of "evidences" */
export type Evidences_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Evidences_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "evidences" */
export type Evidences_Aggregate_Order_By = {
  avg?: InputMaybe<Evidences_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Evidences_Max_Order_By>;
  min?: InputMaybe<Evidences_Min_Order_By>;
  stddev?: InputMaybe<Evidences_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Evidences_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Evidences_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Evidences_Sum_Order_By>;
  var_pop?: InputMaybe<Evidences_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Evidences_Var_Samp_Order_By>;
  variance?: InputMaybe<Evidences_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Evidences_Avg_Fields = {
  __typename?: 'evidences_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "evidences" */
export type Evidences_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "evidences". All fields are combined with a logical 'AND'. */
export type Evidences_Bool_Exp = {
  _and?: InputMaybe<Array<Evidences_Bool_Exp>>;
  _not?: InputMaybe<Evidences_Bool_Exp>;
  _or?: InputMaybe<Array<Evidences_Bool_Exp>>;
  acl?: InputMaybe<Evidence_Acl_Bool_Exp>;
  control_evidences?: InputMaybe<Control_Evidences_Bool_Exp>;
  controls_aggregate_status?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  evidence_integration?: InputMaybe<Evidence_Integrations_Bool_Exp>;
  evidence_policy?: InputMaybe<Evidence_Policies_Bool_Exp>;
  evidence_versions?: InputMaybe<Evidence_Versions_Bool_Exp>;
  evidences_notifications?: InputMaybe<Notifications_Bool_Exp>;
  evidences_vector_stores?: InputMaybe<Ai_Evidences_Vector_Store_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  is_confidential?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  tags?: InputMaybe<Evidence_Tags_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "evidences" */
export enum Evidences_Constraint {
  /** unique or primary key constraint on columns "id" */
  ControlEvidencesPkey = 'control_evidences_pkey',
}

/** input type for inserting data into table "evidences" */
export type Evidences_Insert_Input = {
  control_evidences?: InputMaybe<Control_Evidences_Arr_Rel_Insert_Input>;
  evidence_policy?: InputMaybe<Evidence_Policies_Obj_Rel_Insert_Input>;
  evidence_versions?: InputMaybe<Evidence_Versions_Arr_Rel_Insert_Input>;
  is_confidential?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  tags?: InputMaybe<Evidence_Tags_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Evidences_Max_Fields = {
  __typename?: 'evidences_max_fields';
  /** A computed field, executes function "get_evidence_status" */
  controls_aggregate_status?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "evidences" */
export type Evidences_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Evidences_Min_Fields = {
  __typename?: 'evidences_min_fields';
  /** A computed field, executes function "get_evidence_status" */
  controls_aggregate_status?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "evidences" */
export type Evidences_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "evidences" */
export type Evidences_Mutation_Response = {
  __typename?: 'evidences_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Evidences>;
};

/** input type for inserting object relation for remote table "evidences" */
export type Evidences_Obj_Rel_Insert_Input = {
  data: Evidences_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Evidences_On_Conflict>;
};

/** on_conflict condition type for table "evidences" */
export type Evidences_On_Conflict = {
  constraint: Evidences_Constraint;
  update_columns?: Array<Evidences_Update_Column>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

/** Ordering options when selecting data from "evidences". */
export type Evidences_Order_By = {
  acl_aggregate?: InputMaybe<Evidence_Acl_Aggregate_Order_By>;
  control_evidences_aggregate?: InputMaybe<Control_Evidences_Aggregate_Order_By>;
  controls_aggregate_status?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  evidence_integration?: InputMaybe<Evidence_Integrations_Order_By>;
  evidence_policy?: InputMaybe<Evidence_Policies_Order_By>;
  evidence_versions_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Order_By>;
  evidences_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  evidences_vector_stores_aggregate?: InputMaybe<Ai_Evidences_Vector_Store_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  is_confidential?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Evidence_Tags_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: evidences */
export type Evidences_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "evidences" */
export enum Evidences_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  IsConfidential = 'is_confidential',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "evidences_aggregate_bool_exp_avg_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_bool_and_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsConfidential = 'is_confidential',
}

/** select "evidences_aggregate_bool_exp_bool_or_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsConfidential = 'is_confidential',
}

/** select "evidences_aggregate_bool_exp_corr_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_max_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_min_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_sum_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "evidences_aggregate_bool_exp_var_samp_arguments_columns" columns of table "evidences" */
export enum Evidences_Select_Column_Evidences_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "evidences" */
export type Evidences_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  is_confidential?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Evidences_Stddev_Fields = {
  __typename?: 'evidences_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "evidences" */
export type Evidences_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Evidences_Stddev_Pop_Fields = {
  __typename?: 'evidences_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "evidences" */
export type Evidences_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Evidences_Stddev_Samp_Fields = {
  __typename?: 'evidences_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "evidences" */
export type Evidences_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "evidences" */
export type Evidences_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Evidences_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Evidences_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  is_confidential?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Evidences_Sum_Fields = {
  __typename?: 'evidences_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "evidences" */
export type Evidences_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "evidences" */
export enum Evidences_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  IsConfidential = 'is_confidential',
  /** column name */
  Name = 'name',
}

export type Evidences_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Evidences_Set_Input>;
  /** filter the rows which have to be updated */
  where: Evidences_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Evidences_Var_Pop_Fields = {
  __typename?: 'evidences_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "evidences" */
export type Evidences_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Evidences_Var_Samp_Fields = {
  __typename?: 'evidences_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "evidences" */
export type Evidences_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Evidences_Variance_Fields = {
  __typename?: 'evidences_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "evidences" */
export type Evidences_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** columns and relationships of "field_configs" */
export type Field_Configs = {
  __typename?: 'field_configs';
  created_at: Scalars['timestamptz']['output'];
  entity_name: Field_Entities_Enum;
  field_type: Field_Types_Enum;
  /** An array relationship */
  field_values: Array<Field_Values>;
  hidden: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  program_field_configs: Array<Program_Field_Configs>;
  /** An object relationship */
  select_field_config?: Maybe<Select_Field_Configs>;
  /** An array relationship */
  select_field_configs: Array<Select_Field_Configs>;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "field_configs" */
export type Field_ConfigsField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_configs" */
export type Field_ConfigsProgram_Field_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Field_Configs_Order_By>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

/** columns and relationships of "field_configs" */
export type Field_ConfigsSelect_Field_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Configs_Order_By>>;
  where?: InputMaybe<Select_Field_Configs_Bool_Exp>;
};

/** order by aggregate values of table "field_configs" */
export type Field_Configs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Field_Configs_Max_Order_By>;
  min?: InputMaybe<Field_Configs_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "field_configs". All fields are combined with a logical 'AND'. */
export type Field_Configs_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Configs_Bool_Exp>>;
  _not?: InputMaybe<Field_Configs_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Configs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entity_name?: InputMaybe<Field_Entities_Enum_Comparison_Exp>;
  field_type?: InputMaybe<Field_Types_Enum_Comparison_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  hidden?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  program_field_configs?: InputMaybe<Program_Field_Configs_Bool_Exp>;
  select_field_config?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  select_field_configs?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_configs" */
export enum Field_Configs_Constraint {
  /** unique or primary key constraint on columns "entity_name", "id", "field_type" */
  FieldConfigsEntityNameFieldTypeIdKey = 'field_configs_entity_name_field_type_id_key',
  /** unique or primary key constraint on columns "id", "field_type" */
  FieldConfigsFieldTypeIdKey = 'field_configs_field_type_id_key',
  /** unique or primary key constraint on columns "entity_name", "id" */
  FieldConfigsIdEntityNameKey = 'field_configs_id_entity_name_key',
  /** unique or primary key constraint on columns "entity_name", "name", "organization_id" */
  FieldConfigsOrganizationIdEntityNameNameKey = 'field_configs_organization_id_entity_name_name_key',
  /** unique or primary key constraint on columns "order", "entity_name", "organization_id" */
  FieldConfigsOrganizationIdEntityNameOrderKey = 'field_configs_organization_id_entity_name_order_key',
  /** unique or primary key constraint on columns "id" */
  FieldConfigsPkey = 'field_configs_pkey',
}

/** input type for inserting data into table "field_configs" */
export type Field_Configs_Insert_Input = {
  entity_name?: InputMaybe<Field_Entities_Enum>;
  field_type?: InputMaybe<Field_Types_Enum>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  program_field_configs?: InputMaybe<Program_Field_Configs_Arr_Rel_Insert_Input>;
  select_field_config?: InputMaybe<Select_Field_Configs_Obj_Rel_Insert_Input>;
  select_field_configs?: InputMaybe<Select_Field_Configs_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "field_configs" */
export type Field_Configs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "field_configs" */
export type Field_Configs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "field_configs" */
export type Field_Configs_Mutation_Response = {
  __typename?: 'field_configs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Configs>;
};

/** input type for inserting object relation for remote table "field_configs" */
export type Field_Configs_Obj_Rel_Insert_Input = {
  data: Field_Configs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Configs_On_Conflict>;
};

/** on_conflict condition type for table "field_configs" */
export type Field_Configs_On_Conflict = {
  constraint: Field_Configs_Constraint;
  update_columns?: Array<Field_Configs_Update_Column>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

/** Ordering options when selecting data from "field_configs". */
export type Field_Configs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_name?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  hidden?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  program_field_configs_aggregate?: InputMaybe<Program_Field_Configs_Aggregate_Order_By>;
  select_field_config?: InputMaybe<Select_Field_Configs_Order_By>;
  select_field_configs_aggregate?: InputMaybe<Select_Field_Configs_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: field_configs */
export type Field_Configs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "field_configs" */
export enum Field_Configs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "field_configs" */
export type Field_Configs_Set_Input = {
  field_type?: InputMaybe<Field_Types_Enum>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "field_configs" */
export type Field_Configs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Configs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Configs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  entity_name?: InputMaybe<Field_Entities_Enum>;
  field_type?: InputMaybe<Field_Types_Enum>;
  hidden?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "field_configs" */
export enum Field_Configs_Update_Column {
  /** column name */
  FieldType = 'field_type',
  /** column name */
  Hidden = 'hidden',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
}

export type Field_Configs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Configs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Configs_Bool_Exp;
};

export enum Field_Entities_Enum {
  Control = 'control',
  Policy = 'policy',
  Risk = 'risk',
  Task = 'task',
  Vendor = 'vendor',
}

/** Boolean expression to compare columns of type "field_entities_enum". All fields are combined with logical 'AND'. */
export type Field_Entities_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Field_Entities_Enum>;
  _in?: InputMaybe<Array<Field_Entities_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Field_Entities_Enum>;
  _nin?: InputMaybe<Array<Field_Entities_Enum>>;
};

export enum Field_Types_Enum {
  Date = 'date',
  Email = 'email',
  Number = 'number',
  Select = 'select',
  Text = 'text',
  Url = 'url',
}

/** Boolean expression to compare columns of type "field_types_enum". All fields are combined with logical 'AND'. */
export type Field_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Field_Types_Enum>;
  _in?: InputMaybe<Array<Field_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Field_Types_Enum>;
  _nin?: InputMaybe<Array<Field_Types_Enum>>;
};

/** columns and relationships of "field_values" */
export type Field_Values = {
  __typename?: 'field_values';
  /** An object relationship */
  control?: Maybe<Controls>;
  control_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  date_field_value?: Maybe<Date_Field_Values>;
  /** An array relationship */
  date_field_values: Array<Date_Field_Values>;
  /** An object relationship */
  email_field_value?: Maybe<Email_Field_Values>;
  /** An array relationship */
  email_field_values: Array<Email_Field_Values>;
  entity_name: Field_Entities_Enum;
  /** An object relationship */
  field_config?: Maybe<Field_Configs>;
  field_config_id: Scalars['uuid']['output'];
  field_type: Field_Types_Enum;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  number_field_value?: Maybe<Number_Field_Values>;
  /** An array relationship */
  number_field_values: Array<Number_Field_Values>;
  /** An object relationship */
  policy?: Maybe<Policies>;
  policy_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  risk?: Maybe<Risks>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  select_field_value?: Maybe<Select_Field_Values>;
  /** An array relationship */
  select_field_values: Array<Select_Field_Values>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  text_field_value?: Maybe<Text_Field_Values>;
  /** An array relationship */
  text_field_values: Array<Text_Field_Values>;
  /** An object relationship */
  url_field_value?: Maybe<Url_Field_Values>;
  /** An array relationship */
  url_field_values: Array<Url_Field_Values>;
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id?: Maybe<Scalars['uuid']['output']>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesDate_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Date_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Date_Field_Values_Order_By>>;
  where?: InputMaybe<Date_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesEmail_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Email_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Email_Field_Values_Order_By>>;
  where?: InputMaybe<Email_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesNumber_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Number_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Number_Field_Values_Order_By>>;
  where?: InputMaybe<Number_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesSelect_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Values_Order_By>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesText_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Text_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Text_Field_Values_Order_By>>;
  where?: InputMaybe<Text_Field_Values_Bool_Exp>;
};

/** columns and relationships of "field_values" */
export type Field_ValuesUrl_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Url_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Url_Field_Values_Order_By>>;
  where?: InputMaybe<Url_Field_Values_Bool_Exp>;
};

/** order by aggregate values of table "field_values" */
export type Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Field_Values_Max_Order_By>;
  min?: InputMaybe<Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "field_values" */
export type Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "field_values". All fields are combined with a logical 'AND'. */
export type Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Values_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  date_field_value?: InputMaybe<Date_Field_Values_Bool_Exp>;
  date_field_values?: InputMaybe<Date_Field_Values_Bool_Exp>;
  email_field_value?: InputMaybe<Email_Field_Values_Bool_Exp>;
  email_field_values?: InputMaybe<Email_Field_Values_Bool_Exp>;
  entity_name?: InputMaybe<Field_Entities_Enum_Comparison_Exp>;
  field_config?: InputMaybe<Field_Configs_Bool_Exp>;
  field_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  field_type?: InputMaybe<Field_Types_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  number_field_value?: InputMaybe<Number_Field_Values_Bool_Exp>;
  number_field_values?: InputMaybe<Number_Field_Values_Bool_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  select_field_value?: InputMaybe<Select_Field_Values_Bool_Exp>;
  select_field_values?: InputMaybe<Select_Field_Values_Bool_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  text_field_value?: InputMaybe<Text_Field_Values_Bool_Exp>;
  text_field_values?: InputMaybe<Text_Field_Values_Bool_Exp>;
  url_field_value?: InputMaybe<Url_Field_Values_Bool_Exp>;
  url_field_values?: InputMaybe<Url_Field_Values_Bool_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "field_values" */
export enum Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_config_id", "id", "field_type" */
  FieldValuesFieldConfigIdFieldTypeIdKey = 'field_values_field_config_id_field_type_id_key',
  /** unique or primary key constraint on columns "id", "field_type" */
  FieldValuesFieldTypeIdKey = 'field_values_field_type_id_key',
  /** unique or primary key constraint on columns "id" */
  FieldValuesPkey = 'field_values_pkey',
}

/** input type for inserting data into table "field_values" */
export type Field_Values_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  date_field_value?: InputMaybe<Date_Field_Values_Obj_Rel_Insert_Input>;
  date_field_values?: InputMaybe<Date_Field_Values_Arr_Rel_Insert_Input>;
  email_field_value?: InputMaybe<Email_Field_Values_Obj_Rel_Insert_Input>;
  email_field_values?: InputMaybe<Email_Field_Values_Arr_Rel_Insert_Input>;
  entity_name?: InputMaybe<Field_Entities_Enum>;
  field_config?: InputMaybe<Field_Configs_Obj_Rel_Insert_Input>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  field_type?: InputMaybe<Field_Types_Enum>;
  number_field_value?: InputMaybe<Number_Field_Values_Obj_Rel_Insert_Input>;
  number_field_values?: InputMaybe<Number_Field_Values_Arr_Rel_Insert_Input>;
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  text_field_value?: InputMaybe<Text_Field_Values_Obj_Rel_Insert_Input>;
  text_field_values?: InputMaybe<Text_Field_Values_Arr_Rel_Insert_Input>;
  url_field_value?: InputMaybe<Url_Field_Values_Obj_Rel_Insert_Input>;
  url_field_values?: InputMaybe<Url_Field_Values_Arr_Rel_Insert_Input>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "field_values" */
export type Field_Values_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "field_values" */
export type Field_Values_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "field_values" */
export type Field_Values_Mutation_Response = {
  __typename?: 'field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Values>;
};

/** input type for inserting object relation for remote table "field_values" */
export type Field_Values_Obj_Rel_Insert_Input = {
  data: Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "field_values" */
export type Field_Values_On_Conflict = {
  constraint: Field_Values_Constraint;
  update_columns?: Array<Field_Values_Update_Column>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "field_values". */
export type Field_Values_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  date_field_value?: InputMaybe<Date_Field_Values_Order_By>;
  date_field_values_aggregate?: InputMaybe<Date_Field_Values_Aggregate_Order_By>;
  email_field_value?: InputMaybe<Email_Field_Values_Order_By>;
  email_field_values_aggregate?: InputMaybe<Email_Field_Values_Aggregate_Order_By>;
  entity_name?: InputMaybe<Order_By>;
  field_config?: InputMaybe<Field_Configs_Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  number_field_value?: InputMaybe<Number_Field_Values_Order_By>;
  number_field_values_aggregate?: InputMaybe<Number_Field_Values_Aggregate_Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  select_field_value?: InputMaybe<Select_Field_Values_Order_By>;
  select_field_values_aggregate?: InputMaybe<Select_Field_Values_Aggregate_Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  text_field_value?: InputMaybe<Text_Field_Values_Order_By>;
  text_field_values_aggregate?: InputMaybe<Text_Field_Values_Aggregate_Order_By>;
  url_field_value?: InputMaybe<Url_Field_Values_Order_By>;
  url_field_values_aggregate?: InputMaybe<Url_Field_Values_Aggregate_Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "field_values" */
export enum Field_Values_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  VendorId = 'vendor_id',
}

/** placeholder for update columns of table "field_values" (current role has no relevant permissions) */
export enum Field_Values_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "storage.files" */
export type Files = {
  __typename?: 'files';
  bucketId: Scalars['String']['output'];
  createdAt: Scalars['timestamptz']['output'];
  etag?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  export_client_questionnaires: Array<Client_Questionnaires>;
  /** An object relationship */
  files_client_questionnaire?: Maybe<Client_Questionnaires>;
  /** An object relationship */
  files_comment_upload?: Maybe<Comment_Uploads>;
  /** An object relationship */
  files_evidence_version_file?: Maybe<Evidence_Version_File>;
  id: Scalars['uuid']['output'];
  isUploaded?: Maybe<Scalars['Boolean']['output']>;
  metadata?: Maybe<Scalars['jsonb']['output']>;
  mimeType?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  policy_versions: Array<Policy_Versions>;
  /** An object relationship */
  questionnaire_file?: Maybe<Questionnaires>;
  /** An array relationship */
  questionnaire_uploads: Array<Questionnaire_Uploads>;
  /** An object relationship */
  risk_document?: Maybe<Risk_Documents>;
  size?: Maybe<Scalars['Int']['output']>;
  updatedAt: Scalars['timestamptz']['output'];
  uploadedByUserId?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  vendor_document?: Maybe<Vendor_Documents>;
  /** An array relationship */
  vendor_questionnaire_form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  /** An array relationship */
  vendor_questionnaire_uploads: Array<Vendor_Questionnaire_Uploads>;
};

/** columns and relationships of "storage.files" */
export type FilesExport_Client_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "storage.files" */
export type FilesPolicy_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesQuestionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesVendor_Questionnaire_Form_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

/** columns and relationships of "storage.files" */
export type FilesVendor_Questionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "storage.files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  bucketId?: InputMaybe<String_Comparison_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  etag?: InputMaybe<String_Comparison_Exp>;
  export_client_questionnaires?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  files_client_questionnaire?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  files_comment_upload?: InputMaybe<Comment_Uploads_Bool_Exp>;
  files_evidence_version_file?: InputMaybe<Evidence_Version_File_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  isUploaded?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  mimeType?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  policy_versions?: InputMaybe<Policy_Versions_Bool_Exp>;
  questionnaire_file?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_uploads?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
  risk_document?: InputMaybe<Risk_Documents_Bool_Exp>;
  size?: InputMaybe<Int_Comparison_Exp>;
  updatedAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploadedByUserId?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_document?: InputMaybe<Vendor_Documents_Bool_Exp>;
  vendor_questionnaire_form_uploads?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
  vendor_questionnaire_uploads?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** unique or primary key constraints on table "storage.files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey',
}

/** input type for inserting data into table "storage.files" */
export type Files_Insert_Input = {
  bucketId?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  etag?: InputMaybe<Scalars['String']['input']>;
  export_client_questionnaires?: InputMaybe<Client_Questionnaires_Arr_Rel_Insert_Input>;
  files_client_questionnaire?: InputMaybe<Client_Questionnaires_Obj_Rel_Insert_Input>;
  files_comment_upload?: InputMaybe<Comment_Uploads_Obj_Rel_Insert_Input>;
  files_evidence_version_file?: InputMaybe<Evidence_Version_File_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  isUploaded?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
  mimeType?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  policy_versions?: InputMaybe<Policy_Versions_Arr_Rel_Insert_Input>;
  questionnaire_file?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_uploads?: InputMaybe<Questionnaire_Uploads_Arr_Rel_Insert_Input>;
  risk_document?: InputMaybe<Risk_Documents_Obj_Rel_Insert_Input>;
  size?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['timestamptz']['input']>;
  uploadedByUserId?: InputMaybe<Scalars['uuid']['input']>;
  vendor_document?: InputMaybe<Vendor_Documents_Obj_Rel_Insert_Input>;
};

/** response of any mutation on the table "storage.files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "storage.files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "storage.files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "storage.files". */
export type Files_Order_By = {
  bucketId?: InputMaybe<Order_By>;
  createdAt?: InputMaybe<Order_By>;
  etag?: InputMaybe<Order_By>;
  export_client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Order_By>;
  files_client_questionnaire?: InputMaybe<Client_Questionnaires_Order_By>;
  files_comment_upload?: InputMaybe<Comment_Uploads_Order_By>;
  files_evidence_version_file?: InputMaybe<Evidence_Version_File_Order_By>;
  id?: InputMaybe<Order_By>;
  isUploaded?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  mimeType?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Order_By>;
  questionnaire_file?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_uploads_aggregate?: InputMaybe<Questionnaire_Uploads_Aggregate_Order_By>;
  risk_document?: InputMaybe<Risk_Documents_Order_By>;
  size?: InputMaybe<Order_By>;
  updatedAt?: InputMaybe<Order_By>;
  uploadedByUserId?: InputMaybe<Order_By>;
  vendor_document?: InputMaybe<Vendor_Documents_Order_By>;
  vendor_questionnaire_form_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Aggregate_Order_By>;
  vendor_questionnaire_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Uploads_Aggregate_Order_By>;
};

/** placeholder for update columns of table "storage.files" (current role has no relevant permissions) */
export enum Files_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export enum Finding_Causes_Enum {
  AiRecommendation = 'ai_recommendation',
  AtRiskEvidence = 'at_risk_evidence',
  ExpiredEvidence = 'expired_evidence',
  IntegrationCheck = 'integration_check',
  IntegrationError = 'integration_error',
  ManualConsideration = 'manual_consideration',
  ManualIssue = 'manual_issue',
  ManualRecommendation = 'manual_recommendation',
  PolicyError = 'policy_error',
}

/** Boolean expression to compare columns of type "finding_causes_enum". All fields are combined with logical 'AND'. */
export type Finding_Causes_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Finding_Causes_Enum>;
  _in?: InputMaybe<Array<Finding_Causes_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Finding_Causes_Enum>;
  _nin?: InputMaybe<Array<Finding_Causes_Enum>>;
};

export enum Finding_Types_Enum {
  Consideration = 'consideration',
  Issue = 'issue',
  Recommendation = 'recommendation',
}

/** Boolean expression to compare columns of type "finding_types_enum". All fields are combined with logical 'AND'. */
export type Finding_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Finding_Types_Enum>;
  _in?: InputMaybe<Array<Finding_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Finding_Types_Enum>;
  _nin?: InputMaybe<Array<Finding_Types_Enum>>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** columns and relationships of "forms" */
export type Forms = {
  __typename?: 'forms';
  config?: Maybe<Scalars['FormConfig']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  questionnaire?: Maybe<Questionnaires>;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "forms" */
export type FormsConfigArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Forms_Append_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "forms". All fields are combined with a logical 'AND'. */
export type Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Forms_Bool_Exp>>;
  _not?: InputMaybe<Forms_Bool_Exp>;
  _or?: InputMaybe<Array<Forms_Bool_Exp>>;
  config?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "forms" */
export enum Forms_Constraint {
  /** unique or primary key constraint on columns "id" */
  FormsPkey = 'forms_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Forms_Delete_At_Path_Input = {
  config?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Forms_Delete_Elem_Input = {
  config?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Forms_Delete_Key_Input = {
  config?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "forms" */
export type Forms_Insert_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
  questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
};

/** response of any mutation on the table "forms" */
export type Forms_Mutation_Response = {
  __typename?: 'forms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Forms>;
};

/** input type for inserting object relation for remote table "forms" */
export type Forms_Obj_Rel_Insert_Input = {
  data: Forms_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};

/** on_conflict condition type for table "forms" */
export type Forms_On_Conflict = {
  constraint: Forms_Constraint;
  update_columns?: Array<Forms_Update_Column>;
  where?: InputMaybe<Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "forms". */
export type Forms_Order_By = {
  config?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: forms */
export type Forms_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Forms_Prepend_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for updating data in table "forms" */
export type Forms_Set_Input = {
  config?: InputMaybe<Scalars['jsonb']['input']>;
};

/** update columns of table "forms" */
export enum Forms_Update_Column {
  /** column name */
  Config = 'config',
}

export type Forms_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Forms_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Forms_Set_Input>;
  /** filter the rows which have to be updated */
  where: Forms_Bool_Exp;
};

/** columns and relationships of "frameworks" */
export type Frameworks = {
  __typename?: 'frameworks';
  active: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  frameworks_controls: Array<Frameworks_Controls>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  programs: Array<Programs>;
};

/** columns and relationships of "frameworks" */
export type FrameworksFrameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

/** columns and relationships of "frameworks" */
export type FrameworksProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** order by aggregate values of table "frameworks" */
export type Frameworks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Frameworks_Max_Order_By>;
  min?: InputMaybe<Frameworks_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "frameworks". All fields are combined with a logical 'AND'. */
export type Frameworks_Bool_Exp = {
  _and?: InputMaybe<Array<Frameworks_Bool_Exp>>;
  _not?: InputMaybe<Frameworks_Bool_Exp>;
  _or?: InputMaybe<Array<Frameworks_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  frameworks_controls?: InputMaybe<Frameworks_Controls_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  programs?: InputMaybe<Programs_Bool_Exp>;
};

/** unique or primary key constraints on table "frameworks" */
export enum Frameworks_Constraint {
  /** unique or primary key constraint on columns "id" */
  FrameworksPkey1 = 'frameworks_pkey1',
}

/** columns and relationships of "frameworks_controls" */
export type Frameworks_Controls = {
  __typename?: 'frameworks_controls';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  /** An object relationship */
  framework: Frameworks;
  framework_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "frameworks_controls" */
export type Frameworks_Controls_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Frameworks_Controls_Max_Order_By>;
  min?: InputMaybe<Frameworks_Controls_Min_Order_By>;
};

/** input type for inserting array relation for remote table "frameworks_controls" */
export type Frameworks_Controls_Arr_Rel_Insert_Input = {
  data: Array<Frameworks_Controls_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Frameworks_Controls_On_Conflict>;
};

/** Boolean expression to filter rows from the table "frameworks_controls". All fields are combined with a logical 'AND'. */
export type Frameworks_Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Frameworks_Controls_Bool_Exp>>;
  _not?: InputMaybe<Frameworks_Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Frameworks_Controls_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  framework?: InputMaybe<Frameworks_Bool_Exp>;
  framework_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "frameworks_controls" */
export enum Frameworks_Controls_Constraint {
  /** unique or primary key constraint on columns "control_id", "framework_id" */
  FrameworksControlsPkey1 = 'frameworks_controls_pkey1',
}

/** input type for inserting data into table "frameworks_controls" */
export type Frameworks_Controls_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  framework?: InputMaybe<Frameworks_Obj_Rel_Insert_Input>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "frameworks_controls" */
export type Frameworks_Controls_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "frameworks_controls" */
export type Frameworks_Controls_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "frameworks_controls" */
export type Frameworks_Controls_Mutation_Response = {
  __typename?: 'frameworks_controls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Frameworks_Controls>;
};

/** on_conflict condition type for table "frameworks_controls" */
export type Frameworks_Controls_On_Conflict = {
  constraint: Frameworks_Controls_Constraint;
  update_columns?: Array<Frameworks_Controls_Update_Column>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

/** Ordering options when selecting data from "frameworks_controls". */
export type Frameworks_Controls_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  framework?: InputMaybe<Frameworks_Order_By>;
  framework_id?: InputMaybe<Order_By>;
};

/** select columns of table "frameworks_controls" */
export enum Frameworks_Controls_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  FrameworkId = 'framework_id',
}

/** Streaming cursor of the table "frameworks_controls" */
export type Frameworks_Controls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Frameworks_Controls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Frameworks_Controls_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "frameworks_controls" (current role has no relevant permissions) */
export enum Frameworks_Controls_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** input type for inserting data into table "frameworks" */
export type Frameworks_Insert_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  frameworks_controls?: InputMaybe<Frameworks_Controls_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  programs?: InputMaybe<Programs_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "frameworks" */
export type Frameworks_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "frameworks" */
export type Frameworks_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "frameworks" */
export type Frameworks_Mutation_Response = {
  __typename?: 'frameworks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Frameworks>;
};

/** input type for inserting object relation for remote table "frameworks" */
export type Frameworks_Obj_Rel_Insert_Input = {
  data: Frameworks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Frameworks_On_Conflict>;
};

/** on_conflict condition type for table "frameworks" */
export type Frameworks_On_Conflict = {
  constraint: Frameworks_Constraint;
  update_columns?: Array<Frameworks_Update_Column>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

/** Ordering options when selecting data from "frameworks". */
export type Frameworks_Order_By = {
  active?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  frameworks_controls_aggregate?: InputMaybe<Frameworks_Controls_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  programs_aggregate?: InputMaybe<Programs_Aggregate_Order_By>;
};

/** select columns of table "frameworks" */
export enum Frameworks_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
}

/** Streaming cursor of the table "frameworks" */
export type Frameworks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Frameworks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Frameworks_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "frameworks" (current role has no relevant permissions) */
export enum Frameworks_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export enum Frequencies_Enum {
  Annual = 'Annual',
  Biannual = 'Biannual',
  Daily = 'Daily',
  Indefinite = 'Indefinite',
  Monthly = 'Monthly',
  Quarterly = 'Quarterly',
  ThreeYears = 'Three_years',
  TwoYears = 'Two_years',
  Weekly = 'Weekly',
}

/** Boolean expression to compare columns of type "frequencies_enum". All fields are combined with logical 'AND'. */
export type Frequencies_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Frequencies_Enum>;
  _in?: InputMaybe<Array<Frequencies_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Frequencies_Enum>;
  _nin?: InputMaybe<Array<Frequencies_Enum>>;
};

/** columns and relationships of "groups" */
export type Groups = {
  __typename?: 'groups';
  /** An array relationship */
  control_groups: Array<Control_Groups>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "groups" */
export type GroupsControl_GroupsArgs = {
  distinct_on?: InputMaybe<Array<Control_Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Groups_Order_By>>;
  where?: InputMaybe<Control_Groups_Bool_Exp>;
};

/** order by aggregate values of table "groups" */
export type Groups_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Groups_Max_Order_By>;
  min?: InputMaybe<Groups_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Bool_Exp>>;
  _not?: InputMaybe<Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Bool_Exp>>;
  control_groups?: InputMaybe<Control_Groups_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "groups" */
export enum Groups_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  GroupsOrganizationIdNameKey = 'groups_organization_id_name_key',
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey',
}

/** input type for inserting data into table "groups" */
export type Groups_Insert_Input = {
  control_groups?: InputMaybe<Control_Groups_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "groups" */
export type Groups_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "groups" */
export type Groups_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "groups" */
export type Groups_Mutation_Response = {
  __typename?: 'groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups>;
};

/** input type for inserting object relation for remote table "groups" */
export type Groups_Obj_Rel_Insert_Input = {
  data: Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** on_conflict condition type for table "groups" */
export type Groups_On_Conflict = {
  constraint: Groups_Constraint;
  update_columns?: Array<Groups_Update_Column>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "groups". */
export type Groups_Order_By = {
  control_groups_aggregate?: InputMaybe<Control_Groups_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: groups */
export type Groups_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "groups" */
export enum Groups_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "groups" */
export type Groups_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "groups" */
export type Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "groups" */
export enum Groups_Update_Column {
  /** column name */
  Name = 'name',
}

export type Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Set_Input>;
  /** filter the rows which have to be updated */
  where: Groups_Bool_Exp;
};

export enum Integration_Names_Enum {
  Aws = 'aws',
  Azure = 'azure',
  Confluence = 'confluence',
  Crowdstrike = 'crowdstrike',
  Gcp = 'gcp',
  Github = 'github',
  Jira = 'jira',
  Jumpcloud = 'jumpcloud',
  Onelogin = 'onelogin',
  Qualys = 'qualys',
  Sharepoint = 'sharepoint',
  Veracode = 'veracode',
}

/** Boolean expression to compare columns of type "integration_names_enum". All fields are combined with logical 'AND'. */
export type Integration_Names_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Integration_Names_Enum>;
  _in?: InputMaybe<Array<Integration_Names_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Integration_Names_Enum>;
  _nin?: InputMaybe<Array<Integration_Names_Enum>>;
};

/** columns and relationships of "integration_runs" */
export type Integration_Runs = {
  __typename?: 'integration_runs';
  check_failure_message?: Maybe<Scalars['String']['output']>;
  error_message?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  evidence_integration: Evidence_Integrations;
  evidence_integration_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
};

/** Boolean expression to filter rows from the table "integration_runs". All fields are combined with a logical 'AND'. */
export type Integration_Runs_Bool_Exp = {
  _and?: InputMaybe<Array<Integration_Runs_Bool_Exp>>;
  _not?: InputMaybe<Integration_Runs_Bool_Exp>;
  _or?: InputMaybe<Array<Integration_Runs_Bool_Exp>>;
  check_failure_message?: InputMaybe<String_Comparison_Exp>;
  error_message?: InputMaybe<String_Comparison_Exp>;
  evidence_integration?: InputMaybe<Evidence_Integrations_Bool_Exp>;
  evidence_integration_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "integration_runs". */
export type Integration_Runs_Order_By = {
  check_failure_message?: InputMaybe<Order_By>;
  error_message?: InputMaybe<Order_By>;
  evidence_integration?: InputMaybe<Evidence_Integrations_Order_By>;
  evidence_integration_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "integrations" */
export type Integrations = {
  __typename?: 'integrations';
  disabled: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  name: Integration_Names_Enum;
  /** An array relationship */
  organization_integrations: Array<Organization_Integrations>;
};

/** columns and relationships of "integrations" */
export type IntegrationsOrganization_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "integrations". All fields are combined with a logical 'AND'. */
export type Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Integrations_Bool_Exp>>;
  _not?: InputMaybe<Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Integrations_Bool_Exp>>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<Integration_Names_Enum_Comparison_Exp>;
  organization_integrations?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

/** Ordering options when selecting data from "integrations". */
export type Integrations_Order_By = {
  disabled?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_integrations_aggregate?: InputMaybe<Organization_Integrations_Aggregate_Order_By>;
};

/** select columns of table "integrations" */
export enum Integrations_Select_Column {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "integrations" */
export type Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Integrations_Stream_Cursor_Value_Input = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Integration_Names_Enum>;
};

/** columns and relationships of "invitations" */
export type Invitations = {
  __typename?: 'invitations';
  created_at: Scalars['timestamptz']['output'];
  email: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  is_joined: Scalars['Boolean']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  role: Roles;
  role_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "invitations" */
export type Invitations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Invitations_Max_Order_By>;
  min?: InputMaybe<Invitations_Min_Order_By>;
};

/** input type for inserting array relation for remote table "invitations" */
export type Invitations_Arr_Rel_Insert_Input = {
  data: Array<Invitations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Invitations_On_Conflict>;
};

/** Boolean expression to filter rows from the table "invitations". All fields are combined with a logical 'AND'. */
export type Invitations_Bool_Exp = {
  _and?: InputMaybe<Array<Invitations_Bool_Exp>>;
  _not?: InputMaybe<Invitations_Bool_Exp>;
  _or?: InputMaybe<Array<Invitations_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_joined?: InputMaybe<Boolean_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "invitations" */
export enum Invitations_Constraint {
  /** unique or primary key constraint on columns "id" */
  InvitationsPkey = 'invitations_pkey',
}

/** input type for inserting data into table "invitations" */
export type Invitations_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_joined?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "invitations" */
export type Invitations_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "invitations" */
export type Invitations_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "invitations" */
export type Invitations_Mutation_Response = {
  __typename?: 'invitations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Invitations>;
};

/** on_conflict condition type for table "invitations" */
export type Invitations_On_Conflict = {
  constraint: Invitations_Constraint;
  update_columns?: Array<Invitations_Update_Column>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** Ordering options when selecting data from "invitations". */
export type Invitations_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_joined?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "invitations" */
export enum Invitations_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsJoined = 'is_joined',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "invitations" */
export type Invitations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Invitations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Invitations_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_joined?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "invitations" (current role has no relevant permissions) */
export enum Invitations_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "manual_findings" */
export type Manual_Findings = {
  __typename?: 'manual_findings';
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  title: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "manual_findings" */
export type Manual_FindingsControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "manual_findings". All fields are combined with a logical 'AND'. */
export type Manual_Findings_Bool_Exp = {
  _and?: InputMaybe<Array<Manual_Findings_Bool_Exp>>;
  _not?: InputMaybe<Manual_Findings_Bool_Exp>;
  _or?: InputMaybe<Array<Manual_Findings_Bool_Exp>>;
  control_findings?: InputMaybe<Control_Findings_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** response of any mutation on the table "manual_findings" */
export type Manual_Findings_Mutation_Response = {
  __typename?: 'manual_findings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Manual_Findings>;
};

/** Ordering options when selecting data from "manual_findings". */
export type Manual_Findings_Order_By = {
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Use AI to autocomplete an entity */
  ai_autocomplete_entity?: Maybe<AiAutocompleteEntityOutput>;
  /** Use AI to create a new entity */
  ai_create_entity?: Maybe<AiCreateEntityOutput>;
  /** Generate a sparse vector representation for the text */
  ai_generate_sparse_vector: SparseVectorOutput;
  /** This action is used to change evidence owner */
  change_evidence_owner?: Maybe<GeneralActionOutput>;
  /** This action is used to create custom control */
  create_control?: Maybe<CreateControlOutput>;
  create_custom_program_request?: Maybe<CreateCustomProgramRequestOutput>;
  create_framework_request?: Maybe<FrameworkRequestOutput>;
  create_manual_finding?: Maybe<CreateManualFindingOutput>;
  create_program?: Maybe<ProgramOutput>;
  /** delete single row from the table: "auth.refresh_tokens" */
  deleteAuthRefreshToken?: Maybe<AuthRefreshTokens>;
  /** delete data from the table: "auth.refresh_tokens" */
  deleteAuthRefreshTokens?: Maybe<AuthRefreshTokens_Mutation_Response>;
  /** delete data from the table: "client_questionnaires" */
  delete_client_questionnaires?: Maybe<Client_Questionnaires_Mutation_Response>;
  /** delete single row from the table: "client_questionnaires" */
  delete_client_questionnaires_by_pk?: Maybe<Client_Questionnaires>;
  /** delete data from the table: "comment_uploads" */
  delete_comment_uploads?: Maybe<Comment_Uploads_Mutation_Response>;
  /** delete single row from the table: "comment_uploads" */
  delete_comment_uploads_by_pk?: Maybe<Comment_Uploads>;
  /** delete data from the table: "comments" */
  delete_comments?: Maybe<Comments_Mutation_Response>;
  /** delete single row from the table: "comments" */
  delete_comments_by_pk?: Maybe<Comments>;
  /** delete data from the table: "control_categories" */
  delete_control_categories?: Maybe<Control_Categories_Mutation_Response>;
  /** delete single row from the table: "control_categories" */
  delete_control_categories_by_pk?: Maybe<Control_Categories>;
  /** delete data from the table: "control_criteria" */
  delete_control_criteria?: Maybe<Control_Criteria_Mutation_Response>;
  /** delete single row from the table: "control_criteria" */
  delete_control_criteria_by_pk?: Maybe<Control_Criteria>;
  /** delete data from the table: "control_evidences" */
  delete_control_evidences?: Maybe<Control_Evidences_Mutation_Response>;
  /** delete single row from the table: "control_evidences" */
  delete_control_evidences_by_pk?: Maybe<Control_Evidences>;
  /** delete data from the table: "control_groups" */
  delete_control_groups?: Maybe<Control_Groups_Mutation_Response>;
  /** delete single row from the table: "control_groups" */
  delete_control_groups_by_pk?: Maybe<Control_Groups>;
  /** delete data from the table: "control_tags" */
  delete_control_tags?: Maybe<Control_Tags_Mutation_Response>;
  /** delete single row from the table: "control_tags" */
  delete_control_tags_by_pk?: Maybe<Control_Tags>;
  /** delete data from the table: "control_tasks" */
  delete_control_tasks?: Maybe<Control_Tasks_Mutation_Response>;
  /** delete single row from the table: "control_tasks" */
  delete_control_tasks_by_pk?: Maybe<Control_Tasks>;
  /** delete data from the table: "controls" */
  delete_controls?: Maybe<Controls_Mutation_Response>;
  /** delete single row from the table: "controls" */
  delete_controls_by_pk?: Maybe<Controls>;
  /** delete data from the table: "evidence_policies" */
  delete_evidence_policies?: Maybe<Evidence_Policies_Mutation_Response>;
  /** delete single row from the table: "evidence_policies" */
  delete_evidence_policies_by_pk?: Maybe<Evidence_Policies>;
  /** delete data from the table: "evidence_tags" */
  delete_evidence_tags?: Maybe<Evidence_Tags_Mutation_Response>;
  /** delete single row from the table: "evidence_tags" */
  delete_evidence_tags_by_pk?: Maybe<Evidence_Tags>;
  /** delete data from the table: "evidence_version_file" */
  delete_evidence_version_file?: Maybe<Evidence_Version_File_Mutation_Response>;
  /** delete single row from the table: "evidence_version_file" */
  delete_evidence_version_file_by_pk?: Maybe<Evidence_Version_File>;
  /** delete data from the table: "evidence_versions" */
  delete_evidence_versions?: Maybe<Evidence_Versions_Mutation_Response>;
  /** delete single row from the table: "evidence_versions" */
  delete_evidence_versions_by_pk?: Maybe<Evidence_Versions>;
  /** delete data from the table: "evidences" */
  delete_evidences?: Maybe<Evidences_Mutation_Response>;
  /** delete single row from the table: "evidences" */
  delete_evidences_by_pk?: Maybe<Evidences>;
  /** delete data from the table: "field_configs" */
  delete_field_configs?: Maybe<Field_Configs_Mutation_Response>;
  /** delete single row from the table: "field_configs" */
  delete_field_configs_by_pk?: Maybe<Field_Configs>;
  /** delete data from the table: "field_values" */
  delete_field_values?: Maybe<Field_Values_Mutation_Response>;
  /** delete single row from the table: "field_values" */
  delete_field_values_by_pk?: Maybe<Field_Values>;
  /** delete data from the table: "forms" */
  delete_forms?: Maybe<Forms_Mutation_Response>;
  /** delete single row from the table: "forms" */
  delete_forms_by_pk?: Maybe<Forms>;
  /** delete data from the table: "invitations" */
  delete_invitations?: Maybe<Invitations_Mutation_Response>;
  /** delete single row from the table: "invitations" */
  delete_invitations_by_pk?: Maybe<Invitations>;
  /** delete data from the table: "manual_findings" */
  delete_manual_findings?: Maybe<Manual_Findings_Mutation_Response>;
  /** delete single row from the table: "manual_findings" */
  delete_manual_findings_by_pk?: Maybe<Manual_Findings>;
  /** delete data from the table: "notification_disabled_settings" */
  delete_notification_disabled_settings?: Maybe<Notification_Disabled_Settings_Mutation_Response>;
  /** delete single row from the table: "notification_disabled_settings" */
  delete_notification_disabled_settings_by_pk?: Maybe<Notification_Disabled_Settings>;
  /** delete data from the table: "policies" */
  delete_policies?: Maybe<Policies_Mutation_Response>;
  /** delete single row from the table: "policies" */
  delete_policies_by_pk?: Maybe<Policies>;
  /** delete data from the table: "policy_approvers" */
  delete_policy_approvers?: Maybe<Policy_Approvers_Mutation_Response>;
  /** delete single row from the table: "policy_approvers" */
  delete_policy_approvers_by_pk?: Maybe<Policy_Approvers>;
  /** delete data from the table: "policy_tasks" */
  delete_policy_tasks?: Maybe<Policy_Tasks_Mutation_Response>;
  /** delete single row from the table: "policy_tasks" */
  delete_policy_tasks_by_pk?: Maybe<Policy_Tasks>;
  /** delete data from the table: "policy_versions" */
  delete_policy_versions?: Maybe<Policy_Versions_Mutation_Response>;
  /** delete single row from the table: "policy_versions" */
  delete_policy_versions_by_pk?: Maybe<Policy_Versions>;
  /** delete data from the table: "program_controls" */
  delete_program_controls?: Maybe<Program_Controls_Mutation_Response>;
  /** delete single row from the table: "program_controls" */
  delete_program_controls_by_pk?: Maybe<Program_Controls>;
  /** delete data from the table: "program_field_configs" */
  delete_program_field_configs?: Maybe<Program_Field_Configs_Mutation_Response>;
  /** delete single row from the table: "program_field_configs" */
  delete_program_field_configs_by_pk?: Maybe<Program_Field_Configs>;
  /** delete data from the table: "questionnaire_uploads" */
  delete_questionnaire_uploads?: Maybe<Questionnaire_Uploads_Mutation_Response>;
  /** delete single row from the table: "questionnaire_uploads" */
  delete_questionnaire_uploads_by_pk?: Maybe<Questionnaire_Uploads>;
  /** delete data from the table: "questionnaires" */
  delete_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** delete single row from the table: "questionnaires" */
  delete_questionnaires_by_pk?: Maybe<Questionnaires>;
  /** delete data from the table: "reports" */
  delete_reports?: Maybe<Reports_Mutation_Response>;
  /** delete single row from the table: "reports" */
  delete_reports_by_pk?: Maybe<Reports>;
  /** delete data from the table: "review_files" */
  delete_review_files?: Maybe<Review_Files_Mutation_Response>;
  /** delete single row from the table: "review_files" */
  delete_review_files_by_pk?: Maybe<Review_Files>;
  /** delete data from the table: "reviews" */
  delete_reviews?: Maybe<Reviews_Mutation_Response>;
  /** delete single row from the table: "reviews" */
  delete_reviews_by_pk?: Maybe<Reviews>;
  /** delete data from the table: "risk_categories" */
  delete_risk_categories?: Maybe<Risk_Categories_Mutation_Response>;
  /** delete single row from the table: "risk_categories" */
  delete_risk_categories_by_pk?: Maybe<Risk_Categories>;
  /** delete data from the table: "risk_controls" */
  delete_risk_controls?: Maybe<Risk_Controls_Mutation_Response>;
  /** delete single row from the table: "risk_controls" */
  delete_risk_controls_by_pk?: Maybe<Risk_Controls>;
  /** delete data from the table: "risk_documents" */
  delete_risk_documents?: Maybe<Risk_Documents_Mutation_Response>;
  /** delete single row from the table: "risk_documents" */
  delete_risk_documents_by_pk?: Maybe<Risk_Documents>;
  /** delete data from the table: "risk_impacts" */
  delete_risk_impacts?: Maybe<Risk_Impacts_Mutation_Response>;
  /** delete single row from the table: "risk_impacts" */
  delete_risk_impacts_by_pk?: Maybe<Risk_Impacts>;
  /** delete data from the table: "risk_likelihoods" */
  delete_risk_likelihoods?: Maybe<Risk_Likelihoods_Mutation_Response>;
  /** delete single row from the table: "risk_likelihoods" */
  delete_risk_likelihoods_by_pk?: Maybe<Risk_Likelihoods>;
  /** delete data from the table: "risk_tags" */
  delete_risk_tags?: Maybe<Risk_Tags_Mutation_Response>;
  /** delete single row from the table: "risk_tags" */
  delete_risk_tags_by_pk?: Maybe<Risk_Tags>;
  /** delete data from the table: "risk_tasks" */
  delete_risk_tasks?: Maybe<Risk_Tasks_Mutation_Response>;
  /** delete single row from the table: "risk_tasks" */
  delete_risk_tasks_by_pk?: Maybe<Risk_Tasks>;
  /** delete data from the table: "risks" */
  delete_risks?: Maybe<Risks_Mutation_Response>;
  /** delete single row from the table: "risks" */
  delete_risks_by_pk?: Maybe<Risks>;
  /** delete data from the table: "role_permissions" */
  delete_role_permissions?: Maybe<Role_Permissions_Mutation_Response>;
  /** delete single row from the table: "role_permissions" */
  delete_role_permissions_by_pk?: Maybe<Role_Permissions>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "select_field_options" */
  delete_select_field_options?: Maybe<Select_Field_Options_Mutation_Response>;
  /** delete single row from the table: "select_field_options" */
  delete_select_field_options_by_pk?: Maybe<Select_Field_Options>;
  /** delete data from the table: "task_owners" */
  delete_task_owners?: Maybe<Task_Owners_Mutation_Response>;
  /** delete single row from the table: "task_owners" */
  delete_task_owners_by_pk?: Maybe<Task_Owners>;
  /** delete data from the table: "tasks" */
  delete_tasks?: Maybe<Tasks_Mutation_Response>;
  /** delete single row from the table: "tasks" */
  delete_tasks_by_pk?: Maybe<Tasks>;
  /** delete data from the table: "vendor_documents" */
  delete_vendor_documents?: Maybe<Vendor_Documents_Mutation_Response>;
  /** delete single row from the table: "vendor_documents" */
  delete_vendor_documents_by_pk?: Maybe<Vendor_Documents>;
  /** delete data from the table: "vendor_questionnaire_forms" */
  delete_vendor_questionnaire_forms?: Maybe<Vendor_Questionnaire_Forms_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_forms" */
  delete_vendor_questionnaire_forms_by_pk?: Maybe<Vendor_Questionnaire_Forms>;
  /** delete data from the table: "vendor_questionnaire_uploads" */
  delete_vendor_questionnaire_uploads?: Maybe<Vendor_Questionnaire_Uploads_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaire_uploads" */
  delete_vendor_questionnaire_uploads_by_pk?: Maybe<Vendor_Questionnaire_Uploads>;
  /** delete data from the table: "vendor_questionnaires" */
  delete_vendor_questionnaires?: Maybe<Vendor_Questionnaires_Mutation_Response>;
  /** delete single row from the table: "vendor_questionnaires" */
  delete_vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
  /** delete data from the table: "vendor_risks" */
  delete_vendor_risks?: Maybe<Vendor_Risks_Mutation_Response>;
  /** delete single row from the table: "vendor_risks" */
  delete_vendor_risks_by_pk?: Maybe<Vendor_Risks>;
  /** delete data from the table: "vendor_tasks" */
  delete_vendor_tasks?: Maybe<Vendor_Tasks_Mutation_Response>;
  /** delete single row from the table: "vendor_tasks" */
  delete_vendor_tasks_by_pk?: Maybe<Vendor_Tasks>;
  /** delete data from the table: "vendors" */
  delete_vendors?: Maybe<Vendors_Mutation_Response>;
  /** delete single row from the table: "vendors" */
  delete_vendors_by_pk?: Maybe<Vendors>;
  /** action to duplicate a control */
  duplicate_control?: Maybe<CreateControlOutput>;
  /** action to duplicate a questionnaire */
  duplicate_questionnaire?: Maybe<CreateQuestionnaireOutput>;
  /** Trigger export of the Client Questionnaire */
  export_client_questionnaire?: Maybe<ExportClientQuestionnaireOutput>;
  export_policy?: Maybe<ExportPolicyOutput>;
  gen_integration_run_report?: Maybe<GenIntegrationRunReportOutput>;
  /** This action is used to generate admin link to manage audit trail in workos */
  generate_workos_admin_link?: Maybe<GenerateWorkosAdminLinkOutput>;
  grant_evidence_access?: Maybe<GrantEvidenceAccessOutput>;
  /** insert a single row into the table: "storage.files" */
  insertFile?: Maybe<Files>;
  /** insert data into the table: "storage.files" */
  insertFiles?: Maybe<Files_Mutation_Response>;
  /** insert data into the table: "categories" */
  insert_categories?: Maybe<Categories_Mutation_Response>;
  /** insert a single row into the table: "categories" */
  insert_categories_one?: Maybe<Categories>;
  /** insert data into the table: "client_questionnaire_answers" */
  insert_client_questionnaire_answers?: Maybe<Client_Questionnaire_Answers_Mutation_Response>;
  /** insert a single row into the table: "client_questionnaire_answers" */
  insert_client_questionnaire_answers_one?: Maybe<Client_Questionnaire_Answers>;
  /** insert data into the table: "client_questionnaires" */
  insert_client_questionnaires?: Maybe<Client_Questionnaires_Mutation_Response>;
  /** insert a single row into the table: "client_questionnaires" */
  insert_client_questionnaires_one?: Maybe<Client_Questionnaires>;
  /** insert data into the table: "comment_uploads" */
  insert_comment_uploads?: Maybe<Comment_Uploads_Mutation_Response>;
  /** insert a single row into the table: "comment_uploads" */
  insert_comment_uploads_one?: Maybe<Comment_Uploads>;
  /** insert data into the table: "comments" */
  insert_comments?: Maybe<Comments_Mutation_Response>;
  /** insert a single row into the table: "comments" */
  insert_comments_one?: Maybe<Comments>;
  /** insert data into the table: "control_categories" */
  insert_control_categories?: Maybe<Control_Categories_Mutation_Response>;
  /** insert a single row into the table: "control_categories" */
  insert_control_categories_one?: Maybe<Control_Categories>;
  /** insert data into the table: "control_criteria" */
  insert_control_criteria?: Maybe<Control_Criteria_Mutation_Response>;
  /** insert a single row into the table: "control_criteria" */
  insert_control_criteria_one?: Maybe<Control_Criteria>;
  /** insert data into the table: "control_evidences" */
  insert_control_evidences?: Maybe<Control_Evidences_Mutation_Response>;
  /** insert a single row into the table: "control_evidences" */
  insert_control_evidences_one?: Maybe<Control_Evidences>;
  /** insert data into the table: "control_groups" */
  insert_control_groups?: Maybe<Control_Groups_Mutation_Response>;
  /** insert a single row into the table: "control_groups" */
  insert_control_groups_one?: Maybe<Control_Groups>;
  /** insert data into the table: "control_tags" */
  insert_control_tags?: Maybe<Control_Tags_Mutation_Response>;
  /** insert a single row into the table: "control_tags" */
  insert_control_tags_one?: Maybe<Control_Tags>;
  /** insert data into the table: "control_tasks" */
  insert_control_tasks?: Maybe<Control_Tasks_Mutation_Response>;
  /** insert a single row into the table: "control_tasks" */
  insert_control_tasks_one?: Maybe<Control_Tasks>;
  /** insert data into the table: "controls" */
  insert_controls?: Maybe<Controls_Mutation_Response>;
  /** insert a single row into the table: "controls" */
  insert_controls_one?: Maybe<Controls>;
  /** insert data into the table: "criteria" */
  insert_criteria?: Maybe<Criteria_Mutation_Response>;
  /** insert a single row into the table: "criteria" */
  insert_criteria_one?: Maybe<Criteria>;
  /** insert data into the table: "date_field_values" */
  insert_date_field_values?: Maybe<Date_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "date_field_values" */
  insert_date_field_values_one?: Maybe<Date_Field_Values>;
  /** insert data into the table: "documents" */
  insert_documents?: Maybe<Documents_Mutation_Response>;
  /** insert a single row into the table: "documents" */
  insert_documents_one?: Maybe<Documents>;
  /** insert data into the table: "email_field_values" */
  insert_email_field_values?: Maybe<Email_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "email_field_values" */
  insert_email_field_values_one?: Maybe<Email_Field_Values>;
  /** insert data into the table: "evidence_policies" */
  insert_evidence_policies?: Maybe<Evidence_Policies_Mutation_Response>;
  /** insert a single row into the table: "evidence_policies" */
  insert_evidence_policies_one?: Maybe<Evidence_Policies>;
  /** insert data into the table: "evidence_tags" */
  insert_evidence_tags?: Maybe<Evidence_Tags_Mutation_Response>;
  /** insert a single row into the table: "evidence_tags" */
  insert_evidence_tags_one?: Maybe<Evidence_Tags>;
  /** insert data into the table: "evidence_version_file" */
  insert_evidence_version_file?: Maybe<Evidence_Version_File_Mutation_Response>;
  /** insert a single row into the table: "evidence_version_file" */
  insert_evidence_version_file_one?: Maybe<Evidence_Version_File>;
  /** insert data into the table: "evidence_versions" */
  insert_evidence_versions?: Maybe<Evidence_Versions_Mutation_Response>;
  /** insert a single row into the table: "evidence_versions" */
  insert_evidence_versions_one?: Maybe<Evidence_Versions>;
  /** insert data into the table: "evidences" */
  insert_evidences?: Maybe<Evidences_Mutation_Response>;
  /** insert a single row into the table: "evidences" */
  insert_evidences_one?: Maybe<Evidences>;
  /** insert data into the table: "field_configs" */
  insert_field_configs?: Maybe<Field_Configs_Mutation_Response>;
  /** insert a single row into the table: "field_configs" */
  insert_field_configs_one?: Maybe<Field_Configs>;
  /** insert data into the table: "field_values" */
  insert_field_values?: Maybe<Field_Values_Mutation_Response>;
  /** insert a single row into the table: "field_values" */
  insert_field_values_one?: Maybe<Field_Values>;
  /** insert data into the table: "forms" */
  insert_forms?: Maybe<Forms_Mutation_Response>;
  /** insert a single row into the table: "forms" */
  insert_forms_one?: Maybe<Forms>;
  /** insert data into the table: "frameworks" */
  insert_frameworks?: Maybe<Frameworks_Mutation_Response>;
  /** insert data into the table: "frameworks_controls" */
  insert_frameworks_controls?: Maybe<Frameworks_Controls_Mutation_Response>;
  /** insert a single row into the table: "frameworks_controls" */
  insert_frameworks_controls_one?: Maybe<Frameworks_Controls>;
  /** insert a single row into the table: "frameworks" */
  insert_frameworks_one?: Maybe<Frameworks>;
  /** insert data into the table: "groups" */
  insert_groups?: Maybe<Groups_Mutation_Response>;
  /** insert a single row into the table: "groups" */
  insert_groups_one?: Maybe<Groups>;
  /** insert data into the table: "invitations" */
  insert_invitations?: Maybe<Invitations_Mutation_Response>;
  /** insert a single row into the table: "invitations" */
  insert_invitations_one?: Maybe<Invitations>;
  /** insert data into the table: "notification_disabled_settings" */
  insert_notification_disabled_settings?: Maybe<Notification_Disabled_Settings_Mutation_Response>;
  /** insert a single row into the table: "notification_disabled_settings" */
  insert_notification_disabled_settings_one?: Maybe<Notification_Disabled_Settings>;
  /** insert data into the table: "number_field_values" */
  insert_number_field_values?: Maybe<Number_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "number_field_values" */
  insert_number_field_values_one?: Maybe<Number_Field_Values>;
  /** insert data into the table: "organization_evidence_tags" */
  insert_organization_evidence_tags?: Maybe<Organization_Evidence_Tags_Mutation_Response>;
  /** insert a single row into the table: "organization_evidence_tags" */
  insert_organization_evidence_tags_one?: Maybe<Organization_Evidence_Tags>;
  /** insert data into the table: "organization_risk_categories" */
  insert_organization_risk_categories?: Maybe<Organization_Risk_Categories_Mutation_Response>;
  /** insert a single row into the table: "organization_risk_categories" */
  insert_organization_risk_categories_one?: Maybe<Organization_Risk_Categories>;
  /** insert data into the table: "organization_risk_tags" */
  insert_organization_risk_tags?: Maybe<Organization_Risk_Tags_Mutation_Response>;
  /** insert a single row into the table: "organization_risk_tags" */
  insert_organization_risk_tags_one?: Maybe<Organization_Risk_Tags>;
  /** insert data into the table: "organization_vendor_risk_levels" */
  insert_organization_vendor_risk_levels?: Maybe<Organization_Vendor_Risk_Levels_Mutation_Response>;
  /** insert a single row into the table: "organization_vendor_risk_levels" */
  insert_organization_vendor_risk_levels_one?: Maybe<Organization_Vendor_Risk_Levels>;
  /** insert data into the table: "organization_vendor_tiers" */
  insert_organization_vendor_tiers?: Maybe<Organization_Vendor_Tiers_Mutation_Response>;
  /** insert a single row into the table: "organization_vendor_tiers" */
  insert_organization_vendor_tiers_one?: Maybe<Organization_Vendor_Tiers>;
  /** insert data into the table: "policies" */
  insert_policies?: Maybe<Policies_Mutation_Response>;
  /** insert a single row into the table: "policies" */
  insert_policies_one?: Maybe<Policies>;
  /** insert data into the table: "policy_acknowledgement_users" */
  insert_policy_acknowledgement_users?: Maybe<Policy_Acknowledgement_Users_Mutation_Response>;
  /** insert a single row into the table: "policy_acknowledgement_users" */
  insert_policy_acknowledgement_users_one?: Maybe<Policy_Acknowledgement_Users>;
  /** insert data into the table: "policy_acknowledgements" */
  insert_policy_acknowledgements?: Maybe<Policy_Acknowledgements_Mutation_Response>;
  /** insert a single row into the table: "policy_acknowledgements" */
  insert_policy_acknowledgements_one?: Maybe<Policy_Acknowledgements>;
  /** insert data into the table: "policy_approval_users" */
  insert_policy_approval_users?: Maybe<Policy_Approval_Users_Mutation_Response>;
  /** insert a single row into the table: "policy_approval_users" */
  insert_policy_approval_users_one?: Maybe<Policy_Approval_Users>;
  /** insert data into the table: "policy_approvals" */
  insert_policy_approvals?: Maybe<Policy_Approvals_Mutation_Response>;
  /** insert a single row into the table: "policy_approvals" */
  insert_policy_approvals_one?: Maybe<Policy_Approvals>;
  /** insert data into the table: "policy_approvers" */
  insert_policy_approvers?: Maybe<Policy_Approvers_Mutation_Response>;
  /** insert a single row into the table: "policy_approvers" */
  insert_policy_approvers_one?: Maybe<Policy_Approvers>;
  /** insert data into the table: "policy_tasks" */
  insert_policy_tasks?: Maybe<Policy_Tasks_Mutation_Response>;
  /** insert a single row into the table: "policy_tasks" */
  insert_policy_tasks_one?: Maybe<Policy_Tasks>;
  /** insert data into the table: "policy_versions" */
  insert_policy_versions?: Maybe<Policy_Versions_Mutation_Response>;
  /** insert a single row into the table: "policy_versions" */
  insert_policy_versions_one?: Maybe<Policy_Versions>;
  /** insert data into the table: "program_controls" */
  insert_program_controls?: Maybe<Program_Controls_Mutation_Response>;
  /** insert a single row into the table: "program_controls" */
  insert_program_controls_one?: Maybe<Program_Controls>;
  /** insert data into the table: "program_field_configs" */
  insert_program_field_configs?: Maybe<Program_Field_Configs_Mutation_Response>;
  /** insert a single row into the table: "program_field_configs" */
  insert_program_field_configs_one?: Maybe<Program_Field_Configs>;
  /** insert data into the table: "programs" */
  insert_programs?: Maybe<Programs_Mutation_Response>;
  /** insert a single row into the table: "programs" */
  insert_programs_one?: Maybe<Programs>;
  /** insert data into the table: "questionnaire_uploads" */
  insert_questionnaire_uploads?: Maybe<Questionnaire_Uploads_Mutation_Response>;
  /** insert a single row into the table: "questionnaire_uploads" */
  insert_questionnaire_uploads_one?: Maybe<Questionnaire_Uploads>;
  /** insert data into the table: "questionnaires" */
  insert_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** insert a single row into the table: "questionnaires" */
  insert_questionnaires_one?: Maybe<Questionnaires>;
  /** insert data into the table: "reports" */
  insert_reports?: Maybe<Reports_Mutation_Response>;
  /** insert a single row into the table: "reports" */
  insert_reports_one?: Maybe<Reports>;
  /** insert data into the table: "review_files" */
  insert_review_files?: Maybe<Review_Files_Mutation_Response>;
  /** insert a single row into the table: "review_files" */
  insert_review_files_one?: Maybe<Review_Files>;
  /** insert data into the table: "reviews" */
  insert_reviews?: Maybe<Reviews_Mutation_Response>;
  /** insert a single row into the table: "reviews" */
  insert_reviews_one?: Maybe<Reviews>;
  /** insert data into the table: "risk_categories" */
  insert_risk_categories?: Maybe<Risk_Categories_Mutation_Response>;
  /** insert a single row into the table: "risk_categories" */
  insert_risk_categories_one?: Maybe<Risk_Categories>;
  /** insert data into the table: "risk_controls" */
  insert_risk_controls?: Maybe<Risk_Controls_Mutation_Response>;
  /** insert a single row into the table: "risk_controls" */
  insert_risk_controls_one?: Maybe<Risk_Controls>;
  /** insert data into the table: "risk_documents" */
  insert_risk_documents?: Maybe<Risk_Documents_Mutation_Response>;
  /** insert a single row into the table: "risk_documents" */
  insert_risk_documents_one?: Maybe<Risk_Documents>;
  /** insert data into the table: "risk_impacts" */
  insert_risk_impacts?: Maybe<Risk_Impacts_Mutation_Response>;
  /** insert a single row into the table: "risk_impacts" */
  insert_risk_impacts_one?: Maybe<Risk_Impacts>;
  /** insert data into the table: "risk_likelihoods" */
  insert_risk_likelihoods?: Maybe<Risk_Likelihoods_Mutation_Response>;
  /** insert a single row into the table: "risk_likelihoods" */
  insert_risk_likelihoods_one?: Maybe<Risk_Likelihoods>;
  /** insert data into the table: "risk_tags" */
  insert_risk_tags?: Maybe<Risk_Tags_Mutation_Response>;
  /** insert a single row into the table: "risk_tags" */
  insert_risk_tags_one?: Maybe<Risk_Tags>;
  /** insert data into the table: "risk_tasks" */
  insert_risk_tasks?: Maybe<Risk_Tasks_Mutation_Response>;
  /** insert a single row into the table: "risk_tasks" */
  insert_risk_tasks_one?: Maybe<Risk_Tasks>;
  /** insert data into the table: "risks" */
  insert_risks?: Maybe<Risks_Mutation_Response>;
  /** insert a single row into the table: "risks" */
  insert_risks_one?: Maybe<Risks>;
  /** insert data into the table: "role_permissions" */
  insert_role_permissions?: Maybe<Role_Permissions_Mutation_Response>;
  /** insert a single row into the table: "role_permissions" */
  insert_role_permissions_one?: Maybe<Role_Permissions>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "select_field_configs" */
  insert_select_field_configs?: Maybe<Select_Field_Configs_Mutation_Response>;
  /** insert a single row into the table: "select_field_configs" */
  insert_select_field_configs_one?: Maybe<Select_Field_Configs>;
  /** insert data into the table: "select_field_options" */
  insert_select_field_options?: Maybe<Select_Field_Options_Mutation_Response>;
  /** insert a single row into the table: "select_field_options" */
  insert_select_field_options_one?: Maybe<Select_Field_Options>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "task_owners" */
  insert_task_owners?: Maybe<Task_Owners_Mutation_Response>;
  /** insert a single row into the table: "task_owners" */
  insert_task_owners_one?: Maybe<Task_Owners>;
  /** insert data into the table: "tasks" */
  insert_tasks?: Maybe<Tasks_Mutation_Response>;
  /** insert a single row into the table: "tasks" */
  insert_tasks_one?: Maybe<Tasks>;
  /** insert data into the table: "text_field_values" */
  insert_text_field_values?: Maybe<Text_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "text_field_values" */
  insert_text_field_values_one?: Maybe<Text_Field_Values>;
  /** insert data into the table: "url_field_values" */
  insert_url_field_values?: Maybe<Url_Field_Values_Mutation_Response>;
  /** insert a single row into the table: "url_field_values" */
  insert_url_field_values_one?: Maybe<Url_Field_Values>;
  /** insert data into the table: "vendor_documents" */
  insert_vendor_documents?: Maybe<Vendor_Documents_Mutation_Response>;
  /** insert a single row into the table: "vendor_documents" */
  insert_vendor_documents_one?: Maybe<Vendor_Documents>;
  /** insert data into the table: "vendor_questionnaire_forms" */
  insert_vendor_questionnaire_forms?: Maybe<Vendor_Questionnaire_Forms_Mutation_Response>;
  /** insert a single row into the table: "vendor_questionnaire_forms" */
  insert_vendor_questionnaire_forms_one?: Maybe<Vendor_Questionnaire_Forms>;
  /** insert data into the table: "vendor_risks" */
  insert_vendor_risks?: Maybe<Vendor_Risks_Mutation_Response>;
  /** insert a single row into the table: "vendor_risks" */
  insert_vendor_risks_one?: Maybe<Vendor_Risks>;
  /** insert data into the table: "vendor_tasks" */
  insert_vendor_tasks?: Maybe<Vendor_Tasks_Mutation_Response>;
  /** insert a single row into the table: "vendor_tasks" */
  insert_vendor_tasks_one?: Maybe<Vendor_Tasks>;
  /** insert data into the table: "vendors" */
  insert_vendors?: Maybe<Vendors_Mutation_Response>;
  /** insert a single row into the table: "vendors" */
  insert_vendors_one?: Maybe<Vendors>;
  invite_user?: Maybe<InviteUserOutput>;
  join_organization?: Maybe<GeneralActionOutput>;
  revoke_evidence_access?: Maybe<RevokeEvidenceAccessOutput>;
  /** Send questionnaire to multiple vendors */
  sendVendorQuestionnaire?: Maybe<SendVendorQuestionnaireOutput>;
  /** This action triggers an endpoint that sends reminder for policy approval */
  send_approval_reminder?: Maybe<ApprovalReminderOutput>;
  set_evidence_confidential?: Maybe<SetEvidenceConfidentialOutput>;
  set_select_field_values?: Maybe<SetSelectFieldValuesOutput>;
  toggle_close_out_risk?: Maybe<ToggleRiskCloseOutOutput>;
  toggle_terminate_vendor?: Maybe<ToggleTerminateVendorOutput>;
  /** update single row of the table: "auth.users" */
  updateUser?: Maybe<Users>;
  /** update data of the table: "auth.users" */
  updateUsers?: Maybe<Users_Mutation_Response>;
  /** update data of the table: "categories" */
  update_categories?: Maybe<Categories_Mutation_Response>;
  /** update single row of the table: "categories" */
  update_categories_by_pk?: Maybe<Categories>;
  /** update multiples rows of table: "categories" */
  update_categories_many?: Maybe<Array<Maybe<Categories_Mutation_Response>>>;
  /** update data of the table: "client_questionnaire_questions" */
  update_client_questionnaire_questions?: Maybe<Client_Questionnaire_Questions_Mutation_Response>;
  /** update single row of the table: "client_questionnaire_questions" */
  update_client_questionnaire_questions_by_pk?: Maybe<Client_Questionnaire_Questions>;
  /** update multiples rows of table: "client_questionnaire_questions" */
  update_client_questionnaire_questions_many?: Maybe<
    Array<Maybe<Client_Questionnaire_Questions_Mutation_Response>>
  >;
  /** update data of the table: "client_questionnaires" */
  update_client_questionnaires?: Maybe<Client_Questionnaires_Mutation_Response>;
  /** update single row of the table: "client_questionnaires" */
  update_client_questionnaires_by_pk?: Maybe<Client_Questionnaires>;
  /** update multiples rows of table: "client_questionnaires" */
  update_client_questionnaires_many?: Maybe<Array<Maybe<Client_Questionnaires_Mutation_Response>>>;
  /** update data of the table: "comments" */
  update_comments?: Maybe<Comments_Mutation_Response>;
  /** update single row of the table: "comments" */
  update_comments_by_pk?: Maybe<Comments>;
  /** update multiples rows of table: "comments" */
  update_comments_many?: Maybe<Array<Maybe<Comments_Mutation_Response>>>;
  /** update data of the table: "control_findings" */
  update_control_findings?: Maybe<Control_Findings_Mutation_Response>;
  /** update single row of the table: "control_findings" */
  update_control_findings_by_pk?: Maybe<Control_Findings>;
  /** update multiples rows of table: "control_findings" */
  update_control_findings_many?: Maybe<Array<Maybe<Control_Findings_Mutation_Response>>>;
  /** update data of the table: "controls" */
  update_controls?: Maybe<Controls_Mutation_Response>;
  /** update single row of the table: "controls" */
  update_controls_by_pk?: Maybe<Controls>;
  /** update multiples rows of table: "controls" */
  update_controls_many?: Maybe<Array<Maybe<Controls_Mutation_Response>>>;
  /** update data of the table: "criteria" */
  update_criteria?: Maybe<Criteria_Mutation_Response>;
  /** update single row of the table: "criteria" */
  update_criteria_by_pk?: Maybe<Criteria>;
  /** update multiples rows of table: "criteria" */
  update_criteria_many?: Maybe<Array<Maybe<Criteria_Mutation_Response>>>;
  /** update data of the table: "date_field_values" */
  update_date_field_values?: Maybe<Date_Field_Values_Mutation_Response>;
  /** update single row of the table: "date_field_values" */
  update_date_field_values_by_pk?: Maybe<Date_Field_Values>;
  /** update multiples rows of table: "date_field_values" */
  update_date_field_values_many?: Maybe<Array<Maybe<Date_Field_Values_Mutation_Response>>>;
  /** update data of the table: "documents" */
  update_documents?: Maybe<Documents_Mutation_Response>;
  /** update single row of the table: "documents" */
  update_documents_by_pk?: Maybe<Documents>;
  /** update multiples rows of table: "documents" */
  update_documents_many?: Maybe<Array<Maybe<Documents_Mutation_Response>>>;
  /** update data of the table: "email_field_values" */
  update_email_field_values?: Maybe<Email_Field_Values_Mutation_Response>;
  /** update single row of the table: "email_field_values" */
  update_email_field_values_by_pk?: Maybe<Email_Field_Values>;
  /** update multiples rows of table: "email_field_values" */
  update_email_field_values_many?: Maybe<Array<Maybe<Email_Field_Values_Mutation_Response>>>;
  /** update data of the table: "evidence_policies" */
  update_evidence_policies?: Maybe<Evidence_Policies_Mutation_Response>;
  /** update single row of the table: "evidence_policies" */
  update_evidence_policies_by_pk?: Maybe<Evidence_Policies>;
  /** update multiples rows of table: "evidence_policies" */
  update_evidence_policies_many?: Maybe<Array<Maybe<Evidence_Policies_Mutation_Response>>>;
  /** update data of the table: "evidence_versions" */
  update_evidence_versions?: Maybe<Evidence_Versions_Mutation_Response>;
  /** update single row of the table: "evidence_versions" */
  update_evidence_versions_by_pk?: Maybe<Evidence_Versions>;
  /** update multiples rows of table: "evidence_versions" */
  update_evidence_versions_many?: Maybe<Array<Maybe<Evidence_Versions_Mutation_Response>>>;
  /** update data of the table: "evidences" */
  update_evidences?: Maybe<Evidences_Mutation_Response>;
  /** update single row of the table: "evidences" */
  update_evidences_by_pk?: Maybe<Evidences>;
  /** update multiples rows of table: "evidences" */
  update_evidences_many?: Maybe<Array<Maybe<Evidences_Mutation_Response>>>;
  /** update data of the table: "field_configs" */
  update_field_configs?: Maybe<Field_Configs_Mutation_Response>;
  /** update single row of the table: "field_configs" */
  update_field_configs_by_pk?: Maybe<Field_Configs>;
  /** update multiples rows of table: "field_configs" */
  update_field_configs_many?: Maybe<Array<Maybe<Field_Configs_Mutation_Response>>>;
  /** update data of the table: "forms" */
  update_forms?: Maybe<Forms_Mutation_Response>;
  /** update single row of the table: "forms" */
  update_forms_by_pk?: Maybe<Forms>;
  /** update multiples rows of table: "forms" */
  update_forms_many?: Maybe<Array<Maybe<Forms_Mutation_Response>>>;
  /** update data of the table: "groups" */
  update_groups?: Maybe<Groups_Mutation_Response>;
  /** update single row of the table: "groups" */
  update_groups_by_pk?: Maybe<Groups>;
  /** update multiples rows of table: "groups" */
  update_groups_many?: Maybe<Array<Maybe<Groups_Mutation_Response>>>;
  /** update data of the table: "number_field_values" */
  update_number_field_values?: Maybe<Number_Field_Values_Mutation_Response>;
  /** update single row of the table: "number_field_values" */
  update_number_field_values_by_pk?: Maybe<Number_Field_Values>;
  /** update multiples rows of table: "number_field_values" */
  update_number_field_values_many?: Maybe<Array<Maybe<Number_Field_Values_Mutation_Response>>>;
  /** update data of the table: "organization_users" */
  update_organization_users?: Maybe<Organization_Users_Mutation_Response>;
  /** update single row of the table: "organization_users" */
  update_organization_users_by_pk?: Maybe<Organization_Users>;
  /** update multiples rows of table: "organization_users" */
  update_organization_users_many?: Maybe<Array<Maybe<Organization_Users_Mutation_Response>>>;
  /** update data of the table: "policies" */
  update_policies?: Maybe<Policies_Mutation_Response>;
  /** update single row of the table: "policies" */
  update_policies_by_pk?: Maybe<Policies>;
  /** update multiples rows of table: "policies" */
  update_policies_many?: Maybe<Array<Maybe<Policies_Mutation_Response>>>;
  /** update data of the table: "policy_acknowledgement_users" */
  update_policy_acknowledgement_users?: Maybe<Policy_Acknowledgement_Users_Mutation_Response>;
  /** update single row of the table: "policy_acknowledgement_users" */
  update_policy_acknowledgement_users_by_pk?: Maybe<Policy_Acknowledgement_Users>;
  /** update multiples rows of table: "policy_acknowledgement_users" */
  update_policy_acknowledgement_users_many?: Maybe<
    Array<Maybe<Policy_Acknowledgement_Users_Mutation_Response>>
  >;
  /** update data of the table: "policy_approval_users" */
  update_policy_approval_users?: Maybe<Policy_Approval_Users_Mutation_Response>;
  /** update single row of the table: "policy_approval_users" */
  update_policy_approval_users_by_pk?: Maybe<Policy_Approval_Users>;
  /** update multiples rows of table: "policy_approval_users" */
  update_policy_approval_users_many?: Maybe<Array<Maybe<Policy_Approval_Users_Mutation_Response>>>;
  update_policy_version_content?: Maybe<GeneralActionOutput>;
  /** update data of the table: "policy_versions" */
  update_policy_versions?: Maybe<Policy_Versions_Mutation_Response>;
  /** update single row of the table: "policy_versions" */
  update_policy_versions_by_pk?: Maybe<Policy_Versions>;
  /** update multiples rows of table: "policy_versions" */
  update_policy_versions_many?: Maybe<Array<Maybe<Policy_Versions_Mutation_Response>>>;
  /** update data of the table: "programs" */
  update_programs?: Maybe<Programs_Mutation_Response>;
  /** update single row of the table: "programs" */
  update_programs_by_pk?: Maybe<Programs>;
  /** update multiples rows of table: "programs" */
  update_programs_many?: Maybe<Array<Maybe<Programs_Mutation_Response>>>;
  /** update data of the table: "questionnaire_uploads" */
  update_questionnaire_uploads?: Maybe<Questionnaire_Uploads_Mutation_Response>;
  /** update single row of the table: "questionnaire_uploads" */
  update_questionnaire_uploads_by_pk?: Maybe<Questionnaire_Uploads>;
  /** update multiples rows of table: "questionnaire_uploads" */
  update_questionnaire_uploads_many?: Maybe<Array<Maybe<Questionnaire_Uploads_Mutation_Response>>>;
  /** update data of the table: "questionnaires" */
  update_questionnaires?: Maybe<Questionnaires_Mutation_Response>;
  /** update single row of the table: "questionnaires" */
  update_questionnaires_by_pk?: Maybe<Questionnaires>;
  /** update multiples rows of table: "questionnaires" */
  update_questionnaires_many?: Maybe<Array<Maybe<Questionnaires_Mutation_Response>>>;
  /** update data of the table: "reports" */
  update_reports?: Maybe<Reports_Mutation_Response>;
  /** update single row of the table: "reports" */
  update_reports_by_pk?: Maybe<Reports>;
  /** update multiples rows of table: "reports" */
  update_reports_many?: Maybe<Array<Maybe<Reports_Mutation_Response>>>;
  /** update data of the table: "review_files" */
  update_review_files?: Maybe<Review_Files_Mutation_Response>;
  /** update single row of the table: "review_files" */
  update_review_files_by_pk?: Maybe<Review_Files>;
  /** update multiples rows of table: "review_files" */
  update_review_files_many?: Maybe<Array<Maybe<Review_Files_Mutation_Response>>>;
  /** update data of the table: "reviews" */
  update_reviews?: Maybe<Reviews_Mutation_Response>;
  /** update single row of the table: "reviews" */
  update_reviews_by_pk?: Maybe<Reviews>;
  /** update multiples rows of table: "reviews" */
  update_reviews_many?: Maybe<Array<Maybe<Reviews_Mutation_Response>>>;
  /** update data of the table: "risk_impacts" */
  update_risk_impacts?: Maybe<Risk_Impacts_Mutation_Response>;
  /** update single row of the table: "risk_impacts" */
  update_risk_impacts_by_pk?: Maybe<Risk_Impacts>;
  /** update multiples rows of table: "risk_impacts" */
  update_risk_impacts_many?: Maybe<Array<Maybe<Risk_Impacts_Mutation_Response>>>;
  /** update data of the table: "risk_inherent_levels" */
  update_risk_inherent_levels?: Maybe<Risk_Inherent_Levels_Mutation_Response>;
  /** update single row of the table: "risk_inherent_levels" */
  update_risk_inherent_levels_by_pk?: Maybe<Risk_Inherent_Levels>;
  /** update multiples rows of table: "risk_inherent_levels" */
  update_risk_inherent_levels_many?: Maybe<Array<Maybe<Risk_Inherent_Levels_Mutation_Response>>>;
  /** update data of the table: "risk_likelihoods" */
  update_risk_likelihoods?: Maybe<Risk_Likelihoods_Mutation_Response>;
  /** update single row of the table: "risk_likelihoods" */
  update_risk_likelihoods_by_pk?: Maybe<Risk_Likelihoods>;
  /** update multiples rows of table: "risk_likelihoods" */
  update_risk_likelihoods_many?: Maybe<Array<Maybe<Risk_Likelihoods_Mutation_Response>>>;
  /** update data of the table: "risk_residual_levels" */
  update_risk_residual_levels?: Maybe<Risk_Residual_Levels_Mutation_Response>;
  /** update single row of the table: "risk_residual_levels" */
  update_risk_residual_levels_by_pk?: Maybe<Risk_Residual_Levels>;
  /** update multiples rows of table: "risk_residual_levels" */
  update_risk_residual_levels_many?: Maybe<Array<Maybe<Risk_Residual_Levels_Mutation_Response>>>;
  /** update data of the table: "risks" */
  update_risks?: Maybe<Risks_Mutation_Response>;
  /** update single row of the table: "risks" */
  update_risks_by_pk?: Maybe<Risks>;
  /** update multiples rows of table: "risks" */
  update_risks_many?: Maybe<Array<Maybe<Risks_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "select_field_configs" */
  update_select_field_configs?: Maybe<Select_Field_Configs_Mutation_Response>;
  /** update single row of the table: "select_field_configs" */
  update_select_field_configs_by_pk?: Maybe<Select_Field_Configs>;
  /** update multiples rows of table: "select_field_configs" */
  update_select_field_configs_many?: Maybe<Array<Maybe<Select_Field_Configs_Mutation_Response>>>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "tags" */
  update_tags_many?: Maybe<Array<Maybe<Tags_Mutation_Response>>>;
  /** update data of the table: "task_owners" */
  update_task_owners?: Maybe<Task_Owners_Mutation_Response>;
  /** update single row of the table: "task_owners" */
  update_task_owners_by_pk?: Maybe<Task_Owners>;
  /** update multiples rows of table: "task_owners" */
  update_task_owners_many?: Maybe<Array<Maybe<Task_Owners_Mutation_Response>>>;
  /** update data of the table: "tasks" */
  update_tasks?: Maybe<Tasks_Mutation_Response>;
  /** update single row of the table: "tasks" */
  update_tasks_by_pk?: Maybe<Tasks>;
  /** update multiples rows of table: "tasks" */
  update_tasks_many?: Maybe<Array<Maybe<Tasks_Mutation_Response>>>;
  /** update data of the table: "text_field_values" */
  update_text_field_values?: Maybe<Text_Field_Values_Mutation_Response>;
  /** update single row of the table: "text_field_values" */
  update_text_field_values_by_pk?: Maybe<Text_Field_Values>;
  /** update multiples rows of table: "text_field_values" */
  update_text_field_values_many?: Maybe<Array<Maybe<Text_Field_Values_Mutation_Response>>>;
  /** update data of the table: "url_field_values" */
  update_url_field_values?: Maybe<Url_Field_Values_Mutation_Response>;
  /** update single row of the table: "url_field_values" */
  update_url_field_values_by_pk?: Maybe<Url_Field_Values>;
  /** update multiples rows of table: "url_field_values" */
  update_url_field_values_many?: Maybe<Array<Maybe<Url_Field_Values_Mutation_Response>>>;
  /** update data of the table: "user_notifications" */
  update_user_notifications?: Maybe<User_Notifications_Mutation_Response>;
  /** update single row of the table: "user_notifications" */
  update_user_notifications_by_pk?: Maybe<User_Notifications>;
  /** update multiples rows of table: "user_notifications" */
  update_user_notifications_many?: Maybe<Array<Maybe<User_Notifications_Mutation_Response>>>;
  /** update multiples rows of table: "auth.users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "vendor_findings" */
  update_vendor_findings?: Maybe<Vendor_Findings_Mutation_Response>;
  /** update single row of the table: "vendor_findings" */
  update_vendor_findings_by_pk?: Maybe<Vendor_Findings>;
  /** update multiples rows of table: "vendor_findings" */
  update_vendor_findings_many?: Maybe<Array<Maybe<Vendor_Findings_Mutation_Response>>>;
  /** update data of the table: "vendor_questionnaires" */
  update_vendor_questionnaires?: Maybe<Vendor_Questionnaires_Mutation_Response>;
  /** update single row of the table: "vendor_questionnaires" */
  update_vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
  /** update multiples rows of table: "vendor_questionnaires" */
  update_vendor_questionnaires_many?: Maybe<Array<Maybe<Vendor_Questionnaires_Mutation_Response>>>;
  /** update data of the table: "vendors" */
  update_vendors?: Maybe<Vendors_Mutation_Response>;
  /** update single row of the table: "vendors" */
  update_vendors_by_pk?: Maybe<Vendors>;
  /** update multiples rows of table: "vendors" */
  update_vendors_many?: Maybe<Array<Maybe<Vendors_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootAi_Autocomplete_EntityArgs = {
  input: AiAutocompleteEntityInput;
};

/** mutation root */
export type Mutation_RootAi_Create_EntityArgs = {
  input: Scalars['AiCreateEntityInput']['input'];
};

/** mutation root */
export type Mutation_RootAi_Generate_Sparse_VectorArgs = {
  text: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootChange_Evidence_OwnerArgs = {
  object: ChangeEvidenceOwnerInput;
};

/** mutation root */
export type Mutation_RootCreate_ControlArgs = {
  payload: CreateControlInput;
};

/** mutation root */
export type Mutation_RootCreate_Custom_Program_RequestArgs = {
  payload: CreateCustomProgramRequestInput;
};

/** mutation root */
export type Mutation_RootCreate_Framework_RequestArgs = {
  object: FrameworkRequestInput;
};

/** mutation root */
export type Mutation_RootCreate_Manual_FindingArgs = {
  input: CreateManualFindingInput;
};

/** mutation root */
export type Mutation_RootCreate_ProgramArgs = {
  object: CreateProgramInput;
};

/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDeleteAuthRefreshTokensArgs = {
  where: AuthRefreshTokens_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_QuestionnairesArgs = {
  where: Client_Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Client_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Comment_UploadsArgs = {
  where: Comment_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Comment_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_CommentsArgs = {
  where: Comments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Comments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_CategoriesArgs = {
  where: Control_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Categories_By_PkArgs = {
  category_id: Scalars['uuid']['input'];
  control_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_CriteriaArgs = {
  where: Control_Criteria_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Criteria_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  criteria_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_EvidencesArgs = {
  where: Control_Evidences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Evidences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_GroupsArgs = {
  where: Control_Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Groups_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  group_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_TagsArgs = {
  where: Control_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Tags_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  tag_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Control_TasksArgs = {
  where: Control_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Control_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_ControlsArgs = {
  where: Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Evidence_PoliciesArgs = {
  where: Evidence_Policies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Policies_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Evidence_TagsArgs = {
  where: Evidence_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Version_FileArgs = {
  where: Evidence_Version_File_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Version_File_By_PkArgs = {
  evidence_version_id: Scalars['uuid']['input'];
  file_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Evidence_VersionsArgs = {
  where: Evidence_Versions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidence_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_EvidencesArgs = {
  where: Evidences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Evidences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Field_ConfigsArgs = {
  where: Field_Configs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Configs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Field_ValuesArgs = {
  where: Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Field_Values_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_FormsArgs = {
  where: Forms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Forms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_InvitationsArgs = {
  where: Invitations_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Invitations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Manual_FindingsArgs = {
  where: Manual_Findings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Manual_Findings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Notification_Disabled_SettingsArgs = {
  where: Notification_Disabled_Settings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Notification_Disabled_Settings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_PoliciesArgs = {
  where: Policies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policies_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_ApproversArgs = {
  where: Policy_Approvers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Approvers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_TasksArgs = {
  where: Policy_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Policy_VersionsArgs = {
  where: Policy_Versions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Policy_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Program_ControlsArgs = {
  where: Program_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Program_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  program_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Program_Field_ConfigsArgs = {
  where: Program_Field_Configs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Program_Field_Configs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Questionnaire_UploadsArgs = {
  where: Questionnaire_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Questionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_QuestionnairesArgs = {
  where: Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_ReportsArgs = {
  where: Reports_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Review_FilesArgs = {
  where: Review_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Review_Files_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_ReviewsArgs = {
  where: Reviews_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Reviews_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_CategoriesArgs = {
  where: Risk_Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_ControlsArgs = {
  where: Risk_Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_DocumentsArgs = {
  where: Risk_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Documents_By_PkArgs = {
  file_id: Scalars['uuid']['input'];
  risk_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_ImpactsArgs = {
  where: Risk_Impacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Impacts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_LikelihoodsArgs = {
  where: Risk_Likelihoods_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Likelihoods_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_TagsArgs = {
  where: Risk_Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Risk_TasksArgs = {
  where: Risk_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risk_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_RisksArgs = {
  where: Risks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Risks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Role_PermissionsArgs = {
  where: Role_Permissions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Role_Permissions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Select_Field_OptionsArgs = {
  where: Select_Field_Options_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Select_Field_Options_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Task_OwnersArgs = {
  where: Task_Owners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Task_Owners_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_TasksArgs = {
  where: Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_DocumentsArgs = {
  where: Vendor_Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Documents_By_PkArgs = {
  file_id: Scalars['uuid']['input'];
  vendor_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_FormsArgs = {
  where: Vendor_Questionnaire_Forms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Forms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_UploadsArgs = {
  where: Vendor_Questionnaire_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaire_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_QuestionnairesArgs = {
  where: Vendor_Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_RisksArgs = {
  where: Vendor_Risks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Risks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_Vendor_TasksArgs = {
  where: Vendor_Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendor_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDelete_VendorsArgs = {
  where: Vendors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Vendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootDuplicate_ControlArgs = {
  id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootDuplicate_QuestionnaireArgs = {
  id: Scalars['String']['input'];
};

/** mutation root */
export type Mutation_RootExport_Client_QuestionnaireArgs = {
  input: ExportClientQuestionnaireInput;
};

/** mutation root */
export type Mutation_RootExport_PolicyArgs = {
  input: ExportPolicyInput;
};

/** mutation root */
export type Mutation_RootGen_Integration_Run_ReportArgs = {
  input: GenIntegrationRunReportInput;
};

/** mutation root */
export type Mutation_RootGenerate_Workos_Admin_LinkArgs = {
  object: GenerateWorkosAdminLinkInput;
};

/** mutation root */
export type Mutation_RootGrant_Evidence_AccessArgs = {
  input: GrantEvidenceAccessInput;
};

/** mutation root */
export type Mutation_RootInsertFileArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsertFilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CategoriesArgs = {
  objects: Array<Categories_Insert_Input>;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Categories_OneArgs = {
  object: Categories_Insert_Input;
  on_conflict?: InputMaybe<Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_AnswersArgs = {
  objects: Array<Client_Questionnaire_Answers_Insert_Input>;
  on_conflict?: InputMaybe<Client_Questionnaire_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaire_Answers_OneArgs = {
  object: Client_Questionnaire_Answers_Insert_Input;
  on_conflict?: InputMaybe<Client_Questionnaire_Answers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_QuestionnairesArgs = {
  objects: Array<Client_Questionnaires_Insert_Input>;
  on_conflict?: InputMaybe<Client_Questionnaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Client_Questionnaires_OneArgs = {
  object: Client_Questionnaires_Insert_Input;
  on_conflict?: InputMaybe<Client_Questionnaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Comment_UploadsArgs = {
  objects: Array<Comment_Uploads_Insert_Input>;
  on_conflict?: InputMaybe<Comment_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Comment_Uploads_OneArgs = {
  object: Comment_Uploads_Insert_Input;
  on_conflict?: InputMaybe<Comment_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CommentsArgs = {
  objects: Array<Comments_Insert_Input>;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Comments_OneArgs = {
  object: Comments_Insert_Input;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_CategoriesArgs = {
  objects: Array<Control_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Control_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Categories_OneArgs = {
  object: Control_Categories_Insert_Input;
  on_conflict?: InputMaybe<Control_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_CriteriaArgs = {
  objects: Array<Control_Criteria_Insert_Input>;
  on_conflict?: InputMaybe<Control_Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Criteria_OneArgs = {
  object: Control_Criteria_Insert_Input;
  on_conflict?: InputMaybe<Control_Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_EvidencesArgs = {
  objects: Array<Control_Evidences_Insert_Input>;
  on_conflict?: InputMaybe<Control_Evidences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Evidences_OneArgs = {
  object: Control_Evidences_Insert_Input;
  on_conflict?: InputMaybe<Control_Evidences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_GroupsArgs = {
  objects: Array<Control_Groups_Insert_Input>;
  on_conflict?: InputMaybe<Control_Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Groups_OneArgs = {
  object: Control_Groups_Insert_Input;
  on_conflict?: InputMaybe<Control_Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_TagsArgs = {
  objects: Array<Control_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Control_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Tags_OneArgs = {
  object: Control_Tags_Insert_Input;
  on_conflict?: InputMaybe<Control_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_TasksArgs = {
  objects: Array<Control_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Control_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Control_Tasks_OneArgs = {
  object: Control_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Control_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ControlsArgs = {
  objects: Array<Controls_Insert_Input>;
  on_conflict?: InputMaybe<Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Controls_OneArgs = {
  object: Controls_Insert_Input;
  on_conflict?: InputMaybe<Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CriteriaArgs = {
  objects: Array<Criteria_Insert_Input>;
  on_conflict?: InputMaybe<Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Criteria_OneArgs = {
  object: Criteria_Insert_Input;
  on_conflict?: InputMaybe<Criteria_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Date_Field_ValuesArgs = {
  objects: Array<Date_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Date_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Date_Field_Values_OneArgs = {
  object: Date_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Date_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DocumentsArgs = {
  objects: Array<Documents_Insert_Input>;
  on_conflict?: InputMaybe<Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Documents_OneArgs = {
  object: Documents_Insert_Input;
  on_conflict?: InputMaybe<Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_Field_ValuesArgs = {
  objects: Array<Email_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Email_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Email_Field_Values_OneArgs = {
  object: Email_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Email_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_PoliciesArgs = {
  objects: Array<Evidence_Policies_Insert_Input>;
  on_conflict?: InputMaybe<Evidence_Policies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Policies_OneArgs = {
  object: Evidence_Policies_Insert_Input;
  on_conflict?: InputMaybe<Evidence_Policies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_TagsArgs = {
  objects: Array<Evidence_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Evidence_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Tags_OneArgs = {
  object: Evidence_Tags_Insert_Input;
  on_conflict?: InputMaybe<Evidence_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Version_FileArgs = {
  objects: Array<Evidence_Version_File_Insert_Input>;
  on_conflict?: InputMaybe<Evidence_Version_File_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Version_File_OneArgs = {
  object: Evidence_Version_File_Insert_Input;
  on_conflict?: InputMaybe<Evidence_Version_File_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_VersionsArgs = {
  objects: Array<Evidence_Versions_Insert_Input>;
  on_conflict?: InputMaybe<Evidence_Versions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidence_Versions_OneArgs = {
  object: Evidence_Versions_Insert_Input;
  on_conflict?: InputMaybe<Evidence_Versions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_EvidencesArgs = {
  objects: Array<Evidences_Insert_Input>;
  on_conflict?: InputMaybe<Evidences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Evidences_OneArgs = {
  object: Evidences_Insert_Input;
  on_conflict?: InputMaybe<Evidences_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_ConfigsArgs = {
  objects: Array<Field_Configs_Insert_Input>;
  on_conflict?: InputMaybe<Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Configs_OneArgs = {
  object: Field_Configs_Insert_Input;
  on_conflict?: InputMaybe<Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_ValuesArgs = {
  objects: Array<Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Field_Values_OneArgs = {
  object: Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FormsArgs = {
  objects: Array<Forms_Insert_Input>;
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Forms_OneArgs = {
  object: Forms_Insert_Input;
  on_conflict?: InputMaybe<Forms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FrameworksArgs = {
  objects: Array<Frameworks_Insert_Input>;
  on_conflict?: InputMaybe<Frameworks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Frameworks_ControlsArgs = {
  objects: Array<Frameworks_Controls_Insert_Input>;
  on_conflict?: InputMaybe<Frameworks_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Frameworks_Controls_OneArgs = {
  object: Frameworks_Controls_Insert_Input;
  on_conflict?: InputMaybe<Frameworks_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Frameworks_OneArgs = {
  object: Frameworks_Insert_Input;
  on_conflict?: InputMaybe<Frameworks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
  objects: Array<Groups_Insert_Input>;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
  object: Groups_Insert_Input;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_InvitationsArgs = {
  objects: Array<Invitations_Insert_Input>;
  on_conflict?: InputMaybe<Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Invitations_OneArgs = {
  object: Invitations_Insert_Input;
  on_conflict?: InputMaybe<Invitations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_Disabled_SettingsArgs = {
  objects: Array<Notification_Disabled_Settings_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Disabled_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Notification_Disabled_Settings_OneArgs = {
  object: Notification_Disabled_Settings_Insert_Input;
  on_conflict?: InputMaybe<Notification_Disabled_Settings_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Number_Field_ValuesArgs = {
  objects: Array<Number_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Number_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Number_Field_Values_OneArgs = {
  object: Number_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Number_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Evidence_TagsArgs = {
  objects: Array<Organization_Evidence_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Evidence_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Evidence_Tags_OneArgs = {
  object: Organization_Evidence_Tags_Insert_Input;
  on_conflict?: InputMaybe<Organization_Evidence_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Risk_CategoriesArgs = {
  objects: Array<Organization_Risk_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Risk_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Risk_Categories_OneArgs = {
  object: Organization_Risk_Categories_Insert_Input;
  on_conflict?: InputMaybe<Organization_Risk_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Risk_TagsArgs = {
  objects: Array<Organization_Risk_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Risk_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Risk_Tags_OneArgs = {
  object: Organization_Risk_Tags_Insert_Input;
  on_conflict?: InputMaybe<Organization_Risk_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Vendor_Risk_LevelsArgs = {
  objects: Array<Organization_Vendor_Risk_Levels_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Vendor_Risk_Levels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Vendor_Risk_Levels_OneArgs = {
  object: Organization_Vendor_Risk_Levels_Insert_Input;
  on_conflict?: InputMaybe<Organization_Vendor_Risk_Levels_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Vendor_TiersArgs = {
  objects: Array<Organization_Vendor_Tiers_Insert_Input>;
  on_conflict?: InputMaybe<Organization_Vendor_Tiers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organization_Vendor_Tiers_OneArgs = {
  object: Organization_Vendor_Tiers_Insert_Input;
  on_conflict?: InputMaybe<Organization_Vendor_Tiers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_PoliciesArgs = {
  objects: Array<Policies_Insert_Input>;
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policies_OneArgs = {
  object: Policies_Insert_Input;
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Acknowledgement_UsersArgs = {
  objects: Array<Policy_Acknowledgement_Users_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Acknowledgement_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Acknowledgement_Users_OneArgs = {
  object: Policy_Acknowledgement_Users_Insert_Input;
  on_conflict?: InputMaybe<Policy_Acknowledgement_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_AcknowledgementsArgs = {
  objects: Array<Policy_Acknowledgements_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Acknowledgements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Acknowledgements_OneArgs = {
  object: Policy_Acknowledgements_Insert_Input;
  on_conflict?: InputMaybe<Policy_Acknowledgements_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Approval_UsersArgs = {
  objects: Array<Policy_Approval_Users_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Approval_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Approval_Users_OneArgs = {
  object: Policy_Approval_Users_Insert_Input;
  on_conflict?: InputMaybe<Policy_Approval_Users_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_ApprovalsArgs = {
  objects: Array<Policy_Approvals_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Approvals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Approvals_OneArgs = {
  object: Policy_Approvals_Insert_Input;
  on_conflict?: InputMaybe<Policy_Approvals_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_ApproversArgs = {
  objects: Array<Policy_Approvers_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Approvers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Approvers_OneArgs = {
  object: Policy_Approvers_Insert_Input;
  on_conflict?: InputMaybe<Policy_Approvers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_TasksArgs = {
  objects: Array<Policy_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Tasks_OneArgs = {
  object: Policy_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Policy_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_VersionsArgs = {
  objects: Array<Policy_Versions_Insert_Input>;
  on_conflict?: InputMaybe<Policy_Versions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Policy_Versions_OneArgs = {
  object: Policy_Versions_Insert_Input;
  on_conflict?: InputMaybe<Policy_Versions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Program_ControlsArgs = {
  objects: Array<Program_Controls_Insert_Input>;
  on_conflict?: InputMaybe<Program_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Program_Controls_OneArgs = {
  object: Program_Controls_Insert_Input;
  on_conflict?: InputMaybe<Program_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Program_Field_ConfigsArgs = {
  objects: Array<Program_Field_Configs_Insert_Input>;
  on_conflict?: InputMaybe<Program_Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Program_Field_Configs_OneArgs = {
  object: Program_Field_Configs_Insert_Input;
  on_conflict?: InputMaybe<Program_Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProgramsArgs = {
  objects: Array<Programs_Insert_Input>;
  on_conflict?: InputMaybe<Programs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Programs_OneArgs = {
  object: Programs_Insert_Input;
  on_conflict?: InputMaybe<Programs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionnaire_UploadsArgs = {
  objects: Array<Questionnaire_Uploads_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaire_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionnaire_Uploads_OneArgs = {
  object: Questionnaire_Uploads_Insert_Input;
  on_conflict?: InputMaybe<Questionnaire_Uploads_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_QuestionnairesArgs = {
  objects: Array<Questionnaires_Insert_Input>;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Questionnaires_OneArgs = {
  object: Questionnaires_Insert_Input;
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ReportsArgs = {
  objects: Array<Reports_Insert_Input>;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reports_OneArgs = {
  object: Reports_Insert_Input;
  on_conflict?: InputMaybe<Reports_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_FilesArgs = {
  objects: Array<Review_Files_Insert_Input>;
  on_conflict?: InputMaybe<Review_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Review_Files_OneArgs = {
  object: Review_Files_Insert_Input;
  on_conflict?: InputMaybe<Review_Files_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ReviewsArgs = {
  objects: Array<Reviews_Insert_Input>;
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Reviews_OneArgs = {
  object: Reviews_Insert_Input;
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_CategoriesArgs = {
  objects: Array<Risk_Categories_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Categories_OneArgs = {
  object: Risk_Categories_Insert_Input;
  on_conflict?: InputMaybe<Risk_Categories_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_ControlsArgs = {
  objects: Array<Risk_Controls_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Controls_OneArgs = {
  object: Risk_Controls_Insert_Input;
  on_conflict?: InputMaybe<Risk_Controls_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_DocumentsArgs = {
  objects: Array<Risk_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Documents_OneArgs = {
  object: Risk_Documents_Insert_Input;
  on_conflict?: InputMaybe<Risk_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_ImpactsArgs = {
  objects: Array<Risk_Impacts_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Impacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Impacts_OneArgs = {
  object: Risk_Impacts_Insert_Input;
  on_conflict?: InputMaybe<Risk_Impacts_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_LikelihoodsArgs = {
  objects: Array<Risk_Likelihoods_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Likelihoods_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Likelihoods_OneArgs = {
  object: Risk_Likelihoods_Insert_Input;
  on_conflict?: InputMaybe<Risk_Likelihoods_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_TagsArgs = {
  objects: Array<Risk_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Tags_OneArgs = {
  object: Risk_Tags_Insert_Input;
  on_conflict?: InputMaybe<Risk_Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_TasksArgs = {
  objects: Array<Risk_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Risk_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risk_Tasks_OneArgs = {
  object: Risk_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Risk_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RisksArgs = {
  objects: Array<Risks_Insert_Input>;
  on_conflict?: InputMaybe<Risks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Risks_OneArgs = {
  object: Risks_Insert_Input;
  on_conflict?: InputMaybe<Risks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_PermissionsArgs = {
  objects: Array<Role_Permissions_Insert_Input>;
  on_conflict?: InputMaybe<Role_Permissions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Role_Permissions_OneArgs = {
  object: Role_Permissions_Insert_Input;
  on_conflict?: InputMaybe<Role_Permissions_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Select_Field_ConfigsArgs = {
  objects: Array<Select_Field_Configs_Insert_Input>;
  on_conflict?: InputMaybe<Select_Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Select_Field_Configs_OneArgs = {
  object: Select_Field_Configs_Insert_Input;
  on_conflict?: InputMaybe<Select_Field_Configs_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Select_Field_OptionsArgs = {
  objects: Array<Select_Field_Options_Insert_Input>;
  on_conflict?: InputMaybe<Select_Field_Options_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Select_Field_Options_OneArgs = {
  object: Select_Field_Options_Insert_Input;
  on_conflict?: InputMaybe<Select_Field_Options_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_OwnersArgs = {
  objects: Array<Task_Owners_Insert_Input>;
  on_conflict?: InputMaybe<Task_Owners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Task_Owners_OneArgs = {
  object: Task_Owners_Insert_Input;
  on_conflict?: InputMaybe<Task_Owners_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TasksArgs = {
  objects: Array<Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Tasks_OneArgs = {
  object: Tasks_Insert_Input;
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Text_Field_ValuesArgs = {
  objects: Array<Text_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Text_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Text_Field_Values_OneArgs = {
  object: Text_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Text_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Url_Field_ValuesArgs = {
  objects: Array<Url_Field_Values_Insert_Input>;
  on_conflict?: InputMaybe<Url_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Url_Field_Values_OneArgs = {
  object: Url_Field_Values_Insert_Input;
  on_conflict?: InputMaybe<Url_Field_Values_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_DocumentsArgs = {
  objects: Array<Vendor_Documents_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Documents_OneArgs = {
  object: Vendor_Documents_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Documents_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_FormsArgs = {
  objects: Array<Vendor_Questionnaire_Forms_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Forms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Questionnaire_Forms_OneArgs = {
  object: Vendor_Questionnaire_Forms_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Questionnaire_Forms_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_RisksArgs = {
  objects: Array<Vendor_Risks_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Risks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Risks_OneArgs = {
  object: Vendor_Risks_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Risks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_TasksArgs = {
  objects: Array<Vendor_Tasks_Insert_Input>;
  on_conflict?: InputMaybe<Vendor_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendor_Tasks_OneArgs = {
  object: Vendor_Tasks_Insert_Input;
  on_conflict?: InputMaybe<Vendor_Tasks_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_VendorsArgs = {
  objects: Array<Vendors_Insert_Input>;
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Vendors_OneArgs = {
  object: Vendors_Insert_Input;
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInvite_UserArgs = {
  object: InviteUserInput;
};

/** mutation root */
export type Mutation_RootJoin_OrganizationArgs = {
  object: JoinOrganizationInput;
};

/** mutation root */
export type Mutation_RootRevoke_Evidence_AccessArgs = {
  input: RevokeEvidenceAccessInput;
};

/** mutation root */
export type Mutation_RootSendVendorQuestionnaireArgs = {
  input: SendVendorQuestionnaireInput;
};

/** mutation root */
export type Mutation_RootSend_Approval_ReminderArgs = {
  input: ApprovalReminderInput;
};

/** mutation root */
export type Mutation_RootSet_Evidence_ConfidentialArgs = {
  input: SetEvidenceConfidentialInput;
};

/** mutation root */
export type Mutation_RootSet_Select_Field_ValuesArgs = {
  input: SetSelectFieldValuesInput;
};

/** mutation root */
export type Mutation_RootToggle_Close_Out_RiskArgs = {
  riskId: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootToggle_Terminate_VendorArgs = {
  vendorId: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootUpdateUserArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdateUsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_CategoriesArgs = {
  _set?: InputMaybe<Categories_Set_Input>;
  where: Categories_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Categories_By_PkArgs = {
  _set?: InputMaybe<Categories_Set_Input>;
  pk_columns: Categories_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Categories_ManyArgs = {
  updates: Array<Categories_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_QuestionsArgs = {
  _set?: InputMaybe<Client_Questionnaire_Questions_Set_Input>;
  where: Client_Questionnaire_Questions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Questions_By_PkArgs = {
  _set?: InputMaybe<Client_Questionnaire_Questions_Set_Input>;
  pk_columns: Client_Questionnaire_Questions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaire_Questions_ManyArgs = {
  updates: Array<Client_Questionnaire_Questions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Client_QuestionnairesArgs = {
  _set?: InputMaybe<Client_Questionnaires_Set_Input>;
  where: Client_Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaires_By_PkArgs = {
  _set?: InputMaybe<Client_Questionnaires_Set_Input>;
  pk_columns: Client_Questionnaires_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Client_Questionnaires_ManyArgs = {
  updates: Array<Client_Questionnaires_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CommentsArgs = {
  _set?: InputMaybe<Comments_Set_Input>;
  where: Comments_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Comments_By_PkArgs = {
  _set?: InputMaybe<Comments_Set_Input>;
  pk_columns: Comments_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Comments_ManyArgs = {
  updates: Array<Comments_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Control_FindingsArgs = {
  _set?: InputMaybe<Control_Findings_Set_Input>;
  where: Control_Findings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Findings_By_PkArgs = {
  _set?: InputMaybe<Control_Findings_Set_Input>;
  pk_columns: Control_Findings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Control_Findings_ManyArgs = {
  updates: Array<Control_Findings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ControlsArgs = {
  _set?: InputMaybe<Controls_Set_Input>;
  where: Controls_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Controls_By_PkArgs = {
  _set?: InputMaybe<Controls_Set_Input>;
  pk_columns: Controls_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Controls_ManyArgs = {
  updates: Array<Controls_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CriteriaArgs = {
  _set?: InputMaybe<Criteria_Set_Input>;
  where: Criteria_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Criteria_By_PkArgs = {
  _set?: InputMaybe<Criteria_Set_Input>;
  pk_columns: Criteria_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Criteria_ManyArgs = {
  updates: Array<Criteria_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Date_Field_ValuesArgs = {
  _set?: InputMaybe<Date_Field_Values_Set_Input>;
  where: Date_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Date_Field_Values_By_PkArgs = {
  _set?: InputMaybe<Date_Field_Values_Set_Input>;
  pk_columns: Date_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Date_Field_Values_ManyArgs = {
  updates: Array<Date_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DocumentsArgs = {
  _set?: InputMaybe<Documents_Set_Input>;
  where: Documents_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Documents_By_PkArgs = {
  _set?: InputMaybe<Documents_Set_Input>;
  pk_columns: Documents_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Documents_ManyArgs = {
  updates: Array<Documents_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Field_ValuesArgs = {
  _set?: InputMaybe<Email_Field_Values_Set_Input>;
  where: Email_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Field_Values_By_PkArgs = {
  _set?: InputMaybe<Email_Field_Values_Set_Input>;
  pk_columns: Email_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Email_Field_Values_ManyArgs = {
  updates: Array<Email_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_PoliciesArgs = {
  _set?: InputMaybe<Evidence_Policies_Set_Input>;
  where: Evidence_Policies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Policies_By_PkArgs = {
  _set?: InputMaybe<Evidence_Policies_Set_Input>;
  pk_columns: Evidence_Policies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Policies_ManyArgs = {
  updates: Array<Evidence_Policies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_VersionsArgs = {
  _set?: InputMaybe<Evidence_Versions_Set_Input>;
  where: Evidence_Versions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Versions_By_PkArgs = {
  _set?: InputMaybe<Evidence_Versions_Set_Input>;
  pk_columns: Evidence_Versions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evidence_Versions_ManyArgs = {
  updates: Array<Evidence_Versions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_EvidencesArgs = {
  _set?: InputMaybe<Evidences_Set_Input>;
  where: Evidences_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Evidences_By_PkArgs = {
  _set?: InputMaybe<Evidences_Set_Input>;
  pk_columns: Evidences_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Evidences_ManyArgs = {
  updates: Array<Evidences_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Field_ConfigsArgs = {
  _set?: InputMaybe<Field_Configs_Set_Input>;
  where: Field_Configs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Configs_By_PkArgs = {
  _set?: InputMaybe<Field_Configs_Set_Input>;
  pk_columns: Field_Configs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Field_Configs_ManyArgs = {
  updates: Array<Field_Configs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FormsArgs = {
  _append?: InputMaybe<Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  _set?: InputMaybe<Forms_Set_Input>;
  where: Forms_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Forms_By_PkArgs = {
  _append?: InputMaybe<Forms_Append_Input>;
  _delete_at_path?: InputMaybe<Forms_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Forms_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Forms_Delete_Key_Input>;
  _prepend?: InputMaybe<Forms_Prepend_Input>;
  _set?: InputMaybe<Forms_Set_Input>;
  pk_columns: Forms_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Forms_ManyArgs = {
  updates: Array<Forms_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
  _set?: InputMaybe<Groups_Set_Input>;
  where: Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
  _set?: InputMaybe<Groups_Set_Input>;
  pk_columns: Groups_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Groups_ManyArgs = {
  updates: Array<Groups_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Field_ValuesArgs = {
  _inc?: InputMaybe<Number_Field_Values_Inc_Input>;
  _set?: InputMaybe<Number_Field_Values_Set_Input>;
  where: Number_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Field_Values_By_PkArgs = {
  _inc?: InputMaybe<Number_Field_Values_Inc_Input>;
  _set?: InputMaybe<Number_Field_Values_Set_Input>;
  pk_columns: Number_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Number_Field_Values_ManyArgs = {
  updates: Array<Number_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_UsersArgs = {
  _set?: InputMaybe<Organization_Users_Set_Input>;
  where: Organization_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Users_By_PkArgs = {
  _set?: InputMaybe<Organization_Users_Set_Input>;
  pk_columns: Organization_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organization_Users_ManyArgs = {
  updates: Array<Organization_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_PoliciesArgs = {
  _set?: InputMaybe<Policies_Set_Input>;
  where: Policies_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policies_By_PkArgs = {
  _set?: InputMaybe<Policies_Set_Input>;
  pk_columns: Policies_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policies_ManyArgs = {
  updates: Array<Policies_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgement_UsersArgs = {
  _set?: InputMaybe<Policy_Acknowledgement_Users_Set_Input>;
  where: Policy_Acknowledgement_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgement_Users_By_PkArgs = {
  _set?: InputMaybe<Policy_Acknowledgement_Users_Set_Input>;
  pk_columns: Policy_Acknowledgement_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Acknowledgement_Users_ManyArgs = {
  updates: Array<Policy_Acknowledgement_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approval_UsersArgs = {
  _set?: InputMaybe<Policy_Approval_Users_Set_Input>;
  where: Policy_Approval_Users_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approval_Users_By_PkArgs = {
  _set?: InputMaybe<Policy_Approval_Users_Set_Input>;
  pk_columns: Policy_Approval_Users_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Approval_Users_ManyArgs = {
  updates: Array<Policy_Approval_Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Version_ContentArgs = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_text?: InputMaybe<Scalars['String']['input']>;
  policy_version_id: Scalars['uuid']['input'];
};

/** mutation root */
export type Mutation_RootUpdate_Policy_VersionsArgs = {
  _set?: InputMaybe<Policy_Versions_Set_Input>;
  where: Policy_Versions_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Versions_By_PkArgs = {
  _set?: InputMaybe<Policy_Versions_Set_Input>;
  pk_columns: Policy_Versions_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Policy_Versions_ManyArgs = {
  updates: Array<Policy_Versions_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProgramsArgs = {
  _set?: InputMaybe<Programs_Set_Input>;
  where: Programs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Programs_By_PkArgs = {
  _set?: InputMaybe<Programs_Set_Input>;
  pk_columns: Programs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Programs_ManyArgs = {
  updates: Array<Programs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Questionnaire_UploadsArgs = {
  _set?: InputMaybe<Questionnaire_Uploads_Set_Input>;
  where: Questionnaire_Uploads_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Uploads_By_PkArgs = {
  _set?: InputMaybe<Questionnaire_Uploads_Set_Input>;
  pk_columns: Questionnaire_Uploads_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Questionnaire_Uploads_ManyArgs = {
  updates: Array<Questionnaire_Uploads_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_QuestionnairesArgs = {
  _set?: InputMaybe<Questionnaires_Set_Input>;
  where: Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Questionnaires_By_PkArgs = {
  _set?: InputMaybe<Questionnaires_Set_Input>;
  pk_columns: Questionnaires_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Questionnaires_ManyArgs = {
  updates: Array<Questionnaires_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ReportsArgs = {
  _append?: InputMaybe<Reports_Append_Input>;
  _delete_at_path?: InputMaybe<Reports_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reports_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reports_Delete_Key_Input>;
  _prepend?: InputMaybe<Reports_Prepend_Input>;
  _set?: InputMaybe<Reports_Set_Input>;
  where: Reports_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reports_By_PkArgs = {
  _append?: InputMaybe<Reports_Append_Input>;
  _delete_at_path?: InputMaybe<Reports_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Reports_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Reports_Delete_Key_Input>;
  _prepend?: InputMaybe<Reports_Prepend_Input>;
  _set?: InputMaybe<Reports_Set_Input>;
  pk_columns: Reports_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Reports_ManyArgs = {
  updates: Array<Reports_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Review_FilesArgs = {
  _set?: InputMaybe<Review_Files_Set_Input>;
  where: Review_Files_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Files_By_PkArgs = {
  _set?: InputMaybe<Review_Files_Set_Input>;
  pk_columns: Review_Files_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Review_Files_ManyArgs = {
  updates: Array<Review_Files_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ReviewsArgs = {
  _set?: InputMaybe<Reviews_Set_Input>;
  where: Reviews_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Reviews_By_PkArgs = {
  _set?: InputMaybe<Reviews_Set_Input>;
  pk_columns: Reviews_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Reviews_ManyArgs = {
  updates: Array<Reviews_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_ImpactsArgs = {
  _set?: InputMaybe<Risk_Impacts_Set_Input>;
  where: Risk_Impacts_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Impacts_By_PkArgs = {
  _set?: InputMaybe<Risk_Impacts_Set_Input>;
  pk_columns: Risk_Impacts_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Impacts_ManyArgs = {
  updates: Array<Risk_Impacts_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Inherent_LevelsArgs = {
  _set?: InputMaybe<Risk_Inherent_Levels_Set_Input>;
  where: Risk_Inherent_Levels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Inherent_Levels_By_PkArgs = {
  _set?: InputMaybe<Risk_Inherent_Levels_Set_Input>;
  pk_columns: Risk_Inherent_Levels_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Inherent_Levels_ManyArgs = {
  updates: Array<Risk_Inherent_Levels_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_LikelihoodsArgs = {
  _set?: InputMaybe<Risk_Likelihoods_Set_Input>;
  where: Risk_Likelihoods_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Likelihoods_By_PkArgs = {
  _set?: InputMaybe<Risk_Likelihoods_Set_Input>;
  pk_columns: Risk_Likelihoods_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Likelihoods_ManyArgs = {
  updates: Array<Risk_Likelihoods_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Residual_LevelsArgs = {
  _set?: InputMaybe<Risk_Residual_Levels_Set_Input>;
  where: Risk_Residual_Levels_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Residual_Levels_By_PkArgs = {
  _set?: InputMaybe<Risk_Residual_Levels_Set_Input>;
  pk_columns: Risk_Residual_Levels_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risk_Residual_Levels_ManyArgs = {
  updates: Array<Risk_Residual_Levels_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RisksArgs = {
  _set?: InputMaybe<Risks_Set_Input>;
  where: Risks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Risks_By_PkArgs = {
  _set?: InputMaybe<Risks_Set_Input>;
  pk_columns: Risks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Risks_ManyArgs = {
  updates: Array<Risks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_ConfigsArgs = {
  _set?: InputMaybe<Select_Field_Configs_Set_Input>;
  where: Select_Field_Configs_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_Configs_By_PkArgs = {
  _set?: InputMaybe<Select_Field_Configs_Set_Input>;
  pk_columns: Select_Field_Configs_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Select_Field_Configs_ManyArgs = {
  updates: Array<Select_Field_Configs_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tags_ManyArgs = {
  updates: Array<Tags_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Task_OwnersArgs = {
  _set?: InputMaybe<Task_Owners_Set_Input>;
  where: Task_Owners_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Owners_By_PkArgs = {
  _set?: InputMaybe<Task_Owners_Set_Input>;
  pk_columns: Task_Owners_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Task_Owners_ManyArgs = {
  updates: Array<Task_Owners_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TasksArgs = {
  _set?: InputMaybe<Tasks_Set_Input>;
  where: Tasks_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_By_PkArgs = {
  _set?: InputMaybe<Tasks_Set_Input>;
  pk_columns: Tasks_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Tasks_ManyArgs = {
  updates: Array<Tasks_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Text_Field_ValuesArgs = {
  _set?: InputMaybe<Text_Field_Values_Set_Input>;
  where: Text_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Text_Field_Values_By_PkArgs = {
  _set?: InputMaybe<Text_Field_Values_Set_Input>;
  pk_columns: Text_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Text_Field_Values_ManyArgs = {
  updates: Array<Text_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Url_Field_ValuesArgs = {
  _set?: InputMaybe<Url_Field_Values_Set_Input>;
  where: Url_Field_Values_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Url_Field_Values_By_PkArgs = {
  _set?: InputMaybe<Url_Field_Values_Set_Input>;
  pk_columns: Url_Field_Values_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Url_Field_Values_ManyArgs = {
  updates: Array<Url_Field_Values_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_User_NotificationsArgs = {
  _set?: InputMaybe<User_Notifications_Set_Input>;
  where: User_Notifications_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_User_Notifications_By_PkArgs = {
  _set?: InputMaybe<User_Notifications_Set_Input>;
  pk_columns: User_Notifications_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_User_Notifications_ManyArgs = {
  updates: Array<User_Notifications_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_FindingsArgs = {
  _set?: InputMaybe<Vendor_Findings_Set_Input>;
  where: Vendor_Findings_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Findings_By_PkArgs = {
  _set?: InputMaybe<Vendor_Findings_Set_Input>;
  pk_columns: Vendor_Findings_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Findings_ManyArgs = {
  updates: Array<Vendor_Findings_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_QuestionnairesArgs = {
  _set?: InputMaybe<Vendor_Questionnaires_Set_Input>;
  where: Vendor_Questionnaires_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaires_By_PkArgs = {
  _set?: InputMaybe<Vendor_Questionnaires_Set_Input>;
  pk_columns: Vendor_Questionnaires_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendor_Questionnaires_ManyArgs = {
  updates: Array<Vendor_Questionnaires_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_VendorsArgs = {
  _set?: InputMaybe<Vendors_Set_Input>;
  where: Vendors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Vendors_By_PkArgs = {
  _set?: InputMaybe<Vendors_Set_Input>;
  pk_columns: Vendors_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Vendors_ManyArgs = {
  updates: Array<Vendors_Updates>;
};

export enum Notification_Delivery_Types_Enum {
  Email = 'email',
  Platform = 'platform',
}

/** Boolean expression to compare columns of type "notification_delivery_types_enum". All fields are combined with logical 'AND'. */
export type Notification_Delivery_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Delivery_Types_Enum>;
  _in?: InputMaybe<Array<Notification_Delivery_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Notification_Delivery_Types_Enum>;
  _nin?: InputMaybe<Array<Notification_Delivery_Types_Enum>>;
};

/** entries for disabling a notification delivery type */
export type Notification_Disabled_Settings = {
  __typename?: 'notification_disabled_settings';
  created_at: Scalars['timestamptz']['output'];
  delivery_type: Notification_Delivery_Types_Enum;
  id: Scalars['uuid']['output'];
  type: Notification_Types_Enum;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notification_Disabled_Settings_Max_Order_By>;
  min?: InputMaybe<Notification_Disabled_Settings_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "notification_disabled_settings". All fields are combined with a logical 'AND'. */
export type Notification_Disabled_Settings_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Disabled_Settings_Bool_Exp>>;
  _not?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Disabled_Settings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Notification_Types_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_disabled_settings" */
export enum Notification_Disabled_Settings_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationDisabledSettingsPkey = 'notification_disabled_settings_pkey',
  /** unique or primary key constraint on columns "delivery_type", "user_id", "type" */
  NotificationDisabledSettingsUserIdTypeDeliveryTypeKey = 'notification_disabled_settings_user_id_type_delivery_type_key',
}

/** input type for inserting data into table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Insert_Input = {
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum>;
  type?: InputMaybe<Notification_Types_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Mutation_Response = {
  __typename?: 'notification_disabled_settings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Disabled_Settings>;
};

/** on_conflict condition type for table "notification_disabled_settings" */
export type Notification_Disabled_Settings_On_Conflict = {
  constraint: Notification_Disabled_Settings_Constraint;
  update_columns?: Array<Notification_Disabled_Settings_Update_Column>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_disabled_settings". */
export type Notification_Disabled_Settings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  delivery_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "notification_disabled_settings" */
export enum Notification_Disabled_Settings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryType = 'delivery_type',
  /** column name */
  Id = 'id',
  /** column name */
  Type = 'type',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "notification_disabled_settings" */
export type Notification_Disabled_Settings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Disabled_Settings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Disabled_Settings_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  type?: InputMaybe<Notification_Types_Enum>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "notification_disabled_settings" (current role has no relevant permissions) */
export enum Notification_Disabled_Settings_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export enum Notification_Types_Enum {
  ClientQuestionnaireProcessingDone = 'client_questionnaire_processing_done',
  ClientQuestionnaireProcessingFailed = 'client_questionnaire_processing_failed',
  ClientQuestionnaireStatusExpiring = 'client_questionnaire_status_expiring',
  ClientQuestionnaireStatusOverdue = 'client_questionnaire_status_overdue',
  ControlStatusChangedToNotValid = 'control_status_changed_to_not_valid',
  EntityOwnershipChanged = 'entity_ownership_changed',
  EvidenceAccessGranted = 'evidence_access_granted',
  MentionedInComment = 'mentioned_in_comment',
  OverdueTasksAssignedToYou = 'overdue_tasks_assigned_to_you',
  OverdueTasksCreatedByYou = 'overdue_tasks_created_by_you',
  PolicyAcknowledgementReminder = 'policy_acknowledgement_reminder',
  PolicyApprovalReminder = 'policy_approval_reminder',
  QuestionnaireStatusOverdue = 'questionnaire_status_overdue',
  RiskStatusChangedToExpiring = 'risk_status_changed_to_expiring',
  VendorQuestionnaireSubmission = 'vendor_questionnaire_submission',
  VendorStatusChangedToExpiring = 'vendor_status_changed_to_expiring',
}

/** Boolean expression to compare columns of type "notification_types_enum". All fields are combined with logical 'AND'. */
export type Notification_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Notification_Types_Enum>;
  _in?: InputMaybe<Array<Notification_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Notification_Types_Enum>;
  _nin?: InputMaybe<Array<Notification_Types_Enum>>;
};

/** this table contains a notification entries which could have many users associated to */
export type Notifications = {
  __typename?: 'notifications';
  /** An object relationship */
  client_questionnaire?: Maybe<Client_Questionnaires>;
  /** An object relationship */
  control?: Maybe<Controls>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  notifications_comment?: Maybe<Comments>;
  /** An object relationship */
  notifications_evidence?: Maybe<Evidences>;
  /** An object relationship */
  notifications_risk?: Maybe<Risks>;
  /** An object relationship */
  notifications_vendor?: Maybe<Vendors>;
  /** An object relationship */
  notifications_vendor_questionnaire?: Maybe<Vendor_Questionnaires>;
  params: Scalars['NotificationParams']['output'];
  /** An object relationship */
  policy?: Maybe<Policies>;
  /** An object relationship */
  policy_version?: Maybe<Policy_Versions>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  type: Notification_Types_Enum;
  /** An array relationship */
  user_notifications: Array<User_Notifications>;
  /** An aggregate relationship */
  user_notifications_aggregate: User_Notifications_Aggregate;
};

/** this table contains a notification entries which could have many users associated to */
export type NotificationsParamsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** this table contains a notification entries which could have many users associated to */
export type NotificationsUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** this table contains a notification entries which could have many users associated to */
export type NotificationsUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notifications_Max_Order_By>;
  min?: InputMaybe<Notifications_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  client_questionnaire?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  control?: InputMaybe<Controls_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notifications_comment?: InputMaybe<Comments_Bool_Exp>;
  notifications_evidence?: InputMaybe<Evidences_Bool_Exp>;
  notifications_risk?: InputMaybe<Risks_Bool_Exp>;
  notifications_vendor?: InputMaybe<Vendors_Bool_Exp>;
  notifications_vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  params?: InputMaybe<Jsonb_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_version?: InputMaybe<Policy_Versions_Bool_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  type?: InputMaybe<Notification_Types_Enum_Comparison_Exp>;
  user_notifications?: InputMaybe<User_Notifications_Bool_Exp>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Bool_Exp>;
};

export type Notifications_Insert_Input = {
  __typename?: 'notifications_insert_input';
  params: Scalars['NotificationParams']['output'];
};

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  client_questionnaire?: InputMaybe<Client_Questionnaires_Order_By>;
  control?: InputMaybe<Controls_Order_By>;
  id?: InputMaybe<Order_By>;
  notifications_comment?: InputMaybe<Comments_Order_By>;
  notifications_evidence?: InputMaybe<Evidences_Order_By>;
  notifications_risk?: InputMaybe<Risks_Order_By>;
  notifications_vendor?: InputMaybe<Vendors_Order_By>;
  notifications_vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  params?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_version?: InputMaybe<Policy_Versions_Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  type?: InputMaybe<Order_By>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Order_By>;
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Params = 'params',
  /** column name */
  Type = 'type',
}

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  params?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Notification_Types_Enum>;
};

/** columns and relationships of "number_field_values" */
export type Number_Field_Values = {
  __typename?: 'number_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['numeric']['output'];
};

/** order by aggregate values of table "number_field_values" */
export type Number_Field_Values_Aggregate_Order_By = {
  avg?: InputMaybe<Number_Field_Values_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Number_Field_Values_Max_Order_By>;
  min?: InputMaybe<Number_Field_Values_Min_Order_By>;
  stddev?: InputMaybe<Number_Field_Values_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Number_Field_Values_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Number_Field_Values_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Number_Field_Values_Sum_Order_By>;
  var_pop?: InputMaybe<Number_Field_Values_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Number_Field_Values_Var_Samp_Order_By>;
  variance?: InputMaybe<Number_Field_Values_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "number_field_values" */
export type Number_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Number_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Number_Field_Values_On_Conflict>;
};

/** order by avg() on columns of table "number_field_values" */
export type Number_Field_Values_Avg_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "number_field_values". All fields are combined with a logical 'AND'. */
export type Number_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Number_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Number_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Number_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "number_field_values" */
export enum Number_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  NumberFieldValuesPkey = 'number_field_values_pkey',
}

/** input type for incrementing numeric columns in table "number_field_values" */
export type Number_Field_Values_Inc_Input = {
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** input type for inserting data into table "number_field_values" */
export type Number_Field_Values_Insert_Input = {
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** order by max() on columns of table "number_field_values" */
export type Number_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "number_field_values" */
export type Number_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "number_field_values" */
export type Number_Field_Values_Mutation_Response = {
  __typename?: 'number_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Number_Field_Values>;
};

/** input type for inserting object relation for remote table "number_field_values" */
export type Number_Field_Values_Obj_Rel_Insert_Input = {
  data: Number_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Number_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "number_field_values" */
export type Number_Field_Values_On_Conflict = {
  constraint: Number_Field_Values_Constraint;
  update_columns?: Array<Number_Field_Values_Update_Column>;
  where?: InputMaybe<Number_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "number_field_values". */
export type Number_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: number_field_values */
export type Number_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "number_field_values" */
export enum Number_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "number_field_values" */
export type Number_Field_Values_Set_Input = {
  value?: InputMaybe<Scalars['numeric']['input']>;
};

/** order by stddev() on columns of table "number_field_values" */
export type Number_Field_Values_Stddev_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "number_field_values" */
export type Number_Field_Values_Stddev_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "number_field_values" */
export type Number_Field_Values_Stddev_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** order by sum() on columns of table "number_field_values" */
export type Number_Field_Values_Sum_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** update columns of table "number_field_values" */
export enum Number_Field_Values_Update_Column {
  /** column name */
  Value = 'value',
}

export type Number_Field_Values_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Number_Field_Values_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Number_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Number_Field_Values_Bool_Exp;
};

/** order by var_pop() on columns of table "number_field_values" */
export type Number_Field_Values_Var_Pop_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "number_field_values" */
export type Number_Field_Values_Var_Samp_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "number_field_values" */
export type Number_Field_Values_Variance_Order_By = {
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']['input']>;
  _gt?: InputMaybe<Scalars['numeric']['input']>;
  _gte?: InputMaybe<Scalars['numeric']['input']>;
  _in?: InputMaybe<Array<Scalars['numeric']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['numeric']['input']>;
  _lte?: InputMaybe<Scalars['numeric']['input']>;
  _neq?: InputMaybe<Scalars['numeric']['input']>;
  _nin?: InputMaybe<Array<Scalars['numeric']['input']>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "organization_evidence_tags" */
export type Organization_Evidence_Tags = {
  __typename?: 'organization_evidence_tags';
  created_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  evidence_tags: Array<Evidence_Tags>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "organization_evidence_tags" */
export type Organization_Evidence_TagsEvidence_TagsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Tags_Order_By>>;
  where?: InputMaybe<Evidence_Tags_Bool_Exp>;
};

/** order by aggregate values of table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Evidence_Tags_Max_Order_By>;
  min?: InputMaybe<Organization_Evidence_Tags_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "organization_evidence_tags". All fields are combined with a logical 'AND'. */
export type Organization_Evidence_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Evidence_Tags_Bool_Exp>>;
  _not?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Evidence_Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  evidence_tags?: InputMaybe<Evidence_Tags_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_evidence_tags" */
export enum Organization_Evidence_Tags_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  OrganizationEvidenceTagsNameOrganizationIdKey = 'organization_evidence_tags_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationEvidenceTagsPkey = 'organization_evidence_tags_pkey',
}

/** input type for inserting data into table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Insert_Input = {
  evidence_tags?: InputMaybe<Evidence_Tags_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Mutation_Response = {
  __typename?: 'organization_evidence_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Evidence_Tags>;
};

/** input type for inserting object relation for remote table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Obj_Rel_Insert_Input = {
  data: Organization_Evidence_Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Evidence_Tags_On_Conflict>;
};

/** on_conflict condition type for table "organization_evidence_tags" */
export type Organization_Evidence_Tags_On_Conflict = {
  constraint: Organization_Evidence_Tags_Constraint;
  update_columns?: Array<Organization_Evidence_Tags_Update_Column>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_evidence_tags". */
export type Organization_Evidence_Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  evidence_tags_aggregate?: InputMaybe<Evidence_Tags_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "organization_evidence_tags" */
export enum Organization_Evidence_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "organization_evidence_tags" */
export type Organization_Evidence_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Evidence_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Evidence_Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** placeholder for update columns of table "organization_evidence_tags" (current role has no relevant permissions) */
export enum Organization_Evidence_Tags_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "organization_integrations" */
export type Organization_Integrations = {
  __typename?: 'organization_integrations';
  disabled: Scalars['Boolean']['output'];
  /** An array relationship */
  evidence_integrations: Array<Evidence_Integrations>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  integration: Integrations;
  /** An object relationship */
  organization: Organizations;
};

/** columns and relationships of "organization_integrations" */
export type Organization_IntegrationsEvidence_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Integrations_Order_By>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

/** order by aggregate values of table "organization_integrations" */
export type Organization_Integrations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Integrations_Max_Order_By>;
  min?: InputMaybe<Organization_Integrations_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "organization_integrations". All fields are combined with a logical 'AND'. */
export type Organization_Integrations_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Integrations_Bool_Exp>>;
  _not?: InputMaybe<Organization_Integrations_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Integrations_Bool_Exp>>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  evidence_integrations?: InputMaybe<Evidence_Integrations_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  integration?: InputMaybe<Integrations_Bool_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
};

/** order by max() on columns of table "organization_integrations" */
export type Organization_Integrations_Max_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organization_integrations" */
export type Organization_Integrations_Min_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "organization_integrations". */
export type Organization_Integrations_Order_By = {
  disabled?: InputMaybe<Order_By>;
  evidence_integrations_aggregate?: InputMaybe<Evidence_Integrations_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  integration?: InputMaybe<Integrations_Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
};

/** select columns of table "organization_integrations" */
export enum Organization_Integrations_Select_Column {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
}

/** Streaming cursor of the table "organization_integrations" */
export type Organization_Integrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Integrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Integrations_Stream_Cursor_Value_Input = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "organization_risk_categories" */
export type Organization_Risk_Categories = {
  __typename?: 'organization_risk_categories';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  risk_categories: Array<Risk_Categories>;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "organization_risk_categories" */
export type Organization_Risk_CategoriesRisk_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Categories_Order_By>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

/** order by aggregate values of table "organization_risk_categories" */
export type Organization_Risk_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Risk_Categories_Max_Order_By>;
  min?: InputMaybe<Organization_Risk_Categories_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "organization_risk_categories". All fields are combined with a logical 'AND'. */
export type Organization_Risk_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Risk_Categories_Bool_Exp>>;
  _not?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Risk_Categories_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk_categories?: InputMaybe<Risk_Categories_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_risk_categories" */
export enum Organization_Risk_Categories_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  OrganizationRiskCategoriesNameOrganizationIdKey = 'organization_risk_categories_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationRiskCategoriesPkey = 'organization_risk_categories_pkey',
}

/** input type for inserting data into table "organization_risk_categories" */
export type Organization_Risk_Categories_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_categories?: InputMaybe<Risk_Categories_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "organization_risk_categories" */
export type Organization_Risk_Categories_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organization_risk_categories" */
export type Organization_Risk_Categories_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_risk_categories" */
export type Organization_Risk_Categories_Mutation_Response = {
  __typename?: 'organization_risk_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Risk_Categories>;
};

/** input type for inserting object relation for remote table "organization_risk_categories" */
export type Organization_Risk_Categories_Obj_Rel_Insert_Input = {
  data: Organization_Risk_Categories_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Risk_Categories_On_Conflict>;
};

/** on_conflict condition type for table "organization_risk_categories" */
export type Organization_Risk_Categories_On_Conflict = {
  constraint: Organization_Risk_Categories_Constraint;
  update_columns?: Array<Organization_Risk_Categories_Update_Column>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_risk_categories". */
export type Organization_Risk_Categories_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  risk_categories_aggregate?: InputMaybe<Risk_Categories_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "organization_risk_categories" */
export enum Organization_Risk_Categories_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "organization_risk_categories" */
export type Organization_Risk_Categories_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Risk_Categories_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Risk_Categories_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** placeholder for update columns of table "organization_risk_categories" (current role has no relevant permissions) */
export enum Organization_Risk_Categories_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "organization_risk_tags" */
export type Organization_Risk_Tags = {
  __typename?: 'organization_risk_tags';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  risk_tags: Array<Risk_Tags>;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "organization_risk_tags" */
export type Organization_Risk_TagsRisk_TagsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tags_Order_By>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

/** order by aggregate values of table "organization_risk_tags" */
export type Organization_Risk_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Risk_Tags_Max_Order_By>;
  min?: InputMaybe<Organization_Risk_Tags_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "organization_risk_tags". All fields are combined with a logical 'AND'. */
export type Organization_Risk_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Risk_Tags_Bool_Exp>>;
  _not?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Risk_Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk_tags?: InputMaybe<Risk_Tags_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "organization_risk_tags" */
export enum Organization_Risk_Tags_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  OrganizationRiskTagsNameOrganizationIdKey = 'organization_risk_tags_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationRiskTagsPkey = 'organization_risk_tags_pkey',
}

/** input type for inserting data into table "organization_risk_tags" */
export type Organization_Risk_Tags_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  risk_tags?: InputMaybe<Risk_Tags_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "organization_risk_tags" */
export type Organization_Risk_Tags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organization_risk_tags" */
export type Organization_Risk_Tags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_risk_tags" */
export type Organization_Risk_Tags_Mutation_Response = {
  __typename?: 'organization_risk_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Risk_Tags>;
};

/** input type for inserting object relation for remote table "organization_risk_tags" */
export type Organization_Risk_Tags_Obj_Rel_Insert_Input = {
  data: Organization_Risk_Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Risk_Tags_On_Conflict>;
};

/** on_conflict condition type for table "organization_risk_tags" */
export type Organization_Risk_Tags_On_Conflict = {
  constraint: Organization_Risk_Tags_Constraint;
  update_columns?: Array<Organization_Risk_Tags_Update_Column>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_risk_tags". */
export type Organization_Risk_Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  risk_tags_aggregate?: InputMaybe<Risk_Tags_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "organization_risk_tags" */
export enum Organization_Risk_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "organization_risk_tags" */
export type Organization_Risk_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Risk_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Risk_Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** placeholder for update columns of table "organization_risk_tags" (current role has no relevant permissions) */
export enum Organization_Risk_Tags_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "organization_template_frameworks" */
export type Organization_Template_Frameworks = {
  __typename?: 'organization_template_frameworks';
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  template_framework: Template_Frameworks;
  template_framework_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Template_Frameworks_Max_Order_By>;
  min?: InputMaybe<Organization_Template_Frameworks_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "organization_template_frameworks". All fields are combined with a logical 'AND'. */
export type Organization_Template_Frameworks_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Template_Frameworks_Bool_Exp>>;
  _not?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Template_Frameworks_Bool_Exp>>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  template_framework?: InputMaybe<Template_Frameworks_Bool_Exp>;
  template_framework_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Max_Order_By = {
  organization_id?: InputMaybe<Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Min_Order_By = {
  organization_id?: InputMaybe<Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "organization_template_frameworks". */
export type Organization_Template_Frameworks_Order_By = {
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  template_framework?: InputMaybe<Template_Frameworks_Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
};

/** select columns of table "organization_template_frameworks" */
export enum Organization_Template_Frameworks_Select_Column {
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TemplateFrameworkId = 'template_framework_id',
}

/** Streaming cursor of the table "organization_template_frameworks" */
export type Organization_Template_Frameworks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Template_Frameworks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Template_Frameworks_Stream_Cursor_Value_Input = {
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  template_framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** table consisting of tenants for an organization used for SSO */
export type Organization_Tenants = {
  __typename?: 'organization_tenants';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** order by aggregate values of table "organization_tenants" */
export type Organization_Tenants_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Tenants_Max_Order_By>;
  min?: InputMaybe<Organization_Tenants_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "organization_tenants". All fields are combined with a logical 'AND'. */
export type Organization_Tenants_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Tenants_Bool_Exp>>;
  _not?: InputMaybe<Organization_Tenants_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Tenants_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "organization_tenants" */
export type Organization_Tenants_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organization_tenants" */
export type Organization_Tenants_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "organization_tenants". */
export type Organization_Tenants_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "organization_tenants" */
export enum Organization_Tenants_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "organization_tenants" */
export type Organization_Tenants_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Tenants_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Tenants_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** An organization can have multiple users. One user can belong to only one organization */
export type Organization_Users = {
  __typename?: 'organization_users';
  disabled: Scalars['Boolean']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  role: Roles;
  role_id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "organization_users" */
export type Organization_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Users_Max_Order_By>;
  min?: InputMaybe<Organization_Users_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "organization_users". All fields are combined with a logical 'AND'. */
export type Organization_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Users_Bool_Exp>>;
  _not?: InputMaybe<Organization_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Users_Bool_Exp>>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "organization_users" */
export type Organization_Users_Max_Order_By = {
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organization_users" */
export type Organization_Users_Min_Order_By = {
  organization_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_users" */
export type Organization_Users_Mutation_Response = {
  __typename?: 'organization_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Users>;
};

/** Ordering options when selecting data from "organization_users". */
export type Organization_Users_Order_By = {
  disabled?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: organization_users */
export type Organization_Users_Pk_Columns_Input = {
  organization_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

/** select columns of table "organization_users" */
export enum Organization_Users_Select_Column {
  /** column name */
  Disabled = 'disabled',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "organization_users" */
export type Organization_Users_Set_Input = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "organization_users" */
export type Organization_Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Users_Stream_Cursor_Value_Input = {
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type Organization_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Organization_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organization_Users_Bool_Exp;
};

/** columns and relationships of "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels = {
  __typename?: 'organization_vendor_risk_levels';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  vendors: Array<Vendors>;
};

/** columns and relationships of "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_LevelsVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** order by aggregate values of table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Vendor_Risk_Levels_Max_Order_By>;
  min?: InputMaybe<Organization_Vendor_Risk_Levels_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "organization_vendor_risk_levels". All fields are combined with a logical 'AND'. */
export type Organization_Vendor_Risk_Levels_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Bool_Exp>>;
  _not?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendors?: InputMaybe<Vendors_Bool_Exp>;
};

/** unique or primary key constraints on table "organization_vendor_risk_levels" */
export enum Organization_Vendor_Risk_Levels_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  OrganizationVendorRiskLevelsOrganizationIdNameKey = 'organization_vendor_risk_levels_organization_id_name_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationVendorRiskLevelsPkey = 'organization_vendor_risk_levels_pkey',
}

/** input type for inserting data into table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  vendors?: InputMaybe<Vendors_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Mutation_Response = {
  __typename?: 'organization_vendor_risk_levels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Vendor_Risk_Levels>;
};

/** input type for inserting object relation for remote table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Obj_Rel_Insert_Input = {
  data: Organization_Vendor_Risk_Levels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Vendor_Risk_Levels_On_Conflict>;
};

/** on_conflict condition type for table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_On_Conflict = {
  constraint: Organization_Vendor_Risk_Levels_Constraint;
  update_columns?: Array<Organization_Vendor_Risk_Levels_Update_Column>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_vendor_risk_levels". */
export type Organization_Vendor_Risk_Levels_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Order_By>;
};

/** select columns of table "organization_vendor_risk_levels" */
export enum Organization_Vendor_Risk_Levels_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "organization_vendor_risk_levels" */
export type Organization_Vendor_Risk_Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Vendor_Risk_Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Vendor_Risk_Levels_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** placeholder for update columns of table "organization_vendor_risk_levels" (current role has no relevant permissions) */
export enum Organization_Vendor_Risk_Levels_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "organization_vendor_tiers" */
export type Organization_Vendor_Tiers = {
  __typename?: 'organization_vendor_tiers';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  vendors: Array<Vendors>;
};

/** columns and relationships of "organization_vendor_tiers" */
export type Organization_Vendor_TiersVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** order by aggregate values of table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organization_Vendor_Tiers_Max_Order_By>;
  min?: InputMaybe<Organization_Vendor_Tiers_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "organization_vendor_tiers". All fields are combined with a logical 'AND'. */
export type Organization_Vendor_Tiers_Bool_Exp = {
  _and?: InputMaybe<Array<Organization_Vendor_Tiers_Bool_Exp>>;
  _not?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
  _or?: InputMaybe<Array<Organization_Vendor_Tiers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendors?: InputMaybe<Vendors_Bool_Exp>;
};

/** unique or primary key constraints on table "organization_vendor_tiers" */
export enum Organization_Vendor_Tiers_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  OrganizationVendorTiersOrganizationIdNameKey = 'organization_vendor_tiers_organization_id_name_key',
  /** unique or primary key constraint on columns "id" */
  OrganizationVendorTiersPkey = 'organization_vendor_tiers_pkey',
}

/** input type for inserting data into table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  vendors?: InputMaybe<Vendors_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Mutation_Response = {
  __typename?: 'organization_vendor_tiers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Organization_Vendor_Tiers>;
};

/** input type for inserting object relation for remote table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Obj_Rel_Insert_Input = {
  data: Organization_Vendor_Tiers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Organization_Vendor_Tiers_On_Conflict>;
};

/** on_conflict condition type for table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_On_Conflict = {
  constraint: Organization_Vendor_Tiers_Constraint;
  update_columns?: Array<Organization_Vendor_Tiers_Update_Column>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

/** Ordering options when selecting data from "organization_vendor_tiers". */
export type Organization_Vendor_Tiers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Order_By>;
};

/** select columns of table "organization_vendor_tiers" */
export enum Organization_Vendor_Tiers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Streaming cursor of the table "organization_vendor_tiers" */
export type Organization_Vendor_Tiers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organization_Vendor_Tiers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organization_Vendor_Tiers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** placeholder for update columns of table "organization_vendor_tiers" (current role has no relevant permissions) */
export enum Organization_Vendor_Tiers_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** Table to manage organizations */
export type Organizations = {
  __typename?: 'organizations';
  /** An array relationship */
  categories: Array<Categories>;
  /** An array relationship */
  client_questionnaires: Array<Client_Questionnaires>;
  /** An array relationship */
  controls_histories: Array<Controls_History_Stats>;
  /** An array relationship */
  criteria: Array<Criteria>;
  default_user_role_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  disabled: Scalars['Boolean']['output'];
  /** An array relationship */
  documents: Array<Documents>;
  /** An array relationship */
  evidence_tags: Array<Organization_Evidence_Tags>;
  /** An array relationship */
  evidences: Array<Evidences>;
  /** An aggregate relationship */
  evidences_aggregate: Evidences_Aggregate;
  /** An array relationship */
  field_configs: Array<Field_Configs>;
  /** An array relationship */
  frameworks: Array<Frameworks>;
  /** An array relationship */
  groups: Array<Groups>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  invitations: Array<Invitations>;
  is_audit_trail_enabled: Scalars['Boolean']['output'];
  is_control_ai_review_enabled: Scalars['Boolean']['output'];
  is_controls_module_enabled: Scalars['Boolean']['output'];
  is_day_zero_ai_features_enabled: Scalars['Boolean']['output'];
  is_policies_module_enabled: Scalars['Boolean']['output'];
  is_risks_module_enabled: Scalars['Boolean']['output'];
  is_vendor_questionnaire_ai_review_enabled: Scalars['Boolean']['output'];
  is_vendors_module_enabled: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization?: Maybe<Organizations>;
  /** An array relationship */
  organization_controls: Array<Controls>;
  /** An aggregate relationship */
  organization_controls_aggregate: Controls_Aggregate;
  /** An array relationship */
  organization_integrations: Array<Organization_Integrations>;
  /** An array relationship */
  organization_template_frameworks: Array<Organization_Template_Frameworks>;
  /** An array relationship */
  organization_tenants: Array<Organization_Tenants>;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An array relationship */
  organizations: Array<Organizations>;
  parent_organization_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  policies: Array<Policies>;
  /** An array relationship */
  programs: Array<Programs>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An array relationship */
  reports: Array<Reports>;
  /** An array relationship */
  risk_categories: Array<Organization_Risk_Categories>;
  /** An array relationship */
  risk_impacts: Array<Risk_Impacts>;
  /** An array relationship */
  risk_likelihoods: Array<Risk_Likelihoods>;
  /** An array relationship */
  risk_tags: Array<Organization_Risk_Tags>;
  /** An array relationship */
  risks: Array<Risks>;
  /** An aggregate relationship */
  risks_aggregate: Risks_Aggregate;
  /** An array relationship */
  roles: Array<Roles>;
  /** An array relationship */
  tags: Array<Tags>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** An array relationship */
  vendor_risk_levels: Array<Organization_Vendor_Risk_Levels>;
  /** An array relationship */
  vendor_tiers: Array<Organization_Vendor_Tiers>;
  /** An array relationship */
  vendors: Array<Vendors>;
  workos_organization_id?: Maybe<Scalars['String']['output']>;
};

/** Table to manage organizations */
export type OrganizationsCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsClient_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsControls_HistoriesArgs = {
  distinct_on?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_History_Stats_Order_By>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsCriteriaArgs = {
  distinct_on?: InputMaybe<Array<Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Criteria_Order_By>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsEvidence_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Evidence_Tags_Order_By>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsEvidencesArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsEvidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsField_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsFrameworksArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Order_By>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_Template_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tenants_Order_By>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Categories_Order_By>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_ImpactsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Impacts_Order_By>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_LikelihoodsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Likelihoods_Order_By>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisk_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Tags_Order_By>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisksArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsVendor_Risk_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsVendor_TiersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Tiers_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

/** Table to manage organizations */
export type OrganizationsVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** order by aggregate values of table "organizations" */
export type Organizations_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Organizations_Max_Order_By>;
  min?: InputMaybe<Organizations_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "organizations". All fields are combined with a logical 'AND'. */
export type Organizations_Bool_Exp = {
  _and?: InputMaybe<Array<Organizations_Bool_Exp>>;
  _not?: InputMaybe<Organizations_Bool_Exp>;
  _or?: InputMaybe<Array<Organizations_Bool_Exp>>;
  categories?: InputMaybe<Categories_Bool_Exp>;
  client_questionnaires?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  controls_histories?: InputMaybe<Controls_History_Stats_Bool_Exp>;
  criteria?: InputMaybe<Criteria_Bool_Exp>;
  default_user_role_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  documents?: InputMaybe<Documents_Bool_Exp>;
  evidence_tags?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
  evidences?: InputMaybe<Evidences_Bool_Exp>;
  evidences_aggregate?: InputMaybe<Evidences_Aggregate_Bool_Exp>;
  field_configs?: InputMaybe<Field_Configs_Bool_Exp>;
  frameworks?: InputMaybe<Frameworks_Bool_Exp>;
  groups?: InputMaybe<Groups_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitations?: InputMaybe<Invitations_Bool_Exp>;
  is_audit_trail_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_control_ai_review_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_controls_module_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_day_zero_ai_features_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_policies_module_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_risks_module_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_vendor_questionnaire_ai_review_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  is_vendors_module_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_controls?: InputMaybe<Controls_Bool_Exp>;
  organization_controls_aggregate?: InputMaybe<Controls_Aggregate_Bool_Exp>;
  organization_integrations?: InputMaybe<Organization_Integrations_Bool_Exp>;
  organization_template_frameworks?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
  organization_tenants?: InputMaybe<Organization_Tenants_Bool_Exp>;
  organization_users?: InputMaybe<Organization_Users_Bool_Exp>;
  organizations?: InputMaybe<Organizations_Bool_Exp>;
  parent_organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  policies?: InputMaybe<Policies_Bool_Exp>;
  programs?: InputMaybe<Programs_Bool_Exp>;
  questionnaires?: InputMaybe<Questionnaires_Bool_Exp>;
  reports?: InputMaybe<Reports_Bool_Exp>;
  risk_categories?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
  risk_impacts?: InputMaybe<Risk_Impacts_Bool_Exp>;
  risk_likelihoods?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  risk_tags?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
  risks?: InputMaybe<Risks_Bool_Exp>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  roles?: InputMaybe<Roles_Bool_Exp>;
  tags?: InputMaybe<Tags_Bool_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  vendor_risk_levels?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
  vendor_tiers?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
  vendors?: InputMaybe<Vendors_Bool_Exp>;
  workos_organization_id?: InputMaybe<String_Comparison_Exp>;
};

/** order by max() on columns of table "organizations" */
export type Organizations_Max_Order_By = {
  default_user_role_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  workos_organization_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "organizations" */
export type Organizations_Min_Order_By = {
  default_user_role_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  workos_organization_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "organizations". */
export type Organizations_Order_By = {
  categories_aggregate?: InputMaybe<Categories_Aggregate_Order_By>;
  client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Order_By>;
  controls_histories_aggregate?: InputMaybe<Controls_History_Stats_Aggregate_Order_By>;
  criteria_aggregate?: InputMaybe<Criteria_Aggregate_Order_By>;
  default_user_role_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  disabled?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Documents_Aggregate_Order_By>;
  evidence_tags_aggregate?: InputMaybe<Organization_Evidence_Tags_Aggregate_Order_By>;
  evidences_aggregate?: InputMaybe<Evidences_Aggregate_Order_By>;
  field_configs_aggregate?: InputMaybe<Field_Configs_Aggregate_Order_By>;
  frameworks_aggregate?: InputMaybe<Frameworks_Aggregate_Order_By>;
  groups_aggregate?: InputMaybe<Groups_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<Invitations_Aggregate_Order_By>;
  is_audit_trail_enabled?: InputMaybe<Order_By>;
  is_control_ai_review_enabled?: InputMaybe<Order_By>;
  is_controls_module_enabled?: InputMaybe<Order_By>;
  is_day_zero_ai_features_enabled?: InputMaybe<Order_By>;
  is_policies_module_enabled?: InputMaybe<Order_By>;
  is_risks_module_enabled?: InputMaybe<Order_By>;
  is_vendor_questionnaire_ai_review_enabled?: InputMaybe<Order_By>;
  is_vendors_module_enabled?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_controls_aggregate?: InputMaybe<Controls_Aggregate_Order_By>;
  organization_integrations_aggregate?: InputMaybe<Organization_Integrations_Aggregate_Order_By>;
  organization_template_frameworks_aggregate?: InputMaybe<Organization_Template_Frameworks_Aggregate_Order_By>;
  organization_tenants_aggregate?: InputMaybe<Organization_Tenants_Aggregate_Order_By>;
  organization_users_aggregate?: InputMaybe<Organization_Users_Aggregate_Order_By>;
  organizations_aggregate?: InputMaybe<Organizations_Aggregate_Order_By>;
  parent_organization_id?: InputMaybe<Order_By>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Order_By>;
  programs_aggregate?: InputMaybe<Programs_Aggregate_Order_By>;
  questionnaires_aggregate?: InputMaybe<Questionnaires_Aggregate_Order_By>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Order_By>;
  risk_categories_aggregate?: InputMaybe<Organization_Risk_Categories_Aggregate_Order_By>;
  risk_impacts_aggregate?: InputMaybe<Risk_Impacts_Aggregate_Order_By>;
  risk_likelihoods_aggregate?: InputMaybe<Risk_Likelihoods_Aggregate_Order_By>;
  risk_tags_aggregate?: InputMaybe<Organization_Risk_Tags_Aggregate_Order_By>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  roles_aggregate?: InputMaybe<Roles_Aggregate_Order_By>;
  tags_aggregate?: InputMaybe<Tags_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  vendor_risk_levels_aggregate?: InputMaybe<Organization_Vendor_Risk_Levels_Aggregate_Order_By>;
  vendor_tiers_aggregate?: InputMaybe<Organization_Vendor_Tiers_Aggregate_Order_By>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Order_By>;
  workos_organization_id?: InputMaybe<Order_By>;
};

/** select columns of table "organizations" */
export enum Organizations_Select_Column {
  /** column name */
  DefaultUserRoleId = 'default_user_role_id',
  /** column name */
  Description = 'description',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  Id = 'id',
  /** column name */
  IsAuditTrailEnabled = 'is_audit_trail_enabled',
  /** column name */
  IsControlAiReviewEnabled = 'is_control_ai_review_enabled',
  /** column name */
  IsControlsModuleEnabled = 'is_controls_module_enabled',
  /** column name */
  IsDayZeroAiFeaturesEnabled = 'is_day_zero_ai_features_enabled',
  /** column name */
  IsPoliciesModuleEnabled = 'is_policies_module_enabled',
  /** column name */
  IsRisksModuleEnabled = 'is_risks_module_enabled',
  /** column name */
  IsVendorQuestionnaireAiReviewEnabled = 'is_vendor_questionnaire_ai_review_enabled',
  /** column name */
  IsVendorsModuleEnabled = 'is_vendors_module_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  ParentOrganizationId = 'parent_organization_id',
  /** column name */
  WorkosOrganizationId = 'workos_organization_id',
}

/** Streaming cursor of the table "organizations" */
export type Organizations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organizations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organizations_Stream_Cursor_Value_Input = {
  default_user_role_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_audit_trail_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_control_ai_review_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_controls_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_day_zero_ai_features_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_policies_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_risks_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_vendor_questionnaire_ai_review_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  is_vendors_module_enabled?: InputMaybe<Scalars['Boolean']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent_organization_id?: InputMaybe<Scalars['uuid']['input']>;
  workos_organization_id?: InputMaybe<Scalars['String']['input']>;
};

export enum Permissions_Enum {
  /** Can acknowledge policies */
  AcknowledgePolicies = 'acknowledge_policies',
  /** ability to create or delete controls */
  CreateControls = 'create_controls',
  /** ability to link evidence to controls */
  LinkControlsEvidence = 'link_controls_evidence',
  /** ability to link controls to programs */
  LinkControlsPrograms = 'link_controls_programs',
  /** ability to link controls to risks */
  LinkControlsRisks = 'link_controls_risks',
  /** ability to link risks to vendors */
  LinkRisksVendors = 'link_risks_vendors',
  /** ability to manage organization documents */
  ManageDocuments = 'manage_documents',
  /** ability to manage risk classification */
  ManageRiskClassification = 'manage_risk_classification',
  /** notified when control is at risk or moves to invalid */
  NotifyOnControlAtRisk = 'notify_on_control_at_risk',
  /** ability to get notified when questionnaire is overdue */
  NotifyOnQuestionnaireOverdue = 'notify_on_questionnaire_overdue',
  /** ability to get notified when risk is expiring */
  NotifyOnRiskExpiring = 'notify_on_risk_expiring',
  /** ability to get notified when vendor is expiring */
  NotifyOnVendorExpiring = 'notify_on_vendor_expiring',
  /** View client questionnaires */
  ReadClientQ = 'read_client_q',
  /** ability to read confidential evidence */
  ReadConfidentialEvidence = 'read_confidential_evidence',
  /** ability to read controls */
  ReadControls = 'read_controls',
  /** ability to read evidence */
  ReadEvidence = 'read_evidence',
  /** ability to view policy */
  ReadPolicies = 'read_policies',
  /** ability to read reports */
  ReadReports = 'read_reports',
  /** ability to read risks */
  ReadRisks = 'read_risks',
  /** ability to view tasks */
  ReadTasks = 'read_tasks',
  /** ability to read vendors */
  ReadVendors = 'read_vendors',
  /** ability to update controls */
  UpdateControls = 'update_controls',
  /** Create and edit client questionnaires */
  WriteClientQ = 'write_client_q',
  /** ability to modify confidential evidence */
  WriteConfidentialEvidence = 'write_confidential_evidence',
  /** Create and edit custom fields for controls */
  WriteControlFieldConfigs = 'write_control_field_configs',
  /** ability to modify evidence */
  WriteEvidence = 'write_evidence',
  /** ability to modify policy */
  WritePolicies = 'write_policies',
  /** Create and edit custom fields for policies */
  WritePolicyFieldConfigs = 'write_policy_field_configs',
  /** ability to modify programs */
  WritePrograms = 'write_programs',
  /** ability to modify reports */
  WriteReports = 'write_reports',
  /** ability to modify risk custom field configs */
  WriteRiskFieldConfigs = 'write_risk_field_configs',
  /** ability to modify risks */
  WriteRisks = 'write_risks',
  /** ability to modify roles */
  WriteRoles = 'write_roles',
  /** Create and edit custom fields for tasks */
  WriteTaskFieldConfigs = 'write_task_field_configs',
  /** ability to create and update tasks */
  WriteTasks = 'write_tasks',
  /** ability to modify vendor custom field configs */
  WriteVendorFieldConfigs = 'write_vendor_field_configs',
  /** ability to modify vendors */
  WriteVendors = 'write_vendors',
}

/** Boolean expression to compare columns of type "permissions_enum". All fields are combined with logical 'AND'. */
export type Permissions_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Permissions_Enum>;
  _in?: InputMaybe<Array<Permissions_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Permissions_Enum>;
  _nin?: InputMaybe<Array<Permissions_Enum>>;
};

/** policies module */
export type Policies = {
  __typename?: 'policies';
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  evidence_policies: Array<Evidence_Policies>;
  /** An array relationship */
  field_values: Array<Field_Values>;
  frequency: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  policies_comments: Array<Comments>;
  /** An array relationship */
  policies_policy_tasks: Array<Policy_Tasks>;
  /** An array relationship */
  policies_vector_stores: Array<Ai_Policies_Vector_Store>;
  /** An array relationship */
  policy_approvers: Array<Policy_Approvers>;
  /** An array relationship */
  policy_versions: Array<Policy_Versions>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status: Policy_Statuses_Enum;
  type: Policy_Types_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
};

/** policies module */
export type PoliciesEvidence_PoliciesArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Policies_Order_By>>;
  where?: InputMaybe<Evidence_Policies_Bool_Exp>;
};

/** policies module */
export type PoliciesField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** policies module */
export type PoliciesNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicies_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicies_Policy_TasksArgs = {
  distinct_on?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Tasks_Order_By>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicies_Vector_StoresArgs = {
  distinct_on?: InputMaybe<Array<Ai_Policies_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Policies_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicy_ApproversArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvers_Order_By>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

/** policies module */
export type PoliciesPolicy_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** order by aggregate values of table "policies" */
export type Policies_Aggregate_Order_By = {
  avg?: InputMaybe<Policies_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policies_Max_Order_By>;
  min?: InputMaybe<Policies_Min_Order_By>;
  stddev?: InputMaybe<Policies_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Policies_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Policies_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Policies_Sum_Order_By>;
  var_pop?: InputMaybe<Policies_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Policies_Var_Samp_Order_By>;
  variance?: InputMaybe<Policies_Variance_Order_By>;
};

/** order by avg() on columns of table "policies" */
export type Policies_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "policies". All fields are combined with a logical 'AND'. */
export type Policies_Bool_Exp = {
  _and?: InputMaybe<Array<Policies_Bool_Exp>>;
  _not?: InputMaybe<Policies_Bool_Exp>;
  _or?: InputMaybe<Array<Policies_Bool_Exp>>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  evidence_policies?: InputMaybe<Evidence_Policies_Bool_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  frequency?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  policies_comments?: InputMaybe<Comments_Bool_Exp>;
  policies_policy_tasks?: InputMaybe<Policy_Tasks_Bool_Exp>;
  policies_vector_stores?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
  policy_approvers?: InputMaybe<Policy_Approvers_Bool_Exp>;
  policy_versions?: InputMaybe<Policy_Versions_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  status?: InputMaybe<Policy_Statuses_Enum_Comparison_Exp>;
  type?: InputMaybe<Policy_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "policies" */
export enum Policies_Constraint {
  /** unique or primary key constraint on columns "id" */
  PoliciesPkey = 'policies_pkey',
}

/** input type for inserting data into table "policies" */
export type Policies_Insert_Input = {
  evidence_policies?: InputMaybe<Evidence_Policies_Arr_Rel_Insert_Input>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  policies_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  policies_policy_tasks?: InputMaybe<Policy_Tasks_Arr_Rel_Insert_Input>;
  policy_approvers?: InputMaybe<Policy_Approvers_Arr_Rel_Insert_Input>;
  policy_versions?: InputMaybe<Policy_Versions_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Policy_Types_Enum>;
};

/** order by max() on columns of table "policies" */
export type Policies_Max_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "policies" */
export type Policies_Min_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policies" */
export type Policies_Mutation_Response = {
  __typename?: 'policies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policies>;
};

/** input type for inserting object relation for remote table "policies" */
export type Policies_Obj_Rel_Insert_Input = {
  data: Policies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policies_On_Conflict>;
};

/** on_conflict condition type for table "policies" */
export type Policies_On_Conflict = {
  constraint: Policies_Constraint;
  update_columns?: Array<Policies_Update_Column>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** Ordering options when selecting data from "policies". */
export type Policies_Order_By = {
  assignee_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  evidence_policies_aggregate?: InputMaybe<Evidence_Policies_Aggregate_Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  policies_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  policies_policy_tasks_aggregate?: InputMaybe<Policy_Tasks_Aggregate_Order_By>;
  policies_vector_stores_aggregate?: InputMaybe<Ai_Policies_Vector_Store_Aggregate_Order_By>;
  policy_approvers_aggregate?: InputMaybe<Policy_Approvers_Aggregate_Order_By>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: policies */
export type Policies_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policies" */
export enum Policies_Select_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "policies" */
export type Policies_Set_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "policies" */
export type Policies_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "policies" */
export type Policies_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "policies" */
export type Policies_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "policies" */
export type Policies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Policies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Policies_Stream_Cursor_Value_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  status?: InputMaybe<Policy_Statuses_Enum>;
  type?: InputMaybe<Policy_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by sum() on columns of table "policies" */
export type Policies_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "policies" */
export enum Policies_Update_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Name = 'name',
}

export type Policies_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policies_Bool_Exp;
};

/** order by var_pop() on columns of table "policies" */
export type Policies_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "policies" */
export type Policies_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "policies" */
export type Policies_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export enum Policy_Acknowledgement_Statuses_Enum {
  Acknowledged = 'Acknowledged',
  Expiring = 'Expiring',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Retired = 'Retired',
}

/** Boolean expression to compare columns of type "policy_acknowledgement_statuses_enum". All fields are combined with logical 'AND'. */
export type Policy_Acknowledgement_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Policy_Acknowledgement_Statuses_Enum>;
  _in?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Policy_Acknowledgement_Statuses_Enum>;
  _nin?: InputMaybe<Array<Policy_Acknowledgement_Statuses_Enum>>;
};

/** columns and relationships of "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users = {
  __typename?: 'policy_acknowledgement_users';
  acknowledged_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  policy_acknowledgement: Policy_Acknowledgements;
  policy_acknowledgement_id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Aggregate = {
  __typename?: 'policy_acknowledgement_users_aggregate';
  aggregate?: Maybe<Policy_Acknowledgement_Users_Aggregate_Fields>;
  nodes: Array<Policy_Acknowledgement_Users>;
};

export type Policy_Acknowledgement_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Policy_Acknowledgement_Users_Aggregate_Bool_Exp_Count>;
};

export type Policy_Acknowledgement_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Aggregate_Fields = {
  __typename?: 'policy_acknowledgement_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Acknowledgement_Users_Max_Fields>;
  min?: Maybe<Policy_Acknowledgement_Users_Min_Fields>;
};

/** aggregate fields of "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Acknowledgement_Users_Max_Order_By>;
  min?: InputMaybe<Policy_Acknowledgement_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Arr_Rel_Insert_Input = {
  data: Array<Policy_Acknowledgement_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Acknowledgement_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_acknowledgement_users". All fields are combined with a logical 'AND'. */
export type Policy_Acknowledgement_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Acknowledgement_Users_Bool_Exp>>;
  _not?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Acknowledgement_Users_Bool_Exp>>;
  acknowledged_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_acknowledgement?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
  policy_acknowledgement_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "policy_acknowledgement_users" */
export enum Policy_Acknowledgement_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyAcknowledgementUsersPkey = 'policy_acknowledgement_users_pkey',
  /** unique or primary key constraint on columns "user_id", "policy_acknowledgement_id" */
  PolicyAcknowledgementUsersUserIdPolicyAcknowledgementKey = 'policy_acknowledgement_users_user_id_policy_acknowledgement_key',
}

/** input type for inserting data into table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Insert_Input = {
  policy_acknowledgement?: InputMaybe<Policy_Acknowledgements_Obj_Rel_Insert_Input>;
  policy_acknowledgement_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Policy_Acknowledgement_Users_Max_Fields = {
  __typename?: 'policy_acknowledgement_users_max_fields';
  acknowledged_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_acknowledgement_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Max_Order_By = {
  acknowledged_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_acknowledgement_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policy_Acknowledgement_Users_Min_Fields = {
  __typename?: 'policy_acknowledgement_users_min_fields';
  acknowledged_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_acknowledgement_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Min_Order_By = {
  acknowledged_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_acknowledgement_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Mutation_Response = {
  __typename?: 'policy_acknowledgement_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Acknowledgement_Users>;
};

/** on_conflict condition type for table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_On_Conflict = {
  constraint: Policy_Acknowledgement_Users_Constraint;
  update_columns?: Array<Policy_Acknowledgement_Users_Update_Column>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_acknowledgement_users". */
export type Policy_Acknowledgement_Users_Order_By = {
  acknowledged_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_acknowledgement?: InputMaybe<Policy_Acknowledgements_Order_By>;
  policy_acknowledgement_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: policy_acknowledgement_users */
export type Policy_Acknowledgement_Users_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policy_acknowledgement_users" */
export enum Policy_Acknowledgement_Users_Select_Column {
  /** column name */
  AcknowledgedAt = 'acknowledged_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyAcknowledgementId = 'policy_acknowledgement_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "policy_acknowledgement_users" */
export type Policy_Acknowledgement_Users_Set_Input = {
  acknowledged_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "policy_acknowledgement_users" */
export enum Policy_Acknowledgement_Users_Update_Column {
  /** column name */
  AcknowledgedAt = 'acknowledged_at',
}

export type Policy_Acknowledgement_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Acknowledgement_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Acknowledgement_Users_Bool_Exp;
};

/** columns and relationships of "policy_acknowledgements" */
export type Policy_Acknowledgements = {
  __typename?: 'policy_acknowledgements';
  created_at: Scalars['timestamptz']['output'];
  created_by: Scalars['uuid']['output'];
  due_date: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  message: Scalars['String']['output'];
  /** An array relationship */
  policy_acknowledgement_users: Array<Policy_Acknowledgement_Users>;
  /** An aggregate relationship */
  policy_acknowledgement_users_aggregate: Policy_Acknowledgement_Users_Aggregate;
  /** An object relationship */
  policy_version: Policy_Versions;
  policy_version_id: Scalars['uuid']['output'];
  status: Policy_Acknowledgement_Statuses_Enum;
  subject: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
};

/** columns and relationships of "policy_acknowledgements" */
export type Policy_AcknowledgementsPolicy_Acknowledgement_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

/** columns and relationships of "policy_acknowledgements" */
export type Policy_AcknowledgementsPolicy_Acknowledgement_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

/** order by aggregate values of table "policy_acknowledgements" */
export type Policy_Acknowledgements_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Acknowledgements_Max_Order_By>;
  min?: InputMaybe<Policy_Acknowledgements_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_acknowledgements" */
export type Policy_Acknowledgements_Arr_Rel_Insert_Input = {
  data: Array<Policy_Acknowledgements_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Acknowledgements_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_acknowledgements". All fields are combined with a logical 'AND'. */
export type Policy_Acknowledgements_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Acknowledgements_Bool_Exp>>;
  _not?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Acknowledgements_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  policy_acknowledgement_users?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
  policy_acknowledgement_users_aggregate?: InputMaybe<Policy_Acknowledgement_Users_Aggregate_Bool_Exp>;
  policy_version?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Policy_Acknowledgement_Statuses_Enum_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "policy_acknowledgements" */
export enum Policy_Acknowledgements_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyAcknowledgementsPkey = 'policy_acknowledgements_pkey',
}

/** input type for inserting data into table "policy_acknowledgements" */
export type Policy_Acknowledgements_Insert_Input = {
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  policy_acknowledgement_users?: InputMaybe<Policy_Acknowledgement_Users_Arr_Rel_Insert_Input>;
  policy_version?: InputMaybe<Policy_Versions_Obj_Rel_Insert_Input>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "policy_acknowledgements" */
export type Policy_Acknowledgements_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "policy_acknowledgements" */
export type Policy_Acknowledgements_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_acknowledgements" */
export type Policy_Acknowledgements_Mutation_Response = {
  __typename?: 'policy_acknowledgements_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Acknowledgements>;
};

/** input type for inserting object relation for remote table "policy_acknowledgements" */
export type Policy_Acknowledgements_Obj_Rel_Insert_Input = {
  data: Policy_Acknowledgements_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Acknowledgements_On_Conflict>;
};

/** on_conflict condition type for table "policy_acknowledgements" */
export type Policy_Acknowledgements_On_Conflict = {
  constraint: Policy_Acknowledgements_Constraint;
  update_columns?: Array<Policy_Acknowledgements_Update_Column>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_acknowledgements". */
export type Policy_Acknowledgements_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_acknowledgement_users_aggregate?: InputMaybe<Policy_Acknowledgement_Users_Aggregate_Order_By>;
  policy_version?: InputMaybe<Policy_Versions_Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** select columns of table "policy_acknowledgements" */
export enum Policy_Acknowledgements_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** placeholder for update columns of table "policy_acknowledgements" (current role has no relevant permissions) */
export enum Policy_Acknowledgements_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export enum Policy_Approval_Statuses_Enum {
  Approved = 'Approved',
  Expiring = 'Expiring',
  Overdue = 'Overdue',
  Pending = 'Pending',
  Retired = 'Retired',
}

/** Boolean expression to compare columns of type "policy_approval_statuses_enum". All fields are combined with logical 'AND'. */
export type Policy_Approval_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Policy_Approval_Statuses_Enum>;
  _in?: InputMaybe<Array<Policy_Approval_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Policy_Approval_Statuses_Enum>;
  _nin?: InputMaybe<Array<Policy_Approval_Statuses_Enum>>;
};

/** users approving a policy */
export type Policy_Approval_Users = {
  __typename?: 'policy_approval_users';
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  policy_approval: Policy_Approvals;
  policy_approval_id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "policy_approval_users" */
export type Policy_Approval_Users_Aggregate = {
  __typename?: 'policy_approval_users_aggregate';
  aggregate?: Maybe<Policy_Approval_Users_Aggregate_Fields>;
  nodes: Array<Policy_Approval_Users>;
};

export type Policy_Approval_Users_Aggregate_Bool_Exp = {
  count?: InputMaybe<Policy_Approval_Users_Aggregate_Bool_Exp_Count>;
};

export type Policy_Approval_Users_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "policy_approval_users" */
export type Policy_Approval_Users_Aggregate_Fields = {
  __typename?: 'policy_approval_users_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Policy_Approval_Users_Max_Fields>;
  min?: Maybe<Policy_Approval_Users_Min_Fields>;
};

/** aggregate fields of "policy_approval_users" */
export type Policy_Approval_Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "policy_approval_users" */
export type Policy_Approval_Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Approval_Users_Max_Order_By>;
  min?: InputMaybe<Policy_Approval_Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_approval_users" */
export type Policy_Approval_Users_Arr_Rel_Insert_Input = {
  data: Array<Policy_Approval_Users_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Approval_Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_approval_users". All fields are combined with a logical 'AND'. */
export type Policy_Approval_Users_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Approval_Users_Bool_Exp>>;
  _not?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Approval_Users_Bool_Exp>>;
  approved_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_approval?: InputMaybe<Policy_Approvals_Bool_Exp>;
  policy_approval_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "policy_approval_users" */
export enum Policy_Approval_Users_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyApprovalUsersPkey = 'policy_approval_users_pkey',
  /** unique or primary key constraint on columns "user_id", "policy_approval_id" */
  PolicyApprovalUsersPolicyApprovalIdUserIdKey = 'policy_approval_users_policy_approval_id_user_id_key',
}

/** input type for inserting data into table "policy_approval_users" */
export type Policy_Approval_Users_Insert_Input = {
  policy_approval?: InputMaybe<Policy_Approvals_Obj_Rel_Insert_Input>;
  policy_approval_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Policy_Approval_Users_Max_Fields = {
  __typename?: 'policy_approval_users_max_fields';
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_approval_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "policy_approval_users" */
export type Policy_Approval_Users_Max_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_approval_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Policy_Approval_Users_Min_Fields = {
  __typename?: 'policy_approval_users_min_fields';
  approved_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  policy_approval_id?: Maybe<Scalars['uuid']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "policy_approval_users" */
export type Policy_Approval_Users_Min_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_approval_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_approval_users" */
export type Policy_Approval_Users_Mutation_Response = {
  __typename?: 'policy_approval_users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Approval_Users>;
};

/** on_conflict condition type for table "policy_approval_users" */
export type Policy_Approval_Users_On_Conflict = {
  constraint: Policy_Approval_Users_Constraint;
  update_columns?: Array<Policy_Approval_Users_Update_Column>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_approval_users". */
export type Policy_Approval_Users_Order_By = {
  approved_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_approval?: InputMaybe<Policy_Approvals_Order_By>;
  policy_approval_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: policy_approval_users */
export type Policy_Approval_Users_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policy_approval_users" */
export enum Policy_Approval_Users_Select_Column {
  /** column name */
  ApprovedAt = 'approved_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyApprovalId = 'policy_approval_id',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "policy_approval_users" */
export type Policy_Approval_Users_Set_Input = {
  approved_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "policy_approval_users" */
export enum Policy_Approval_Users_Update_Column {
  /** column name */
  ApprovedAt = 'approved_at',
}

export type Policy_Approval_Users_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Approval_Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Approval_Users_Bool_Exp;
};

/** table for approvals */
export type Policy_Approvals = {
  __typename?: 'policy_approvals';
  created_at: Scalars['timestamptz']['output'];
  created_by: Scalars['uuid']['output'];
  due_date: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  message: Scalars['String']['output'];
  /** An array relationship */
  policy_approval_users: Array<Policy_Approval_Users>;
  /** An aggregate relationship */
  policy_approval_users_aggregate: Policy_Approval_Users_Aggregate;
  /** An object relationship */
  policy_version: Policy_Versions;
  policy_version_id: Scalars['uuid']['output'];
  status: Policy_Approval_Statuses_Enum;
  subject: Scalars['String']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
};

/** table for approvals */
export type Policy_ApprovalsPolicy_Approval_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

/** table for approvals */
export type Policy_ApprovalsPolicy_Approval_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

/** order by aggregate values of table "policy_approvals" */
export type Policy_Approvals_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Approvals_Max_Order_By>;
  min?: InputMaybe<Policy_Approvals_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_approvals" */
export type Policy_Approvals_Arr_Rel_Insert_Input = {
  data: Array<Policy_Approvals_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Approvals_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_approvals". All fields are combined with a logical 'AND'. */
export type Policy_Approvals_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Approvals_Bool_Exp>>;
  _not?: InputMaybe<Policy_Approvals_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Approvals_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  policy_approval_users?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
  policy_approval_users_aggregate?: InputMaybe<Policy_Approval_Users_Aggregate_Bool_Exp>;
  policy_version?: InputMaybe<Policy_Versions_Bool_Exp>;
  policy_version_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Policy_Approval_Statuses_Enum_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "policy_approvals" */
export enum Policy_Approvals_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyApprovalsPkey = 'policy_approvals_pkey',
}

/** input type for inserting data into table "policy_approvals" */
export type Policy_Approvals_Insert_Input = {
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  policy_approval_users?: InputMaybe<Policy_Approval_Users_Arr_Rel_Insert_Input>;
  policy_version?: InputMaybe<Policy_Versions_Obj_Rel_Insert_Input>;
  policy_version_id?: InputMaybe<Scalars['uuid']['input']>;
  subject?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "policy_approvals" */
export type Policy_Approvals_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "policy_approvals" */
export type Policy_Approvals_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_approvals" */
export type Policy_Approvals_Mutation_Response = {
  __typename?: 'policy_approvals_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Approvals>;
};

/** input type for inserting object relation for remote table "policy_approvals" */
export type Policy_Approvals_Obj_Rel_Insert_Input = {
  data: Policy_Approvals_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Approvals_On_Conflict>;
};

/** on_conflict condition type for table "policy_approvals" */
export type Policy_Approvals_On_Conflict = {
  constraint: Policy_Approvals_Constraint;
  update_columns?: Array<Policy_Approvals_Update_Column>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_approvals". */
export type Policy_Approvals_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  policy_approval_users_aggregate?: InputMaybe<Policy_Approval_Users_Aggregate_Order_By>;
  policy_version?: InputMaybe<Policy_Versions_Order_By>;
  policy_version_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** select columns of table "policy_approvals" */
export enum Policy_Approvals_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  PolicyVersionId = 'policy_version_id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** placeholder for update columns of table "policy_approvals" (current role has no relevant permissions) */
export enum Policy_Approvals_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** list of users that would approve a policy, a snapshot of this instance is taken when approval is sent */
export type Policy_Approvers = {
  __typename?: 'policy_approvers';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  policy: Policies;
  policy_id: Scalars['uuid']['output'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "policy_approvers" */
export type Policy_Approvers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Approvers_Max_Order_By>;
  min?: InputMaybe<Policy_Approvers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_approvers" */
export type Policy_Approvers_Arr_Rel_Insert_Input = {
  data: Array<Policy_Approvers_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Approvers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_approvers". All fields are combined with a logical 'AND'. */
export type Policy_Approvers_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Approvers_Bool_Exp>>;
  _not?: InputMaybe<Policy_Approvers_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Approvers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "policy_approvers" */
export enum Policy_Approvers_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyApproversPkey = 'policy_approvers_pkey',
  /** unique or primary key constraint on columns "user_id", "policy_id" */
  PolicyApproversPolicyIdUserIdKey = 'policy_approvers_policy_id_user_id_key',
}

/** input type for inserting data into table "policy_approvers" */
export type Policy_Approvers_Insert_Input = {
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "policy_approvers" */
export type Policy_Approvers_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "policy_approvers" */
export type Policy_Approvers_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_approvers" */
export type Policy_Approvers_Mutation_Response = {
  __typename?: 'policy_approvers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Approvers>;
};

/** on_conflict condition type for table "policy_approvers" */
export type Policy_Approvers_On_Conflict = {
  constraint: Policy_Approvers_Constraint;
  update_columns?: Array<Policy_Approvers_Update_Column>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_approvers". */
export type Policy_Approvers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "policy_approvers" */
export enum Policy_Approvers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  UserId = 'user_id',
}

/** placeholder for update columns of table "policy_approvers" (current role has no relevant permissions) */
export enum Policy_Approvers_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export enum Policy_Statuses_Enum {
  Approved = 'Approved',
  AwaitingApproval = 'Awaiting_approval',
  Draft = 'Draft',
}

/** Boolean expression to compare columns of type "policy_statuses_enum". All fields are combined with logical 'AND'. */
export type Policy_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Policy_Statuses_Enum>;
  _in?: InputMaybe<Array<Policy_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Policy_Statuses_Enum>;
  _nin?: InputMaybe<Array<Policy_Statuses_Enum>>;
};

/** Stores list of tasks linked to policies */
export type Policy_Tasks = {
  __typename?: 'policy_tasks';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  policy_id: Scalars['uuid']['output'];
  /** An object relationship */
  policy_tasks_policy: Policies;
  /** An object relationship */
  policy_tasks_task: Tasks;
  task_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "policy_tasks" */
export type Policy_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Tasks_Max_Order_By>;
  min?: InputMaybe<Policy_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_tasks" */
export type Policy_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Policy_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_tasks". All fields are combined with a logical 'AND'. */
export type Policy_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Policy_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Tasks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_tasks_policy?: InputMaybe<Policies_Bool_Exp>;
  policy_tasks_task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "policy_tasks" */
export enum Policy_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyTasksPkey = 'policy_tasks_pkey',
  /** unique or primary key constraint on columns "task_id", "policy_id" */
  PolicyTasksPolicyIdTaskIdKey = 'policy_tasks_policy_id_task_id_key',
}

/** input type for inserting data into table "policy_tasks" */
export type Policy_Tasks_Insert_Input = {
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_tasks_policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_tasks_task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "policy_tasks" */
export type Policy_Tasks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "policy_tasks" */
export type Policy_Tasks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_tasks" */
export type Policy_Tasks_Mutation_Response = {
  __typename?: 'policy_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Tasks>;
};

/** on_conflict condition type for table "policy_tasks" */
export type Policy_Tasks_On_Conflict = {
  constraint: Policy_Tasks_Constraint;
  update_columns?: Array<Policy_Tasks_Update_Column>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_tasks". */
export type Policy_Tasks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_tasks_policy?: InputMaybe<Policies_Order_By>;
  policy_tasks_task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** select columns of table "policy_tasks" */
export enum Policy_Tasks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  TaskId = 'task_id',
}

/** placeholder for update columns of table "policy_tasks" (current role has no relevant permissions) */
export enum Policy_Tasks_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

export enum Policy_Types_Enum {
  Custom = 'Custom',
  Template = 'Template',
  Upload = 'Upload',
}

/** Boolean expression to compare columns of type "policy_types_enum". All fields are combined with logical 'AND'. */
export type Policy_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Policy_Types_Enum>;
  _in?: InputMaybe<Array<Policy_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Policy_Types_Enum>;
  _nin?: InputMaybe<Array<Policy_Types_Enum>>;
};

export enum Policy_Version_Statuses_Enum {
  Approved = 'Approved',
  AwaitingApproval = 'Awaiting_approval',
  Draft = 'Draft',
  Retired = 'Retired',
}

/** Boolean expression to compare columns of type "policy_version_statuses_enum". All fields are combined with logical 'AND'. */
export type Policy_Version_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Policy_Version_Statuses_Enum>;
  _in?: InputMaybe<Array<Policy_Version_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Policy_Version_Statuses_Enum>;
  _nin?: InputMaybe<Array<Policy_Version_Statuses_Enum>>;
};

/** columns and relationships of "policy_versions" */
export type Policy_Versions = {
  __typename?: 'policy_versions';
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  evidence_versions: Array<Evidence_Versions>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An object relationship */
  policy: Policies;
  /** An array relationship */
  policy_acknowledgements: Array<Policy_Acknowledgements>;
  /** An array relationship */
  policy_approvals: Array<Policy_Approvals>;
  policy_id: Scalars['uuid']['output'];
  policy_text?: Maybe<Scalars['String']['output']>;
  revision_details?: Maybe<Scalars['String']['output']>;
  status: Policy_Version_Statuses_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user?: Maybe<Users>;
  validity_start?: Maybe<Scalars['timestamptz']['output']>;
  version_id: Scalars['String']['output'];
  version_id_for_sort?: Maybe<Scalars['_int4']['output']>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsEvidence_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsPolicy_AcknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

/** columns and relationships of "policy_versions" */
export type Policy_VersionsPolicy_ApprovalsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

/** order by aggregate values of table "policy_versions" */
export type Policy_Versions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Policy_Versions_Max_Order_By>;
  min?: InputMaybe<Policy_Versions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "policy_versions" */
export type Policy_Versions_Arr_Rel_Insert_Input = {
  data: Array<Policy_Versions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Versions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "policy_versions". All fields are combined with a logical 'AND'. */
export type Policy_Versions_Bool_Exp = {
  _and?: InputMaybe<Array<Policy_Versions_Bool_Exp>>;
  _not?: InputMaybe<Policy_Versions_Bool_Exp>;
  _or?: InputMaybe<Array<Policy_Versions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  evidence_versions?: InputMaybe<Evidence_Versions_Bool_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  policy?: InputMaybe<Policies_Bool_Exp>;
  policy_acknowledgements?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
  policy_approvals?: InputMaybe<Policy_Approvals_Bool_Exp>;
  policy_id?: InputMaybe<Uuid_Comparison_Exp>;
  policy_text?: InputMaybe<String_Comparison_Exp>;
  revision_details?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<Policy_Version_Statuses_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  validity_start?: InputMaybe<Timestamptz_Comparison_Exp>;
  version_id?: InputMaybe<String_Comparison_Exp>;
  version_id_for_sort?: InputMaybe<_Int4_Comparison_Exp>;
};

/** unique or primary key constraints on table "policy_versions" */
export enum Policy_Versions_Constraint {
  /** unique or primary key constraint on columns "id" */
  PolicyVersionsPkey = 'policy_versions_pkey',
  /** unique or primary key constraint on columns "policy_id", "version_id" */
  PolicyVersionsPolicyIdVersionIdKey = 'policy_versions_policy_id_version_id_key',
}

/** input type for inserting data into table "policy_versions" */
export type Policy_Versions_Insert_Input = {
  evidence_versions?: InputMaybe<Evidence_Versions_Arr_Rel_Insert_Input>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  policy?: InputMaybe<Policies_Obj_Rel_Insert_Input>;
  policy_acknowledgements?: InputMaybe<Policy_Acknowledgements_Arr_Rel_Insert_Input>;
  policy_approvals?: InputMaybe<Policy_Approvals_Arr_Rel_Insert_Input>;
  policy_id?: InputMaybe<Scalars['uuid']['input']>;
  policy_text?: InputMaybe<Scalars['String']['input']>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by max() on columns of table "policy_versions" */
export type Policy_Versions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_text?: InputMaybe<Order_By>;
  revision_details?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  validity_start?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "policy_versions" */
export type Policy_Versions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_text?: InputMaybe<Order_By>;
  revision_details?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  validity_start?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "policy_versions" */
export type Policy_Versions_Mutation_Response = {
  __typename?: 'policy_versions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Policy_Versions>;
};

/** input type for inserting object relation for remote table "policy_versions" */
export type Policy_Versions_Obj_Rel_Insert_Input = {
  data: Policy_Versions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Policy_Versions_On_Conflict>;
};

/** on_conflict condition type for table "policy_versions" */
export type Policy_Versions_On_Conflict = {
  constraint: Policy_Versions_Constraint;
  update_columns?: Array<Policy_Versions_Update_Column>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** Ordering options when selecting data from "policy_versions". */
export type Policy_Versions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  evidence_versions_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  policy?: InputMaybe<Policies_Order_By>;
  policy_acknowledgements_aggregate?: InputMaybe<Policy_Acknowledgements_Aggregate_Order_By>;
  policy_approvals_aggregate?: InputMaybe<Policy_Approvals_Aggregate_Order_By>;
  policy_id?: InputMaybe<Order_By>;
  policy_text?: InputMaybe<Order_By>;
  revision_details?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  validity_start?: InputMaybe<Order_By>;
  version_id?: InputMaybe<Order_By>;
  version_id_for_sort?: InputMaybe<Order_By>;
};

/** primary key columns input for table: policy_versions */
export type Policy_Versions_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "policy_versions" */
export enum Policy_Versions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  PolicyId = 'policy_id',
  /** column name */
  PolicyText = 'policy_text',
  /** column name */
  RevisionDetails = 'revision_details',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidityStart = 'validity_start',
  /** column name */
  VersionId = 'version_id',
  /** column name */
  VersionIdForSort = 'version_id_for_sort',
}

/** input type for updating data in table "policy_versions" */
export type Policy_Versions_Set_Input = {
  revision_details?: InputMaybe<Scalars['String']['input']>;
  validity_start?: InputMaybe<Scalars['timestamptz']['input']>;
  version_id?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "policy_versions" */
export enum Policy_Versions_Update_Column {
  /** column name */
  RevisionDetails = 'revision_details',
  /** column name */
  ValidityStart = 'validity_start',
  /** column name */
  VersionId = 'version_id',
}

export type Policy_Versions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Policy_Versions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Policy_Versions_Bool_Exp;
};

/** columns and relationships of "program_controls" */
export type Program_Controls = {
  __typename?: 'program_controls';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  /** An object relationship */
  program: Programs;
  program_id: Scalars['uuid']['output'];
};

/** aggregated selection of "program_controls" */
export type Program_Controls_Aggregate = {
  __typename?: 'program_controls_aggregate';
  aggregate?: Maybe<Program_Controls_Aggregate_Fields>;
  nodes: Array<Program_Controls>;
};

export type Program_Controls_Aggregate_Bool_Exp = {
  count?: InputMaybe<Program_Controls_Aggregate_Bool_Exp_Count>;
};

export type Program_Controls_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Program_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Program_Controls_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "program_controls" */
export type Program_Controls_Aggregate_Fields = {
  __typename?: 'program_controls_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Program_Controls_Max_Fields>;
  min?: Maybe<Program_Controls_Min_Fields>;
};

/** aggregate fields of "program_controls" */
export type Program_Controls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Program_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "program_controls" */
export type Program_Controls_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Program_Controls_Max_Order_By>;
  min?: InputMaybe<Program_Controls_Min_Order_By>;
};

/** input type for inserting array relation for remote table "program_controls" */
export type Program_Controls_Arr_Rel_Insert_Input = {
  data: Array<Program_Controls_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Program_Controls_On_Conflict>;
};

/** Boolean expression to filter rows from the table "program_controls". All fields are combined with a logical 'AND'. */
export type Program_Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Program_Controls_Bool_Exp>>;
  _not?: InputMaybe<Program_Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Program_Controls_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  program?: InputMaybe<Programs_Bool_Exp>;
  program_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "program_controls" */
export enum Program_Controls_Constraint {
  /** unique or primary key constraint on columns "control_id", "program_id" */
  ProgramOrganizationControlsPkey = 'program_organization_controls_pkey',
}

/** input type for inserting data into table "program_controls" */
export type Program_Controls_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  program?: InputMaybe<Programs_Obj_Rel_Insert_Input>;
  program_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate max on columns */
export type Program_Controls_Max_Fields = {
  __typename?: 'program_controls_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  program_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "program_controls" */
export type Program_Controls_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Program_Controls_Min_Fields = {
  __typename?: 'program_controls_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  program_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "program_controls" */
export type Program_Controls_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "program_controls" */
export type Program_Controls_Mutation_Response = {
  __typename?: 'program_controls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Program_Controls>;
};

/** on_conflict condition type for table "program_controls" */
export type Program_Controls_On_Conflict = {
  constraint: Program_Controls_Constraint;
  update_columns?: Array<Program_Controls_Update_Column>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

/** Ordering options when selecting data from "program_controls". */
export type Program_Controls_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  program?: InputMaybe<Programs_Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** select columns of table "program_controls" */
export enum Program_Controls_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  ProgramId = 'program_id',
}

/** placeholder for update columns of table "program_controls" (current role has no relevant permissions) */
export enum Program_Controls_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** Determines which custom field is shown in a control belonging to a program */
export type Program_Field_Configs = {
  __typename?: 'program_field_configs';
  created_at: Scalars['timestamptz']['output'];
  entity_name: Scalars['String']['output'];
  /** An object relationship */
  field_config?: Maybe<Field_Configs>;
  field_config_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  program: Programs;
  program_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "program_field_configs" */
export type Program_Field_Configs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Program_Field_Configs_Max_Order_By>;
  min?: InputMaybe<Program_Field_Configs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "program_field_configs" */
export type Program_Field_Configs_Arr_Rel_Insert_Input = {
  data: Array<Program_Field_Configs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Program_Field_Configs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "program_field_configs". All fields are combined with a logical 'AND'. */
export type Program_Field_Configs_Bool_Exp = {
  _and?: InputMaybe<Array<Program_Field_Configs_Bool_Exp>>;
  _not?: InputMaybe<Program_Field_Configs_Bool_Exp>;
  _or?: InputMaybe<Array<Program_Field_Configs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  entity_name?: InputMaybe<String_Comparison_Exp>;
  field_config?: InputMaybe<Field_Configs_Bool_Exp>;
  field_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  program?: InputMaybe<Programs_Bool_Exp>;
  program_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "program_field_configs" */
export enum Program_Field_Configs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProgramFieldConfigsPkey = 'program_field_configs_pkey',
  /** unique or primary key constraint on columns "field_config_id", "program_id" */
  ProgramFieldConfigsProgramIdFieldConfigIdKey = 'program_field_configs_program_id_field_config_id_key',
}

/** input type for inserting data into table "program_field_configs" */
export type Program_Field_Configs_Insert_Input = {
  field_config?: InputMaybe<Field_Configs_Obj_Rel_Insert_Input>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  program?: InputMaybe<Programs_Obj_Rel_Insert_Input>;
  program_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "program_field_configs" */
export type Program_Field_Configs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_name?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "program_field_configs" */
export type Program_Field_Configs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_name?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "program_field_configs" */
export type Program_Field_Configs_Mutation_Response = {
  __typename?: 'program_field_configs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Program_Field_Configs>;
};

/** on_conflict condition type for table "program_field_configs" */
export type Program_Field_Configs_On_Conflict = {
  constraint: Program_Field_Configs_Constraint;
  update_columns?: Array<Program_Field_Configs_Update_Column>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

/** Ordering options when selecting data from "program_field_configs". */
export type Program_Field_Configs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  entity_name?: InputMaybe<Order_By>;
  field_config?: InputMaybe<Field_Configs_Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  program?: InputMaybe<Programs_Order_By>;
  program_id?: InputMaybe<Order_By>;
};

/** select columns of table "program_field_configs" */
export enum Program_Field_Configs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntityName = 'entity_name',
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProgramId = 'program_id',
}

/** placeholder for update columns of table "program_field_configs" (current role has no relevant permissions) */
export enum Program_Field_Configs_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "programs" */
export type Programs = {
  __typename?: 'programs';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  framework?: Maybe<Frameworks>;
  framework_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  program_controls: Array<Program_Controls>;
  /** An aggregate relationship */
  program_controls_aggregate: Program_Controls_Aggregate;
  /** An array relationship */
  program_field_configs: Array<Program_Field_Configs>;
  /** An array relationship */
  programs_vector_stores: Array<Ai_Programs_Vector_Store>;
  similarity?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  template_framework?: Maybe<Template_Frameworks>;
  template_framework_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** columns and relationships of "programs" */
export type ProgramsProgram_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

/** columns and relationships of "programs" */
export type ProgramsProgram_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Program_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Controls_Order_By>>;
  where?: InputMaybe<Program_Controls_Bool_Exp>;
};

/** columns and relationships of "programs" */
export type ProgramsProgram_Field_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Program_Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Program_Field_Configs_Order_By>>;
  where?: InputMaybe<Program_Field_Configs_Bool_Exp>;
};

/** columns and relationships of "programs" */
export type ProgramsPrograms_Vector_StoresArgs = {
  distinct_on?: InputMaybe<Array<Ai_Programs_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Programs_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
};

/** order by aggregate values of table "programs" */
export type Programs_Aggregate_Order_By = {
  avg?: InputMaybe<Programs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Programs_Max_Order_By>;
  min?: InputMaybe<Programs_Min_Order_By>;
  stddev?: InputMaybe<Programs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Programs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Programs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Programs_Sum_Order_By>;
  var_pop?: InputMaybe<Programs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Programs_Var_Samp_Order_By>;
  variance?: InputMaybe<Programs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "programs" */
export type Programs_Arr_Rel_Insert_Input = {
  data: Array<Programs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Programs_On_Conflict>;
};

/** order by avg() on columns of table "programs" */
export type Programs_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "programs". All fields are combined with a logical 'AND'. */
export type Programs_Bool_Exp = {
  _and?: InputMaybe<Array<Programs_Bool_Exp>>;
  _not?: InputMaybe<Programs_Bool_Exp>;
  _or?: InputMaybe<Array<Programs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  framework?: InputMaybe<Frameworks_Bool_Exp>;
  framework_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  program_controls?: InputMaybe<Program_Controls_Bool_Exp>;
  program_controls_aggregate?: InputMaybe<Program_Controls_Aggregate_Bool_Exp>;
  program_field_configs?: InputMaybe<Program_Field_Configs_Bool_Exp>;
  programs_vector_stores?: InputMaybe<Ai_Programs_Vector_Store_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  template_framework?: InputMaybe<Template_Frameworks_Bool_Exp>;
  template_framework_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "programs" */
export enum Programs_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProgramsIdKey = 'programs_id_key',
  /** unique or primary key constraint on columns "id" */
  ProgramsPkey = 'programs_pkey',
}

/** input type for inserting data into table "programs" */
export type Programs_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  framework?: InputMaybe<Frameworks_Obj_Rel_Insert_Input>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  program_controls?: InputMaybe<Program_Controls_Arr_Rel_Insert_Input>;
  program_field_configs?: InputMaybe<Program_Field_Configs_Arr_Rel_Insert_Input>;
  template_framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "programs" */
export type Programs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "programs" */
export type Programs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "programs" */
export type Programs_Mutation_Response = {
  __typename?: 'programs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Programs>;
};

/** input type for inserting object relation for remote table "programs" */
export type Programs_Obj_Rel_Insert_Input = {
  data: Programs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Programs_On_Conflict>;
};

/** on_conflict condition type for table "programs" */
export type Programs_On_Conflict = {
  constraint: Programs_Constraint;
  update_columns?: Array<Programs_Update_Column>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** Ordering options when selecting data from "programs". */
export type Programs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  framework?: InputMaybe<Frameworks_Order_By>;
  framework_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  program_controls_aggregate?: InputMaybe<Program_Controls_Aggregate_Order_By>;
  program_field_configs_aggregate?: InputMaybe<Program_Field_Configs_Aggregate_Order_By>;
  programs_vector_stores_aggregate?: InputMaybe<Ai_Programs_Vector_Store_Aggregate_Order_By>;
  similarity?: InputMaybe<Order_By>;
  template_framework?: InputMaybe<Template_Frameworks_Order_By>;
  template_framework_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: programs */
export type Programs_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "programs" */
export enum Programs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FrameworkId = 'framework_id',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  TemplateFrameworkId = 'template_framework_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "programs" */
export type Programs_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** order by stddev() on columns of table "programs" */
export type Programs_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "programs" */
export type Programs_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "programs" */
export type Programs_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "programs" */
export type Programs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Programs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Programs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  template_framework_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by sum() on columns of table "programs" */
export type Programs_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "programs" */
export enum Programs_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
}

export type Programs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Programs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Programs_Bool_Exp;
};

/** order by var_pop() on columns of table "programs" */
export type Programs_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "programs" */
export type Programs_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "programs" */
export type Programs_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** execute function "ai.control_evidences_suggestions" which returns "evidences" */
  ai_control_evidences_suggestions: Array<Evidences>;
  /** execute function "ai.control_evidences_suggestions" and query aggregates on result of table type "evidences" */
  ai_control_evidences_suggestions_aggregate: Evidences_Aggregate;
  /** execute function "ai.controls_text_search" which returns "controls" */
  ai_controls_text_search: Array<Controls>;
  /** execute function "ai.controls_text_search" and query aggregates on result of table type "controls" */
  ai_controls_text_search_aggregate: Controls_Aggregate;
  /** execute function "ai.controls_vector_store_search" which returns "controls" */
  ai_controls_vector_store_search: Array<Controls>;
  /** execute function "ai.controls_vector_store_search" and query aggregates on result of table type "controls" */
  ai_controls_vector_store_search_aggregate: Controls_Aggregate;
  /** execute function "ai.documents_text_search" which returns "documents" */
  ai_documents_text_search: Array<Documents>;
  /** fetch data from the table: "ai.documents_vector_store" */
  ai_documents_vector_store: Array<Ai_Documents_Vector_Store>;
  /** fetch data from the table: "ai.documents_vector_store" using primary key columns */
  ai_documents_vector_store_by_pk?: Maybe<Ai_Documents_Vector_Store>;
  /** execute function "ai.documents_vector_store_search" which returns "ai.documents_vector_store" */
  ai_documents_vector_store_search: Array<Ai_Documents_Vector_Store>;
  /** ai_evidence_suggestions_for_control */
  ai_evidence_suggestions_for_control?: Maybe<AiControlEvidenceSuggestionsOutput>;
  /** execute function "ai.evidences_text_search" which returns "evidences" */
  ai_evidences_text_search: Array<Evidences>;
  /** execute function "ai.evidences_text_search" and query aggregates on result of table type "evidences" */
  ai_evidences_text_search_aggregate: Evidences_Aggregate;
  /** execute function "ai.evidences_vector_store_search" which returns "evidences" */
  ai_evidences_vector_store_search: Array<Evidences>;
  /** execute function "ai.evidences_vector_store_search" and query aggregates on result of table type "evidences" */
  ai_evidences_vector_store_search_aggregate: Evidences_Aggregate;
  /** execute function "ai.policies_text_search" which returns "policies" */
  ai_policies_text_search: Array<Policies>;
  /** fetch data from the table: "ai.policies_vector_store" */
  ai_policies_vector_store: Array<Ai_Policies_Vector_Store>;
  /** fetch data from the table: "ai.policies_vector_store" using primary key columns */
  ai_policies_vector_store_by_pk?: Maybe<Ai_Policies_Vector_Store>;
  /** execute function "ai.policies_vector_store_search" which returns "ai.policies_vector_store" */
  ai_policies_vector_store_search: Array<Ai_Policies_Vector_Store>;
  /** execute function "ai.programs_text_search" which returns "programs" */
  ai_programs_text_search: Array<Programs>;
  /** execute function "ai.programs_vector_store_search" which returns "programs" */
  ai_programs_vector_store_search: Array<Programs>;
  /** execute function "ai.risk_controls_suggestions" which returns "controls" */
  ai_risk_controls_suggestions: Array<Controls>;
  /** execute function "ai.risk_controls_suggestions" and query aggregates on result of table type "controls" */
  ai_risk_controls_suggestions_aggregate: Controls_Aggregate;
  /** execute function "ai.risks_text_search" which returns "risks" */
  ai_risks_text_search: Array<Risks>;
  /** execute function "ai.risks_text_search" and query aggregates on result of table type "risks" */
  ai_risks_text_search_aggregate: Risks_Aggregate;
  /** execute function "ai.risks_vector_store_search" which returns "risks" */
  ai_risks_vector_store_search: Array<Risks>;
  /** execute function "ai.risks_vector_store_search" and query aggregates on result of table type "risks" */
  ai_risks_vector_store_search_aggregate: Risks_Aggregate;
  /** execute function "ai.tasks_text_search" which returns "tasks" */
  ai_tasks_text_search: Array<Tasks>;
  /** execute function "ai.tasks_text_search" and query aggregates on result of table type "tasks" */
  ai_tasks_text_search_aggregate: Tasks_Aggregate;
  /** execute function "ai.tasks_vector_store_search" which returns "tasks" */
  ai_tasks_vector_store_search: Array<Tasks>;
  /** execute function "ai.tasks_vector_store_search" and query aggregates on result of table type "tasks" */
  ai_tasks_vector_store_search_aggregate: Tasks_Aggregate;
  /** execute function "ai.vendors_text_search" which returns "vendors" */
  ai_vendors_text_search: Array<Vendors>;
  /** execute function "ai.vendors_vector_store_search" which returns "vendors" */
  ai_vendors_vector_store_search: Array<Vendors>;
  /** fetch data from the table: "audit.control_history_view" */
  audit_control_history_view: Array<Audit_Control_History_View>;
  /** fetch data from the table: "audit.policy_history_view" */
  audit_policy_history_view: Array<Audit_Policy_History_View>;
  /** fetch data from the table: "audit.risk_history_view" */
  audit_risk_history_view: Array<Audit_Risk_History_View>;
  /** fetch data from the table: "audit.vendor_history_view" */
  audit_vendor_history_view: Array<Audit_Vendor_History_View>;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** An array relationship */
  categories: Array<Categories>;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** An array relationship */
  client_questionnaire_questions: Array<Client_Questionnaire_Questions>;
  /** An aggregate relationship */
  client_questionnaire_questions_aggregate: Client_Questionnaire_Questions_Aggregate;
  /** fetch data from the table: "client_questionnaire_questions" using primary key columns */
  client_questionnaire_questions_by_pk?: Maybe<Client_Questionnaire_Questions>;
  /** An array relationship */
  client_questionnaires: Array<Client_Questionnaires>;
  /** fetch data from the table: "client_questionnaires" using primary key columns */
  client_questionnaires_by_pk?: Maybe<Client_Questionnaires>;
  /** An array relationship */
  controls: Array<Controls>;
  /** An aggregate relationship */
  controls_aggregate: Controls_Aggregate;
  /** fetch data from the table: "controls" using primary key columns */
  controls_by_pk?: Maybe<Controls>;
  /** An array relationship */
  criteria: Array<Criteria>;
  /** fetch data from the table: "criteria" using primary key columns */
  criteria_by_pk?: Maybe<Criteria>;
  /** fetch data from the table: "daily_control_stats_view" */
  daily_control_stats_view: Array<Daily_Control_Stats_View>;
  /** fetch data from the table: "daily_control_user_stats_view" */
  daily_control_user_stats_view: Array<Daily_Control_User_Stats_View>;
  /** An array relationship */
  documents: Array<Documents>;
  /** fetch data from the table: "documents" using primary key columns */
  documents_by_pk?: Maybe<Documents>;
  /** An array relationship */
  evidence_integrations: Array<Evidence_Integrations>;
  /** fetch data from the table: "evidence_integrations" using primary key columns */
  evidence_integrations_by_pk?: Maybe<Evidence_Integrations>;
  /** An array relationship */
  evidence_versions: Array<Evidence_Versions>;
  /** fetch data from the table: "evidence_versions" using primary key columns */
  evidence_versions_by_pk?: Maybe<Evidence_Versions>;
  /** An array relationship */
  evidences: Array<Evidences>;
  /** An aggregate relationship */
  evidences_aggregate: Evidences_Aggregate;
  /** fetch data from the table: "evidences" using primary key columns */
  evidences_by_pk?: Maybe<Evidences>;
  /** An array relationship */
  field_configs: Array<Field_Configs>;
  /** fetch data from the table: "field_configs" using primary key columns */
  field_configs_by_pk?: Maybe<Field_Configs>;
  /** fetch data from the table: "storage.files" using primary key columns */
  file?: Maybe<Files>;
  /** An array relationship */
  frameworks: Array<Frameworks>;
  /** fetch data from the table: "frameworks" using primary key columns */
  frameworks_by_pk?: Maybe<Frameworks>;
  /** An array relationship */
  frameworks_controls: Array<Frameworks_Controls>;
  /** fetch data from the table: "frameworks_controls" using primary key columns */
  frameworks_controls_by_pk?: Maybe<Frameworks_Controls>;
  /** Execute a global search */
  global_search?: Maybe<SearchOutput>;
  /** An array relationship */
  groups: Array<Groups>;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table: "integrations" */
  integrations: Array<Integrations>;
  /** fetch data from the table: "integrations" using primary key columns */
  integrations_by_pk?: Maybe<Integrations>;
  /** An array relationship */
  invitations: Array<Invitations>;
  /** fetch data from the table: "invitations" using primary key columns */
  invitations_by_pk?: Maybe<Invitations>;
  /** An array relationship */
  notification_disabled_settings: Array<Notification_Disabled_Settings>;
  /** fetch data from the table: "notification_disabled_settings" using primary key columns */
  notification_disabled_settings_by_pk?: Maybe<Notification_Disabled_Settings>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table: "organization_evidence_tags" */
  organization_evidence_tags: Array<Organization_Evidence_Tags>;
  /** fetch data from the table: "organization_evidence_tags" using primary key columns */
  organization_evidence_tags_by_pk?: Maybe<Organization_Evidence_Tags>;
  /** An array relationship */
  organization_integrations: Array<Organization_Integrations>;
  /** fetch data from the table: "organization_integrations" using primary key columns */
  organization_integrations_by_pk?: Maybe<Organization_Integrations>;
  /** fetch data from the table: "organization_risk_categories" */
  organization_risk_categories: Array<Organization_Risk_Categories>;
  /** fetch data from the table: "organization_risk_categories" using primary key columns */
  organization_risk_categories_by_pk?: Maybe<Organization_Risk_Categories>;
  /** fetch data from the table: "organization_risk_tags" */
  organization_risk_tags: Array<Organization_Risk_Tags>;
  /** fetch data from the table: "organization_risk_tags" using primary key columns */
  organization_risk_tags_by_pk?: Maybe<Organization_Risk_Tags>;
  /** An array relationship */
  organization_template_frameworks: Array<Organization_Template_Frameworks>;
  /** fetch data from the table: "organization_template_frameworks" using primary key columns */
  organization_template_frameworks_by_pk?: Maybe<Organization_Template_Frameworks>;
  /** An array relationship */
  organization_tenants: Array<Organization_Tenants>;
  /** fetch data from the table: "organization_tenants" using primary key columns */
  organization_tenants_by_pk?: Maybe<Organization_Tenants>;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** fetch data from the table: "organization_users" using primary key columns */
  organization_users_by_pk?: Maybe<Organization_Users>;
  /** fetch data from the table: "organization_vendor_risk_levels" */
  organization_vendor_risk_levels: Array<Organization_Vendor_Risk_Levels>;
  /** fetch data from the table: "organization_vendor_risk_levels" using primary key columns */
  organization_vendor_risk_levels_by_pk?: Maybe<Organization_Vendor_Risk_Levels>;
  /** fetch data from the table: "organization_vendor_tiers" */
  organization_vendor_tiers: Array<Organization_Vendor_Tiers>;
  /** fetch data from the table: "organization_vendor_tiers" using primary key columns */
  organization_vendor_tiers_by_pk?: Maybe<Organization_Vendor_Tiers>;
  /** An array relationship */
  organizations: Array<Organizations>;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** An array relationship */
  policies: Array<Policies>;
  /** fetch data from the table: "policies" using primary key columns */
  policies_by_pk?: Maybe<Policies>;
  /** An array relationship */
  programs: Array<Programs>;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<Programs>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** An array relationship */
  reports: Array<Reports>;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** An array relationship */
  risk_impacts: Array<Risk_Impacts>;
  /** fetch data from the table: "risk_impacts" using primary key columns */
  risk_impacts_by_pk?: Maybe<Risk_Impacts>;
  /** An array relationship */
  risk_inherent_levels: Array<Risk_Inherent_Levels>;
  /** fetch data from the table: "risk_inherent_levels" using primary key columns */
  risk_inherent_levels_by_pk?: Maybe<Risk_Inherent_Levels>;
  /** An array relationship */
  risk_likelihoods: Array<Risk_Likelihoods>;
  /** fetch data from the table: "risk_likelihoods" using primary key columns */
  risk_likelihoods_by_pk?: Maybe<Risk_Likelihoods>;
  /** An array relationship */
  risk_residual_levels: Array<Risk_Residual_Levels>;
  /** fetch data from the table: "risk_residual_levels" using primary key columns */
  risk_residual_levels_by_pk?: Maybe<Risk_Residual_Levels>;
  /** An array relationship */
  risks: Array<Risks>;
  /** An aggregate relationship */
  risks_aggregate: Risks_Aggregate;
  /** fetch data from the table: "risks" using primary key columns */
  risks_by_pk?: Maybe<Risks>;
  /** An array relationship */
  role_permissions: Array<Role_Permissions>;
  /** fetch data from the table: "role_permissions" using primary key columns */
  role_permissions_by_pk?: Maybe<Role_Permissions>;
  /** An array relationship */
  roles: Array<Roles>;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** An array relationship */
  tags: Array<Tags>;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table: "template_controls" */
  template_controls: Array<Template_Controls>;
  /** fetch aggregated fields from the table: "template_controls" */
  template_controls_aggregate: Template_Controls_Aggregate;
  /** fetch data from the table: "template_controls" using primary key columns */
  template_controls_by_pk?: Maybe<Template_Controls>;
  /** fetch data from the table: "template_frameworks" */
  template_frameworks: Array<Template_Frameworks>;
  /** fetch data from the table: "template_frameworks" using primary key columns */
  template_frameworks_by_pk?: Maybe<Template_Frameworks>;
  /** An array relationship */
  template_frameworks_controls: Array<Template_Frameworks_Controls>;
  /** fetch data from the table: "template_frameworks_controls" using primary key columns */
  template_frameworks_controls_by_pk?: Maybe<Template_Frameworks_Controls>;
  /** An array relationship */
  template_tasks: Array<Template_Tasks>;
  /** An aggregate relationship */
  template_tasks_aggregate: Template_Tasks_Aggregate;
  /** fetch data from the table: "template_tasks" using primary key columns */
  template_tasks_by_pk?: Maybe<Template_Tasks>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "user_controls_stats_view" */
  user_controls_stats_view: Array<User_Controls_Stats_View>;
  /** An array relationship */
  user_notifications: Array<User_Notifications>;
  /** An aggregate relationship */
  user_notifications_aggregate: User_Notifications_Aggregate;
  /** fetch data from the table: "user_notifications" using primary key columns */
  user_notifications_by_pk?: Maybe<User_Notifications>;
  /** fetch data from the table: "user_tasks_stats_view" */
  user_tasks_stats_view: Array<User_Tasks_Stats_View>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" using primary key columns */
  vendor_questionnaire_form_answer_statuses_by_pk?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** An array relationship */
  vendor_questionnaire_form_answers: Array<Vendor_Questionnaire_Form_Answers>;
  /** fetch data from the table: "vendor_questionnaire_form_answers" using primary key columns */
  vendor_questionnaire_form_answers_by_pk?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** An array relationship */
  vendor_questionnaire_form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_form_uploads" using primary key columns */
  vendor_questionnaire_form_uploads_by_pk?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms: Array<Vendor_Questionnaire_Forms>;
  /** fetch data from the table: "vendor_questionnaire_forms" using primary key columns */
  vendor_questionnaire_forms_by_pk?: Maybe<Vendor_Questionnaire_Forms>;
  /** fetch data from the table: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses: Array<Vendor_Questionnaire_Statuses>;
  /** fetch data from the table: "vendor_questionnaire_statuses" using primary key columns */
  vendor_questionnaire_statuses_by_pk?: Maybe<Vendor_Questionnaire_Statuses>;
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
  /** fetch data from the table: "vendor_questionnaires" using primary key columns */
  vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
  /** An array relationship */
  vendor_risks: Array<Vendor_Risks>;
  /** fetch data from the table: "vendor_risks" using primary key columns */
  vendor_risks_by_pk?: Maybe<Vendor_Risks>;
  /** An array relationship */
  vendors: Array<Vendors>;
  /** fetch data from the table: "vendors" using primary key columns */
  vendors_by_pk?: Maybe<Vendors>;
  /** fetch data from the table: "vendors_summary_view" */
  vendors_summary_view: Array<Vendors_Summary_View>;
};

export type Query_RootAi_Control_Evidences_SuggestionsArgs = {
  args: Ai_Control_Evidences_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Control_Evidences_Suggestions_AggregateArgs = {
  args: Ai_Control_Evidences_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Controls_Text_SearchArgs = {
  args: Ai_Controls_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootAi_Controls_Text_Search_AggregateArgs = {
  args: Ai_Controls_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootAi_Controls_Vector_Store_SearchArgs = {
  args: Ai_Controls_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootAi_Controls_Vector_Store_Search_AggregateArgs = {
  args: Ai_Controls_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootAi_Documents_Text_SearchArgs = {
  args: Ai_Documents_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Query_RootAi_Documents_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Documents_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Documents_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Documents_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Documents_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Documents_Vector_Store_SearchArgs = {
  args: Ai_Documents_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Ai_Documents_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Documents_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Documents_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Evidence_Suggestions_For_ControlArgs = {
  input: AiControlEvidenceSuggestionsInput;
};

export type Query_RootAi_Evidences_Text_SearchArgs = {
  args: Ai_Evidences_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Evidences_Text_Search_AggregateArgs = {
  args: Ai_Evidences_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Evidences_Vector_Store_SearchArgs = {
  args: Ai_Evidences_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Evidences_Vector_Store_Search_AggregateArgs = {
  args: Ai_Evidences_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootAi_Policies_Text_SearchArgs = {
  args: Ai_Policies_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Query_RootAi_Policies_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Policies_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Policies_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Policies_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAi_Policies_Vector_Store_SearchArgs = {
  args: Ai_Policies_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Ai_Policies_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Policies_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

export type Query_RootAi_Programs_Text_SearchArgs = {
  args: Ai_Programs_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Query_RootAi_Programs_Vector_Store_SearchArgs = {
  args: Ai_Programs_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Query_RootAi_Risk_Controls_SuggestionsArgs = {
  args: Ai_Risk_Controls_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootAi_Risk_Controls_Suggestions_AggregateArgs = {
  args: Ai_Risk_Controls_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootAi_Risks_Text_SearchArgs = {
  args: Ai_Risks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootAi_Risks_Text_Search_AggregateArgs = {
  args: Ai_Risks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootAi_Risks_Vector_Store_SearchArgs = {
  args: Ai_Risks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootAi_Risks_Vector_Store_Search_AggregateArgs = {
  args: Ai_Risks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootAi_Tasks_Text_SearchArgs = {
  args: Ai_Tasks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootAi_Tasks_Text_Search_AggregateArgs = {
  args: Ai_Tasks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootAi_Tasks_Vector_Store_SearchArgs = {
  args: Ai_Tasks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootAi_Tasks_Vector_Store_Search_AggregateArgs = {
  args: Ai_Tasks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootAi_Vendors_Text_SearchArgs = {
  args: Ai_Vendors_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Query_RootAi_Vendors_Vector_Store_SearchArgs = {
  args: Ai_Vendors_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Query_RootAudit_Control_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Control_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Control_History_View_Order_By>>;
  where?: InputMaybe<Audit_Control_History_View_Bool_Exp>;
};

export type Query_RootAudit_Policy_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Policy_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Policy_History_View_Order_By>>;
  where?: InputMaybe<Audit_Policy_History_View_Bool_Exp>;
};

export type Query_RootAudit_Risk_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Risk_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Risk_History_View_Order_By>>;
  where?: InputMaybe<Audit_Risk_History_View_Bool_Exp>;
};

export type Query_RootAudit_Vendor_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Vendor_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Vendor_History_View_Order_By>>;
  where?: InputMaybe<Audit_Vendor_History_View_Bool_Exp>;
};

export type Query_RootAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootAuthRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

export type Query_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

export type Query_RootCategories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootClient_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

export type Query_RootClient_Questionnaire_Questions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootClient_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

export type Query_RootClient_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Query_RootControls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootCriteriaArgs = {
  distinct_on?: InputMaybe<Array<Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Criteria_Order_By>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

export type Query_RootCriteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootDaily_Control_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_Stats_View_Bool_Exp>;
};

export type Query_RootDaily_Control_User_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_User_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_User_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_User_Stats_View_Bool_Exp>;
};

export type Query_RootDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Query_RootDocuments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEvidence_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Integrations_Order_By>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

export type Query_RootEvidence_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEvidence_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

export type Query_RootEvidence_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootEvidencesArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootEvidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Query_RootEvidences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootField_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

export type Query_RootField_Configs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFileArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFrameworksArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Order_By>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

export type Query_RootFrameworks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootFrameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

export type Query_RootFrameworks_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

export type Query_RootGlobal_SearchArgs = {
  input: SearchInput;
};

export type Query_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

export type Query_RootGroups_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integrations_Order_By>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Query_RootIntegrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

export type Query_RootInvitations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootNotification_Disabled_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

export type Query_RootNotification_Disabled_Settings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Evidence_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Evidence_Tags_Order_By>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

export type Query_RootOrganization_Evidence_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

export type Query_RootOrganization_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Risk_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Categories_Order_By>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

export type Query_RootOrganization_Risk_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Risk_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Tags_Order_By>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

export type Query_RootOrganization_Risk_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Template_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

export type Query_RootOrganization_Template_Frameworks_By_PkArgs = {
  organization_id: Scalars['uuid']['input'];
  template_framework_id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tenants_Order_By>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

export type Query_RootOrganization_Tenants_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

export type Query_RootOrganization_Users_By_PkArgs = {
  organization_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Vendor_Risk_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

export type Query_RootOrganization_Vendor_Risk_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganization_Vendor_TiersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Tiers_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

export type Query_RootOrganization_Vendor_Tiers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Query_RootOrganizations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Query_RootPolicies_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Query_RootPrograms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Query_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

export type Query_RootReports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_ImpactsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Impacts_Order_By>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

export type Query_RootRisk_Impacts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_Inherent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

export type Query_RootRisk_Inherent_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_LikelihoodsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Likelihoods_Order_By>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

export type Query_RootRisk_Likelihoods_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisk_Residual_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

export type Query_RootRisk_Residual_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRisksArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Query_RootRisks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRole_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

export type Query_RootRole_Permissions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Query_RootRoles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Query_RootTags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Query_RootTasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTemplate_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

export type Query_RootTemplate_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

export type Query_RootTemplate_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTemplate_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

export type Query_RootTemplate_Frameworks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootTemplate_Frameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

export type Query_RootTemplate_Frameworks_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

export type Query_RootTemplate_TasksArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

export type Query_RootTemplate_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

export type Query_RootTemplate_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootUserArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootUser_Controls_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<User_Controls_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Controls_Stats_View_Order_By>>;
  where?: InputMaybe<User_Controls_Stats_View_Bool_Exp>;
};

export type Query_RootUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

export type Query_RootUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

export type Query_RootUser_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootUser_Tasks_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<User_Tasks_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Tasks_Stats_View_Order_By>>;
  where?: InputMaybe<User_Tasks_Stats_View_Bool_Exp>;
};

export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Answer_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Answer_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootVendor_Questionnaire_Form_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_Form_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Form_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_FormsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Forms_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Forms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_Questionnaire_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Query_RootVendor_Questionnaire_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Query_RootVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Query_RootVendor_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendor_RisksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

export type Query_RootVendor_Risks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Query_RootVendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Query_RootVendors_Summary_ViewArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Summary_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Summary_View_Order_By>>;
  where?: InputMaybe<Vendors_Summary_View_Bool_Exp>;
};

/** File uploads for questionnaires */
export type Questionnaire_Uploads = {
  __typename?: 'questionnaire_uploads';
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "questionnaire_uploads" */
export type Questionnaire_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaire_Uploads_Max_Order_By>;
  min?: InputMaybe<Questionnaire_Uploads_Min_Order_By>;
};

/** input type for inserting array relation for remote table "questionnaire_uploads" */
export type Questionnaire_Uploads_Arr_Rel_Insert_Input = {
  data: Array<Questionnaire_Uploads_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaire_Uploads_On_Conflict>;
};

/** Boolean expression to filter rows from the table "questionnaire_uploads". All fields are combined with a logical 'AND'. */
export type Questionnaire_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaire_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaire_Uploads_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "questionnaire_uploads" */
export enum Questionnaire_Uploads_Constraint {
  /** unique or primary key constraint on columns "id" */
  QuestionnaireUploadsPkey = 'questionnaire_uploads_pkey',
}

/** input type for inserting data into table "questionnaire_uploads" */
export type Questionnaire_Uploads_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire?: InputMaybe<Questionnaires_Obj_Rel_Insert_Input>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "questionnaire_uploads" */
export type Questionnaire_Uploads_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "questionnaire_uploads" */
export type Questionnaire_Uploads_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionnaire_uploads" */
export type Questionnaire_Uploads_Mutation_Response = {
  __typename?: 'questionnaire_uploads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaire_Uploads>;
};

/** on_conflict condition type for table "questionnaire_uploads" */
export type Questionnaire_Uploads_On_Conflict = {
  constraint: Questionnaire_Uploads_Constraint;
  update_columns?: Array<Questionnaire_Uploads_Update_Column>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaire_uploads". */
export type Questionnaire_Uploads_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: questionnaire_uploads */
export type Questionnaire_Uploads_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "questionnaire_uploads" */
export enum Questionnaire_Uploads_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
}

/** input type for updating data in table "questionnaire_uploads" */
export type Questionnaire_Uploads_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "questionnaire_uploads" */
export enum Questionnaire_Uploads_Update_Column {
  /** column name */
  FileId = 'file_id',
}

export type Questionnaire_Uploads_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaire_Uploads_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaire_Uploads_Bool_Exp;
};

/** columns and relationships of "questionnaires" */
export type Questionnaires = {
  __typename?: 'questionnaires';
  /** An array relationship */
  comments: Array<Comments>;
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  form?: Maybe<Forms>;
  form_id?: Maybe<Scalars['uuid']['output']>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  questionnaire_uploads: Array<Questionnaire_Uploads>;
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
};

/** columns and relationships of "questionnaires" */
export type QuestionnairesCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** columns and relationships of "questionnaires" */
export type QuestionnairesQuestionnaire_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "questionnaires" */
export type QuestionnairesVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** order by aggregate values of table "questionnaires" */
export type Questionnaires_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Questionnaires_Max_Order_By>;
  min?: InputMaybe<Questionnaires_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "questionnaires". All fields are combined with a logical 'AND'. */
export type Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Questionnaires_Bool_Exp>>;
  comments?: InputMaybe<Comments_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  form?: InputMaybe<Forms_Bool_Exp>;
  form_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire_uploads?: InputMaybe<Questionnaire_Uploads_Bool_Exp>;
  vendor_questionnaires?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** unique or primary key constraints on table "questionnaires" */
export enum Questionnaires_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  QuestionnairesFileIdKey = 'questionnaires_file_id_key',
  /** unique or primary key constraint on columns "form_id" */
  QuestionnairesFormIdKey = 'questionnaires_form_id_key',
  /** unique or primary key constraint on columns "id" */
  QuestionnairesPkey = 'questionnaires_pkey',
}

/** input type for inserting data into table "questionnaires" */
export type Questionnaires_Insert_Input = {
  comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  form?: InputMaybe<Forms_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  questionnaire_uploads?: InputMaybe<Questionnaire_Uploads_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "questionnaires" */
export type Questionnaires_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "questionnaires" */
export type Questionnaires_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "questionnaires" */
export type Questionnaires_Mutation_Response = {
  __typename?: 'questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Questionnaires>;
};

/** input type for inserting object relation for remote table "questionnaires" */
export type Questionnaires_Obj_Rel_Insert_Input = {
  data: Questionnaires_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Questionnaires_On_Conflict>;
};

/** on_conflict condition type for table "questionnaires" */
export type Questionnaires_On_Conflict = {
  constraint: Questionnaires_Constraint;
  update_columns?: Array<Questionnaires_Update_Column>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** Ordering options when selecting data from "questionnaires". */
export type Questionnaires_Order_By = {
  comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  form?: InputMaybe<Forms_Order_By>;
  form_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  questionnaire_uploads_aggregate?: InputMaybe<Questionnaire_Uploads_Aggregate_Order_By>;
  vendor_questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
};

/** primary key columns input for table: questionnaires */
export type Questionnaires_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "questionnaires" */
export enum Questionnaires_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  FormId = 'form_id',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
}

/** input type for updating data in table "questionnaires" */
export type Questionnaires_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Streaming cursor of the table "questionnaires" */
export type Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Questionnaires_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  form_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "questionnaires" */
export enum Questionnaires_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
}

export type Questionnaires_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Questionnaires_Bool_Exp;
};

export enum Report_Types_Enum {
  Controls = 'controls',
  Evidence = 'evidence',
  Risks = 'risks',
  Tasks = 'tasks',
  Vendors = 'vendors',
}

/** Boolean expression to compare columns of type "report_types_enum". All fields are combined with logical 'AND'. */
export type Report_Types_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Report_Types_Enum>;
  _in?: InputMaybe<Array<Report_Types_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Report_Types_Enum>;
  _nin?: InputMaybe<Array<Report_Types_Enum>>;
};

/** columns and relationships of "reports" */
export type Reports = {
  __typename?: 'reports';
  created_at: Scalars['timestamptz']['output'];
  created_by: Scalars['uuid']['output'];
  description?: Maybe<Scalars['String']['output']>;
  filters?: Maybe<Scalars['ColumnFiltersState']['output']>;
  id: Scalars['uuid']['output'];
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  sorting?: Maybe<Scalars['SortingState']['output']>;
  type: Report_Types_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
};

/** columns and relationships of "reports" */
export type ReportsFiltersArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "reports" */
export type ReportsSortingArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** order by aggregate values of table "reports" */
export type Reports_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reports_Max_Order_By>;
  min?: InputMaybe<Reports_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Reports_Append_Input = {
  filters?: InputMaybe<Scalars['jsonb']['input']>;
  sorting?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Boolean expression to filter rows from the table "reports". All fields are combined with a logical 'AND'. */
export type Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Reports_Bool_Exp>>;
  _not?: InputMaybe<Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Reports_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  filters?: InputMaybe<Jsonb_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  sorting?: InputMaybe<Jsonb_Comparison_Exp>;
  type?: InputMaybe<Report_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "reports" */
export enum Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReportsPkey = 'reports_pkey',
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Reports_Delete_At_Path_Input = {
  filters?: InputMaybe<Array<Scalars['String']['input']>>;
  sorting?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Reports_Delete_Elem_Input = {
  filters?: InputMaybe<Scalars['Int']['input']>;
  sorting?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Reports_Delete_Key_Input = {
  filters?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "reports" */
export type Reports_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  sorting?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Report_Types_Enum>;
};

/** order by max() on columns of table "reports" */
export type Reports_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "reports" */
export type Reports_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reports" */
export type Reports_Mutation_Response = {
  __typename?: 'reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reports>;
};

/** on_conflict condition type for table "reports" */
export type Reports_On_Conflict = {
  constraint: Reports_Constraint;
  update_columns?: Array<Reports_Update_Column>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "reports". */
export type Reports_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  filters?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  sorting?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: reports */
export type Reports_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Reports_Prepend_Input = {
  filters?: InputMaybe<Scalars['jsonb']['input']>;
  sorting?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "reports" */
export enum Reports_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Filters = 'filters',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Sorting = 'sorting',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "reports" */
export type Reports_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['jsonb']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sorting?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "reports" */
export type Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reports_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  filters?: InputMaybe<Scalars['jsonb']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  sorting?: InputMaybe<Scalars['jsonb']['input']>;
  type?: InputMaybe<Report_Types_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "reports" */
export enum Reports_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Filters = 'filters',
  /** column name */
  Name = 'name',
  /** column name */
  Sorting = 'sorting',
}

export type Reports_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Reports_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Reports_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Reports_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Reports_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Reports_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reports_Bool_Exp;
};

/** columns and relationships of "review_files" */
export type Review_Files = {
  __typename?: 'review_files';
  created_at: Scalars['timestamptz']['output'];
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  review_files_file: Files;
  /** An object relationship */
  review_files_review: Reviews;
  review_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "review_files" */
export type Review_Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Review_Files_Max_Order_By>;
  min?: InputMaybe<Review_Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "review_files" */
export type Review_Files_Arr_Rel_Insert_Input = {
  data: Array<Review_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Review_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "review_files". All fields are combined with a logical 'AND'. */
export type Review_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Review_Files_Bool_Exp>>;
  _not?: InputMaybe<Review_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Review_Files_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  review_files_file?: InputMaybe<Files_Bool_Exp>;
  review_files_review?: InputMaybe<Reviews_Bool_Exp>;
  review_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "review_files" */
export enum Review_Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewFilesPkey = 'review_files_pkey',
}

/** input type for inserting data into table "review_files" */
export type Review_Files_Insert_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  review_files_file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  review_files_review?: InputMaybe<Reviews_Obj_Rel_Insert_Input>;
  review_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "review_files" */
export type Review_Files_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "review_files" */
export type Review_Files_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "review_files" */
export type Review_Files_Mutation_Response = {
  __typename?: 'review_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Review_Files>;
};

/** on_conflict condition type for table "review_files" */
export type Review_Files_On_Conflict = {
  constraint: Review_Files_Constraint;
  update_columns?: Array<Review_Files_Update_Column>;
  where?: InputMaybe<Review_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "review_files". */
export type Review_Files_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  review_files_file?: InputMaybe<Files_Order_By>;
  review_files_review?: InputMaybe<Reviews_Order_By>;
  review_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: review_files */
export type Review_Files_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "review_files" */
export enum Review_Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  ReviewId = 'review_id',
}

/** input type for updating data in table "review_files" */
export type Review_Files_Set_Input = {
  file_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "review_files" */
export enum Review_Files_Update_Column {
  /** column name */
  FileId = 'file_id',
}

export type Review_Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Review_Files_Set_Input>;
  /** filter the rows which have to be updated */
  where: Review_Files_Bool_Exp;
};

/** stores review information related to risks and vendors */
export type Reviews = {
  __typename?: 'reviews';
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  review_date: Scalars['timestamptz']['output'];
  reviewed_by?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  reviews_review_files: Array<Review_Files>;
  /** An object relationship */
  reviews_risk?: Maybe<Risks>;
  /** An object relationship */
  reviews_user?: Maybe<Users>;
  /** An object relationship */
  reviews_vendor?: Maybe<Vendors>;
};

/** stores review information related to risks and vendors */
export type ReviewsReviews_Review_FilesArgs = {
  distinct_on?: InputMaybe<Array<Review_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Review_Files_Order_By>>;
  where?: InputMaybe<Review_Files_Bool_Exp>;
};

/** order by aggregate values of table "reviews" */
export type Reviews_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reviews_Max_Order_By>;
  min?: InputMaybe<Reviews_Min_Order_By>;
};

/** input type for inserting array relation for remote table "reviews" */
export type Reviews_Arr_Rel_Insert_Input = {
  data: Array<Reviews_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** Boolean expression to filter rows from the table "reviews". All fields are combined with a logical 'AND'. */
export type Reviews_Bool_Exp = {
  _and?: InputMaybe<Array<Reviews_Bool_Exp>>;
  _not?: InputMaybe<Reviews_Bool_Exp>;
  _or?: InputMaybe<Array<Reviews_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notes?: InputMaybe<String_Comparison_Exp>;
  review_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  reviewed_by?: InputMaybe<Uuid_Comparison_Exp>;
  reviews_review_files?: InputMaybe<Review_Files_Bool_Exp>;
  reviews_risk?: InputMaybe<Risks_Bool_Exp>;
  reviews_user?: InputMaybe<Users_Bool_Exp>;
  reviews_vendor?: InputMaybe<Vendors_Bool_Exp>;
};

/** unique or primary key constraints on table "reviews" */
export enum Reviews_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReviewsPkey = 'reviews_pkey',
}

/** input type for inserting data into table "reviews" */
export type Reviews_Insert_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  review_date?: InputMaybe<Scalars['timestamptz']['input']>;
  reviewed_by?: InputMaybe<Scalars['uuid']['input']>;
  reviews_review_files?: InputMaybe<Review_Files_Arr_Rel_Insert_Input>;
  reviews_risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  reviews_vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "reviews" */
export type Reviews_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  review_date?: InputMaybe<Order_By>;
  reviewed_by?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "reviews" */
export type Reviews_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  review_date?: InputMaybe<Order_By>;
  reviewed_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reviews" */
export type Reviews_Mutation_Response = {
  __typename?: 'reviews_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reviews>;
};

/** input type for inserting object relation for remote table "reviews" */
export type Reviews_Obj_Rel_Insert_Input = {
  data: Reviews_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reviews_On_Conflict>;
};

/** on_conflict condition type for table "reviews" */
export type Reviews_On_Conflict = {
  constraint: Reviews_Constraint;
  update_columns?: Array<Reviews_Update_Column>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** Ordering options when selecting data from "reviews". */
export type Reviews_Order_By = {
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notes?: InputMaybe<Order_By>;
  review_date?: InputMaybe<Order_By>;
  reviewed_by?: InputMaybe<Order_By>;
  reviews_review_files_aggregate?: InputMaybe<Review_Files_Aggregate_Order_By>;
  reviews_risk?: InputMaybe<Risks_Order_By>;
  reviews_user?: InputMaybe<Users_Order_By>;
  reviews_vendor?: InputMaybe<Vendors_Order_By>;
};

/** primary key columns input for table: reviews */
export type Reviews_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "reviews" */
export enum Reviews_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  ReviewDate = 'review_date',
  /** column name */
  ReviewedBy = 'reviewed_by',
}

/** input type for updating data in table "reviews" */
export type Reviews_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  review_date?: InputMaybe<Scalars['timestamptz']['input']>;
  reviewed_by?: InputMaybe<Scalars['uuid']['input']>;
};

/** update columns of table "reviews" */
export enum Reviews_Update_Column {
  /** column name */
  Name = 'name',
  /** column name */
  Notes = 'notes',
  /** column name */
  ReviewDate = 'review_date',
  /** column name */
  ReviewedBy = 'reviewed_by',
}

export type Reviews_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reviews_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reviews_Bool_Exp;
};

/** columns and relationships of "risk_categories" */
export type Risk_Categories = {
  __typename?: 'risk_categories';
  /** An object relationship */
  category: Organization_Risk_Categories;
  category_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  risk: Risks;
  risk_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "risk_categories" */
export type Risk_Categories_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Categories_Max_Order_By>;
  min?: InputMaybe<Risk_Categories_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_categories" */
export type Risk_Categories_Arr_Rel_Insert_Input = {
  data: Array<Risk_Categories_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Categories_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_categories". All fields are combined with a logical 'AND'. */
export type Risk_Categories_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Categories_Bool_Exp>>;
  _not?: InputMaybe<Risk_Categories_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Categories_Bool_Exp>>;
  category?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
  category_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_categories" */
export enum Risk_Categories_Constraint {
  /** unique or primary key constraint on columns "risk_id", "category_id" */
  RiskCategoriesCategoryIdRiskIdKey = 'risk_categories_category_id_risk_id_key',
  /** unique or primary key constraint on columns "id" */
  RiskCategoriesPkey = 'risk_categories_pkey',
}

/** input type for inserting data into table "risk_categories" */
export type Risk_Categories_Insert_Input = {
  category?: InputMaybe<Organization_Risk_Categories_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "risk_categories" */
export type Risk_Categories_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risk_categories" */
export type Risk_Categories_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_categories" */
export type Risk_Categories_Mutation_Response = {
  __typename?: 'risk_categories_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Categories>;
};

/** on_conflict condition type for table "risk_categories" */
export type Risk_Categories_On_Conflict = {
  constraint: Risk_Categories_Constraint;
  update_columns?: Array<Risk_Categories_Update_Column>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_categories". */
export type Risk_Categories_Order_By = {
  category?: InputMaybe<Organization_Risk_Categories_Order_By>;
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** select columns of table "risk_categories" */
export enum Risk_Categories_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
}

/** placeholder for update columns of table "risk_categories" (current role has no relevant permissions) */
export enum Risk_Categories_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** join table for controls associated with risk */
export type Risk_Controls = {
  __typename?: 'risk_controls';
  /** An object relationship */
  control: Controls;
  control_id: Scalars['uuid']['output'];
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  is_control_primary: Scalars['Boolean']['output'];
  /** An object relationship */
  risk: Risks;
  risk_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** order by aggregate values of table "risk_controls" */
export type Risk_Controls_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Controls_Max_Order_By>;
  min?: InputMaybe<Risk_Controls_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_controls" */
export type Risk_Controls_Arr_Rel_Insert_Input = {
  data: Array<Risk_Controls_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Controls_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_controls". All fields are combined with a logical 'AND'. */
export type Risk_Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Controls_Bool_Exp>>;
  _not?: InputMaybe<Risk_Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Controls_Bool_Exp>>;
  control?: InputMaybe<Controls_Bool_Exp>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_control_primary?: InputMaybe<Boolean_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_controls" */
export enum Risk_Controls_Constraint {
  /** unique or primary key constraint on columns "id" */
  RiskControlsPkey = 'risk_controls_pkey',
  /** unique or primary key constraint on columns "control_id", "risk_id" */
  RiskControlsRiskIdControlIdKey = 'risk_controls_risk_id_control_id_key',
}

/** input type for inserting data into table "risk_controls" */
export type Risk_Controls_Insert_Input = {
  control?: InputMaybe<Controls_Obj_Rel_Insert_Input>;
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  is_control_primary?: InputMaybe<Scalars['Boolean']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "risk_controls" */
export type Risk_Controls_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risk_controls" */
export type Risk_Controls_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_controls" */
export type Risk_Controls_Mutation_Response = {
  __typename?: 'risk_controls_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Controls>;
};

/** on_conflict condition type for table "risk_controls" */
export type Risk_Controls_On_Conflict = {
  constraint: Risk_Controls_Constraint;
  update_columns?: Array<Risk_Controls_Update_Column>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_controls". */
export type Risk_Controls_Order_By = {
  control?: InputMaybe<Controls_Order_By>;
  control_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_control_primary?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "risk_controls" */
export enum Risk_Controls_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsControlPrimary = 'is_control_primary',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** placeholder for update columns of table "risk_controls" (current role has no relevant permissions) */
export enum Risk_Controls_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "risk_documents" */
export type Risk_Documents = {
  __typename?: 'risk_documents';
  /** An object relationship */
  file?: Maybe<Files>;
  file_id: Scalars['uuid']['output'];
  /** An object relationship */
  risk?: Maybe<Risks>;
  risk_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "risk_documents" */
export type Risk_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Documents_Max_Order_By>;
  min?: InputMaybe<Risk_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_documents" */
export type Risk_Documents_Arr_Rel_Insert_Input = {
  data: Array<Risk_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_documents". All fields are combined with a logical 'AND'. */
export type Risk_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Documents_Bool_Exp>>;
  _not?: InputMaybe<Risk_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Documents_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_documents" */
export enum Risk_Documents_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  RiskDocumentsFileIdKey = 'risk_documents_file_id_key',
  /** unique or primary key constraint on columns "file_id", "risk_id" */
  RiskDocumentsPkey = 'risk_documents_pkey',
}

/** input type for inserting data into table "risk_documents" */
export type Risk_Documents_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "risk_documents" */
export type Risk_Documents_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risk_documents" */
export type Risk_Documents_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_documents" */
export type Risk_Documents_Mutation_Response = {
  __typename?: 'risk_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Documents>;
};

/** input type for inserting object relation for remote table "risk_documents" */
export type Risk_Documents_Obj_Rel_Insert_Input = {
  data: Risk_Documents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Documents_On_Conflict>;
};

/** on_conflict condition type for table "risk_documents" */
export type Risk_Documents_On_Conflict = {
  constraint: Risk_Documents_Constraint;
  update_columns?: Array<Risk_Documents_Update_Column>;
  where?: InputMaybe<Risk_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_documents". */
export type Risk_Documents_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
};

/** select columns of table "risk_documents" */
export enum Risk_Documents_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  RiskId = 'risk_id',
}

/** placeholder for update columns of table "risk_documents" (current role has no relevant permissions) */
export enum Risk_Documents_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "risk_impacts" */
export type Risk_Impacts = {
  __typename?: 'risk_impacts';
  created_at: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  inherent_risk_impacts: Array<Risks>;
  /** An aggregate relationship */
  inherent_risk_impacts_aggregate: Risks_Aggregate;
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  residual_risk_impacts: Array<Risks>;
  /** An aggregate relationship */
  residual_risk_impacts_aggregate: Risks_Aggregate;
  /** An array relationship */
  risk_inherent_levels: Array<Risk_Inherent_Levels>;
  /** An array relationship */
  risk_residual_levels: Array<Risk_Residual_Levels>;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsInherent_Risk_ImpactsArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsInherent_Risk_Impacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsResidual_Risk_ImpactsArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsResidual_Risk_Impacts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsRisk_Inherent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_impacts" */
export type Risk_ImpactsRisk_Residual_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

/** order by aggregate values of table "risk_impacts" */
export type Risk_Impacts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Impacts_Max_Order_By>;
  min?: InputMaybe<Risk_Impacts_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risk_impacts". All fields are combined with a logical 'AND'. */
export type Risk_Impacts_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Impacts_Bool_Exp>>;
  _not?: InputMaybe<Risk_Impacts_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Impacts_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inherent_risk_impacts?: InputMaybe<Risks_Bool_Exp>;
  inherent_risk_impacts_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  residual_risk_impacts?: InputMaybe<Risks_Bool_Exp>;
  residual_risk_impacts_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  risk_inherent_levels?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
  risk_residual_levels?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_impacts" */
export enum Risk_Impacts_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationRiskImpactsPkey = 'organization_risk_impacts_pkey',
  /** unique or primary key constraint on columns "name", "organization_id" */
  RiskImpactsNameOrganizationIdKey = 'risk_impacts_name_organization_id_key',
  /** unique or primary key constraint on columns "order", "organization_id" */
  RiskImpactsOrganizationIdOrderKey = 'risk_impacts_organization_id_order_key',
}

/** input type for inserting data into table "risk_impacts" */
export type Risk_Impacts_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  inherent_risk_impacts?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_risk_impacts?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "risk_impacts" */
export type Risk_Impacts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risk_impacts" */
export type Risk_Impacts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_impacts" */
export type Risk_Impacts_Mutation_Response = {
  __typename?: 'risk_impacts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Impacts>;
};

/** input type for inserting object relation for remote table "risk_impacts" */
export type Risk_Impacts_Obj_Rel_Insert_Input = {
  data: Risk_Impacts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Impacts_On_Conflict>;
};

/** on_conflict condition type for table "risk_impacts" */
export type Risk_Impacts_On_Conflict = {
  constraint: Risk_Impacts_Constraint;
  update_columns?: Array<Risk_Impacts_Update_Column>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_impacts". */
export type Risk_Impacts_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inherent_risk_impacts_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  residual_risk_impacts_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  risk_inherent_levels_aggregate?: InputMaybe<Risk_Inherent_Levels_Aggregate_Order_By>;
  risk_residual_levels_aggregate?: InputMaybe<Risk_Residual_Levels_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_impacts */
export type Risk_Impacts_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_impacts" */
export enum Risk_Impacts_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "risk_impacts" */
export type Risk_Impacts_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risk_impacts" */
export type Risk_Impacts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Impacts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Impacts_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "risk_impacts" */
export enum Risk_Impacts_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
}

export type Risk_Impacts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Impacts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Impacts_Bool_Exp;
};

/** columns and relationships of "risk_inherent_levels" */
export type Risk_Inherent_Levels = {
  __typename?: 'risk_inherent_levels';
  id: Scalars['uuid']['output'];
  level: Risk_Levels_Enum;
  /** An object relationship */
  risk_impact: Risk_Impacts;
  /** An object relationship */
  risk_likelihood: Risk_Likelihoods;
};

/** order by aggregate values of table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Inherent_Levels_Max_Order_By>;
  min?: InputMaybe<Risk_Inherent_Levels_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risk_inherent_levels". All fields are combined with a logical 'AND'. */
export type Risk_Inherent_Levels_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Inherent_Levels_Bool_Exp>>;
  _not?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Inherent_Levels_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  level?: InputMaybe<Risk_Levels_Enum_Comparison_Exp>;
  risk_impact?: InputMaybe<Risk_Impacts_Bool_Exp>;
  risk_likelihood?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

/** order by max() on columns of table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Max_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Min_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Mutation_Response = {
  __typename?: 'risk_inherent_levels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Inherent_Levels>;
};

/** Ordering options when selecting data from "risk_inherent_levels". */
export type Risk_Inherent_Levels_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  risk_impact?: InputMaybe<Risk_Impacts_Order_By>;
  risk_likelihood?: InputMaybe<Risk_Likelihoods_Order_By>;
};

/** primary key columns input for table: risk_inherent_levels */
export type Risk_Inherent_Levels_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_inherent_levels" */
export enum Risk_Inherent_Levels_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
}

/** input type for updating data in table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Set_Input = {
  level?: InputMaybe<Risk_Levels_Enum>;
};

/** Streaming cursor of the table "risk_inherent_levels" */
export type Risk_Inherent_Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Inherent_Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Inherent_Levels_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<Risk_Levels_Enum>;
};

export type Risk_Inherent_Levels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Inherent_Levels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Inherent_Levels_Bool_Exp;
};

export enum Risk_Levels_Enum {
  High = 'high',
  Low = 'low',
  Medium = 'medium',
  VeryHigh = 'very_high',
  VeryLow = 'very_low',
}

/** Boolean expression to compare columns of type "risk_levels_enum". All fields are combined with logical 'AND'. */
export type Risk_Levels_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Risk_Levels_Enum>;
  _in?: InputMaybe<Array<Risk_Levels_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Risk_Levels_Enum>;
  _nin?: InputMaybe<Array<Risk_Levels_Enum>>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_Likelihoods = {
  __typename?: 'risk_likelihoods';
  created_at: Scalars['timestamptz']['output'];
  description: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  inherent_risk_likelihoods: Array<Risks>;
  /** An aggregate relationship */
  inherent_risk_likelihoods_aggregate: Risks_Aggregate;
  name: Scalars['String']['output'];
  order?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  residual_risk_likelihoods: Array<Risks>;
  /** An aggregate relationship */
  residual_risk_likelihoods_aggregate: Risks_Aggregate;
  /** An array relationship */
  risk_inherent_levels: Array<Risk_Inherent_Levels>;
  /** An array relationship */
  risk_residual_levels: Array<Risk_Residual_Levels>;
  updated_at: Scalars['timestamptz']['output'];
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsInherent_Risk_LikelihoodsArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsInherent_Risk_Likelihoods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsResidual_Risk_LikelihoodsArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsResidual_Risk_Likelihoods_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsRisk_Inherent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

/** columns and relationships of "risk_likelihoods" */
export type Risk_LikelihoodsRisk_Residual_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

/** order by aggregate values of table "risk_likelihoods" */
export type Risk_Likelihoods_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Likelihoods_Max_Order_By>;
  min?: InputMaybe<Risk_Likelihoods_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risk_likelihoods". All fields are combined with a logical 'AND'. */
export type Risk_Likelihoods_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Likelihoods_Bool_Exp>>;
  _not?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Likelihoods_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inherent_risk_likelihoods?: InputMaybe<Risks_Bool_Exp>;
  inherent_risk_likelihoods_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  residual_risk_likelihoods?: InputMaybe<Risks_Bool_Exp>;
  residual_risk_likelihoods_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  risk_inherent_levels?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
  risk_residual_levels?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_likelihoods" */
export enum Risk_Likelihoods_Constraint {
  /** unique or primary key constraint on columns "id" */
  OrganizationRiskLikelihoodsPkey = 'organization_risk_likelihoods_pkey',
  /** unique or primary key constraint on columns "name", "organization_id" */
  RiskLikelihoodsNameOrganizationIdKey = 'risk_likelihoods_name_organization_id_key',
  /** unique or primary key constraint on columns "order", "organization_id" */
  RiskLikelihoodsOrganizationIdOrderKey = 'risk_likelihoods_organization_id_order_key',
}

/** input type for inserting data into table "risk_likelihoods" */
export type Risk_Likelihoods_Insert_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  inherent_risk_likelihoods?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_risk_likelihoods?: InputMaybe<Risks_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "risk_likelihoods" */
export type Risk_Likelihoods_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risk_likelihoods" */
export type Risk_Likelihoods_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_likelihoods" */
export type Risk_Likelihoods_Mutation_Response = {
  __typename?: 'risk_likelihoods_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Likelihoods>;
};

/** input type for inserting object relation for remote table "risk_likelihoods" */
export type Risk_Likelihoods_Obj_Rel_Insert_Input = {
  data: Risk_Likelihoods_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Likelihoods_On_Conflict>;
};

/** on_conflict condition type for table "risk_likelihoods" */
export type Risk_Likelihoods_On_Conflict = {
  constraint: Risk_Likelihoods_Constraint;
  update_columns?: Array<Risk_Likelihoods_Update_Column>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_likelihoods". */
export type Risk_Likelihoods_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inherent_risk_likelihoods_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  order?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  residual_risk_likelihoods_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  risk_inherent_levels_aggregate?: InputMaybe<Risk_Inherent_Levels_Aggregate_Order_By>;
  risk_residual_levels_aggregate?: InputMaybe<Risk_Residual_Levels_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: risk_likelihoods */
export type Risk_Likelihoods_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_likelihoods" */
export enum Risk_Likelihoods_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "risk_likelihoods" */
export type Risk_Likelihoods_Set_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "risk_likelihoods" */
export type Risk_Likelihoods_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Likelihoods_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Likelihoods_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "risk_likelihoods" */
export enum Risk_Likelihoods_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  Order = 'order',
}

export type Risk_Likelihoods_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Likelihoods_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Likelihoods_Bool_Exp;
};

/** columns and relationships of "risk_residual_levels" */
export type Risk_Residual_Levels = {
  __typename?: 'risk_residual_levels';
  id: Scalars['uuid']['output'];
  level: Risk_Levels_Enum;
  /** An object relationship */
  risk_impact: Risk_Impacts;
  /** An object relationship */
  risk_likelihood: Risk_Likelihoods;
};

/** order by aggregate values of table "risk_residual_levels" */
export type Risk_Residual_Levels_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Residual_Levels_Max_Order_By>;
  min?: InputMaybe<Risk_Residual_Levels_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "risk_residual_levels". All fields are combined with a logical 'AND'. */
export type Risk_Residual_Levels_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Residual_Levels_Bool_Exp>>;
  _not?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Residual_Levels_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  level?: InputMaybe<Risk_Levels_Enum_Comparison_Exp>;
  risk_impact?: InputMaybe<Risk_Impacts_Bool_Exp>;
  risk_likelihood?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

/** order by max() on columns of table "risk_residual_levels" */
export type Risk_Residual_Levels_Max_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risk_residual_levels" */
export type Risk_Residual_Levels_Min_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_residual_levels" */
export type Risk_Residual_Levels_Mutation_Response = {
  __typename?: 'risk_residual_levels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Residual_Levels>;
};

/** Ordering options when selecting data from "risk_residual_levels". */
export type Risk_Residual_Levels_Order_By = {
  id?: InputMaybe<Order_By>;
  level?: InputMaybe<Order_By>;
  risk_impact?: InputMaybe<Risk_Impacts_Order_By>;
  risk_likelihood?: InputMaybe<Risk_Likelihoods_Order_By>;
};

/** primary key columns input for table: risk_residual_levels */
export type Risk_Residual_Levels_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risk_residual_levels" */
export enum Risk_Residual_Levels_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
}

/** input type for updating data in table "risk_residual_levels" */
export type Risk_Residual_Levels_Set_Input = {
  level?: InputMaybe<Risk_Levels_Enum>;
};

/** Streaming cursor of the table "risk_residual_levels" */
export type Risk_Residual_Levels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risk_Residual_Levels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risk_Residual_Levels_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['uuid']['input']>;
  level?: InputMaybe<Risk_Levels_Enum>;
};

export type Risk_Residual_Levels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risk_Residual_Levels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risk_Residual_Levels_Bool_Exp;
};

/** columns and relationships of "risk_tags" */
export type Risk_Tags = {
  __typename?: 'risk_tags';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  risk: Risks;
  risk_id: Scalars['uuid']['output'];
  /** An object relationship */
  tag: Organization_Risk_Tags;
  tag_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "risk_tags" */
export type Risk_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Tags_Max_Order_By>;
  min?: InputMaybe<Risk_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_tags" */
export type Risk_Tags_Arr_Rel_Insert_Input = {
  data: Array<Risk_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_tags". All fields are combined with a logical 'AND'. */
export type Risk_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Tags_Bool_Exp>>;
  _not?: InputMaybe<Risk_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Tags_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_tags" */
export enum Risk_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  RiskTagsPkey = 'risk_tags_pkey',
  /** unique or primary key constraint on columns "risk_id", "tag_id" */
  RiskTagsTagIdRiskIdKey = 'risk_tags_tag_id_risk_id_key',
}

/** input type for inserting data into table "risk_tags" */
export type Risk_Tags_Insert_Input = {
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  tag?: InputMaybe<Organization_Risk_Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "risk_tags" */
export type Risk_Tags_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risk_tags" */
export type Risk_Tags_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_tags" */
export type Risk_Tags_Mutation_Response = {
  __typename?: 'risk_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Tags>;
};

/** on_conflict condition type for table "risk_tags" */
export type Risk_Tags_On_Conflict = {
  constraint: Risk_Tags_Constraint;
  update_columns?: Array<Risk_Tags_Update_Column>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_tags". */
export type Risk_Tags_Order_By = {
  id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Organization_Risk_Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** select columns of table "risk_tags" */
export enum Risk_Tags_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TagId = 'tag_id',
}

/** placeholder for update columns of table "risk_tags" (current role has no relevant permissions) */
export enum Risk_Tags_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** Stores list of tasks linked to risks */
export type Risk_Tasks = {
  __typename?: 'risk_tasks';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  risk: Risks;
  risk_id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "risk_tasks" */
export type Risk_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risk_Tasks_Max_Order_By>;
  min?: InputMaybe<Risk_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "risk_tasks" */
export type Risk_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Risk_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risk_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "risk_tasks". All fields are combined with a logical 'AND'. */
export type Risk_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Risk_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Risk_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Risk_Tasks_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "risk_tasks" */
export enum Risk_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  RiskTasksPkey = 'risk_tasks_pkey',
  /** unique or primary key constraint on columns "task_id", "risk_id" */
  RiskTasksRiskIdTaskIdKey = 'risk_tasks_risk_id_task_id_key',
}

/** input type for inserting data into table "risk_tasks" */
export type Risk_Tasks_Insert_Input = {
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "risk_tasks" */
export type Risk_Tasks_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "risk_tasks" */
export type Risk_Tasks_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risk_tasks" */
export type Risk_Tasks_Mutation_Response = {
  __typename?: 'risk_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risk_Tasks>;
};

/** on_conflict condition type for table "risk_tasks" */
export type Risk_Tasks_On_Conflict = {
  constraint: Risk_Tasks_Constraint;
  update_columns?: Array<Risk_Tasks_Update_Column>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "risk_tasks". */
export type Risk_Tasks_Order_By = {
  id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
};

/** select columns of table "risk_tasks" */
export enum Risk_Tasks_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
}

/** placeholder for update columns of table "risk_tasks" (current role has no relevant permissions) */
export enum Risk_Tasks_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** table contains list of risks related to organizations */
export type Risks = {
  __typename?: 'risks';
  assessment_status: Scalars['RiskAssessmentStatus']['output'];
  /** An object relationship */
  assignee?: Maybe<Users>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  categories: Array<Risk_Categories>;
  closed_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  created_at: Scalars['timestamptz']['output'];
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  documents: Array<Risk_Documents>;
  /** An array relationship */
  field_values: Array<Field_Values>;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  inherent_impact?: Maybe<Risk_Impacts>;
  inherent_impact_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  inherent_likelihood?: Maybe<Risk_Likelihoods>;
  inherent_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_risk_last_review_date" */
  last_review_date?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  residual_impact?: Maybe<Risk_Impacts>;
  residual_impact_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  residual_likelihood?: Maybe<Risk_Likelihoods>;
  residual_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  review_frequency: Frequencies_Enum;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An array relationship */
  risk_controls: Array<Risk_Controls>;
  /** An array relationship */
  risks_comments: Array<Comments>;
  /** An array relationship */
  risks_notifications: Array<Notifications>;
  /** An array relationship */
  risks_vector_stores: Array<Ai_Risks_Vector_Store>;
  similarity?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  tags: Array<Risk_Tags>;
  /** An array relationship */
  tasks: Array<Risk_Tasks>;
  treatment_details?: Maybe<Scalars['String']['output']>;
  treatment_plan: Treatment_Plan_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_finding?: Maybe<Vendor_Findings>;
  /** An array relationship */
  vendor_risks: Array<Vendor_Risks>;
};

/** table contains list of risks related to organizations */
export type RisksCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Categories_Order_By>>;
  where?: InputMaybe<Risk_Categories_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Documents_Order_By>>;
  where?: InputMaybe<Risk_Documents_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksRisk_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Controls_Order_By>>;
  where?: InputMaybe<Risk_Controls_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksRisks_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksRisks_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksRisks_Vector_StoresArgs = {
  distinct_on?: InputMaybe<Array<Ai_Risks_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Risks_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksTagsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tags_Order_By>>;
  where?: InputMaybe<Risk_Tags_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksTasksArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tasks_Order_By>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

/** table contains list of risks related to organizations */
export type RisksVendor_RisksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

/** aggregated selection of "risks" */
export type Risks_Aggregate = {
  __typename?: 'risks_aggregate';
  aggregate?: Maybe<Risks_Aggregate_Fields>;
  nodes: Array<Risks>;
};

export type Risks_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Risks_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Risks_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Risks_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Risks_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Risks_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Risks_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Risks_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Risks_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Risks_Aggregate_Bool_Exp_Var_Samp>;
};

export type Risks_Aggregate_Bool_Exp_Avg = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Corr = {
  arguments: Risks_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Risks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Risks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Risks_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Risks_Aggregate_Bool_Exp_Max = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Min = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Sum = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Risks_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Risks_Select_Column_Risks_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Risks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "risks" */
export type Risks_Aggregate_Fields = {
  __typename?: 'risks_aggregate_fields';
  avg?: Maybe<Risks_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Risks_Max_Fields>;
  min?: Maybe<Risks_Min_Fields>;
  stddev?: Maybe<Risks_Stddev_Fields>;
  stddev_pop?: Maybe<Risks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Risks_Stddev_Samp_Fields>;
  sum?: Maybe<Risks_Sum_Fields>;
  var_pop?: Maybe<Risks_Var_Pop_Fields>;
  var_samp?: Maybe<Risks_Var_Samp_Fields>;
  variance?: Maybe<Risks_Variance_Fields>;
};

/** aggregate fields of "risks" */
export type Risks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Risks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "risks" */
export type Risks_Aggregate_Order_By = {
  avg?: InputMaybe<Risks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Risks_Max_Order_By>;
  min?: InputMaybe<Risks_Min_Order_By>;
  stddev?: InputMaybe<Risks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Risks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Risks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Risks_Sum_Order_By>;
  var_pop?: InputMaybe<Risks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Risks_Var_Samp_Order_By>;
  variance?: InputMaybe<Risks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "risks" */
export type Risks_Arr_Rel_Insert_Input = {
  data: Array<Risks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Risks_On_Conflict>;
};

/** aggregate avg on columns */
export type Risks_Avg_Fields = {
  __typename?: 'risks_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "risks" */
export type Risks_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "risks". All fields are combined with a logical 'AND'. */
export type Risks_Bool_Exp = {
  _and?: InputMaybe<Array<Risks_Bool_Exp>>;
  _not?: InputMaybe<Risks_Bool_Exp>;
  _or?: InputMaybe<Array<Risks_Bool_Exp>>;
  assessment_status?: InputMaybe<String_Comparison_Exp>;
  assignee?: InputMaybe<Users_Bool_Exp>;
  assignee_id?: InputMaybe<Uuid_Comparison_Exp>;
  categories?: InputMaybe<Risk_Categories_Bool_Exp>;
  closed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  control_findings?: InputMaybe<Control_Findings_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  documents?: InputMaybe<Risk_Documents_Bool_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  inherent_impact?: InputMaybe<Risk_Impacts_Bool_Exp>;
  inherent_impact_id?: InputMaybe<Uuid_Comparison_Exp>;
  inherent_likelihood?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  inherent_likelihood_id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  last_review_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  residual_impact?: InputMaybe<Risk_Impacts_Bool_Exp>;
  residual_impact_id?: InputMaybe<Uuid_Comparison_Exp>;
  residual_likelihood?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
  residual_likelihood_id?: InputMaybe<Uuid_Comparison_Exp>;
  review_frequency?: InputMaybe<Frequencies_Enum_Comparison_Exp>;
  reviews?: InputMaybe<Reviews_Bool_Exp>;
  risk_controls?: InputMaybe<Risk_Controls_Bool_Exp>;
  risks_comments?: InputMaybe<Comments_Bool_Exp>;
  risks_notifications?: InputMaybe<Notifications_Bool_Exp>;
  risks_vector_stores?: InputMaybe<Ai_Risks_Vector_Store_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  tags?: InputMaybe<Risk_Tags_Bool_Exp>;
  tasks?: InputMaybe<Risk_Tasks_Bool_Exp>;
  treatment_details?: InputMaybe<String_Comparison_Exp>;
  treatment_plan?: InputMaybe<Treatment_Plan_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_finding?: InputMaybe<Vendor_Findings_Bool_Exp>;
  vendor_risks?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

/** unique or primary key constraints on table "risks" */
export enum Risks_Constraint {
  /** unique or primary key constraint on columns "id" */
  RisksPkey = 'risks_pkey',
}

/** input type for inserting data into table "risks" */
export type Risks_Insert_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  categories?: InputMaybe<Risk_Categories_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Risk_Documents_Arr_Rel_Insert_Input>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  inherent_impact?: InputMaybe<Risk_Impacts_Obj_Rel_Insert_Input>;
  inherent_likelihood?: InputMaybe<Risk_Likelihoods_Obj_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_impact?: InputMaybe<Risk_Impacts_Obj_Rel_Insert_Input>;
  residual_likelihood?: InputMaybe<Risk_Likelihoods_Obj_Rel_Insert_Input>;
  reviews?: InputMaybe<Reviews_Arr_Rel_Insert_Input>;
  risk_controls?: InputMaybe<Risk_Controls_Arr_Rel_Insert_Input>;
  risks_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  tags?: InputMaybe<Risk_Tags_Arr_Rel_Insert_Input>;
  tasks?: InputMaybe<Risk_Tasks_Arr_Rel_Insert_Input>;
  treatment_details?: InputMaybe<Scalars['String']['input']>;
  vendor_risks?: InputMaybe<Vendor_Risks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Risks_Max_Fields = {
  __typename?: 'risks_max_fields';
  assessment_status?: Maybe<Scalars['String']['output']>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  closed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  inherent_impact_id?: Maybe<Scalars['uuid']['output']>;
  inherent_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_risk_last_review_date" */
  last_review_date?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  residual_impact_id?: Maybe<Scalars['uuid']['output']>;
  residual_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  treatment_details?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "risks" */
export type Risks_Max_Order_By = {
  assessment_status?: InputMaybe<Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  closed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inherent_impact_id?: InputMaybe<Order_By>;
  inherent_likelihood_id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  residual_impact_id?: InputMaybe<Order_By>;
  residual_likelihood_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  treatment_details?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Risks_Min_Fields = {
  __typename?: 'risks_min_fields';
  assessment_status?: Maybe<Scalars['String']['output']>;
  assignee_id?: Maybe<Scalars['uuid']['output']>;
  closed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  inherent_impact_id?: Maybe<Scalars['uuid']['output']>;
  inherent_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_risk_last_review_date" */
  last_review_date?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  residual_impact_id?: Maybe<Scalars['uuid']['output']>;
  residual_likelihood_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  treatment_details?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "risks" */
export type Risks_Min_Order_By = {
  assessment_status?: InputMaybe<Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  closed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  inherent_impact_id?: InputMaybe<Order_By>;
  inherent_likelihood_id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  residual_impact_id?: InputMaybe<Order_By>;
  residual_likelihood_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  treatment_details?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "risks" */
export type Risks_Mutation_Response = {
  __typename?: 'risks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Risks>;
};

/** input type for inserting object relation for remote table "risks" */
export type Risks_Obj_Rel_Insert_Input = {
  data: Risks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Risks_On_Conflict>;
};

/** on_conflict condition type for table "risks" */
export type Risks_On_Conflict = {
  constraint: Risks_Constraint;
  update_columns?: Array<Risks_Update_Column>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** Ordering options when selecting data from "risks". */
export type Risks_Order_By = {
  assessment_status?: InputMaybe<Order_By>;
  assignee?: InputMaybe<Users_Order_By>;
  assignee_id?: InputMaybe<Order_By>;
  categories_aggregate?: InputMaybe<Risk_Categories_Aggregate_Order_By>;
  closed_at?: InputMaybe<Order_By>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Risk_Documents_Aggregate_Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  inherent_impact?: InputMaybe<Risk_Impacts_Order_By>;
  inherent_impact_id?: InputMaybe<Order_By>;
  inherent_likelihood?: InputMaybe<Risk_Likelihoods_Order_By>;
  inherent_likelihood_id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  last_review_date?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  residual_impact?: InputMaybe<Risk_Impacts_Order_By>;
  residual_impact_id?: InputMaybe<Order_By>;
  residual_likelihood?: InputMaybe<Risk_Likelihoods_Order_By>;
  residual_likelihood_id?: InputMaybe<Order_By>;
  review_frequency?: InputMaybe<Order_By>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  risk_controls_aggregate?: InputMaybe<Risk_Controls_Aggregate_Order_By>;
  risks_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  risks_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  risks_vector_stores_aggregate?: InputMaybe<Ai_Risks_Vector_Store_Aggregate_Order_By>;
  similarity?: InputMaybe<Order_By>;
  tags_aggregate?: InputMaybe<Risk_Tags_Aggregate_Order_By>;
  tasks_aggregate?: InputMaybe<Risk_Tasks_Aggregate_Order_By>;
  treatment_details?: InputMaybe<Order_By>;
  treatment_plan?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_finding?: InputMaybe<Vendor_Findings_Order_By>;
  vendor_risks_aggregate?: InputMaybe<Vendor_Risks_Aggregate_Order_By>;
};

/** primary key columns input for table: risks */
export type Risks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "risks" */
export enum Risks_Select_Column {
  /** column name */
  AssessmentStatus = 'assessment_status',
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InherentImpactId = 'inherent_impact_id',
  /** column name */
  InherentLikelihoodId = 'inherent_likelihood_id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ResidualImpactId = 'residual_impact_id',
  /** column name */
  ResidualLikelihoodId = 'residual_likelihood_id',
  /** column name */
  ReviewFrequency = 'review_frequency',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  TreatmentDetails = 'treatment_details',
  /** column name */
  TreatmentPlan = 'treatment_plan',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "risks_aggregate_bool_exp_avg_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_corr_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_max_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_min_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_sum_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "risks_aggregate_bool_exp_var_samp_arguments_columns" columns of table "risks" */
export enum Risks_Select_Column_Risks_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "risks" */
export type Risks_Set_Input = {
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  inherent_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  review_frequency?: InputMaybe<Frequencies_Enum>;
  treatment_details?: InputMaybe<Scalars['String']['input']>;
  treatment_plan?: InputMaybe<Treatment_Plan_Enum>;
};

/** aggregate stddev on columns */
export type Risks_Stddev_Fields = {
  __typename?: 'risks_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "risks" */
export type Risks_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Risks_Stddev_Pop_Fields = {
  __typename?: 'risks_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "risks" */
export type Risks_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Risks_Stddev_Samp_Fields = {
  __typename?: 'risks_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "risks" */
export type Risks_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "risks" */
export type Risks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Risks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Risks_Stream_Cursor_Value_Input = {
  assessment_status?: InputMaybe<Scalars['String']['input']>;
  assignee_id?: InputMaybe<Scalars['uuid']['input']>;
  closed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  inherent_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_impact_id?: InputMaybe<Scalars['uuid']['input']>;
  residual_likelihood_id?: InputMaybe<Scalars['uuid']['input']>;
  review_frequency?: InputMaybe<Frequencies_Enum>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  treatment_details?: InputMaybe<Scalars['String']['input']>;
  treatment_plan?: InputMaybe<Treatment_Plan_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Risks_Sum_Fields = {
  __typename?: 'risks_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "risks" */
export type Risks_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "risks" */
export enum Risks_Update_Column {
  /** column name */
  AssigneeId = 'assignee_id',
  /** column name */
  Description = 'description',
  /** column name */
  InherentImpactId = 'inherent_impact_id',
  /** column name */
  InherentLikelihoodId = 'inherent_likelihood_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  ResidualImpactId = 'residual_impact_id',
  /** column name */
  ResidualLikelihoodId = 'residual_likelihood_id',
  /** column name */
  ReviewFrequency = 'review_frequency',
  /** column name */
  TreatmentDetails = 'treatment_details',
  /** column name */
  TreatmentPlan = 'treatment_plan',
}

export type Risks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Risks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Risks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Risks_Var_Pop_Fields = {
  __typename?: 'risks_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "risks" */
export type Risks_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Risks_Var_Samp_Fields = {
  __typename?: 'risks_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "risks" */
export type Risks_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Risks_Variance_Fields = {
  __typename?: 'risks_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "risks" */
export type Risks_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** set of permissions which make a role */
export type Role_Permissions = {
  __typename?: 'role_permissions';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  permission: Permissions_Enum;
  /** An object relationship */
  role: Roles;
  role_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "role_permissions" */
export type Role_Permissions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Permissions_Max_Order_By>;
  min?: InputMaybe<Role_Permissions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "role_permissions" */
export type Role_Permissions_Arr_Rel_Insert_Input = {
  data: Array<Role_Permissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Permissions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "role_permissions". All fields are combined with a logical 'AND'. */
export type Role_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Role_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Permissions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  permission?: InputMaybe<Permissions_Enum_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_permissions" */
export enum Role_Permissions_Constraint {
  /** unique or primary key constraint on columns "id" */
  RolePermissionsPkey = 'role_permissions_pkey',
  /** unique or primary key constraint on columns "role_id", "permission" */
  RolePermissionsRoleIdPermissionKey = 'role_permissions_role_id_permission_key',
}

/** input type for inserting data into table "role_permissions" */
export type Role_Permissions_Insert_Input = {
  permission?: InputMaybe<Permissions_Enum>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "role_permissions" */
export type Role_Permissions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "role_permissions" */
export type Role_Permissions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_permissions" */
export type Role_Permissions_Mutation_Response = {
  __typename?: 'role_permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Permissions>;
};

/** on_conflict condition type for table "role_permissions" */
export type Role_Permissions_On_Conflict = {
  constraint: Role_Permissions_Constraint;
  update_columns?: Array<Role_Permissions_Update_Column>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "role_permissions". */
export type Role_Permissions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** select columns of table "role_permissions" */
export enum Role_Permissions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Permission = 'permission',
  /** column name */
  RoleId = 'role_id',
}

/** Streaming cursor of the table "role_permissions" */
export type Role_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Permissions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  permission?: InputMaybe<Permissions_Enum>;
  role_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "role_permissions" (current role has no relevant permissions) */
export enum Role_Permissions_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** different roles managed by organization  */
export type Roles = {
  __typename?: 'roles';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An array relationship */
  invitations: Array<Invitations>;
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An array relationship */
  role_permissions: Array<Role_Permissions>;
  system_role?: Maybe<System_Roles_Enum>;
  updated_at: Scalars['timestamptz']['output'];
};

/** different roles managed by organization  */
export type RolesInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** different roles managed by organization  */
export type RolesOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

/** different roles managed by organization  */
export type RolesRole_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

/** order by aggregate values of table "roles" */
export type Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Roles_Max_Order_By>;
  min?: InputMaybe<Roles_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitations?: InputMaybe<Invitations_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  organization_users?: InputMaybe<Organization_Users_Bool_Exp>;
  role_permissions?: InputMaybe<Role_Permissions_Bool_Exp>;
  system_role?: InputMaybe<System_Roles_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "system_role", "organization_id" */
  RolesOrganizationIdSystemRoleKey = 'roles_organization_id_system_role_key',
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'roles_pkey',
}

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  invitations?: InputMaybe<Invitations_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  role_permissions?: InputMaybe<Role_Permissions_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "roles" */
export type Roles_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "roles" */
export type Roles_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<Invitations_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  organization_users_aggregate?: InputMaybe<Organization_Users_Aggregate_Order_By>;
  role_permissions_aggregate?: InputMaybe<Role_Permissions_Aggregate_Order_By>;
  system_role?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  SystemRole = 'system_role',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  system_role?: InputMaybe<System_Roles_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  Name = 'name',
}

export type Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

/** columns and relationships of "select_field_configs" */
export type Select_Field_Configs = {
  __typename?: 'select_field_configs';
  /** An object relationship */
  fieldConfigByFieldConfigId: Field_Configs;
  /** An object relationship */
  field_config?: Maybe<Field_Configs>;
  field_config_id: Scalars['uuid']['output'];
  field_type: Scalars['String']['output'];
  is_creatable: Scalars['Boolean']['output'];
  is_multi: Scalars['Boolean']['output'];
  /** An array relationship */
  select_field_options: Array<Select_Field_Options>;
};

/** columns and relationships of "select_field_configs" */
export type Select_Field_ConfigsSelect_Field_OptionsArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Options_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Options_Order_By>>;
  where?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

/** order by aggregate values of table "select_field_configs" */
export type Select_Field_Configs_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Select_Field_Configs_Max_Order_By>;
  min?: InputMaybe<Select_Field_Configs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "select_field_configs" */
export type Select_Field_Configs_Arr_Rel_Insert_Input = {
  data: Array<Select_Field_Configs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Select_Field_Configs_On_Conflict>;
};

/** Boolean expression to filter rows from the table "select_field_configs". All fields are combined with a logical 'AND'. */
export type Select_Field_Configs_Bool_Exp = {
  _and?: InputMaybe<Array<Select_Field_Configs_Bool_Exp>>;
  _not?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  _or?: InputMaybe<Array<Select_Field_Configs_Bool_Exp>>;
  fieldConfigByFieldConfigId?: InputMaybe<Field_Configs_Bool_Exp>;
  field_config?: InputMaybe<Field_Configs_Bool_Exp>;
  field_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  is_creatable?: InputMaybe<Boolean_Comparison_Exp>;
  is_multi?: InputMaybe<Boolean_Comparison_Exp>;
  select_field_options?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

/** unique or primary key constraints on table "select_field_configs" */
export enum Select_Field_Configs_Constraint {
  /** unique or primary key constraint on columns "field_config_id" */
  SelectFieldConfigsPkey = 'select_field_configs_pkey',
}

/** input type for inserting data into table "select_field_configs" */
export type Select_Field_Configs_Insert_Input = {
  fieldConfigByFieldConfigId?: InputMaybe<Field_Configs_Obj_Rel_Insert_Input>;
  field_config?: InputMaybe<Field_Configs_Obj_Rel_Insert_Input>;
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  is_creatable?: InputMaybe<Scalars['Boolean']['input']>;
  is_multi?: InputMaybe<Scalars['Boolean']['input']>;
  select_field_options?: InputMaybe<Select_Field_Options_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "select_field_configs" */
export type Select_Field_Configs_Max_Order_By = {
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "select_field_configs" */
export type Select_Field_Configs_Min_Order_By = {
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "select_field_configs" */
export type Select_Field_Configs_Mutation_Response = {
  __typename?: 'select_field_configs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Select_Field_Configs>;
};

/** input type for inserting object relation for remote table "select_field_configs" */
export type Select_Field_Configs_Obj_Rel_Insert_Input = {
  data: Select_Field_Configs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Select_Field_Configs_On_Conflict>;
};

/** on_conflict condition type for table "select_field_configs" */
export type Select_Field_Configs_On_Conflict = {
  constraint: Select_Field_Configs_Constraint;
  update_columns?: Array<Select_Field_Configs_Update_Column>;
  where?: InputMaybe<Select_Field_Configs_Bool_Exp>;
};

/** Ordering options when selecting data from "select_field_configs". */
export type Select_Field_Configs_Order_By = {
  fieldConfigByFieldConfigId?: InputMaybe<Field_Configs_Order_By>;
  field_config?: InputMaybe<Field_Configs_Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  is_creatable?: InputMaybe<Order_By>;
  is_multi?: InputMaybe<Order_By>;
  select_field_options_aggregate?: InputMaybe<Select_Field_Options_Aggregate_Order_By>;
};

/** primary key columns input for table: select_field_configs */
export type Select_Field_Configs_Pk_Columns_Input = {
  field_config_id: Scalars['uuid']['input'];
};

/** select columns of table "select_field_configs" */
export enum Select_Field_Configs_Select_Column {
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  IsCreatable = 'is_creatable',
  /** column name */
  IsMulti = 'is_multi',
}

/** input type for updating data in table "select_field_configs" */
export type Select_Field_Configs_Set_Input = {
  is_creatable?: InputMaybe<Scalars['Boolean']['input']>;
  is_multi?: InputMaybe<Scalars['Boolean']['input']>;
};

/** update columns of table "select_field_configs" */
export enum Select_Field_Configs_Update_Column {
  /** column name */
  IsCreatable = 'is_creatable',
  /** column name */
  IsMulti = 'is_multi',
}

export type Select_Field_Configs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Select_Field_Configs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Select_Field_Configs_Bool_Exp;
};

/** columns and relationships of "select_field_options" */
export type Select_Field_Options = {
  __typename?: 'select_field_options';
  field_config_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  select_field_config: Select_Field_Configs;
  /** An array relationship */
  select_field_values: Array<Select_Field_Values>;
  value: Scalars['String']['output'];
};

/** columns and relationships of "select_field_options" */
export type Select_Field_OptionsSelect_Field_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Select_Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Select_Field_Values_Order_By>>;
  where?: InputMaybe<Select_Field_Values_Bool_Exp>;
};

/** order by aggregate values of table "select_field_options" */
export type Select_Field_Options_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Select_Field_Options_Max_Order_By>;
  min?: InputMaybe<Select_Field_Options_Min_Order_By>;
};

/** input type for inserting array relation for remote table "select_field_options" */
export type Select_Field_Options_Arr_Rel_Insert_Input = {
  data: Array<Select_Field_Options_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Select_Field_Options_On_Conflict>;
};

/** Boolean expression to filter rows from the table "select_field_options". All fields are combined with a logical 'AND'. */
export type Select_Field_Options_Bool_Exp = {
  _and?: InputMaybe<Array<Select_Field_Options_Bool_Exp>>;
  _not?: InputMaybe<Select_Field_Options_Bool_Exp>;
  _or?: InputMaybe<Array<Select_Field_Options_Bool_Exp>>;
  field_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  select_field_config?: InputMaybe<Select_Field_Configs_Bool_Exp>;
  select_field_values?: InputMaybe<Select_Field_Values_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "select_field_options" */
export enum Select_Field_Options_Constraint {
  /** unique or primary key constraint on columns "field_config_id", "id" */
  SelectFieldOptionsFieldConfigIdIdKey = 'select_field_options_field_config_id_id_key',
  /** unique or primary key constraint on columns "id" */
  SelectFieldOptionsPkey = 'select_field_options_pkey',
  /** unique or primary key constraint on columns "field_config_id", "value" */
  SelectFieldOptionsValueFieldConfigIdKey = 'select_field_options_value_field_config_id_key',
}

/** input type for inserting data into table "select_field_options" */
export type Select_Field_Options_Insert_Input = {
  field_config_id?: InputMaybe<Scalars['uuid']['input']>;
  select_field_config?: InputMaybe<Select_Field_Configs_Obj_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "select_field_options" */
export type Select_Field_Options_Max_Order_By = {
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "select_field_options" */
export type Select_Field_Options_Min_Order_By = {
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "select_field_options" */
export type Select_Field_Options_Mutation_Response = {
  __typename?: 'select_field_options_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Select_Field_Options>;
};

/** on_conflict condition type for table "select_field_options" */
export type Select_Field_Options_On_Conflict = {
  constraint: Select_Field_Options_Constraint;
  update_columns?: Array<Select_Field_Options_Update_Column>;
  where?: InputMaybe<Select_Field_Options_Bool_Exp>;
};

/** Ordering options when selecting data from "select_field_options". */
export type Select_Field_Options_Order_By = {
  field_config_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  select_field_config?: InputMaybe<Select_Field_Configs_Order_By>;
  select_field_values_aggregate?: InputMaybe<Select_Field_Values_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** select columns of table "select_field_options" */
export enum Select_Field_Options_Select_Column {
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value',
}

/** placeholder for update columns of table "select_field_options" (current role has no relevant permissions) */
export enum Select_Field_Options_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "select_field_values" */
export type Select_Field_Values = {
  __typename?: 'select_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_config_id: Scalars['uuid']['output'];
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  /** An object relationship */
  select_field_option?: Maybe<Select_Field_Options>;
  select_field_option_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** order by aggregate values of table "select_field_values" */
export type Select_Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Select_Field_Values_Max_Order_By>;
  min?: InputMaybe<Select_Field_Values_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "select_field_values". All fields are combined with a logical 'AND'. */
export type Select_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Select_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Select_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Select_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_config_id?: InputMaybe<Uuid_Comparison_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  select_field_option?: InputMaybe<Select_Field_Options_Bool_Exp>;
  select_field_option_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** order by max() on columns of table "select_field_values" */
export type Select_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  select_field_option_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "select_field_values" */
export type Select_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  select_field_option_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "select_field_values". */
export type Select_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_config_id?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  select_field_option?: InputMaybe<Select_Field_Options_Order_By>;
  select_field_option_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "select_field_values" */
export enum Select_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldConfigId = 'field_config_id',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  SelectFieldOptionId = 'select_field_option_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** Boolean expression to compare columns of type "sparsevec". All fields are combined with logical 'AND'. */
export type Sparsevec_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['sparsevec']['input']>;
  _gt?: InputMaybe<Scalars['sparsevec']['input']>;
  _gte?: InputMaybe<Scalars['sparsevec']['input']>;
  _in?: InputMaybe<Array<Scalars['sparsevec']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['sparsevec']['input']>;
  _lte?: InputMaybe<Scalars['sparsevec']['input']>;
  _neq?: InputMaybe<Scalars['sparsevec']['input']>;
  _nin?: InputMaybe<Array<Scalars['sparsevec']['input']>>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** execute function "ai.control_evidences_suggestions" which returns "evidences" */
  ai_control_evidences_suggestions: Array<Evidences>;
  /** execute function "ai.control_evidences_suggestions" and query aggregates on result of table type "evidences" */
  ai_control_evidences_suggestions_aggregate: Evidences_Aggregate;
  /** execute function "ai.controls_text_search" which returns "controls" */
  ai_controls_text_search: Array<Controls>;
  /** execute function "ai.controls_text_search" and query aggregates on result of table type "controls" */
  ai_controls_text_search_aggregate: Controls_Aggregate;
  /** execute function "ai.controls_vector_store_search" which returns "controls" */
  ai_controls_vector_store_search: Array<Controls>;
  /** execute function "ai.controls_vector_store_search" and query aggregates on result of table type "controls" */
  ai_controls_vector_store_search_aggregate: Controls_Aggregate;
  /** execute function "ai.documents_text_search" which returns "documents" */
  ai_documents_text_search: Array<Documents>;
  /** fetch data from the table: "ai.documents_vector_store" */
  ai_documents_vector_store: Array<Ai_Documents_Vector_Store>;
  /** fetch data from the table: "ai.documents_vector_store" using primary key columns */
  ai_documents_vector_store_by_pk?: Maybe<Ai_Documents_Vector_Store>;
  /** execute function "ai.documents_vector_store_search" which returns "ai.documents_vector_store" */
  ai_documents_vector_store_search: Array<Ai_Documents_Vector_Store>;
  /** fetch data from the table in a streaming manner: "ai.documents_vector_store" */
  ai_documents_vector_store_stream: Array<Ai_Documents_Vector_Store>;
  /** execute function "ai.evidences_text_search" which returns "evidences" */
  ai_evidences_text_search: Array<Evidences>;
  /** execute function "ai.evidences_text_search" and query aggregates on result of table type "evidences" */
  ai_evidences_text_search_aggregate: Evidences_Aggregate;
  /** execute function "ai.evidences_vector_store_search" which returns "evidences" */
  ai_evidences_vector_store_search: Array<Evidences>;
  /** execute function "ai.evidences_vector_store_search" and query aggregates on result of table type "evidences" */
  ai_evidences_vector_store_search_aggregate: Evidences_Aggregate;
  /** execute function "ai.policies_text_search" which returns "policies" */
  ai_policies_text_search: Array<Policies>;
  /** fetch data from the table: "ai.policies_vector_store" */
  ai_policies_vector_store: Array<Ai_Policies_Vector_Store>;
  /** fetch data from the table: "ai.policies_vector_store" using primary key columns */
  ai_policies_vector_store_by_pk?: Maybe<Ai_Policies_Vector_Store>;
  /** execute function "ai.policies_vector_store_search" which returns "ai.policies_vector_store" */
  ai_policies_vector_store_search: Array<Ai_Policies_Vector_Store>;
  /** fetch data from the table in a streaming manner: "ai.policies_vector_store" */
  ai_policies_vector_store_stream: Array<Ai_Policies_Vector_Store>;
  /** execute function "ai.programs_text_search" which returns "programs" */
  ai_programs_text_search: Array<Programs>;
  /** execute function "ai.programs_vector_store_search" which returns "programs" */
  ai_programs_vector_store_search: Array<Programs>;
  /** execute function "ai.risk_controls_suggestions" which returns "controls" */
  ai_risk_controls_suggestions: Array<Controls>;
  /** execute function "ai.risk_controls_suggestions" and query aggregates on result of table type "controls" */
  ai_risk_controls_suggestions_aggregate: Controls_Aggregate;
  /** execute function "ai.risks_text_search" which returns "risks" */
  ai_risks_text_search: Array<Risks>;
  /** execute function "ai.risks_text_search" and query aggregates on result of table type "risks" */
  ai_risks_text_search_aggregate: Risks_Aggregate;
  /** execute function "ai.risks_vector_store_search" which returns "risks" */
  ai_risks_vector_store_search: Array<Risks>;
  /** execute function "ai.risks_vector_store_search" and query aggregates on result of table type "risks" */
  ai_risks_vector_store_search_aggregate: Risks_Aggregate;
  /** execute function "ai.tasks_text_search" which returns "tasks" */
  ai_tasks_text_search: Array<Tasks>;
  /** execute function "ai.tasks_text_search" and query aggregates on result of table type "tasks" */
  ai_tasks_text_search_aggregate: Tasks_Aggregate;
  /** execute function "ai.tasks_vector_store_search" which returns "tasks" */
  ai_tasks_vector_store_search: Array<Tasks>;
  /** execute function "ai.tasks_vector_store_search" and query aggregates on result of table type "tasks" */
  ai_tasks_vector_store_search_aggregate: Tasks_Aggregate;
  /** execute function "ai.vendors_text_search" which returns "vendors" */
  ai_vendors_text_search: Array<Vendors>;
  /** execute function "ai.vendors_vector_store_search" which returns "vendors" */
  ai_vendors_vector_store_search: Array<Vendors>;
  /** fetch data from the table: "audit.control_history_view" */
  audit_control_history_view: Array<Audit_Control_History_View>;
  /** fetch data from the table in a streaming manner: "audit.control_history_view" */
  audit_control_history_view_stream: Array<Audit_Control_History_View>;
  /** fetch data from the table: "audit.policy_history_view" */
  audit_policy_history_view: Array<Audit_Policy_History_View>;
  /** fetch data from the table in a streaming manner: "audit.policy_history_view" */
  audit_policy_history_view_stream: Array<Audit_Policy_History_View>;
  /** fetch data from the table: "audit.risk_history_view" */
  audit_risk_history_view: Array<Audit_Risk_History_View>;
  /** fetch data from the table in a streaming manner: "audit.risk_history_view" */
  audit_risk_history_view_stream: Array<Audit_Risk_History_View>;
  /** fetch data from the table: "audit.vendor_history_view" */
  audit_vendor_history_view: Array<Audit_Vendor_History_View>;
  /** fetch data from the table in a streaming manner: "audit.vendor_history_view" */
  audit_vendor_history_view_stream: Array<Audit_Vendor_History_View>;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  authRefreshToken?: Maybe<AuthRefreshTokens>;
  /** fetch data from the table: "auth.refresh_tokens" */
  authRefreshTokens: Array<AuthRefreshTokens>;
  /** fetch data from the table in a streaming manner: "auth.refresh_tokens" */
  authRefreshTokens_stream: Array<AuthRefreshTokens>;
  /** An array relationship */
  categories: Array<Categories>;
  /** fetch data from the table: "categories" using primary key columns */
  categories_by_pk?: Maybe<Categories>;
  /** fetch data from the table in a streaming manner: "categories" */
  categories_stream: Array<Categories>;
  /** An array relationship */
  client_questionnaire_questions: Array<Client_Questionnaire_Questions>;
  /** An aggregate relationship */
  client_questionnaire_questions_aggregate: Client_Questionnaire_Questions_Aggregate;
  /** fetch data from the table: "client_questionnaire_questions" using primary key columns */
  client_questionnaire_questions_by_pk?: Maybe<Client_Questionnaire_Questions>;
  /** fetch data from the table in a streaming manner: "client_questionnaire_questions" */
  client_questionnaire_questions_stream: Array<Client_Questionnaire_Questions>;
  /** An array relationship */
  client_questionnaires: Array<Client_Questionnaires>;
  /** fetch data from the table: "client_questionnaires" using primary key columns */
  client_questionnaires_by_pk?: Maybe<Client_Questionnaires>;
  /** fetch data from the table in a streaming manner: "client_questionnaires" */
  client_questionnaires_stream: Array<Client_Questionnaires>;
  /** An array relationship */
  controls: Array<Controls>;
  /** An aggregate relationship */
  controls_aggregate: Controls_Aggregate;
  /** fetch data from the table: "controls" using primary key columns */
  controls_by_pk?: Maybe<Controls>;
  /** fetch data from the table in a streaming manner: "controls" */
  controls_stream: Array<Controls>;
  /** An array relationship */
  criteria: Array<Criteria>;
  /** fetch data from the table: "criteria" using primary key columns */
  criteria_by_pk?: Maybe<Criteria>;
  /** fetch data from the table in a streaming manner: "criteria" */
  criteria_stream: Array<Criteria>;
  /** fetch data from the table: "daily_control_stats_view" */
  daily_control_stats_view: Array<Daily_Control_Stats_View>;
  /** fetch data from the table in a streaming manner: "daily_control_stats_view" */
  daily_control_stats_view_stream: Array<Daily_Control_Stats_View>;
  /** fetch data from the table: "daily_control_user_stats_view" */
  daily_control_user_stats_view: Array<Daily_Control_User_Stats_View>;
  /** fetch data from the table in a streaming manner: "daily_control_user_stats_view" */
  daily_control_user_stats_view_stream: Array<Daily_Control_User_Stats_View>;
  /** An array relationship */
  documents: Array<Documents>;
  /** fetch data from the table: "documents" using primary key columns */
  documents_by_pk?: Maybe<Documents>;
  /** fetch data from the table in a streaming manner: "documents" */
  documents_stream: Array<Documents>;
  /** An array relationship */
  evidence_integrations: Array<Evidence_Integrations>;
  /** fetch data from the table: "evidence_integrations" using primary key columns */
  evidence_integrations_by_pk?: Maybe<Evidence_Integrations>;
  /** fetch data from the table in a streaming manner: "evidence_integrations" */
  evidence_integrations_stream: Array<Evidence_Integrations>;
  /** An array relationship */
  evidence_versions: Array<Evidence_Versions>;
  /** fetch data from the table: "evidence_versions" using primary key columns */
  evidence_versions_by_pk?: Maybe<Evidence_Versions>;
  /** fetch data from the table in a streaming manner: "evidence_versions" */
  evidence_versions_stream: Array<Evidence_Versions>;
  /** An array relationship */
  evidences: Array<Evidences>;
  /** An aggregate relationship */
  evidences_aggregate: Evidences_Aggregate;
  /** fetch data from the table: "evidences" using primary key columns */
  evidences_by_pk?: Maybe<Evidences>;
  /** fetch data from the table in a streaming manner: "evidences" */
  evidences_stream: Array<Evidences>;
  /** An array relationship */
  field_configs: Array<Field_Configs>;
  /** fetch data from the table: "field_configs" using primary key columns */
  field_configs_by_pk?: Maybe<Field_Configs>;
  /** fetch data from the table in a streaming manner: "field_configs" */
  field_configs_stream: Array<Field_Configs>;
  /** An array relationship */
  frameworks: Array<Frameworks>;
  /** fetch data from the table: "frameworks" using primary key columns */
  frameworks_by_pk?: Maybe<Frameworks>;
  /** An array relationship */
  frameworks_controls: Array<Frameworks_Controls>;
  /** fetch data from the table: "frameworks_controls" using primary key columns */
  frameworks_controls_by_pk?: Maybe<Frameworks_Controls>;
  /** fetch data from the table in a streaming manner: "frameworks_controls" */
  frameworks_controls_stream: Array<Frameworks_Controls>;
  /** fetch data from the table in a streaming manner: "frameworks" */
  frameworks_stream: Array<Frameworks>;
  /** An array relationship */
  groups: Array<Groups>;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table in a streaming manner: "groups" */
  groups_stream: Array<Groups>;
  /** fetch data from the table: "integrations" */
  integrations: Array<Integrations>;
  /** fetch data from the table: "integrations" using primary key columns */
  integrations_by_pk?: Maybe<Integrations>;
  /** fetch data from the table in a streaming manner: "integrations" */
  integrations_stream: Array<Integrations>;
  /** An array relationship */
  invitations: Array<Invitations>;
  /** fetch data from the table: "invitations" using primary key columns */
  invitations_by_pk?: Maybe<Invitations>;
  /** fetch data from the table in a streaming manner: "invitations" */
  invitations_stream: Array<Invitations>;
  /** An array relationship */
  notification_disabled_settings: Array<Notification_Disabled_Settings>;
  /** fetch data from the table: "notification_disabled_settings" using primary key columns */
  notification_disabled_settings_by_pk?: Maybe<Notification_Disabled_Settings>;
  /** fetch data from the table in a streaming manner: "notification_disabled_settings" */
  notification_disabled_settings_stream: Array<Notification_Disabled_Settings>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** fetch data from the table: "organization_evidence_tags" */
  organization_evidence_tags: Array<Organization_Evidence_Tags>;
  /** fetch data from the table: "organization_evidence_tags" using primary key columns */
  organization_evidence_tags_by_pk?: Maybe<Organization_Evidence_Tags>;
  /** fetch data from the table in a streaming manner: "organization_evidence_tags" */
  organization_evidence_tags_stream: Array<Organization_Evidence_Tags>;
  /** An array relationship */
  organization_integrations: Array<Organization_Integrations>;
  /** fetch data from the table: "organization_integrations" using primary key columns */
  organization_integrations_by_pk?: Maybe<Organization_Integrations>;
  /** fetch data from the table in a streaming manner: "organization_integrations" */
  organization_integrations_stream: Array<Organization_Integrations>;
  /** fetch data from the table: "organization_risk_categories" */
  organization_risk_categories: Array<Organization_Risk_Categories>;
  /** fetch data from the table: "organization_risk_categories" using primary key columns */
  organization_risk_categories_by_pk?: Maybe<Organization_Risk_Categories>;
  /** fetch data from the table in a streaming manner: "organization_risk_categories" */
  organization_risk_categories_stream: Array<Organization_Risk_Categories>;
  /** fetch data from the table: "organization_risk_tags" */
  organization_risk_tags: Array<Organization_Risk_Tags>;
  /** fetch data from the table: "organization_risk_tags" using primary key columns */
  organization_risk_tags_by_pk?: Maybe<Organization_Risk_Tags>;
  /** fetch data from the table in a streaming manner: "organization_risk_tags" */
  organization_risk_tags_stream: Array<Organization_Risk_Tags>;
  /** An array relationship */
  organization_template_frameworks: Array<Organization_Template_Frameworks>;
  /** fetch data from the table: "organization_template_frameworks" using primary key columns */
  organization_template_frameworks_by_pk?: Maybe<Organization_Template_Frameworks>;
  /** fetch data from the table in a streaming manner: "organization_template_frameworks" */
  organization_template_frameworks_stream: Array<Organization_Template_Frameworks>;
  /** An array relationship */
  organization_tenants: Array<Organization_Tenants>;
  /** fetch data from the table: "organization_tenants" using primary key columns */
  organization_tenants_by_pk?: Maybe<Organization_Tenants>;
  /** fetch data from the table in a streaming manner: "organization_tenants" */
  organization_tenants_stream: Array<Organization_Tenants>;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** fetch data from the table: "organization_users" using primary key columns */
  organization_users_by_pk?: Maybe<Organization_Users>;
  /** fetch data from the table in a streaming manner: "organization_users" */
  organization_users_stream: Array<Organization_Users>;
  /** fetch data from the table: "organization_vendor_risk_levels" */
  organization_vendor_risk_levels: Array<Organization_Vendor_Risk_Levels>;
  /** fetch data from the table: "organization_vendor_risk_levels" using primary key columns */
  organization_vendor_risk_levels_by_pk?: Maybe<Organization_Vendor_Risk_Levels>;
  /** fetch data from the table in a streaming manner: "organization_vendor_risk_levels" */
  organization_vendor_risk_levels_stream: Array<Organization_Vendor_Risk_Levels>;
  /** fetch data from the table: "organization_vendor_tiers" */
  organization_vendor_tiers: Array<Organization_Vendor_Tiers>;
  /** fetch data from the table: "organization_vendor_tiers" using primary key columns */
  organization_vendor_tiers_by_pk?: Maybe<Organization_Vendor_Tiers>;
  /** fetch data from the table in a streaming manner: "organization_vendor_tiers" */
  organization_vendor_tiers_stream: Array<Organization_Vendor_Tiers>;
  /** An array relationship */
  organizations: Array<Organizations>;
  /** fetch data from the table: "organizations" using primary key columns */
  organizations_by_pk?: Maybe<Organizations>;
  /** fetch data from the table in a streaming manner: "organizations" */
  organizations_stream: Array<Organizations>;
  /** An array relationship */
  policies: Array<Policies>;
  /** fetch data from the table: "policies" using primary key columns */
  policies_by_pk?: Maybe<Policies>;
  /** fetch data from the table in a streaming manner: "policies" */
  policies_stream: Array<Policies>;
  /** An array relationship */
  programs: Array<Programs>;
  /** fetch data from the table: "programs" using primary key columns */
  programs_by_pk?: Maybe<Programs>;
  /** fetch data from the table in a streaming manner: "programs" */
  programs_stream: Array<Programs>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** fetch data from the table: "questionnaires" using primary key columns */
  questionnaires_by_pk?: Maybe<Questionnaires>;
  /** fetch data from the table in a streaming manner: "questionnaires" */
  questionnaires_stream: Array<Questionnaires>;
  /** An array relationship */
  reports: Array<Reports>;
  /** fetch data from the table: "reports" using primary key columns */
  reports_by_pk?: Maybe<Reports>;
  /** fetch data from the table in a streaming manner: "reports" */
  reports_stream: Array<Reports>;
  /** An array relationship */
  risk_impacts: Array<Risk_Impacts>;
  /** fetch data from the table: "risk_impacts" using primary key columns */
  risk_impacts_by_pk?: Maybe<Risk_Impacts>;
  /** fetch data from the table in a streaming manner: "risk_impacts" */
  risk_impacts_stream: Array<Risk_Impacts>;
  /** An array relationship */
  risk_inherent_levels: Array<Risk_Inherent_Levels>;
  /** fetch data from the table: "risk_inherent_levels" using primary key columns */
  risk_inherent_levels_by_pk?: Maybe<Risk_Inherent_Levels>;
  /** fetch data from the table in a streaming manner: "risk_inherent_levels" */
  risk_inherent_levels_stream: Array<Risk_Inherent_Levels>;
  /** An array relationship */
  risk_likelihoods: Array<Risk_Likelihoods>;
  /** fetch data from the table: "risk_likelihoods" using primary key columns */
  risk_likelihoods_by_pk?: Maybe<Risk_Likelihoods>;
  /** fetch data from the table in a streaming manner: "risk_likelihoods" */
  risk_likelihoods_stream: Array<Risk_Likelihoods>;
  /** An array relationship */
  risk_residual_levels: Array<Risk_Residual_Levels>;
  /** fetch data from the table: "risk_residual_levels" using primary key columns */
  risk_residual_levels_by_pk?: Maybe<Risk_Residual_Levels>;
  /** fetch data from the table in a streaming manner: "risk_residual_levels" */
  risk_residual_levels_stream: Array<Risk_Residual_Levels>;
  /** An array relationship */
  risks: Array<Risks>;
  /** An aggregate relationship */
  risks_aggregate: Risks_Aggregate;
  /** fetch data from the table: "risks" using primary key columns */
  risks_by_pk?: Maybe<Risks>;
  /** fetch data from the table in a streaming manner: "risks" */
  risks_stream: Array<Risks>;
  /** An array relationship */
  role_permissions: Array<Role_Permissions>;
  /** fetch data from the table: "role_permissions" using primary key columns */
  role_permissions_by_pk?: Maybe<Role_Permissions>;
  /** fetch data from the table in a streaming manner: "role_permissions" */
  role_permissions_stream: Array<Role_Permissions>;
  /** An array relationship */
  roles: Array<Roles>;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** An array relationship */
  tags: Array<Tags>;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "tags" */
  tags_stream: Array<Tags>;
  /** An array relationship */
  tasks: Array<Tasks>;
  /** fetch data from the table: "tasks" using primary key columns */
  tasks_by_pk?: Maybe<Tasks>;
  /** fetch data from the table: "template_controls" */
  template_controls: Array<Template_Controls>;
  /** fetch aggregated fields from the table: "template_controls" */
  template_controls_aggregate: Template_Controls_Aggregate;
  /** fetch data from the table: "template_controls" using primary key columns */
  template_controls_by_pk?: Maybe<Template_Controls>;
  /** fetch data from the table in a streaming manner: "template_controls" */
  template_controls_stream: Array<Template_Controls>;
  /** fetch data from the table: "template_frameworks" */
  template_frameworks: Array<Template_Frameworks>;
  /** fetch data from the table: "template_frameworks" using primary key columns */
  template_frameworks_by_pk?: Maybe<Template_Frameworks>;
  /** An array relationship */
  template_frameworks_controls: Array<Template_Frameworks_Controls>;
  /** fetch data from the table: "template_frameworks_controls" using primary key columns */
  template_frameworks_controls_by_pk?: Maybe<Template_Frameworks_Controls>;
  /** fetch data from the table in a streaming manner: "template_frameworks_controls" */
  template_frameworks_controls_stream: Array<Template_Frameworks_Controls>;
  /** fetch data from the table in a streaming manner: "template_frameworks" */
  template_frameworks_stream: Array<Template_Frameworks>;
  /** An array relationship */
  template_tasks: Array<Template_Tasks>;
  /** An aggregate relationship */
  template_tasks_aggregate: Template_Tasks_Aggregate;
  /** fetch data from the table: "template_tasks" using primary key columns */
  template_tasks_by_pk?: Maybe<Template_Tasks>;
  /** fetch data from the table in a streaming manner: "template_tasks" */
  template_tasks_stream: Array<Template_Tasks>;
  /** fetch data from the table: "auth.users" using primary key columns */
  user?: Maybe<Users>;
  /** fetch data from the table: "user_controls_stats_view" */
  user_controls_stats_view: Array<User_Controls_Stats_View>;
  /** fetch data from the table in a streaming manner: "user_controls_stats_view" */
  user_controls_stats_view_stream: Array<User_Controls_Stats_View>;
  /** An array relationship */
  user_notifications: Array<User_Notifications>;
  /** An aggregate relationship */
  user_notifications_aggregate: User_Notifications_Aggregate;
  /** fetch data from the table: "user_notifications" using primary key columns */
  user_notifications_by_pk?: Maybe<User_Notifications>;
  /** fetch data from the table in a streaming manner: "user_notifications" */
  user_notifications_stream: Array<User_Notifications>;
  /** fetch data from the table: "user_tasks_stats_view" */
  user_tasks_stats_view: Array<User_Tasks_Stats_View>;
  /** fetch data from the table in a streaming manner: "user_tasks_stats_view" */
  user_tasks_stats_view_stream: Array<User_Tasks_Stats_View>;
  /** fetch data from the table: "auth.users" */
  users: Array<Users>;
  /** fetch data from the table in a streaming manner: "auth.users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** fetch data from the table: "vendor_questionnaire_form_answer_statuses" using primary key columns */
  vendor_questionnaire_form_answer_statuses_by_pk?: Maybe<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_form_answer_statuses" */
  vendor_questionnaire_form_answer_statuses_stream: Array<Vendor_Questionnaire_Form_Answer_Statuses>;
  /** An array relationship */
  vendor_questionnaire_form_answers: Array<Vendor_Questionnaire_Form_Answers>;
  /** fetch data from the table: "vendor_questionnaire_form_answers" using primary key columns */
  vendor_questionnaire_form_answers_by_pk?: Maybe<Vendor_Questionnaire_Form_Answers>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_form_answers" */
  vendor_questionnaire_form_answers_stream: Array<Vendor_Questionnaire_Form_Answers>;
  /** An array relationship */
  vendor_questionnaire_form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_form_uploads" using primary key columns */
  vendor_questionnaire_form_uploads_by_pk?: Maybe<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_form_uploads" */
  vendor_questionnaire_form_uploads_stream: Array<Vendor_Questionnaire_Form_Uploads>;
  /** fetch data from the table: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms: Array<Vendor_Questionnaire_Forms>;
  /** fetch data from the table: "vendor_questionnaire_forms" using primary key columns */
  vendor_questionnaire_forms_by_pk?: Maybe<Vendor_Questionnaire_Forms>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_forms" */
  vendor_questionnaire_forms_stream: Array<Vendor_Questionnaire_Forms>;
  /** fetch data from the table: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses: Array<Vendor_Questionnaire_Statuses>;
  /** fetch data from the table: "vendor_questionnaire_statuses" using primary key columns */
  vendor_questionnaire_statuses_by_pk?: Maybe<Vendor_Questionnaire_Statuses>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaire_statuses" */
  vendor_questionnaire_statuses_stream: Array<Vendor_Questionnaire_Statuses>;
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
  /** fetch data from the table: "vendor_questionnaires" using primary key columns */
  vendor_questionnaires_by_pk?: Maybe<Vendor_Questionnaires>;
  /** fetch data from the table in a streaming manner: "vendor_questionnaires" */
  vendor_questionnaires_stream: Array<Vendor_Questionnaires>;
  /** An array relationship */
  vendor_risks: Array<Vendor_Risks>;
  /** fetch data from the table: "vendor_risks" using primary key columns */
  vendor_risks_by_pk?: Maybe<Vendor_Risks>;
  /** fetch data from the table in a streaming manner: "vendor_risks" */
  vendor_risks_stream: Array<Vendor_Risks>;
  /** An array relationship */
  vendors: Array<Vendors>;
  /** fetch data from the table: "vendors" using primary key columns */
  vendors_by_pk?: Maybe<Vendors>;
  /** fetch data from the table in a streaming manner: "vendors" */
  vendors_stream: Array<Vendors>;
  /** fetch data from the table: "vendors_summary_view" */
  vendors_summary_view: Array<Vendors_Summary_View>;
  /** fetch data from the table in a streaming manner: "vendors_summary_view" */
  vendors_summary_view_stream: Array<Vendors_Summary_View>;
};

export type Subscription_RootAi_Control_Evidences_SuggestionsArgs = {
  args: Ai_Control_Evidences_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Control_Evidences_Suggestions_AggregateArgs = {
  args: Ai_Control_Evidences_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Text_SearchArgs = {
  args: Ai_Controls_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Text_Search_AggregateArgs = {
  args: Ai_Controls_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Vector_Store_SearchArgs = {
  args: Ai_Controls_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootAi_Controls_Vector_Store_Search_AggregateArgs = {
  args: Ai_Controls_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootAi_Documents_Text_SearchArgs = {
  args: Ai_Documents_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Subscription_RootAi_Documents_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Documents_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Documents_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Documents_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Documents_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Documents_Vector_Store_SearchArgs = {
  args: Ai_Documents_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Ai_Documents_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Documents_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Documents_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Documents_Vector_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Documents_Vector_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Documents_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Text_SearchArgs = {
  args: Ai_Evidences_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Text_Search_AggregateArgs = {
  args: Ai_Evidences_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Vector_Store_SearchArgs = {
  args: Ai_Evidences_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Evidences_Vector_Store_Search_AggregateArgs = {
  args: Ai_Evidences_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Text_SearchArgs = {
  args: Ai_Policies_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Vector_StoreArgs = {
  distinct_on?: InputMaybe<Array<Ai_Policies_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Policies_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Vector_Store_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAi_Policies_Vector_Store_SearchArgs = {
  args: Ai_Policies_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Ai_Policies_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Policies_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Policies_Vector_Store_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Ai_Policies_Vector_Store_Stream_Cursor_Input>>;
  where?: InputMaybe<Ai_Policies_Vector_Store_Bool_Exp>;
};

export type Subscription_RootAi_Programs_Text_SearchArgs = {
  args: Ai_Programs_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootAi_Programs_Vector_Store_SearchArgs = {
  args: Ai_Programs_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootAi_Risk_Controls_SuggestionsArgs = {
  args: Ai_Risk_Controls_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootAi_Risk_Controls_Suggestions_AggregateArgs = {
  args: Ai_Risk_Controls_Suggestions_Args;
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Text_SearchArgs = {
  args: Ai_Risks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Text_Search_AggregateArgs = {
  args: Ai_Risks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Vector_Store_SearchArgs = {
  args: Ai_Risks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootAi_Risks_Vector_Store_Search_AggregateArgs = {
  args: Ai_Risks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Text_SearchArgs = {
  args: Ai_Tasks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Text_Search_AggregateArgs = {
  args: Ai_Tasks_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Vector_Store_SearchArgs = {
  args: Ai_Tasks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootAi_Tasks_Vector_Store_Search_AggregateArgs = {
  args: Ai_Tasks_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootAi_Vendors_Text_SearchArgs = {
  args: Ai_Vendors_Text_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootAi_Vendors_Vector_Store_SearchArgs = {
  args: Ai_Vendors_Vector_Store_Search_Args;
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootAudit_Control_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Control_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Control_History_View_Order_By>>;
  where?: InputMaybe<Audit_Control_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Control_History_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Audit_Control_History_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Control_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Policy_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Policy_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Policy_History_View_Order_By>>;
  where?: InputMaybe<Audit_Policy_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Policy_History_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Audit_Policy_History_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Policy_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Risk_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Risk_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Risk_History_View_Order_By>>;
  where?: InputMaybe<Audit_Risk_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Risk_History_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Audit_Risk_History_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Risk_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Vendor_History_ViewArgs = {
  distinct_on?: InputMaybe<Array<Audit_Vendor_History_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Audit_Vendor_History_View_Order_By>>;
  where?: InputMaybe<Audit_Vendor_History_View_Bool_Exp>;
};

export type Subscription_RootAudit_Vendor_History_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Audit_Vendor_History_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Vendor_History_View_Bool_Exp>;
};

export type Subscription_RootAuthRefreshTokenArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootAuthRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

export type Subscription_RootAuthRefreshTokens_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<AuthRefreshTokens_Stream_Cursor_Input>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

export type Subscription_RootCategoriesArgs = {
  distinct_on?: InputMaybe<Array<Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Categories_Order_By>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

export type Subscription_RootCategories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootCategories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Categories_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_QuestionsArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Questions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Questions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Questions_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaire_Questions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootClient_Questionnaire_Questions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Client_Questionnaire_Questions_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Questionnaire_Questions_Bool_Exp>;
};

export type Subscription_RootClient_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

export type Subscription_RootClient_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootClient_Questionnaires_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Client_Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

export type Subscription_RootControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootControls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootControls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Controls_Stream_Cursor_Input>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

export type Subscription_RootCriteriaArgs = {
  distinct_on?: InputMaybe<Array<Criteria_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Criteria_Order_By>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

export type Subscription_RootCriteria_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootCriteria_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Criteria_Stream_Cursor_Input>>;
  where?: InputMaybe<Criteria_Bool_Exp>;
};

export type Subscription_RootDaily_Control_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_Stats_View_Bool_Exp>;
};

export type Subscription_RootDaily_Control_Stats_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Daily_Control_Stats_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Daily_Control_Stats_View_Bool_Exp>;
};

export type Subscription_RootDaily_Control_User_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<Daily_Control_User_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Daily_Control_User_Stats_View_Order_By>>;
  where?: InputMaybe<Daily_Control_User_Stats_View_Bool_Exp>;
};

export type Subscription_RootDaily_Control_User_Stats_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Daily_Control_User_Stats_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Daily_Control_User_Stats_View_Bool_Exp>;
};

export type Subscription_RootDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Documents_Order_By>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Subscription_RootDocuments_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootDocuments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Documents_Stream_Cursor_Input>>;
  where?: InputMaybe<Documents_Bool_Exp>;
};

export type Subscription_RootEvidence_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Integrations_Order_By>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

export type Subscription_RootEvidence_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEvidence_Integrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Evidence_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Evidence_Integrations_Bool_Exp>;
};

export type Subscription_RootEvidence_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

export type Subscription_RootEvidence_Versions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEvidence_Versions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Evidence_Versions_Stream_Cursor_Input>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

export type Subscription_RootEvidencesArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootEvidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootEvidences_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootEvidences_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Evidences_Stream_Cursor_Input>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

export type Subscription_RootField_ConfigsArgs = {
  distinct_on?: InputMaybe<Array<Field_Configs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Configs_Order_By>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

export type Subscription_RootField_Configs_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootField_Configs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Field_Configs_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Configs_Bool_Exp>;
};

export type Subscription_RootFrameworksArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Order_By>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

export type Subscription_RootFrameworks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootFrameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

export type Subscription_RootFrameworks_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

export type Subscription_RootFrameworks_Controls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Frameworks_Controls_Stream_Cursor_Input>>;
  where?: InputMaybe<Frameworks_Controls_Bool_Exp>;
};

export type Subscription_RootFrameworks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Frameworks_Stream_Cursor_Input>>;
  where?: InputMaybe<Frameworks_Bool_Exp>;
};

export type Subscription_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

export type Subscription_RootGroups_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootGroups_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

export type Subscription_RootIntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Integrations_Order_By>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Subscription_RootIntegrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootIntegrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Integrations_Bool_Exp>;
};

export type Subscription_RootInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

export type Subscription_RootInvitations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootInvitations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Invitations_Stream_Cursor_Input>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

export type Subscription_RootNotification_Disabled_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

export type Subscription_RootNotification_Disabled_Settings_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootNotification_Disabled_Settings_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notification_Disabled_Settings_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

export type Subscription_RootOrganization_Evidence_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Evidence_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Evidence_Tags_Order_By>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

export type Subscription_RootOrganization_Evidence_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Evidence_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Evidence_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Evidence_Tags_Bool_Exp>;
};

export type Subscription_RootOrganization_IntegrationsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Integrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Integrations_Order_By>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

export type Subscription_RootOrganization_Integrations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Integrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Integrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Integrations_Bool_Exp>;
};

export type Subscription_RootOrganization_Risk_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Categories_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Categories_Order_By>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

export type Subscription_RootOrganization_Risk_Categories_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Risk_Categories_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Risk_Categories_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Risk_Categories_Bool_Exp>;
};

export type Subscription_RootOrganization_Risk_TagsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Risk_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Risk_Tags_Order_By>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

export type Subscription_RootOrganization_Risk_Tags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Risk_Tags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Risk_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Risk_Tags_Bool_Exp>;
};

export type Subscription_RootOrganization_Template_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

export type Subscription_RootOrganization_Template_Frameworks_By_PkArgs = {
  organization_id: Scalars['uuid']['input'];
  template_framework_id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Template_Frameworks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Template_Frameworks_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

export type Subscription_RootOrganization_TenantsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Tenants_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Tenants_Order_By>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

export type Subscription_RootOrganization_Tenants_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Tenants_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Tenants_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Tenants_Bool_Exp>;
};

export type Subscription_RootOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

export type Subscription_RootOrganization_Users_By_PkArgs = {
  organization_id: Scalars['uuid']['input'];
  user_id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Users_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

export type Subscription_RootOrganization_Vendor_Risk_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Risk_Levels_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

export type Subscription_RootOrganization_Vendor_Risk_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Vendor_Risk_Levels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Vendor_Risk_Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
};

export type Subscription_RootOrganization_Vendor_TiersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Vendor_Tiers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Vendor_Tiers_Order_By>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

export type Subscription_RootOrganization_Vendor_Tiers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganization_Vendor_Tiers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organization_Vendor_Tiers_Stream_Cursor_Input>>;
  where?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
};

export type Subscription_RootOrganizationsArgs = {
  distinct_on?: InputMaybe<Array<Organizations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organizations_Order_By>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Subscription_RootOrganizations_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootOrganizations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Organizations_Stream_Cursor_Input>>;
  where?: InputMaybe<Organizations_Bool_Exp>;
};

export type Subscription_RootPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootPolicies_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPolicies_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Policies_Stream_Cursor_Input>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

export type Subscription_RootProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootPrograms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootPrograms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Programs_Stream_Cursor_Input>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

export type Subscription_RootQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Subscription_RootQuestionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootQuestionnaires_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

export type Subscription_RootReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

export type Subscription_RootReports_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootReports_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

export type Subscription_RootRisk_ImpactsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Impacts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Impacts_Order_By>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

export type Subscription_RootRisk_Impacts_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Impacts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Impacts_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Impacts_Bool_Exp>;
};

export type Subscription_RootRisk_Inherent_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Inherent_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Inherent_Levels_Order_By>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_Inherent_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Inherent_Levels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Inherent_Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Inherent_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_LikelihoodsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Likelihoods_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Likelihoods_Order_By>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

export type Subscription_RootRisk_Likelihoods_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Likelihoods_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Likelihoods_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Likelihoods_Bool_Exp>;
};

export type Subscription_RootRisk_Residual_LevelsArgs = {
  distinct_on?: InputMaybe<Array<Risk_Residual_Levels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Residual_Levels_Order_By>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

export type Subscription_RootRisk_Residual_Levels_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisk_Residual_Levels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risk_Residual_Levels_Stream_Cursor_Input>>;
  where?: InputMaybe<Risk_Residual_Levels_Bool_Exp>;
};

export type Subscription_RootRisksArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootRisks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRisks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Risks_Stream_Cursor_Input>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

export type Subscription_RootRole_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Permissions_Order_By>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

export type Subscription_RootRole_Permissions_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRole_Permissions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Role_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Permissions_Bool_Exp>;
};

export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

export type Subscription_RootTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

export type Subscription_RootTasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTemplate_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_Controls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Controls_Order_By>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_Controls_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTemplate_Controls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Controls_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

export type Subscription_RootTemplate_Frameworks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTemplate_Frameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_Frameworks_Controls_By_PkArgs = {
  control_id: Scalars['uuid']['input'];
  framework_id: Scalars['uuid']['input'];
};

export type Subscription_RootTemplate_Frameworks_Controls_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Frameworks_Controls_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

export type Subscription_RootTemplate_Frameworks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Frameworks_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

export type Subscription_RootTemplate_TasksArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

export type Subscription_RootTemplate_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

export type Subscription_RootTemplate_Tasks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootTemplate_Tasks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Template_Tasks_Stream_Cursor_Input>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

export type Subscription_RootUserArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootUser_Controls_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<User_Controls_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Controls_Stats_View_Order_By>>;
  where?: InputMaybe<User_Controls_Stats_View_Bool_Exp>;
};

export type Subscription_RootUser_Controls_Stats_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Controls_Stats_View_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Controls_Stats_View_Bool_Exp>;
};

export type Subscription_RootUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

export type Subscription_RootUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

export type Subscription_RootUser_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootUser_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

export type Subscription_RootUser_Tasks_Stats_ViewArgs = {
  distinct_on?: InputMaybe<Array<User_Tasks_Stats_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Tasks_Stats_View_Order_By>>;
  where?: InputMaybe<User_Tasks_Stats_View_Bool_Exp>;
};

export type Subscription_RootUser_Tasks_Stats_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Tasks_Stats_View_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Tasks_Stats_View_Bool_Exp>;
};

export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answer_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answer_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootVendor_Questionnaire_Form_Answer_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Answers_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Form_Answers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Form_Answers_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Form_Uploads_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Form_Uploads_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_FormsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Forms_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Forms_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Forms_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaire_Forms_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Forms_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaire_Statuses_By_PkArgs = {
  value: Scalars['String']['input'];
};

export type Subscription_RootVendor_Questionnaire_Statuses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaire_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
};

export type Subscription_RootVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Subscription_RootVendor_Questionnaires_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Questionnaires_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Questionnaires_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export type Subscription_RootVendor_RisksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

export type Subscription_RootVendor_Risks_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendor_Risks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendor_Risks_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

export type Subscription_RootVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootVendors_By_PkArgs = {
  id: Scalars['uuid']['input'];
};

export type Subscription_RootVendors_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendors_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

export type Subscription_RootVendors_Summary_ViewArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Summary_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Summary_View_Order_By>>;
  where?: InputMaybe<Vendors_Summary_View_Bool_Exp>;
};

export type Subscription_RootVendors_Summary_View_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Vendors_Summary_View_Stream_Cursor_Input>>;
  where?: InputMaybe<Vendors_Summary_View_Bool_Exp>;
};

export enum System_Roles_Enum {
  Admin = 'admin',
  Auditor = 'auditor',
  Member = 'member',
}

/** Boolean expression to compare columns of type "system_roles_enum". All fields are combined with logical 'AND'. */
export type System_Roles_Enum_Comparison_Exp = {
  _eq?: InputMaybe<System_Roles_Enum>;
  _in?: InputMaybe<Array<System_Roles_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<System_Roles_Enum>;
  _nin?: InputMaybe<Array<System_Roles_Enum>>;
};

/** stores custom tags related to organization */
export type Tags = {
  __typename?: 'tags';
  /** An array relationship */
  control_tags: Array<Control_Tags>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
};

/** stores custom tags related to organization */
export type TagsControl_TagsArgs = {
  distinct_on?: InputMaybe<Array<Control_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tags_Order_By>>;
  where?: InputMaybe<Control_Tags_Bool_Exp>;
};

/** order by aggregate values of table "tags" */
export type Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tags_Max_Order_By>;
  min?: InputMaybe<Tags_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Tags_Bool_Exp>>;
  _not?: InputMaybe<Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Tags_Bool_Exp>>;
  control_tags?: InputMaybe<Control_Tags_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint on columns "name", "organization_id" */
  TagsNameOrganizationIdKey = 'tags_name_organization_id_key',
  /** unique or primary key constraint on columns "id" */
  TagsPkey = 'tags_pkey',
}

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  control_tags?: InputMaybe<Control_Tags_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "tags" */
export type Tags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "tags" */
export type Tags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  control_tags_aggregate?: InputMaybe<Control_Tags_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  name?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  Name = 'name',
}

export type Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tags_Bool_Exp;
};

/** users responsible for task */
export type Task_Owners = {
  __typename?: 'task_owners';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  owner_id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  user: Users;
};

/** order by aggregate values of table "task_owners" */
export type Task_Owners_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Task_Owners_Max_Order_By>;
  min?: InputMaybe<Task_Owners_Min_Order_By>;
};

/** input type for inserting array relation for remote table "task_owners" */
export type Task_Owners_Arr_Rel_Insert_Input = {
  data: Array<Task_Owners_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Task_Owners_On_Conflict>;
};

/** Boolean expression to filter rows from the table "task_owners". All fields are combined with a logical 'AND'. */
export type Task_Owners_Bool_Exp = {
  _and?: InputMaybe<Array<Task_Owners_Bool_Exp>>;
  _not?: InputMaybe<Task_Owners_Bool_Exp>;
  _or?: InputMaybe<Array<Task_Owners_Bool_Exp>>;
  completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "task_owners" */
export enum Task_Owners_Constraint {
  /** unique or primary key constraint on columns "id" */
  TaskOwnersPkey = 'task_owners_pkey',
  /** unique or primary key constraint on columns "task_id", "owner_id" */
  TaskOwnersTaskIdOwnerIdKey = 'task_owners_task_id_owner_id_key',
}

/** input type for inserting data into table "task_owners" */
export type Task_Owners_Insert_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "task_owners" */
export type Task_Owners_Max_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "task_owners" */
export type Task_Owners_Min_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "task_owners" */
export type Task_Owners_Mutation_Response = {
  __typename?: 'task_owners_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Task_Owners>;
};

/** on_conflict condition type for table "task_owners" */
export type Task_Owners_On_Conflict = {
  constraint: Task_Owners_Constraint;
  update_columns?: Array<Task_Owners_Update_Column>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

/** Ordering options when selecting data from "task_owners". */
export type Task_Owners_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: task_owners */
export type Task_Owners_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "task_owners" */
export enum Task_Owners_Select_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "task_owners" */
export type Task_Owners_Set_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "task_owners" */
export enum Task_Owners_Update_Column {
  /** column name */
  CompletedAt = 'completed_at',
}

export type Task_Owners_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Task_Owners_Set_Input>;
  /** filter the rows which have to be updated */
  where: Task_Owners_Bool_Exp;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type Tasks = {
  __typename?: 'tasks';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  control_findings: Array<Control_Findings>;
  /** An array relationship */
  controls: Array<Control_Tasks>;
  /** An aggregate relationship */
  controls_aggregate: Control_Tasks_Aggregate;
  created_at: Scalars['timestamptz']['output'];
  created_by?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  field_values: Array<Field_Values>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An object relationship */
  organization: Organizations;
  organization_id: Scalars['uuid']['output'];
  /** An array relationship */
  risks: Array<Risk_Tasks>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status: Scalars['TaskStatus']['output'];
  /** An array relationship */
  task_owners: Array<Task_Owners>;
  /** An array relationship */
  tasks_comments: Array<Comments>;
  /** An array relationship */
  tasks_policy_tasks: Array<Policy_Tasks>;
  /** An array relationship */
  tasks_vector_stores: Array<Ai_Tasks_Vector_Store>;
  /** An object relationship */
  template_task?: Maybe<Template_Tasks>;
  template_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_finding?: Maybe<Vendor_Findings>;
  /** An array relationship */
  vendors: Array<Vendor_Tasks>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksControl_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Control_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Findings_Order_By>>;
  where?: InputMaybe<Control_Findings_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksControlsArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Control_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Control_Tasks_Order_By>>;
  where?: InputMaybe<Control_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksRisksArgs = {
  distinct_on?: InputMaybe<Array<Risk_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risk_Tasks_Order_By>>;
  where?: InputMaybe<Risk_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksTask_OwnersArgs = {
  distinct_on?: InputMaybe<Array<Task_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Owners_Order_By>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksTasks_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksTasks_Policy_TasksArgs = {
  distinct_on?: InputMaybe<Array<Policy_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Tasks_Order_By>>;
  where?: InputMaybe<Policy_Tasks_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksTasks_Vector_StoresArgs = {
  distinct_on?: InputMaybe<Array<Ai_Tasks_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Tasks_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
};

/** Stores programs' tasks, specific to one organization. Tasks are customized here. */
export type TasksVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Tasks_Order_By>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

/** aggregated selection of "tasks" */
export type Tasks_Aggregate = {
  __typename?: 'tasks_aggregate';
  aggregate?: Maybe<Tasks_Aggregate_Fields>;
  nodes: Array<Tasks>;
};

export type Tasks_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Tasks_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Tasks_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Tasks_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Tasks_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Tasks_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Tasks_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Tasks_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Tasks_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Tasks_Aggregate_Bool_Exp_Var_Samp>;
};

export type Tasks_Aggregate_Bool_Exp_Avg = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Corr = {
  arguments: Tasks_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Tasks_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Tasks_Aggregate_Bool_Exp_Max = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Min = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Sum = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Tasks_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Tasks_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_Fields = {
  __typename?: 'tasks_aggregate_fields';
  avg?: Maybe<Tasks_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Tasks_Max_Fields>;
  min?: Maybe<Tasks_Min_Fields>;
  stddev?: Maybe<Tasks_Stddev_Fields>;
  stddev_pop?: Maybe<Tasks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tasks_Stddev_Samp_Fields>;
  sum?: Maybe<Tasks_Sum_Fields>;
  var_pop?: Maybe<Tasks_Var_Pop_Fields>;
  var_samp?: Maybe<Tasks_Var_Samp_Fields>;
  variance?: Maybe<Tasks_Variance_Fields>;
};

/** aggregate fields of "tasks" */
export type Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "tasks" */
export type Tasks_Aggregate_Order_By = {
  avg?: InputMaybe<Tasks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Tasks_Max_Order_By>;
  min?: InputMaybe<Tasks_Min_Order_By>;
  stddev?: InputMaybe<Tasks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Tasks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Tasks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Tasks_Sum_Order_By>;
  var_pop?: InputMaybe<Tasks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Tasks_Var_Samp_Order_By>;
  variance?: InputMaybe<Tasks_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Tasks_Avg_Fields = {
  __typename?: 'tasks_avg_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "tasks" */
export type Tasks_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "tasks". All fields are combined with a logical 'AND'. */
export type Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Tasks_Bool_Exp>>;
  _not?: InputMaybe<Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Tasks_Bool_Exp>>;
  completed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  control_findings?: InputMaybe<Control_Findings_Bool_Exp>;
  controls?: InputMaybe<Control_Tasks_Bool_Exp>;
  controls_aggregate?: InputMaybe<Control_Tasks_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  risks?: InputMaybe<Risk_Tasks_Bool_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  task_owners?: InputMaybe<Task_Owners_Bool_Exp>;
  tasks_comments?: InputMaybe<Comments_Bool_Exp>;
  tasks_policy_tasks?: InputMaybe<Policy_Tasks_Bool_Exp>;
  tasks_vector_stores?: InputMaybe<Ai_Tasks_Vector_Store_Bool_Exp>;
  template_task?: InputMaybe<Template_Tasks_Bool_Exp>;
  template_task_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_finding?: InputMaybe<Vendor_Findings_Bool_Exp>;
  vendors?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

/** unique or primary key constraints on table "tasks" */
export enum Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProgramTasksPkey = 'program_tasks_pkey',
}

/** input type for inserting data into table "tasks" */
export type Tasks_Insert_Input = {
  controls?: InputMaybe<Control_Tasks_Arr_Rel_Insert_Input>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id: Scalars['uuid']['input'];
  risks?: InputMaybe<Risk_Tasks_Arr_Rel_Insert_Input>;
  task_owners?: InputMaybe<Task_Owners_Arr_Rel_Insert_Input>;
  tasks_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  tasks_policy_tasks?: InputMaybe<Policy_Tasks_Arr_Rel_Insert_Input>;
  template_task_id?: InputMaybe<Scalars['uuid']['input']>;
  vendors?: InputMaybe<Vendor_Tasks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Tasks_Max_Fields = {
  __typename?: 'tasks_max_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  template_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "tasks" */
export type Tasks_Max_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Tasks_Min_Fields = {
  __typename?: 'tasks_min_fields';
  completed_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  template_task_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "tasks" */
export type Tasks_Min_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  template_task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "tasks" */
export type Tasks_Mutation_Response = {
  __typename?: 'tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tasks>;
};

/** input type for inserting object relation for remote table "tasks" */
export type Tasks_Obj_Rel_Insert_Input = {
  data: Tasks_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tasks_On_Conflict>;
};

/** on_conflict condition type for table "tasks" */
export type Tasks_On_Conflict = {
  constraint: Tasks_Constraint;
  update_columns?: Array<Tasks_Update_Column>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "tasks". */
export type Tasks_Order_By = {
  completed_at?: InputMaybe<Order_By>;
  control_findings_aggregate?: InputMaybe<Control_Findings_Aggregate_Order_By>;
  controls_aggregate?: InputMaybe<Control_Tasks_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  risks_aggregate?: InputMaybe<Risk_Tasks_Aggregate_Order_By>;
  similarity?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  task_owners_aggregate?: InputMaybe<Task_Owners_Aggregate_Order_By>;
  tasks_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  tasks_policy_tasks_aggregate?: InputMaybe<Policy_Tasks_Aggregate_Order_By>;
  tasks_vector_stores_aggregate?: InputMaybe<Ai_Tasks_Vector_Store_Aggregate_Order_By>;
  template_task?: InputMaybe<Template_Tasks_Order_By>;
  template_task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_finding?: InputMaybe<Vendor_Findings_Order_By>;
  vendors_aggregate?: InputMaybe<Vendor_Tasks_Aggregate_Order_By>;
};

/** primary key columns input for table: tasks */
export type Tasks_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "tasks" */
export enum Tasks_Select_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateTaskId = 'template_task_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** select "tasks_aggregate_bool_exp_avg_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_corr_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_max_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_min_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_sum_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** select "tasks_aggregate_bool_exp_var_samp_arguments_columns" columns of table "tasks" */
export enum Tasks_Select_Column_Tasks_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Similarity = 'similarity',
}

/** input type for updating data in table "tasks" */
export type Tasks_Set_Input = {
  completed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  template_task_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** aggregate stddev on columns */
export type Tasks_Stddev_Fields = {
  __typename?: 'tasks_stddev_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "tasks" */
export type Tasks_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Tasks_Stddev_Pop_Fields = {
  __typename?: 'tasks_stddev_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "tasks" */
export type Tasks_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Tasks_Stddev_Samp_Fields = {
  __typename?: 'tasks_stddev_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "tasks" */
export type Tasks_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Tasks_Sum_Fields = {
  __typename?: 'tasks_sum_fields';
  similarity?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "tasks" */
export type Tasks_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** update columns of table "tasks" */
export enum Tasks_Update_Column {
  /** column name */
  CompletedAt = 'completed_at',
  /** column name */
  Description = 'description',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  Status = 'status',
  /** column name */
  TemplateTaskId = 'template_task_id',
}

export type Tasks_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tasks_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tasks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tasks_Var_Pop_Fields = {
  __typename?: 'tasks_var_pop_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "tasks" */
export type Tasks_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Tasks_Var_Samp_Fields = {
  __typename?: 'tasks_var_samp_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "tasks" */
export type Tasks_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Tasks_Variance_Fields = {
  __typename?: 'tasks_variance_fields';
  similarity?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "tasks" */
export type Tasks_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** columns and relationships of "template_controls" */
export type Template_Controls = {
  __typename?: 'template_controls';
  /** An array relationship */
  controls: Array<Controls>;
  /** An aggregate relationship */
  controls_aggregate: Controls_Aggregate;
  description?: Maybe<Scalars['String']['output']>;
  frequency: Control_Frequencies_Enum;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  priority?: Maybe<Control_Priorities_Enum>;
  tags: Scalars['ControlTags']['output'];
  /** An array relationship */
  template_frameworks_controls: Array<Template_Frameworks_Controls>;
  /** An array relationship */
  template_tasks: Array<Template_Tasks>;
  /** An aggregate relationship */
  template_tasks_aggregate: Template_Tasks_Aggregate;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTagsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTemplate_Frameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTemplate_TasksArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

/** columns and relationships of "template_controls" */
export type Template_ControlsTemplate_Tasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Tasks_Order_By>>;
  where?: InputMaybe<Template_Tasks_Bool_Exp>;
};

/** aggregated selection of "template_controls" */
export type Template_Controls_Aggregate = {
  __typename?: 'template_controls_aggregate';
  aggregate?: Maybe<Template_Controls_Aggregate_Fields>;
  nodes: Array<Template_Controls>;
};

/** aggregate fields of "template_controls" */
export type Template_Controls_Aggregate_Fields = {
  __typename?: 'template_controls_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Controls_Max_Fields>;
  min?: Maybe<Template_Controls_Min_Fields>;
};

/** aggregate fields of "template_controls" */
export type Template_Controls_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Controls_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "template_controls". All fields are combined with a logical 'AND'. */
export type Template_Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Controls_Bool_Exp>>;
  _not?: InputMaybe<Template_Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Controls_Bool_Exp>>;
  controls?: InputMaybe<Controls_Bool_Exp>;
  controls_aggregate?: InputMaybe<Controls_Aggregate_Bool_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  frequency?: InputMaybe<Control_Frequencies_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  priority?: InputMaybe<Control_Priorities_Enum_Comparison_Exp>;
  tags?: InputMaybe<Jsonb_Comparison_Exp>;
  template_frameworks_controls?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
  template_tasks?: InputMaybe<Template_Tasks_Bool_Exp>;
  template_tasks_aggregate?: InputMaybe<Template_Tasks_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type Template_Controls_Max_Fields = {
  __typename?: 'template_controls_max_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Template_Controls_Min_Fields = {
  __typename?: 'template_controls_min_fields';
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "template_controls". */
export type Template_Controls_Order_By = {
  controls_aggregate?: InputMaybe<Controls_Aggregate_Order_By>;
  description?: InputMaybe<Order_By>;
  frequency?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  priority?: InputMaybe<Order_By>;
  tags?: InputMaybe<Order_By>;
  template_frameworks_controls_aggregate?: InputMaybe<Template_Frameworks_Controls_Aggregate_Order_By>;
  template_tasks_aggregate?: InputMaybe<Template_Tasks_Aggregate_Order_By>;
};

/** select columns of table "template_controls" */
export enum Template_Controls_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Frequency = 'frequency',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  Priority = 'priority',
  /** column name */
  Tags = 'tags',
}

/** Streaming cursor of the table "template_controls" */
export type Template_Controls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Controls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Controls_Stream_Cursor_Value_Input = {
  description?: InputMaybe<Scalars['String']['input']>;
  frequency?: InputMaybe<Control_Frequencies_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  priority?: InputMaybe<Control_Priorities_Enum>;
  tags?: InputMaybe<Scalars['jsonb']['input']>;
};

/** columns and relationships of "template_frameworks" */
export type Template_Frameworks = {
  __typename?: 'template_frameworks';
  active: Scalars['Boolean']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  organization_template_frameworks: Array<Organization_Template_Frameworks>;
  /** An array relationship */
  programs: Array<Programs>;
  /** An array relationship */
  template_frameworks_controls: Array<Template_Frameworks_Controls>;
};

/** columns and relationships of "template_frameworks" */
export type Template_FrameworksOrganization_Template_FrameworksArgs = {
  distinct_on?: InputMaybe<Array<Organization_Template_Frameworks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Template_Frameworks_Order_By>>;
  where?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
};

/** columns and relationships of "template_frameworks" */
export type Template_FrameworksProgramsArgs = {
  distinct_on?: InputMaybe<Array<Programs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Programs_Order_By>>;
  where?: InputMaybe<Programs_Bool_Exp>;
};

/** columns and relationships of "template_frameworks" */
export type Template_FrameworksTemplate_Frameworks_ControlsArgs = {
  distinct_on?: InputMaybe<Array<Template_Frameworks_Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Template_Frameworks_Controls_Order_By>>;
  where?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "template_frameworks". All fields are combined with a logical 'AND'. */
export type Template_Frameworks_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Frameworks_Bool_Exp>>;
  _not?: InputMaybe<Template_Frameworks_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Frameworks_Bool_Exp>>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization_template_frameworks?: InputMaybe<Organization_Template_Frameworks_Bool_Exp>;
  programs?: InputMaybe<Programs_Bool_Exp>;
  template_frameworks_controls?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
};

/** columns and relationships of "template_frameworks_controls" */
export type Template_Frameworks_Controls = {
  __typename?: 'template_frameworks_controls';
  control_id: Scalars['uuid']['output'];
  framework_id: Scalars['uuid']['output'];
  /** An object relationship */
  template_control: Template_Controls;
  /** An object relationship */
  template_framework: Template_Frameworks;
};

/** order by aggregate values of table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Template_Frameworks_Controls_Max_Order_By>;
  min?: InputMaybe<Template_Frameworks_Controls_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "template_frameworks_controls". All fields are combined with a logical 'AND'. */
export type Template_Frameworks_Controls_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Frameworks_Controls_Bool_Exp>>;
  _not?: InputMaybe<Template_Frameworks_Controls_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Frameworks_Controls_Bool_Exp>>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  framework_id?: InputMaybe<Uuid_Comparison_Exp>;
  template_control?: InputMaybe<Template_Controls_Bool_Exp>;
  template_framework?: InputMaybe<Template_Frameworks_Bool_Exp>;
};

/** order by max() on columns of table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "template_frameworks_controls". */
export type Template_Frameworks_Controls_Order_By = {
  control_id?: InputMaybe<Order_By>;
  framework_id?: InputMaybe<Order_By>;
  template_control?: InputMaybe<Template_Controls_Order_By>;
  template_framework?: InputMaybe<Template_Frameworks_Order_By>;
};

/** select columns of table "template_frameworks_controls" */
export enum Template_Frameworks_Controls_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  FrameworkId = 'framework_id',
}

/** Streaming cursor of the table "template_frameworks_controls" */
export type Template_Frameworks_Controls_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Frameworks_Controls_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Frameworks_Controls_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  framework_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** Ordering options when selecting data from "template_frameworks". */
export type Template_Frameworks_Order_By = {
  active?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_template_frameworks_aggregate?: InputMaybe<Organization_Template_Frameworks_Aggregate_Order_By>;
  programs_aggregate?: InputMaybe<Programs_Aggregate_Order_By>;
  template_frameworks_controls_aggregate?: InputMaybe<Template_Frameworks_Controls_Aggregate_Order_By>;
};

/** select columns of table "template_frameworks" */
export enum Template_Frameworks_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "template_frameworks" */
export type Template_Frameworks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Frameworks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Frameworks_Stream_Cursor_Value_Input = {
  active?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "template_tasks" */
export type Template_Tasks = {
  __typename?: 'template_tasks';
  control_id: Scalars['uuid']['output'];
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  tasks: Array<Tasks>;
  /** An aggregate relationship */
  tasks_aggregate: Tasks_Aggregate;
  /** An object relationship */
  template_control: Template_Controls;
};

/** columns and relationships of "template_tasks" */
export type Template_TasksTasksArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** columns and relationships of "template_tasks" */
export type Template_TasksTasks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tasks_Order_By>>;
  where?: InputMaybe<Tasks_Bool_Exp>;
};

/** aggregated selection of "template_tasks" */
export type Template_Tasks_Aggregate = {
  __typename?: 'template_tasks_aggregate';
  aggregate?: Maybe<Template_Tasks_Aggregate_Fields>;
  nodes: Array<Template_Tasks>;
};

export type Template_Tasks_Aggregate_Bool_Exp = {
  count?: InputMaybe<Template_Tasks_Aggregate_Bool_Exp_Count>;
};

export type Template_Tasks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Template_Tasks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "template_tasks" */
export type Template_Tasks_Aggregate_Fields = {
  __typename?: 'template_tasks_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Template_Tasks_Max_Fields>;
  min?: Maybe<Template_Tasks_Min_Fields>;
};

/** aggregate fields of "template_tasks" */
export type Template_Tasks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Template_Tasks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "template_tasks" */
export type Template_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Template_Tasks_Max_Order_By>;
  min?: InputMaybe<Template_Tasks_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "template_tasks". All fields are combined with a logical 'AND'. */
export type Template_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Template_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Template_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Template_Tasks_Bool_Exp>>;
  control_id?: InputMaybe<Uuid_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  tasks?: InputMaybe<Tasks_Bool_Exp>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Bool_Exp>;
  template_control?: InputMaybe<Template_Controls_Bool_Exp>;
};

/** aggregate max on columns */
export type Template_Tasks_Max_Fields = {
  __typename?: 'template_tasks_max_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "template_tasks" */
export type Template_Tasks_Max_Order_By = {
  control_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Template_Tasks_Min_Fields = {
  __typename?: 'template_tasks_min_fields';
  control_id?: Maybe<Scalars['uuid']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  internal_id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "template_tasks" */
export type Template_Tasks_Min_Order_By = {
  control_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "template_tasks". */
export type Template_Tasks_Order_By = {
  control_id?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Tasks_Aggregate_Order_By>;
  template_control?: InputMaybe<Template_Controls_Order_By>;
};

/** select columns of table "template_tasks" */
export enum Template_Tasks_Select_Column {
  /** column name */
  ControlId = 'control_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
}

/** Streaming cursor of the table "template_tasks" */
export type Template_Tasks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Template_Tasks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Template_Tasks_Stream_Cursor_Value_Input = {
  control_id?: InputMaybe<Scalars['uuid']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "text_field_values" */
export type Text_Field_Values = {
  __typename?: 'text_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['String']['output'];
};

/** order by aggregate values of table "text_field_values" */
export type Text_Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Text_Field_Values_Max_Order_By>;
  min?: InputMaybe<Text_Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "text_field_values" */
export type Text_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Text_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Text_Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "text_field_values". All fields are combined with a logical 'AND'. */
export type Text_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Text_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Text_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Text_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "text_field_values" */
export enum Text_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  TextFieldValuesPkey = 'text_field_values_pkey',
}

/** input type for inserting data into table "text_field_values" */
export type Text_Field_Values_Insert_Input = {
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "text_field_values" */
export type Text_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "text_field_values" */
export type Text_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "text_field_values" */
export type Text_Field_Values_Mutation_Response = {
  __typename?: 'text_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Text_Field_Values>;
};

/** input type for inserting object relation for remote table "text_field_values" */
export type Text_Field_Values_Obj_Rel_Insert_Input = {
  data: Text_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Text_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "text_field_values" */
export type Text_Field_Values_On_Conflict = {
  constraint: Text_Field_Values_Constraint;
  update_columns?: Array<Text_Field_Values_Update_Column>;
  where?: InputMaybe<Text_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "text_field_values". */
export type Text_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: text_field_values */
export type Text_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "text_field_values" */
export enum Text_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "text_field_values" */
export type Text_Field_Values_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "text_field_values" */
export enum Text_Field_Values_Update_Column {
  /** column name */
  Value = 'value',
}

export type Text_Field_Values_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Text_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Text_Field_Values_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

export enum Treatment_Plan_Enum {
  Accepted = 'Accepted',
  Avoided = 'Avoided',
  Mitigated = 'Mitigated',
  Pending = 'Pending',
  Transferred = 'Transferred',
}

/** Boolean expression to compare columns of type "treatment_plan_enum". All fields are combined with logical 'AND'. */
export type Treatment_Plan_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Treatment_Plan_Enum>;
  _in?: InputMaybe<Array<Treatment_Plan_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Treatment_Plan_Enum>;
  _nin?: InputMaybe<Array<Treatment_Plan_Enum>>;
};

/** columns and relationships of "url_field_values" */
export type Url_Field_Values = {
  __typename?: 'url_field_values';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  fieldValueByFieldValueId: Field_Values;
  field_type: Scalars['String']['output'];
  /** An object relationship */
  field_value?: Maybe<Field_Values>;
  field_value_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  value: Scalars['String']['output'];
};

/** order by aggregate values of table "url_field_values" */
export type Url_Field_Values_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Url_Field_Values_Max_Order_By>;
  min?: InputMaybe<Url_Field_Values_Min_Order_By>;
};

/** input type for inserting array relation for remote table "url_field_values" */
export type Url_Field_Values_Arr_Rel_Insert_Input = {
  data: Array<Url_Field_Values_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Url_Field_Values_On_Conflict>;
};

/** Boolean expression to filter rows from the table "url_field_values". All fields are combined with a logical 'AND'. */
export type Url_Field_Values_Bool_Exp = {
  _and?: InputMaybe<Array<Url_Field_Values_Bool_Exp>>;
  _not?: InputMaybe<Url_Field_Values_Bool_Exp>;
  _or?: InputMaybe<Array<Url_Field_Values_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Bool_Exp>;
  field_type?: InputMaybe<String_Comparison_Exp>;
  field_value?: InputMaybe<Field_Values_Bool_Exp>;
  field_value_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "url_field_values" */
export enum Url_Field_Values_Constraint {
  /** unique or primary key constraint on columns "field_value_id" */
  UrlFieldValuesPkey = 'url_field_values_pkey',
}

/** input type for inserting data into table "url_field_values" */
export type Url_Field_Values_Insert_Input = {
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value?: InputMaybe<Field_Values_Obj_Rel_Insert_Input>;
  field_value_id?: InputMaybe<Scalars['uuid']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** order by max() on columns of table "url_field_values" */
export type Url_Field_Values_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "url_field_values" */
export type Url_Field_Values_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "url_field_values" */
export type Url_Field_Values_Mutation_Response = {
  __typename?: 'url_field_values_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Url_Field_Values>;
};

/** input type for inserting object relation for remote table "url_field_values" */
export type Url_Field_Values_Obj_Rel_Insert_Input = {
  data: Url_Field_Values_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Url_Field_Values_On_Conflict>;
};

/** on_conflict condition type for table "url_field_values" */
export type Url_Field_Values_On_Conflict = {
  constraint: Url_Field_Values_Constraint;
  update_columns?: Array<Url_Field_Values_Update_Column>;
  where?: InputMaybe<Url_Field_Values_Bool_Exp>;
};

/** Ordering options when selecting data from "url_field_values". */
export type Url_Field_Values_Order_By = {
  created_at?: InputMaybe<Order_By>;
  fieldValueByFieldValueId?: InputMaybe<Field_Values_Order_By>;
  field_type?: InputMaybe<Order_By>;
  field_value?: InputMaybe<Field_Values_Order_By>;
  field_value_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: url_field_values */
export type Url_Field_Values_Pk_Columns_Input = {
  field_value_id: Scalars['uuid']['input'];
};

/** select columns of table "url_field_values" */
export enum Url_Field_Values_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldType = 'field_type',
  /** column name */
  FieldValueId = 'field_value_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
}

/** input type for updating data in table "url_field_values" */
export type Url_Field_Values_Set_Input = {
  value?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "url_field_values" */
export enum Url_Field_Values_Update_Column {
  /** column name */
  Value = 'value',
}

export type Url_Field_Values_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Url_Field_Values_Set_Input>;
  /** filter the rows which have to be updated */
  where: Url_Field_Values_Bool_Exp;
};

/** columns and relationships of "user_controls_stats_view" */
export type User_Controls_Stats_View = {
  __typename?: 'user_controls_stats_view';
  controls_count?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']['output']>;
  valid_controls_count?: Maybe<Scalars['Int']['output']>;
  valid_controls_ratio?: Maybe<Scalars['float8']['output']>;
};

/** Boolean expression to filter rows from the table "user_controls_stats_view". All fields are combined with a logical 'AND'. */
export type User_Controls_Stats_View_Bool_Exp = {
  _and?: InputMaybe<Array<User_Controls_Stats_View_Bool_Exp>>;
  _not?: InputMaybe<User_Controls_Stats_View_Bool_Exp>;
  _or?: InputMaybe<Array<User_Controls_Stats_View_Bool_Exp>>;
  controls_count?: InputMaybe<Int_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  valid_controls_count?: InputMaybe<Int_Comparison_Exp>;
  valid_controls_ratio?: InputMaybe<Float8_Comparison_Exp>;
};

/** Ordering options when selecting data from "user_controls_stats_view". */
export type User_Controls_Stats_View_Order_By = {
  controls_count?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  valid_controls_count?: InputMaybe<Order_By>;
  valid_controls_ratio?: InputMaybe<Order_By>;
};

/** select columns of table "user_controls_stats_view" */
export enum User_Controls_Stats_View_Select_Column {
  /** column name */
  ControlsCount = 'controls_count',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  UserId = 'user_id',
  /** column name */
  ValidControlsCount = 'valid_controls_count',
  /** column name */
  ValidControlsRatio = 'valid_controls_ratio',
}

/** Streaming cursor of the table "user_controls_stats_view" */
export type User_Controls_Stats_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Controls_Stats_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Controls_Stats_View_Stream_Cursor_Value_Input = {
  controls_count?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
  valid_controls_count?: InputMaybe<Scalars['Int']['input']>;
  valid_controls_ratio?: InputMaybe<Scalars['float8']['input']>;
};

/** this table contains actual notification entry for a user for different delivery type */
export type User_Notifications = {
  __typename?: 'user_notifications';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at: Scalars['timestamptz']['output'];
  delivery_type: Notification_Delivery_Types_Enum;
  id: Scalars['uuid']['output'];
  /** An object relationship */
  notification: Notifications;
  notification_id: Scalars['uuid']['output'];
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid']['output'];
};

/** aggregated selection of "user_notifications" */
export type User_Notifications_Aggregate = {
  __typename?: 'user_notifications_aggregate';
  aggregate?: Maybe<User_Notifications_Aggregate_Fields>;
  nodes: Array<User_Notifications>;
};

export type User_Notifications_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Notifications_Aggregate_Bool_Exp_Count>;
};

export type User_Notifications_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Notifications_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_notifications" */
export type User_Notifications_Aggregate_Fields = {
  __typename?: 'user_notifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<User_Notifications_Max_Fields>;
  min?: Maybe<User_Notifications_Min_Fields>;
};

/** aggregate fields of "user_notifications" */
export type User_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_notifications" */
export type User_Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Notifications_Max_Order_By>;
  min?: InputMaybe<User_Notifications_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "user_notifications". All fields are combined with a logical 'AND'. */
export type User_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<User_Notifications_Bool_Exp>>;
  _not?: InputMaybe<User_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<User_Notifications_Bool_Exp>>;
  archived_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification?: InputMaybe<Notifications_Bool_Exp>;
  notification_id?: InputMaybe<Uuid_Comparison_Exp>;
  read_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** aggregate max on columns */
export type User_Notifications_Max_Fields = {
  __typename?: 'user_notifications_max_fields';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by max() on columns of table "user_notifications" */
export type User_Notifications_Max_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Notifications_Min_Fields = {
  __typename?: 'user_notifications_min_fields';
  archived_at?: Maybe<Scalars['timestamptz']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notification_id?: Maybe<Scalars['uuid']['output']>;
  read_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by min() on columns of table "user_notifications" */
export type User_Notifications_Min_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_notifications" */
export type User_Notifications_Mutation_Response = {
  __typename?: 'user_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Notifications>;
};

/** Ordering options when selecting data from "user_notifications". */
export type User_Notifications_Order_By = {
  archived_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  delivery_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification?: InputMaybe<Notifications_Order_By>;
  notification_id?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_notifications */
export type User_Notifications_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "user_notifications" */
export enum User_Notifications_Select_Column {
  /** column name */
  ArchivedAt = 'archived_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeliveryType = 'delivery_type',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  UserId = 'user_id',
}

/** input type for updating data in table "user_notifications" */
export type User_Notifications_Set_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "user_notifications" */
export type User_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Notifications_Stream_Cursor_Value_Input = {
  archived_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  delivery_type?: InputMaybe<Notification_Delivery_Types_Enum>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notification_id?: InputMaybe<Scalars['uuid']['input']>;
  read_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type User_Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Notifications_Bool_Exp;
};

/** columns and relationships of "user_tasks_stats_view" */
export type User_Tasks_Stats_View = {
  __typename?: 'user_tasks_stats_view';
  completed_tasks_count?: Maybe<Scalars['Int']['output']>;
  completed_tasks_ratio?: Maybe<Scalars['float8']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  tasks_count?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']['output']>;
};

/** Boolean expression to filter rows from the table "user_tasks_stats_view". All fields are combined with a logical 'AND'. */
export type User_Tasks_Stats_View_Bool_Exp = {
  _and?: InputMaybe<Array<User_Tasks_Stats_View_Bool_Exp>>;
  _not?: InputMaybe<User_Tasks_Stats_View_Bool_Exp>;
  _or?: InputMaybe<Array<User_Tasks_Stats_View_Bool_Exp>>;
  completed_tasks_count?: InputMaybe<Int_Comparison_Exp>;
  completed_tasks_ratio?: InputMaybe<Float8_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  tasks_count?: InputMaybe<Int_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** Ordering options when selecting data from "user_tasks_stats_view". */
export type User_Tasks_Stats_View_Order_By = {
  completed_tasks_count?: InputMaybe<Order_By>;
  completed_tasks_ratio?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  tasks_count?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** select columns of table "user_tasks_stats_view" */
export enum User_Tasks_Stats_View_Select_Column {
  /** column name */
  CompletedTasksCount = 'completed_tasks_count',
  /** column name */
  CompletedTasksRatio = 'completed_tasks_ratio',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  TasksCount = 'tasks_count',
  /** column name */
  UserId = 'user_id',
}

/** Streaming cursor of the table "user_tasks_stats_view" */
export type User_Tasks_Stats_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Tasks_Stats_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Tasks_Stats_View_Stream_Cursor_Value_Input = {
  completed_tasks_count?: InputMaybe<Scalars['Int']['input']>;
  completed_tasks_ratio?: InputMaybe<Scalars['float8']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  tasks_count?: InputMaybe<Scalars['Int']['input']>;
  user_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type Users = {
  __typename?: 'users';
  activeMfaType?: Maybe<Scalars['String']['output']>;
  avatarUrl: Scalars['String']['output'];
  /** An array relationship */
  client_questionnaire_answers: Array<Client_Questionnaire_Answers>;
  /** An array relationship */
  client_questionnaires: Array<Client_Questionnaires>;
  /** An array relationship */
  controlEvidenceVersionsByUpdatedBy: Array<Evidence_Versions>;
  /** An array relationship */
  control_evidence_versions: Array<Evidence_Versions>;
  /** An array relationship */
  control_evidences: Array<Evidences>;
  /** An aggregate relationship */
  control_evidences_aggregate: Evidences_Aggregate;
  /** An array relationship */
  controls: Array<Controls>;
  /** An aggregate relationship */
  controls_aggregate: Controls_Aggregate;
  /** An array relationship */
  controls_history_stats: Array<Controls_History_Stats>;
  createdAt: Scalars['timestamptz']['output'];
  disabled: Scalars['Boolean']['output'];
  displayName: Scalars['String']['output'];
  email?: Maybe<Scalars['citext']['output']>;
  /** An array relationship */
  evidence_acls: Array<Evidence_Acl>;
  id: Scalars['uuid']['output'];
  /** An array relationship */
  invitations: Array<Invitations>;
  joined_with_sso?: Maybe<Scalars['Boolean']['output']>;
  metadata: Scalars['UserMetaData']['output'];
  /** An array relationship */
  notification_disabled_settings: Array<Notification_Disabled_Settings>;
  /** An array relationship */
  organization_users: Array<Organization_Users>;
  /** An array relationship */
  policies: Array<Policies>;
  /** An array relationship */
  policy_acknowledgement_users: Array<Policy_Acknowledgement_Users>;
  /** An aggregate relationship */
  policy_acknowledgement_users_aggregate: Policy_Acknowledgement_Users_Aggregate;
  /** An array relationship */
  policy_acknowledgements: Array<Policy_Acknowledgements>;
  /** An array relationship */
  policy_approval_users: Array<Policy_Approval_Users>;
  /** An aggregate relationship */
  policy_approval_users_aggregate: Policy_Approval_Users_Aggregate;
  /** An array relationship */
  policy_approvals: Array<Policy_Approvals>;
  /** An array relationship */
  policy_approvers: Array<Policy_Approvers>;
  /** An array relationship */
  policy_versions: Array<Policy_Versions>;
  /** An array relationship */
  questionnaires: Array<Questionnaires>;
  /** An array relationship */
  refreshTokens: Array<AuthRefreshTokens>;
  /** An array relationship */
  reports: Array<Reports>;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An array relationship */
  risks: Array<Risks>;
  /** An aggregate relationship */
  risks_aggregate: Risks_Aggregate;
  /** An array relationship */
  task_owners: Array<Task_Owners>;
  /** An array relationship */
  user_notifications: Array<User_Notifications>;
  /** An aggregate relationship */
  user_notifications_aggregate: User_Notifications_Aggregate;
  /** An array relationship */
  users_comments: Array<Comments>;
  /** An array relationship */
  vendor_questionnaires_sent_by: Array<Vendor_Questionnaires>;
  /** An array relationship */
  vendor_questionnaires_sent_to: Array<Vendor_Questionnaires>;
  /** An array relationship */
  vendors: Array<Vendors>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersClient_Questionnaire_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaire_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaire_Answers_Order_By>>;
  where?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersClient_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Client_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Client_Questionnaires_Order_By>>;
  where?: InputMaybe<Client_Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControlEvidenceVersionsByUpdatedByArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControl_Evidence_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Versions_Order_By>>;
  where?: InputMaybe<Evidence_Versions_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControl_EvidencesArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControl_Evidences_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Evidences_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidences_Order_By>>;
  where?: InputMaybe<Evidences_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControlsArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControls_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Controls_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_Order_By>>;
  where?: InputMaybe<Controls_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersControls_History_StatsArgs = {
  distinct_on?: InputMaybe<Array<Controls_History_Stats_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Controls_History_Stats_Order_By>>;
  where?: InputMaybe<Controls_History_Stats_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersEvidence_AclsArgs = {
  distinct_on?: InputMaybe<Array<Evidence_Acl_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Evidence_Acl_Order_By>>;
  where?: InputMaybe<Evidence_Acl_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersInvitationsArgs = {
  distinct_on?: InputMaybe<Array<Invitations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Invitations_Order_By>>;
  where?: InputMaybe<Invitations_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersMetadataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersNotification_Disabled_SettingsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Disabled_Settings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notification_Disabled_Settings_Order_By>>;
  where?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersOrganization_UsersArgs = {
  distinct_on?: InputMaybe<Array<Organization_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Organization_Users_Order_By>>;
  where?: InputMaybe<Organization_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPoliciesArgs = {
  distinct_on?: InputMaybe<Array<Policies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policies_Order_By>>;
  where?: InputMaybe<Policies_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Acknowledgement_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Acknowledgement_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgement_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgement_Users_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_AcknowledgementsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Acknowledgements_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Acknowledgements_Order_By>>;
  where?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Approval_UsersArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_Approval_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approval_Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approval_Users_Order_By>>;
  where?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_ApprovalsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvals_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvals_Order_By>>;
  where?: InputMaybe<Policy_Approvals_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_ApproversArgs = {
  distinct_on?: InputMaybe<Array<Policy_Approvers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Approvers_Order_By>>;
  where?: InputMaybe<Policy_Approvers_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersPolicy_VersionsArgs = {
  distinct_on?: InputMaybe<Array<Policy_Versions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Policy_Versions_Order_By>>;
  where?: InputMaybe<Policy_Versions_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Questionnaires_Order_By>>;
  where?: InputMaybe<Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRefreshTokensArgs = {
  distinct_on?: InputMaybe<Array<AuthRefreshTokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<AuthRefreshTokens_Order_By>>;
  where?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersReportsArgs = {
  distinct_on?: InputMaybe<Array<Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reports_Order_By>>;
  where?: InputMaybe<Reports_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRisksArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersRisks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Risks_Order_By>>;
  where?: InputMaybe<Risks_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersTask_OwnersArgs = {
  distinct_on?: InputMaybe<Array<Task_Owners_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Task_Owners_Order_By>>;
  where?: InputMaybe<Task_Owners_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUser_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUser_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Notifications_Order_By>>;
  where?: InputMaybe<User_Notifications_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersUsers_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendor_Questionnaires_Sent_ByArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendor_Questionnaires_Sent_ToArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** User account information. Don't modify its structure as Hasura Auth relies on it to function properly. */
export type UsersVendorsArgs = {
  distinct_on?: InputMaybe<Array<Vendors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendors_Order_By>>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  metadata?: InputMaybe<Scalars['UserMetaData']['input']>;
};

/** Boolean expression to filter rows from the table "auth.users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  activeMfaType?: InputMaybe<String_Comparison_Exp>;
  avatarUrl?: InputMaybe<String_Comparison_Exp>;
  client_questionnaire_answers?: InputMaybe<Client_Questionnaire_Answers_Bool_Exp>;
  client_questionnaires?: InputMaybe<Client_Questionnaires_Bool_Exp>;
  controlEvidenceVersionsByUpdatedBy?: InputMaybe<Evidence_Versions_Bool_Exp>;
  control_evidence_versions?: InputMaybe<Evidence_Versions_Bool_Exp>;
  control_evidences?: InputMaybe<Evidences_Bool_Exp>;
  control_evidences_aggregate?: InputMaybe<Evidences_Aggregate_Bool_Exp>;
  controls?: InputMaybe<Controls_Bool_Exp>;
  controls_aggregate?: InputMaybe<Controls_Aggregate_Bool_Exp>;
  controls_history_stats?: InputMaybe<Controls_History_Stats_Bool_Exp>;
  createdAt?: InputMaybe<Timestamptz_Comparison_Exp>;
  disabled?: InputMaybe<Boolean_Comparison_Exp>;
  displayName?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  evidence_acls?: InputMaybe<Evidence_Acl_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  invitations?: InputMaybe<Invitations_Bool_Exp>;
  joined_with_sso?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  notification_disabled_settings?: InputMaybe<Notification_Disabled_Settings_Bool_Exp>;
  organization_users?: InputMaybe<Organization_Users_Bool_Exp>;
  policies?: InputMaybe<Policies_Bool_Exp>;
  policy_acknowledgement_users?: InputMaybe<Policy_Acknowledgement_Users_Bool_Exp>;
  policy_acknowledgement_users_aggregate?: InputMaybe<Policy_Acknowledgement_Users_Aggregate_Bool_Exp>;
  policy_acknowledgements?: InputMaybe<Policy_Acknowledgements_Bool_Exp>;
  policy_approval_users?: InputMaybe<Policy_Approval_Users_Bool_Exp>;
  policy_approval_users_aggregate?: InputMaybe<Policy_Approval_Users_Aggregate_Bool_Exp>;
  policy_approvals?: InputMaybe<Policy_Approvals_Bool_Exp>;
  policy_approvers?: InputMaybe<Policy_Approvers_Bool_Exp>;
  policy_versions?: InputMaybe<Policy_Versions_Bool_Exp>;
  questionnaires?: InputMaybe<Questionnaires_Bool_Exp>;
  refreshTokens?: InputMaybe<AuthRefreshTokens_Bool_Exp>;
  reports?: InputMaybe<Reports_Bool_Exp>;
  reviews?: InputMaybe<Reviews_Bool_Exp>;
  risks?: InputMaybe<Risks_Bool_Exp>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Bool_Exp>;
  task_owners?: InputMaybe<Task_Owners_Bool_Exp>;
  user_notifications?: InputMaybe<User_Notifications_Bool_Exp>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Bool_Exp>;
  users_comments?: InputMaybe<Comments_Bool_Exp>;
  vendor_questionnaires_sent_by?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaires_sent_to?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendors?: InputMaybe<Vendors_Bool_Exp>;
};

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']['input']>;
};

/** response of any mutation on the table "auth.users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** Ordering options when selecting data from "auth.users". */
export type Users_Order_By = {
  activeMfaType?: InputMaybe<Order_By>;
  avatarUrl?: InputMaybe<Order_By>;
  client_questionnaire_answers_aggregate?: InputMaybe<Client_Questionnaire_Answers_Aggregate_Order_By>;
  client_questionnaires_aggregate?: InputMaybe<Client_Questionnaires_Aggregate_Order_By>;
  controlEvidenceVersionsByUpdatedBy_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Order_By>;
  control_evidence_versions_aggregate?: InputMaybe<Evidence_Versions_Aggregate_Order_By>;
  control_evidences_aggregate?: InputMaybe<Evidences_Aggregate_Order_By>;
  controls_aggregate?: InputMaybe<Controls_Aggregate_Order_By>;
  controls_history_stats_aggregate?: InputMaybe<Controls_History_Stats_Aggregate_Order_By>;
  createdAt?: InputMaybe<Order_By>;
  disabled?: InputMaybe<Order_By>;
  displayName?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  evidence_acls_aggregate?: InputMaybe<Evidence_Acl_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  invitations_aggregate?: InputMaybe<Invitations_Aggregate_Order_By>;
  joined_with_sso?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  notification_disabled_settings_aggregate?: InputMaybe<Notification_Disabled_Settings_Aggregate_Order_By>;
  organization_users_aggregate?: InputMaybe<Organization_Users_Aggregate_Order_By>;
  policies_aggregate?: InputMaybe<Policies_Aggregate_Order_By>;
  policy_acknowledgement_users_aggregate?: InputMaybe<Policy_Acknowledgement_Users_Aggregate_Order_By>;
  policy_acknowledgements_aggregate?: InputMaybe<Policy_Acknowledgements_Aggregate_Order_By>;
  policy_approval_users_aggregate?: InputMaybe<Policy_Approval_Users_Aggregate_Order_By>;
  policy_approvals_aggregate?: InputMaybe<Policy_Approvals_Aggregate_Order_By>;
  policy_approvers_aggregate?: InputMaybe<Policy_Approvers_Aggregate_Order_By>;
  policy_versions_aggregate?: InputMaybe<Policy_Versions_Aggregate_Order_By>;
  questionnaires_aggregate?: InputMaybe<Questionnaires_Aggregate_Order_By>;
  refreshTokens_aggregate?: InputMaybe<AuthRefreshTokens_Aggregate_Order_By>;
  reports_aggregate?: InputMaybe<Reports_Aggregate_Order_By>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  risks_aggregate?: InputMaybe<Risks_Aggregate_Order_By>;
  task_owners_aggregate?: InputMaybe<Task_Owners_Aggregate_Order_By>;
  user_notifications_aggregate?: InputMaybe<User_Notifications_Aggregate_Order_By>;
  users_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  vendor_questionnaires_sent_by_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
  vendor_questionnaires_sent_to_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
  vendors_aggregate?: InputMaybe<Vendors_Aggregate_Order_By>;
};

/** primary key columns input for table: auth.users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "auth.users" */
export enum Users_Select_Column {
  /** column name */
  ActiveMfaType = 'activeMfaType',
  /** column name */
  AvatarUrl = 'avatarUrl',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Disabled = 'disabled',
  /** column name */
  DisplayName = 'displayName',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  JoinedWithSso = 'joined_with_sso',
  /** column name */
  Metadata = 'metadata',
}

/** input type for updating data in table "auth.users" */
export type Users_Set_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  activeMfaType?: InputMaybe<Scalars['String']['input']>;
  avatarUrl?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['timestamptz']['input']>;
  disabled?: InputMaybe<Scalars['Boolean']['input']>;
  displayName?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['citext']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  joined_with_sso?: InputMaybe<Scalars['Boolean']['input']>;
  metadata?: InputMaybe<Scalars['jsonb']['input']>;
};

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** Boolean expression to compare columns of type "vector". All fields are combined with logical 'AND'. */
export type Vector_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['vector']['input']>;
  _gt?: InputMaybe<Scalars['vector']['input']>;
  _gte?: InputMaybe<Scalars['vector']['input']>;
  _in?: InputMaybe<Array<Scalars['vector']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['vector']['input']>;
  _lte?: InputMaybe<Scalars['vector']['input']>;
  _neq?: InputMaybe<Scalars['vector']['input']>;
  _nin?: InputMaybe<Array<Scalars['vector']['input']>>;
};

export enum Vendor_Approval_Statuses_Enum {
  Accepted = 'accepted',
  Pending = 'pending',
  Rejected = 'rejected',
}

/** Boolean expression to compare columns of type "vendor_approval_statuses_enum". All fields are combined with logical 'AND'. */
export type Vendor_Approval_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vendor_Approval_Statuses_Enum>;
  _in?: InputMaybe<Array<Vendor_Approval_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Vendor_Approval_Statuses_Enum>;
  _nin?: InputMaybe<Array<Vendor_Approval_Statuses_Enum>>;
};

/** columns and relationships of "vendor_documents" */
export type Vendor_Documents = {
  __typename?: 'vendor_documents';
  /** An object relationship */
  file?: Maybe<Files>;
  file_id: Scalars['uuid']['output'];
  /** An object relationship */
  vendor?: Maybe<Vendors>;
  vendor_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "vendor_documents" */
export type Vendor_Documents_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Documents_Max_Order_By>;
  min?: InputMaybe<Vendor_Documents_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_documents" */
export type Vendor_Documents_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Documents_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Documents_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_documents". All fields are combined with a logical 'AND'. */
export type Vendor_Documents_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Documents_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Documents_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Documents_Bool_Exp>>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_documents" */
export enum Vendor_Documents_Constraint {
  /** unique or primary key constraint on columns "file_id" */
  VendorDocumentsFileIdKey = 'vendor_documents_file_id_key',
  /** unique or primary key constraint on columns "vendor_id", "file_id" */
  VendorDocumentsPkey = 'vendor_documents_pkey',
}

/** input type for inserting data into table "vendor_documents" */
export type Vendor_Documents_Insert_Input = {
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "vendor_documents" */
export type Vendor_Documents_Max_Order_By = {
  file_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_documents" */
export type Vendor_Documents_Min_Order_By = {
  file_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_documents" */
export type Vendor_Documents_Mutation_Response = {
  __typename?: 'vendor_documents_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Documents>;
};

/** input type for inserting object relation for remote table "vendor_documents" */
export type Vendor_Documents_Obj_Rel_Insert_Input = {
  data: Vendor_Documents_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Documents_On_Conflict>;
};

/** on_conflict condition type for table "vendor_documents" */
export type Vendor_Documents_On_Conflict = {
  constraint: Vendor_Documents_Constraint;
  update_columns?: Array<Vendor_Documents_Update_Column>;
  where?: InputMaybe<Vendor_Documents_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_documents". */
export type Vendor_Documents_Order_By = {
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "vendor_documents" */
export enum Vendor_Documents_Select_Column {
  /** column name */
  FileId = 'file_id',
  /** column name */
  VendorId = 'vendor_id',
}

/** placeholder for update columns of table "vendor_documents" (current role has no relevant permissions) */
export enum Vendor_Documents_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** Set of relevant findings from humans and AI that need to be highlighted for vendors */
export type Vendor_Findings = {
  __typename?: 'vendor_findings';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  ignored_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  risk?: Maybe<Risks>;
  risk_id?: Maybe<Scalars['uuid']['output']>;
  /** An object relationship */
  task?: Maybe<Tasks>;
  task_id?: Maybe<Scalars['uuid']['output']>;
  type: Finding_Types_Enum;
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor: Vendors;
  vendor_id: Scalars['uuid']['output'];
  /** An object relationship */
  vendor_questionnaire?: Maybe<Vendor_Questionnaires>;
  /** An object relationship */
  vendor_questionnaire_ai_review_assessment?: Maybe<Vendor_Questionnaire_Ai_Review_Assessments>;
  vendor_questionnaire_ai_review_assessment_id?: Maybe<Scalars['uuid']['output']>;
  vendor_questionnaire_id?: Maybe<Scalars['uuid']['output']>;
};

/** order by aggregate values of table "vendor_findings" */
export type Vendor_Findings_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Findings_Max_Order_By>;
  min?: InputMaybe<Vendor_Findings_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "vendor_findings". All fields are combined with a logical 'AND'. */
export type Vendor_Findings_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Findings_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Findings_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Findings_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  ignored_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<Finding_Types_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaire_ai_review_assessment?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "vendor_findings" */
export type Vendor_Findings_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_findings" */
export type Vendor_Findings_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_findings" */
export type Vendor_Findings_Mutation_Response = {
  __typename?: 'vendor_findings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Findings>;
};

/** Ordering options when selecting data from "vendor_findings". */
export type Vendor_Findings_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  ignored_at?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  vendor_questionnaire_ai_review_assessment?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>;
  vendor_questionnaire_ai_review_assessment_id?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: vendor_findings */
export type Vendor_Findings_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_findings" */
export enum Vendor_Findings_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IgnoredAt = 'ignored_at',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
  /** column name */
  VendorQuestionnaireAiReviewAssessmentId = 'vendor_questionnaire_ai_review_assessment_id',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

/** input type for updating data in table "vendor_findings" */
export type Vendor_Findings_Set_Input = {
  ignored_at?: InputMaybe<Scalars['timestamptz']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type Vendor_Findings_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Findings_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Findings_Bool_Exp;
};

/** Assessments to individual questions based on AI Criteria */
export type Vendor_Questionnaire_Ai_Review_Assessments = {
  __typename?: 'vendor_questionnaire_ai_review_assessments';
  assessment: Scalars['String']['output'];
  created_at: Scalars['timestamptz']['output'];
  criteria: Scalars['String']['output'];
  criteria_heading: Scalars['String']['output'];
  high_confidence: Scalars['Boolean']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  vendor_finding?: Maybe<Vendor_Findings>;
  vendor_questionnaire_ai_review_run_id: Scalars['uuid']['output'];
  /** An object relationship */
  vendor_questionnaire_form_answer: Vendor_Questionnaire_Form_Answers;
  vendor_questionnaire_form_answer_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_ai_review_assessments". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>>;
  assessment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  criteria?: InputMaybe<String_Comparison_Exp>;
  criteria_heading?: InputMaybe<String_Comparison_Exp>;
  high_confidence?: InputMaybe<Boolean_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_finding?: InputMaybe<Vendor_Findings_Bool_Exp>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaire_form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Max_Order_By = {
  assessment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria?: InputMaybe<Order_By>;
  criteria_heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_questionnaire_ai_review_assessments" */
export type Vendor_Questionnaire_Ai_Review_Assessments_Min_Order_By = {
  assessment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria?: InputMaybe<Order_By>;
  criteria_heading?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "vendor_questionnaire_ai_review_assessments". */
export type Vendor_Questionnaire_Ai_Review_Assessments_Order_By = {
  assessment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  criteria?: InputMaybe<Order_By>;
  criteria_heading?: InputMaybe<Order_By>;
  high_confidence?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  vendor_finding?: InputMaybe<Vendor_Findings_Order_By>;
  vendor_questionnaire_ai_review_run_id?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** select columns of table "vendor_questionnaire_ai_review_assessments" */
export enum Vendor_Questionnaire_Ai_Review_Assessments_Select_Column {
  /** column name */
  Assessment = 'assessment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Criteria = 'criteria',
  /** column name */
  CriteriaHeading = 'criteria_heading',
  /** column name */
  HighConfidence = 'high_confidence',
  /** column name */
  Id = 'id',
  /** column name */
  VendorQuestionnaireAiReviewRunId = 'vendor_questionnaire_ai_review_run_id',
  /** column name */
  VendorQuestionnaireFormAnswerId = 'vendor_questionnaire_form_answer_id',
}

/** columns and relationships of "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses = {
  __typename?: 'vendor_questionnaire_form_answer_statuses';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  /** An array relationship */
  vendor_questionnaire_form_answers: Array<Vendor_Questionnaire_Form_Answers>;
};

/** columns and relationships of "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_StatusesVendor_Questionnaire_Form_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_form_answer_statuses". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  vendor_questionnaire_form_answers?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

export enum Vendor_Questionnaire_Form_Answer_Statuses_Enum {
  /** An answer which has been marked as completed */
  Completed = 'completed',
  /** An answer which may have data but not marked as completed yet */
  Pending = 'pending',
}

/** Boolean expression to compare columns of type "vendor_questionnaire_form_answer_statuses_enum". All fields are combined with logical 'AND'. */
export type Vendor_Questionnaire_Form_Answer_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  _in?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  _nin?: InputMaybe<Array<Vendor_Questionnaire_Form_Answer_Statuses_Enum>>;
};

/** Ordering options when selecting data from "vendor_questionnaire_form_answer_statuses". */
export type Vendor_Questionnaire_Form_Answer_Statuses_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answers_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Answers_Aggregate_Order_By>;
};

/** select columns of table "vendor_questionnaire_form_answer_statuses" */
export enum Vendor_Questionnaire_Form_Answer_Statuses_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** Streaming cursor of the table "vendor_questionnaire_form_answer_statuses" */
export type Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Form_Answer_Statuses_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers = {
  __typename?: 'vendor_questionnaire_form_answers';
  comment?: Maybe<Scalars['String']['output']>;
  created_at: Scalars['timestamptz']['output'];
  field_name: Scalars['String']['output'];
  /** An object relationship */
  form: Vendor_Questionnaire_Forms;
  /** An object relationship */
  form_answer_status: Vendor_Questionnaire_Form_Answer_Statuses;
  /** An array relationship */
  form_uploads: Array<Vendor_Questionnaire_Form_Uploads>;
  id: Scalars['uuid']['output'];
  status: Vendor_Questionnaire_Form_Answer_Statuses_Enum;
  updated_at: Scalars['timestamptz']['output'];
  value?: Maybe<Scalars['VendorQuestionnaireFormAnswerValue']['output']>;
  /** An array relationship */
  vendor_questionnaire_ai_review_assessments: Array<Vendor_Questionnaire_Ai_Review_Assessments>;
  vendor_questionnaire_form_id: Scalars['uuid']['output'];
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_AnswersForm_UploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_AnswersValueArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_AnswersVendor_Questionnaire_Ai_Review_AssessmentsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Ai_Review_Assessments_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
};

/** order by aggregate values of table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Form_Answers_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Form_Answers_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_form_answers". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Form_Answers_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_name?: InputMaybe<String_Comparison_Exp>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
  form_answer_status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Bool_Exp>;
  form_uploads?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Jsonb_Comparison_Exp>;
  vendor_questionnaire_ai_review_assessments?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Bool_Exp>;
  vendor_questionnaire_form_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "vendor_questionnaire_form_answers". */
export type Vendor_Questionnaire_Form_Answers_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  field_name?: InputMaybe<Order_By>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Order_By>;
  form_answer_status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Order_By>;
  form_uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  vendor_questionnaire_ai_review_assessments_aggregate?: InputMaybe<Vendor_Questionnaire_Ai_Review_Assessments_Aggregate_Order_By>;
  vendor_questionnaire_form_id?: InputMaybe<Order_By>;
};

/** select columns of table "vendor_questionnaire_form_answers" */
export enum Vendor_Questionnaire_Form_Answers_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FieldName = 'field_name',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value',
  /** column name */
  VendorQuestionnaireFormId = 'vendor_questionnaire_form_id',
}

/** Streaming cursor of the table "vendor_questionnaire_form_answers" */
export type Vendor_Questionnaire_Form_Answers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Form_Answers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Form_Answers_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Form_Answer_Statuses_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  value?: InputMaybe<Scalars['jsonb']['input']>;
  vendor_questionnaire_form_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads = {
  __typename?: 'vendor_questionnaire_form_uploads';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  /** An object relationship */
  form_answer: Vendor_Questionnaire_Form_Answers;
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  vendor_questionnaire_form_answer_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_form_uploads". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Form_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Form_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Form_Uploads_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** order by max() on columns of table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "vendor_questionnaire_form_uploads". */
export type Vendor_Questionnaire_Form_Uploads_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  form_answer?: InputMaybe<Vendor_Questionnaire_Form_Answers_Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Order_By>;
};

/** select columns of table "vendor_questionnaire_form_uploads" */
export enum Vendor_Questionnaire_Form_Uploads_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireFormAnswerId = 'vendor_questionnaire_form_answer_id',
}

/** Streaming cursor of the table "vendor_questionnaire_form_uploads" */
export type Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Form_Uploads_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  file_id?: InputMaybe<Scalars['uuid']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_form_answer_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** columns and relationships of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms = {
  __typename?: 'vendor_questionnaire_forms';
  /** An array relationship */
  answers: Array<Vendor_Questionnaire_Form_Answers>;
  config_snapshot?: Maybe<Scalars['FormConfig']['output']>;
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_questionnaire: Vendor_Questionnaires;
  vendor_questionnaire_id: Scalars['uuid']['output'];
};

/** columns and relationships of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_FormsAnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Form_Answers_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_FormsConfig_SnapshotArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_forms". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Forms_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Forms_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Forms_Bool_Exp>>;
  answers?: InputMaybe<Vendor_Questionnaire_Form_Answers_Bool_Exp>;
  config_snapshot?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_questionnaire_forms" */
export enum Vendor_Questionnaire_Forms_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorQuestionnaireFormsPkey = 'vendor_questionnaire_forms_pkey',
  /** unique or primary key constraint on columns "vendor_questionnaire_id" */
  VendorQuestionnaireFormsVendorQuestionnaireIdKey = 'vendor_questionnaire_forms_vendor_questionnaire_id_key',
}

/** input type for inserting data into table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Insert_Input = {
  config_snapshot?: InputMaybe<Scalars['jsonb']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** response of any mutation on the table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Mutation_Response = {
  __typename?: 'vendor_questionnaire_forms_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Forms>;
};

/** on_conflict condition type for table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_On_Conflict = {
  constraint: Vendor_Questionnaire_Forms_Constraint;
  update_columns?: Array<Vendor_Questionnaire_Forms_Update_Column>;
  where?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_questionnaire_forms". */
export type Vendor_Questionnaire_Forms_Order_By = {
  answers_aggregate?: InputMaybe<Vendor_Questionnaire_Form_Answers_Aggregate_Order_By>;
  config_snapshot?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** select columns of table "vendor_questionnaire_forms" */
export enum Vendor_Questionnaire_Forms_Select_Column {
  /** column name */
  ConfigSnapshot = 'config_snapshot',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

/** Streaming cursor of the table "vendor_questionnaire_forms" */
export type Vendor_Questionnaire_Forms_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Forms_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Forms_Stream_Cursor_Value_Input = {
  config_snapshot?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "vendor_questionnaire_forms" (current role has no relevant permissions) */
export enum Vendor_Questionnaire_Forms_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses = {
  __typename?: 'vendor_questionnaire_statuses';
  comment?: Maybe<Scalars['String']['output']>;
  value: Scalars['String']['output'];
  /** An array relationship */
  vendor_questionnaires: Array<Vendor_Questionnaires>;
};

/** columns and relationships of "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_StatusesVendor_QuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_statuses". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
  vendor_questionnaires?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

export enum Vendor_Questionnaire_Statuses_Enum {
  /** The questionnaire has been created for a vendor and awaiting to be sent */
  Created = 'created',
  /** The questionnaire is due to expire */
  Expiring = 'expiring',
  /** The questionnaire is overdue and not submitted */
  Overdue = 'overdue',
  /** The questionnaire has been submitted to a vendor and awaiting their answer */
  Pending = 'pending',
  /** pending questionnaire are revoked when vendor is terminated or retired */
  Revoked = 'revoked',
  /** The questionnaire answer has been submitted by a vendor */
  Submitted = 'submitted',
}

/** Boolean expression to compare columns of type "vendor_questionnaire_statuses_enum". All fields are combined with logical 'AND'. */
export type Vendor_Questionnaire_Statuses_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  _in?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  _nin?: InputMaybe<Array<Vendor_Questionnaire_Statuses_Enum>>;
};

/** Ordering options when selecting data from "vendor_questionnaire_statuses". */
export type Vendor_Questionnaire_Statuses_Order_By = {
  comment?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
  vendor_questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
};

/** select columns of table "vendor_questionnaire_statuses" */
export enum Vendor_Questionnaire_Statuses_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  Value = 'value',
}

/** Streaming cursor of the table "vendor_questionnaire_statuses" */
export type Vendor_Questionnaire_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaire_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaire_Statuses_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  value?: InputMaybe<Scalars['String']['input']>;
};

/** columns and relationships of "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads = {
  __typename?: 'vendor_questionnaire_uploads';
  created_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid']['output'];
  id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor_questionnaire: Vendor_Questionnaires;
  vendor_questionnaire_id: Scalars['uuid']['output'];
  /** An array relationship */
  vendor_questionnaires_answers: Array<Vendor_Questionnaires>;
};

/** columns and relationships of "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_UploadsVendor_Questionnaires_AnswersArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** order by aggregate values of table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaire_Uploads_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaire_Uploads_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaire_uploads". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaire_Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  vendor_questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaires_answers?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** order by max() on columns of table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaire_uploads" */
export type Vendor_Questionnaire_Uploads_Mutation_Response = {
  __typename?: 'vendor_questionnaire_uploads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaire_Uploads>;
};

/** Ordering options when selecting data from "vendor_questionnaire_uploads". */
export type Vendor_Questionnaire_Uploads_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_questionnaire?: InputMaybe<Vendor_Questionnaires_Order_By>;
  vendor_questionnaire_id?: InputMaybe<Order_By>;
  vendor_questionnaires_answers_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
};

/** select columns of table "vendor_questionnaire_uploads" */
export enum Vendor_Questionnaire_Uploads_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorQuestionnaireId = 'vendor_questionnaire_id',
}

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_Questionnaires = {
  __typename?: 'vendor_questionnaires';
  /** An object relationship */
  answer_upload?: Maybe<Vendor_Questionnaire_Uploads>;
  answer_upload_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  comments: Array<Comments>;
  created_at: Scalars['timestamptz']['output'];
  custom_message?: Maybe<Scalars['String']['output']>;
  due_date?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  form?: Maybe<Vendor_Questionnaire_Forms>;
  id: Scalars['uuid']['output'];
  is_disabled: Scalars['Boolean']['output'];
  /** An object relationship */
  questionnaire: Questionnaires;
  questionnaire_id: Scalars['uuid']['output'];
  /** An object relationship */
  questionnaire_status: Vendor_Questionnaire_Statuses;
  /** An object relationship */
  sent_by_user: Users;
  sent_by_user_id: Scalars['uuid']['output'];
  sent_to_email: Scalars['String']['output'];
  /** An object relationship */
  sent_to_user?: Maybe<Users>;
  sent_to_user_id?: Maybe<Scalars['uuid']['output']>;
  status: Vendor_Questionnaire_Statuses_Enum;
  subject?: Maybe<Scalars['String']['output']>;
  updated_at: Scalars['timestamptz']['output'];
  /** An array relationship */
  uploads: Array<Vendor_Questionnaire_Uploads>;
  /** An object relationship */
  vendor: Vendors;
  /** An array relationship */
  vendor_findings: Array<Vendor_Findings>;
  vendor_id: Scalars['uuid']['output'];
  /** An array relationship */
  vendor_questionnaires_notifications: Array<Notifications>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesUploadsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaire_Uploads_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesVendor_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Findings_Order_By>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

/** columns and relationships of "vendor_questionnaires" */
export type Vendor_QuestionnairesVendor_Questionnaires_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** order by aggregate values of table "vendor_questionnaires" */
export type Vendor_Questionnaires_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Questionnaires_Max_Order_By>;
  min?: InputMaybe<Vendor_Questionnaires_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "vendor_questionnaires". All fields are combined with a logical 'AND'. */
export type Vendor_Questionnaires_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Questionnaires_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Questionnaires_Bool_Exp>>;
  answer_upload?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
  answer_upload_id?: InputMaybe<Uuid_Comparison_Exp>;
  comments?: InputMaybe<Comments_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_message?: InputMaybe<String_Comparison_Exp>;
  due_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_disabled?: InputMaybe<Boolean_Comparison_Exp>;
  questionnaire?: InputMaybe<Questionnaires_Bool_Exp>;
  questionnaire_id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaire_status?: InputMaybe<Vendor_Questionnaire_Statuses_Bool_Exp>;
  sent_by_user?: InputMaybe<Users_Bool_Exp>;
  sent_by_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  sent_to_email?: InputMaybe<String_Comparison_Exp>;
  sent_to_user?: InputMaybe<Users_Bool_Exp>;
  sent_to_user_id?: InputMaybe<Uuid_Comparison_Exp>;
  status?: InputMaybe<Vendor_Questionnaire_Statuses_Enum_Comparison_Exp>;
  subject?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploads?: InputMaybe<Vendor_Questionnaire_Uploads_Bool_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_findings?: InputMaybe<Vendor_Findings_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor_questionnaires_notifications?: InputMaybe<Notifications_Bool_Exp>;
};

/** order by max() on columns of table "vendor_questionnaires" */
export type Vendor_Questionnaires_Max_Order_By = {
  answer_upload_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_message?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  sent_by_user_id?: InputMaybe<Order_By>;
  sent_to_email?: InputMaybe<Order_By>;
  sent_to_user_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_questionnaires" */
export type Vendor_Questionnaires_Min_Order_By = {
  answer_upload_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_message?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  sent_by_user_id?: InputMaybe<Order_By>;
  sent_to_email?: InputMaybe<Order_By>;
  sent_to_user_id?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_questionnaires" */
export type Vendor_Questionnaires_Mutation_Response = {
  __typename?: 'vendor_questionnaires_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Questionnaires>;
};

/** Ordering options when selecting data from "vendor_questionnaires". */
export type Vendor_Questionnaires_Order_By = {
  answer_upload?: InputMaybe<Vendor_Questionnaire_Uploads_Order_By>;
  answer_upload_id?: InputMaybe<Order_By>;
  comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_message?: InputMaybe<Order_By>;
  due_date?: InputMaybe<Order_By>;
  form?: InputMaybe<Vendor_Questionnaire_Forms_Order_By>;
  id?: InputMaybe<Order_By>;
  is_disabled?: InputMaybe<Order_By>;
  questionnaire?: InputMaybe<Questionnaires_Order_By>;
  questionnaire_id?: InputMaybe<Order_By>;
  questionnaire_status?: InputMaybe<Vendor_Questionnaire_Statuses_Order_By>;
  sent_by_user?: InputMaybe<Users_Order_By>;
  sent_by_user_id?: InputMaybe<Order_By>;
  sent_to_email?: InputMaybe<Order_By>;
  sent_to_user?: InputMaybe<Users_Order_By>;
  sent_to_user_id?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  subject?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploads_aggregate?: InputMaybe<Vendor_Questionnaire_Uploads_Aggregate_Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_findings_aggregate?: InputMaybe<Vendor_Findings_Aggregate_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
  vendor_questionnaires_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
};

/** primary key columns input for table: vendor_questionnaires */
export type Vendor_Questionnaires_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendor_questionnaires" */
export enum Vendor_Questionnaires_Select_Column {
  /** column name */
  AnswerUploadId = 'answer_upload_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomMessage = 'custom_message',
  /** column name */
  DueDate = 'due_date',
  /** column name */
  Id = 'id',
  /** column name */
  IsDisabled = 'is_disabled',
  /** column name */
  QuestionnaireId = 'questionnaire_id',
  /** column name */
  SentByUserId = 'sent_by_user_id',
  /** column name */
  SentToEmail = 'sent_to_email',
  /** column name */
  SentToUserId = 'sent_to_user_id',
  /** column name */
  Status = 'status',
  /** column name */
  Subject = 'subject',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
}

/** input type for updating data in table "vendor_questionnaires" */
export type Vendor_Questionnaires_Set_Input = {
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
};

/** Streaming cursor of the table "vendor_questionnaires" */
export type Vendor_Questionnaires_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Questionnaires_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Questionnaires_Stream_Cursor_Value_Input = {
  answer_upload_id?: InputMaybe<Scalars['uuid']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  custom_message?: InputMaybe<Scalars['String']['input']>;
  due_date?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  is_disabled?: InputMaybe<Scalars['Boolean']['input']>;
  questionnaire_id?: InputMaybe<Scalars['uuid']['input']>;
  sent_by_user_id?: InputMaybe<Scalars['uuid']['input']>;
  sent_to_email?: InputMaybe<Scalars['String']['input']>;
  sent_to_user_id?: InputMaybe<Scalars['uuid']['input']>;
  status?: InputMaybe<Vendor_Questionnaire_Statuses_Enum>;
  subject?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

export type Vendor_Questionnaires_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendor_Questionnaires_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendor_Questionnaires_Bool_Exp;
};

/** columns and relationships of "vendor_risks" */
export type Vendor_Risks = {
  __typename?: 'vendor_risks';
  created_at: Scalars['timestamptz']['output'];
  id: Scalars['uuid']['output'];
  /** An object relationship */
  risk: Risks;
  risk_id: Scalars['uuid']['output'];
  updated_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  vendor: Vendors;
  vendor_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "vendor_risks" */
export type Vendor_Risks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Risks_Max_Order_By>;
  min?: InputMaybe<Vendor_Risks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_risks" */
export type Vendor_Risks_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Risks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Risks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_risks". All fields are combined with a logical 'AND'. */
export type Vendor_Risks_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Risks_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Risks_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Risks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  risk?: InputMaybe<Risks_Bool_Exp>;
  risk_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_risks" */
export enum Vendor_Risks_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorRisksPkey = 'vendor_risks_pkey',
  /** unique or primary key constraint on columns "vendor_id", "risk_id" */
  VendorRisksVendorIdRiskIdKey = 'vendor_risks_vendor_id_risk_id_key',
}

/** input type for inserting data into table "vendor_risks" */
export type Vendor_Risks_Insert_Input = {
  risk?: InputMaybe<Risks_Obj_Rel_Insert_Input>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "vendor_risks" */
export type Vendor_Risks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_risks" */
export type Vendor_Risks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_risks" */
export type Vendor_Risks_Mutation_Response = {
  __typename?: 'vendor_risks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Risks>;
};

/** on_conflict condition type for table "vendor_risks" */
export type Vendor_Risks_On_Conflict = {
  constraint: Vendor_Risks_Constraint;
  update_columns?: Array<Vendor_Risks_Update_Column>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_risks". */
export type Vendor_Risks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  risk?: InputMaybe<Risks_Order_By>;
  risk_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "vendor_risks" */
export enum Vendor_Risks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RiskId = 'risk_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VendorId = 'vendor_id',
}

/** Streaming cursor of the table "vendor_risks" */
export type Vendor_Risks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendor_Risks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendor_Risks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  risk_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** placeholder for update columns of table "vendor_risks" (current role has no relevant permissions) */
export enum Vendor_Risks_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** Stores list of tasks linked with vendors */
export type Vendor_Tasks = {
  __typename?: 'vendor_tasks';
  id: Scalars['uuid']['output'];
  /** An object relationship */
  task: Tasks;
  task_id: Scalars['uuid']['output'];
  /** An object relationship */
  vendor: Vendors;
  vendor_id: Scalars['uuid']['output'];
};

/** order by aggregate values of table "vendor_tasks" */
export type Vendor_Tasks_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendor_Tasks_Max_Order_By>;
  min?: InputMaybe<Vendor_Tasks_Min_Order_By>;
};

/** input type for inserting array relation for remote table "vendor_tasks" */
export type Vendor_Tasks_Arr_Rel_Insert_Input = {
  data: Array<Vendor_Tasks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendor_Tasks_On_Conflict>;
};

/** Boolean expression to filter rows from the table "vendor_tasks". All fields are combined with a logical 'AND'. */
export type Vendor_Tasks_Bool_Exp = {
  _and?: InputMaybe<Array<Vendor_Tasks_Bool_Exp>>;
  _not?: InputMaybe<Vendor_Tasks_Bool_Exp>;
  _or?: InputMaybe<Array<Vendor_Tasks_Bool_Exp>>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  task?: InputMaybe<Tasks_Bool_Exp>;
  task_id?: InputMaybe<Uuid_Comparison_Exp>;
  vendor?: InputMaybe<Vendors_Bool_Exp>;
  vendor_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "vendor_tasks" */
export enum Vendor_Tasks_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorTasksPkey = 'vendor_tasks_pkey',
  /** unique or primary key constraint on columns "vendor_id", "task_id" */
  VendorTasksVendorIdTaskIdKey = 'vendor_tasks_vendor_id_task_id_key',
}

/** input type for inserting data into table "vendor_tasks" */
export type Vendor_Tasks_Insert_Input = {
  task?: InputMaybe<Tasks_Obj_Rel_Insert_Input>;
  task_id?: InputMaybe<Scalars['uuid']['input']>;
  vendor?: InputMaybe<Vendors_Obj_Rel_Insert_Input>;
  vendor_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by max() on columns of table "vendor_tasks" */
export type Vendor_Tasks_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendor_tasks" */
export type Vendor_Tasks_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  task_id?: InputMaybe<Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendor_tasks" */
export type Vendor_Tasks_Mutation_Response = {
  __typename?: 'vendor_tasks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendor_Tasks>;
};

/** on_conflict condition type for table "vendor_tasks" */
export type Vendor_Tasks_On_Conflict = {
  constraint: Vendor_Tasks_Constraint;
  update_columns?: Array<Vendor_Tasks_Update_Column>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

/** Ordering options when selecting data from "vendor_tasks". */
export type Vendor_Tasks_Order_By = {
  id?: InputMaybe<Order_By>;
  task?: InputMaybe<Tasks_Order_By>;
  task_id?: InputMaybe<Order_By>;
  vendor?: InputMaybe<Vendors_Order_By>;
  vendor_id?: InputMaybe<Order_By>;
};

/** select columns of table "vendor_tasks" */
export enum Vendor_Tasks_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TaskId = 'task_id',
  /** column name */
  VendorId = 'vendor_id',
}

/** placeholder for update columns of table "vendor_tasks" (current role has no relevant permissions) */
export enum Vendor_Tasks_Update_Column {
  /** placeholder (do not use) */
  Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "vendors" */
export type Vendors = {
  __typename?: 'vendors';
  approval_status?: Maybe<Vendor_Approval_Statuses_Enum>;
  assessment_status: Scalars['VendorAssessmentStatus']['output'];
  contact_email?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  documents: Array<Vendor_Documents>;
  /** An array relationship */
  field_values: Array<Field_Values>;
  id: Scalars['uuid']['output'];
  internal_id?: Maybe<Scalars['String']['output']>;
  /** A computed field, executes function "get_vendor_last_review_date" */
  last_review_date?: Maybe<Scalars['timestamptz']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  organization?: Maybe<Organizations>;
  organization_id: Scalars['uuid']['output'];
  /** An object relationship */
  owner?: Maybe<Users>;
  owner_id?: Maybe<Scalars['uuid']['output']>;
  /** An array relationship */
  questionnaires: Array<Vendor_Questionnaires>;
  review_frequency: Frequencies_Enum;
  /** An array relationship */
  reviews: Array<Reviews>;
  /** An object relationship */
  risk_level?: Maybe<Organization_Vendor_Risk_Levels>;
  risk_level_id?: Maybe<Scalars['uuid']['output']>;
  similarity?: Maybe<Scalars['float8']['output']>;
  /** An array relationship */
  tasks: Array<Vendor_Tasks>;
  terminated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  tier?: Maybe<Organization_Vendor_Tiers>;
  tier_id?: Maybe<Scalars['uuid']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  vendor_findings: Array<Vendor_Findings>;
  /** An array relationship */
  vendor_risks: Array<Vendor_Risks>;
  /** An array relationship */
  vendors_comments: Array<Comments>;
  /** An array relationship */
  vendors_notifications: Array<Notifications>;
  /** An array relationship */
  vendors_vector_stores: Array<Ai_Vendors_Vector_Store>;
};

/** columns and relationships of "vendors" */
export type VendorsDocumentsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Documents_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Documents_Order_By>>;
  where?: InputMaybe<Vendor_Documents_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsField_ValuesArgs = {
  distinct_on?: InputMaybe<Array<Field_Values_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Values_Order_By>>;
  where?: InputMaybe<Field_Values_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsQuestionnairesArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Questionnaires_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Questionnaires_Order_By>>;
  where?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsReviewsArgs = {
  distinct_on?: InputMaybe<Array<Reviews_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reviews_Order_By>>;
  where?: InputMaybe<Reviews_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsTasksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Tasks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Tasks_Order_By>>;
  where?: InputMaybe<Vendor_Tasks_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendor_FindingsArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Findings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Findings_Order_By>>;
  where?: InputMaybe<Vendor_Findings_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendor_RisksArgs = {
  distinct_on?: InputMaybe<Array<Vendor_Risks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Vendor_Risks_Order_By>>;
  where?: InputMaybe<Vendor_Risks_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendors_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendors_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** columns and relationships of "vendors" */
export type VendorsVendors_Vector_StoresArgs = {
  distinct_on?: InputMaybe<Array<Ai_Vendors_Vector_Store_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Ai_Vendors_Vector_Store_Order_By>>;
  where?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
};

/** order by aggregate values of table "vendors" */
export type Vendors_Aggregate_Order_By = {
  avg?: InputMaybe<Vendors_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Vendors_Max_Order_By>;
  min?: InputMaybe<Vendors_Min_Order_By>;
  stddev?: InputMaybe<Vendors_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Vendors_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Vendors_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Vendors_Sum_Order_By>;
  var_pop?: InputMaybe<Vendors_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Vendors_Var_Samp_Order_By>;
  variance?: InputMaybe<Vendors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "vendors" */
export type Vendors_Arr_Rel_Insert_Input = {
  data: Array<Vendors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};

/** order by avg() on columns of table "vendors" */
export type Vendors_Avg_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "vendors". All fields are combined with a logical 'AND'. */
export type Vendors_Bool_Exp = {
  _and?: InputMaybe<Array<Vendors_Bool_Exp>>;
  _not?: InputMaybe<Vendors_Bool_Exp>;
  _or?: InputMaybe<Array<Vendors_Bool_Exp>>;
  approval_status?: InputMaybe<Vendor_Approval_Statuses_Enum_Comparison_Exp>;
  assessment_status?: InputMaybe<String_Comparison_Exp>;
  contact_email?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  documents?: InputMaybe<Vendor_Documents_Bool_Exp>;
  field_values?: InputMaybe<Field_Values_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  internal_id?: InputMaybe<String_Comparison_Exp>;
  last_review_date?: InputMaybe<Timestamptz_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  organization?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner?: InputMaybe<Users_Bool_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  questionnaires?: InputMaybe<Vendor_Questionnaires_Bool_Exp>;
  review_frequency?: InputMaybe<Frequencies_Enum_Comparison_Exp>;
  reviews?: InputMaybe<Reviews_Bool_Exp>;
  risk_level?: InputMaybe<Organization_Vendor_Risk_Levels_Bool_Exp>;
  risk_level_id?: InputMaybe<Uuid_Comparison_Exp>;
  similarity?: InputMaybe<Float8_Comparison_Exp>;
  tasks?: InputMaybe<Vendor_Tasks_Bool_Exp>;
  terminated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  tier?: InputMaybe<Organization_Vendor_Tiers_Bool_Exp>;
  tier_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  vendor_findings?: InputMaybe<Vendor_Findings_Bool_Exp>;
  vendor_risks?: InputMaybe<Vendor_Risks_Bool_Exp>;
  vendors_comments?: InputMaybe<Comments_Bool_Exp>;
  vendors_notifications?: InputMaybe<Notifications_Bool_Exp>;
  vendors_vector_stores?: InputMaybe<Ai_Vendors_Vector_Store_Bool_Exp>;
};

/** unique or primary key constraints on table "vendors" */
export enum Vendors_Constraint {
  /** unique or primary key constraint on columns "id" */
  VendorsPkey = 'vendors_pkey',
}

/** input type for inserting data into table "vendors" */
export type Vendors_Insert_Input = {
  approval_status?: InputMaybe<Vendor_Approval_Statuses_Enum>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  documents?: InputMaybe<Vendor_Documents_Arr_Rel_Insert_Input>;
  field_values?: InputMaybe<Field_Values_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  reviews?: InputMaybe<Reviews_Arr_Rel_Insert_Input>;
  risk_level?: InputMaybe<Organization_Vendor_Risk_Levels_Obj_Rel_Insert_Input>;
  tasks?: InputMaybe<Vendor_Tasks_Arr_Rel_Insert_Input>;
  tier?: InputMaybe<Organization_Vendor_Tiers_Obj_Rel_Insert_Input>;
  vendor_risks?: InputMaybe<Vendor_Risks_Arr_Rel_Insert_Input>;
  vendors_comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
};

/** order by max() on columns of table "vendors" */
export type Vendors_Max_Order_By = {
  assessment_status?: InputMaybe<Order_By>;
  contact_email?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  risk_level_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  terminated_at?: InputMaybe<Order_By>;
  tier_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "vendors" */
export type Vendors_Min_Order_By = {
  assessment_status?: InputMaybe<Order_By>;
  contact_email?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  risk_level_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  terminated_at?: InputMaybe<Order_By>;
  tier_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "vendors" */
export type Vendors_Mutation_Response = {
  __typename?: 'vendors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Vendors>;
};

/** input type for inserting object relation for remote table "vendors" */
export type Vendors_Obj_Rel_Insert_Input = {
  data: Vendors_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Vendors_On_Conflict>;
};

/** on_conflict condition type for table "vendors" */
export type Vendors_On_Conflict = {
  constraint: Vendors_Constraint;
  update_columns?: Array<Vendors_Update_Column>;
  where?: InputMaybe<Vendors_Bool_Exp>;
};

/** Ordering options when selecting data from "vendors". */
export type Vendors_Order_By = {
  approval_status?: InputMaybe<Order_By>;
  assessment_status?: InputMaybe<Order_By>;
  contact_email?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  documents_aggregate?: InputMaybe<Vendor_Documents_Aggregate_Order_By>;
  field_values_aggregate?: InputMaybe<Field_Values_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  internal_id?: InputMaybe<Order_By>;
  last_review_date?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  organization?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  owner?: InputMaybe<Users_Order_By>;
  owner_id?: InputMaybe<Order_By>;
  questionnaires_aggregate?: InputMaybe<Vendor_Questionnaires_Aggregate_Order_By>;
  review_frequency?: InputMaybe<Order_By>;
  reviews_aggregate?: InputMaybe<Reviews_Aggregate_Order_By>;
  risk_level?: InputMaybe<Organization_Vendor_Risk_Levels_Order_By>;
  risk_level_id?: InputMaybe<Order_By>;
  similarity?: InputMaybe<Order_By>;
  tasks_aggregate?: InputMaybe<Vendor_Tasks_Aggregate_Order_By>;
  terminated_at?: InputMaybe<Order_By>;
  tier?: InputMaybe<Organization_Vendor_Tiers_Order_By>;
  tier_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  vendor_findings_aggregate?: InputMaybe<Vendor_Findings_Aggregate_Order_By>;
  vendor_risks_aggregate?: InputMaybe<Vendor_Risks_Aggregate_Order_By>;
  vendors_comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  vendors_notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  vendors_vector_stores_aggregate?: InputMaybe<Ai_Vendors_Vector_Store_Aggregate_Order_By>;
};

/** primary key columns input for table: vendors */
export type Vendors_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "vendors" */
export enum Vendors_Select_Column {
  /** column name */
  ApprovalStatus = 'approval_status',
  /** column name */
  AssessmentStatus = 'assessment_status',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  InternalId = 'internal_id',
  /** column name */
  Name = 'name',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ReviewFrequency = 'review_frequency',
  /** column name */
  RiskLevelId = 'risk_level_id',
  /** column name */
  Similarity = 'similarity',
  /** column name */
  TerminatedAt = 'terminated_at',
  /** column name */
  TierId = 'tier_id',
  /** column name */
  UpdatedAt = 'updated_at',
}

/** input type for updating data in table "vendors" */
export type Vendors_Set_Input = {
  approval_status?: InputMaybe<Vendor_Approval_Statuses_Enum>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  review_frequency?: InputMaybe<Frequencies_Enum>;
  risk_level_id?: InputMaybe<Scalars['uuid']['input']>;
  terminated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  tier_id?: InputMaybe<Scalars['uuid']['input']>;
};

/** order by stddev() on columns of table "vendors" */
export type Vendors_Stddev_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "vendors" */
export type Vendors_Stddev_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "vendors" */
export type Vendors_Stddev_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "vendors" */
export type Vendors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendors_Stream_Cursor_Value_Input = {
  approval_status?: InputMaybe<Vendor_Approval_Statuses_Enum>;
  assessment_status?: InputMaybe<Scalars['String']['input']>;
  contact_email?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  internal_id?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  owner_id?: InputMaybe<Scalars['uuid']['input']>;
  review_frequency?: InputMaybe<Frequencies_Enum>;
  risk_level_id?: InputMaybe<Scalars['uuid']['input']>;
  similarity?: InputMaybe<Scalars['float8']['input']>;
  terminated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  tier_id?: InputMaybe<Scalars['uuid']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** order by sum() on columns of table "vendors" */
export type Vendors_Sum_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** columns and relationships of "vendors_summary_view" */
export type Vendors_Summary_View = {
  __typename?: 'vendors_summary_view';
  accepted_count?: Maybe<Scalars['Int']['output']>;
  /** An object relationship */
  organisation?: Maybe<Organizations>;
  organization_id?: Maybe<Scalars['uuid']['output']>;
  pending_count?: Maybe<Scalars['Int']['output']>;
  rejected_count?: Maybe<Scalars['Int']['output']>;
};

/** Boolean expression to filter rows from the table "vendors_summary_view". All fields are combined with a logical 'AND'. */
export type Vendors_Summary_View_Bool_Exp = {
  _and?: InputMaybe<Array<Vendors_Summary_View_Bool_Exp>>;
  _not?: InputMaybe<Vendors_Summary_View_Bool_Exp>;
  _or?: InputMaybe<Array<Vendors_Summary_View_Bool_Exp>>;
  accepted_count?: InputMaybe<Int_Comparison_Exp>;
  organisation?: InputMaybe<Organizations_Bool_Exp>;
  organization_id?: InputMaybe<Uuid_Comparison_Exp>;
  pending_count?: InputMaybe<Int_Comparison_Exp>;
  rejected_count?: InputMaybe<Int_Comparison_Exp>;
};

/** Ordering options when selecting data from "vendors_summary_view". */
export type Vendors_Summary_View_Order_By = {
  accepted_count?: InputMaybe<Order_By>;
  organisation?: InputMaybe<Organizations_Order_By>;
  organization_id?: InputMaybe<Order_By>;
  pending_count?: InputMaybe<Order_By>;
  rejected_count?: InputMaybe<Order_By>;
};

/** select columns of table "vendors_summary_view" */
export enum Vendors_Summary_View_Select_Column {
  /** column name */
  AcceptedCount = 'accepted_count',
  /** column name */
  OrganizationId = 'organization_id',
  /** column name */
  PendingCount = 'pending_count',
  /** column name */
  RejectedCount = 'rejected_count',
}

/** Streaming cursor of the table "vendors_summary_view" */
export type Vendors_Summary_View_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vendors_Summary_View_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vendors_Summary_View_Stream_Cursor_Value_Input = {
  accepted_count?: InputMaybe<Scalars['Int']['input']>;
  organization_id?: InputMaybe<Scalars['uuid']['input']>;
  pending_count?: InputMaybe<Scalars['Int']['input']>;
  rejected_count?: InputMaybe<Scalars['Int']['input']>;
};

/** update columns of table "vendors" */
export enum Vendors_Update_Column {
  /** column name */
  ApprovalStatus = 'approval_status',
  /** column name */
  ContactEmail = 'contact_email',
  /** column name */
  Description = 'description',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  ReviewFrequency = 'review_frequency',
  /** column name */
  RiskLevelId = 'risk_level_id',
  /** column name */
  TerminatedAt = 'terminated_at',
  /** column name */
  TierId = 'tier_id',
}

export type Vendors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Vendors_Set_Input>;
  /** filter the rows which have to be updated */
  where: Vendors_Bool_Exp;
};

/** order by var_pop() on columns of table "vendors" */
export type Vendors_Var_Pop_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "vendors" */
export type Vendors_Var_Samp_Order_By = {
  similarity?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "vendors" */
export type Vendors_Variance_Order_By = {
  similarity?: InputMaybe<Order_By>;
};
