import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Icon,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { zodResolver } from '@hookform/resolvers/zod';
import { isGraphqlError, toError } from '@main/shared/utils';
import { AutoResizeTextarea, errorToast, successToast } from '@main/ui';
import { TFunction } from 'i18next';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useUpdatePolicyVersionMutation } from './manage-policies.generated';

export type VersionEditModalProps = {
  versionId: string;
  versionNumber: string;
  isOpen: boolean;
  onClose(): void;
  versionDetails?: string;
};

export const getEditSchema = (t: TFunction<'translation'>) => {
  return z.object({
    versionNumber: z
      .string()
      .min(1, { message: t('policies.versions.edit.error.empty') })
      .refine((value) => !/[a-zA-Z]/.test(value), {
        message: t('policies.versions.edit.error.letterNotAllowed'),
      })
      .refine((value) => /^[0-9]+(\.[0-9]+)*$/.test(value), {
        message: t('policies.versions.edit.error.invalidSeparator'),
      })
      .refine((value) => /^([0-9]+(\.[0-9]+){0,2})$/.test(value), {
        message: t('policies.versions.edit.error.invalidFormat'),
      }),
    versionDetails: z.string().trim().optional(),
  });
};

export function EditVersionModal(props: VersionEditModalProps) {
  const { t } = useTranslation();
  const [updateVersion] = useUpdatePolicyVersionMutation();
  const validationSchema = getEditSchema(t);
  const form = useForm<z.infer<typeof validationSchema>>({
    mode: 'onBlur',
    resolver: zodResolver(validationSchema),
    defaultValues: {
      versionNumber: props.versionNumber.toString(),
      versionDetails: props.versionDetails ?? '',
    },
  });

  const onSubmit = form.handleSubmit(async (data) => {
    try {
      await updateVersion({
        id: props.versionId,
        updatePayload: {
          version_id: data.versionNumber,
          revision_details: data.versionDetails,
        },
      }).unwrap();
      successToast(t('policies.versions.edit.successToast'));
      props.onClose();
    } catch (error) {
      if (isGraphqlError(error)) {
        switch (error[0].extensions.code) {
          case 'constraint-violation':
            if (error[0].message.includes('policy_versions_policy_id_version_id_key')) {
              form.setError('versionNumber', {
                message: t('policies.versions.edit.error.alreadyExists', {
                  versionNumber: data.versionNumber,
                }),
              });
            }
            break;

          default: {
            errorToast(t('errorMessages.updateFailed', { entity: t('entities.policyVersion') }));
            datadogLogs.logger.error(
              'Policy version details update failed',
              { versionId: props.versionDetails },
              toError(error),
            );
          }
        }
      }
    }
  });

  return (
    <Modal size="xl" isCentered isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader as={Text} fontSize="lg" fontWeight="bold">
          {t('policies.versions.edit.header')}
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={onSubmit}>
          <ModalBody>
            <Flex direction="column" gap={6}>
              <Text>{t('policies.versions.edit.editInfo')}</Text>
              <FormControl isInvalid={!!form.formState.errors.versionNumber}>
                <Flex>
                  <FormLabel mr={2}>{t('policies.versions.edit.versionNumber')}</FormLabel>
                  <Tooltip
                    placement="right"
                    hasArrow
                    label={t('policies.versions.edit.tooltip')}
                    openDelay={500}
                  >
                    <Icon as={InformationCircleIcon} />
                  </Tooltip>
                </Flex>
                <Input {...form.register('versionNumber')} />
                <FormErrorMessage>{form.formState.errors.versionNumber?.message}</FormErrorMessage>
              </FormControl>
              <FormControl>
                <FormLabel>{t('policies.versions.edit.versionDetails')}</FormLabel>
                <AutoResizeTextarea
                  {...form.register('versionDetails')}
                  minRows={3}
                  placeholder={t('policies.versions.edit.header')}
                />
              </FormControl>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button onClick={props.onClose} mr={3}>
              {t('buttons.cancel')}
            </Button>
            <Button type="submit" colorScheme="blue" isLoading={form.formState.isSubmitting}>
              {t('buttons.submit')}
            </Button>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
