import {
  Button,
  ButtonGroup,
  FormControlProps,
  HStack,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  useMergeRefs,
  VStack,
} from '@chakra-ui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import { DynamicForm, DynamicFormRef, useQuestionnaireComponentRegistry } from '@main/dynamic-form';
import { VendorQuestionnaireFormAnswerValue } from '@main/graphql/client-scalars';
import { Vendor_Questionnaire_Form_Answer_Statuses_Enum } from '@main/graphql/types.generated';
import { mapByKey } from '@main/shared/utils';
import { ForwardedRef, forwardRef, useEffect, useMemo, useRef, useState } from 'react';
import { UseFormProps } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getQuestionConditionsFilter } from './conditions';
import { QuestionnaireFieldCommentPrefix } from './field-comment';
import { QuestionnnaireFieldWrapper } from './field-wrapper';
import {
  QuestionnnaireFieldWrapperProps,
  QuestionnnaireFormFieldAnswer,
  QuestionnnaireFormFilterMode,
  QuestionnnaireFormFilters,
  QuestionnnaireFormMode,
  QuestionnnaireFormProps,
  QuestionnnaireFormStats,
  QuestionnnaireFormValues,
} from './form-types';
import { createNewAnswerFor, getFormConfigFilter, getQuestionFilter } from './utils';

export const QuestionnnaireForm = forwardRef(
  (props: QuestionnnaireFormProps, forwardedRef: ForwardedRef<DynamicFormRef>) => {
    const { t } = useTranslation();
    const componentRegistry = useQuestionnaireComponentRegistry();
    const dynamicFormRef = useRef<DynamicFormRef>(null);
    const ref = useMergeRefs(dynamicFormRef, forwardedRef);

    const [filters, setFilters] = useState<QuestionnnaireFormFilters>({
      mode: QuestionnnaireFormFilterMode.All,
      search: '',
    });

    const fieldsByName = useMemo(() => mapByKey(props.fields, 'name'), [props.fields]);
    const isQuestionHidden = useMemo(() => getQuestionFilter(filters), [filters]);
    const conditionsFiltersMap = useMemo(
      () => new Map(props.fields.map((field) => [field.name, getQuestionConditionsFilter(field)])),
      [props.fields],
    );
    const answersMap = useMemo(
      () => new Map(props.answers?.map((answer) => [answer.field_name, answer])),
      [props.answers],
    );
    const visibleFields = useMemo(
      () =>
        props.fields.filter(
          getFormConfigFilter(props.answers ?? [], answersMap, conditionsFiltersMap),
        ),
      [answersMap, conditionsFiltersMap, props.answers, props.fields],
    );
    const isAllQuestionsHidden = useMemo(
      () => visibleFields.every((field) => isQuestionHidden(field, answersMap.get(field.name))),
      [visibleFields, isQuestionHidden, answersMap],
    );

    const values: QuestionnnaireFormValues = useMemo(
      () =>
        Object.fromEntries(
          props.answers?.flatMap((answer) => [
            [answer.field_name, answer.value],
            [`${QuestionnaireFieldCommentPrefix}${answer.field_name}`, answer.comment],
          ]) ?? [],
        ),
      [props.answers],
    );

    const useFormProps: UseFormProps = useMemo(
      () => ({ values, resetOptions: { keepDirtyValues: true, keepErrors: true } }),
      [values],
    );

    const fieldControl: FormControlProps = useMemo(
      () => ({ isDisabled: props.mode === QuestionnnaireFormMode.View }),
      [props.mode],
    );

    const onAnswerChange = props.onAnswerChange;
    const wrapperProps: QuestionnnaireFieldWrapperProps = useMemo(
      () => ({ form: props, filters, visibleFields, onAnswerChange }),
      [props, filters, visibleFields, onAnswerChange],
    );

    const stats: QuestionnnaireFormStats = useMemo(
      () => ({
        total: visibleFields.length,
        completed:
          visibleFields
            .map((field) => answersMap.get(field.name))
            .filter(
              (answer) =>
                !!answer &&
                answer.status === Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed,
            ).length ?? 0,
      }),
      [visibleFields, answersMap],
    );

    const onStatsChange = props.onStatsChange;
    useEffect(
      () => onStatsChange?.({ total: stats.total, completed: stats.completed }),
      [onStatsChange, stats.total, stats.completed],
    );

    useEffect(() => {
      if (!dynamicFormRef.current) {
        return;
      }

      const subscription = dynamicFormRef.current.form.watch((data, info) => {
        if (!info.name || info.type !== 'change') {
          return;
        }
        const isCommentUpdate = info.name.startsWith(QuestionnaireFieldCommentPrefix);
        const fieldName = isCommentUpdate
          ? info.name.replace(QuestionnaireFieldCommentPrefix, '')
          : info.name;
        const field = fieldsByName[fieldName];
        const answerData: Partial<QuestionnnaireFormFieldAnswer> = {
          value: data[fieldName] as VendorQuestionnaireFormAnswerValue | undefined,
          comment: data[`${QuestionnaireFieldCommentPrefix}${fieldName}`] as string | undefined,
        };
        if (!field) {
          return;
        }
        const answer = answersMap.get(field.name) ?? createNewAnswerFor(field);
        void props.onAnswerChange?.(answer, answerData);
      });

      return () => subscription.unsubscribe();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.answers, props.fields, dynamicFormRef, props.onAnswerChange]);

    return (
      <Stack spacing={6}>
        {props.mode === QuestionnnaireFormMode.Answer && !props.isSubmitted && (
          <HStack justifyContent="space-between">
            <ButtonGroup variant="outline" size="lg" isAttached>
              <Button
                fontSize="sm"
                fontWeight="normal"
                isActive={filters.mode === QuestionnnaireFormFilterMode.All}
                onClick={() =>
                  setFilters((f) => ({ ...f, mode: QuestionnnaireFormFilterMode.All }))
                }
              >
                {t('questionnaires.form.filters.all')}
              </Button>
              <Button
                fontSize="sm"
                fontWeight="normal"
                isActive={filters.mode === QuestionnnaireFormFilterMode.Unanswered}
                onClick={() =>
                  setFilters((f) => ({ ...f, mode: QuestionnnaireFormFilterMode.Unanswered }))
                }
              >
                {t('questionnaires.form.filters.unanswered')}
              </Button>
            </ButtonGroup>

            <InputGroup variant="outline" size="md" maxW="222px">
              <InputLeftElement pointerEvents="none">
                <Icon color="gray.400" as={MagnifyingGlassIcon} />
              </InputLeftElement>
              <Input
                placeholder={t('questionnaires.form.filters.searchPlaceholder')}
                value={filters.search}
                onChange={(event) =>
                  setFilters((f) => ({ ...f, search: event.target.value.toLowerCase() }))
                }
              />
            </InputGroup>
          </HStack>
        )}

        <Stack spacing="3">
          {props.mode === QuestionnnaireFormMode.Answer && (
            <Text fontSize="xs" color="gray.600">
              {t('questionnaires.form.requiredNote')}
            </Text>
          )}
          <DynamicForm
            {...componentRegistry}
            fieldSortDir="asc"
            useForm={useFormProps}
            fields={visibleFields}
            fieldControl={fieldControl}
            fieldWrapper={props.fieldWrapper ?? QuestionnnaireFieldWrapper}
            fieldWrapperProps={wrapperProps}
            ref={ref}
          />
          {isAllQuestionsHidden && (
            <VStack>
              <Text fontSize="lg" color="gray.500">
                {t('questionnaires.form.noMatchingQuestions')}
              </Text>
            </VStack>
          )}
        </Stack>
      </Stack>
    );
  },
);
