import { VendorAssessmentStatus } from '@main/graphql/client-scalars';
import { Notification_Types_Enum } from '@main/graphql/types.generated';
import { useTranslation } from 'react-i18next';

import { router } from '../../router';
import {
  AssignedNotificationAdapter,
  AssignedNotificationData,
  AssignedNotificationHandler,
} from '../notifications/assigned-notification-handler';
import { StatusUpdateContent } from '../notifications/notification-content';
import {
  NotificationContentComponent,
  NotificationContentProps,
  NotificationHandler,
  NotificationItem,
} from '../notifications/notification-handler';

export interface VendorAssignedNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_vendor: NonNullable<NotificationItem['content']['notifications_vendor']>;
  };
}

export class VendorAssignedNotificationHandler
  implements AssignedNotificationAdapter<VendorAssignedNotification>
{
  static readonly type = Notification_Types_Enum.EntityOwnershipChanged;

  static canHandle(notification: NotificationItem): notification is VendorAssignedNotification {
    return !!notification.content.notifications_vendor;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: VendorAssignedNotification): AssignedNotificationData {
    return {
      internalId: notification.content.notifications_vendor.internal_id,
      entityName: notification.content.notifications_vendor.name,
      content: 'notification.vendor.assigned',
    };
  }

  redirect(notification: VendorAssignedNotification) {
    router.navigate({
      to: '/vendors',
      search: {
        drawerEntity: 'vendor',
        drawerEntityId: notification.content.notifications_vendor.id,
      },
    });
  }
}

export interface MentionedInVendorCommentNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_comment: NonNullable<NotificationItem['content']['notifications_comment']> & {
      comments_vendor: NonNullable<
        NonNullable<NotificationItem['content']['notifications_comment']>['comments_vendor']
      >;
    };
  };
}

export class MentionedInVendorCommentNotificationHandler
  implements AssignedNotificationAdapter<MentionedInVendorCommentNotification>
{
  static readonly type = Notification_Types_Enum.MentionedInComment;

  static canHandle(
    notification: NotificationItem,
  ): notification is MentionedInVendorCommentNotification {
    return !!notification.content.notifications_comment?.comments_vendor;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: MentionedInVendorCommentNotification): AssignedNotificationData {
    return {
      internalId: notification.content.notifications_comment.comments_vendor.internal_id,
      entityName: notification.content.notifications_comment.comments_vendor.name,
      content: 'notification.vendor.mentioned',
    };
  }

  redirect(notification: MentionedInVendorCommentNotification) {
    router.navigate({
      to: '/vendors',
      search: {
        drawerEntity: 'vendor',
        drawerEntityId: notification.content.notifications_comment.comments_vendor.id,
        activeTab: 'comments',
      },
    });
  }
}

export interface VendorExpiringNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_vendor: NonNullable<NotificationItem['content']['notifications_vendor']>;
    params: { vendorStatus: VendorAssessmentStatus };
  };
}

export class VendorExpiringNotificationHandler
  implements NotificationHandler<VendorExpiringNotification>
{
  static readonly type = Notification_Types_Enum.VendorStatusChangedToExpiring;

  static canHandle(notification: NotificationItem): notification is VendorExpiringNotification {
    return (
      !!notification.content.notifications_vendor && 'vendorStatus' in notification.content.params
    );
  }

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<VendorExpiringNotification, this> {
    return VendorExpiringNotificationContent;
  }

  redirect(notification: VendorExpiringNotification) {
    router.navigate({
      to: '/vendors',
      search: {
        drawerEntity: 'vendor',
        drawerEntityId: notification.content.notifications_vendor.id,
      },
    });
  }
}

function VendorExpiringNotificationContent({
  notification,
}: NotificationContentProps<VendorExpiringNotification>) {
  const { t } = useTranslation();
  const vendorStatus = t(
    `vendors.enum.assessmentStatus.${notification.content.params.vendorStatus}`,
  ).toLowerCase();

  return (
    <StatusUpdateContent
      content={t('notification.vendor.needsReview', { vendorStatus })}
      internalId={notification.content.notifications_vendor.internal_id ?? ''}
      entityName={notification.content.notifications_vendor.name ?? ''}
    />
  );
}
