import { Icon, IconButton, Tooltip } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { TrashIcon } from '@heroicons/react/24/outline';
import { Tasks_Set_Input } from '@main/graphql/types.generated';
import { isNonNullable, toError } from '@main/shared/utils';
import { createColumnHelper, errorToast, useDrawer } from '@main/ui';
import { SecondaryActionConfig } from '@main/ui/table/columns';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import { dateToMidnightUTC, toDatePart } from '../../utils/date';
import { TASK_STATUS_COLOR } from '../shared/status-color';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { useDeleteTask } from './delete-task';
import {
  useCreateTaskOwnerMutation,
  useRemoveTaskOwnerMutation,
  useUpdateTaskByIdMutation,
} from './manage-tasks.generated';
import { TaskTableFragmentFragment } from './tasks.fragment.generated';
import {
  useLazyInProgressOrPendingToggleMenuAction,
  useLazyPendingOrCompleteToggleAction,
} from './use-drawer-actions';
import { useTaskApi } from './use-task-api';
import { useUpdateTaskHandler } from './use-update-task-handler';

type Task = TaskTableFragmentFragment;
type TaskColumnProps = {
  isEditable?: boolean;
  enableColumnFilter?: boolean;
  enableGlobalFilter?: boolean;
  enableSorting?: boolean;
};

export function useTaskColumnHelper() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditTask = !!userRole.permissionMap?.write_tasks;

  const updateTaskHandler = useUpdateTaskHandler();
  const [createTaskOwner] = useCreateTaskOwnerMutation();
  const [removeTaskOwner] = useRemoveTaskOwnerMutation();
  const [updateTaskById] = useUpdateTaskByIdMutation();
  const taskApi = useTaskApi();
  const deleteTask = useDeleteTask();
  const markInProgressOrPendingToggleAction = useLazyInProgressOrPendingToggleMenuAction();

  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);

  const openTaskDrawer = useCallback(
    (taskId: string) => {
      drawer.open({ entity: 'task', entityId: taskId });
    },
    [drawer],
  );

  const updateHandler = useCallback(
    async (taskId: string, input: Tasks_Set_Input) => {
      const undo = taskApi.optimisticUpdateOnLinkedTaskTable({
        taskId,
        updateInput: input,
      });
      try {
        await updateTaskById({
          taskId,
          updatePayload: input,
        }).unwrap();
      } catch (error) {
        errorToast(
          t('errorMessages.updateFailed', {
            entity: `${t('entities.task').toLowerCase()}`,
          }),
        );
        datadogLogs.logger.error('Failed to update task', { taskId }, toError(error));
        undo();
      }
    },
    [t, taskApi, updateTaskById],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<Task>();

    return {
      status(props?: TaskColumnProps) {
        return columnHelper.columns.status({
          id: 'status',
          header: t('tasks.table.columns.status'),
          accessorFn: ({ status }) => ({
            colorScheme: TASK_STATUS_COLOR[status],
            value: t(`tasks.enum.status.${status}`),
          }),
          size: 120,
          ...props,
        });
      },

      name(props?: TaskColumnProps & { newTaskId?: string }) {
        return columnHelper.columns.text({
          id: 'name',
          header: t('tasks.table.columns.name'),
          accessorFn: ({ name }) => name,
          minSize: 200,
          meta: {
            cell: {
              onClick: ({ row }) => openTaskDrawer(row.original.id),
            },
          },
          ...props,
          edit:
            canEditTask && props?.isEditable
              ? {
                  onChange: (row, name) => {
                    updateHandler(row.original.id, { name });
                  },
                  defaultIsEditing: (row) => row.original.id === props.newTaskId,
                }
              : undefined,
        });
      },

      dueDate(props?: TaskColumnProps) {
        return columnHelper.columns.date({
          id: 'dueDate',
          header: t('tasks.details.dueDate'),
          accessorFn: ({ due_date }) => (due_date ? toDatePart(due_date) : undefined),
          edit:
            canEditTask && props?.isEditable
              ? {
                  onChange: (row, value) =>
                    updateHandler(row.original.id, { due_date: dateToMidnightUTC(value) || null }),
                }
              : undefined,
          size: 150,
          meta: {
            cell: {
              fontSize: 'xs',
              color: 'gray.500',
            },
          },
          ...props,
        });
      },

      completedDate(props?: TaskColumnProps) {
        return columnHelper.columns.date({
          id: 'completedDate',
          header: t('tasks.details.completedDate'),
          accessorFn: ({ completed_at }) => completed_at,
          size: 150,
          meta: {
            cell: {
              fontSize: 'xs',
              color: 'gray.500',
            },
          },
          ...props,
        });
      },

      createdBy(props?: TaskColumnProps) {
        return columnHelper.columns.avatar({
          id: 'createdBy',
          header: t('tasks.details.createdBy'),
          accessorFn: ({ created_by }) => {
            if (!created_by) {
              return COMPLYANCE_USER;
            }

            const user = currentOrgUsers[created_by];
            if (!user) {
              return;
            }

            return {
              id: user.id,
              displayName: user.displayName,
            };
          },
          size: 85,
          meta: {
            cell: {
              fontSize: 'xs',
              color: 'gray.500',
            },
          },
          ...props,
        });
      },

      owner(props?: TaskColumnProps) {
        return columnHelper.columns.avatar({
          id: 'assignee',
          header: t('tasks.table.columns.assignee'),
          accessorFn: ({ task_owners }) => {
            // TODO: Add support for multiple owners
            const owner = task_owners[0];
            if (!owner?.owner_id) {
              return;
            }

            const user = currentOrgUsers[owner.owner_id];
            if (!user) {
              return;
            }

            return {
              id: user.id,
              displayName: user.displayName,
            };
          },
          size: 85,
          ...props,
          edit:
            canEditTask && props?.isEditable
              ? {
                  options: currentOrgNonDisabledUsers.map((user) => ({
                    id: user.id,
                    displayName: user.displayName,
                  })),
                  onChange: (row, user) => {
                    if (!user?.id) {
                      updateTaskHandler(
                        removeTaskOwner({
                          taskId: row.original.id,
                        }),
                      );
                    } else {
                      updateTaskHandler(
                        createTaskOwner({
                          taskId: row.original.id,
                          ownerId: user.id,
                        }),
                      );
                    }
                  },
                }
              : undefined,
        });
      },

      actions({ onUnlink }: { onUnlink?: (row: Task) => SecondaryActionConfig | undefined } = {}) {
        return columnHelper.columns.actions({
          size: 70,
          PrimaryAction: (context) => <TaskTablePrimaryAction task={context.row.original} />,
          secondaryActions: ({ row }) => {
            const markAsInProgressOrPending = markInProgressOrPendingToggleAction({
              taskId: row.original.id,
              taskStatus: row.original.status,
            });
            const unlinkTaskFromEntity = onUnlink?.(row.original);

            return [
              markAsInProgressOrPending,
              unlinkTaskFromEntity,
              deleteTask && {
                icon: <Icon as={TrashIcon} />,
                label: t('buttons.delete'),
                onClick: () => {
                  deleteTask(row.original.id);
                },
              },
            ].filter(isNonNullable);
          },
        });
      },
    };
  }, [
    t,
    canEditTask,
    openTaskDrawer,
    updateHandler,
    currentOrgUsers,
    currentOrgNonDisabledUsers,
    updateTaskHandler,
    removeTaskOwner,
    createTaskOwner,
    markInProgressOrPendingToggleAction,
    deleteTask,
  ]);
}

const TaskTablePrimaryAction = ({ task }: { task: Task }) => {
  const markPendingOrComplete = useLazyPendingOrCompleteToggleAction()({
    taskId: task.id,
    completedAt: task.completed_at,
  });

  if (!markPendingOrComplete) {
    return;
  }

  return (
    <Tooltip label={markPendingOrComplete.label} placement="top">
      <IconButton
        minW={4}
        variant="link"
        aria-label={markPendingOrComplete.label}
        icon={markPendingOrComplete.icon}
        onClick={markPendingOrComplete.onClick}
        isLoading={markPendingOrComplete.isLoading}
      />
    </Tooltip>
  );
};
