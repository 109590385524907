import { FileItemRef } from '@nhost/nhost-js';
import { createContext, useContext } from 'react';

import { UploadedFile } from './shared';

export interface FileInputContext<T extends UploadedFile = UploadedFile> {
  isMulti?: boolean;
  uploadedFiles?: T[];
  onUploadedFileDownload?: (fileId: string) => void;
  onUploadedFileDelete?: (file: T) => Promise<void>;
  onUploadedFileReupload?: (file: T, files: File[]) => Promise<void>;
  stagedFiles?: FileItemRef[];
  onStagedFilesAdd?: (files: File[]) => void;
  onStagedFileDelete?: (fileId: string, file: File) => void;
  registerReuploadCb(cb: (file: T) => void): () => void;
}

export const FileInputContext = createContext<FileInputContext | null>(null);

export function useFileInputContext() {
  const context = useContext(FileInputContext);

  if (!context) {
    throw new Error('FileUpload components must be wrapped in <FileUpload />');
  }

  return context;
}
