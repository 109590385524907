import {
  Center,
  Icon,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
  useFormControl,
} from '@chakra-ui/react';
import {
  ArrowDownTrayIcon,
  ArrowPathRoundedSquareIcon,
  ArrowUpOnSquareIcon,
  ExclamationCircleIcon,
  PaperClipIcon,
  XCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { useStableCallback } from '@main/shared/utils';
import { format as bytesFormat } from 'bytes';
import { useTranslation } from 'react-i18next';

import { OverflowContainer } from '../overflow-tooltip';
import { Card } from './base-card';

type UploadFileCardProps = {
  file: {
    name?: string;
    size?: number;
  };
  isLoading?: boolean;
  isUploaded?: boolean;
  error?: string;
  onDelete?: () => void;
  onDownload?: () => void;
  onReupload?: () => void;
};

export const UploadFileCard = ({
  file,
  isLoading,
  isUploaded,
  error,
  onDownload,
  onDelete,
  onReupload,
}: UploadFileCardProps) => {
  const { t } = useTranslation('ui');
  const { readOnly } = useFormControl({});
  const bgColor = useColorModeValue('blue.50', 'rgba(214, 188, 250, 0.16)');
  const errorBgColor = useColorModeValue('red.100', 'rgba(254, 178, 178, 0.16)');
  const iconColor = useColorModeValue('purple.800', 'purple.200');
  const errorIconColor = useColorModeValue('red.800', 'red.200');
  const tooltipColor = useColorModeValue('red.500', 'red.200');

  const getFileStateIcon = useStableCallback(() => {
    if (isLoading) {
      return <Spinner thickness="1.5px" color={iconColor} size="sm" />;
    }
    if (isUploaded) {
      return <Icon color={iconColor} as={PaperClipIcon} />;
    }
    if (error) {
      return <Icon color={errorIconColor} as={XCircleIcon} />;
    }

    return <Icon color={iconColor} as={ArrowUpOnSquareIcon} />;
  });

  return (
    <Card data-testid="file-stack" aria-busy={isLoading} isReadOnly={readOnly}>
      <Card.Header
        left={
          <Center bg={error ? errorBgColor : bgColor} p={1.5} rounded="md">
            {getFileStateIcon()}
          </Center>
        }
        right={
          <Text fontSize="xs" color="gray.500" whiteSpace="nowrap" pr={1}>
            {bytesFormat(file.size || 0, { unitSeparator: ' ' })}
          </Text>
        }
        actions={[
          onDownload && !isLoading
            ? {
                icon: ArrowDownTrayIcon,
                label: t('cards.actions.download'),
                onClick: onDownload,
                isReadOnly: true,
              }
            : undefined,
          onReupload
            ? {
                icon: ArrowPathRoundedSquareIcon,
                label: t('cards.actions.reupload'),
                onClick: onReupload,
              }
            : undefined,
          onDelete && {
            icon: XMarkIcon,
            label: t('cards.actions.delete'),
            onClick: onDelete,
          },
        ]}
      />
      <Card.Body>
        <OverflowContainer w="fit-content">
          <OverflowContainer.Tooltip
            label={file.name}
            hasArrow
            placement="top"
            gutter={12}
            fontSize="sm"
            openDelay={500}
          >
            <Text
              fontSize="sm"
              fontWeight="medium"
              noOfLines={1}
              lineHeight={5}
              color="gray.700"
              /**
               * If there is a case when file name is very long single word,
               * then truncation will not work and text will push the action buttons out of the card,
               * to avoid this we need to set maxW to a value that will make sure that the text will not overflow
               */
              maxW={['2xs', 'xs']}
              _dark={{ color: 'gray.200' }}
            >
              {file.name}
            </Text>
          </OverflowContainer.Tooltip>
        </OverflowContainer>

        {error && (
          <Tooltip label={error} placement="top" hasArrow>
            <Icon w={4} h={4} color={tooltipColor} as={ExclamationCircleIcon} mr={2} />
          </Tooltip>
        )}
      </Card.Body>
    </Card>
  );
};
