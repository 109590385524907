import {
  Box,
  Button,
  Divider,
  Heading,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  PinInput,
  PinInputField,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { useUpdateUserMutation } from '@main/graphql/mutations/UpdateUser.generated';
import { useGetUserByIdQuery } from '@main/graphql/queries/GetUserById.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useAlertDialog } from '@main/ui';
import { useConfigMfa } from '@nhost/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserDetails, getCurrentUserId } from './slice';

export const Mfa: React.FC = () => {
  const [code, setCode] = useState('');
  const [step, setStep] = useState<'step1' | 'step2'>('step1');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();
  const { generateQrCode, activateMfa, qrCodeDataUrl } = useConfigMfa();
  const [updateUser] = useUpdateUserMutation();
  const { openDialog } = useAlertDialog();
  const currentUserId = useAppSelector(getCurrentUserId);
  const { refetch: refetchCurrentUser } = useGetUserByIdQuery({
    id: currentUserId,
  });
  const userResult = useAppSelector(getCurrentUserDetails);

  const generate = async () => {
    const result = await generateQrCode();
    if (result.error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.mfa') }));
    } else {
      onOpen();
    }
  };

  const onCloseMfaModal = () => {
    setStep('step1');
    onClose();
  };

  const deactivateMfa = async () => {
    try {
      await updateUser({
        id: currentUserId,
        users_set_input: {
          activeMfaType: null,
        },
      }).unwrap();
      await refetchCurrentUser().unwrap();
      successToast(t('profile.mfa.disableSuccess'));
    } catch (error) {
      datadogLogs.logger.error('Error deactivating mfa', {}, toError(error));
    }
  };

  return (
    <>
      <Box py="6" px={[4, 4, 8]}>
        <Heading as="h2" size="md">
          {t('settings.account.verification.heading')}
        </Heading>
        <Text fontSize="14" color="gray.500">
          {t('settings.account.verification.subheading')}
        </Text>
      </Box>

      <Divider orientation="horizontal" />
      <Box w="100%" py="6" px={[4, 4, 8]}>
        {userResult?.user?.activeMfaType === 'totp' ? (
          <Box w="100%">
            <Button
              colorScheme="red"
              size="md"
              variant="outline"
              onClick={() => {
                openDialog({
                  dialogHeader: t('profile.mfa.alert.heading'),
                  dialogContent: t('profile.mfa.alert.content'),
                  confirmAction: {
                    children: t('profile.mfa.alert.confirmBtn'),
                    onClick: deactivateMfa,
                  },
                });
              }}
            >
              {t('profile.mfa.disableMfaBtn')}
            </Button>
          </Box>
        ) : (
          <Box w="100%">
            <Button colorScheme="blue" size="md" onClick={generate}>
              {t('profile.mfa.setupMfaBtn')}
            </Button>
          </Box>
        )}
        <Modal
          isOpen={isOpen}
          onClose={onCloseMfaModal}
          isCentered
          size="xl"
          motionPreset="slideInBottom"
        >
          <ModalOverlay />
          <ModalContent maxW="xl">
            <ModalHeader fontSize="18">{t('profile.mfa.setupMfaModal.heading')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {step === 'step1' && (
                <>
                  <VStack
                    border="1px solid"
                    borderRadius="lg"
                    borderColor="gray.200"
                    padding="2rem"
                    marginBottom="2rem"
                  >
                    <Text fontWeight="semibold">
                      {t('profile.mfa.setupMfaModal.qrinstruction')}
                    </Text>
                    <Image src={qrCodeDataUrl} alt="qrcode" />
                  </VStack>

                  <Text fontWeight="semibold">
                    {t('profile.mfa.setupMfaModal.helpTextHeading')}
                  </Text>
                  <Text>{t('profile.mfa.setupMfaModal.helpContent')}</Text>

                  <HStack justifyContent="flex-end" padding="1rem 0">
                    <Button colorScheme="gray" size="md" onClick={onCloseMfaModal}>
                      {t('profile.mfa.setupMfaModal.cancel')}
                    </Button>
                    <Button
                      colorScheme="blue"
                      size="md"
                      onClick={() => {
                        setStep('step2');
                      }}
                    >
                      {t('profile.mfa.setupMfaModal.continue')}
                    </Button>
                  </HStack>
                </>
              )}
              {step === 'step2' && (
                <>
                  <VStack
                    border="1px solid"
                    borderRadius="lg"
                    borderColor="gray.200"
                    padding="2rem"
                    marginBottom="2rem"
                  >
                    <Text fontWeight="semibold">{t('profile.mfa.setupMfaModal.entercode')}</Text>
                    <HStack>
                      <PinInput otp onComplete={setCode} autoFocus>
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                        <PinInputField />
                      </PinInput>
                    </HStack>
                  </VStack>

                  <HStack justifyContent="flex-end" padding="1rem 0">
                    <Button colorScheme="gray" size="md" onClick={onCloseMfaModal}>
                      {t('profile.mfa.setupMfaModal.cancel')}
                    </Button>
                    <Button
                      colorScheme="blue"
                      size="md"
                      isDisabled={code.length === 0}
                      onClick={async () => {
                        const { error } = await activateMfa(code);
                        if (!error) {
                          onCloseMfaModal();
                          refetchCurrentUser();
                          successToast(t('profile.mfa.setupMfaModal.success'));
                        } else {
                          errorToast(t('profile.mfa.setupMfaModal.error'));
                        }
                      }}
                    >
                      {t('profile.mfa.setupMfaModal.enableBtn')}
                    </Button>
                  </HStack>
                </>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};
