import {
  Button,
  ButtonGroup,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { Policy_Version_Statuses_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useDownloadStorageFile } from '@main/ui';
import { useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { ExportPolicyMutationVariables, useExportPolicyMutation } from './export-policy.generated';
import { useGetPolicyQuery } from './manage-policies.generated';

export type ExportPolicyModalProps = {
  policyId: string;
  isOpen: boolean;
  onClose(): void;
};

const schema = z.object({
  withVersionsChapter: z.boolean(),
  withRevisionDetails: z.boolean(),
  withApprovalsChapter: z.boolean(),
  withAcknowledgementsChapter: z.boolean(),
});

type Schema = z.infer<typeof schema>;

export const ExportPolicyModal = ({ policyId, isOpen, onClose }: ExportPolicyModalProps) => {
  const { t } = useTranslation();

  const defaultValues = useDefaultValues(policyId);

  const { register, reset, handleSubmit, formState } = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues,
  });

  useEffect(() => {
    if (isOpen) {
      reset(defaultValues);
    }
  }, [defaultValues, isOpen, reset]);

  const exportPolicy = useExportPolicy();

  const onSubmit = handleSubmit(
    async ({
      withVersionsChapter,
      withRevisionDetails,
      withApprovalsChapter,
      withAcknowledgementsChapter,
    }) => {
      await exportPolicy({
        policyId,
        withVersionsChapter,
        withRevisionDetails,
        withApprovalsChapter,
        withAcknowledgementsChapter,
      });
      onClose();
    },
  );

  return (
    <Modal size="xl" isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t('policies.exportModal.heading')}</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={onSubmit}>
          <ModalBody>
            <Flex direction="column" gap={6}>
              <FormControl
                isInvalid={!!formState.errors.withVersionsChapter}
                isDisabled={!defaultValues.withVersionsChapter}
              >
                <FormLabel>{t('policies.exportModal.withVersionsChapterLabel')}:</FormLabel>
                <Switch {...register('withVersionsChapter')} />
                <FormErrorMessage>{formState.errors.withVersionsChapter?.message}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={!!formState.errors.withRevisionDetails}
                isDisabled={!defaultValues.withRevisionDetails}
              >
                <FormLabel>{t('policies.exportModal.withVersionDetailsLabel')}:</FormLabel>
                <Switch {...register('withRevisionDetails')} />
                <FormErrorMessage>{formState.errors.withRevisionDetails?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formState.errors.withApprovalsChapter}>
                <FormLabel>{t('policies.exportModal.withApprovalsChapterLabel')}:</FormLabel>
                <Switch {...register('withApprovalsChapter')} />
                <FormErrorMessage>
                  {formState.errors.withApprovalsChapter?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!formState.errors.withAcknowledgementsChapter}>
                <FormLabel>{t('policies.exportModal.withAcknowledgementsChapterLabel')}:</FormLabel>
                <Switch {...register('withAcknowledgementsChapter')} />
                <FormErrorMessage>
                  {formState.errors.withAcknowledgementsChapter?.message}
                </FormErrorMessage>
              </FormControl>
            </Flex>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button isDisabled={formState.isSubmitting} onClick={onClose}>
                {t('buttons.cancel')}
              </Button>
              <Button type="submit" colorScheme="blue" isLoading={formState.isSubmitting}>
                {t('buttons.confirm')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

function useDefaultValues(policyId: string): Schema {
  const { data } = useGetPolicyQuery({ id: policyId });

  return useMemo(() => {
    const hasApprovedVersion = !!data?.policies_by_pk?.policy_versions.some(
      (version) => version.status === Policy_Version_Statuses_Enum.Approved,
    );

    return {
      withVersionsChapter: hasApprovedVersion,
      withRevisionDetails: hasApprovedVersion,
      withApprovalsChapter: true,
      withAcknowledgementsChapter: hasApprovedVersion,
    };
  }, [data]);
}

function useExportPolicy() {
  const { t } = useTranslation();

  const [exportPolicy] = useExportPolicyMutation();
  const downloadStorageFile = useDownloadStorageFile();

  return async (input: ExportPolicyMutationVariables['input']) => {
    try {
      const { export_policy } = await exportPolicy({ input }).unwrap();
      if (!export_policy) {
        throw new Error('Policy not found');
      }
      await downloadStorageFile(export_policy.fileId);
      successToast(t('policies.exportModal.successToast'));
    } catch (error) {
      errorToast(t('errorMessages.exportFailed', { entity: t('entities.policy') }));
      datadogLogs.logger.error('Policy export failed', input, toError(error));
    }
  };
}
