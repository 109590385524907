import { Icon, IconButton } from '@chakra-ui/react';
import { PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { isNonNullable } from '@main/shared/utils';
import {
  createColumnHelper,
  Table,
  useDrawer,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { customFieldColumn } from '../custom-fields/custom-field-column';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { getCurrentUserSelectedOrg } from '../user/slice';
import { RISKS_TABLE_FILTER_PARAM_NAME, RISKS_TABLE_SORT_PARAM_NAME } from './constants';
import { useDeleteRisk } from './delete-risk';
import { OrganizationRisk } from './slice';
import { useRiskColumnHelper } from './table-columns';
import { useRisksTableSettings } from './table-settings';
import { useLazyToggleRiskCloseAction } from './use-drawer-actions';
import { useGetRiskTableData } from './use-get-risk-data';

export const RisksTable = () => {
  const { t } = useTranslation();
  const { risks, isRiskLoading } = useGetRiskTableData();
  const { columnVisibility, setColumnVisibility } = useRisksTableSettings(
    'risks:table:column-visibility',
  );
  const columns = useRisksTableColumns();
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: RISKS_TABLE_FILTER_PARAM_NAME,
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({ searchParam: RISKS_TABLE_SORT_PARAM_NAME });
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.risk').toLowerCase(),
      plural: t('entities.plural.risks').toLowerCase(),
    };
  }, [t]);

  return (
    <Table
      minW="1000px"
      entity="risk"
      data={risks ?? []}
      isLoading={isRiskLoading}
      columns={columns}
      itemName={tableItemName}
      pageSize={15}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      sorting={sorting}
      onSortingChange={setSorting}
    />
  );
};

function useRisksTableColumns() {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { id: orgId, is_controls_module_enabled: isControlsModuleEnabled } =
    useAppSelector(getCurrentUserSelectedOrg);

  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId,
    entityName: Field_Entities_Enum.Risk,
  });
  const fieldConfigs = fieldConfigsQuery.data?.field_configs;

  const deleteRisk = useDeleteRisk();
  const toggleCloseRisk = useLazyToggleRiskCloseAction();

  const riskColumnHelper = useRiskColumnHelper();

  return useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationRisk>();
    const openRiskDrawer = (riskId: string) => drawer.open({ entity: 'risk', entityId: riskId });

    return [
      riskColumnHelper.status(),
      riskColumnHelper.internalId(),
      riskColumnHelper.name({ onClickOpenDrawer: true }),
      riskColumnHelper.lastReviewedDate(),
      ...(isControlsModuleEnabled ? [riskColumnHelper.linkedControls()] : []),
      riskColumnHelper.inherentRiskImpact(),
      riskColumnHelper.description(),
      riskColumnHelper.inherentRiskLikelihood(),
      riskColumnHelper.residualRiskImpact(),
      riskColumnHelper.residualRiskLikelihood(),
      riskColumnHelper.inherentRisk(),
      riskColumnHelper.residualRisk(),
      riskColumnHelper.inherentScore(),
      riskColumnHelper.residualScore(),
      riskColumnHelper.tags(),
      riskColumnHelper.category(),
      riskColumnHelper.treatmentPlan(),
      riskColumnHelper.owner({ isEditable: true }),

      ...(fieldConfigs || []).map(customFieldColumn<OrganizationRisk>),

      columnHelper.columns.actions({
        size: 70,
        PrimaryAction: (context) => (
          <IconButton
            minW={4}
            variant="link"
            aria-label={t('risks.actions.edit')}
            icon={<PencilIcon />}
            onClick={() => openRiskDrawer(context.row.original.id)}
          />
        ),
        secondaryActions: ({ row }) =>
          [
            toggleCloseRisk({
              riskId: row.original.id,
              status: row.original.assessment_status,
            }),
            deleteRisk && {
              icon: <Icon as={TrashIcon} />,
              label: t('buttons.delete'),
              onClick: () => {
                deleteRisk(row.original.id);
              },
            },
          ].filter(isNonNullable),
      }),
    ];
  }, [
    riskColumnHelper,
    isControlsModuleEnabled,
    fieldConfigs,
    drawer,
    t,
    toggleCloseRisk,
    deleteRisk,
  ]);
}
