import { ThemeTypings } from '@chakra-ui/react';
import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import {
  CONTROL_STATUS_COLOR,
  POLICY_STATUS_COLOR,
  RISK_ASSESSMENT_STATUS_COLOR,
  VENDOR_ASSESSMENT_STATUS_COLOR,
} from '../shared/status-color';
import { api as getTaskApi } from './get-task.generated';
import { TaskLinkedEntityFragment } from './sidebar/manage-task-sidebar.generated';

const getTaskApiData = createSelector(
  (state: AppRootState, taskId: string) => ({ state, taskId }),
  ({ state, taskId }) => {
    return getTaskApi.endpoints.GetTask.select({
      taskId,
    })(state).data?.tasks_by_pk;
  },
);

export type TaskLinkedEntity = 'control' | 'risk' | 'vendor' | 'policy';
type Status = {
  value: string;
  colorScheme: ThemeTypings['colorSchemes'];
};
export type LinkedEntityObject = Record<
  TaskLinkedEntity,
  {
    id: string;
    status: Status;
    name?: string;
    internal_id?: string;
    owner?: string;
  }[]
>;

export const getLinkedEntities = <T extends TaskLinkedEntityFragment>(task: T) => {
  const linkedEntities: LinkedEntityObject = {
    control: [],
    risk: [],
    vendor: [],
    policy: [],
  };

  task?.controls.forEach(({ control }) =>
    linkedEntities.control.push({
      ...control,
      status: {
        value: control.status,
        colorScheme: CONTROL_STATUS_COLOR[control.status],
      },
      owner: control.assignee?.displayName,
    }),
  );
  task?.risks.forEach(({ risk }) =>
    linkedEntities.risk.push({
      ...risk,
      status: {
        value: risk.assessment_status,
        colorScheme: RISK_ASSESSMENT_STATUS_COLOR[risk.assessment_status],
      },
      owner: risk.assignee?.displayName,
    }),
  );
  task?.vendors.forEach(({ vendor }) =>
    linkedEntities.vendor.push({
      ...vendor,
      status: {
        value: vendor.assessment_status,
        colorScheme: VENDOR_ASSESSMENT_STATUS_COLOR[vendor.assessment_status],
      },
      owner: vendor.owner?.displayName,
    }),
  );
  task?.policies.forEach(({ policy }) =>
    linkedEntities.policy.push({
      ...policy,
      status: {
        value: policy.status,
        colorScheme: POLICY_STATUS_COLOR[policy.status],
      },
      owner: policy.user?.displayName,
    }),
  );

  return linkedEntities;
};

export const getMappedTask = createSelector(getTaskApiData, (task) => {
  if (!task) {
    return;
  }

  const linkedEntities = getLinkedEntities(task);

  return {
    ...task,
    linkedEntities,
  };
});
