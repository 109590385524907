/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
export type NotificationItemFragment = {
  __typename?: 'user_notifications';
  id: string;
  read_at?: string;
  archived_at?: string;
  created_at: string;
  content: {
    __typename?: 'notifications';
    type: Types.Notification_Types_Enum;
    params: ClientTypes.NotificationParams;
    control?: { __typename?: 'controls'; id: string; name?: string; internal_id?: string };
    notifications_comment?: {
      __typename?: 'comments';
      id: string;
      comments_control?: {
        __typename?: 'controls';
        id: string;
        name?: string;
        internal_id?: string;
      };
      comments_task?: { __typename?: 'tasks'; id: string; name?: string; internal_id?: string };
      comments_risk?: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
      comments_vendor?: { __typename?: 'vendors'; id: string; name?: string; internal_id?: string };
      comments_policy?: {
        __typename?: 'policies';
        id: string;
        name?: string;
        internal_id?: string;
      };
      questionnaire?: {
        __typename?: 'questionnaires';
        id: string;
        name?: string;
        internal_id?: string;
      };
      vendor_questionnaire?: {
        __typename?: 'vendor_questionnaires';
        id: string;
        questionnaire: {
          __typename?: 'questionnaires';
          id: string;
          name?: string;
          internal_id?: string;
        };
      };
      client_questionnaire_question?: {
        __typename?: 'client_questionnaire_questions';
        id: string;
        question: string;
        client_questionnaire: {
          __typename?: 'client_questionnaires';
          id: string;
          name?: string;
          internal_id?: string;
        };
      };
    };
    task?: { __typename?: 'tasks'; id: string; name?: string; internal_id?: string };
    policy?: { __typename?: 'policies'; id: string; name?: string; internal_id?: string };
    policy_version?: {
      __typename?: 'policy_versions';
      id: string;
      policy: { __typename?: 'policies'; id: string; name?: string; internal_id?: string };
    };
    notifications_risk?: { __typename?: 'risks'; id: string; name?: string; internal_id?: string };
    notifications_vendor?: {
      __typename?: 'vendors';
      id: string;
      name?: string;
      internal_id?: string;
    };
    notifications_evidence?: {
      __typename?: 'evidences';
      id: string;
      name?: string;
      internal_id?: string;
    };
    notifications_vendor_questionnaire?: {
      __typename?: 'vendor_questionnaires';
      id: string;
      sent_to_email: string;
      vendor: { __typename?: 'vendors'; id: string; name?: string };
      questionnaire: { __typename?: 'questionnaires'; name?: string };
    };
    client_questionnaire?: {
      __typename?: 'client_questionnaires';
      id: string;
      internal_id?: string;
      name?: string;
      company: string;
    };
  };
};

export const NotificationItemFragmentDoc = `
    fragment NotificationItem on user_notifications {
  id
  read_at
  archived_at
  created_at
  content: notification {
    type
    params
    control {
      id
      name
      internal_id
    }
    notifications_comment {
      id
      comments_control {
        id
        name
        internal_id
      }
      comments_task {
        id
        name
        internal_id
      }
      comments_risk {
        id
        name
        internal_id
      }
      comments_vendor {
        id
        name
        internal_id
      }
      comments_policy {
        id
        name
        internal_id
      }
      questionnaire {
        id
        name
        internal_id
      }
      vendor_questionnaire {
        id
        questionnaire {
          id
          name
          internal_id
        }
      }
      client_questionnaire_question {
        id
        question
        client_questionnaire {
          id
          name
          internal_id
        }
      }
    }
    task {
      id
      name
      internal_id
    }
    policy {
      id
      name
      internal_id
    }
    policy_version {
      id
      policy {
        id
        name
        internal_id
      }
    }
    notifications_risk {
      id
      name
      internal_id
    }
    notifications_vendor {
      id
      name
      internal_id
    }
    notifications_evidence {
      id
      name
      internal_id
    }
    notifications_vendor_questionnaire {
      id
      sent_to_email
      vendor {
        id
        name
      }
      questionnaire {
        name
      }
    }
    client_questionnaire {
      id
      internal_id
      name
      company
    }
  }
}
    `;
