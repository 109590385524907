import { registerNotificationHandler } from '../notifications/registry';
import {
  ControlAssignedNotificationHandler,
  ControlNotValidNotificationHandler,
  MentionedInControlCommentNotificationHandler,
} from './notifications';

registerNotificationHandler(ControlAssignedNotificationHandler);
registerNotificationHandler(MentionedInControlCommentNotificationHandler);
registerNotificationHandler(ControlNotValidNotificationHandler);
