import { RiskAssessmentStatus } from '@main/graphql/client-scalars';
import { Notification_Types_Enum } from '@main/graphql/types.generated';
import { useTranslation } from 'react-i18next';

import { router } from '../../router';
import {
  AssignedNotificationAdapter,
  AssignedNotificationData,
  AssignedNotificationHandler,
} from '../notifications/assigned-notification-handler';
import { StatusUpdateContent } from '../notifications/notification-content';
import {
  NotificationContentComponent,
  NotificationContentProps,
  NotificationHandler,
  NotificationItem,
} from '../notifications/notification-handler';

export interface RiskAssignedNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_risk: NonNullable<NotificationItem['content']['notifications_risk']>;
  };
}

export class RiskAssignedNotificationHandler
  implements AssignedNotificationAdapter<RiskAssignedNotification>
{
  static readonly type = Notification_Types_Enum.EntityOwnershipChanged;

  static canHandle(notification: NotificationItem): notification is RiskAssignedNotification {
    return !!notification.content.notifications_risk;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: RiskAssignedNotification): AssignedNotificationData {
    return {
      internalId: notification.content.notifications_risk.internal_id,
      entityName: notification.content.notifications_risk.name,
      content: 'notification.risk.assigned',
    };
  }

  redirect(notification: RiskAssignedNotification) {
    router.navigate({
      to: '/risks',
      search: {
        drawerEntity: 'risk',
        drawerEntityId: notification.content.notifications_risk.id,
      },
    });
  }
}

export interface MentionedInRiskCommentNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_comment: NonNullable<NotificationItem['content']['notifications_comment']> & {
      comments_risk: NonNullable<
        NonNullable<NotificationItem['content']['notifications_comment']>['comments_risk']
      >;
    };
  };
}

export class MentionedInRiskCommentNotificationHandler
  implements AssignedNotificationAdapter<MentionedInRiskCommentNotification>
{
  static readonly type = Notification_Types_Enum.MentionedInComment;

  static canHandle(
    notification: NotificationItem,
  ): notification is MentionedInRiskCommentNotification {
    return !!notification.content.notifications_comment?.comments_risk;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: MentionedInRiskCommentNotification): AssignedNotificationData {
    return {
      internalId: notification.content.notifications_comment.comments_risk.internal_id,
      entityName: notification.content.notifications_comment.comments_risk.name,
      content: 'notification.risk.mentioned',
    };
  }

  redirect(notification: MentionedInRiskCommentNotification) {
    router.navigate({
      to: '/risks',
      search: {
        drawerEntity: 'risk',
        drawerEntityId: notification.content.notifications_comment.comments_risk.id,
        activeTab: 'comments',
      },
    });
  }
}

export interface RiskExpiringNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_risk: NonNullable<NotificationItem['content']['notifications_risk']>;
    params: { riskStatus: RiskAssessmentStatus };
  };
}

export class RiskExpiringNotificationHandler
  implements NotificationHandler<RiskExpiringNotification>
{
  static readonly type = Notification_Types_Enum.RiskStatusChangedToExpiring;

  static canHandle(notification: NotificationItem): notification is RiskExpiringNotification {
    return !!notification.content.notifications_risk && 'riskStatus' in notification.content.params;
  }

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<RiskExpiringNotification, this> {
    return RiskExpiringNotificationContent;
  }

  redirect(notification: RiskExpiringNotification) {
    router.navigate({
      to: '/risks',
      search: {
        drawerEntity: 'risk',
        drawerEntityId: notification.content.notifications_risk.id,
      },
    });
  }
}

function RiskExpiringNotificationContent({
  notification,
}: NotificationContentProps<RiskExpiringNotification>) {
  const { t } = useTranslation();
  const riskStatus = t(
    `risks.enum.assessmentStatus.${notification.content.params.riskStatus}`,
  ).toLowerCase();

  return (
    <StatusUpdateContent
      content={t('notification.risk.needsReview', { riskStatus })}
      internalId={notification.content.notifications_risk.internal_id ?? ''}
      entityName={notification.content.notifications_risk.name ?? ''}
    />
  );
}
