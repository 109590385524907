import { ThemeTypings } from '@chakra-ui/react';
import {
  Client_Questionnaire_Question_Status_Enum,
  Client_Questionnaire_Status_Enum,
} from '@main/graphql/types.generated';
import { useStableCallback } from '@main/shared/utils';
import { ParseKeys } from 'i18next';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

export const CLIENT_QESTIONNAIRE_STATUSES: {
  [key in Client_Questionnaire_Status_Enum]: {
    value: ParseKeys;
    colorScheme: ThemeTypings['colorSchemes'];
  };
} = {
  [Client_Questionnaire_Status_Enum.ExtractingQuestions]: {
    value: 'clientQuestionnaires.statuses.processing',
    colorScheme: 'gray',
  },
  [Client_Questionnaire_Status_Enum.Pending]: {
    value: 'clientQuestionnaires.statuses.pending',
    colorScheme: 'gray',
  },
  [Client_Questionnaire_Status_Enum.ReadyForExtraction]: {
    value: 'clientQuestionnaires.statuses.readyForExtraction',
    colorScheme: 'gray',
  },
  [Client_Questionnaire_Status_Enum.ExtractingCompleted]: {
    value: 'clientQuestionnaires.statuses.pending',
    colorScheme: 'gray',
  },
  [Client_Questionnaire_Status_Enum.InProgress]: {
    value: 'clientQuestionnaires.statuses.inProgress',
    colorScheme: 'orange',
  },
  [Client_Questionnaire_Status_Enum.Expiring]: {
    value: 'clientQuestionnaires.statuses.expiring',
    colorScheme: 'orange',
  },
  [Client_Questionnaire_Status_Enum.Overdue]: {
    value: 'clientQuestionnaires.statuses.overdue',
    colorScheme: 'red',
  },
  [Client_Questionnaire_Status_Enum.ExtractionFailed]: {
    value: 'clientQuestionnaires.statuses.failed',
    colorScheme: 'red',
  },
  [Client_Questionnaire_Status_Enum.Complete]: {
    value: 'clientQuestionnaires.statuses.complete',
    colorScheme: 'green',
  },
};

export function useClientQstatusToOption() {
  const { t } = useTranslation();

  return useStableCallback((status: Client_Questionnaire_Status_Enum) => {
    const metaType = CLIENT_QESTIONNAIRE_STATUSES[status];
    return {
      value: status,
      label: t(metaType.value),
      colorScheme: metaType.colorScheme,
    };
  });
}

export const CLIENT_QESTIONNAIRE_QUESTION_STATUSES: {
  [key in Client_Questionnaire_Question_Status_Enum]: {
    value: ParseKeys;
    colorScheme: ThemeTypings['colorSchemes'];
  };
} = {
  [Client_Questionnaire_Question_Status_Enum.Pending]: {
    value: 'clientQuestionnaire.statuses.pending',
    colorScheme: 'gray',
  },
  [Client_Questionnaire_Question_Status_Enum.ReadyForReview]: {
    value: 'clientQuestionnaire.statuses.readyForReview',
    colorScheme: 'orange',
  },
  [Client_Questionnaire_Question_Status_Enum.Approved]: {
    value: 'clientQuestionnaire.statuses.approved',
    colorScheme: 'green',
  },
  [Client_Questionnaire_Question_Status_Enum.Processing]: {
    value: 'clientQuestionnaire.statuses.processing',
    colorScheme: 'gray',
  },
  [Client_Questionnaire_Question_Status_Enum.ProcessingFailed]: {
    value: 'clientQuestionnaire.statuses.processingFailed',
    colorScheme: 'red',
  },
};

export const CHANGABLE_CLIENT_Q_QUESTION_STATUSES = [
  Client_Questionnaire_Question_Status_Enum.Pending,
  Client_Questionnaire_Question_Status_Enum.ReadyForReview,
  Client_Questionnaire_Question_Status_Enum.Approved,
];

export const ALLOWED_CLIENT_Q_QUESTION_STATUSES = Object.fromEntries(
  Object.entries(CLIENT_QESTIONNAIRE_QUESTION_STATUSES).filter(([key]) =>
    CHANGABLE_CLIENT_Q_QUESTION_STATUSES.includes(key as Client_Questionnaire_Question_Status_Enum),
  ),
) as typeof CLIENT_QESTIONNAIRE_QUESTION_STATUSES;

export function useClientQquestionStatusToOption() {
  const { t } = useTranslation();

  return useStableCallback((status: Client_Questionnaire_Question_Status_Enum) => {
    const metaType = CLIENT_QESTIONNAIRE_QUESTION_STATUSES[status];
    return {
      value: status,
      label: t(metaType.value),
      colorScheme: metaType.colorScheme,
    };
  });
}

export function useClientQquestionAllowedStatusOptions() {
  const { t } = useTranslation();

  return useMemo(
    () =>
      Object.entries(ALLOWED_CLIENT_Q_QUESTION_STATUSES).map(([key, value]) => ({
        value: key as Client_Questionnaire_Question_Status_Enum,
        label: t(value.value),
        colorScheme: value.colorScheme,
      })),
    [t],
  );
}
