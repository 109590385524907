import { Avatar, Flex, Link, Text, Tooltip } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowDownTrayIcon, DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { getHashedAvatarColor } from '../../utils';
import { useDownloadStorageFile } from '../hooks';
import { OverflowContainer } from '../overflow-tooltip';
import { Card, CardActionsProps } from './base-card';

const urlSchema = z.string().url();

interface VersionCardProps extends CardActionsProps {
  size?: 'sm' | 'lg';
  url?: string;
  fileName?: string;
  fileId?: string | null;
  validityStart?: string;
  createdBy?: string;
}

export const CurrentVersionCard = ({
  size,
  url,
  fileId,
  fileName,
  validityStart,
  createdBy,
  actions,
  numberOfInlineActions,
}: VersionCardProps) => {
  const { t } = useTranslation('ui');
  const downloadFile = useDownloadStorageFile();
  const copyToClipboard = (url: string) => navigator.clipboard.writeText(url);
  const type = url && urlSchema.safeParse(url).success ? 'link' : 'file';

  if (type === 'file' && (!fileId || !fileName)) {
    datadogLogs.logger.error('Document version must have fileId and fileName', {
      fileId,
      fileName,
    });
  }

  return (
    <Card size={size}>
      <Card.Header
        left={
          <Card.Tags
            tags={[
              {
                label: t(`cards.version.type.${type}`),
                colorSchema: 'purple',
              },
            ]}
          />
        }
        right={
          <Flex gap={4} alignItems="center">
            <Tooltip
              label={t('cards.validityDate')}
              placement="top"
              hasArrow
              gutter={12}
              openDelay={600}
            >
              <Text fontSize="xs" color="gray.500">
                {validityStart}
              </Text>
            </Tooltip>
            {createdBy && (
              <Tooltip label={createdBy} placement="top" hasArrow gutter={12} openDelay={600}>
                <Avatar size="xs" name={createdBy} {...getHashedAvatarColor(createdBy)} />
              </Tooltip>
            )}
          </Flex>
        }
        actions={[
          type === 'file' && fileId
            ? {
                icon: ArrowDownTrayIcon,
                label: t('cards.actions.download'),
                onClick: () => downloadFile(fileId),
              }
            : undefined,
          type === 'link' && url
            ? {
                icon: DocumentDuplicateIcon,
                label: t('cards.actions.copyLink'),
                onClick: () => copyToClipboard(url),
              }
            : undefined,
          ...(actions ? [...actions] : []),
        ]}
        numberOfInlineActions={numberOfInlineActions ?? 3}
      />

      <Card.Body>
        <OverflowContainer>
          <OverflowContainer.Tooltip
            label={type === 'link' ? url : fileName}
            placement="top"
            gutter={12}
            openDelay={500}
          >
            {type === 'link' ? (
              <Link
                noOfLines={1}
                fontSize="sm"
                isExternal
                href={url}
                lineHeight={7}
                color="blue.400"
                /* This is important to make sure that very long link will not push action buttons out of the card */
                maxW={['3xs', '2xs']}
                _dark={{ color: 'blue.200' }}
              >
                {url}
              </Link>
            ) : (
              <Text
                noOfLines={1}
                fontSize="sm"
                fontWeight="medium"
                lineHeight={7}
                color="gray.700"
                /**
                 * If there is a case when file name is very long single word,
                 * then truncation will not work and text will push the action buttons out of the card,
                 * to avoid this we need to set maxW to a value that will make sure that the text will not overflow
                 */
                maxW={['3xs', '2xs']}
                _dark={{ color: 'gray.200' }}
              >
                {fileName}
              </Text>
            )}
          </OverflowContainer.Tooltip>
        </OverflowContainer>
      </Card.Body>
    </Card>
  );
};
