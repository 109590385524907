import { registerNotificationHandler } from '../notifications/registry';
import {
  ClientQassignedNotificationHandler,
  ClientQprocessingDoneNotificationHandler,
  ClientQprocessingFailedNotificationHandler,
  ClientQuestionnaireExpiringNotificationHandler,
  MentionedInCleintQquestionCommentNotificationHandler,
} from './notifications';

registerNotificationHandler(ClientQprocessingDoneNotificationHandler);
registerNotificationHandler(ClientQprocessingFailedNotificationHandler);
registerNotificationHandler(ClientQassignedNotificationHandler);
registerNotificationHandler(MentionedInCleintQquestionCommentNotificationHandler);
registerNotificationHandler(ClientQuestionnaireExpiringNotificationHandler);
