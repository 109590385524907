import { BoxProps, Input, Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { LinkIcon, TrashIcon } from '@heroicons/react/24/outline';
import { Policy_Version_Statuses_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import { DrawerProperty, errorToast, successToast, useAlertDialog } from '@main/ui';
import { CardButton, CurrentVersionCard } from '@main/ui/cards';
import { useFileUpload } from '@nhost/react';
import { ChangeEvent, ReactNode, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useUpdatePolicyVersionContentMutation } from './manage-policies.generated';
import { usePreferredPolicyVersion } from './use-preferred-policy-version';

export const PolicyUploadVersion = () => {
  const { t } = useTranslation();
  const { openDialog } = useAlertDialog();
  const currentVersion = usePreferredPolicyVersion();
  const [updateVersion, { isLoading: isDeletingCurrentVersion }] =
    useUpdatePolicyVersionContentMutation();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canDeleteCurrenVersion =
    userRole.permissionMap?.write_policies &&
    currentVersion?.status === Policy_Version_Statuses_Enum.Draft;

  const deleteVersionUpload = async () => {
    if (!currentVersion) {
      return;
    }

    try {
      await updateVersion({
        policyVersionId: currentVersion.id,
        fileId: null,
      }).unwrap();
      successToast(t('policies.versions.deleteSucceeded'));
    } catch (error) {
      errorToast(t('policies.versions.deleteFailed'));
      datadogLogs.logger.error(
        'Deleting policy version file failed',
        { policyVersion: currentVersion.id },
        toError(error),
      );
    }
  };

  if (!currentVersion || !currentVersion.file) {
    return (
      <Container>
        <VersionUploadButton />
      </Container>
    );
  }

  return (
    <Container>
      <CurrentVersionCard
        fileId={currentVersion.file?.id}
        fileName={currentVersion.file.name}
        actions={[
          canDeleteCurrenVersion
            ? {
                icon: TrashIcon,
                label: t('buttons.delete'),
                onClick: () =>
                  openDialog({
                    dialogHeader: t('policies.versions.alert.delete.header'),
                    dialogContent: t('policies.versions.alert.delete.content'),
                    confirmAction: {
                      children: t('policies.versions.alert.delete.confirm'),
                      onClick: deleteVersionUpload,
                      isLoading: isDeletingCurrentVersion,
                    },
                  }),
              }
            : undefined,
        ]}
      />
    </Container>
  );
};

function VersionUploadButton() {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const currentVersion = usePreferredPolicyVersion();
  const canEditPolicy =
    userRole.permissionMap?.write_policies &&
    currentVersion?.status === Policy_Version_Statuses_Enum.Draft;
  const { upload, isUploading } = useFileUpload();
  const [updateVersion, { isLoading: isUpdatingVersion }] = useUpdatePolicyVersionContentMutation();

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!currentVersion || !event.target.files) {
      return;
    }

    try {
      const file = await upload({
        file: event.target.files[0],
      });

      await updateVersion({
        policyVersionId: currentVersion.id,
        fileId: file.id,
      }).unwrap();
      successToast(t('policies.versions.successfullyUploaded'));
    } catch (error) {
      errorToast(t('policies.versions.failedToUpload'));
      datadogLogs.logger.error(
        'Uploading policy version file failed',
        { policyVersion: currentVersion.id },
        toError(error),
      );
    }
  };

  if (!canEditPolicy) {
    return (
      <Text fontSize="xs" textColor="gray.500" _dark={{ color: 'gray.400' }}>
        {t('policies.placeholder.noVersion')}
      </Text>
    );
  }

  return (
    <>
      <CardButton
        onClick={() => inputRef.current?.click()}
        isLoading={isUploading || isUpdatingVersion}
      >
        {t('policies.placeholder.clickToAddVersion')}
      </CardButton>
      <Input
        display="none"
        type="file"
        accept=".doc, .docx, .pdf"
        ref={inputRef}
        onChange={handleUpload}
      />
    </>
  );
}

interface ContainerProps extends BoxProps {
  children: ReactNode;
}
const Container = ({ children, ...props }: ContainerProps) => {
  const { t } = useTranslation();
  return (
    <DrawerProperty>
      <DrawerProperty.Label icon={LinkIcon}>
        {t('evidences.version.currentVersion')}
      </DrawerProperty.Label>
      <DrawerProperty.Content px={2} {...props} w="full" display="flex" alignItems="center">
        {children}
      </DrawerProperty.Content>
    </DrawerProperty>
  );
};
