import { BulkDownloaderLogger } from '../logger';
import { MergedAbortController } from '../merge-abort-controller';
import { assertNotAborted, awaitUntil } from '../promise-utils';
import { StreamSink } from '../stream-sink';
import { ServiceWorkerFileStreamer } from './file-streamer';
import { LoggerSink } from './logger';
import {
  ServiceWorkerStreamPortalSource,
  ServiceWorkerStreamSinkData,
  ServiceWorkerStreamSinkResult,
} from './sw-downloader';

export class ServiceWorkerStreamSink implements StreamSink {
  protected readonly id = this.generateId();
  protected readonly fileName = this.streamer.getFileName();
  protected readonly abortController = new AbortController();
  protected readonly swLoggerSink = new LoggerSink(navigator.serviceWorker, this.logger);
  protected readonly downloadUrl = Promise.withResolvers<string>();
  protected readonly streamReady;

  constructor(
    protected readonly serviceWorker: ServiceWorker,
    protected readonly swStreamPortal: ServiceWorkerStreamPortalSource,
    protected readonly streamer: ServiceWorkerFileStreamer,
    protected readonly logger: BulkDownloaderLogger = console,
  ) {
    navigator.serviceWorker.addEventListener('message', (event) => this.handleSWMessage(event), {
      signal: this.abortController.signal,
    });

    this.logger.debug('ServiceWorkerStreamSink Sending stream to SW', {
      id: this.id,
      fileName: this.fileName,
    });
    this.streamReady = this.swStreamPortal.send(this.streamer.getStream(), {
      id: this.id,
      fileName: this.fileName,
    });
  }

  async streamFile(
    stream: ReadableStream,
    fileName: string,
    options?: { signal?: AbortSignal },
  ): Promise<void> {
    assertNotAborted(this.abortController.signal);
    assertNotAborted(options?.signal);

    const localAbortController = new MergedAbortController(this.abortController.signal);

    if (options?.signal) {
      localAbortController.merge(options.signal);
    }

    await awaitUntil(this.streamReady, localAbortController.signal);

    this.logger.debug('ServiceWorkerStreamSink Streaming file', { id: this.id, fileName });
    await this.streamer.streamFile(stream, fileName, { signal: localAbortController.signal });
    this.logger.debug('ServiceWorkerStreamSink Streamed file', { id: this.id, fileName });

    localAbortController.abort(new Error('StreamZipped'));
  }

  async finalize() {
    try {
      this.logger.debug('ServiceWorkerStreamSink Finalizing', { id: this.id });
      await this.streamer.finalize();
      await this.downloadUrl.promise;
    } finally {
      await this.abort('Finalized');
    }
  }

  async dispose() {
    try {
      this.logger.debug('ServiceWorkerStreamSink Disposing', { id: this.id });
      await this.streamer.finalize();
      await this.abort('Disposed');
    } catch {
      await this.abort('Disposed');
    }
  }

  async abort(reason?: unknown) {
    if (this.abortController.signal.aborted) {
      return;
    }

    this.logger.debug('ServiceWorkerStreamSink Aborting', { id: this.id, reason });

    try {
      this.downloadUrl.reject(reason);
      await this.swStreamPortal.dispose?.();
    } finally {
      this.sendSWMessage({ type: 'stream-sink', id: this.id, done: true });
      this.abortController.abort(reason);
      // Wait for SW to flush final logs
      setTimeout(() => this.swLoggerSink.dispose(), 100);
    }
  }

  protected sendSWMessage(data: ServiceWorkerStreamSinkData) {
    this.serviceWorker.postMessage(data);
  }

  protected handleSWMessage(e: MessageEvent<unknown>) {
    const data = e.data as ServiceWorkerStreamSinkResult;

    if (!data || data.type !== 'stream-sink' || data.id !== this.id) {
      return;
    }

    this.logger.debug('ServiceWorkerStreamSink Opening download URL', {
      id: this.id,
      url: data.url,
    });
    window.open(data.url, '_blank');
    this.downloadUrl.resolve(data.url);
  }

  protected generateId(): string {
    return crypto.randomUUID();
  }
}
