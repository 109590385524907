import { Button, ButtonProps, useColorModeValue } from '@chakra-ui/react';
import { PropsWithChildren } from 'react';

export const CardButton = (props: PropsWithChildren<ButtonProps>) => {
  const bgColor = useColorModeValue('gray.25', 'gray.700');
  const borderColor = useColorModeValue('gray.50', 'gray.600');
  const textColor = useColorModeValue('gray.600', 'gray.400');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const hoverTextColor = useColorModeValue('gray.700', 'gray.300');

  return (
    <Button
      {...props}
      w="full"
      p={3}
      textColor={textColor}
      fontSize="xs"
      fontWeight="normal"
      shadow="sm"
      bg={bgColor}
      border="1px"
      borderColor={borderColor}
      rounded="lg"
      _hover={{ bg: hoverBgColor, borderColor: hoverBgColor, textColor: hoverTextColor }}
    />
  );
};
