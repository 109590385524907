/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type VqCombinedQuestionnaireForDownloadFragment = {
  __typename?: 'questionnaires';
  id: string;
  name?: string;
  internal_id?: string;
  form?: { __typename?: 'forms'; id: string; config?: ClientTypes.FormConfig };
  vendor_questionnaires: Array<{
    __typename?: 'vendor_questionnaires';
    id: string;
    due_date?: string;
    vendor: { __typename?: 'vendors'; id: string; internal_id?: string; name?: string };
    form?: {
      __typename?: 'vendor_questionnaire_forms';
      config_snapshot?: ClientTypes.FormConfig;
      answers: Array<{
        __typename?: 'vendor_questionnaire_form_answers';
        field_name: string;
        status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
        value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
      }>;
    };
  }>;
};

export type VqCombinedForDownloadFragment = {
  __typename?: 'vendor_questionnaires';
  id: string;
  due_date?: string;
  vendor: { __typename?: 'vendors'; id: string; internal_id?: string; name?: string };
  form?: {
    __typename?: 'vendor_questionnaire_forms';
    config_snapshot?: ClientTypes.FormConfig;
    answers: Array<{
      __typename?: 'vendor_questionnaire_form_answers';
      field_name: string;
      status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
      value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
    }>;
  };
};

export type VqCombinedAnswerForDownloadFragment = {
  __typename?: 'vendor_questionnaire_form_answers';
  field_name: string;
  status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
  value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
};

export type GetVqCombinedQuestionnaireForDownloadQueryVariables = Types.Exact<{
  questionnaireId: Types.Scalars['uuid']['input'];
}>;

export type GetVqCombinedQuestionnaireForDownloadQuery = {
  __typename?: 'query_root';
  questionnaire?: {
    __typename?: 'questionnaires';
    id: string;
    name?: string;
    internal_id?: string;
    form?: { __typename?: 'forms'; id: string; config?: ClientTypes.FormConfig };
    vendor_questionnaires: Array<{
      __typename?: 'vendor_questionnaires';
      id: string;
      due_date?: string;
      vendor: { __typename?: 'vendors'; id: string; internal_id?: string; name?: string };
      form?: {
        __typename?: 'vendor_questionnaire_forms';
        config_snapshot?: ClientTypes.FormConfig;
        answers: Array<{
          __typename?: 'vendor_questionnaire_form_answers';
          field_name: string;
          status: Types.Vendor_Questionnaire_Form_Answer_Statuses_Enum;
          value?: ClientTypes.VendorQuestionnaireFormAnswerValue;
        }>;
      };
    }>;
  };
};

export const VqCombinedAnswerForDownloadFragmentDoc = `
    fragment VqCombinedAnswerForDownload on vendor_questionnaire_form_answers {
  field_name
  status
  value
}
    `;
export const VqCombinedForDownloadFragmentDoc = `
    fragment VqCombinedForDownload on vendor_questionnaires {
  id
  due_date
  vendor {
    id
    internal_id
    name
  }
  form {
    config_snapshot
    answers {
      ...VqCombinedAnswerForDownload
    }
  }
}
    `;
export const VqCombinedQuestionnaireForDownloadFragmentDoc = `
    fragment VqCombinedQuestionnaireForDownload on questionnaires {
  id
  name
  internal_id
  form {
    id
    config
  }
  vendor_questionnaires(
    where: {status: {_eq: submitted}}
    order_by: {vendor: {name: asc}}
  ) {
    ...VqCombinedForDownload
  }
}
    `;
export const GetVqCombinedQuestionnaireForDownloadDocument = `
    query GetVqCombinedQuestionnaireForDownload($questionnaireId: uuid!) {
  questionnaire: questionnaires_by_pk(id: $questionnaireId) {
    ...VqCombinedQuestionnaireForDownload
  }
}
    ${VqCombinedQuestionnaireForDownloadFragmentDoc}
${VqCombinedForDownloadFragmentDoc}
${VqCombinedAnswerForDownloadFragmentDoc}` as string &
  TypedDocumentNode<
    GetVqCombinedQuestionnaireForDownloadQuery,
    GetVqCombinedQuestionnaireForDownloadQueryVariables
  >;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetVqCombinedQuestionnaireForDownload: build.query<
      GetVqCombinedQuestionnaireForDownloadQuery,
      GetVqCombinedQuestionnaireForDownloadQueryVariables
    >({
      query: (variables) => ({
        document: GetVqCombinedQuestionnaireForDownloadDocument,
        variables,
      }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useGetVqCombinedQuestionnaireForDownloadQuery,
  useLazyGetVqCombinedQuestionnaireForDownloadQuery,
} = injectedRtkApi;
