import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Stack,
  Text,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { PlusIcon } from '@heroicons/react/24/outline';
import { useAddVendorMutation } from '@main/graphql/mutations/InsertVendor.generated';
import { toError } from '@main/shared/utils';
import { errorToast, successToast, useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useGetPollingInterval } from '../../hooks/use-get-polling-interval';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgRole } from '../user/slice';
import { useGetVendorsQuery } from './get-vendor.generated';
import { VendorsTable } from './vendors-table';

export const VendorManagement = () => {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateVendor = userRole.permissionMap?.write_vendors;
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const pollingInterval = useGetPollingInterval(5000);

  const [createVendor, { isLoading: isCreatingRisk }] = useAddVendorMutation();
  const { refetch: refetchVendors } = useGetVendorsQuery(
    { organization_id: organization.id },
    { pollingInterval },
  );

  const onVendorCreate = async () => {
    try {
      const vendorDrawer = await drawer.openLoading({ entity: 'vendor' });

      const createVendorResult = await createVendor({
        vendor_input: {
          organization_id: organization.id,
        },
      }).unwrap();

      if (!createVendorResult.insert_vendors_one?.id) {
        throw new Error('Could not get vendor id from response');
      }

      vendorDrawer.load({ entityId: createVendorResult.insert_vendors_one?.id });
      await refetchVendors().unwrap();
      successToast(t('successMessages.createSucceeded', { entity: t('entities.vendor') }));
    } catch (error) {
      drawer.close();
      errorToast(t('errorMessages.createFailed', { entity: t('entities.vendor') }));
      datadogLogs.logger.error('Vendor create failed', {}, toError(error));
    }
  };

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {t('vendors.breadcrumbTitle')}
      </Text>

      <Card variant="table-styles">
        <CardHeader>
          <Box>
            <Heading size="md">{t('vendors.heading')}</Heading>
            <Text variant="subheading">{t('vendors.subheading')}</Text>
          </Box>
          {canCreateVendor && (
            <Button
              leftIcon={<Icon color="white" _dark={{ color: 'black' }} w={4} h={4} as={PlusIcon} />}
              colorScheme="blue"
              variant="solid"
              isLoading={isCreatingRisk}
              onClick={onVendorCreate}
            >
              {t('vendors.addButton')}
            </Button>
          )}
        </CardHeader>

        <CardBody>
          <VendorsTable />
        </CardBody>
      </Card>
    </Stack>
  );
};
