import {
  Box,
  Button,
  ButtonProps,
  Divider,
  Flex,
  forwardRef,
  IconButton,
  IconButtonProps,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Text,
  Tooltip,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { ArrowLeftIcon, ArrowRightIcon, EllipsisHorizontalIcon } from '@heroicons/react/20/solid';
import { ChevronLeftIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { useRouter } from '@tanstack/react-router';
import { Fragment, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useDrawer } from './use-drawer';
import { getIconVariantActions, getOutlineVariantActions } from './utils';

declare global {
  interface Window {
    navigation?: {
      canGoBack: boolean;
      canGoForward: boolean;
    };
  }
}

interface BaseAction {
  label: string;
  onClick: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  children?: ReactElement;
}

export type OutlineVariant = BaseAction & {
  variant: 'outline';
};

export type IconVariant = BaseAction & {
  variant: 'icon';
  icon: ReactElement;
};

export type SecondaryAction = OutlineVariant | IconVariant;

export type PrimaryAction = BaseAction;

export type MenuAction = BaseAction & {
  icon: ReactElement;
};

export type DrawerActionsProps = {
  primaryAction?: PrimaryAction | null;
  secondaryActions?: SecondaryAction[];
  menuActions?: MenuAction[];
};

export const DrawerActions = (props: DrawerActionsProps & { info?: string }) => {
  const isMobile = useBreakpointValue({ base: true, md: false }, { fallback: 'md' });
  const bgColor = useColorModeValue('white', 'gray.700');

  return (
    <Box
      role="toolbar"
      aria-label="Drawer actions"
      position="sticky"
      top={0}
      zIndex={1}
      bg={bgColor}
    >
      {isMobile ? <DrawerActionsMobile {...props} /> : <DrawerActionsDesktop {...props} />}
    </Box>
  );
};

const DrawerActionsMobile = ({
  primaryAction,
  secondaryActions,
  menuActions,
}: DrawerActionsProps) => {
  const borderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <Flex
      gap={3}
      py={3}
      pl={4}
      pr={5}
      borderBottom="1px"
      borderColor={borderColor}
      alignItems="center"
    >
      <CloseDrawerButton icon={<ChevronLeftIcon />} />
      <Spacer />
      <ToolbarActions primaryAction={primaryAction} secondaryActions={secondaryActions} />
      <MenuActions actions={menuActions} />
    </Flex>
  );
};

const DrawerActionsDesktop = ({
  info,
  primaryAction,
  secondaryActions,
  menuActions,
}: DrawerActionsProps & { info?: string }) => {
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const infoTextColor = useColorModeValue('gray.400', 'gray.600');

  return (
    <Flex
      height={12}
      justifyContent="space-between"
      borderBottom="1px"
      borderColor={borderColor}
      py={2}
      pl={4}
      pr={5}
    >
      <NavigationButtons />
      <Flex gap={3} alignItems="center">
        <Text fontSize="xs" color={infoTextColor}>
          {info}
        </Text>
        <ToolbarActions primaryAction={primaryAction} secondaryActions={secondaryActions} />
        <MenuActions actions={menuActions} />
        <CloseDrawerButton icon={<XMarkIcon />} />
      </Flex>
    </Flex>
  );
};

const CloseDrawerButton = ({ icon }: { icon: ReactElement }) => {
  const { t } = useTranslation('ui');
  const drawer = useDrawer();

  return (
    <IconAction
      label={t('drawer.actions.close')}
      icon={icon}
      onClick={() => drawer.close()}
      aria-label={t('drawer.actions.close')}
    />
  );
};

const NavigationButtons = () => {
  const { t } = useTranslation('ui');
  const router = useRouter();
  const canGoBack = window.navigation?.canGoBack ?? true;
  const canGoForward = window.navigation?.canGoForward ?? true;

  return (
    <Flex gap={3}>
      <IconAction
        label={t('drawer.actions.back')}
        isDisabled={!canGoBack}
        icon={<ArrowLeftIcon />}
        aria-label={t('drawer.actions.back')}
        onClick={() => router.history.back()}
      />
      <IconAction
        label={t('drawer.actions.forward')}
        isDisabled={!canGoForward}
        icon={<ArrowRightIcon />}
        aria-label={t('drawer.actions.forward')}
        onClick={() => router.history.forward()}
      />
    </Flex>
  );
};

const ToolbarActions = ({ primaryAction, secondaryActions }: DrawerActionsProps) => {
  if (!primaryAction && !secondaryActions?.length) {
    return null;
  }

  const iconButtons = getIconVariantActions(secondaryActions);
  const outlineButtons = getOutlineVariantActions(secondaryActions);

  const renderPrimaryAction = () => {
    if (!primaryAction) {
      return null;
    }

    const { children, ...primaryActionProps } = primaryAction;
    return (
      <>
        <ButtonAction {...primaryActionProps} colorScheme="blue" />
        {children}
      </>
    );
  };

  return (
    <>
      <Flex gap={2} alignItems="center">
        {outlineButtons.map(({ children, ...props }) => (
          <Fragment key={props.label}>
            <ButtonAction {...props} />
            {children}
          </Fragment>
        ))}
        {renderPrimaryAction()}
      </Flex>
      {(outlineButtons.length > 0 || primaryAction) && (
        <Box h={4} borderColor="gray.300">
          <Divider orientation="vertical" />
        </Box>
      )}
      {iconButtons.map(({ children, ...props }) => {
        return (
          <Fragment key={props.label}>
            <IconAction aria-label={props.label} color="gray.500" {...props} />
            {children}
          </Fragment>
        );
      })}
    </>
  );
};

const MenuActions = ({ actions }: { actions: MenuAction[] | undefined }) => {
  const { t } = useTranslation('ui');

  if (!actions?.length) {
    return null;
  }

  return (
    <Menu>
      <MenuButton
        label={t('drawer.actions.more')}
        aria-label={t('drawer.actions.more')}
        icon={<EllipsisHorizontalIcon />}
        as={IconAction}
      />
      <MenuList>
        {actions.map((action) => (
          <MenuItem
            key={action.label}
            icon={action.icon}
            iconSpacing={2}
            onClick={action.onClick}
            fontSize="24px"
          >
            <Text fontSize="sm">{action.label}</Text>
          </MenuItem>
        ))}
      </MenuList>

      {actions.map((action) => (
        <Fragment key={action.label}>{action.children}</Fragment>
      ))}
    </Menu>
  );
};

type ActionButtonProps = Omit<PrimaryAction, 'type'> & ButtonProps;
const ButtonAction = forwardRef(({ label, ...props }: ActionButtonProps, ref) => {
  return (
    <Button ref={ref} aria-label={label} {...props} size="sm">
      {label}
    </Button>
  );
});

type IconActionProps = Omit<SecondaryAction, 'type' | 'variant'> & IconButtonProps;
const IconAction = forwardRef(({ label, ...props }: IconActionProps, ref) => {
  return (
    <Tooltip label={label}>
      <IconButton
        ref={ref}
        minW={5}
        variant="link"
        // Prevent tooltip appearing when this button opens a modal dialog.
        onFocus={(event) => event.preventDefault()}
        {...props}
      />
    </Tooltip>
  );
});
