import { api as getReportByIdApi } from '@main/graphql/queries/GetReportById.generated';
import { isColumnFilter, isColumnSort } from '@main/ui';
import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';

const getOrganizationReportApiData = createSelector(
  (state: AppRootState, reportId: string) => ({ reportId, state }),
  ({ state, reportId }) => {
    return getReportByIdApi.endpoints.GetReportById.select({
      id: reportId,
    })(state).data?.reports_by_pk;
  },
);

export const getReportById = createSelector([getOrganizationReportApiData], (report) => {
  if (!report) {
    return undefined;
  }

  return {
    ...report,
    filters: report.filters?.filter(isColumnFilter) ?? [],
    sorting: report.sorting?.filter(isColumnSort) ?? [],
  };
});
