import { Input, InputProps, Stack, useFormControl } from '@chakra-ui/react';
import { useEffectOnce } from '@main/shared/utils';
import {
  ForwardedRef,
  forwardRef,
  InputHTMLAttributes,
  PropsWithChildren,
  useRef,
  useState,
} from 'react';

import { UploadFileCard } from '../cards';
import { FileInputContext, useFileInputContext } from './context';
import { Dropzone, DropzoneInput } from './dropzone';
import { NhostFileUploader } from './nhost-upload-files';
import { UploadedFile } from './shared';

export type FileInputProps<T extends UploadedFile = UploadedFile> = Omit<
  FileInputContext<T>,
  'registerReuploadCb'
>;

/**
 * File list + upload dropzone.
 *
 * Props should not be provided directly, and instead one of the following
 * supportive hooks should be used:
 * - useEagerFileUpload
 * - useEagerMultipleFilesUpload
 * - useLazyFileUpload
 * - useLazyMultipleFilesUpload
 *
 * @example
 * const fileUpload = useEagerFileUpload({ ... });
 *
 * <FileUpload {...fileUpload}>
 *   <FileUpload.Dropzone {...} />
 * </FileUpload>
 */
export const FileUpload = <T extends UploadedFile>({
  children,
  ...props
}: PropsWithChildren<FileInputProps<T>>) => {
  const {
    uploadedFiles = [],
    onUploadedFileDownload,
    onUploadedFileDelete,
    onUploadedFileReupload,
    stagedFiles = [],
    onStagedFileDelete,
  } = props;

  const [loadingFileIds, setLoadingFileIds] = useState<string[]>([]);
  const reuploadCbs = useRef<Set<(file: T) => void>>(new Set());

  function registerReuploadCb(cb: (file: T) => void) {
    reuploadCbs.current.add(cb);
    return () => reuploadCbs.current.delete(cb);
  }

  function handleReupload(file: T) {
    reuploadCbs.current.forEach((cb) => cb(file));
  }

  async function handleDelete(file: T) {
    setLoadingFileIds((prevDeletingFileIds) => [...prevDeletingFileIds, file.id]);
    try {
      await onUploadedFileDelete?.(file);
    } finally {
      setLoadingFileIds((prevDeletingFileIds) =>
        prevDeletingFileIds.filter((fileId) => fileId !== file.id),
      );
    }
  }

  const context: FileInputContext<T> = {
    ...props,
    registerReuploadCb,
  };

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <FileInputContext.Provider value={context as FileInputContext<any>}>
      <Stack w="full" spacing={3}>
        {uploadedFiles.map((file) => {
          return (
            <UploadFileCard
              key={file.id}
              file={file}
              isUploaded
              isLoading={loadingFileIds.includes(file.id)}
              onDownload={onUploadedFileDownload && (() => onUploadedFileDownload?.(file.id))}
              onReupload={onUploadedFileReupload ? () => handleReupload(file) : undefined}
              onDelete={onUploadedFileDelete && (() => handleDelete(file))}
            />
          );
        })}

        {stagedFiles.map((file) => {
          return <NhostFileUploader key={file.id} fileRef={file} onDelete={onStagedFileDelete} />;
        })}
        {children}
      </Stack>
    </FileInputContext.Provider>
  );
};

type FileUploadInputProps = Omit<InputProps, 'multiple' | 'onChange' | 'type'>;
const FileUploadInput = forwardRef(
  (props: FileUploadInputProps, forwardedRef: ForwardedRef<HTMLInputElement>) => {
    const {
      uploadedFiles,
      stagedFiles,
      isMulti,
      onStagedFilesAdd,
      onUploadedFileReupload,
      registerReuploadCb,
    } = useFileInputContext();
    const { readOnly } = useFormControl({});
    const reuploadRef = useRef<HTMLInputElement>();
    const reuploadingFile = useRef<UploadedFile>();

    useEffectOnce(() =>
      registerReuploadCb((file) => {
        reuploadingFile.current = file;
        reuploadRef.current?.click();
      }),
    );

    if (readOnly || (!isMulti && (uploadedFiles?.length || stagedFiles?.length))) {
      return (
        <Input
          type="file"
          style={{ display: 'none' }}
          onChange={(event) => {
            event.target.files?.length &&
              reuploadingFile.current &&
              void onUploadedFileReupload?.(
                reuploadingFile.current,
                Array.from(event.target.files),
              );
            reuploadingFile.current = undefined;
            event.target.value = '';
          }}
          {...props}
          ref={reuploadRef}
        />
      );
    }

    return (
      <Input
        {...props}
        ref={forwardedRef}
        type="file"
        multiple={isMulti}
        onChange={(event) => {
          if (!event.target.files) {
            return;
          }
          onStagedFilesAdd?.(Array.from(event.target.files));
          event.target.value = '';
        }}
      />
    );
  },
);
FileUpload.Input = FileUploadInput;

type FileInputDropzoneProps = {
  topLabelText?: string;
  bottomLabelText?: string;
  constraintText?: string;
  inputProps?: InputHTMLAttributes<HTMLInputElement>;
  onDragLeave?: () => void;
};
const FileInputDropzone = ({
  topLabelText,
  bottomLabelText,
  constraintText,
  inputProps,
  onDragLeave,
}: FileInputDropzoneProps) => {
  const {
    uploadedFiles,
    stagedFiles,
    isMulti,
    onStagedFilesAdd,
    onUploadedFileReupload,
    registerReuploadCb,
  } = useFileInputContext();
  const { readOnly } = useFormControl({});
  const reuploadRef = useRef<HTMLInputElement | null>(null);
  const reuploadingFile = useRef<UploadedFile>();

  useEffectOnce(() =>
    registerReuploadCb((file) => {
      reuploadingFile.current = file;
      reuploadRef.current?.click();
    }),
  );

  if (readOnly || (!isMulti && (uploadedFiles?.length || stagedFiles?.length))) {
    return (
      <DropzoneInput
        style={{ display: 'none' }}
        onChange={(event) => {
          event.target.files?.length &&
            reuploadingFile.current &&
            void onUploadedFileReupload?.(reuploadingFile.current, Array.from(event.target.files));
          reuploadingFile.current = undefined;
          event.target.value = '';
        }}
        {...inputProps}
        ref={reuploadRef}
      />
    );
  }

  return (
    <Dropzone
      isMulti={isMulti}
      topLabelText={topLabelText}
      bottomLabelText={bottomLabelText}
      constraintText={constraintText}
      inputProps={inputProps}
      onChange={onStagedFilesAdd}
      onDragLeave={onDragLeave}
    />
  );
};
FileUpload.Dropzone = FileInputDropzone;
