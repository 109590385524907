import {
  AwsCloudtrailCheckType,
  AwsCloudwatchCheckType,
  AwsEcrCheckType,
  AwsGuarddutyCheckType,
  AwsIamCheckType,
  AwsInspectorCheckType,
  AwsInspectorService,
  AwsObjectStorageCheck,
  AwsWafCheckType,
  DBIntegrationCheck,
  EvidenceIntegrationType,
  GuardDutyFeature,
  LogMetricFilterCheck,
} from '@main/graphql/client-scalars';
import { Integration_Names_Enum } from '@main/graphql/types.generated';
import { IntegrationConfigSchemas } from '@main/integrations/shared';
import { z } from 'zod';

declare module '@main/integrations/shared' {
  interface IntegrationHandlerConfig {
    [Integration_Names_Enum.Aws]: HandlerConfigAws;
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-object-type, @typescript-eslint/no-empty-interface
  interface HandlerConfigAws {
    auth: {
      region: string;
      accessKeyId: string;
      secretAccessKey: string;
    };
  }
}

const authSchema = z
  .object({
    region: z.string().min(1),
    roleArn: z.string().min(1),
    externalId: z.string().optional(),
  })
  .optional();

export const AwsConfigSchemas: IntegrationConfigSchemas<Integration_Names_Enum.Aws> = {
  name: Integration_Names_Enum.Aws,

  orgConfig: z.object({
    accessKeyId: z.string().optional().describe('Access Key ID'),
    secretAccessKey: z.string().optional().describe('Secret Access Key'),
  }),

  evidenceConfig: z.discriminatedUnion('type', [
    z.object({
      type: z.literal(EvidenceIntegrationType.Database),
      auth: authSchema,
      region: z.string().optional(),
      dbInstanceIds: z.array(z.string()).optional(),
      dbClusterIds: z.array(z.string()).optional(),
      checks: z.array(z.nativeEnum(DBIntegrationCheck)),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.ObjectStorage),
      auth: authSchema,
      region: z.string().optional(),
      buckets: z.array(z.string()),
      check: z.nativeEnum(AwsObjectStorageCheck),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Guardduty),
      auth: authSchema,
      region: z.string().optional(),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.enum([
              AwsGuarddutyCheckType.HighSeverityFindings,
              AwsGuarddutyCheckType.SnsSubscription,
            ]),
          }),
          z.object({
            type: z.literal(AwsGuarddutyCheckType.EnabledFeatures),
            features: z.array(z.nativeEnum(GuardDutyFeature)),
          }),
        ]),
      ),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Ecr),
      auth: authSchema,
      region: z.string().optional(),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal(AwsEcrCheckType.ImageScanningConfigured),
            repositoryNames: z.array(z.string()).min(1),
          }),
        ]),
      ),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Inspector),
      auth: authSchema,
      region: z.string().optional(),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.enum([AwsInspectorCheckType.HighSeverityFindings]),
          }),
          z.object({
            type: z.literal(AwsInspectorCheckType.EnabledServices),
            services: z.array(z.nativeEnum(AwsInspectorService)),
          }),
        ]),
      ),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Cloudtrail),
      auth: authSchema,
      region: z.string().optional(),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.enum([
              AwsCloudtrailCheckType.MultiRegionTrail,
              AwsCloudtrailCheckType.TrailEnabled,
              AwsCloudtrailCheckType.LogFileValidation,
              AwsCloudtrailCheckType.TrailEncryption,
              AwsCloudtrailCheckType.LogsIntegration,
            ]),
          }),
        ]),
      ),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Cloudwatch),
      auth: authSchema,
      region: z.string().optional(),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal(AwsCloudwatchCheckType.AlarmActionsConfigured),
            insufficientDataActionRequired: z.boolean().optional(),
            okActionRequired: z.boolean().optional(),
          }),
          z.object({
            type: z.enum([AwsCloudwatchCheckType.AlarmActionsActivated]),
          }),
          z.object({
            type: z.literal(AwsCloudwatchCheckType.LogGroupsRetention),
            retentionTimeInDays: z.number(),
            logGroupPrefixes: z.array(z.string()).optional(),
          }),
          z.object({
            type: z.literal(AwsCloudwatchCheckType.LogMetricFilter),
            checks: z.array(z.nativeEnum(LogMetricFilterCheck)).nonempty(),
          }),
        ]),
      ),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.IdentityAccessManagement),
      auth: authSchema,
      region: z.string().optional(),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal(AwsIamCheckType.AccessKeyRotation),
            rotationDays: z.number(),
          }),
          z.object({
            type: z.literal(AwsIamCheckType.PasswordRotation),
            rotationDays: z.number(),
          }),
          z.object({
            type: z.literal(AwsIamCheckType.UnusedCredentials),
            inactiveDays: z.number(),
          }),
          z.object({
            type: z.enum([
              AwsIamCheckType.NoAdminPrivileges,
              AwsIamCheckType.NoWildcardActionsForServices,
              AwsIamCheckType.NoPoliciesAttached,
              AwsIamCheckType.MfaEnabledAllUsers,
              AwsIamCheckType.MfaEnabledConsoleAccess,
              AwsIamCheckType.NoCloudShellFullAccess,
              AwsIamCheckType.ExternalAccessAnalyzer,
              AwsIamCheckType.PasswordPolicyConfiguration,
              AwsIamCheckType.ExpiredServerCertificate,
              AwsIamCheckType.SupportRole,
            ]),
          }),
        ]),
      ),
    }),

    z.object({
      type: z.literal(EvidenceIntegrationType.Waf),
      auth: authSchema,
      region: z.string().optional(),
      checks: z.array(
        z.discriminatedUnion('type', [
          z.object({
            type: z.literal(AwsWafCheckType.WebAclAssociation),
            resourceArns: z.array(z.string()).min(1),
            blockActionRequired: z.boolean().optional(),
          }),
        ]),
      ),
    }),
  ]),
};
