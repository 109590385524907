import {
  AbsoluteCenter,
  Box,
  CircularProgress,
  CircularProgressLabel,
  Flex,
  Grid,
  SimpleGrid,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
  Tag,
  TagLabel,
  Text,
  useColorModeValue,
  VStack,
  Wrap,
} from '@chakra-ui/react';
import { useGetAllCategoriesQuery } from '@main/graphql/features/RiskCategories.generated';
import { Treatment_Plan_Enum } from '@main/graphql/types.generated';
import { encodeColumnFilters, FilterMode } from '@main/shared/url-helpers';
import { createSorter } from '@main/shared/utils';
import { DashboardCard, ProgressStatCard } from '@main/ui';
import { useNavigate } from '@tanstack/react-router';
import { ColumnFiltersState } from '@tanstack/react-table';
import { ReactNode, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useRiskHeatMap } from '../dashboard/risk-heat-map';
import { RISK_TREATMENT_PLAN_COLOR } from '../shared/status-color';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgId } from '../user/slice';
import { categoryColumnId, RISKS_TABLE_FILTER_PARAM_NAME } from './constants';
import { useGetRisksQuery } from './get-risk.generated';
import { HeatMap } from './risk-heat-map';
import {
  getNonClosedOutRisks,
  getRiskHeatmapData,
  getRiskStatsByGroup,
  getRiskStatsByPassingControls,
  getRiskStatsByStatus,
  Risk,
} from './slice';

function countTreatedRisks(risks: Risk[]) {
  return risks.filter((risk) => risk.treatment_plan !== Treatment_Plan_Enum.Pending).length;
}

export const RiskDashboard = () => {
  const { t } = useTranslation();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);

  const { is_controls_module_enabled: isControlsModuleEnabled } =
    useAppSelector(getCurrentUserSelectedOrg);

  const riskHeatMap = useRiskHeatMap();

  useGetRisksQuery({ organization_id: organizationId });
  const risks = useAppSelector((state) => getNonClosedOutRisks(state));

  const sorter = useMemo(() => createSorter(), []);
  const riskClassifications = useMemo(
    () => ({
      impacts: riskHeatMap.data?.impacts.toSorted(sorter) ?? [],
      likelihoods: riskHeatMap.data?.likelihoods.toSorted(sorter) ?? [],
    }),
    [riskHeatMap, sorter],
  );
  const { data } = useGetAllCategoriesQuery(
    { organizationId },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const { inherentLevelsHeatmap, residualLevelsHeatmap } = useAppSelector(getRiskHeatmapData);

  const riskStatsByStatus = useAppSelector(getRiskStatsByStatus);
  const riskStatusByGroup = useAppSelector(getRiskStatsByGroup);
  const { risksWithLinkedControls, risksWithPassingControls } = useAppSelector(
    getRiskStatsByPassingControls,
  );

  return (
    <Stack spacing={6}>
      <Text fontSize="3xl" fontWeight="semibold">
        {t('risks.dashboard.heading')}
      </Text>
      <SimpleGrid columns={{ base: 1, lg: 3 }} spacing={{ base: 5, lg: 6 }}>
        <StatCardContainer>
          <Stat h="full">
            <AbsoluteCenter>
              <VStack spacing={0}>
                <StatLabel fontSize="md" fontWeight="semibold">
                  {t('risks.dashboard.totalRisks')}
                </StatLabel>
                <StatNumber fontSize="5xl">{risks.length}</StatNumber>
              </VStack>
            </AbsoluteCenter>
          </Stat>
        </StatCardContainer>
        <StatCardContainer>
          <Stat>
            <StatLabel fontSize="md" fontWeight="semibold">
              {t('risks.dashboard.risksBreakdown')}
            </StatLabel>
            <Stack spacing={1} mt={3}>
              {Object.values(Treatment_Plan_Enum).map((status) => (
                <Flex gap={1.5} key={status}>
                  <Tag colorScheme={RISK_TREATMENT_PLAN_COLOR[status]} minW="24px">
                    <TagLabel>{riskStatsByStatus ? riskStatsByStatus[status] : 0}</TagLabel>
                  </Tag>
                  <Text fontSize="xs">{t(`risks.enum.treatment.${status}`)}</Text>
                </Flex>
              ))}
            </Stack>
          </Stat>
        </StatCardContainer>
        {isControlsModuleEnabled && (
          <StatCardContainer>
            <Stat>
              <Stack spacing={0}>
                <Box>
                  <StatLabel fontSize="md" fontWeight="semibold">
                    {t('risks.dashboard.risksWithPassingControls')}
                  </StatLabel>
                  <StatNumber fontSize="2xl" mt={2}>
                    {risksWithPassingControls}/{risksWithLinkedControls}
                  </StatNumber>
                </Box>

                <Flex justifyContent="end">
                  <CircularProgress
                    value={
                      risksWithLinkedControls
                        ? (risksWithPassingControls / risksWithLinkedControls) * 100
                        : 0
                    }
                    color="green.400"
                    size="82px"
                    thickness="5px"
                  >
                    <CircularProgressLabel>
                      {risksWithLinkedControls
                        ? Math.round((risksWithPassingControls / risksWithLinkedControls) * 100)
                        : 0}
                      %
                    </CircularProgressLabel>
                  </CircularProgress>
                </Flex>
              </Stack>
            </Stat>
          </StatCardContainer>
        )}
      </SimpleGrid>
      {data?.organization_risk_categories.length ? (
        <DashboardCard>
          <DashboardCard.Header heading={t('risks.dashboard.riskCategories')} />
          <DashboardCard.Body>
            <Wrap overflow="visible">
              {data?.organization_risk_categories.map((category) => {
                return riskStatusByGroup.get(category.name)?.length ? (
                  <CategoryStat
                    categoryId={category.id}
                    key={category.id}
                    category={category.name}
                    total={riskStatusByGroup.get(category.name)?.length || 0}
                    treated={countTreatedRisks(riskStatusByGroup.get(category.name) || [])}
                  />
                ) : null;
              })}
            </Wrap>
          </DashboardCard.Body>
        </DashboardCard>
      ) : null}

      <Grid
        templateColumns={[
          'repeat(1, 1fr)',
          'repeat(1, 1fr)',
          'repeat(1, 1fr)',
          'repeat(1, 1fr)',
          'repeat(2, 1fr)',
        ]}
        gap={6}
      >
        <StatCardContainer>
          <Text fontWeight="semibold">{t('risks.dashboard.inherentRiskHeatmap')}</Text>
          <HeatMap
            map={inherentLevelsHeatmap}
            impacts={riskClassifications.impacts}
            likelihoods={riskClassifications.likelihoods}
          />
        </StatCardContainer>
        <StatCardContainer>
          <Text fontWeight="semibold">{t('risks.dashboard.residualRiskHeatmap')}</Text>
          <HeatMap
            map={residualLevelsHeatmap}
            impacts={riskClassifications.impacts}
            likelihoods={riskClassifications.likelihoods}
          />
        </StatCardContainer>
      </Grid>
    </Stack>
  );
};

const StatCardContainer = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      border="1px solid"
      borderColor={useColorModeValue('gray.200', 'gray.700')}
      bg={useColorModeValue('white', 'gray.800')}
      rounded="lg"
      p={6}
      minH="200px"
      minW="fit-content"
      w="full"
    >
      {children}
    </Box>
  );
};

const CategoryStat = ({
  categoryId,
  category,
  total,
  treated,
}: {
  categoryId: string;
  category: string;
  total: number;
  treated: number;
}) => {
  const { t } = useTranslation();
  const percentage = total ? Math.round((treated / total) * 100) : 0;
  const navigate = useNavigate();

  function openRiskCategory() {
    const columnFilter = [
      { id: categoryColumnId, value: { value: [categoryId], mode: FilterMode.Includes } },
    ] satisfies ColumnFiltersState;

    navigate({
      to: '/risks',
      search: () => ({
        [RISKS_TABLE_FILTER_PARAM_NAME]: encodeColumnFilters(columnFilter),
      }),
    });
  }

  const amountOfRisks = `${total} ${t('entities.risk', { count: total }).toLowerCase()}`;

  return (
    <ProgressStatCard
      onClick={openRiskCategory}
      progressPercentage={percentage}
      progressInfo={t('risks.dashboard.riskTreated')}
      title={category}
      subTitle={amountOfRisks}
    />
  );
};
