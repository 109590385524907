import { Icon, useDisclosure } from '@chakra-ui/react';
import {
  BookmarkIcon,
  BookmarkSlashIcon,
  DocumentDuplicateIcon,
  DocumentMagnifyingGlassIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { useUpdateControlByIdMutation } from '@main/graphql/mutations/UpdateControlById.generated';
import { isNonNullable } from '@main/shared/utils';
import { DrawerActionsProps, successToast, useDrawer } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useUpdateControlOnDrawerHandler } from '../program/use-update-control-handler';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useDeleteControl } from './delete-control';
import { useDuplicateControl } from './duplicate-control';
import { CreateFindingModal } from './finding/create-finding-modal';
import { getMappedControl } from './slice';

export const useControlDrawerActions = (controlId: string): DrawerActionsProps => {
  const duplicateControlAction = useDuplicateControlAction(controlId);
  const addFindingAction = useAddFindingAction(controlId);
  const setControlRelevancyAction = useSetControlRelevancyAction(controlId);
  const deleteControlAction = useDeleteControlAction(controlId);

  return {
    menuActions: [
      duplicateControlAction,
      addFindingAction,
      setControlRelevancyAction,
      deleteControlAction,
    ].filter(isNonNullable),
  };
};

function useDuplicateControlAction(controlId: string) {
  const { t } = useTranslation();

  const duplicateControl = useDuplicateControl();
  if (!duplicateControl) {
    return null;
  }

  return {
    icon: <DocumentDuplicateIcon />,
    label: t('buttons.duplicate'),
    onClick: async () => {
      duplicateControl(controlId);
    },
  };
}

function useAddFindingAction(controlId: string) {
  const { t } = useTranslation();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateFinding = !!userRole.permissionMap?.update_controls;
  if (!canCreateFinding) {
    return null;
  }

  return {
    icon: <DocumentMagnifyingGlassIcon />,
    label: t('controls.createFindingModal.addFindingBtn'),
    onClick: onOpen,
    children: <CreateFindingModal controlId={controlId} isOpen={isOpen} onClose={onClose} />,
  };
}

function useSetControlRelevancyAction(controlId: string) {
  const { t } = useTranslation();

  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canSetControlRelevancy = !!userRole.permissionMap?.update_controls;

  const control = useAppSelector((state) => getMappedControl(state, controlId));

  const updateControlHandler = useUpdateControlOnDrawerHandler();
  const [updateControlById] = useUpdateControlByIdMutation();

  if (!canSetControlRelevancy || !control) {
    return null;
  }

  const isRelevant = !control.irrelevant;
  const tKey = isRelevant ? 'relevant' : 'irrelevant';

  return {
    icon: <Icon as={isRelevant ? BookmarkSlashIcon : BookmarkIcon} />,
    label: t(`controls.relevancy.${tKey}.actionLabel`),
    onClick: async () => {
      const result = await updateControlHandler(
        updateControlById({
          controlId,
          updatePayload: { irrelevant: isRelevant },
        }),
      );
      if (result) {
        successToast(t(`controls.relevancy.${tKey}.successMsg`));
      }
    },
  };
}

function useDeleteControlAction(controlId: string) {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const deleteControl = useDeleteControl();
  if (!deleteControl) {
    return null;
  }

  return {
    icon: <TrashIcon />,
    label: t('buttons.delete'),
    onClick: () => {
      deleteControl(controlId, () => drawer.close());
    },
  };
}
