import { ThemeTypings } from '@chakra-ui/react';
import { GetRiskMatrixQuery } from '@main/graphql/features/RiskMatrix.generated';
import { Risk_Levels_Enum } from '@main/graphql/types.generated';
import { TFunction } from 'i18next';

export const RISK_LEVEL_COLORS = {
  very_low: 'green',
  low: 'green',
  medium: 'yellow',
  high: 'red',
  very_high: 'red',
} satisfies Record<Risk_Levels_Enum, ThemeTypings['colorSchemes']>;

export const MATRIX_CELL_COLORS = {
  very_low: { bgColor: 'green.400', hoverBgColor: 'green.500' },
  low: { bgColor: 'green.300', hoverBgColor: 'green.400' },
  medium: { bgColor: 'yellow.300', hoverBgColor: 'yellow.400' },
  high: { bgColor: 'red.300', hoverBgColor: 'red.400' },
  very_high: { bgColor: 'red.400', hoverBgColor: 'red.500' },
} satisfies Record<
  Risk_Levels_Enum,
  { bgColor: ThemeTypings['colors']; hoverBgColor: ThemeTypings['colors'] }
>;

type MatrixData =
  | GetRiskMatrixQuery['risk_inherent_levels']
  | GetRiskMatrixQuery['risk_residual_levels'];
export type MatrixItem = { level: Risk_Levels_Enum; id: string };

export const createMatrixMap = (data: MatrixData) => {
  const map = new Map<string, MatrixItem>();
  data.forEach((matrix) => {
    const key = constructMapKey({
      impactName: matrix.risk_impact.name,
      likelihoodName: matrix.risk_likelihood.name,
    });
    const value = {
      level: matrix.level,
      id: matrix.id,
    };

    map.set(key, value);
  });

  return map;
};

export const constructMapKey = ({
  likelihoodName,
  impactName,
}: {
  likelihoodName: string;
  impactName: string;
}) => likelihoodName + impactName;

function sortedRiskLevels(t: TFunction) {
  const sortedMap = {
    very_low: Risk_Levels_Enum.VeryLow,
    low: Risk_Levels_Enum.Low,
    medium: Risk_Levels_Enum.Medium,
    high: Risk_Levels_Enum.High,
    very_high: Risk_Levels_Enum.VeryHigh,
  } satisfies Record<Risk_Levels_Enum, Risk_Levels_Enum>;

  return sortedMap;
}
export function getRiskLevelOptions(t: TFunction) {
  const levels = Object.values(sortedRiskLevels(t));
  const levelOptions = levels.map((value) => ({
    value,
    label: t(`settings.organization.risks.matrix.levels.${value}`),
    colorScheme: 'purple',
  }));

  return levelOptions;
}
