import { useGetOrganizationControlsQuery } from '@main/graphql/queries/GetOrganizationControls.generated';
import { useGetOrganizationControlStatusesSubscription } from '@main/graphql/subscriptions/GetOrganizationControlStatusesSubscription';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useGetPollingInterval } from '../../hooks/use-get-polling-interval';
import { getOrgControls } from './slice';

export function useGetOrganizationControls(organizationId: string) {
  useGetOrganizationControlStatusesSubscription({ organizationId });
  const pollingInterval = useGetPollingInterval(5000);
  const { isLoading } = useGetOrganizationControlsQuery({ organizationId }, { pollingInterval });
  const controls = useAppSelector(getOrgControls);
  return {
    controls,
    isLoading,
  };
}
