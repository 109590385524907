/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type DisableNotificationMutationVariables = Types.Exact<{
  objects:
    | Array<Types.Notification_Disabled_Settings_Insert_Input>
    | Types.Notification_Disabled_Settings_Insert_Input;
}>;

export type DisableNotificationMutation = {
  __typename?: 'mutation_root';
  insert_notification_disabled_settings?: {
    __typename?: 'notification_disabled_settings_mutation_response';
    affected_rows: number;
  };
};

export type EnableNotificationMutationVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
  deliveryType: Types.Notification_Delivery_Types_Enum;
  notificationTypes: Array<Types.Notification_Types_Enum> | Types.Notification_Types_Enum;
}>;

export type EnableNotificationMutation = {
  __typename?: 'mutation_root';
  delete_notification_disabled_settings?: {
    __typename?: 'notification_disabled_settings_mutation_response';
    affected_rows: number;
  };
};

export type GetDisabledNotificationsQueryVariables = Types.Exact<{
  userId: Types.Scalars['uuid']['input'];
}>;

export type GetDisabledNotificationsQuery = {
  __typename?: 'query_root';
  notification_disabled_settings: Array<{
    __typename?: 'notification_disabled_settings';
    id: string;
    type: Types.Notification_Types_Enum;
    delivery_type: Types.Notification_Delivery_Types_Enum;
  }>;
};

export const DisableNotificationDocument = `
    mutation DisableNotification($objects: [notification_disabled_settings_insert_input!]!) {
  insert_notification_disabled_settings(objects: $objects) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<DisableNotificationMutation, DisableNotificationMutationVariables>;
export const EnableNotificationDocument = `
    mutation EnableNotification($userId: uuid!, $deliveryType: notification_delivery_types_enum!, $notificationTypes: [notification_types_enum!]!) {
  delete_notification_disabled_settings(
    where: {user_id: {_eq: $userId}, type: {_in: $notificationTypes}, delivery_type: {_eq: $deliveryType}}
  ) {
    affected_rows
  }
}
    ` as string &
  TypedDocumentNode<EnableNotificationMutation, EnableNotificationMutationVariables>;
export const GetDisabledNotificationsDocument = `
    query GetDisabledNotifications($userId: uuid!) {
  notification_disabled_settings(where: {user_id: {_eq: $userId}}) {
    id
    type
    delivery_type
  }
}
    ` as string &
  TypedDocumentNode<GetDisabledNotificationsQuery, GetDisabledNotificationsQueryVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    DisableNotification: build.mutation<
      DisableNotificationMutation,
      DisableNotificationMutationVariables
    >({
      query: (variables) => ({ document: DisableNotificationDocument, variables }),
    }),
    EnableNotification: build.mutation<
      EnableNotificationMutation,
      EnableNotificationMutationVariables
    >({
      query: (variables) => ({ document: EnableNotificationDocument, variables }),
    }),
    GetDisabledNotifications: build.query<
      GetDisabledNotificationsQuery,
      GetDisabledNotificationsQueryVariables
    >({
      query: (variables) => ({ document: GetDisabledNotificationsDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useDisableNotificationMutation,
  useEnableNotificationMutation,
  useGetDisabledNotificationsQuery,
  useLazyGetDisabledNotificationsQuery,
} = injectedRtkApi;
