import { registerNotificationHandler } from '../notifications/registry';
import {
  MentionedInVendorCommentNotificationHandler,
  VendorAssignedNotificationHandler,
  VendorExpiringNotificationHandler,
} from './notifications';
import {
  MentionedInVQCommentNotificationHandler,
  VendorQuestionnaireAiFindingNotificationHandler,
  VendorQuestionnaireOverdueNotificationHandler,
  VendorQuestionnaireSubmittedNotificationHandler,
} from './vendor-questionnaires/notifications';

registerNotificationHandler(VendorAssignedNotificationHandler);
registerNotificationHandler(MentionedInVendorCommentNotificationHandler);
registerNotificationHandler(VendorExpiringNotificationHandler);
registerNotificationHandler(VendorQuestionnaireOverdueNotificationHandler);
registerNotificationHandler(VendorQuestionnaireSubmittedNotificationHandler);
registerNotificationHandler(VendorQuestionnaireAiFindingNotificationHandler);
registerNotificationHandler(MentionedInVQCommentNotificationHandler);
