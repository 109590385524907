import { ControlStatus } from '@main/graphql/client-scalars';
import { api as getOrgControlsApi } from '@main/graphql/queries/GetOrganizationControls.generated';
import { api as getOrgControlStatusesApi } from '@main/graphql/queries/GetOrganizationControlStatuses.generated';
import { Finding_Causes_Enum } from '@main/graphql/types.generated';
import { isNonNullable, mapByKey, Severity } from '@main/shared/utils';
import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import { isFeatureFlagEnabled } from '../feature-flags/slice';
import { getOrgProgramsMap, OrganizationProgram } from '../program/slice';
import { getCurrentUserSelectedOrgId } from '../user/slice';
import { ControlFinding, parseControlFinding } from './finding/control-finding';
import { api as getControlApi } from './get-control.generated';

const getOrgControlsApiData = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    return (
      getOrgControlsApi.endpoints.GetOrganizationControls.select({
        organizationId: orgId,
      })(state).data?.controls || []
    );
  },
);

const getOrgControlStatusesApiData = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    return (
      getOrgControlStatusesApi.endpoints.GetOrganizationControlStatuses.select({
        organizationId: orgId,
      })(state).data?.controls || []
    );
  },
);

const getControlPrograms = (
  { programs }: { programs: { program_id: string }[] },
  programsMap: Map<string, OrganizationProgram>,
) => {
  return {
    programs: programs.map(({ program_id }) => programsMap.get(program_id)).filter(isNonNullable),
  };
};

export const getOrgControls = createSelector(
  [getOrgControlsApiData, getOrgControlStatusesApiData, getOrgProgramsMap],
  (controls, controlStatuses, programsMap) => {
    const controlStatusesById = controlStatuses.reduce((acc, { id, status }) => {
      acc.set(id, status);
      return acc;
    }, new Map<string, ControlStatus>());

    return controls.map((control) => ({
      ...control,
      ...getControlPrograms(control, programsMap),
      status: controlStatusesById.get(control.id) || control.status,
    }));
  },
);

export const getProgramControls = createSelector(
  [getOrgControls, (_: AppRootState, programId: string) => programId],
  (orgControls, programId) => {
    return orgControls.filter((control) => control.programs.some(({ id }) => id === programId));
  },
);

const getControlApiData = createSelector(
  (state: AppRootState, controlId?: string) => ({ state, controlId }),
  ({ state, controlId }) => {
    if (!controlId) {
      return undefined;
    }
    return getControlApi.endpoints.GetControl.select({
      controlId,
    })(state).data?.controls_by_pk;
  },
);

export const getMappedControl = createSelector(
  [getControlApiData, getOrgProgramsMap],
  (control, programsMap) => {
    if (!control) {
      return undefined;
    }

    const tags = {
      groups:
        control.groups.map(({ group }) => ({
          value: group.id,
          label: group.name,
          colorScheme: 'purple',
        })) || [],
      categories:
        control.categories.map(({ category }) => ({
          value: category.id,
          label: category.name,
          colorScheme: 'purple',
        })) || [],
      criteria:
        control.criterias.map(({ criteria }) => ({
          value: criteria.id,
          label: criteria.name,
          colorScheme: 'purple',
        })) || [],
      custom:
        control?.custom_tags.map(({ tag }) => ({
          value: tag.id,
          label: tag.name,
          colorScheme: 'purple',
        })) || [],
    };
    return { ...control, ...getControlPrograms(control, programsMap), tags };
  },
);

export const getMappedControlEvidences = createSelector([getControlApiData], (control) => {
  return (
    control?.control_evidences
      .map(({ id, evidence, status }) => {
        if (!evidence) {
          return undefined;
        }

        return {
          controlEvidenceId: id,
          ...evidence,
          name: evidence.name || '',
          status,
          currentVersion: evidence.evidence_versions[0]
            ? {
                id: evidence.evidence_versions[0].id,
                url: evidence.evidence_versions[0].url,
                fileId: evidence.evidence_versions[0].evidence_version_file?.file.id,
                validityStart: evidence.evidence_versions[0].validity_start,
              }
            : undefined,
        };
      })
      .filter(isNonNullable) || []
  );
});

const getGroupByControlEvidenceIdMap = createSelector(
  [getMappedControlEvidences],
  (mappedControlEvidences) => mapByKey(mappedControlEvidences, 'controlEvidenceId'),
);

export type ControlEvidence = ReturnType<typeof getMappedControlEvidences>[number];

export type FindingAPIData = NonNullable<ReturnType<typeof getControlApiData>>['findings'][number];
export type ControlFindingsWithMetadata = {
  highestSeverity: Severity;
  findings: ControlFinding[];
  countWithoutIgnored: number;
};

export const getControlFindings = createSelector(
  [getControlApiData, getGroupByControlEvidenceIdMap, isFeatureFlagEnabled('control-ai-review')],
  (control, controlEvidenceMap, isControlAiReviewEnabled): ControlFindingsWithMetadata => {
    let controlFindings = (control?.findings || [])
      .map((finding) => parseControlFinding({ finding, controlEvidenceMap }))
      .filter(isNonNullable)
      .sort((a, b) => b.severity - a.severity);

    if (!isControlAiReviewEnabled) {
      controlFindings = controlFindings.filter(
        (finding) => finding.cause !== Finding_Causes_Enum.AiRecommendation,
      );
    }

    const findingsWithoutIgnored = controlFindings.filter(
      ({ finding }) => finding.ignored_at === null,
    );

    const evidenceWithHighestSeverity = findingsWithoutIgnored[0];

    return {
      highestSeverity: evidenceWithHighestSeverity?.severity || Severity.LOW,
      findings: controlFindings,
      countWithoutIgnored: findingsWithoutIgnored.length,
    };
  },
);
