import { Avatar, Flex, Link, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { Link as RouterLink } from '@tanstack/react-router';

import { getHashedAvatarColor } from '../../utils';
import { DrawerEntity } from '../drawer';
import { OverflowContainer } from '../overflow-tooltip';
import { TabId } from '../tabs';
import { Card, CardActionsProps, CardTagsProps } from './base-card';

interface ReferenceCardProps extends CardActionsProps, CardTagsProps {
  size?: 'sm' | 'lg';
  owner?: string;
  name: string | undefined;
  navigate: {
    drawerEntity: DrawerEntity;
    drawerEntityId: string;
    activeTab?: TabId;
  };
}

export const ReferenceCard = ({
  size,
  name,
  owner,
  tags,
  actions,
  numberOfInlineActions,
  navigate,
}: ReferenceCardProps) => {
  const textColor = useColorModeValue('gray.700', 'gray.200');

  return (
    <Card size={size} position="relative" aria-label={navigate.drawerEntity}>
      <Card.Body>
        <OverflowContainer>
          <OverflowContainer.Tooltip label={name} placement="top" gutter={12} openDelay={500}>
            <Link
              as={RouterLink}
              noOfLines={1}
              fontSize="sm"
              fontWeight="medium"
              lineHeight={7}
              color={textColor}
              search={{
                ...navigate,
              }}
              /* Code below important to imitate card accessibility on focus */
              _before={{
                content: '""',
                position: 'absolute',
                inset: 0,
              }}
              _focus={{
                ring: 0,
                _before: {
                  ring: '3px',
                  ringColor: 'rgba(66, 153, 225, 0.6)',
                  rounded: 'lg',
                },
              }}
              _active={{
                _before: {
                  ring: 0,
                },
              }}
              _hover={{
                textDecoration: 'none',
              }}
            >
              {name}
            </Link>
          </OverflowContainer.Tooltip>
        </OverflowContainer>
      </Card.Body>

      <Card.Header
        left={<Card.Tags tags={tags} />}
        right={
          owner && (
            <Flex gap={4} alignItems="center">
              <Tooltip label={owner} placement="top" hasArrow gutter={12} openDelay={600}>
                <Avatar size="xs" name={owner} {...getHashedAvatarColor(owner)} />
              </Tooltip>
            </Flex>
          )
        }
        actions={actions}
        numberOfInlineActions={numberOfInlineActions}
      />
    </Card>
  );
};
