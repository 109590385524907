import { ControlStatus } from '@main/graphql/client-scalars';
import { Notification_Types_Enum } from '@main/graphql/types.generated';
import { useTranslation } from 'react-i18next';

import { router } from '../../router';
import {
  AssignedNotificationAdapter,
  AssignedNotificationData,
  AssignedNotificationHandler,
} from '../notifications/assigned-notification-handler';
import { StatusUpdateContent } from '../notifications/notification-content';
import {
  NotificationContentComponent,
  NotificationContentProps,
  NotificationHandler,
  NotificationItem,
} from '../notifications/notification-handler';

export interface ControlAssignedNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    control: NonNullable<NotificationItem['content']['control']>;
  };
}

export class ControlAssignedNotificationHandler
  implements AssignedNotificationAdapter<ControlAssignedNotification>
{
  static readonly type = Notification_Types_Enum.EntityOwnershipChanged;

  static canHandle(notification: NotificationItem): notification is ControlAssignedNotification {
    return !!notification.content.control;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: ControlAssignedNotification): AssignedNotificationData {
    return {
      internalId: notification.content.control.internal_id,
      entityName: notification.content.control.name,
      content: 'notification.control.assigned',
    };
  }

  redirect(notification: ControlAssignedNotification) {
    router.navigate({
      to: '/controls',
      search: {
        drawerEntity: 'control',
        drawerEntityId: notification.content.control.id,
      },
    });
  }
}

export interface MentionedInControlCommentNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_comment: NonNullable<NotificationItem['content']['notifications_comment']> & {
      comments_control: NonNullable<
        NonNullable<NotificationItem['content']['notifications_comment']>['comments_control']
      >;
    };
  };
}

export class MentionedInControlCommentNotificationHandler
  implements AssignedNotificationAdapter<MentionedInControlCommentNotification>
{
  static readonly type = Notification_Types_Enum.MentionedInComment;

  static canHandle(
    notification: NotificationItem,
  ): notification is MentionedInControlCommentNotification {
    return !!notification.content.notifications_comment?.comments_control;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: MentionedInControlCommentNotification): AssignedNotificationData {
    return {
      internalId: notification.content.notifications_comment.comments_control.internal_id,
      entityName: notification.content.notifications_comment.comments_control.name,
      content: 'notification.control.mentioned',
    };
  }

  redirect(notification: MentionedInControlCommentNotification) {
    router.navigate({
      to: '/controls',
      search: {
        drawerEntity: 'control',
        drawerEntityId: notification.content.notifications_comment.comments_control.id,
        activeTab: 'comments',
      },
    });
  }
}

export interface ControlNotValidNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    control: NonNullable<NotificationItem['content']['control']>;
    params: { controlStatus: ControlStatus };
  };
}

export class ControlNotValidNotificationHandler
  implements NotificationHandler<ControlNotValidNotification>
{
  static readonly type = Notification_Types_Enum.ControlStatusChangedToNotValid;

  static canHandle(notification: NotificationItem): notification is ControlNotValidNotification {
    return !!notification.content.control && 'controlStatus' in notification.content.params;
  }

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<ControlNotValidNotification, this> {
    return ControlNotValidNotificationContent;
  }

  redirect(notification: ControlNotValidNotification) {
    router.navigate({
      to: '/controls',
      search: {
        drawerEntity: 'control',
        drawerEntityId: notification.content.control.id,
      },
    });
  }
}

function ControlNotValidNotificationContent({
  notification,
}: NotificationContentProps<ControlNotValidNotification>) {
  const { t } = useTranslation();
  const controlStatus = t(
    `controls.status.enums.${notification.content.params.controlStatus}`,
  ).toLowerCase();

  return (
    <StatusUpdateContent
      content={t('notification.control.notValid', { controlStatus })}
      internalId={notification.content.control.internal_id ?? ''}
      entityName={notification.content.control.name ?? ''}
    />
  );
}
