import { HStack, Tag } from '@chakra-ui/react';
import {
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  ClipboardDocumentIcon,
  ClipboardDocumentListIcon,
  ClockIcon,
  DocumentTextIcon,
  ListBulletIcon,
  ShieldExclamationIcon,
} from '@heroicons/react/24/outline';
import { VendorTabKeys } from '@main/shared/utils';
import { Drawer, EditableAutoResizeTextarea, getVisibleTabs, StatusTag } from '@main/ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useOrgGuard } from '../../hooks/use-org-guard';
import { CommentsTab } from '../comments/comments-tab';
import { VENDOR_ASSESSMENT_STATUS_COLOR } from '../shared/status-color';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { VendorDetailsTab } from './details-tab';
import { VendorDocumentsTab } from './documents-tab';
import { useGetVendorQuery } from './get-vendor.generated';
import { useGetVendorSubscription } from './get-vendor.sub';
import { VendorHistoryTab } from './history-tab';
import { VendorQuestionnairesTab } from './questionnaires-tab';
import { VendorReviewsTab } from './reviews-tab';
import { VendorRisksTab } from './risks-tab';
import { getMappedVendor, getVendorRisksSeverity } from './slice';
import { VendorTasksTab } from './tasks-tab';
import { useUpdateVendorMutation } from './update-vendor.generated';
import { useVendorDrawerActions } from './use-drawer-actions';
import { useUpdateVendorHandler } from './use-udpate-vendor-handler';
import { useGetVendorRisksQuery } from './VendorRisks.generated';

export const VendorDrawer = ({ vendorId }: { vendorId: string }) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewVendor = userRole.permissionMap?.read_vendors;

  const { data, isLoading: isVendorLoading } = useOrgGuard(
    useGetVendorSubscription({ vendor_id: vendorId }),
    ({ data }) => data?.vendors_by_pk?.organization_id,
  );

  const { tabs } = useVendorDrawerTabs({ vendorId });
  const { menuActions } = useVendorDrawerActions(vendorId);

  return (
    <Drawer.Layout
      isLoading={isVendorLoading}
      canView={canViewVendor}
      isNotFound={!data?.vendors_by_pk}
    >
      <Drawer.Toolbar menuActions={menuActions} />
      <Drawer.Tabs tabs={tabs}>
        <VendorDrawerHeader vendorId={vendorId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
};

const VendorDrawerHeader = ({ vendorId }: { vendorId: string }) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;

  const [updateVendor] = useUpdateVendorMutation();
  const updateVendorHandler = useUpdateVendorHandler();
  const vendor = useAppSelector((state) => getMappedVendor(state, vendorId));

  if (!vendor) {
    return null;
  }

  return (
    <>
      <HStack mb={4}>
        <Tag colorScheme="purple">
          {t('entities.vendor')} / {vendor.internal_id}
        </Tag>
        <StatusTag
          size="sm"
          minW="auto"
          maxW="none"
          colorScheme={VENDOR_ASSESSMENT_STATUS_COLOR[vendor.assessment_status]}
          data-testid="vendor-status"
        >
          {t(`vendors.enum.assessmentStatus.${vendor.assessment_status}`)}
        </StatusTag>
      </HStack>
      <EditableAutoResizeTextarea
        isDisabled={!canUpdateVendor}
        ml={-2}
        fontSize="xl"
        fontWeight="bold"
        defaultValue={vendor.name}
        placeholder={t('vendors.props.name')}
        onSubmit={(value) =>
          updateVendorHandler(
            updateVendor({
              id: vendorId,
              vendor_input: {
                name: value,
              },
            }),
          )
        }
      />
    </>
  );
};

const useVendorDrawerTabs = ({ vendorId }: { vendorId: string }) => {
  const { t } = useTranslation();
  const { comments, refetch } = useGetVendorQuery(
    { vendor_id: vendorId },
    {
      selectFromResult: ({ data }) => {
        return { comments: data?.vendors_by_pk?.vendors_comments ?? [] };
      },
    },
  );
  const { numOfRisks } = useGetVendorRisksQuery(
    { vendorId },
    {
      refetchOnMountOrArgChange: true,
      selectFromResult: ({ data }) => {
        return { numOfRisks: data?.vendor_risks.length };
      },
    },
  );

  const refetchComments = useCallback(async () => {
    await refetch().unwrap();
  }, [refetch]);

  const risksSeverity = useAppSelector((state) => getVendorRisksSeverity(state, vendorId));
  const tabs = useMemo(
    () =>
      getVisibleTabs<VendorTabKeys>({
        details: {
          label: t('vendors.tabs.details'),
          icon: ListBulletIcon,
          panel: <VendorDetailsTab vendorId={vendorId} />,
          hideTabTitle: true,
        },
        documents: {
          label: t('vendors.tabs.documents'),
          icon: DocumentTextIcon,
          panel: (
            <VendorDocumentsTab
              vendorId={vendorId}
              onFilesChange={async () => {
                await refetch().unwrap();
              }}
            />
          ),
        },
        questionnaires: {
          label: t('vendors.tabs.questionnaires'),
          icon: ClipboardDocumentListIcon,
          panel: <VendorQuestionnairesTab vendorId={vendorId} />,
        },
        risks: {
          label: t('vendors.tabs.risks'),
          icon: ShieldExclamationIcon,
          panel: <VendorRisksTab vendorId={vendorId} />,
          badge: {
            count: numOfRisks,
            severity: risksSeverity,
          },
        },
        reviews: {
          label: t('vendors.tabs.reviews'),
          icon: ClipboardDocumentIcon,
          panel: <VendorReviewsTab vendorId={vendorId} />,
        },
        comments: {
          label: t('vendors.tabs.comments'),
          icon: ChatBubbleLeftRightIcon,
          panel: (
            <CommentsTab
              entity="vendor"
              entityId={vendorId}
              comments={comments}
              refetchComments={refetchComments}
            />
          ),
        },
        tasks: {
          label: t('vendors.tabs.tasks'),
          icon: ClipboardDocumentCheckIcon,
          panel: <VendorTasksTab vendorId={vendorId} />,
        },
        history: {
          label: t('vendors.tabs.history'),
          icon: ClockIcon,
          panel: <VendorHistoryTab vendorId={vendorId} />,
        },
      }),
    [comments, numOfRisks, refetch, refetchComments, risksSeverity, t, vendorId],
  );

  return { tabs };
};
