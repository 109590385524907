import { BoxProps, Skeleton, Text, useDisclosure } from '@chakra-ui/react';
import { ArrowPathRoundedSquareIcon, LinkIcon, PencilIcon } from '@heroicons/react/24/outline';
import { useGetEvidenceByIdQuery } from '@main/graphql/queries/GetEvidenceById.generated';
import { useGetEvidenceVersionsListByEvidenceIdQuery } from '@main/graphql/queries/GetEvidenceVersionsListByEvidenceId.generated';
import { DrawerProperty } from '@main/ui';
import { CardButton, CurrentVersionCard } from '@main/ui/cards';
import { Fragment, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../../utils/constants';
import { getCurrentOrgUsersMap } from '../../user/slice';
import { getMappedEvidence, getMappedEvidenceVersions } from '../slice';
import { useIsUserAuthorizedToChangeEvidence, useIsUserAuthorizedToViewVersions } from '../utils';
import { CreateEvidenceVersionModal } from './create-evidence-version';
import { EditEvidenceVersionModal } from './edit-evidence-version';
import { EvidenceVersionType } from './evidence-version-type';

export const CurrentVersion = ({ evidenceId }: { evidenceId: string }) => {
  const { t } = useTranslation();
  const editDisclosure = useDisclosure();
  const createDisclosure = useDisclosure();
  const orgUsers = useAppSelector(getCurrentOrgUsersMap);
  const { refetch: refetchEvidence } = useGetEvidenceByIdQuery({
    evidence_id: evidenceId,
  });
  const evidence = useAppSelector((state) => getMappedEvidence(state, evidenceId));
  const { refetch: refetchEvidenceVersions, isLoading } =
    useGetEvidenceVersionsListByEvidenceIdQuery({
      evidenceId,
    });
  const { currentVersion } = useAppSelector((state) =>
    getMappedEvidenceVersions(state, evidenceId),
  );

  const isUserAuthorizedToChangeEvidence = useIsUserAuthorizedToChangeEvidence(evidence);
  const isUserAuthorizedToViewVersions = useIsUserAuthorizedToViewVersions(evidence);

  if (!isUserAuthorizedToViewVersions) {
    return (
      <CurrentVersionContainer display="flex" alignItems="center">
        <Text fontSize="xs" textColor="gray.500" _dark={{ color: 'gray.400' }}>
          {t('evidences.confidential.lowerText')}
        </Text>
      </CurrentVersionContainer>
    );
  }

  if (isLoading) {
    return (
      <CurrentVersionContainer>
        <Skeleton height="46px" width="full" rounded="lg" />
      </CurrentVersionContainer>
    );
  }

  if (!currentVersion && isUserAuthorizedToChangeEvidence) {
    return (
      <CurrentVersionContainer>
        <CardButton onClick={createDisclosure.onOpen}>
          {t('evidences.buttons.addNewVersion')}
        </CardButton>
        <CreateEvidenceVersionModal
          evidenceId={evidenceId}
          isOpen={createDisclosure.isOpen}
          onClose={createDisclosure.onClose}
          onCreate={() => {
            refetchEvidenceVersions();
            refetchEvidence();
            createDisclosure.onClose();
          }}
        />
      </CurrentVersionContainer>
    );
  }

  if (!currentVersion || currentVersion.type === EvidenceVersionType.Policy) {
    return;
  }

  return (
    <Fragment>
      <CurrentVersionContainer>
        <CurrentVersionCard
          url={currentVersion.url}
          fileId={currentVersion.fileId}
          fileName={currentVersion.fileName}
          validityStart={currentVersion.validityStart}
          createdBy={
            currentVersion.createdBy
              ? (orgUsers[currentVersion.createdBy]?.displayName as string)
              : COMPLYANCE_USER.displayName
          }
          actions={[
            {
              label: t('evidences.buttons.replaceEvidence'),
              icon: ArrowPathRoundedSquareIcon,
              onClick: createDisclosure.onOpen,
            },
            {
              label: t('buttons.edit'),
              icon: PencilIcon,
              onClick: editDisclosure.onOpen,
            },
          ]}
        />
        <EditEvidenceVersionModal
          evidenceId={evidenceId}
          evidenceVersionId={currentVersion.id}
          isOpen={editDisclosure.isOpen}
          onClose={editDisclosure.onClose}
          onEdit={() => {
            refetchEvidenceVersions();
            editDisclosure.onClose();
          }}
        />
      </CurrentVersionContainer>

      <CreateEvidenceVersionModal
        evidenceId={evidenceId}
        isOpen={createDisclosure.isOpen}
        onClose={createDisclosure.onClose}
        onCreate={() => {
          refetchEvidenceVersions();
          refetchEvidence();
          createDisclosure.onClose();
        }}
      />
    </Fragment>
  );
};

interface ContainerProps extends BoxProps {
  children: ReactNode;
}
const CurrentVersionContainer = ({ children, ...props }: ContainerProps) => {
  const { t } = useTranslation();
  return (
    <DrawerProperty>
      <DrawerProperty.Label icon={LinkIcon}>
        {t('evidences.version.currentVersion')}
      </DrawerProperty.Label>
      <DrawerProperty.Content px={2} {...props}>
        {children}
      </DrawerProperty.Content>
    </DrawerProperty>
  );
};
