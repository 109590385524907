import { Notification_Types_Enum } from '@main/graphql/types.generated';

import { router } from '../../router';
import {
  AssignedNotificationAdapter,
  AssignedNotificationData,
  AssignedNotificationHandler,
} from '../notifications/assigned-notification-handler';
import { NotificationItem } from '../notifications/notification-handler';

export interface MentionedInQuestionnaireCommentNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_comment: NonNullable<NotificationItem['content']['notifications_comment']> & {
      questionnaire: NonNullable<
        NonNullable<NotificationItem['content']['notifications_comment']>['questionnaire']
      >;
    };
  };
}

export class MentionedInQuestionnaireCommentNotificationHandler
  implements AssignedNotificationAdapter<MentionedInQuestionnaireCommentNotification>
{
  static readonly type = Notification_Types_Enum.MentionedInComment;

  static canHandle(
    notification: NotificationItem,
  ): notification is MentionedInQuestionnaireCommentNotification {
    return !!notification.content.notifications_comment?.questionnaire;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(
    notification: MentionedInQuestionnaireCommentNotification,
  ): AssignedNotificationData {
    return {
      internalId: notification.content.notifications_comment.questionnaire.internal_id,
      entityName: notification.content.notifications_comment.questionnaire.name,
      content: 'notification.questionnaire.mentioned',
    };
  }

  redirect(notification: MentionedInQuestionnaireCommentNotification) {
    router.navigate({
      to: '/questionnaires',
      search: {
        drawerEntity: 'questionnaire',
        drawerEntityId: notification.content.notifications_comment.questionnaire.id,
      },
    });
  }
}
