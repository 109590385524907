import {
  Box,
  Button,
  Card,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowDownTrayIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { useInsertEvidencesMutation } from '@main/graphql/mutations/InsertEvidences.generated';
import { SearchEntitiesEnum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import {
  errorToast,
  FileUpload,
  MultipleFilesUploadResult,
  NoPermissionPlaceholder,
  successToast,
  useEagerMultipleFilesUpload,
} from '@main/ui';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { removeExtentionFromFilename } from '../../../utils/string';
import { CreateEvidenceWithVersionsModal } from '../../evidence/evidence-versions';
import { useEvidenceDownloader } from '../../evidence/use-evidence-downloader';
import { AppGlobalSearchResult } from '../../global-search/use-global-search';
import { EntitySearch } from '../../link-entity/entity-search';
import { useLinkEvidenceToControlMutation } from '../../link-entity/link-entity.generated';
import { LinkEntityModal } from '../../link-entity/link-entity-modal';
import { useEntitySearch } from '../../link-entity/use-entity-search';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../../user/slice';
import { getMappedControl, getMappedControlEvidences } from '../slice';
import { AiSuggestionBlock } from './ai-evidence-suggestions';
import { ControlEvidenceTable } from './control-evidence-table';

type Status = 'empty' | 'dragging' | 'loading' | 'finished';

export const ControlEvidenceTab = ({ controlId }: { controlId: string }) => {
  const { t } = useTranslation();
  const {
    isOpen: isCreateModalOpen,
    onOpen: onCreateModalOpen,
    onClose: onCreateModalClose,
  } = useDisclosure();
  const [status, setStatus] = useState<Status>('empty');
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const evidenceList = useAppSelector((state) => {
    return getMappedControlEvidences(state, controlId);
  });
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canAddEvidence = userRole.permissionMap?.link_controls_evidence;
  const canViewEvidence = userRole.permissionMap.read_evidence;

  const evidenceSearch = useEntitySearch({
    entities: [SearchEntitiesEnum.Evidences],
  });
  const [createEvidences] = useInsertEvidencesMutation();

  const { linkEvidenceHandler, isLoading: isLinkingEvidence } = useLinkEvidence({
    controlId,
    onClose: () => evidenceSearch.disclosure.onClose?.(),
  });
  const control = useAppSelector((state) => getMappedControl(state, controlId));

  const evidenceDownloader = useEvidenceDownloader({
    downloadId: `control-evidence-${controlId}`,
  });
  const evidenceFetcher = { fetchEvidence: async () => evidenceList };

  useEffect(() => {
    if (evidenceList.length !== 0) {
      setStatus('finished');
    } else {
      setStatus('empty');
    }
  }, [evidenceList.length]);

  async function fileUploadHandler({ files }: MultipleFilesUploadResult) {
    try {
      await createEvidences({
        objects: files.map((file) => ({
          name: removeExtentionFromFilename(file.name),
          organization_id: organizationId,
          control_evidences: {
            data: [
              {
                control_id: controlId,
              },
            ],
          },
          evidence_versions: {
            data: [
              {
                validity_start: 'now()',
                evidence_version_file: {
                  data: {
                    file_id: file.id,
                  },
                },
              },
            ],
          },
        })),
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.evidence') }));
      datadogLogs.logger.error(
        'Failed to upload new evidence files',
        { ...files, controlId },
        toError(error),
      );
    } finally {
      setStatus('finished');
    }
  }

  const filesUpload = useEagerMultipleFilesUpload({
    onFilesUpload: fileUploadHandler,
  });

  const overlayBg = useColorModeValue('white', 'gray.700');

  if (!canViewEvidence) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <Stack direction="column" position="relative" spacing={6} p={0.5}>
      {status !== 'empty' || (status === 'empty' && !canAddEvidence) ? (
        <Card
          variant="outline"
          overflowY="hidden"
          rounded="md"
          onDragEnter={(event) => {
            if (
              canAddEvidence &&
              event.target instanceof HTMLElement &&
              event.currentTarget.contains(event.target)
            ) {
              setStatus('dragging');
            }
          }}
        >
          <ControlEvidenceTable isLoading={status === 'loading'} controlId={controlId} />
        </Card>
      ) : null}

      {canAddEvidence && (status === 'dragging' || status === 'empty') ? (
        <Box
          position={status === 'empty' ? 'unset' : 'absolute'}
          top={0}
          left={0}
          bgColor={overlayBg}
          w="full"
          h="full"
          zIndex={1}
        >
          <FileUpload {...filesUpload}>
            <FileUpload.Dropzone
              topLabelText={t('evidences.version.dropzone.topLabel')}
              bottomLabelText={t('evidences.version.dropzone.bottomLabel')}
              onDragLeave={() => evidenceList.length !== 0 && setStatus('finished')}
            />
          </FileUpload>
        </Box>
      ) : null}

      <Flex alignSelf="end" gap={2}>
        <Button
          leftIcon={<Icon w={4} h={4} as={ArrowDownTrayIcon} />}
          variant="outline"
          height="40px"
          p="0px 16px"
          size="md"
          gap="8px"
          isDisabled={evidenceList.length === 0 || evidenceDownloader.isDownloading}
          onClick={() =>
            evidenceDownloader.start(
              `${control?.name ?? `Control ${controlId}`} Evidence`,
              evidenceFetcher,
            )
          }
        >
          {t('buttons.bulkDownload')}
        </Button>
        {canAddEvidence && (
          <Menu>
            <MenuButton as={Button} rightIcon={<Icon as={ChevronDownIcon} />} colorScheme="blue">
              {t('evidences.add.addEvidence')}
            </MenuButton>
            <MenuList>
              <MenuItem onClick={onCreateModalOpen}>{t('evidences.add.addNewEvidence')}</MenuItem>
              <MenuItem onClick={() => evidenceSearch.disclosure.onOpen?.()}>
                {t('evidences.add.linkEvidence')}
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Flex>

      <CreateEvidenceWithVersionsModal
        controlId={controlId}
        isOpen={isCreateModalOpen}
        onCreate={onCreateModalClose}
        onClose={onCreateModalClose}
      />

      <AiSuggestionBlock controlId={controlId} />

      <LinkEntityModal
        isOpen={evidenceSearch.disclosure.isOpen}
        onClose={() => evidenceSearch.disclosure.onClose?.()}
        entityName="evidence"
        onLinkEntity={() => linkEvidenceHandler(evidenceSearch.selectedResults)}
        isLinkingEntity={isLinkingEvidence}
      >
        <EntitySearch
          input={{
            placeholder: t('shared.linkModal.placeholder', {
              entity: t('entities.evidence').toLowerCase(),
            }),
          }}
          {...evidenceSearch}
        />
      </LinkEntityModal>
    </Stack>
  );
};

export function useLinkEvidence({
  controlId,
  onClose,
}: {
  controlId: string;
  onClose: () => void;
}) {
  const { t } = useTranslation();
  const [linkEvidenceToControl, { isLoading }] = useLinkEvidenceToControlMutation();

  const linkEvidenceHandler = async (results: AppGlobalSearchResult[]) => {
    try {
      await linkEvidenceToControl({
        input: results.map((result) => ({ control_id: controlId, evidence_id: result.id })),
      }).unwrap();

      successToast(t('successMessages.linkSucceeded', { entity: t('entities.evidence') }));
      onClose();
    } catch (error) {
      errorToast(t('errorMessages.linkFailed', { entity: t('entities.evidence') }));
      datadogLogs.logger.error(
        `Failed linking evidence to control`,
        { controlId, evidence: results },
        toError(error),
      );
    }
  };

  return { linkEvidenceHandler, isLoading };
}
