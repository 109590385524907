import { HStack, Tag } from '@chakra-ui/react';
import {
  ChatBubbleLeftRightIcon,
  ClipboardDocumentCheckIcon,
  ClockIcon,
  DocumentMagnifyingGlassIcon,
  FingerPrintIcon,
  ListBulletIcon,
} from '@heroicons/react/24/outline';
import { useUpdateControlByIdMutation } from '@main/graphql/mutations/UpdateControlById.generated';
import { ControlTabKeys } from '@main/shared/utils';
import { Drawer, EditableAutoResizeTextarea, getVisibleTabs, StatusTag } from '@main/ui';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useOrgGuard } from '../../hooks/use-org-guard';
import { CommentsTab } from '../comments/comments-tab';
import { useUpdateControlOnDrawerHandler } from '../program/use-update-control-handler';
import { CONTROL_STATUS_COLOR } from '../shared/status-color';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { useGetControlSubscription } from './control-subscriptions';
import { ControlDetailsTab } from './details-tab';
import { ControlEvidenceTab } from './evidence/evidence-tab';
import { ControlFindingsTab } from './findings-tab';
import { useGetControlQuery } from './get-control.generated';
import { ControlHistoryTab } from './history-tab';
import { getControlFindings, getMappedControl } from './slice';
import { ControlTasksTab } from './tasks-tab';
import { useControlDrawerActions } from './use-drawer-actions';

export const ControlDrawer = ({ controlId }: { controlId: string }) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewControl = userRole.permissionMap?.read_controls;

  const { data, isLoading: isControlLoading } = useOrgGuard(
    useGetControlSubscription({ controlId }),
    ({ data }) => data?.controls_by_pk?.organization_id,
  );

  const { tabs } = useControlDrawerTabs(controlId);
  const { menuActions } = useControlDrawerActions(controlId);

  return (
    <Drawer.Layout
      isLoading={isControlLoading}
      canView={canViewControl}
      isNotFound={!data?.controls_by_pk}
    >
      <Drawer.Toolbar menuActions={menuActions} />
      <Drawer.Tabs tabs={tabs}>
        <ControlDrawerHeader controlId={controlId} />
      </Drawer.Tabs>
    </Drawer.Layout>
  );
};

const ControlDrawerHeader = ({ controlId }: { controlId: string }) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditControl = userRole.permissionMap?.update_controls;

  const [updateControlById] = useUpdateControlByIdMutation();
  const updateControlHandler = useUpdateControlOnDrawerHandler();

  const control = useAppSelector((state) => getMappedControl(state, controlId));

  if (!control) {
    return null;
  }

  return (
    <>
      <HStack mb={4}>
        <Tag colorScheme="purple">
          {t('entities.control')} / {control.internal_id}
        </Tag>
        <StatusTag
          size="sm"
          colorScheme={CONTROL_STATUS_COLOR[control.status]}
          data-testid="control-status"
        >
          {t(`controls.status.enums.${control.status}`)}
        </StatusTag>
      </HStack>
      <EditableAutoResizeTextarea
        isDisabled={!canEditControl}
        ml={-2}
        fontSize="xl"
        fontWeight="bold"
        defaultValue={control.name}
        placeholder={t('controls.controlTitle')}
        onSubmit={(value) => {
          controlId &&
            updateControlHandler(
              updateControlById({
                controlId,
                updatePayload: { name: value },
              }),
            );
        }}
      />
    </>
  );
};

const useControlDrawerTabs = (controlId: string) => {
  const { t } = useTranslation();
  const { refetch } = useGetControlQuery({ controlId });
  const control = useAppSelector((state) => getMappedControl(state, controlId));
  const controlFindings = useAppSelector((state) => getControlFindings(state, controlId));

  const refetchComments = useCallback(async () => {
    await refetch().unwrap();
  }, [refetch]);

  const tabs = useMemo(
    () =>
      getVisibleTabs<ControlTabKeys>({
        details: {
          label: t('controls.tabs.details'),
          icon: ListBulletIcon,
          panel: <ControlDetailsTab controlId={controlId} />,
          hideTabTitle: true,
        },
        evidence: {
          label: t('controls.tabs.evidence'),
          icon: FingerPrintIcon,
          panel: <ControlEvidenceTab controlId={controlId} />,
        },
        findings: {
          label: t('controls.tabs.findings'),
          icon: DocumentMagnifyingGlassIcon,
          panel: <ControlFindingsTab controlId={controlId} />,
          badge: {
            count: controlFindings.countWithoutIgnored,
            severity: controlFindings.highestSeverity,
          },
          isHidden: controlFindings.findings.length === 0,
        },
        comments: {
          label: t('controls.tabs.comments'),
          icon: ChatBubbleLeftRightIcon,
          panel: (
            <CommentsTab
              entity="control"
              entityId={controlId}
              comments={control?.controls_comments ?? []}
              refetchComments={refetchComments}
            />
          ),
        },
        tasks: {
          label: t('controls.tabs.tasks'),
          icon: ClipboardDocumentCheckIcon,
          panel: <ControlTasksTab controlId={controlId} />,
        },
        history: {
          label: t('controls.tabs.history'),
          icon: ClockIcon,
          panel: <ControlHistoryTab controlId={controlId} />,
        },
      }),
    [
      t,
      controlId,
      controlFindings.highestSeverity,
      controlFindings.countWithoutIgnored,
      controlFindings.findings.length,
      control?.controls_comments,
      refetchComments,
    ],
  );

  return { tabs };
};
