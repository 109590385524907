import { AnyDynamicFormComponentRegistry, DynamicFormFieldConfig } from '@main/dynamic-form';
import { Vendor_Questionnaire_Form_Answer_Statuses_Enum } from '@main/graphql/types.generated';
import { ErrorToMessage } from '@main/ui';

import { QuestionConditionChecker } from './conditions';
import {
  QuestionnnaireFormFieldAnswer,
  QuestionnnaireFormFilterMode,
  QuestionnnaireFormFilters,
} from './form-types';

export function getQuestionFilter(filters: QuestionnnaireFormFilters) {
  return <TRegistry extends AnyDynamicFormComponentRegistry>(
    config: DynamicFormFieldConfig<TRegistry>,
    answer?: QuestionnnaireFormFieldAnswer,
  ) => {
    return (
      (filters.mode === QuestionnnaireFormFilterMode.Unanswered &&
        answer?.status === Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed) ||
      (filters.search.length > 0 &&
        config.label &&
        !config.label.toLowerCase().includes(filters.search))
    );
  };
}

export function createNewAnswerFor(
  field: DynamicFormFieldConfig<AnyDynamicFormComponentRegistry>,
): QuestionnnaireFormFieldAnswer {
  return {
    field_name: field.name,
    status: Vendor_Questionnaire_Form_Answer_Statuses_Enum.Pending,
  };
}

export function reorderFormAnswers<
  TAnswer extends QuestionnnaireFormFieldAnswer,
  TRegisrty extends AnyDynamicFormComponentRegistry,
>(answers: TAnswer[], config: DynamicFormFieldConfig<TRegisrty>[]) {
  const indexMap = Object.fromEntries(config.map((field, index) => [field.name, index]) ?? []);
  const orderedAnswers = new Array<TAnswer>(answers.length ?? 0);

  for (const answer of answers ?? []) {
    const index = indexMap[answer.field_name];

    if (index !== undefined) {
      orderedAnswers[index] = answer;
    }
  }

  return orderedAnswers;
}

export function getFormConfigFilter<
  TAnswer extends QuestionnnaireFormFieldAnswer,
  TRegisrty extends AnyDynamicFormComponentRegistry,
>(
  answers: readonly TAnswer[],
  answersMap: Map<string, TAnswer>,
  conditionsFiltersMap: Map<string, QuestionConditionChecker>,
) {
  return (field: DynamicFormFieldConfig<TRegisrty>) =>
    conditionsFiltersMap.get(field.name)?.(answers, answersMap.get(field.name)) !== true;
}

export const questionnaireErrors: ErrorToMessage = {
  required: 'questionnaireValidation.required',
};
