import { ThemeTypings } from '@chakra-ui/react';
import {
  ControlEvidenceStatus,
  ControlStatus,
  EvidenceStatus,
  RiskAssessmentStatus,
  TaskStatus,
  VendorAssessmentStatus,
} from '@main/graphql/client-scalars';
import {
  Finding_Types_Enum,
  Policy_Acknowledgement_Statuses_Enum,
  Policy_Approval_Statuses_Enum,
  Policy_Statuses_Enum,
  Policy_Version_Statuses_Enum,
  Risk_Levels_Enum,
  Treatment_Plan_Enum,
  Vendor_Approval_Statuses_Enum,
} from '@main/graphql/types.generated';

type StatusToColorMap<Status extends string> = Record<Status, ThemeTypings['colorSchemes']>;

/* Control */
export const CONTROL_STATUS_COLOR = {
  [ControlStatus.PENDING]: 'gray',
  [ControlStatus.NOT_RELEVANT]: 'gray',
  [ControlStatus.VALID]: 'green',
  [ControlStatus.AT_RISK]: 'orange',
  [ControlStatus.FAILING]: 'red',
} satisfies StatusToColorMap<ControlStatus>;

export const FINDING_TYPE_COLOR = {
  [Finding_Types_Enum.Consideration]: 'teal',
  [Finding_Types_Enum.Issue]: 'red',
  [Finding_Types_Enum.Recommendation]: 'orange',
} satisfies StatusToColorMap<Finding_Types_Enum>;

/* Evidence */
export const EVIDENCE_STATUS_COLOR = {
  [EvidenceStatus.VALID]: 'green',
  [EvidenceStatus.AT_RISK]: 'orange',
  [EvidenceStatus.INVALID]: 'red',
  [EvidenceStatus.PENDING]: 'gray',
};

export const CONTROL_EVIDENCE_STATUS_COLOR = {
  [ControlEvidenceStatus.CALCULATING]: 'gray',
  [ControlEvidenceStatus.AT_RISK]: 'orange',
  [ControlEvidenceStatus.INVALID]: 'red',
  [ControlEvidenceStatus.VALID]: 'green',
} satisfies StatusToColorMap<ControlEvidenceStatus>;

/* Risk */
export const RISK_ASSESSMENT_STATUS_COLOR = {
  [RiskAssessmentStatus.PENDING]: 'gray',
  [RiskAssessmentStatus.REVIEWED]: 'green',
  [RiskAssessmentStatus.EXPIRING]: 'orange',
  [RiskAssessmentStatus.OVERDUE]: 'red',
  [RiskAssessmentStatus.CLOSED]: 'gray',
} satisfies StatusToColorMap<RiskAssessmentStatus>;

export const RISK_LEVEL_COLOR = {
  [Risk_Levels_Enum.VeryHigh]: 'red',
  [Risk_Levels_Enum.High]: 'red',
  [Risk_Levels_Enum.Medium]: 'orange',
  [Risk_Levels_Enum.Low]: 'green',
  [Risk_Levels_Enum.VeryLow]: 'green',
} satisfies StatusToColorMap<Risk_Levels_Enum>;

export const RISK_TREATMENT_PLAN_COLOR = {
  [Treatment_Plan_Enum.Accepted]: 'purple',
  [Treatment_Plan_Enum.Avoided]: 'teal',
  [Treatment_Plan_Enum.Mitigated]: 'green',
  [Treatment_Plan_Enum.Pending]: 'gray',
  [Treatment_Plan_Enum.Transferred]: 'orange',
} satisfies StatusToColorMap<Treatment_Plan_Enum>;

/* Vendor */
export const VENDOR_ASSESSMENT_STATUS_COLOR = {
  [VendorAssessmentStatus.PENDING]: 'gray',
  [VendorAssessmentStatus.REVIEWED]: 'green',
  [VendorAssessmentStatus.EXPIRING]: 'orange',
  [VendorAssessmentStatus.OVERDUE]: 'red',
  [VendorAssessmentStatus.TERMINATED]: 'gray',
} satisfies StatusToColorMap<VendorAssessmentStatus>;

export const VENDOR_APPROVAL_STATUS_COLOR = {
  [Vendor_Approval_Statuses_Enum.Accepted]: 'green',
  [Vendor_Approval_Statuses_Enum.Pending]: 'gray',
  [Vendor_Approval_Statuses_Enum.Rejected]: 'red',
} satisfies StatusToColorMap<Vendor_Approval_Statuses_Enum>;

/* Policy */
export const POLICY_STATUS_COLOR = {
  [Policy_Statuses_Enum.Draft]: 'gray',
  [Policy_Statuses_Enum.AwaitingApproval]: 'orange',
  [Policy_Statuses_Enum.Approved]: 'green',
} satisfies StatusToColorMap<Policy_Statuses_Enum>;

export const POLICY_VERSION_STATUS_COLOR = {
  [Policy_Version_Statuses_Enum.Draft]: 'gray',
  [Policy_Version_Statuses_Enum.AwaitingApproval]: 'orange',
  [Policy_Version_Statuses_Enum.Retired]: 'red',
  [Policy_Version_Statuses_Enum.Approved]: 'green',
} satisfies StatusToColorMap<Policy_Version_Statuses_Enum>;

export const POLICY_APPROVAL_STATUS_COLOR = {
  [Policy_Approval_Statuses_Enum.Pending]: 'gray',
  [Policy_Approval_Statuses_Enum.Expiring]: 'orange',
  [Policy_Approval_Statuses_Enum.Overdue]: 'red',
  [Policy_Approval_Statuses_Enum.Approved]: 'green',
  [Policy_Approval_Statuses_Enum.Retired]: 'red',
} satisfies StatusToColorMap<Policy_Approval_Statuses_Enum>;

export const POLICY_ACKNOWLEDGEMENT_STATUS_COLOR = {
  [Policy_Acknowledgement_Statuses_Enum.Pending]: 'gray',
  [Policy_Acknowledgement_Statuses_Enum.Expiring]: 'orange',
  [Policy_Acknowledgement_Statuses_Enum.Retired]: 'red',
  [Policy_Acknowledgement_Statuses_Enum.Overdue]: 'red',
  [Policy_Acknowledgement_Statuses_Enum.Acknowledged]: 'green',
} satisfies StatusToColorMap<Policy_Acknowledgement_Statuses_Enum>;

/* Tasks */
export const TASK_STATUS_COLOR = {
  [TaskStatus.PENDING]: 'gray',
  [TaskStatus.IN_PROGRESS]: 'orange',
  [TaskStatus.COMPLETED]: 'green',
  [TaskStatus.OVERDUE]: 'red',
} satisfies StatusToColorMap<TaskStatus>;
