import { Nullable } from '@main/shared/types';
import { FilterMode } from '@main/shared/url-helpers';
import { Column, ColumnDef, Row, SortDirection, Table } from '@tanstack/react-table';
import { ActionMeta, OnChangeValue } from 'chakra-react-select';
import { ReactElement } from 'react';

import { ComboboxProps, selectValueToArray } from '../../../combobox';
import { EditablePopover } from '../../../editable';
import { CellContainer } from '../cell';
import { createSelectFilter } from './select-filter';

type SelectHelperConfig<TOptionBase, TExtraColumnDef> = {
  getOptionValue: (option: TOptionBase) => string;
  getOptionLabel: (option: TOptionBase) => string;

  components: {
    Preview: <TData, TOption extends TOptionBase, IsMulti extends boolean, TFilter, TSort>(props: {
      columnDef: SelectColumnDef<TData, TOption, IsMulti, TFilter, TSort> & TExtraColumnDef;
      value?: OnChangeValue<TOption, IsMulti>;
    }) => ReactElement;

    Edit: <TOption extends TOptionBase, IsMulti extends boolean>(
      props: Partial<ComboboxProps<TOption, IsMulti>>,
    ) => ReactElement;
  };
};

type SelectColumnDef<TData, TOption, IsMulti extends boolean, TFilter, TSort> = ColumnDef<
  TData,
  OnChangeValue<TOption, IsMulti> | undefined
> &
  SelectColumnConfig<TData, TOption, IsMulti, TFilter, TSort>;

type SelectColumnConfig<TData, TOption, IsMulti extends boolean, TFilter, TSort> = {
  header: string;
  isMulti?: IsMulti;
  /**
   * Should return an array of possible select options.
   * When not defined, the options will be inferred from the table data.
   */
  getOptions?: (table: Table<TData>, column: Column<TData>) => TOption[] | Promise<TOption[]>;
  edit?: SelectEditConfig<TData, TOption, IsMulti>;
  getGlobalFilterCondition?(filterValue: string): Nullable<TFilter>;
  getColumnFilterCondition?(filterMode: FilterMode, filterValue: string[]): Nullable<TFilter>;
  getColumnSort?(sortDirection: SortDirection): TSort;
};

type SelectEditConfig<TData, TOption, IsMulti extends boolean> = Omit<
  Partial<ComboboxProps<TOption, IsMulti>>,
  'isMulti' | 'value' | 'onChange'
> & {
  onChange: (
    row: Row<TData>,
    value: OnChangeValue<TOption, IsMulti>,
    meta: ActionMeta<TOption>,
  ) => void;
};

export function createSelectHelper<TOptionBase extends object, TExtraColumnDef = object>({
  getOptionValue,
  getOptionLabel,
  components,
}: SelectHelperConfig<TOptionBase, TExtraColumnDef>) {
  return <TData, TFilter, TSort>() =>
    <TOption extends TOptionBase, IsMulti extends boolean = false>(
      columnDef: SelectColumnDef<TData, TOption, IsMulti, TFilter, TSort> & TExtraColumnDef,
    ): ColumnDef<TData, OnChangeValue<TOption, IsMulti> | undefined> => {
      const selectFilter = createSelectFilter<TData, TOptionBase, TOption, IsMulti>({
        name: columnDef.header,
        getOptions: columnDef.getOptions,
        getOptionValue,
        getOptionLabel,
        components,
      });

      return {
        enableGlobalFilter: false,
        enableColumnFilter: false,
        enableSorting: false,
        sortDescFirst: false,
        sortUndefined: false,

        ...columnDef,

        cell: ({ row, getValue, cell }) => {
          const value = getValue();

          if (columnDef.edit) {
            const { isMulti, edit } = columnDef;

            return (
              <EditablePopover>
                <EditablePopover.Trigger>
                  <CellContainer p={0} cell={cell}>
                    <components.Preview columnDef={columnDef} value={value} />
                  </CellContainer>
                </EditablePopover.Trigger>
                <EditablePopover.Content
                  borderRadius="sm"
                  outline="2px solid"
                  outlineColor="chakra-border-color"
                >
                  <components.Edit
                    {...edit}
                    variant="popover"
                    size="sm"
                    placeholder=""
                    autoFocus
                    menuIsOpen
                    isMulti={isMulti}
                    value={value}
                    onChange={(...args) => {
                      edit.onChange(row, ...args);
                    }}
                  />
                </EditablePopover.Content>
              </EditablePopover>
            );
          }

          return (
            <CellContainer p={0} cell={cell}>
              <components.Preview columnDef={columnDef} value={value} />
            </CellContainer>
          );
        },

        filterFn: selectFilter.filterFn,

        sortingFn(rowA, rowB, columnId) {
          const getFirstRowLabel = (row: Row<TData>): string => {
            const [option] = selectValueToArray<TOption>(row.getValue(columnId));
            return option ? getOptionLabel(option) : '';
          };

          const labelA = getFirstRowLabel(rowA);
          if (!labelA) return 1;
          const labelB = getFirstRowLabel(rowB);
          if (!labelB) return -1;
          return labelA.localeCompare(labelB);
        },

        meta: {
          ...columnDef.meta,
          name: columnDef.header,
          globalFilterFn: selectFilter.globalFilterFn,
          filter: selectFilter.meta,
          getGlobalFilterCondition: columnDef.getGlobalFilterCondition,
          getColumnFilterCondition: columnDef.getColumnFilterCondition,
          getColumnSort: columnDef.getColumnSort,
        },
      };
    };
}
