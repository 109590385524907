import { ChakraStylesConfig, GroupBase } from 'chakra-react-select';

import { isNewOption } from './new-option';

export type ComboboxVariant = 'inline' | 'popover' | 'outline';

/**
 * Base `Combobox` styles, shared by all variants.
 * Before adding new css here, please check that it does not break any variant.
 */
const baseStyles = {
  container: (base, state) => ({
    ...base,
    width: 'full',
  }),
  control: ({ padding, ...base }, state) => ({
    ...base,
    ...(state.selectProps.menuIsOpen
      ? {
          bgColor: 'gray.25',
          borderColor: 'chakra-border-color',
          borderBottomRadius: 0,
          _dark: {
            bgColor: 'gray.700',
          },
        }
      : {
          borderColor: 'transparent',
          _focusWithin: state.selectProps.isReadOnly
            ? {}
            : {
                bgColor: 'gray.25',
                borderColor: 'chakra-border-color',
                _dark: {
                  bgColor: 'gray.800',
                },
              },
          _hover: state.selectProps.isReadOnly
            ? {}
            : {
                cursor: 'pointer',
                bgColor: 'gray.100',
                _dark: {
                  bgColor: 'gray.600',
                },
              },
        }),
  }),
  valueContainer: (base) => ({
    ...base,
    gap: 2,
    py: 1.5,
  }),
  multiValue: (base) => ({
    ...base,
    m: 0,
  }),
  menu: (base) => ({
    ...base,
    m: 0,
    top: 'unset',
  }),
  menuList: (base) => ({
    ...base,
    p: 0,
    borderTop: 'none',
    borderTopRadius: 0,
  }),
  option: (base, state) => ({
    ...base,
    ...(isNewOption(state.data)
      ? {
          minH: 10,
          mt: 2,
          borderTopWidth: '1px',
          borderColor: 'chakra-border-color',
          ...(!state.isFocused && {
            bgColor: 'gray.25',
            _dark: {
              bgColor: 'gray.700',
            },
          }),
        }
      : {
          _first: {
            mt: 2,
          },
          _last: {
            mb: 2,
          },
        }),
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: 'xs',
    m: 0,
    _dark: {
      color: 'gray.500',
    },
  }),
} satisfies ChakraStylesConfig<object>;

/**
 * Variant-specific `Combobox` styles, which extend base styles above
 */
const variantStyles: Record<ComboboxVariant, ChakraStylesConfig<object>> = {
  inline: {
    ...baseStyles,
    control: (base, state) => ({
      ...baseStyles.control(base, state),
      ...(state.selectProps.menuIsOpen && {
        borderWidth: '1px',
      }),
      _disabled: {
        opacity: 1,
      },
      minH: 'auto',
      borderWidth: '1px',
    }),
    inputContainer: (base) => ({
      ...base,
      p: 0,
      m: 0,
    }),
    input: (base) => ({
      ...base,
      fontSize: 'sm',
    }),
    valueContainer: (base) => ({
      ...baseStyles.valueContainer(base),
      px: 2,
    }),
  },

  popover: {
    ...baseStyles,
    control: (base, state) => ({
      ...baseStyles.control(base, state),
      ...(state.selectProps.menuIsOpen && {
        borderBottomWidth: '1px',
        px: 4,
      }),
    }),
    menu: (base) => ({
      ...baseStyles.menu(base),
      position: 'relative',
    }),
    menuList: (base) => ({
      ...baseStyles.menuList(base),
      border: 'none',
      shadow: 'none',
    }),
    valueContainer: (base) => ({
      ...baseStyles.valueContainer(base),
      minH: '52px',
    }),
  },

  outline: {
    ...baseStyles,
    control: (base, state) => ({
      ...baseStyles.control(base, state),
      minH: 'auto',
      borderWidth: '1px',
      borderColor: 'chakra-border-color',
      ...(state.selectProps.isReadOnly
        ? {
            opacity: 0.5,
          }
        : {}),
      _hover: state.selectProps.isReadOnly
        ? {}
        : {
            cursor: 'pointer',
            borderColor: 'gray.300',
            _dark: {
              borderColor: 'gray.600',
            },
          },
    }),
    inputContainer: (base) => ({
      ...base,
      p: 0,
      m: 0,
    }),
    input: (base) => ({
      ...base,
      fontSize: 'sm',
    }),
    valueContainer: (base) => ({
      ...baseStyles.valueContainer(base),
      px: 4,
      py: '7px',
    }),
    placeholder: (base) => ({
      ...baseStyles.placeholder(base),
      fontSize: 'md',
      color: 'gray.500',
      m: 0,
      _dark: {
        color: 'gray.500',
      },
    }),
  },
};

export function getChakraStyles<
  TOption extends object,
  IsMulti extends boolean,
  TGroup extends GroupBase<TOption>,
>(variant: ComboboxVariant) {
  // we are not using option specific properties during styling - therefore can safely cast here,
  // instead of wrapping styles in another function and propagating the generics
  return variantStyles[variant] as unknown as ChakraStylesConfig<TOption, IsMulti, TGroup>;
}
