import { Flex, useColorModeValue } from '@chakra-ui/react';

export const TabFooter = ({ children }: { children: React.ReactNode }) => {
  const tipBgColor = useColorModeValue('white', 'gray.700');

  return (
    <Flex position="absolute" bg={tipBgColor} px={9} py={5} bottom={0} left={0} right="60px">
      {children}
    </Flex>
  );
};
