import { Notification_Types_Enum } from '@main/graphql/types.generated';
import { Notification } from '@main/notifications';
import { ParseKeys } from 'i18next';

import { FeatureFlagsState } from '../feature-flags/slice';
import { Organization } from './slice';

interface NotificationState {
  featureFlags: FeatureFlagsState;
  userOrg: Organization;
}

export const notificationsMask = {
  /* General */
  entity_ownership_changed: () => true,
  mentioned_in_comment: () => true,
  /* Controls */
  control_status_changed_to_not_valid: ({ userOrg }) => userOrg.is_controls_module_enabled,
  /* Evidence */
  evidence_access_granted: ({ userOrg }) => userOrg.is_controls_module_enabled,

  /* Tasks */
  overdue_tasks_assigned_to_you: () => true,
  overdue_tasks_created_by_you: () => true,

  /* Risks */
  risk_status_changed_to_expiring: ({ userOrg }) => userOrg.is_risks_module_enabled,
  /* Vendors */
  vendor_status_changed_to_expiring: ({ userOrg }) => userOrg.is_vendors_module_enabled,
  vendor_questionnaire_submission: ({ userOrg }) => userOrg.is_vendors_module_enabled,
  questionnaire_status_overdue: ({ userOrg }) => userOrg.is_vendors_module_enabled,
  /* Policies */
  policy_acknowledgement_reminder: ({ userOrg }) => userOrg.is_policies_module_enabled,
  policy_approval_reminder: ({ userOrg }) => userOrg.is_policies_module_enabled,
  /* Trust */
  client_questionnaire_processing_done: ({ featureFlags }) =>
    !!featureFlags['client-questionnaires'],
  client_questionnaire_processing_failed: ({ featureFlags }) =>
    !!featureFlags['client-questionnaires'],
  client_questionnaire_status_expiring: ({ featureFlags }) =>
    !!featureFlags['client-questionnaires'],
  client_questionnaire_status_overdue: ({ featureFlags }) =>
    !!featureFlags['client-questionnaires'],
} satisfies Record<Notification_Types_Enum, (state: NotificationState) => boolean>;

export const notificationsTranslation = {
  control_due_status: 'notifications.types.control_due_status',
  risk_due_status: 'notifications.types.risk_due_status',
  vendor_due_status: 'notifications.types.vendor_due_status',
  vq_submission: 'notifications.types.vq_submission',
  cq_due_status: 'notifications.types.cq_due_status',
  cq_processing_result: 'notifications.types.cq_processing_result',
  evidence_access_granted: 'notifications.types.evidence_access_granted',
  mentioned_in_comment: 'notifications.types.mentioned_in_comment',
  ownership_changed: 'notifications.types.ownership_changed',
  overdue_tasks_assigned_to_you: 'notifications.types.overdue_tasks_assigned_to_you',
  overdue_tasks_created_by_you: 'notifications.types.overdue_tasks_created_by_you',
  policy_acknowledgement_reminder: 'notifications.types.policy_acknowledgement_reminder',
  policy_approval_reminder: 'notifications.types.policy_approval_reminder',
  questionnaire_status_overdue: 'notifications.types.questionnaire_status_overdue',
} satisfies Record<Notification['id'], ParseKeys>;
