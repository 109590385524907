import { FieldError } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { defaultErrorToMessage, ErrorToMessage } from './error-message';

export * from './error-message';

export function FieldErrorMessage({
  error,
  errorToMessage = defaultErrorToMessage,
}: {
  error?: FieldError;
  errorToMessage?: ErrorToMessage;
}): string | undefined {
  const { t } = useTranslation('ui');

  if (!error) {
    return;
  }

  if (error?.message) {
    return error.message;
  }

  const msg = errorToMessage[error.type];

  if (!msg) {
    return;
  }

  if (typeof msg === 'function') {
    return msg(error);
  }

  return t(msg as never);
}
