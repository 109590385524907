import { TaskStatus } from '@main/graphql/client-scalars';
import { Notification_Types_Enum } from '@main/graphql/types.generated';
import { useTranslation } from 'react-i18next';

import { router } from '../../router';
import {
  AssignedNotificationAdapter,
  AssignedNotificationData,
  AssignedNotificationHandler,
} from '../notifications/assigned-notification-handler';
import { StatusUpdateContent } from '../notifications/notification-content';
import {
  NotificationContentComponent,
  NotificationContentProps,
  NotificationHandler,
  NotificationItem,
} from '../notifications/notification-handler';

export interface TaskAssignedNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    task: NonNullable<NotificationItem['content']['task']>;
  };
}

function isTaskAssignedNotification(
  notification: NotificationItem,
): notification is TaskAssignedNotification {
  return !!notification.content.task;
}

export class TaskAssignedNotificationHandler
  implements AssignedNotificationAdapter<TaskAssignedNotification>
{
  static readonly type = Notification_Types_Enum.EntityOwnershipChanged;
  static canHandle = isTaskAssignedNotification;

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: TaskAssignedNotification): AssignedNotificationData {
    return {
      internalId: notification.content.task.internal_id,
      entityName: notification.content.task.name,
      content: 'notification.task.assigned',
    };
  }

  redirect(notification: TaskAssignedNotification) {
    router.navigate({
      to: '/tasks',
      search: {
        drawerEntity: 'task',
        drawerEntityId: notification.content.task.id,
      },
    });
  }
}

export interface MentionedInTaskCommentNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_comment: NonNullable<NotificationItem['content']['notifications_comment']> & {
      comments_task: NonNullable<
        NonNullable<NotificationItem['content']['notifications_comment']>['comments_task']
      >;
    };
  };
}

export class MentionedInTaskCommentNotificationHandler
  implements AssignedNotificationAdapter<MentionedInTaskCommentNotification>
{
  static readonly type = Notification_Types_Enum.MentionedInComment;

  static canHandle(
    notification: NotificationItem,
  ): notification is MentionedInTaskCommentNotification {
    return !!notification.content.notifications_comment?.comments_task;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: MentionedInTaskCommentNotification): AssignedNotificationData {
    return {
      internalId: notification.content.notifications_comment.comments_task.internal_id,
      entityName: notification.content.notifications_comment.comments_task.name,
      content: 'notification.task.mentioned',
    };
  }

  redirect(notification: MentionedInTaskCommentNotification) {
    router.navigate({
      to: '/tasks',
      search: {
        drawerEntity: 'task',
        drawerEntityId: notification.content.notifications_comment.comments_task.id,
        activeTab: 'comments',
      },
    });
  }
}

export interface TasksStatusUpdatedNotification extends TaskAssignedNotification {
  content: TaskAssignedNotification['content'] & {
    params: { taskStatus: TaskStatus };
  };
}

export class TasksStatusUpdatedNotificationHandler
  implements NotificationHandler<TasksStatusUpdatedNotification>
{
  static readonly type = [
    Notification_Types_Enum.OverdueTasksCreatedByYou,
    Notification_Types_Enum.OverdueTasksAssignedToYou,
  ];
  static canHandle = isTaskAssignedNotification;

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<TasksStatusUpdatedNotification, this> {
    return TasksStatusUpdatedNotificationContent;
  }

  redirect(notification: TasksStatusUpdatedNotification): void {
    router.navigate({
      to: '/tasks',
      search: { drawerEntity: 'task', drawerEntityId: notification.content.task.id },
    });
  }
}

function TasksStatusUpdatedNotificationContent({
  notification,
}: NotificationContentProps<TasksStatusUpdatedNotification>) {
  const { t } = useTranslation();
  const taskStatus = t(`tasks.enum.status.${notification.content.params.taskStatus}`).toLowerCase();

  return (
    <StatusUpdateContent
      content={t('notification.task.needsReview', { taskStatus })}
      internalId={notification.content.task.internal_id ?? ''}
      entityName={notification.content.task.name ?? ''}
    />
  );
}
