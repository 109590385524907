import { Box, Flex, Stack, Tag, Text, useColorModeValue } from '@chakra-ui/react';
import { CalendarDaysIcon, HashtagIcon, UserIcon } from '@heroicons/react/24/outline';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { useAiAutocompleteEntityMutation } from '@main/graphql/features/AiAutocompleteEntity.generated';
import { Field_Entities_Enum, Treatment_Plan_Enum } from '@main/graphql/types.generated';
import {
  DrawerProperty,
  EditableAutoResizeTextarea,
  EditableAvatar,
  EditableTag,
  HoverAskAiButton,
  MarkdownEditor,
} from '@main/ui';
import { BarsIcon, DoubleTagsIcon, TagIcon } from '@main/ui/icons';
import { TFunction } from 'i18next/typescript/t';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { CustomFieldInput } from '../custom-fields/custom-field-input';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { getFrequencyOption, getFrequencyOptions } from '../shared/frequency';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import { RISK_LEVEL_COLOR } from '../shared/status-color';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { Categories } from './categories';
import { Tags } from './tags';
import { useUpdateRiskMutation } from './update-risk.generated';
import { useGetRiskDrawerData } from './use-get-risk-data';
import { useUpdateRiskHandler } from './use-update-risk-handler';

const treatmentPlan = Object.values(Treatment_Plan_Enum);

function getTreatmentOptions(t: TFunction) {
  const levelOptions = treatmentPlan.map((value) => ({
    value,
    label: t(`risks.enum.treatment.${value}`),
    colorScheme: 'purple',
  }));

  return levelOptions;
}

export const RiskDetailsTab = ({ riskId }: { riskId: string }) => {
  const { t } = useTranslation();
  const textColor = useColorModeValue('gray.600', 'gray.500');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const { is_day_zero_ai_features_enabled } = useAppSelector(getCurrentUserSelectedOrg);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateRisks = userRole.permissionMap?.write_risks;

  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId: organizationId,
    entityName: Field_Entities_Enum.Risk,
  });
  const [updateRisk] = useUpdateRiskMutation();
  const [aiAutocompleteEntity] = useAiAutocompleteEntityMutation();
  const updateRiskHandler = useUpdateRiskHandler();
  const { risk } = useGetRiskDrawerData({ riskId });

  const getAssigneeValue = () => {
    if (!risk?.assignee) {
      return;
    }

    const user = currentOrgUsers[risk.assignee.id];
    if (!user) {
      return;
    }

    return {
      id: risk.assignee.id,
      displayName: user.displayName,
    };
  };

  const updateTreatmentPlan = useCallback(
    (treatment_details?: string) =>
      risk?.id &&
      risk.treatment_details !== treatment_details &&
      updateRiskHandler(
        updateRisk({
          id: risk.id,
          risk_input: { treatment_details },
        }),
      ),
    [risk, updateRisk, updateRiskHandler],
  );

  if (!risk) {
    return null;
  }

  return (
    <Stack spacing={[6, 3]}>
      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={BarsIcon}>
          {t('risks.details.description')}
        </DrawerProperty.Label>
        <DrawerProperty.Content {...HoverAskAiButton.ParentProps}>
          <EditableAutoResizeTextarea
            defaultValue={risk.description}
            placeholder={t('risks.details.placeholder.description')}
            color={textColor}
            onSubmit={(value) =>
              updateRiskHandler(
                updateRisk({
                  id: riskId,
                  risk_input: {
                    description: value,
                  },
                }),
              )
            }
          />
          {is_day_zero_ai_features_enabled && canUpdateRisks && (
            <HoverAskAiButton
              data-testid="autocomplete-risk-description"
              renderRecommendation={(description) => (
                <Text data-testid="risk-description-recommendation">{description}</Text>
              )}
              onRecommendationRequest={() =>
                aiAutocompleteEntity({ input: { entity: 'risk-description', entityId: riskId } })
                  .unwrap()
                  .then((res) => res.ai_autocomplete_entity?.autocomplete)
              }
              onRecommendationAccept={(description) =>
                updateRiskHandler(updateRisk({ id: riskId, risk_input: { description } }))
              }
            >
              {risk.description ? t('askAiButton.regenerateLabel') : t('askAiButton.generateLabel')}
            </HoverAskAiButton>
          )}
        </DrawerProperty.Content>
      </DrawerProperty>
      {risk.inherentRiskLevel && (
        <DrawerProperty>
          <DrawerProperty.Label
            icon={TagIcon}
            tooltip={t('risks.details.tooltip.calculatedRiskField')}
          >
            {t('risks.details.inherentRisk')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Tag colorScheme={RISK_LEVEL_COLOR[risk.inherentRiskLevel]} w="fit-content">
              {t(`risks.enum.level.${risk.inherentRiskLevel}`)}
            </Tag>
          </DrawerProperty.Content>
        </DrawerProperty>
      )}

      {risk.inherentScore ? (
        <DrawerProperty>
          <DrawerProperty.Label
            icon={HashtagIcon}
            tooltip={t('risks.details.tooltip.inherentScore')}
          >
            {t('risks.details.inherentScore')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Text fontSize="xs">{risk.inherentScore}</Text>
          </DrawerProperty.Content>
        </DrawerProperty>
      ) : null}

      {risk.residualRiskLevel && (
        <DrawerProperty>
          <DrawerProperty.Label
            icon={TagIcon}
            tooltip={t('risks.details.tooltip.calculatedRiskField')}
          >
            {t('risks.details.residualRisk')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Tag colorScheme={RISK_LEVEL_COLOR[risk.residualRiskLevel]} w="fit-content">
              {t(`risks.enum.level.${risk.residualRiskLevel}`)}
            </Tag>
          </DrawerProperty.Content>
        </DrawerProperty>
      )}

      {risk.residualScore ? (
        <DrawerProperty>
          <DrawerProperty.Label
            icon={HashtagIcon}
            tooltip={t('risks.details.tooltip.residualScore')}
          >
            {t('risks.details.residualScore')}
          </DrawerProperty.Label>
          <DrawerProperty.Content px={2} display="flex" alignItems="center">
            <Text fontSize="xs">{risk.residualScore}</Text>
          </DrawerProperty.Content>
        </DrawerProperty>
      ) : null}

      <DrawerProperty label={t('risks.details.category')}>
        <DrawerProperty.Label icon={DoubleTagsIcon}>
          {t('risks.details.category')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <Categories riskId={riskId} />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={DoubleTagsIcon}>{t('risks.details.tags')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <Tags riskId={riskId} />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={UserIcon}>{t('risks.details.owner')}</DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableAvatar
            options={getAssigneeOptions(currentOrgNonDisabledUsers)}
            value={getAssigneeValue()}
            placeholder={t('risks.details.placeholder.owner')}
            onChange={(option) => {
              updateRiskHandler(
                updateRisk({
                  id: risk.id,
                  risk_input: {
                    assignee_id: option ? option.id : null,
                  },
                }),
              );
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('risks.details.reviewFrequency')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            isClearable={false}
            value={risk.review_frequency && getFrequencyOption(t, risk.review_frequency)}
            options={getFrequencyOptions(t)}
            onChange={(option) => {
              if (option) {
                updateRiskHandler(
                  updateRisk({
                    id: risk.id,
                    risk_input: {
                      review_frequency: option.value,
                    },
                  }),
                );
              }
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty>
        <DrawerProperty.Label
          icon={CalendarDaysIcon}
          tooltip={t('risks.details.tooltip.lastReviewedDate')}
        >
          {t('risks.details.lastReviewedDate')}
        </DrawerProperty.Label>
        <DrawerProperty.Content px={2} display="flex" alignItems="center" fontSize="xs">
          {risk.lastReviewedDate}
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={TagIcon}>
          {t('risks.details.treatmentPlan')}
        </DrawerProperty.Label>
        <DrawerProperty.Content>
          <EditableTag
            isClearable={false}
            value={getTreatmentOptions(t).find(({ value }) => value === risk.treatment_plan)}
            options={getTreatmentOptions(t)}
            onChange={(option) => {
              if (option) {
                updateRiskHandler(
                  updateRisk({
                    id: risk.id,
                    risk_input: {
                      treatment_plan: option.value,
                    },
                  }),
                );
              }
            }}
          />
        </DrawerProperty.Content>
      </DrawerProperty>

      <DrawerProperty isReadOnly={!canUpdateRisks}>
        <DrawerProperty.Label icon={BarsIcon}>
          {t('risks.details.treatmentDetails.label')}
        </DrawerProperty.Label>
        <DrawerProperty.Content {...HoverAskAiButton.ParentProps}>
          <Flex h="full" alignItems="center" gap={2}>
            <Box
              p={2.5}
              borderRadius="md"
              transitionProperty="common"
              transitionDuration="normal"
              _hover={canUpdateRisks ? { bg: hoverBgColor, cursor: 'pointer' } : undefined}
              _focusWithin={{
                bg: 'none',
                cursor: 'auto',
                outline: '1px solid',
                outlineColor: 'gray.200',
                _dark: {
                  outlineColor: 'rgba(255,255,255,0.16)',
                },
              }}
              w="full"
            >
              <MarkdownEditor
                defaultValue={risk.treatment_details}
                config={{ editable: !!canUpdateRisks }}
                placeholder={t('risks.details.placeholder.treatmentPlan')}
                onBlur={updateTreatmentPlan}
                contentEditable={
                  <ContentEditable
                    data-testid="treatment-details"
                    className="editor-input"
                    style={{ outline: 'none', fontSize: '12px' }}
                  />
                }
              />
            </Box>
          </Flex>
          {is_day_zero_ai_features_enabled && canUpdateRisks && (
            <HoverAskAiButton
              data-testid="autocomplete-treatment-details"
              renderRecommendation={(recommendation) => (
                <Box maxH="75vh" overflowY="auto">
                  <MarkdownEditor
                    config={{ editable: false }}
                    defaultValue={recommendation}
                    contentEditable={
                      <ContentEditable
                        data-testid="ai-treatment-details"
                        className="editor-input"
                      />
                    }
                  />
                </Box>
              )}
              onRecommendationRequest={() =>
                aiAutocompleteEntity({
                  input: { entity: 'risk-treatment-plan', entityId: risk.id },
                })
                  .unwrap()
                  .then((res) => res?.ai_autocomplete_entity?.autocomplete)
              }
              onRecommendationAccept={updateTreatmentPlan}
              acceptLabel={risk.treatment_details && t('risks.details.treatmentDetails.replace')}
            >
              {risk.treatment_details
                ? t('askAiButton.regenerateLabel')
                : t('askAiButton.generateLabel')}
            </HoverAskAiButton>
          )}
        </DrawerProperty.Content>
      </DrawerProperty>

      {fieldConfigsQuery?.data?.field_configs.map((config) => (
        <CustomFieldInput
          key={config.id}
          entityId={riskId}
          config={config}
          values={risk.field_values}
          onChange={() => {
            fieldConfigsQuery.refetch();
          }}
          isReadOnly={!canUpdateRisks}
        />
      ))}
    </Stack>
  );
};
