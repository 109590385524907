import { useStableCallback } from '@main/shared/utils';
import { useEffect, useState } from 'react';

import { NotificationHandler, NotificationItem } from './notification-handler';
import { createNotificationHandler } from './registry';
import { useLegacyNotificationHandler } from './use-legacy-notification-handler';

/**
 * Returns a suitable {@link NotificationHandler} for the given {@link NotificationItem}.
 * See {@link NotificationHandler} for all notification implementations.
 * Use {@link registerNotificationHandler()} to register new {@link NotificationHandler}.
 */
export function useNotificationHandler(
  notification: NotificationItem,
): NotificationHandler | undefined {
  const [handler, setHandler] = useState<NotificationHandler | undefined>();
  const legacyHandler = useLegacyNotificationHandler(notification, handler);

  const updateHandler = useStableCallback((newHandler?: NotificationHandler) => {
    if (newHandler !== handler) {
      setHandler(newHandler);
    }
  });

  useEffect(
    () => updateHandler(createNotificationHandler(notification)),
    [notification, updateHandler],
  );

  return handler ?? legacyHandler;
}
