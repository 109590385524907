import { datadogLogs } from '@datadog/browser-logs';
import { toError } from '@main/shared/utils';
import { errorToast } from '@main/ui';
import { ActionMeta, SingleValue } from 'chakra-react-select';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrg, getCurrentUserSelectedOrgId } from '../user/slice';
import { Option } from '../vendors/slice';
import { api as getVendorApi } from './get-vendor.generated';
import { useUpdateVendorMutation } from './update-vendor.generated';
import { useCreateVendorTierMutation } from './VendorTiers.generated';

type UpdateVendorMutationReturnType = ReturnType<ReturnType<typeof useUpdateVendorMutation>[0]>;
export function useUpdateVendorHandler() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const refetchVendorQueries = useRefetchVendorQueries();

  return useCallback(
    async (resultPromise: UpdateVendorMutationReturnType) => {
      const vendorId = resultPromise.arg.originalArgs.id;

      dispatch(
        getVendorApi.util.updateQueryData(
          'GetVendors',
          { organization_id: organization.id },
          (draft) => {
            for (const vendor of draft.vendors) {
              if (vendor.id === resultPromise.arg.originalArgs.id) {
                Object.assign(vendor, resultPromise.arg.originalArgs.vendor_input);
              }
            }
          },
        ),
      );

      try {
        await resultPromise.unwrap();
      } catch (error) {
        errorToast(t('errorMessages.updateFailed', { entity: t('entities.vendor') }));
        datadogLogs.logger.error('Vendor update failed', {}, toError(error));
      } finally {
        await refetchVendorQueries(vendorId);
      }
    },
    [dispatch, organization.id, t, refetchVendorQueries],
  );
}

export function useRefetchVendorQueries() {
  const dispatch = useAppDispatch();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  return useCallback(
    async (vendorId?: string) => {
      try {
        await Promise.all([
          vendorId &&
            dispatch(
              getVendorApi.endpoints.GetVendor.initiate(
                { vendor_id: vendorId },
                { subscribe: false, forceRefetch: false },
              ),
            ),
          dispatch(
            getVendorApi.endpoints.GetVendors.initiate(
              { organization_id: orgId },
              { subscribe: false, forceRefetch: true },
            ),
          ),
        ]);
      } catch (error) {
        datadogLogs.logger.error('Vendor refetch failed', { orgId, vendorId }, toError(error));
      }
    },
    [dispatch, orgId],
  );
}

type CreateVendorTierMutationReturnType = ReturnType<
  ReturnType<typeof useCreateVendorTierMutation>[0]
>;
type SelectHandlerProps = {
  actions: {
    create: (name: string) => CreateVendorTierMutationReturnType;
    select: (id: string) => UpdateVendorMutationReturnType;
    remove: () => UpdateVendorMutationReturnType;
  };
  vendorId?: string;
};
export function useSelectHandler() {
  const { t } = useTranslation();
  const refetchVendorQueries = useRefetchVendorQueries();

  return useCallback(
    ({ actions, vendorId }: SelectHandlerProps) => {
      return async (value: SingleValue<Option>, actionMeta: ActionMeta<Option>) => {
        try {
          switch (actionMeta.action) {
            case 'create-option': {
              await actions.create(actionMeta.option.label).unwrap();
              break;
            }
            case 'select-option': {
              if (!actionMeta.option) {
                break;
              }
              await actions.select(actionMeta.option.value).unwrap();
              break;
            }
            case 'remove-value':
            case 'pop-value': {
              await actions.remove().unwrap();
              break;
            }
          }
        } catch (error) {
          errorToast(t('errorMessages.updateFailed', { entity: t('entities.vendor') }));
          datadogLogs.logger.error(
            'Updating vendor failed.',
            { vendorId, value: value },
            toError(error),
          );
        } finally {
          await refetchVendorQueries(vendorId);
        }
      };
    },
    [refetchVendorQueries, t],
  );
}
