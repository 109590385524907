import { registerNotificationHandler } from '../notifications/registry';
import {
  MentionedInPolicyCommentNotificationHandler,
  PolicyAcknowledgementReminderNotificationHandler,
  PolicyApproverReviewNotificationHandler,
  PolicyAssignedNotificationHandler,
  PolicyCreatorReviewNotificationHandler,
  PolicyVersionAcknowledgementAssignedNotificationHandler,
  PolicyVersionApprovalAssignedNotificationHandler,
} from './notifications';

registerNotificationHandler(PolicyAssignedNotificationHandler);
registerNotificationHandler(PolicyVersionApprovalAssignedNotificationHandler);
registerNotificationHandler(PolicyVersionAcknowledgementAssignedNotificationHandler);
registerNotificationHandler(MentionedInPolicyCommentNotificationHandler);
registerNotificationHandler(PolicyCreatorReviewNotificationHandler);
registerNotificationHandler(PolicyApproverReviewNotificationHandler);
registerNotificationHandler(PolicyAcknowledgementReminderNotificationHandler);
