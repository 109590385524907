import {
  Flex,
  HStack,
  Icon,
  IconButton,
  Link,
  Spinner,
  Text,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  BellIcon,
  CheckCircleIcon,
  ClockIcon,
  LinkIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { SparklesIcon } from '@heroicons/react/24/solid';
import { Client_Questionnaire_Question_Status_Enum } from '@main/graphql/types.generated';
import { isNonNullable, stripPseudoFormatting } from '@main/shared/utils';
import {
  createColumnHelper,
  EmptyPlaceholder,
  Table,
  TableEmptyState,
  Trans,
  useDrawer,
  useStatefulCallback,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { SecondaryActionConfig } from '@main/ui/table/columns';
import { CellContext } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useTableSettings } from '../../utils/table-settings';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { ClientQuestionnaireQuestionTableFragment } from './questions-table.generated';
import { useClientQquestionStatusToOption } from './statuses';
import { useClientQquestionService } from './use-question-service';

export interface ClientQquestionsTableProps {
  questions?: ClientQuestionnaireQuestionTableFragment[];
  isLoading: boolean;
  isQueued?: boolean;
  isProcessing?: boolean;
  isFailed?: boolean;
}

export function ClientQquestionsTable({
  questions,
  isLoading,
  isQueued,
  isProcessing,
  isFailed,
}: ClientQquestionsTableProps) {
  const { t } = useTranslation();
  const drawer = useDrawer();

  const data = useMemo(() => questions?.toSorted(questionSorter), [questions]);
  const columns = useClientQquestionsTableColumns();
  const { columnVisibility, setColumnVisibility } = useTableSettings({
    storageKey: 'client-questionnaire-questions:table:column-visibility',
  });
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: 'client-questionnaire-questions:table:filter',
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({
    searchParam: 'client-questionnaire-questions:table:sort',
  });

  const tableItemName = useMemo(
    () => ({
      singular: t('entities.question').toLowerCase(),
      plural: t('entities.plural.questions').toLowerCase(),
    }),
    [t],
  );

  return (
    <Table
      minW="900px"
      columns={columns}
      data={data && !isQueued && !isProcessing && !isFailed ? data : []}
      isLoading={isLoading}
      itemName={tableItemName}
      pageSize={15}
      onRowClick={(row) => drawer.open({ entity: 'client-q-question', entityId: row.original.id })}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      sorting={sorting}
      onSortingChange={setSorting}
      renderEmptyState={({ itemName }) =>
        isQueued ? (
          <TablePendingState
            heading={t('clientQuestionnaire.tableQueued.heading')}
            subheading={t('clientQuestionnaire.tableQueued.subheading')}
          />
        ) : isProcessing ? (
          <TablePendingState
            heading={t('clientQuestionnaire.tableProcessing.heading')}
            subheading={t('clientQuestionnaire.tableProcessing.subheading')}
          />
        ) : isFailed ? (
          <TableFailedState />
        ) : (
          <TableEmptyState itemName={itemName} />
        )
      }
    />
  );
}

function questionSorter(
  a: ClientQuestionnaireQuestionTableFragment,
  b: ClientQuestionnaireQuestionTableFragment,
) {
  if (!a.metadata || !b.metadata) {
    return 0;
  }

  return a.metadata.row - b.metadata.row;
}

function TablePendingState({ heading, subheading }: { heading?: string; subheading?: string }) {
  const outerBgColor = useColorModeValue('purple.50', 'purple.200');
  const innerBgColor = useColorModeValue('purple.100', 'purple.300');
  const iconColor = useColorModeValue('purple.800', 'purple.100');

  return (
    <EmptyPlaceholder py={8}>
      <Flex
        w={12}
        h={12}
        justify="center"
        alignItems="center"
        bgColor={outerBgColor}
        rounded="full"
      >
        <Flex
          w={9}
          h={9}
          justify="center"
          alignItems="center"
          bgColor={innerBgColor}
          rounded="full"
        >
          <Spinner thickness="1.5px" color={iconColor} size="sm" />
        </Flex>
      </Flex>
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>{heading}</EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>{subheading}</EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
}

function TableFailedState() {
  const { t } = useTranslation();

  return (
    <EmptyPlaceholder py={8}>
      <EmptyPlaceholder.Icon as={XMarkIcon} />
      <EmptyPlaceholder.Content>
        <EmptyPlaceholder.Heading>
          {t('clientQuestionnaire.tableFailed.heading')}
        </EmptyPlaceholder.Heading>
        <EmptyPlaceholder.Subheading>
          <Trans
            i18nKey="clientQuestionnaire.tableFailed.subheading"
            values={{ email: 'support@complyance.com' }}
            components={{ email: <Link href="mailto:support@complyance.com" /> }}
          />
        </EmptyPlaceholder.Subheading>
      </EmptyPlaceholder.Content>
    </EmptyPlaceholder>
  );
}

function useClientQquestionsTableColumns() {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditClientQ = !!userRole.permissionMap?.write_client_q;
  const statusToOption = useClientQquestionStatusToOption();

  return useMemo(() => {
    const columnHelper = createColumnHelper<ClientQuestionnaireQuestionTableFragment>();

    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('clientQuestionnaire.tableColumns.status'),
        accessorFn: ({ status }) => statusToOption(status),
        enableSorting: true,
        enableColumnFilter: true,
        size: 145,
      }),
      columnHelper.columns.text({
        id: 'question',
        header: t('clientQuestionnaire.tableColumns.question'),
        accessorFn: ({ question }) => stripPseudoFormatting(question),
        isMultiline: true,
        enableGlobalFilter: true,
        enableSorting: true,
        size: 1,
        meta: { sizeUnit: 'fr' },
        cell: (context) => (
          <Text p={4} whiteSpace="break-spaces" lineHeight="20px">
            {context.getValue()}
          </Text>
        ),
      }),
      columnHelper.columns.text({
        id: 'answer',
        header: t('clientQuestionnaire.tableColumns.answer'),
        accessorFn: ({ answer: answers }) => {
          const answer = answers[0];

          if (!answer) {
            return '';
          }

          return `${answer.answer}${answer.answer && answer.comment ? ', ' : ''}${answer.comment}`;
        },
        isMultiline: true,
        enableGlobalFilter: true,
        enableSorting: true,
        size: 1,
        meta: { sizeUnit: 'fr' },
        cell: (context) => {
          const value = context.getValue();
          const question = context.row.original;

          if (value) {
            return (
              <HStack p={4} alignItems="flex-start">
                {question.answer[0]?.is_ai_generated && (
                  <Icon color="orange.400" fontSize="md" as={SparklesIcon} />
                )}
                <Text whiteSpace="break-spaces" lineHeight="20px">
                  {value}
                </Text>
              </HStack>
            );
          }

          return (
            <Text p={4} color="gray.400">
              {t(
                question.status === Client_Questionnaire_Question_Status_Enum.Processing
                  ? 'clientQuestionnaire.processing'
                  : 'clientQuestionnaire.noAnswer',
              )}
            </Text>
          );
        },
      }),
      columnHelper.columns.actions({
        size: 70,
        PrimaryAction: canEditClientQ ? QuestionPrimaryAction : undefined,
        secondaryActions: QuestionSecondaryActions,
      }),
    ];
  }, [canEditClientQ, statusToOption, t]);
}

function QuestionPrimaryAction(
  context: CellContext<ClientQuestionnaireQuestionTableFragment, unknown>,
) {
  const { t } = useTranslation();
  const { approveAnswer, handleUpdate, updateStatus } = useClientQquestionService();
  const question = context.row.original;

  const canApprove = useMemo(
    () =>
      ![
        Client_Questionnaire_Question_Status_Enum.Processing,
        Client_Questionnaire_Question_Status_Enum.Approved,
      ].includes(question.status),
    [question.status],
  );

  const canMarkForReview = useMemo(
    () =>
      ![
        Client_Questionnaire_Question_Status_Enum.Processing,
        Client_Questionnaire_Question_Status_Enum.ReadyForReview,
      ].includes(question.status),
    [question.status],
  );

  const onApprove = useStatefulCallback(() =>
    approveAnswer(question.id, !!question.answer[0]?.answer),
  );

  const onMarkForReview = useStatefulCallback(() =>
    handleUpdate(
      updateStatus({
        questionId: question.id,
        status: Client_Questionnaire_Question_Status_Enum.ReadyForReview,
      }),
    ),
  );

  if (canApprove) {
    return (
      <Tooltip label={t('clientQquestionDrawer.actions.approve.label')}>
        <IconButton
          minW={4}
          variant="link"
          aria-label={t('clientQquestionDrawer.actions.approve.label')}
          icon={<Icon as={CheckCircleIcon} />}
          isLoading={onApprove.isLoading}
          onClick={onApprove.callback}
        />
      </Tooltip>
    );
  }

  if (canMarkForReview) {
    return (
      <Tooltip label={t('clientQquestionDrawer.actions.markForReview.label')}>
        <IconButton
          minW={4}
          variant="link"
          aria-label={t('clientQquestionDrawer.actions.markForReview.label')}
          icon={<Icon as={BellIcon} />}
          isLoading={onMarkForReview.isLoading}
          onClick={onMarkForReview.callback}
        />
      </Tooltip>
    );
  }

  return;
}

function QuestionSecondaryActions(
  context: CellContext<ClientQuestionnaireQuestionTableFragment, unknown>,
): SecondaryActionConfig[] {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditClientQ = !!userRole.permissionMap?.write_client_q;
  const { handleUpdate, updateStatus, shareLink } = useClientQquestionService();
  const question = context.row.original;

  const canSetPending = useMemo(
    () =>
      !!canEditClientQ &&
      !!question.status &&
      ![
        Client_Questionnaire_Question_Status_Enum.Processing,
        Client_Questionnaire_Question_Status_Enum.Pending,
      ].includes(question.status),
    [canEditClientQ, question.status],
  );

  const onSetPending = useStatefulCallback(() =>
    handleUpdate(
      updateStatus({
        questionId: question.id,
        status: Client_Questionnaire_Question_Status_Enum.Pending,
      }),
    ),
  );

  return useMemo(
    () =>
      [
        {
          icon: <LinkIcon />,
          label: t('clientQquestionDrawer.actions.share.label'),
          onClick: () => shareLink(question.id),
        },
        canSetPending
          ? {
              icon: <ClockIcon />,
              label: t('clientQquestionDrawer.actions.setPending.label'),
              isLoading: onSetPending.isLoading,
              onClick: onSetPending.callback,
            }
          : undefined,
      ].filter(isNonNullable),
    [canSetPending, onSetPending, question.id, shareLink, t],
  );
}
