import { Box } from '@chakra-ui/react';
import { useGetRiskMatrixQuery } from '@main/graphql/features/RiskMatrix.generated';
import { FieldConfigFragment } from '@main/graphql/fragments/FieldConfigFragments.generated';
import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { NoPermissionPlaceholder, Table } from '@main/ui';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { CSVInput } from '../../../utils/export-csv';
import { serializeCustomFields } from '../../custom-fields/custom-field';
import { customFieldColumn } from '../../custom-fields/custom-field-column';
import { useGetFieldConfigsQuery } from '../../custom-fields/field-config';
import { useGetRisksQuery } from '../../risks/get-risk.generated';
import { getMappedRisks, OrganizationRisk } from '../../risks/slice';
import { useRiskColumnHelper } from '../../risks/table-columns';
import { useRisksTableSettings } from '../../risks/table-settings';
import {
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../../user/slice';
import { useUpdateReportHandler } from '../use-update-report-handler';
import { ReportCardBase } from './report-card-base';

export const RisksReportCard = ({
  reportId,
  onExportCSV,
}: {
  reportId: string;
  onExportCSV: (data: CSVInput[]) => void;
}) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canViewRisks = userRole.permissionMap?.read_risks;

  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  useGetRiskMatrixQuery({ organizationId }, { refetchOnMountOrArgChange: true });
  const { isLoading } = useGetRisksQuery({ organization_id: organizationId });
  const risks = useAppSelector(getMappedRisks);
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId: organizationId,
    entityName: Field_Entities_Enum.Risk,
  });
  const { columnVisibility, setColumnVisibility } = useRisksTableSettings(
    `reports:${reportId}:risks-table:column-visibility`,
  );
  const columns = useRiskTableColumns({
    fieldConfigs: fieldConfigsQuery.data?.field_configs,
  });

  const {
    filters: [columnFilters, setColumnFilters],
    sorting: [sorting, setSorting],
  } = useUpdateReportHandler({ columns });
  const [filteredRows, setFilteredRows] = useState<OrganizationRisk[]>([]);

  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.risk').toLowerCase(),
      plural: t('entities.plural.risks').toLowerCase(),
    };
  }, [t]);

  const handleExportCSV = () => {
    const fieldConfigs = fieldConfigsQuery.data?.field_configs || [];
    const filteredData = filteredRows.map((row) => {
      return {
        Name: row.name,
        Description: row.description,
        Status: t(`risks.enum.assessmentStatus.${row.assessment_status}`),
        'Last reviewed date': row.lastReviewedDate,
        'Inherent risk': row.inherentRiskLevel && t(`risks.enum.level.${row.inherentRiskLevel}`),
        'Residual risk': row.residualRiskLevel && t(`risks.enum.level.${row.residualRiskLevel}`),
        'Inherent risk impact': row?.inherent_impact?.name,
        'Inherent risk likelihood': row?.inherent_likelihood?.name,
        'Residual risk impact': row?.residual_impact?.name,
        'Residual risk likelihood': row?.residual_likelihood?.name,
        Category: row.categories.map(({ category }) => category.name),
        Tags: row.tags.map(({ tag }) => tag.name),
        Assignee: row.assignee?.displayName,
        'Review frequency': t(`vendors.frequency.${row.review_frequency}`),
        'Treatment plan': t(`risks.enum.treatment.${row.treatment_plan}`),
        'Treatment details': row.treatment_details,
        ...serializeCustomFields(fieldConfigs, row.field_values),
      };
    });
    onExportCSV(filteredData);
  };

  if (!canViewRisks) {
    return <NoPermissionPlaceholder />;
  }

  return (
    <ReportCardBase
      heading={t('entities.plural.risks')}
      subheading={t('risks.subheading')}
      onExportCSV={handleExportCSV}
    >
      <Box borderRadius="inherit" overflowX="auto">
        <Table
          minW="900px"
          isLoading={isLoading}
          entity="risk"
          data={risks}
          columns={columns}
          itemName={tableItemName}
          pageSize={15}
          columnVisibility={columnVisibility}
          onColumnVisibilityChange={setColumnVisibility}
          columnFilters={columnFilters}
          onColumnFiltersChange={setColumnFilters}
          onFilteredDataChange={setFilteredRows}
          sorting={sorting}
          onSortingChange={setSorting}
        />
      </Box>
    </ReportCardBase>
  );
};

function useRiskTableColumns({ fieldConfigs = [] }: { fieldConfigs?: FieldConfigFragment[] }) {
  const riskColumnHelper = useRiskColumnHelper();
  const { is_controls_module_enabled: isControlsModuleEnabled } =
    useAppSelector(getCurrentUserSelectedOrg);

  return useMemo(
    () => [
      riskColumnHelper.status(),
      riskColumnHelper.internalId(),
      riskColumnHelper.name({ onClickOpenDrawer: true }),
      riskColumnHelper.lastReviewedDate(),
      ...(isControlsModuleEnabled ? [riskColumnHelper.linkedControls()] : []),
      riskColumnHelper.inherentRiskImpact(),
      riskColumnHelper.description(),
      riskColumnHelper.inherentRiskLikelihood(),
      riskColumnHelper.residualRiskImpact(),
      riskColumnHelper.residualRiskLikelihood(),
      riskColumnHelper.inherentRisk(),
      riskColumnHelper.residualRisk(),
      riskColumnHelper.tags(),
      riskColumnHelper.category(),
      riskColumnHelper.treatmentPlan(),
      riskColumnHelper.owner(),
      ...fieldConfigs.map(customFieldColumn<OrganizationRisk>),
    ],
    [riskColumnHelper, isControlsModuleEnabled, fieldConfigs],
  );
}
