import { EvidenceStatus } from '@main/graphql/client-scalars';
import { TFunction } from 'i18next';

import { EVIDENCE_STATUS_COLOR } from '../shared/status-color';

// Can rely on non-integer object key order:
// https://2ality.com/2015/10/property-traversal-order-es6.html
const orderedEvidenceStatus = Object.keys(EVIDENCE_STATUS_COLOR) as EvidenceStatus[];

export function getAllEvidenceStatusOptions(t: TFunction<'translation'>) {
  return orderedEvidenceStatus.map((value) => getEvidenceStatusOption(t, value));
}

export function getEvidenceStatusOption(t: TFunction<'translation'>, value: EvidenceStatus) {
  return {
    value,
    label: t(`evidences.status.${value}`),
    colorScheme: EVIDENCE_STATUS_COLOR[value],
  };
}
