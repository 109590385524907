import type { FieldConfigFragment } from '@main/graphql/fragments/FieldConfigFragments.generated';
import type { FieldValueFragment } from '@main/graphql/fragments/FieldValueFragments.generated';
import type { createColumnHelper } from '@main/ui';

import { getSelectFieldValues } from '../field-value';

export function selectFieldColumn<T extends { field_values: FieldValueFragment[] }>(
  columnHelper: ReturnType<typeof createColumnHelper<T>>,
  customField: FieldConfigFragment,
) {
  return columnHelper.columns.tag({
    id: customField.name,
    header: customField.name,
    enableGlobalFilter: true,
    enableColumnFilter: true,
    enableSorting: true,
    isMulti: true,
    accessorFn: ({ field_values }) => {
      return getSelectFieldValues(customField, field_values).map((option) => ({
        value: option.id,
        label: option.value,
        colorScheme: 'purple',
      }));
    },
    size: 120,
  });
}
