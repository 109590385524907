import {
  Alert,
  AlertIcon,
  Box,
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Switch,
  TabPanel,
  TabPanels,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { QuestionnaireComponentKind } from '@main/dynamic-form';
import { QuestionnaireUploadFragment } from '@main/graphql/queries/GetQuestionnaire.generated';
import { QuestionnaireFormFieldConfig } from '@main/questionnaires-form';
import { toError, useStableCallback, withDefaultPrevented } from '@main/shared/utils';
import {
  AutoResizeTextarea,
  ButtonTab,
  ButtonTabList,
  ButtonTabs,
  createEnumTabsControl,
  EditableTag,
  FileInputProps,
  FileUpload,
  useDownloadStorageFile,
  useLazyFileUpload,
} from '@main/ui';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { FormEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, DeepPartial, useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { isOptionsChanged } from './conditions';
import { useFieldConfigModalAssociateFileMutation } from './field-config-modal.generated';

const fieldSchema = z.object({
  label: z.string().trim().min(1),
  validation: z.object({ required: z.boolean().optional() }).optional(),
});

const aiReviewSchema = z.object({
  hasAiReview: z.boolean().optional(),
  aiCriteria: z.string().trim().optional(),
  aiMessage: z.string().trim().optional(),
});

const fileSchema = z.object({
  hasSupportingFile: z.boolean().optional(),
  supportingUploadId: z.string().trim().optional(),
});

const textFieldSchema = z.object({}).merge(fieldSchema).merge(aiReviewSchema).merge(fileSchema);

type TextFieldSchema = z.infer<typeof textFieldSchema>;

const defaultTextValues: TextFieldSchema = {
  label: '',
  hasAiReview: false,
};

const selectFieldSchema = z
  .object({
    options: z.array(
      z.object({
        label: z.string().trim().min(1),
      }),
    ),
    isMultiOptions: z.boolean(),
  })
  .merge(fieldSchema)
  .merge(aiReviewSchema)
  .merge(fileSchema);

type SelectFieldSchema = z.infer<typeof selectFieldSchema>;

const defaultSelectValues: SelectFieldSchema = {
  label: '',
  options: [{ label: 'Yes' }, { label: 'No' }, { label: 'N/A' }],
  isMultiOptions: false,
};

const tabsControl = createEnumTabsControl([
  QuestionnaireComponentKind.Text,
  QuestionnaireComponentKind.Options,
]);

const NewFileId = '__new-file__';
const DeletedFileId = '__deleted-file__';

export const FormFieldConfigModal = ({
  questionnaireId,
  entity,
  editingFieldSchema,
  isOpen,
  affectedConditionalFields,
  uploads,
  onClose,
  onSubmit,
}: {
  questionnaireId: string;
  entity: 'question';
  editingFieldSchema?: QuestionnaireFormFieldConfig;
  isOpen: boolean;
  affectedConditionalFields?: QuestionnaireFormFieldConfig[];
  uploads: QuestionnaireUploadFragment[];
  onClose: () => void;
  onSubmit: (data: QuestionnaireFormFieldConfig) => void;
}) => {
  const { t } = useTranslation();
  const actionType = editingFieldSchema ? 'update' : 'create';
  const schema = useMemo(
    () => ({ ...editingFieldSchema, hasSupportingFile: !!editingFieldSchema?.supportingUploadId }),
    [editingFieldSchema],
  );

  const [fieldType, setFieldType] = useState(
    editingFieldSchema?.kind || QuestionnaireComponentKind.Text,
  );

  const textFieldForm = useForm<TextFieldSchema>({
    resolver: zodResolver(textFieldSchema),
    defaultValues:
      editingFieldSchema?.kind === QuestionnaireComponentKind.Text ? schema : defaultTextValues,
  });

  const selectFieldForm = useForm<SelectFieldSchema>({
    resolver: zodResolver(selectFieldSchema),
    defaultValues:
      editingFieldSchema?.kind === QuestionnaireComponentKind.Options
        ? schema
        : defaultSelectValues,
  });

  const fileForm = (fieldType === QuestionnaireComponentKind.Text
    ? textFieldForm
    : selectFieldForm) as unknown as UseFormReturn<z.infer<typeof fileSchema>>;

  const optionLabelToValue = useMemo(
    () =>
      editingFieldSchema?.kind === QuestionnaireComponentKind.Options
        ? new Map(editingFieldSchema.options?.map((option) => [option.label, option.value]))
        : new Map<string, string>(),
    [editingFieldSchema],
  );

  const getSelectField = useCallback(
    (schema: DeepPartial<SelectFieldSchema>): QuestionnaireFormFieldConfig => ({
      ...editingFieldSchema,
      kind: QuestionnaireComponentKind.Options,
      name: editingFieldSchema?.name ?? crypto.randomUUID(),
      ...schema,
      options: schema.options?.map((option) => ({
        value: optionLabelToValue.get(option?.label ?? '') ?? crypto.randomUUID(),
        label: option?.label ?? '',
      })),
    }),
    [editingFieldSchema, optionLabelToValue],
  );

  const [willResetConditions, setWillResetConditions] = useState(false);
  useEffect(
    () =>
      selectFieldForm.watch((data) =>
        setWillResetConditions(
          !!affectedConditionalFields?.length &&
            !!editingFieldSchema &&
            isOptionsChanged(getSelectField(data), editingFieldSchema),
        ),
      ).unsubscribe,
    [editingFieldSchema, getSelectField, affectedConditionalFields, selectFieldForm],
  );

  const supportingUploadId =
    fileForm.watch('supportingUploadId') ?? editingFieldSchema?.supportingUploadId;
  const supportingFile = useMemo(
    () => uploads.find((upload) => upload.id === supportingUploadId)?.file,
    [supportingUploadId, uploads],
  );

  const fileUpload = useLazyFileUpload({
    onFileDownload: useDownloadStorageFile(),
    file: supportingFile,
    onFileAdd: () =>
      fileForm.setValue('supportingUploadId', NewFileId, {
        shouldDirty: true,
        shouldValidate: true,
      }),
    onFileDelete: () =>
      fileForm.setValue('supportingUploadId', DeletedFileId, {
        shouldDirty: true,
        shouldValidate: true,
      }),
  });

  const resetForm = useStableCallback(() => {
    textFieldForm.reset(defaultTextValues);
    selectFieldForm.reset(defaultSelectValues);
    fileUpload.clear();
  });

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen, resetForm]);

  const isSubmitting =
    textFieldForm.formState.isSubmitting || selectFieldForm.formState.isSubmitting;

  const isDirty = (() => {
    switch (fieldType) {
      case QuestionnaireComponentKind.Text:
        return textFieldForm.formState.isDirty;
      case QuestionnaireComponentKind.Options:
        return selectFieldForm.formState.isDirty;
    }
  })();

  const [associateFile] = useFieldConfigModalAssociateFileMutation();

  async function uploadSupportingFile() {
    const { error, file } = await fileUpload.upload();

    if (error) {
      throw toError(error);
    }

    const { insert_questionnaire_uploads_one } = await associateFile({
      questionnaireId,
      fileId: file.id,
    }).unwrap();

    if (!insert_questionnaire_uploads_one) {
      throw new Error('Could not get upload ID from response');
    }

    return insert_questionnaire_uploads_one.id;
  }

  const hasSupportingFile = fileForm.watch('hasSupportingFile');
  const handleSubmit = withDefaultPrevented(async (event: FormEvent<HTMLFormElement>) => {
    const name = editingFieldSchema?.name ?? crypto.randomUUID();
    let uploadId = supportingUploadId;

    if (hasSupportingFile && uploadId === NewFileId) {
      try {
        uploadId = await uploadSupportingFile();

        fileForm.clearErrors('supportingUploadId');
        fileForm.setValue('supportingUploadId', uploadId, {
          shouldDirty: true,
          shouldValidate: true,
        });
      } catch (error) {
        datadogLogs.logger.error(
          'Failed uploading supporting question file',
          { questionnaireId, fieldId: name },
          toError(error),
        );
        fileForm.setError('supportingUploadId', {
          type: 'uploadFailed',
          message: t('errorMessages.uploadFailed', { entity: t('entities.file') }),
        });
        return;
      }
    } else if (!hasSupportingFile || uploadId === DeletedFileId) {
      uploadId = undefined;
    }

    switch (fieldType) {
      case QuestionnaireComponentKind.Text: {
        textFieldForm.handleSubmit((data) => {
          delete data.hasSupportingFile;

          onSubmit({
            ...editingFieldSchema,
            name,
            kind: fieldType,
            ...data,
            supportingUploadId: uploadId,
          } as QuestionnaireFormFieldConfig);
        })(event);
        break;
      }

      case QuestionnaireComponentKind.Options: {
        selectFieldForm.handleSubmit((data) => {
          delete data.hasSupportingFile;

          onSubmit({
            ...getSelectField(data),
            supportingUploadId: uploadId,
          });
        })(event);
        break;
      }
    }
  });

  return (
    <Modal size="xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t(`form.entities.${entity}.${actionType}`)}</ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <ButtonTabs {...tabsControl(fieldType, setFieldType)}>
              <FormControl>
                <FormLabel>{t('form.fields.type.label')}:</FormLabel>
                <ButtonTabList isDisabled={actionType === 'update'}>
                  <ButtonTab>{t('form.fields.type.values.text')}</ButtonTab>
                  <ButtonTab>{t('form.fields.type.values.select')}</ButtonTab>
                </ButtonTabList>
              </FormControl>

              <TabPanels>
                <TabPanel px={0}>
                  <Stack spacing={4}>
                    <FormControl isInvalid={!!textFieldForm.formState.errors.label}>
                      <FormLabel>{t('form.fields.name.label')}:</FormLabel>
                      <AutoResizeTextarea
                        placeholder={t('form.fields.name.placeholder', { entity })}
                        {...textFieldForm.register('label')}
                      />
                      <FormErrorMessage>
                        {textFieldForm.formState.errors.label?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <SharedFields
                      form={textFieldForm as unknown as UseFormReturn<z.infer<typeof fieldSchema>>}
                    />

                    <AIReviewFields
                      entity={entity}
                      aiReviewForm={
                        textFieldForm as unknown as UseFormReturn<z.infer<typeof aiReviewSchema>>
                      }
                    />

                    <SupportingFileFields
                      form={textFieldForm as unknown as UseFormReturn<z.infer<typeof fileSchema>>}
                      fileUpload={fileUpload.props}
                    />
                  </Stack>
                </TabPanel>

                <TabPanel px={0}>
                  <Stack spacing={4}>
                    <FormControl isInvalid={!!selectFieldForm.formState.errors.label}>
                      <FormLabel>{t('form.fields.name.label')}:</FormLabel>
                      <AutoResizeTextarea
                        placeholder={t('form.fields.name.placeholder', { entity })}
                        {...selectFieldForm.register('label')}
                      />
                      <FormErrorMessage>
                        {selectFieldForm.formState.errors.label?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!selectFieldForm.formState.errors.options}>
                      <FormLabel>{t('form.fields.options.label')}:</FormLabel>
                      <Controller
                        name="options"
                        control={selectFieldForm.control}
                        render={({ field }) => (
                          <EditableTag
                            isMulti
                            value={field.value.map((option) => ({
                              value: option.label,
                              colorScheme: 'purple',
                            }))}
                            getNewOptionData={(value) => ({ value, colorScheme: 'purple' })}
                            onChange={(values) => {
                              field.onChange(values.map(({ value }) => ({ label: value })));
                            }}
                          />
                        )}
                      />
                      <FormErrorMessage>
                        {selectFieldForm.formState.errors.options?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <FormControl isInvalid={!!selectFieldForm.formState.errors.isMultiOptions}>
                      <FormLabel>{t('form.fields.isMulti.label')}:</FormLabel>
                      <Switch {...selectFieldForm.register('isMultiOptions')} />
                      <FormErrorMessage>
                        {selectFieldForm.formState.errors.isMultiOptions?.message}
                      </FormErrorMessage>
                    </FormControl>

                    <SharedFields
                      form={
                        selectFieldForm as unknown as UseFormReturn<z.infer<typeof fieldSchema>>
                      }
                    />

                    <AIReviewFields
                      entity={entity}
                      aiReviewForm={
                        selectFieldForm as unknown as UseFormReturn<z.infer<typeof aiReviewSchema>>
                      }
                    />

                    <SupportingFileFields
                      form={selectFieldForm as unknown as UseFormReturn<z.infer<typeof fileSchema>>}
                      fileUpload={fileUpload.props}
                    />
                  </Stack>
                </TabPanel>
              </TabPanels>
            </ButtonTabs>
            {willResetConditions && (
              <Alert status="warning" variant="left-accent" mb={4}>
                <AlertIcon />
                <Box>
                  {t('questionnaires.questions.editQuestionModal.affectedQuestions', {
                    count: affectedConditionalFields?.length ?? 0,
                  })}
                  <List>
                    {affectedConditionalFields?.map((field) => (
                      <ListItem key={field.name}>- {field.label}</ListItem>
                    ))}
                  </List>
                </Box>
              </Alert>
            )}
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button isDisabled={isSubmitting} onClick={onClose}>
                {t('buttons.cancel')}
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={isSubmitting}
                isDisabled={!isDirty}
              >
                {t(`buttons.${actionType}`)}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
};

const SharedFields = ({ form }: { form: UseFormReturn<z.infer<typeof fieldSchema>> }) => {
  const { t } = useTranslation();

  return (
    <FormControl>
      <FormLabel>{t('form.fields.required.label')}:</FormLabel>
      <Switch {...form.register('validation.required')} />
    </FormControl>
  );
};

const AIReviewFields = ({
  aiReviewForm,
  entity,
}: {
  aiReviewForm: UseFormReturn<z.infer<typeof aiReviewSchema>>;
  entity: string;
}) => {
  const { t } = useTranslation();
  const isAIReviewFeatureEnabled = useFeatureFlagEnabled('vendor-questionnaire-ai-criteria-form');

  const isAIReviewSwitchEnabled = aiReviewForm.watch('hasAiReview');

  if (!isAIReviewFeatureEnabled) {
    return null;
  }
  return (
    <>
      <FormControl>
        <FormLabel>{t('form.fields.aiReviewToggle')}:</FormLabel>
        <Switch {...aiReviewForm.register('hasAiReview')} />
      </FormControl>

      {isAIReviewSwitchEnabled && (
        <>
          <FormControl>
            <FormLabel>{t('form.fields.aiCriteria.label')}:</FormLabel>
            <AutoResizeTextarea
              minRows={3}
              placeholder={t('form.fields.aiCriteria.placeholder', { entity })}
              {...aiReviewForm.register('aiCriteria')}
            />
          </FormControl>

          <FormControl>
            <FormLabel>{t('form.fields.aiMessage.label')}:</FormLabel>
            <AutoResizeTextarea
              minRows={3}
              placeholder={t('form.fields.aiMessage.placeholder', { entity })}
              {...aiReviewForm.register('aiMessage')}
            />
          </FormControl>
        </>
      )}
    </>
  );
};

function SupportingFileFields({
  form,
  fileUpload,
}: {
  form: UseFormReturn<z.infer<typeof fileSchema>>;
  fileUpload: FileInputProps;
}) {
  const { t } = useTranslation();

  return (
    <>
      <FormControl>
        <FormLabel>{t('form.fields.supportingFileSwitch.label')}:</FormLabel>
        <Switch {...form.register('hasSupportingFile')} />
      </FormControl>

      {form.watch('hasSupportingFile') && (
        <FormControl isInvalid={!!form.formState.errors.supportingUploadId}>
          <FormLabel>{t('form.fields.supportingFile.label')}:</FormLabel>
          <FileUpload {...fileUpload}>
            <FileUpload.Dropzone />
          </FileUpload>
          <FormErrorMessage>{form.formState.errors.supportingUploadId?.message}</FormErrorMessage>
        </FormControl>
      )}
    </>
  );
}
