import {
  Button,
  ButtonGroup,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Stack,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { zodResolver } from '@hookform/resolvers/zod';
import { toError } from '@main/shared/utils';
import {
  AutoResizeTextarea,
  EditableAvatar,
  errorToast,
  FileUpload,
  successToast,
  useAddDayjs,
  useDownloadStorageFile,
  useLazyFileUpload,
  useOnModalClosed,
} from '@main/ui';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { z } from 'zod';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { dateToMidnightUTC } from '../../utils/date';
import { getAssigneeOptions } from '../shared/get-assignee-options';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentUserDetails,
  getCurrentUserSelectedOrgId,
} from '../user/slice';
import { useCreateClientQuestionnaireModalMutation } from './create-modal.generated';

const formSchema = z.object({
  name: z.string().trim().min(1),
  company: z.string().trim().min(1),
  owner: z.object({
    id: z.string().trim().min(1),
    displayName: z.string().trim().min(1),
  }),
  dueDate: z.string().min(1).transform(dateToMidnightUTC),
  description: z.string().trim().optional(),
  fileId: z.string().trim().min(1),
});

export function CreateClientQuestionnaireModal(modal: Omit<ModalProps, 'children'>) {
  const { t } = useTranslation();
  const organization_id = useAppSelector(getCurrentUserSelectedOrgId);
  const currentUser = useAppSelector(getCurrentUserDetails);
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const [createClientQuestionnaire] = useCreateClientQuestionnaireModalMutation();
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      owner: currentUser?.user
        ? { id: currentUser.user.id, displayName: currentUser.user.displayName }
        : undefined,
      dueDate: useAddDayjs(7, 'days', 'YYYY-MM-DD'),
    },
  });
  const fileUpload = useLazyFileUpload({
    onFileDownload: useDownloadStorageFile(),
    onFileAdd: () =>
      form.setValue('fileId', '__new-file__', { shouldDirty: true, shouldValidate: true }),
    onFileDelete: () => form.setValue('fileId', '', { shouldDirty: true, shouldValidate: true }),
  });

  const handleSubmit = form.handleSubmit(async (data) => {
    try {
      form.clearErrors('fileId');
      const { file, error } = await fileUpload.upload();

      if (error) {
        form.setError('fileId', {
          type: 'uploadFailed',
          message: t('errorMessages.uploadFailed', { entity: t('entities.file') }),
        });
        throw toError(error);
      }

      if (!file.name?.match(/\.(csv|xlsx)$/)) {
        form.setError('fileId', {
          type: 'invalidFileType',
          message: t('errorMessages.unsupportedFileType', { types: '.csv, .xlsx' }),
        });
        throw new Error(`Invalid file type ${file.name}`);
      }

      await createClientQuestionnaire({
        input: {
          organization_id,
          name: data.name,
          company: data.company,
          owner_id: data.owner.id,
          due_date: data.dueDate,
          description: data.description,
          file_id: file.id,
        },
      }).unwrap();

      successToast(
        t('successMessages.createSucceeded', { entity: t('entities.clientQuestionnaire') }),
      );
      modal.onClose();
    } catch (error) {
      datadogLogs.logger.error('Failed to create Client Questionnaire', data, toError(error));
      errorToast(t('errorMessages.createFailed', { entity: t('entities.clientQuestionnaire') }));
    }
  });

  useOnModalClosed(() => {
    form.reset();
    fileUpload.clear();
  }, modal);

  return (
    <Modal size="xl" isCentered motionPreset="slideInBottom" {...modal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader fontSize="lg" fontWeight="bold">
          {t('createQuestionnaireModal.heading')}
        </ModalHeader>
        <ModalCloseButton />

        <form onSubmit={handleSubmit}>
          <ModalBody>
            <Stack spacing="6">
              <FormControl isInvalid={!!form.formState.errors.name}>
                <FormLabel>{t('createQuestionnaireModal.form.name.label')}:</FormLabel>
                <Input
                  {...form.register('name')}
                  placeholder={t('createQuestionnaireModal.form.name.placeholder')}
                />
                <FormErrorMessage>
                  {t('clientQuestionnaires.formErrorMessages.name')}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!form.formState.errors.company}>
                <FormLabel>{t('createQuestionnaireModal.form.company.label')}:</FormLabel>
                <Input
                  {...form.register('company')}
                  placeholder={t('createQuestionnaireModal.form.company.placeholder')}
                />
                <FormErrorMessage>
                  {t('clientQuestionnaires.formErrorMessages.company')}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!form.formState.errors.owner}>
                <FormLabel>{t('createQuestionnaireModal.form.owner.label')}:</FormLabel>
                <Controller
                  name="owner"
                  control={form.control}
                  render={({ field }) => (
                    <EditableAvatar
                      isClearable={false}
                      options={getAssigneeOptions(currentOrgNonDisabledUsers)}
                      value={field.value}
                      placeholder={t('createQuestionnaireModal.form.owner.placeholder')}
                      onChange={field.onChange}
                    />
                  )}
                />
                <FormErrorMessage>
                  {t('clientQuestionnaires.formErrorMessages.owner')}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!form.formState.errors.dueDate}>
                <FormLabel>{t('createQuestionnaireModal.form.dueDate.label')}:</FormLabel>
                <Input
                  {...form.register('dueDate')}
                  type="date"
                  placeholder={t('createQuestionnaireModal.form.dueDate.placeholder')}
                />
                <FormErrorMessage>
                  {t('clientQuestionnaires.formErrorMessages.dueDate')}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!form.formState.errors.description}>
                <FormLabel>{t('createQuestionnaireModal.form.description.label')}:</FormLabel>
                <AutoResizeTextarea
                  {...form.register('description')}
                  placeholder={t('createQuestionnaireModal.form.description.placeholder')}
                  minRows={3}
                />
                <FormErrorMessage>{form.formState.errors.description?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!form.formState.errors.fileId}>
                <FormLabel>{t('createQuestionnaireModal.form.file.label')}:</FormLabel>
                <FileUpload {...fileUpload.props}>
                  <FileUpload.Dropzone
                    topLabelText={t('createQuestionnaireModal.form.file.placeholderHeading')}
                    bottomLabelText={t('createQuestionnaireModal.form.file.placeholderSubheading')}
                    inputProps={{
                      accept:
                        'text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    }}
                    constraintText={t('createQuestionnaireModal.form.file.constraintText')}
                  />
                </FileUpload>
                <FormErrorMessage>{form.formState.errors.fileId?.message}</FormErrorMessage>
              </FormControl>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button isDisabled={form.formState.isSubmitting} onClick={modal.onClose}>
                {t('buttons.cancel')}
              </Button>
              <Button
                type="submit"
                colorScheme="blue"
                isLoading={form.formState.isSubmitting}
                isDisabled={!form.formState.isDirty}
              >
                {t('buttons.submit')}
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </form>
      </ModalContent>
    </Modal>
  );
}
