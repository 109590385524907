import {
  Box,
  Card,
  CardBody,
  CardHeader,
  FormErrorMessage,
  FormLabel,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  DynamicFieldWrapper,
  QuestionnaireComponentKind,
  QuestionnaireFormComponentRegistry,
} from '@main/dynamic-form';
import { Vendor_Questionnaire_Form_Answer_Statuses_Enum } from '@main/graphql/types.generated';
import { useEffectOnce } from '@main/shared/utils';
import { FieldErrorMessage } from '@main/ui';
import { SetStateAction, useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { QuestionnaireFieldComment } from './field-comment';
import { QuestionnaireFieldFiles } from './field-files';
import { QuestionnaireFieldHeader } from './field-header';
import { QuestionnaireFieldSupportingFile } from './field-supporting-file';
import { QuestionnnaireFieldWrapperProps, QuestionnnaireFormMode } from './form-types';
import { createNewAnswerFor, getQuestionFilter, questionnaireErrors } from './utils';

export const QuestionnnaireFieldWrapper: DynamicFieldWrapper<
  QuestionnaireFormComponentRegistry,
  QuestionnnaireFieldWrapperProps
> = (props) => {
  const { t } = useTranslation();
  const { getFieldState } = useFormContext();

  const config = props.config;

  const answer = useMemo(
    () =>
      props.form.answers?.find((answer) => answer.field_name === config.name) ??
      createNewAnswerFor(config),
    [config, props.form.answers],
  );
  const supportingFile = useMemo(
    () =>
      config.supportingUploadId
        ? props.form.uploads?.find((upload) => upload.id === config.supportingUploadId)
        : undefined,
    [config.supportingUploadId, props.form.uploads],
  );
  const hasComment = !!answer.comment;
  const hasFiles = !!answer.form_uploads?.length;
  const isDisabled = answer.status === Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed;
  const isText = config.kind === QuestionnaireComponentKind.Text;
  const shouldStyleText = isText && isDisabled;
  const isRequired = !!config.validation?.required;
  const fieldState = getFieldState(config.name);

  const [wasShowingComment, setWasShowingComment] = useState(false);
  const [wasShowingFiles, setWasShowingFiles] = useState(false);
  const [isShowComment, _setShowComment] = useState(wasShowingComment || hasComment);
  const [isShowFiles, _setShowFiles] = useState(wasShowingFiles || hasFiles);

  const filtersFilter = useMemo(() => getQuestionFilter(props.filters), [props.filters]);
  const isHidden = useMemo(() => filtersFilter(config, answer), [filtersFilter, config, answer]);

  const setShowComment = (setter: SetStateAction<boolean>) => {
    _setShowComment(setter);
    setWasShowingComment(setter);
  };

  const setShowFiles = (setter: SetStateAction<boolean>) => {
    _setShowFiles(setter);
    setWasShowingFiles(setter);
  };

  useEffectOnce(() => {
    if (hasComment) {
      setWasShowingComment(true);
    }

    if (hasFiles) {
      setWasShowingFiles(true);
    }
  });

  useEffect(() => {
    if (answer.status !== Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed) {
      return;
    }

    setShowComment(hasComment);
    setShowFiles(hasFiles);
  }, [answer.status, hasComment, hasFiles]);

  if (isHidden) {
    return null;
  }

  return (
    <Card variant="outline" mb={6} p={4} data-testid={`question:${config.label}`}>
      <CardHeader p={0}>
        <Stack>
          <QuestionnaireFieldHeader
            {...props}
            config={config}
            answer={answer}
            setShowComment={setShowComment}
            setShowFiles={setShowFiles}
          />
          <Box>
            <FormLabel m={0} fontWeight="medium" color="gray.600" _disabled={{ opacity: 1 }}>
              {config.label}
              {isRequired && ' *'}
            </FormLabel>
          </Box>
        </Stack>
      </CardHeader>
      <CardBody p={0} pt={4} borderRadius="inherit">
        <Stack>
          {supportingFile && (
            <Box>
              <QuestionnaireFieldSupportingFile supportingFile={supportingFile} />
            </Box>
          )}
          <Box>
            <Text fontWeight="semibold" fontSize="xs">
              {t('questionnaires.form.answerLabel')}
            </Text>
          </Box>
          <Box
            fontSize={shouldStyleText ? 'sm' : undefined}
            color={shouldStyleText ? 'gray.600' : undefined}
            data-testid={`answer:${config.label}`}
          >
            {props.children}
          </Box>
          {!isDisabled && (
            <FormErrorMessage>
              <FieldErrorMessage error={fieldState.error} errorToMessage={questionnaireErrors} />
            </FormErrorMessage>
          )}
          {isShowComment && (
            <Box>
              <QuestionnaireFieldComment {...props} config={config} answer={answer} />
            </Box>
          )}
          {isShowFiles && (
            <Box>
              <QuestionnaireFieldFiles {...props} config={config} answer={answer} />
            </Box>
          )}
        </Stack>
      </CardBody>
    </Card>
  );
};

QuestionnnaireFieldWrapper.resolveFormControl = (
  config,
  formControl,
  props: QuestionnnaireFieldWrapperProps,
) => {
  const answer = props.form.answers?.find((answer) => answer.field_name === config.name);
  const isAnswerMode = props.form.mode === QuestionnnaireFormMode.Answer;

  if (!isAnswerMode || !answer) {
    return formControl;
  }

  return {
    ...formControl,
    isDisabled: answer.status === Vendor_Questionnaire_Form_Answer_Statuses_Enum.Completed,
  };
};
