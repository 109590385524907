import { useMultipleFilesUpload } from '@nhost/react';
import { useState } from 'react';

import { FileInputProps } from './file-input';
import { getFileUploadResult, getMultipleFilesUploadResult, UploadedFile } from './shared';

export function useLazyFileUpload<T extends UploadedFile>({
  file,
  onFileAdd,
  onFileDelete,
  onFileDownload,
}: {
  file?: T;
  onFileAdd?: (file: File) => void;
  onFileDelete?: () => void;
  onFileDownload?: (fileId: string) => void;
}) {
  const fileUpload = useLazyMultipleFilesUpload({
    files: file ? [file] : undefined,
    onFilesAdd: ([file]) => {
      if (file) {
        onFileAdd?.(file);
      }
    },
    onFileDelete,
    onFileDownload,
  });

  const upload = async () => {
    const uploadResult = await fileUpload.upload();
    return getFileUploadResult(uploadResult);
  };

  return {
    ...fileUpload,
    props: {
      ...fileUpload.props,
      isMulti: false,
    },
    upload,
  };
}

export function useLazyMultipleFilesUpload<T extends UploadedFile>({
  files,
  onFilesAdd,
  onFilesReupload,
  onFileDelete,
  onFileDownload,
}: {
  files?: T[];
  onFilesAdd?: (files: File[]) => void;
  onFilesReupload?: (files: File[], existingFileId: string) => void;
  onFileDelete?: (fileName: string) => void;
  onFileDownload?: (fileId: string) => void;
}) {
  const filesUpload = useMultipleFilesUpload();
  const [deletedFiles, setDeletedFiles] = useState<T[]>([]);

  function getNewFiles(files: File[]) {
    return files.filter(
      (selectedFile) =>
        !filesUpload.files.find((stagedFile) => {
          const file = stagedFile.getSnapshot()?.context.file;
          return file?.name === selectedFile.name && file?.size === selectedFile.size;
        }),
    );
  }

  const props: FileInputProps<T> = {
    isMulti: true,
    uploadedFiles: files?.filter(
      (uploadedFile) => !deletedFiles.find((file) => file.id === uploadedFile.id),
    ),
    onUploadedFileDownload: onFileDownload,
    onUploadedFileDelete: async (file) => {
      setDeletedFiles((prevFileIds) => [...prevFileIds, file]);
      onFileDelete?.(file.id);
    },
    stagedFiles: filesUpload.files,
    onStagedFilesAdd: (files) => {
      const newFiles = getNewFiles(files);

      if (newFiles.length) {
        filesUpload.add({ files: newFiles });
        onFilesAdd?.(newFiles);
      }
    },
    onUploadedFileReupload: onFilesReupload
      ? async (file, files) => {
          const newFiles = getNewFiles(files);

          if (newFiles.length) {
            filesUpload.add({ files: newFiles });
            onFilesReupload(newFiles, file.id);
          }
        }
      : undefined,
    onStagedFileDelete: (fileId, file) => {
      filesUpload.files.find((file) => file.id === fileId)?.send('DESTROY');
      onFileDelete?.(file.name);
    },
  };

  const upload = async () => {
    if (!filesUpload.files.length) {
      return {
        errors: [],
        files: [],
      };
    }

    const uploadResult = await filesUpload.upload();
    return getMultipleFilesUploadResult(uploadResult);
  };

  const clearDeletedFiles = () => setDeletedFiles([]);

  return {
    props,
    deletedFiles,
    upload,
    clear: filesUpload.clear,
    clearDeletedFiles,
  };
}
