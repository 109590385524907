import '@main/polyfills/promise-with-resolvers';

import { BulkDownloaderLogger } from '../logger';
import { MergedAbortController } from '../merge-abort-controller';
import { assertNotAborted, awaitUntil } from '../promise-utils';
import { MessagableTarget, MessageStreamPortalSink, MessageStreamPortalSource } from './message';
import {
  MessageChannelStreamPortalSink,
  MessageChannelStreamPortalSource,
} from './message-channel';
import { StreamPortalSink } from './sink';
import { StreamPortalSource } from './source';

interface DynamicStreamPortalData {
  type: 'stream-portal-transfer-support';
  supportsStreamTransfer: boolean;
}

export class DynamicStreamPortalFactory {
  private static supportsStreamTransfer?: boolean;

  static createSource<TExtra>(
    target: EventTarget & MessagableTarget,
    logger?: BulkDownloaderLogger,
  ): StreamPortalSource<TExtra> {
    if (this.hasStreamTransferSupport(target)) {
      logger?.debug('StreamPortalFactory Creating MessageStreamPortalSource');
      return new MessageStreamPortalSource(target, logger);
    }

    logger?.debug('StreamPortalFactory Creating MessageChannelStreamPortalSource');
    return new MessageChannelStreamPortalSource(target, logger);
  }

  static createSink<TExtra>(
    target: EventTarget,
    logger?: BulkDownloaderLogger,
  ): StreamPortalSink<TExtra> {
    return new DynamicStreamPortalSink(target, logger);
  }

  protected static hasStreamTransferSupport(target: MessagableTarget) {
    if (this.supportsStreamTransfer === undefined) {
      const worker = new Worker('data:application/javascript,');
      try {
        const stream = new ReadableStream();
        worker.postMessage(null, [stream]);
        this.supportsStreamTransfer = true;
      } catch {
        this.supportsStreamTransfer = false;
      } finally {
        worker.terminate();
      }
    }

    target.postMessage({
      type: 'stream-portal-transfer-support',
      supportsStreamTransfer: this.supportsStreamTransfer,
    } as DynamicStreamPortalData);

    return this.supportsStreamTransfer;
  }
}

export class MessageChannelStreamPortalFactory {
  static createSource<TExtra>(
    target: EventTarget & MessagableTarget,
    logger?: BulkDownloaderLogger,
  ): StreamPortalSource<TExtra> {
    logger?.debug('MessageChannelStreamPortalFactory Creating MessageChannelStreamPortalSource');
    return new MessageChannelStreamPortalSource(target, logger);
  }

  static createSink<TExtra>(
    target: EventTarget,
    logger?: BulkDownloaderLogger,
  ): StreamPortalSink<TExtra> {
    logger?.debug('MessageChannelStreamPortalFactory Creating MessageChannelStreamPortalSink');
    return new MessageChannelStreamPortalSink(target, logger);
  }
}

export const StreamPortalFactory = MessageChannelStreamPortalFactory;

export class DynamicStreamPortalSink<TExtra> implements StreamPortalSink<TExtra> {
  private dynamicPortal = Promise.withResolvers<StreamPortalSink<TExtra>>();
  protected readonly disposeController = new AbortController();
  protected readonly messagesController = new MergedAbortController(this.disposeController.signal);

  constructor(
    protected readonly target: EventTarget,
    protected readonly logger: BulkDownloaderLogger = console,
  ) {
    this.target.addEventListener('message', this.handleMessage.bind(this), {
      signal: this.messagesController.signal,
    });
  }

  onStream(cb: (stream: ReadableStream, extra: TExtra) => void) {
    assertNotAborted(this.disposeController.signal, new Error('StreamPortalSink is disposed'));

    const abortController = new MergedAbortController(this.disposeController.signal);

    void this.dynamicOnStream(cb, abortController.signal);

    return () => abortController.abort();
  }

  dispose(): void {
    this.disposeController.abort();
    void this.dynamicPortal.promise.then((portal) => portal.dispose?.());
  }

  protected handleMessage(event: Event) {
    if ('data' in event === false) {
      return;
    }

    const data = event.data as DynamicStreamPortalData;

    if (data.type !== 'stream-portal-transfer-support') {
      return;
    }

    this.initDynamicPortal(data);
  }

  protected initDynamicPortal(data: DynamicStreamPortalData) {
    this.logger.debug('DynamicStreamPortalSink Creating dynamic portal sink', {
      supportsStreamTransfer: data.supportsStreamTransfer,
    });

    const dynamicPortal = data.supportsStreamTransfer
      ? new MessageStreamPortalSink<TExtra>(this.target, this.logger)
      : new MessageChannelStreamPortalSink<TExtra>(this.target, this.logger);

    this.messagesController.abort();
    this.dynamicPortal.resolve(dynamicPortal);
  }

  protected async dynamicOnStream(
    cb: (stream: ReadableStream, extra: TExtra) => void,
    signal: AbortSignal,
  ) {
    try {
      const dynamicPortal = await awaitUntil(this.dynamicPortal.promise, signal);
      const cancel = dynamicPortal.onStream(cb);
      signal.addEventListener('abort', cancel, { once: true });
    } catch {
      /* empty */
    }
  }
}
