import {
  Notification_Delivery_Types_Enum,
  Notification_Types_Enum,
} from '@main/graphql/types.generated';

export enum Module_Order_Enum {
  'general',
  'control',
  'evidence',
  'risk',
  'vendor',
  'task',
  'policy',
  'trust',
}

export type Module = keyof typeof Module_Order_Enum;

type InputConfig<T extends string, TId extends string> = {
  id: TId;
  module: Module;
  order: number;
  groupedTypes: T[];
};

type OutputConfig<TId extends string> = {
  id: TId;
  module: Module;
  order: number;
  type: Notification_Types_Enum;
};

function notificationFactory<T extends string, TId extends string>(
  props: InputConfig<T, TId>,
): Record<T, OutputConfig<TId>> {
  return props.groupedTypes.reduce(
    (acc, type) => ({
      ...acc,
      [type]: {
        id: props.id,
        module: props.module,
        order: props.order,
        type,
      },
    }),
    {} as Record<T, OutputConfig<TId>>,
  );
}

const notifications = {
  ...notificationFactory({
    id: 'vendor_due_status' as const,
    module: 'vendor',
    order: 1,
    groupedTypes: [Notification_Types_Enum.VendorStatusChangedToExpiring],
  }),
  ...notificationFactory({
    id: 'vq_submission' as const,
    module: 'vendor',
    order: 2,
    groupedTypes: [Notification_Types_Enum.VendorQuestionnaireSubmission],
  }),
  ...notificationFactory({
    id: 'questionnaire_status_overdue' as const,
    module: 'vendor',
    order: 3,
    groupedTypes: [Notification_Types_Enum.QuestionnaireStatusOverdue],
  }),
  ...notificationFactory({
    id: 'cq_due_status' as const,
    module: 'trust',
    order: 1,
    groupedTypes: [
      Notification_Types_Enum.ClientQuestionnaireStatusExpiring,
      Notification_Types_Enum.ClientQuestionnaireStatusOverdue,
    ],
  }),
  ...notificationFactory({
    id: 'cq_processing_result' as const,
    module: 'trust',
    order: 2,
    groupedTypes: [
      Notification_Types_Enum.ClientQuestionnaireProcessingDone,
      Notification_Types_Enum.ClientQuestionnaireProcessingFailed,
    ],
  }),
  ...notificationFactory({
    id: 'control_due_status' as const,
    module: 'control',
    order: 1,
    groupedTypes: [Notification_Types_Enum.ControlStatusChangedToNotValid],
  }),
  ...notificationFactory({
    id: 'ownership_changed' as const,
    module: 'general',
    order: 1,
    groupedTypes: [Notification_Types_Enum.EntityOwnershipChanged],
  }),
  ...notificationFactory({
    id: 'mentioned_in_comment' as const,
    module: 'general',
    order: 2,
    groupedTypes: [Notification_Types_Enum.MentionedInComment],
  }),
  ...notificationFactory({
    id: 'evidence_access_granted' as const,
    module: 'evidence',
    order: 1,
    groupedTypes: [Notification_Types_Enum.EvidenceAccessGranted],
  }),
  ...notificationFactory({
    id: 'overdue_tasks_assigned_to_you' as const,
    module: 'task',
    order: 1,
    groupedTypes: [Notification_Types_Enum.OverdueTasksAssignedToYou],
  }),
  ...notificationFactory({
    id: 'overdue_tasks_created_by_you' as const,
    module: 'task',
    order: 1,
    groupedTypes: [Notification_Types_Enum.OverdueTasksCreatedByYou],
  }),
  ...notificationFactory({
    id: 'policy_acknowledgement_reminder' as const,
    module: 'policy',
    order: 1,
    groupedTypes: [Notification_Types_Enum.PolicyAcknowledgementReminder],
  }),
  ...notificationFactory({
    id: 'policy_approval_reminder' as const,
    module: 'policy',
    order: 1,
    groupedTypes: [Notification_Types_Enum.PolicyApprovalReminder],
  }),
  ...notificationFactory({
    id: 'risk_due_status' as const,
    module: 'risk',
    order: 1,
    groupedTypes: [Notification_Types_Enum.RiskStatusChangedToExpiring],
  }),
} satisfies Record<Notification_Types_Enum, OutputConfig<string>>;

type DeliveryTypeProps = {
  [K in Notification_Delivery_Types_Enum]: {
    value: boolean;
    isNotAvailable?: boolean;
    valueWhenNotAvailable?: boolean;
  };
};

/**
 * This type is the same as OutputConfig but with inferred string literals for @id property
 */
type ConfigurationProps = (typeof notifications)[Notification_Types_Enum];

export type Notification = ConfigurationProps & DeliveryTypeProps;

export const getMappedNotifications = () => {
  return Object.values(Notification_Types_Enum).reduce(
    (acc, type) => {
      const notification: Notification = {
        ...notifications[type],
        platform: {
          value: true,
        },
        email: {
          value: true,
        },
      };

      /* Following notification types have no email delivery */
      if (
        type === Notification_Types_Enum.OverdueTasksAssignedToYou ||
        type === Notification_Types_Enum.OverdueTasksCreatedByYou
      ) {
        notification.email.isNotAvailable = true;
        notification.email.valueWhenNotAvailable = false;
      }

      return { ...acc, [type]: notification };
    },
    {} as Record<Notification_Types_Enum, Notification>,
  );
};
