import { isNonNullable } from '@main/shared/utils';
import { Table, TableEmptyState, TableItem } from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { GetTasksQuery } from './get-task.generated';
import { TaskLinkedEntity } from './slice';
import { useTaskColumnHelper } from './table-columns';
import { useUnlinkTask } from './unlink-task';

type Tasks = GetTasksQuery['tasks'];

const useTaskTableColumns = (
  linkedEntity: TaskLinkedEntity,
  entityId: string,
  newTaskId: string | undefined,
) => {
  const columnHelper = useTaskColumnHelper();
  const unlinkTask = useUnlinkTask();

  return useMemo(() => {
    return [
      columnHelper.status(),
      columnHelper.name({ isEditable: true, newTaskId }),
      columnHelper.dueDate({ isEditable: true }),
      columnHelper.owner({ isEditable: true }),
      columnHelper.actions({
        onUnlink: (task) => unlinkTask({ taskId: task.id, linkedEntity, entityId }),
      }),
    ].filter(isNonNullable);
  }, [columnHelper, newTaskId, unlinkTask, linkedEntity, entityId]);
};

export const LinkedEntityTasksTable = ({
  tasks,
  entityId,
  linkedEntity,
  newTaskId,
  isTasksLoading,
}: {
  tasks: Tasks;
  entityId: string;
  linkedEntity: TaskLinkedEntity;
  newTaskId: string | undefined;
  isTasksLoading: boolean;
}) => {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreateTask = userRole.permissionMap?.write_tasks;
  const columns = useTaskTableColumns(linkedEntity, entityId, newTaskId);

  const tableItemName = useMemo((): TableItem => {
    return {
      singular: t('entities.task').toLowerCase(),
      plural: t('entities.plural.tasks').toLowerCase(),
    };
  }, [t]);

  return (
    <Table
      minW="400px"
      entity="task"
      data={tasks ?? []}
      isLoading={isTasksLoading}
      columns={columns}
      itemName={tableItemName}
      renderEmptyState={(props) => (
        <TableEmptyState
          {...props}
          subHeading={
            canCreateTask && t('table.clickButtonBelowToAdd', { item: tableItemName.singular })
          }
        />
      )}
    />
  );
};
