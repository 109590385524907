import { Text, useColorModeValue } from '@chakra-ui/react';
import {
  Client_Questionnaire_Status_Enum,
  Notification_Types_Enum,
} from '@main/graphql/types.generated';
import { maxTextLength } from '@main/shared/utils';
import { Trans } from '@main/ui';

import { router } from '../../router';
import {
  AssignedNotificationAdapter,
  AssignedNotificationData,
  AssignedNotificationHandler,
} from '../notifications/assigned-notification-handler';
import {
  NotificationContentComponent,
  NotificationContentProps,
  NotificationHandler,
  NotificationItem,
} from '../notifications/notification-handler';

export interface ClientQuestionnaireNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    client_questionnaire: NonNullable<NotificationItem['content']['client_questionnaire']>;
  };
}

function isCQNotification(
  notification: NotificationItem,
): notification is ClientQuestionnaireNotification {
  return !!notification.content.client_questionnaire;
}

export class ClientQprocessingDoneNotificationHandler
  implements NotificationHandler<ClientQuestionnaireNotification>
{
  static readonly type: Notification_Types_Enum =
    Notification_Types_Enum.ClientQuestionnaireProcessingDone;
  static canHandle = isCQNotification;

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<ClientQuestionnaireNotification, this> {
    return ClientQprocessingDoneNotificationContent;
  }

  redirect(notification: ClientQuestionnaireNotification) {
    router.navigate({
      to: '/client-questionnaires/$questionnaireId',
      params: { questionnaireId: notification.content.client_questionnaire.id },
    });
  }
}

export class ClientQprocessingFailedNotificationHandler extends ClientQprocessingDoneNotificationHandler {
  static override readonly type = Notification_Types_Enum.ClientQuestionnaireProcessingFailed;

  override getContentComponent() {
    return ClientQprocessingFailedNotificationContent;
  }
}

export class ClientQassignedNotificationHandler
  implements AssignedNotificationAdapter<ClientQuestionnaireNotification>
{
  static readonly type = Notification_Types_Enum.EntityOwnershipChanged;
  static canHandle = isCQNotification;

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: ClientQuestionnaireNotification): AssignedNotificationData {
    return {
      internalId: notification.content.client_questionnaire.internal_id,
      entityName: notification.content.client_questionnaire.name,
      content: 'notification.clientQuestionnaire.assigned',
    };
  }

  redirect(notification: ClientQuestionnaireNotification) {
    router.navigate({
      to: '/client-questionnaires/$questionnaireId',
      params: { questionnaireId: notification.content.client_questionnaire.id },
    });
  }
}

export interface MentionedInCleintQquestionCommentNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_comment: NonNullable<NotificationItem['content']['notifications_comment']> & {
      client_questionnaire_question: NonNullable<
        NonNullable<
          NotificationItem['content']['notifications_comment']
        >['client_questionnaire_question']
      >;
    };
  };
}

export class MentionedInCleintQquestionCommentNotificationHandler
  implements AssignedNotificationAdapter<MentionedInCleintQquestionCommentNotification>
{
  static readonly type = Notification_Types_Enum.MentionedInComment;

  static canHandle(
    notification: NotificationItem,
  ): notification is MentionedInCleintQquestionCommentNotification {
    return !!notification.content.notifications_comment?.client_questionnaire_question;
  }

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(
    notification: MentionedInCleintQquestionCommentNotification,
  ): AssignedNotificationData {
    return {
      internalId:
        notification.content.notifications_comment.client_questionnaire_question
          .client_questionnaire.internal_id,
      entityName:
        notification.content.notifications_comment.client_questionnaire_question
          .client_questionnaire.name,
      content: 'notification.clientQuestionnaire.mentioned',
    };
  }

  redirect(notification: MentionedInCleintQquestionCommentNotification) {
    router.navigate({
      to: '/client-questionnaires/$questionnaireId',
      params: {
        questionnaireId:
          notification.content.notifications_comment.client_questionnaire_question
            .client_questionnaire.id,
      },
      search: {
        drawerEntity: 'client-q-question',
        drawerEntityId: notification.content.notifications_comment.client_questionnaire_question.id,
        activeTab: 'comments',
      },
    });
  }
}

function ClientQprocessingDoneNotificationContent({
  notification,
}: NotificationContentProps<ClientQuestionnaireNotification>) {
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      <Trans
        i18nKey="notification.clientQuestionnaire.processingDone"
        values={{
          name: maxTextLength(notification.content.client_questionnaire.name ?? ''),
        }}
      />
    </Text>
  );
}

function ClientQprocessingFailedNotificationContent({
  notification,
}: NotificationContentProps<ClientQuestionnaireNotification>) {
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      <Trans
        i18nKey="notification.clientQuestionnaire.processingFailed"
        values={{
          name: maxTextLength(notification.content.client_questionnaire?.name ?? ''),
        }}
      />
    </Text>
  );
}

export interface ClientQuestionnaireDueNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    client_questionnaire: NonNullable<NotificationItem['content']['client_questionnaire']>;
    params: {
      clientQuestionnaireStatus:
        | Client_Questionnaire_Status_Enum.Expiring
        | Client_Questionnaire_Status_Enum.Overdue;
    };
  };
}

export class ClientQuestionnaireExpiringNotificationHandler
  implements NotificationHandler<ClientQuestionnaireDueNotification>
{
  static readonly type = [
    Notification_Types_Enum.ClientQuestionnaireStatusExpiring,
    Notification_Types_Enum.ClientQuestionnaireStatusOverdue,
  ];

  static canHandle(
    notification: NotificationItem,
  ): notification is ClientQuestionnaireDueNotification {
    return (
      !!notification.content.client_questionnaire &&
      'clientQuestionnaireStatus' in notification.content.params
    );
  }

  static create() {
    return new this();
  }

  getContentComponent(): NotificationContentComponent<ClientQuestionnaireDueNotification, this> {
    return ClientQExpiringNotificationContent;
  }

  redirect(notification: ClientQuestionnaireDueNotification) {
    router.navigate({
      to: '/client-questionnaires/$questionnaireId',
      params: {
        questionnaireId: notification.content.client_questionnaire.id,
      },
    });
  }
}

function ClientQExpiringNotificationContent({
  notification,
}: NotificationContentProps<ClientQuestionnaireDueNotification>) {
  const cqStatus = notification.content.params.clientQuestionnaireStatus;
  const notificationTextColor = useColorModeValue('gray.600', 'gray.300');

  return (
    <Text fontSize="sm" textColor={notificationTextColor}>
      <Trans
        i18nKey={`notification.clientQuestionnaire.${cqStatus}`}
        values={{
          questionnaireName: notification.content.client_questionnaire.name,
          companyName: notification.content.client_questionnaire.company,
        }}
      />
    </Text>
  );
}
