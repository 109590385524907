import { ParseKeys } from 'i18next';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getAllMembersOfOrgsCurrentUserJoined } from '../user/slice';
import { AssigneeAndMentionContent } from './notification-content';
import {
  NotificationContentComponent,
  NotificationContentProps,
  NotificationHandler,
  NotificationItem,
} from './notification-handler';

export interface AssignedNotificationAdapter<T extends NotificationItem> {
  getAssignedData(notification: T): AssignedNotificationData;
  redirect(notification: T): void;
}

export interface AssignedNotificationData {
  internalId: string | undefined;
  entityName: string | undefined;
  content: ParseKeys;
}

export class AssignedNotificationHandler<T extends NotificationItem>
  implements NotificationHandler<T>
{
  constructor(public readonly adapter: AssignedNotificationAdapter<T>) {}

  getContentComponent(): NotificationContentComponent<T, this> {
    return AssignedNotificationContent;
  }

  redirect(notification: T) {
    this.adapter.redirect(notification);
  }
}

export function AssignedNotificationContent<T extends NotificationItem>({
  notification,
  handler,
}: NotificationContentProps<T, AssignedNotificationHandler<T>>) {
  const { t } = useTranslation();
  const users = useAppSelector(getAllMembersOfOrgsCurrentUserJoined);

  const data = handler.adapter.getAssignedData(notification);
  const assignorId =
    'assignorId' in notification.content.params
      ? notification.content.params.assignorId
      : undefined;
  const assignor = users[assignorId ?? '']?.displayName ?? '';

  return (
    <AssigneeAndMentionContent
      assignor={assignor}
      internalId={data.internalId ?? ''}
      entityName={data.entityName ?? ''}
      content={t(data.content)}
    />
  );
}
