import {
  Box,
  Center,
  Divider,
  Flex,
  Heading,
  Spinner,
  Text,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline';
import { Document_Type_Enum } from '@main/graphql/types.generated';
import { toError } from '@main/shared/utils';
import {
  EmptyPlaceholder,
  errorToast,
  FileUpload,
  FileUploadResult,
  useAlertDialog,
  useDownloadStorageFile,
  useEagerFileUpload,
} from '@main/ui';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../hooks/redux-toolkit-hooks';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../../../user/slice';
import {
  useDocumentsPageDeleteDocumentMutation,
  useDocumentsPageGetDocumentsQuery,
  useDocumentsPageUploadGoldenQuestionnaireMutation,
} from './documents-page.generated';

export function DocumentsPage() {
  const { t } = useTranslation();
  const subheadingColor = useColorModeValue('gray.600', 'gray.400');
  const dialog = useAlertDialog();
  const organizationId = useAppSelector(getCurrentUserSelectedOrgId);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canManageDocuments = userRole.permissionMap.manage_documents;
  const { data, isLoading, refetch } = useDocumentsPageGetDocumentsQuery({ organizationId });
  const [uploadGoldenQuestionnaire] = useDocumentsPageUploadGoldenQuestionnaireMutation();
  const [deleteDocument] = useDocumentsPageDeleteDocumentMutation();

  const goldenQuestionnaire = data?.documents.find(
    (doc) => doc.type === Document_Type_Enum.GoldenQuestionnaire,
  );

  async function createGoldenQuestionnaire({ file, error }: FileUploadResult) {
    if (!canManageDocuments) {
      return;
    }

    if (error) {
      errorToast(t('errorMessages.uploadFailed', { entity: t('entities.questionnaire') }));
      datadogLogs.logger.error(
        'Failed uploading golden questionnaire file',
        { organizationId },
        toError(error),
      );
      return;
    }

    if (!file.name?.match(/\.(csv|xlsx?)$/)) {
      errorToast(t('settings.organization.documents.invalidFileType'));
      datadogLogs.logger.error('Invalid golden questionnaire file type', {
        organizationId,
        file,
      });
      return;
    }

    try {
      await uploadGoldenQuestionnaire({
        organizationId,
        fileId: file.id,
        name: file.name,
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.createFailed', { entity: t('entities.goldenQuestionnaire') }));
      datadogLogs.logger.error(
        'Failed creating golden questionnaire',
        { organizationId, file },
        toError(error),
      );
    }

    await refetch().unwrap();
  }

  async function deleteGoldenQuestionnaire() {
    if (!canManageDocuments || !goldenQuestionnaire) {
      return;
    }

    try {
      await dialog.openDialog({
        dialogHeader: t('settings.organization.documents.deleteAlert.header'),
        dialogContent: t('settings.organization.documents.deleteAlert.content'),
        confirmAction: { children: t('buttons.delete') },
      });
    } catch {
      return;
    }

    try {
      await deleteDocument({ documentId: goldenQuestionnaire.id }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.deleteFailed', { entity: t('entities.goldenQuestionnaire') }));
      datadogLogs.logger.error(
        'Failed deleting golden questionnaire',
        { organizationId, documentId: goldenQuestionnaire.id },
        toError(error),
      );
    }

    await refetch().unwrap();
  }

  const goldenQuestionnaireUpload = useEagerFileUpload({
    onFileDownload: useDownloadStorageFile(),
    file: goldenQuestionnaire?.file,
    onFileUpload: canManageDocuments ? createGoldenQuestionnaire : undefined,
    onFileReupload: canManageDocuments ? createGoldenQuestionnaire : undefined,
    onFileDelete: canManageDocuments ? deleteGoldenQuestionnaire : undefined,
  });

  if (isLoading) {
    return (
      <Center height="100vh">
        <Spinner thickness="4px" size="xl" color="purple.500" />
      </Center>
    );
  }

  return (
    <>
      <Flex py="6" px={[4, 4, 8]} justifyContent="space-between" alignItems="center">
        <Box>
          <Heading as="h2" size="md">
            {t('settings.organization.documents.heading')}
          </Heading>
          <Text fontSize="14" color={subheadingColor}>
            {t('settings.organization.documents.subheading')}
          </Text>
        </Box>
      </Flex>
      <Divider orientation="horizontal" />
      <VStack alignItems="flex-start" py="6" px={[4, 4, 8]}>
        <Text fontSize="xs" fontWeight="semibold">
          {t('settings.organization.documents.golden-questionnaire')}
        </Text>
        <FileUpload {...goldenQuestionnaireUpload}>
          {canManageDocuments ? (
            <FileUpload.Dropzone
              topLabelText={t('settings.organization.documents.dropzone.heading')}
              bottomLabelText={t('settings.organization.documents.dropzone.subheading')}
              inputProps={{
                accept:
                  'text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              }}
              constraintText={t('settings.organization.documents.dropzone.constraintText')}
            />
          ) : (
            !goldenQuestionnaire && (
              <EmptyPlaceholder>
                <EmptyPlaceholder.Icon as={DocumentDuplicateIcon} />
                <EmptyPlaceholder.Content>
                  <EmptyPlaceholder.Heading>
                    {t('settings.organization.documents.emptyState.golden-questionnaire')}
                  </EmptyPlaceholder.Heading>
                </EmptyPlaceholder.Content>
              </EmptyPlaceholder>
            )
          )}
        </FileUpload>
      </VStack>
    </>
  );
}
