import { Notification_Types_Enum } from '@main/graphql/types.generated';

import { router } from '../../router';
import {
  AssignedNotificationAdapter,
  AssignedNotificationData,
  AssignedNotificationHandler,
} from '../notifications/assigned-notification-handler';
import { NotificationItem } from '../notifications/notification-handler';

export interface EvidenceNotification extends NotificationItem {
  content: NotificationItem['content'] & {
    notifications_evidence: NonNullable<NotificationItem['content']['notifications_evidence']>;
  };
}

function isNotificationEvidence(
  notification: NotificationItem,
): notification is EvidenceNotification {
  return !!notification.content.notifications_evidence;
}

export class EvidenceAssignedNotificationHandler
  implements AssignedNotificationAdapter<EvidenceNotification>
{
  static readonly type = Notification_Types_Enum.EntityOwnershipChanged;
  static readonly canHandle = isNotificationEvidence;

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: EvidenceNotification): AssignedNotificationData {
    return {
      internalId: notification.content.notifications_evidence.internal_id,
      entityName: notification.content.notifications_evidence.name,
      content: 'notification.evidence.assigned',
    };
  }

  redirect(notification: EvidenceNotification) {
    router.navigate({
      to: '/evidence',
      search: {
        drawerEntity: 'evidence',
        drawerEntityId: notification.content.notifications_evidence.id,
      },
    });
  }
}

export class EvidenceAccessGrantedNotificationHandler
  implements AssignedNotificationAdapter<EvidenceNotification>
{
  static readonly type = Notification_Types_Enum.EvidenceAccessGranted;
  static readonly canHandle = isNotificationEvidence;

  static create() {
    return new AssignedNotificationHandler(new this());
  }

  getAssignedData(notification: EvidenceNotification): AssignedNotificationData {
    return {
      internalId: notification.content.notifications_evidence.internal_id,
      entityName: notification.content.notifications_evidence.name,
      content: 'notification.evidence.accessGranted',
    };
  }

  redirect(notification: EvidenceNotification) {
    router.navigate({
      to: '/evidence',
      search: {
        drawerEntity: 'evidence',
        drawerEntityId: notification.content.notifications_evidence.id,
      },
    });
  }
}
