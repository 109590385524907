export class MergedAbortController extends AbortController {
  protected readonly innerAbort = this.abort.bind(this);

  constructor(...signals: (AbortSignal | undefined)[]) {
    super();

    for (const signal of signals) {
      signal && this.merge(signal);
    }
  }

  /** Merge {@link AbortSignal} into this controller. */
  merge(signal: AbortSignal): void {
    if (this.signal.aborted) {
      throw new Error('MergedAbortController is already aborted');
    }

    if (signal.aborted) {
      return this.abort(signal.reason);
    }

    signal.addEventListener('abort', this.innerAbort, { signal: this.signal });
  }
}

export function mergeSignals<T extends (AbortSignal | undefined)[]>(...signals: [...T]): T[number] {
  if (signals.length === 1 && signals[0]) {
    return signals[0];
  }

  return new MergedAbortController(...signals).signal;
}
