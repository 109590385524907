import { Button, Card, Flex, Icon, Tooltip, useDisclosure } from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import { Policy_Version_Statuses_Enum } from '@main/graphql/types.generated';
import { createColumnHelper, Table, TableEmptyState } from '@main/ui';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { COMPLYANCE_USER } from '../../utils/constants';
import { POLICY_ACKNOWLEDGEMENT_STATUS_COLOR } from '../shared/status-color';
import { getCurrentOrgUsersMap, getCurrentUserSelectedOrgRole } from '../user/slice';
import { AcknowledgementUsersModal } from './acknowledgement-users-modal';
import { useGetPolicyQuery } from './manage-policies.generated';
import { SendAcknowledgementModal } from './send-acknowledgement-modal';
import { getAcknowledgementRatio, getMappedPolicy } from './slice';
import { usePreferredPolicyVersion } from './use-preferred-policy-version';

export const AcknowledgementsTab = ({ policyId }: { policyId: string }) => {
  const { t } = useTranslation();
  const sendAckModal = useDisclosure();
  const ackUserModal = useDisclosure();
  const { isLoading } = useGetPolicyQuery({
    id: policyId,
  });
  const policy = useAppSelector((state) => getMappedPolicy(state, policyId));
  const { permissionMap } = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditPolicy = permissionMap.write_policies;
  const currentVersion = usePreferredPolicyVersion();

  const columns = useAcknowledgementTableColumns();
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.acknowledgement').toLowerCase(),
      plural: t('entities.plural.acknowledgements').toLowerCase(),
    };
  }, [t]);

  const [selectedAckId, setSelectedAck] = useState('');
  const openAcknowledgement = useCallback(
    (row: { original: PolicyAcknowledgement }) => {
      setSelectedAck(row.original.id);
      ackUserModal.onOpen();
    },
    [ackUserModal],
  );

  return (
    <Flex direction="column" justify="center" gap="8">
      <Card variant="table-styles">
        <Table
          minW="400px"
          data={policy.acknowledgements}
          isLoading={isLoading}
          columns={columns}
          itemName={tableItemName}
          onRowClick={openAcknowledgement}
          renderEmptyState={(props) => (
            <TableEmptyState
              {...props}
              subHeading={
                canEditPolicy && t('table.clickButtonBelowToAdd', { item: tableItemName.singular })
              }
            />
          )}
        />
      </Card>

      {canEditPolicy && (
        <Tooltip
          label={t('policies.acknowledgement.tooltip')}
          isDisabled={currentVersion?.status === Policy_Version_Statuses_Enum.Approved}
          placement="top"
          hasArrow
          rounded={4}
        >
          <Button
            variant="outline"
            fontSize="sm"
            leftIcon={<Icon strokeWidth={2} as={PlusIcon} />}
            alignSelf="end"
            onClick={sendAckModal.onOpen}
            isDisabled={currentVersion?.status !== Policy_Version_Statuses_Enum.Approved}
          >
            {t('policies.acknowledgement.requestNewAcknowledgement')}
          </Button>
        </Tooltip>
      )}

      <SendAcknowledgementModal isOpen={sendAckModal.isOpen} onClose={sendAckModal.onClose} />
      <AcknowledgementUsersModal
        isOpen={ackUserModal.isOpen}
        onClose={ackUserModal.onClose}
        policyId={policyId}
        acknowledgementId={selectedAckId}
      />
    </Flex>
  );
};

export type PolicyAcknowledgement = ReturnType<typeof getMappedPolicy>['acknowledgements'][number];

function useAcknowledgementTableColumns() {
  const { t } = useTranslation();
  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);

  return useMemo(() => {
    const columnHelper = createColumnHelper<PolicyAcknowledgement>();
    return [
      columnHelper.columns.tag({
        id: 'versionId',
        header: t('policies.acknowledgement.tableColumns.version'),
        accessorFn: ({ version }) => {
          return {
            value: version.formattedVersionId,
            colorScheme: 'purple',
          };
        },
        size: 80,
      }),

      columnHelper.columns.status({
        id: 'acknowledgementStatus',
        header: t('policies.acknowledgement.tableColumns.acknowledgementStatus'),
        accessorFn: ({ status }) => {
          return {
            value: t(`policies.acknowledgement.status.${status}`),
            colorScheme: POLICY_ACKNOWLEDGEMENT_STATUS_COLOR[status],
          };
        },
        size: 160,
      }),

      columnHelper.columns.date({
        id: 'dueDate',
        header: t('policies.acknowledgement.tableColumns.dueDate'),
        accessorFn: ({ due_date }) => due_date,
        size: 200,
      }),

      columnHelper.columns.text({
        id: 'acknowledgersCount',
        header: t('policies.acknowledgement.tableColumns.acknowledgers'),
        accessorFn: ({ policy_acknowledgement_users }) =>
          getAcknowledgementRatio(policy_acknowledgement_users),
      }),

      columnHelper.columns.avatar({
        id: 'createdBy',
        header: t('policies.acknowledgement.tableColumns.createdBy'),
        accessorFn: ({ created_by }) => {
          if (!created_by) {
            return COMPLYANCE_USER;
          }

          const user = currentOrgUsers[created_by];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 100,
      }),
    ];
  }, [currentOrgUsers, t]);
}
