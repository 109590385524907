import { Field_Entities_Enum } from '@main/graphql/types.generated';
import {
  Table,
  TableEmptyState,
  useTableFiltersQuery,
  useTableSearchQuery,
  useTableSortQuery,
} from '@main/ui';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useGetPollingInterval } from '../../hooks/use-get-polling-interval';
import { customFieldColumn } from '../custom-fields/custom-field-column';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { getCurrentUserSelectedOrgId, getCurrentUserSelectedOrgRole } from '../user/slice';
import { POLICY_TABLE_FILTER_PARAM_NAME, POLICY_TABLE_SORT_PARAM_NAME } from './constants';
import { useGetPoliciesQuery } from './manage-policies.generated';
import { getMappedOrgPolicies } from './slice';
import { usePolicyColumnHelper } from './table-columns';
import { usePoliciesTableSettings } from './table-settings';

type PolicyEntry = ReturnType<typeof getMappedOrgPolicies>[number];

export const PoliciesTable = () => {
  const { t } = useTranslation();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);
  const pollingInterval = useGetPollingInterval(5000);
  const { isLoading } = useGetPoliciesQuery(
    {
      orgId,
    },
    {
      pollingInterval,
    },
  );
  const policies = useAppSelector(getMappedOrgPolicies);
  const columns = usePoliciesTableColumns();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canCreatePolicy = userRole.permissionMap?.write_policies;
  const { columnVisibility, setColumnVisibility } = usePoliciesTableSettings(
    'policies:table:column-visibility',
  );
  const [columnFilters, setColumnFilters] = useTableFiltersQuery({
    columns,
    searchParam: POLICY_TABLE_FILTER_PARAM_NAME,
  });
  const [globalFilter, setGlobalFilter] = useTableSearchQuery({ searchParam: 'search' });
  const [sorting, setSorting] = useTableSortQuery({ searchParam: POLICY_TABLE_SORT_PARAM_NAME });
  const tableItemName = useMemo(() => {
    return {
      singular: t('entities.policy').toLowerCase(),
      plural: t('entities.plural.policies').toLowerCase(),
    };
  }, [t]);

  return (
    <Table
      minW="900px"
      entity="policy"
      data={policies}
      isLoading={isLoading}
      columns={columns}
      itemName={tableItemName}
      pageSize={15}
      columnFilters={columnFilters}
      onColumnFiltersChange={setColumnFilters}
      globalFilter={globalFilter}
      onGlobalFilterChange={setGlobalFilter}
      sorting={sorting}
      onSortingChange={setSorting}
      columnVisibility={columnVisibility}
      onColumnVisibilityChange={setColumnVisibility}
      renderEmptyState={(props) => (
        <TableEmptyState
          {...props}
          subHeading={
            canCreatePolicy && t('table.clickButtonAboveToAdd', { item: tableItemName.singular })
          }
        />
      )}
    />
  );
};

function usePoliciesTableColumns() {
  const columnHelper = usePolicyColumnHelper();
  const orgId = useAppSelector(getCurrentUserSelectedOrgId);

  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId,
    entityName: Field_Entities_Enum.Policy,
  });
  const fieldConfigs = fieldConfigsQuery.data?.field_configs;

  return useMemo(() => {
    return [
      columnHelper.status(),
      columnHelper.type(),
      columnHelper.name(),
      columnHelper.description(),
      columnHelper.internalId(),
      columnHelper.currentValidityStart(),
      columnHelper.approversCount(),
      columnHelper.acknowledgementsCount(),
      columnHelper.acknowledgementStatus(),
      columnHelper.owner({ isEditable: true }),
      columnHelper.approvers(),
      ...(fieldConfigs || []).map(customFieldColumn<PolicyEntry>),
      columnHelper.actions(),
    ];
  }, [columnHelper, fieldConfigs]);
}
