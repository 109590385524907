import { BulkDownloaderLogger } from '../logger';
import { MessagableTarget, StreamPortalFactory } from '../stream-portal';
import { ServiceWorkerFileStreamer } from './file-streamer';
import { ZipperServiceWorkerFileStreamer } from './file-streamer-zipper';
import { ServiceWorkerStreamSink } from './stream-sink';

export interface CreateSWStreamSinkOptions {
  fileName: string;
  url: string;
  type: WorkerType;
  scope: string;
  streamer?: ServiceWorkerFileStreamer;
  logger?: BulkDownloaderLogger;
}

export async function createSWStreamSink(options: CreateSWStreamSinkOptions) {
  options.logger?.debug('Creating SW downloader', {
    url: options.url,
    scope: options.scope,
    type: options.type,
  });

  const swReg =
    (await navigator.serviceWorker.getRegistration(options.scope)) ??
    (await navigator.serviceWorker.register(options.url, {
      type: options.type,
      scope: options.scope,
    }));
  let sw = swReg.active;

  if (!sw) {
    const swRegTmp = swReg.installing ?? swReg.waiting;

    if (!swRegTmp) {
      throw new Error('Unable to get service worker registration');
    }

    sw = await new Promise<ServiceWorker>((res) => {
      swRegTmp.addEventListener('statechange', () => {
        if (swRegTmp.state === 'activated') {
          res(swRegTmp);
        }
      });
    });
  }

  return new ServiceWorkerStreamSink(
    sw,
    StreamPortalFactory.createSource(
      new SWMessegableTarget(sw, navigator.serviceWorker),
      options.logger,
    ),
    options.streamer ?? new ZipperServiceWorkerFileStreamer(options.fileName, options.logger),
    options.logger,
  );
}

class SWMessegableTarget implements EventTarget, MessagableTarget {
  constructor(
    protected readonly sw: ServiceWorker,
    protected readonly swContainer: ServiceWorkerContainer,
  ) {}

  postMessage(message: unknown, transfer?: Transferable[] | StructuredSerializeOptions): void {
    this.sw.postMessage(message, transfer as never);
  }

  addEventListener(
    type: string,
    callback: EventListener,
    options?: boolean | AddEventListenerOptions,
  ): void {
    this.swContainer.addEventListener(type, callback, options);
  }

  dispatchEvent(event: Event): boolean {
    return this.swContainer.dispatchEvent(event);
  }

  removeEventListener(
    type: string,
    callback: EventListener,
    options?: boolean | EventListenerOptions,
  ): void {
    this.swContainer.removeEventListener(type, callback, options);
  }
}
