import { datadogLogs } from '@datadog/browser-logs';

import {
  NotificationHandler,
  NotificationHandlerFactory,
  NotificationItem,
} from './notification-handler';

const factoryBuckets = Object.create(null) as Record<string, NotificationHandlerFactory[]>;

/**
 * Register a {@link NotificationHandler} factory for a specific notification type(s).
 */
export function registerNotificationHandler(factory: NotificationHandlerFactory) {
  const types = Array.isArray(factory.type) ? factory.type : [factory.type];

  types.forEach((type) => {
    if (!factoryBuckets[type]) {
      factoryBuckets[type] = [];
    }

    factoryBuckets[type].push(factory);
  });
}

const handlersCache = new WeakMap<NotificationHandlerFactory, WeakRef<NotificationHandler>>();

/**
 * Create a suitable {@link NotificationHandler} for the given {@link NotificationItem}.
 * If no handler is found, an error is thrown in development mode or an error is logged in production.
 * {@link NotificationHandler}s are cached to avoid creating multiple instances for the same factory.
 */
export function createNotificationHandler(
  notification: NotificationItem,
): NotificationHandler | undefined {
  const factory = factoryBuckets[notification.content.type]?.find((factory) =>
    factory.canHandle(notification),
  );

  if (!factory) {
    if (import.meta.env.DEV) {
      throw new Error(`No handler for notification: ${JSON.stringify(notification)}`);
    } else {
      datadogLogs.logger.error('No handler for notification', { notification });
    }

    return;
  }

  const handler = handlersCache.get(factory)?.deref() ?? factory.create();
  handlersCache.set(factory, new WeakRef(handler));

  return handler;
}
