import { useDrawer } from '@main/ui';
import useTimeout from 'ahooks/es/useTimeout';
import { useEffect, useRef, useState } from 'react';

/**
 * This hook is used to get the polling interval based on the drawer state
 * to be used in the entites management tables(Controls, Vendors, Risks etc.), instead of polling all the time.
 * */
export function useGetPollingInterval(interval: number) {
  const drawer = useDrawer();
  const [isPolling, setIsPolling] = useState(false);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const prevOpenRef = useRef(isDrawerOpen);

  useEffect(
    () =>
      drawer.onDrawer((data) => {
        if (data?.entityId) {
          setIsDrawerOpen(true);
        } else {
          setIsDrawerOpen(false);
        }
      }),
    [drawer],
  );

  const clearTimeoutRef = useTimeout(
    () => {
      setIsPolling(false);
    },
    isPolling ? 20000 : undefined,
  );

  useEffect(() => {
    // Check if the drawer just changed from open => closed
    if (prevOpenRef.current === true && isDrawerOpen === false) {
      setIsPolling(true);
    }

    // Check if the drawer just changed from closed => open
    else if (prevOpenRef.current === false && isDrawerOpen === true) {
      setIsPolling(false);
      clearTimeoutRef();
    }

    prevOpenRef.current = isDrawerOpen;
  }, [clearTimeoutRef, isDrawerOpen]);

  return isPolling ? interval : 0;
}
