import { Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { createContext, useContext } from 'react';

import { StatusTag } from '../status-tag';

const FindingContext = createContext<{ isIgnored: boolean } | null>(null);

export function useFindingContext() {
  const context = useContext(FindingContext);

  if (!context) {
    throw new Error('components must be wrapped in <FindingContext />');
  }

  return context;
}

export const FindingCard = ({
  children,
  isIgnored = false,
}: {
  children: React.ReactNode;
  isIgnored: boolean;
}) => {
  const findingBorderColor = useColorModeValue('gray.200', 'gray.600');

  return (
    <FindingContext.Provider
      value={{
        isIgnored,
      }}
    >
      <Flex
        p={4}
        rounded="md"
        border="1px"
        borderColor={findingBorderColor}
        opacity={isIgnored ? 0.7 : 1}
        position="relative"
        gap={4}
        direction="column"
        width="full"
      >
        {children}
      </Flex>
    </FindingContext.Provider>
  );
};

const CardHeader = ({
  heading,
  subheading,
  children,
}: {
  heading: React.ReactNode;
  subheading?: React.ReactNode;
  children: React.ReactNode;
}) => {
  const subheaderColor = useColorModeValue('gray.400', 'gray.600');
  const { isIgnored } = useFindingContext();

  return (
    <Flex gap={3}>
      <Text fontSize="xs" fontWeight="semibold">
        {heading}
      </Text>
      {subheading && (
        <Text fontSize="xs" color={subheaderColor}>
          {subheading}
        </Text>
      )}
      {isIgnored && (
        <StatusTag colorScheme="gray" size="sm">
          Ignored
        </StatusTag>
      )}
      {children}
    </Flex>
  );
};

const CardBody = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex gap={3} direction="column" width="full">
      {children}
    </Flex>
  );
};

const CardActions = ({ children }: { children: React.ReactNode }) => {
  return (
    <Flex gap={2} position="absolute" right={4} top={4}>
      {children}
    </Flex>
  );
};

FindingCard.Header = CardHeader;
FindingCard.Body = CardBody;
FindingCard.Actions = CardActions;
