/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type ToggleControlIgnoreFindingMutationVariables = Types.Exact<{
  findingId: Types.Scalars['uuid']['input'];
  ignored_at?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
}>;

export type ToggleControlIgnoreFindingMutation = {
  __typename?: 'mutation_root';
  update_control_findings_by_pk?: { __typename?: 'control_findings'; id: string };
};

export type ToggleVendorIgnoreFindingMutationVariables = Types.Exact<{
  findingId: Types.Scalars['uuid']['input'];
  ignored_at?: Types.InputMaybe<Types.Scalars['timestamptz']['input']>;
}>;

export type ToggleVendorIgnoreFindingMutation = {
  __typename?: 'mutation_root';
  update_vendor_findings_by_pk?: { __typename?: 'vendor_findings'; id: string };
};

export type DeleteManualFindingMutationVariables = Types.Exact<{
  findingId: Types.Scalars['uuid']['input'];
}>;

export type DeleteManualFindingMutation = {
  __typename?: 'mutation_root';
  delete_manual_findings_by_pk?: { __typename?: 'manual_findings'; id: string };
};

export const ToggleControlIgnoreFindingDocument = `
    mutation ToggleControlIgnoreFinding($findingId: uuid!, $ignored_at: timestamptz) {
  update_control_findings_by_pk(
    pk_columns: {id: $findingId}
    _set: {ignored_at: $ignored_at}
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<
    ToggleControlIgnoreFindingMutation,
    ToggleControlIgnoreFindingMutationVariables
  >;
export const ToggleVendorIgnoreFindingDocument = `
    mutation ToggleVendorIgnoreFinding($findingId: uuid!, $ignored_at: timestamptz) {
  update_vendor_findings_by_pk(
    pk_columns: {id: $findingId}
    _set: {ignored_at: $ignored_at}
  ) {
    id
  }
}
    ` as string &
  TypedDocumentNode<ToggleVendorIgnoreFindingMutation, ToggleVendorIgnoreFindingMutationVariables>;
export const DeleteManualFindingDocument = `
    mutation DeleteManualFinding($findingId: uuid!) {
  delete_manual_findings_by_pk(id: $findingId) {
    id
  }
}
    ` as string &
  TypedDocumentNode<DeleteManualFindingMutation, DeleteManualFindingMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    ToggleControlIgnoreFinding: build.mutation<
      ToggleControlIgnoreFindingMutation,
      ToggleControlIgnoreFindingMutationVariables
    >({
      query: (variables) => ({ document: ToggleControlIgnoreFindingDocument, variables }),
    }),
    ToggleVendorIgnoreFinding: build.mutation<
      ToggleVendorIgnoreFindingMutation,
      ToggleVendorIgnoreFindingMutationVariables
    >({
      query: (variables) => ({ document: ToggleVendorIgnoreFindingDocument, variables }),
    }),
    DeleteManualFinding: build.mutation<
      DeleteManualFindingMutation,
      DeleteManualFindingMutationVariables
    >({
      query: (variables) => ({ document: DeleteManualFindingDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const {
  useToggleControlIgnoreFindingMutation,
  useToggleVendorIgnoreFindingMutation,
  useDeleteManualFindingMutation,
} = injectedRtkApi;
