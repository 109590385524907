import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useUpdateUserMutation } from '@main/graphql/mutations/UpdateUser.generated';
import { useGetUserByIdQuery } from '@main/graphql/queries/GetUserById.generated';
import { Route } from '@tanstack/react-router';
import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCurrentUserDetails, getCurrentUserId } from '../../../features/user/slice';
import { useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { accountSettingsLayoutRoute } from './layout';

const Profile = () => {
  const { t } = useTranslation();
  const userResult = useAppSelector(getCurrentUserDetails);
  const currentUserId = useAppSelector(getCurrentUserId);
  const { refetch: refetchCurrentUser } = useGetUserByIdQuery({
    id: currentUserId,
  });
  const toast = useToast();

  const [firstName, setFirstName] = useState(userResult?.user?.metadata?.firstName ?? '');
  const [lastName, setLastName] = useState(userResult?.user?.metadata?.lastName ?? '');

  const isFirstNameDirty = firstName !== userResult?.user?.metadata?.firstName;
  const isLastNameDirty = lastName !== userResult?.user?.metadata?.lastName;
  const isProfileFormDirty = isFirstNameDirty || isLastNameDirty;
  const [updateUser] = useUpdateUserMutation();

  const updateUserProfile = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!userResult?.user?.id) {
      return;
    }

    try {
      await updateUser({
        id: userResult?.user?.id,
        users_set_input: {
          displayName: `${firstName} ${lastName}`.trim(),
        },
        users_append_metadata: {
          metadata: {
            firstName,
            lastName,
          },
        },
      }).unwrap();

      refetchCurrentUser();
      toast({
        id: 'updateProfile',
        title: 'Updated your profile',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch {
      toast({
        id: 'updateProfile',
        title: 'Unable to update your profile',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Box py="6" px={[4, 4, 8]}>
        <Heading as="h2" size="md">
          {t('settings.account.profile.personalInformation.heading')}
        </Heading>
        <Text fontSize="14" color="gray.500">
          {t('settings.account.profile.personalInformation.subheading')}
        </Text>
      </Box>

      <Divider orientation="horizontal" />
      <Box w="100%" py="6" px={[4, 4, 8]}>
        <form onSubmit={updateUserProfile}>
          <Box>
            <Flex direction={{ base: 'column', lg: 'row' }} gap="4" wrap="wrap">
              <FormControl width={{ base: '100%', lg: 'xl' }}>
                <FormLabel>
                  {t('settings.account.profile.personalInformation.form.firstName')}
                </FormLabel>
                <Input
                  type="text"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  required
                />
              </FormControl>
              <FormControl width={{ base: '100%', lg: 'xl' }}>
                <FormLabel>
                  {t('settings.account.profile.personalInformation.form.lastName')}
                </FormLabel>
                <Input
                  type="text"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  required
                />
              </FormControl>
            </Flex>
            <Box mt="6">
              <FormControl width={{ base: '100%', lg: 'xl' }}>
                <FormLabel>
                  {t('settings.account.profile.personalInformation.form.email')}
                </FormLabel>
                <Input type="email" value={userResult?.user?.email} readOnly color="gray.500" />
              </FormControl>
            </Box>
          </Box>

          <Flex justifyContent="flex-end" py="4">
            <Button type="submit" isDisabled={!isProfileFormDirty} colorScheme="blue" size="md">
              {t('buttons.update')}
            </Button>
          </Flex>
        </form>
      </Box>
    </>
  );
};

export const accountProfileSettingsRoute = new Route({
  getParentRoute: () => accountSettingsLayoutRoute,
  path: '/profile',
  component: Profile,
});
