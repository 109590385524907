import { registerNotificationHandler } from '../notifications/registry';
import {
  MentionedInRiskCommentNotificationHandler,
  RiskAssignedNotificationHandler,
  RiskExpiringNotificationHandler,
} from './notifications';

registerNotificationHandler(RiskAssignedNotificationHandler);
registerNotificationHandler(MentionedInRiskCommentNotificationHandler);
registerNotificationHandler(RiskExpiringNotificationHandler);
