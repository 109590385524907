import { Button, ButtonGroup, Icon, useDisclosure } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { toError } from '@main/shared/utils';
import { errorToast, useDownloadStorageFile } from '@main/ui';
import { useTranslation } from 'react-i18next';

import { ControlFindingIntegrationCheck } from '../control-finding';
import { useGenIntegrationRunReportMutation } from './control-finding-content.generated';
import { FindingDescription, FindingHeading, FullLogModal } from './shared';

export const ContentIntegrationCheck = ({
  integrationRunId,
  providerName,
  evidenceName,
  checkFailureMessage,
}: ControlFindingIntegrationCheck) => {
  const { t } = useTranslation();
  const { t: tIntegrations } = useTranslation('integrations');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const fullProviderName = tIntegrations(`integrations.${providerName}.name`);
  const [genIntegrationRunReport, reportMutation] = useGenIntegrationRunReport();

  return (
    <>
      <FindingHeading>{t(`findings.cause.integration_check.title`)}</FindingHeading>
      <FindingDescription>
        {t(`findings.cause.integration_check.reason`, {
          providerName: fullProviderName,
          evidenceName,
        })}
      </FindingDescription>
      <ButtonGroup spacing={3} justifyContent="end">
        <Button
          variant="outline"
          leftIcon={<Icon as={ArrowDownTrayIcon} />}
          isLoading={reportMutation.isLoading}
          onClick={() => genIntegrationRunReport(integrationRunId)}
        >
          {t(`findings.cause.integration_check.viewReportBtn`)}
        </Button>
        <Button variant="outline" onClick={onOpen}>
          {t('findings.fullLogModal.viewBtn')}
        </Button>
      </ButtonGroup>

      <FullLogModal isOpen={isOpen} onClose={onClose}>
        {checkFailureMessage}
      </FullLogModal>
    </>
  );
};

function useGenIntegrationRunReport() {
  const { t } = useTranslation();
  const [_genIntegrationRunReport, mutationDetails] = useGenIntegrationRunReportMutation();
  const downloadStorageFile = useDownloadStorageFile();

  const genIntegrationRunReport = async (integrationRunId: string) => {
    try {
      const { gen_integration_run_report } = await _genIntegrationRunReport({
        integrationRunId,
      }).unwrap();
      if (!gen_integration_run_report) {
        throw new Error('Failed generating integration run report');
      }
      await downloadStorageFile(gen_integration_run_report.reportFileId);
    } catch (error) {
      errorToast(t('findings.cause.integration_check.viewReportError'));
      datadogLogs.logger.error(
        'Failed generating integration run report',
        { integrationRunId },
        toError(error),
      );
    }
  };

  return [genIntegrationRunReport, mutationDetails] as const;
}
