import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Flex,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowPathRoundedSquareIcon, XCircleIcon } from '@heroicons/react/24/outline';
import { useAiCreateEntityMutation } from '@main/graphql/features/AiCreateEntity.generated';
import { QuestionnnaireForm, QuestionnnaireFormMode } from '@main/questionnaires-form';
import { formatDate, isGraphqlError, isNonNullable, toError } from '@main/shared/utils';
import { errorToast, FindingCard, StatusTag, useDrawer, useMultiStateToast } from '@main/ui';
import { Trans, useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux-toolkit-hooks';
import { CreateFindingRiskButton } from '../../findings/create-risk';
import { CreateFindingTaskButton } from '../../findings/create-task';
import { useToggleVendorIgnoreFindingMutation } from '../../findings/ManageFindings.generated';
import { TabFooter } from '../../shared/tab-footer';
// import { FindingMenuActions } from '../../findings/menu-actions';
import { getCurrentUserSelectedOrgRole } from '../../user/slice';
import { getFindingsMappedWithQuestionAndAnswer } from '../slice';
import { FindingQuestionnnaireFieldWrapper } from './finding-questionnaire-field-wrapper';
import {
  api as getVendorQuestionnaireApi,
  useGetVendorQuestionnairesByIdQuery,
} from './vendor-questionnaires.generated';

const ToggleFindingIgnoreStateButton = ({
  onToggle,
  label,
}: {
  onToggle: () => void;
  label: string;
}) => {
  const actionBtnColor = useColorModeValue('gray.500', 'gray.300');

  return (
    <Button
      variant="outline"
      size="sm"
      onClick={() => {
        onToggle();
      }}
      color={actionBtnColor}
    >
      {label}
    </Button>
  );
};

const useFindingMenuActions = ({
  vendorFinding,
  vendorQuestionnaireId,
}: {
  vendorFinding: VendorFinding;
  vendorQuestionnaireId: string;
}) => {
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;
  const { t } = useTranslation();
  const { toggleIgnoreState } = useFindingActionsHandlers();
  const isIgnored = !!vendorFinding.ignored_at;

  const toggleIgnoreStateAction = canUpdateVendor
    ? {
        icon: isIgnored ? <ArrowPathRoundedSquareIcon /> : <XCircleIcon />,
        label: isIgnored ? t('findings.card.reinstateBtn') : t('findings.card.ignoreBtn'),
        onClick: () => {
          toggleIgnoreState({
            findingId: vendorFinding.id,
            isIgnored,
            vendorQuestionnaireId,
          });
        },
      }
    : undefined;

  return {
    menuActions: [toggleIgnoreStateAction].filter(isNonNullable),
  };
};

type VendorFinding = ReturnType<typeof getFindingsMappedWithQuestionAndAnswer>[number];

const VendorFindingCard = ({
  vendorQuestionnaireId,
  vendorFinding,
  idx,
}: {
  vendorQuestionnaireId: string;
  vendorFinding: VendorFinding;
  idx: number;
}) => {
  const { t } = useTranslation();
  const contentColor = useColorModeValue('gray.600', 'gray.300');
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canUpdateVendor = userRole.permissionMap?.write_vendors;
  const canLinkVendorToRisk = userRole.permissionMap?.link_risks_vendors;
  const { menuActions } = useFindingMenuActions({
    vendorQuestionnaireId,
    vendorFinding,
  });
  // we only have one action for now
  const toggleIgnoreAction = menuActions[0];
  const { createRisk, createTask } = useFindingActionsHandlers();
  const questionnaireFindings = useAppSelector((state) =>
    getFindingsMappedWithQuestionAndAnswer(state, vendorQuestionnaireId),
  );
  const drawer = useDrawer();
  const isIgnored = !!vendorFinding.ignored_at;
  const riskId = vendorFinding.risk_id;
  const taskId = vendorFinding.task_id;

  return (
    <FindingCard key={vendorFinding.id} isIgnored={isIgnored}>
      <FindingCard.Header
        heading={t('findings.card.findingsNo', {
          number: idx + 1,
          total: questionnaireFindings.length,
        })}
      >
        <StatusTag size="sm" colorScheme="teal">
          {t('findings.type.recommendation')}
        </StatusTag>
      </FindingCard.Header>

      <FindingCard.Actions>
        {canLinkVendorToRisk && (
          <CreateFindingRiskButton
            onCreate={() => createRisk({ findingId: vendorFinding.id })}
            onOpen={
              riskId
                ? () =>
                    drawer.open({
                      entity: 'risk',
                      entityId: riskId,
                    })
                : undefined
            }
          />
        )}
        {canUpdateVendor && (
          <CreateFindingTaskButton
            onCreate={() => createTask({ findingId: vendorFinding.id })}
            onOpen={
              taskId
                ? () =>
                    drawer.open({
                      entity: 'task',
                      entityId: taskId,
                    })
                : undefined
            }
          />
        )}

        {toggleIgnoreAction && (
          <ToggleFindingIgnoreStateButton
            onToggle={toggleIgnoreAction.onClick}
            label={toggleIgnoreAction.label}
          />
        )}
        {/* to be used when we have more actions */}
        {/* <FindingMenuActions actions={menuActions} /> */}
      </FindingCard.Actions>

      <FindingCard.Body>
        <Text fontSize="sm" fontWeight="semibold">
          {vendorFinding.questionFromSnapshot?.aiMessage}
        </Text>
        <Text fontSize="sm" color={contentColor}>
          {vendorFinding.vendor_questionnaire_ai_review_assessment?.assessment}
        </Text>
        <Accordion allowToggle>
          <AccordionItem border="none">
            {({ isExpanded }) => (
              <>
                <AccordionButton padding="0" justifyContent="space-between">
                  <Flex direction="column" alignItems="flex-start">
                    <Text fontSize="xs" fontWeight="semibold">
                      {t('vendors.questionnaires.aiReview.question', {
                        questionNumber: vendorFinding.questionFromSnapshot?.questionIdx,
                      })}
                    </Text>
                    <Text fontSize="sm" color={contentColor} textAlign="start">
                      {vendorFinding.questionFromSnapshot?.label}
                    </Text>
                  </Flex>
                  <AccordionIcon />
                </AccordionButton>
                <AccordionPanel paddingLeft="0" paddingTop="2">
                  {isExpanded && (
                    <QuestionnnaireForm
                      fieldWrapper={FindingQuestionnnaireFieldWrapper}
                      mode={QuestionnnaireFormMode.View}
                      {...vendorFinding.questionnaireFormProps}
                    />
                  )}
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      </FindingCard.Body>
    </FindingCard>
  );
};

export const VendorFindingsTab = ({ vendorQuestionnaireId }: { vendorQuestionnaireId: string }) => {
  const { t } = useTranslation();
  useGetVendorQuestionnairesByIdQuery({
    vqId: vendorQuestionnaireId,
  });
  const questionnaireFindings = useAppSelector((state) =>
    getFindingsMappedWithQuestionAndAnswer(state, vendorQuestionnaireId),
  );

  return (
    <Box>
      <Flex direction="column" gap={3} paddingBottom="40px">
        <Text fontSize="xs" color="gray.400">
          {t('vendors.questionnaires.aiReview.hasReviewsTitle', {
            date: formatDate(questionnaireFindings[0]?.created_at),
          })}
        </Text>
        <Text>{t('vendors.questionnaires.aiReview.hasReviewsSubTitle')}</Text>
        <Stack>
          {questionnaireFindings.map((vendorFinding, idx) => (
            <VendorFindingCard
              key={vendorFinding.id}
              idx={idx}
              vendorFinding={vendorFinding}
              vendorQuestionnaireId={vendorQuestionnaireId}
            />
          ))}
        </Stack>
      </Flex>
      <TabFooter>
        <Text fontSize="xs" color="gray.400">
          {t('vendors.questionnaires.aiReview.footer')}
        </Text>
      </TabFooter>
    </Box>
  );
};

const useFindingActionsHandlers = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const toast = useMultiStateToast();
  const drawer = useDrawer();

  const [toggleIgnoreFinding] = useToggleVendorIgnoreFindingMutation();
  const [aiCreateEntity] = useAiCreateEntityMutation();

  const toastTextColor = useColorModeValue('green.800', 'green.600');

  const toggleIgnoreState = async ({
    findingId,
    isIgnored,
    vendorQuestionnaireId,
  }: {
    findingId: string;
    isIgnored: boolean;
    vendorQuestionnaireId: string;
  }) => {
    const patchResult = dispatch(
      getVendorQuestionnaireApi.util.updateQueryData(
        'GetVendorQuestionnairesById',
        { vqId: vendorQuestionnaireId },
        (draft) => {
          for (const draftFinding of draft.vendor_questionnaires_by_pk?.vendor_findings ?? []) {
            if (draftFinding.id === findingId) {
              draftFinding.ignored_at = isIgnored ? undefined : new Date().toISOString();
            }
          }
        },
      ),
    );
    try {
      await toggleIgnoreFinding({
        findingId: findingId,
        ignored_at: isIgnored ? null : 'now()',
      }).unwrap();
    } catch (error) {
      errorToast(t('errorMessages.updateFailed', { entity: t('entities.finding') }));
      datadogLogs.logger.error('Toggling ignore state of the finding failed', {}, toError(error));
      patchResult.undo();
    }
  };

  const createRisk = async ({ findingId }: { findingId: string }) => {
    toast.loading({ title: t('findings.risk.isBeingCreatedToast') });

    try {
      const { ai_create_entity } = await aiCreateEntity({
        input: { entity: 'risk-vendor-finding', findingId },
      }).unwrap();

      toast.success({
        title: (
          <Trans
            i18nKey="findings.risk.createdToast"
            values={{
              internalId: ai_create_entity?.internalId,
            }}
            components={{
              p: <Text display="inline" color={toastTextColor} />,
              a: (
                <Button
                  variant="link"
                  color={toastTextColor}
                  onClick={() => {
                    drawer.open({
                      entity: 'risk',
                      entityId: ai_create_entity?.entityId ?? '',
                    });
                    toast.close();
                  }}
                />
              ),
            }}
          />
        ),
      });
    } catch (error) {
      if (isGraphqlError(error)) {
        switch (error[0]?.extensions.code) {
          case 'NOT_FOUND':
            datadogLogs.logger.error(
              'Vendor of the finding was not found',
              { findingId },
              toError(error),
            );
            return;
          default:
            datadogLogs.logger.error(
              'Either creating risk or linking it to the finding failed',
              { findingId },
              toError(error),
            );
            break;
        }
      }
      toast.error({ title: t('errorMessages.createFailed', { entity: t('entities.risk') }) });
    }
  };

  const createTask = async ({ findingId }: { findingId: string }) => {
    toast.loading({ title: t('findings.task.isBeingCreatedToast') });

    try {
      const { ai_create_entity } = await aiCreateEntity({
        input: { entity: 'task-vendor-finding', findingId },
      }).unwrap();

      toast.success({
        title: (
          <Trans
            i18nKey="findings.task.createdToast"
            values={{
              internalId: ai_create_entity?.internalId,
            }}
            components={{
              p: <Text display="inline" color={toastTextColor} />,
              a: (
                <Button
                  variant="link"
                  color={toastTextColor}
                  onClick={() => {
                    drawer.open({
                      entity: 'task',
                      entityId: ai_create_entity?.entityId ?? '',
                    });
                    toast.close();
                  }}
                />
              ),
            }}
          />
        ),
      });
    } catch (error) {
      if (isGraphqlError(error)) {
        switch (error[0]?.extensions.code) {
          case 'NOT_FOUND':
            datadogLogs.logger.error(
              'Vendor of the finding was not found, it is used for task creation',
              { findingId },
              toError(error),
            );
            return;
          default:
            datadogLogs.logger.error(
              'Either creating task or linking it to the finding failed',
              { findingId },
              toError(error),
            );
            break;
        }
      }
      toast.error({ title: t('errorMessages.createFailed', { entity: t('entities.task') }) });
    }
  };

  return {
    toggleIgnoreState,
    createRisk,
    createTask,
  };
};
