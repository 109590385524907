import {
  As,
  CardProps as ChakraCardProps,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tag,
  ThemeTypings,
  Tooltip,
  useColorModeValue,
} from '@chakra-ui/react';
import { EllipsisVerticalIcon } from '@heroicons/react/24/outline';
import { isNonNullable } from '@main/shared/utils';
import { ReactNode, useMemo } from 'react';

import { StatusTag } from '../status-tag';
import { CardContext, useCardContext } from './card-context';

interface CardProps extends ChakraCardProps {
  size?: 'sm' | 'lg';
  isReadOnly?: boolean;
}

export const Card = ({ children, size = 'sm', isReadOnly, ...props }: CardProps) => {
  const borderColor = useColorModeValue('gray.50', 'gray.600');
  /**
   *  We are using Grid Areas to place specific parts of the card in certain positions.
   *  The Areas are defined as follows:
   *  - left: The left are placed in the top left corner.
   *  - right: The right of the card are placed in the top right corner.
   *  - main:
   *    - For small cards, the main is placed in center, between left and right.
   *    - For large cards, the main is moved to the second row and takes up full length of the card.
   */
  const templateAreas = size === 'sm' ? `"left main right"` : `"left . right" "main main main"`;

  return (
    <CardContext.Provider
      value={{
        size,
        isReadOnly: !!isReadOnly,
      }}
    >
      <Grid
        templateAreas={templateAreas}
        gridTemplateColumns="auto 1fr auto"
        w="full"
        px={3}
        py={2}
        gap={3}
        rounded="lg"
        shadow="sm"
        border="1px"
        alignItems="center"
        borderColor={borderColor}
        {...props}
      >
        {children}
      </Grid>
    </CardContext.Provider>
  );
};

const CardBody = ({ children }: { children: ReactNode }) => {
  return (
    <GridItem area="main">
      <Flex gap={2} alignItems="center" w="full" flexGrow={1}>
        {children}
      </Flex>
    </GridItem>
  );
};
export interface TagConfig {
  label: string;
  colorSchema: ThemeTypings['colorSchemes'];
  type?: 'status' | 'text';
}

interface CardHeaderProps extends Partial<CardActionsProps> {
  right?: ReactNode;
  left?: ReactNode;
}

const CardHeader = (props: CardHeaderProps) => {
  return (
    <>
      <GridItem area="left">{props.left}</GridItem>
      <GridItem area="right" as={Flex} gap={2} alignItems="center">
        {props.right}
        <Card.Actions actions={props.actions} numberOfInlineActions={props.numberOfInlineActions} />
      </GridItem>
    </>
  );
};

export interface CardTagsProps {
  tags: TagConfig[];
}
const CardTags = ({ tags }: CardTagsProps) => {
  return (
    <Flex gap={2} alignItems="center">
      {tags.map((tag) => {
        if (tag.type === 'status') {
          return (
            <StatusTag key={tag.label} colorScheme={tag.colorSchema}>
              {tag.label}
            </StatusTag>
          );
        }

        return (
          <Tag key={tag.label} colorScheme={tag.colorSchema}>
            {tag.label}
          </Tag>
        );
      })}{' '}
    </Flex>
  );
};

export interface CardAction {
  label: string;
  icon: As;
  onClick: () => void;
  isLoading?: boolean;
  isReadOnly?: boolean;
}
export interface CardActionsProps {
  actions?: (CardAction | undefined)[];
  numberOfInlineActions?: number;
}
const CardActions = ({ actions, numberOfInlineActions = 3 }: CardActionsProps) => {
  const hoverIconColor = useColorModeValue('gray.700', 'gray.500');
  const { isReadOnly } = useCardContext();

  const { inlineActions, menuActions } = useMemo(() => {
    const validActions = actions
      ?.filter(isNonNullable)
      .filter((action) => !isReadOnly || action.isReadOnly);
    const inlineActions = validActions?.slice(0, numberOfInlineActions);
    const menuActions = validActions?.slice(numberOfInlineActions);

    return { inlineActions, menuActions };
  }, [actions, isReadOnly, numberOfInlineActions]);

  if (!inlineActions?.length && !menuActions?.length) {
    return null;
  }

  return (
    <Flex gap={2} alignItems="center" color="gray.500">
      {inlineActions?.map((action) => (
        <Tooltip
          key={action.label}
          label={action.label}
          placement="top"
          gutter={12}
          openDelay={300}
        >
          <IconButton
            variant="link"
            size="sm"
            minW="14px"
            onClick={action.onClick}
            aria-label={action.label}
            icon={<Icon as={action.icon} />}
            isLoading={action.isLoading}
            _hover={{ color: hoverIconColor }}
          />
        </Tooltip>
      ))}

      {menuActions?.length ? (
        <Menu>
          <MenuButton
            aria-label="menu-actions"
            as={IconButton}
            variant="link"
            size="sm"
            minW={4}
            icon={<Icon as={EllipsisVerticalIcon} boxSize="4" />}
            isLoading={menuActions.some((action) => action.isLoading)}
          />
          <MenuList>
            {menuActions.map((action) => (
              <MenuItem
                key={action.label}
                icon={<Icon as={action.icon} boxSize="4" />}
                iconSpacing={2}
                fontSize="sm"
                onClick={action.onClick}
                _dark={{ color: 'gray.300' }}
              >
                {action.label}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>
      ) : null}
    </Flex>
  );
};

Card.Header = CardHeader;
Card.Body = CardBody;
Card.Actions = CardActions;
Card.Tags = CardTags;
