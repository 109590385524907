import { Icon, IconButton, Text } from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { DocumentDuplicateIcon, PencilIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useAddControlGroupTagMutation } from '@main/graphql/mutations/AddControlGroupTag.generated';
import { useCreateGroupTagMutation } from '@main/graphql/mutations/CreateGroupTag.generated';
import { useRemoveControlMutation } from '@main/graphql/mutations/RemoveControl.generated';
import { useRemoveControlGroupTagMutation } from '@main/graphql/mutations/RemoveControlGroupTag.generated';
import { useUpdateControlByIdMutation } from '@main/graphql/mutations/UpdateControlById.generated';
import { api as getGroupTagsApi } from '@main/graphql/queries/GetGroupTags.generated';
import { api as getOrganizationControlsApi } from '@main/graphql/queries/GetOrganizationControls.generated';
import { Field_Entities_Enum } from '@main/graphql/types.generated';
import { isNonNullable, toError, uniqBy } from '@main/shared/utils';
import {
  createColumnHelper,
  errorToast,
  getHashedColor,
  successToast,
  TagOption,
  useAlertDialog,
  useDrawer,
} from '@main/ui';
import { UnlinkIcon } from '@main/ui/icons';
import { useParams } from '@tanstack/react-router';
import { ActionMeta, MultiValue } from 'chakra-react-select';
import { useCallback, useMemo } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { customFieldColumn } from '../custom-fields/custom-field-column';
import { useGetFieldConfigsQuery } from '../custom-fields/field-config';
import { useUpdateControlOnTableHandler } from '../program/use-update-control-handler';
import { getControlFrequencyOption, getControlFrequencyOptions } from '../shared/frequency';
import { CONTROL_STATUS_COLOR, FINDING_TYPE_COLOR } from '../shared/status-color';
import {
  getCurrentOrgNonDisabledUsers,
  getCurrentOrgUsersMap,
  getCurrentUserSelectedOrg,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { useDeleteControl } from './delete-control';
import { useDuplicateControl } from './duplicate-control';
import { getOrgControls } from './slice';

export const programColumnId = 'programs';

type TableColumnProps = {
  page: 'control-center' | 'program-page' | 'alerts-page' | 'reports-page';
};

const PAGES_WITH_ACTIONS: TableColumnProps['page'][] = ['control-center', 'program-page'];

export type OrganizationControl = ReturnType<typeof getOrgControls>[0];

export function useControlsTableColumns({ page }: TableColumnProps) {
  const { t } = useTranslation();
  const drawer = useDrawer();
  const { programId } = useParams({ from: '/programs/$programId' });
  const { openDialog } = useAlertDialog();
  const dispatch = useAppDispatch();

  const currentOrgUsers = useAppSelector(getCurrentOrgUsersMap);
  const currentOrgNonDisabledUsers = useAppSelector(getCurrentOrgNonDisabledUsers);
  const organization = useAppSelector(getCurrentUserSelectedOrg);
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditControl = userRole.permissionMap?.update_controls;
  const canLinkControlToProgram = userRole.permissionMap.link_controls_programs;
  const fieldConfigsQuery = useGetFieldConfigsQuery({
    orgId: organization.id,
    entityName: Field_Entities_Enum.Control,
  });
  const fieldConfigs = fieldConfigsQuery.data?.field_configs;

  const [updateControlById] = useUpdateControlByIdMutation();
  const updateControlHandler = useUpdateControlOnTableHandler();

  const [createGroupTag] = useCreateGroupTagMutation();
  const [addControlGroupTag] = useAddControlGroupTagMutation();
  const [removeControlGroupTag] = useRemoveControlGroupTagMutation();
  const [removeControl] = useRemoveControlMutation();
  const deleteControl = useDeleteControl();
  const duplicateControl = useDuplicateControl();

  const selectControlTagHandler = useCallback(
    async (controlId: string, groupId: string) => {
      try {
        await addControlGroupTag({ groupId, controlId }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.addFailed'));
        datadogLogs.logger.error('Unable to add custom tag into control.', {}, toError(error));
      }
    },
    [addControlGroupTag, t],
  );

  const createTagHandler = useCallback(
    async (controlId: string, label: string) => {
      try {
        await createGroupTag({
          object: {
            control_id: controlId,
            group: {
              data: {
                name: label,
                organization_id: organization.id,
              },
            },
          },
        }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.createFailed', { entity: t('controls.tags') }));
        datadogLogs.logger.error('Unable to create tag.', {}, toError(error));
        return;
      }
    },
    [createGroupTag, organization, t],
  );

  const removeControlTagHandler = useCallback(
    async (controlId: string, groupId: string): Promise<void> => {
      try {
        await removeControlGroupTag({ groupId, controlId }).unwrap();
      } catch (error) {
        errorToast(t('errorMessages.removeFailed', { entity: t('controls.tags') }));
        datadogLogs.logger.error('Unable to delete custom tag from control.', {}, toError(error));
      }
    },
    [removeControlGroupTag, t],
  );

  const handleChange = useCallback(
    async (controlId: string, items: MultiValue<TagOption>, actionMeta: ActionMeta<TagOption>) => {
      switch (actionMeta.action) {
        case 'create-option': {
          dispatch(
            getOrganizationControlsApi.util.updateQueryData(
              'GetOrganizationControls',
              { organizationId: organization.id },
              (draft) => {
                for (const control of draft.controls) {
                  if (control.id === controlId) {
                    control.groups.push({
                      group: {
                        id: actionMeta.option?.value,
                        name: actionMeta.option?.label as string,
                      },
                    });
                  }
                }
              },
            ),
          );

          createTagHandler(controlId, actionMeta.option.label as string);
          break;
        }
        case 'select-option': {
          dispatch(
            getOrganizationControlsApi.util.updateQueryData(
              'GetOrganizationControls',
              { organizationId: organization.id },
              (draft) => {
                for (const control of draft.controls) {
                  if (control.id === controlId) {
                    control.groups.push({
                      group: {
                        id: actionMeta.option?.value as string,
                        name: actionMeta.option?.label as string,
                      },
                    });
                  }
                }
              },
            ),
          );
          await selectControlTagHandler(controlId, actionMeta.option?.value as string);
          break;
        }
        case 'remove-value':
        case 'pop-value': {
          dispatch(
            getOrganizationControlsApi.util.updateQueryData(
              'GetOrganizationControls',
              { organizationId: organization.id },
              (draft) => {
                for (const control of draft.controls) {
                  if (control.id === controlId) {
                    const remainingGroups = control.groups.filter(
                      ({ group }) => group.id !== actionMeta.removedValue.value,
                    );
                    control.groups = remainingGroups;
                  }
                }
              },
            ),
          );

          await removeControlTagHandler(controlId, actionMeta.removedValue.value);
          break;
        }
      }
    },
    [createTagHandler, removeControlTagHandler, dispatch, selectControlTagHandler, organization.id],
  );

  const onUnlinkControl = useCallback(
    async (controlId: string) => {
      try {
        await removeControl({
          control_id: controlId,
          program_id: programId,
        }).unwrap();

        successToast(t('successMessages.unlinkSucceeded', { entity: t('entities.control') }));
      } catch (error) {
        errorToast(t('errorMessages.unlinkFailed', { entity: t('entities.control') }));
        datadogLogs.logger.error('Control unlink failed', {}, toError(error));
      }
    },
    [removeControl, programId, t],
  );

  return useMemo(() => {
    const columnHelper = createColumnHelper<OrganizationControl>();

    const openControlDrawer = (controlId: string) => {
      drawer.open({ entity: 'control', entityId: controlId });
    };

    return [
      columnHelper.columns.status({
        id: 'status',
        header: t('controls.table.columns.status'),
        accessorFn: ({ status }) => {
          return {
            value: t(`controls.status.enums.${status}`),
            colorScheme: CONTROL_STATUS_COLOR[status],
          };
        },
        size: 180,
        enableColumnFilter: true,
        enableSorting: true,
      }),

      columnHelper.columns.tag({
        id: 'internalId',
        header: t('controls.table.columns.internalId'),
        accessorFn: ({ internal_id }) => ({
          value: internal_id ?? '',
          colorScheme: 'purple',
        }),
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
        size: 100,
      }),

      columnHelper.columns.text({
        id: 'name',
        header: t('controls.table.columns.name'),
        accessorFn: ({ name }) => name,
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
        meta: {
          cell: {
            onClick: (cell) => openControlDrawer(cell.row.original.id),
          },
        },
      }),

      columnHelper.columns.text({
        id: 'description',
        header: t('controls.table.columns.description'),
        accessorFn: ({ description }) => description,
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
        size: 300,
      }),

      columnHelper.columns.tag({
        id: 'criteria',
        header: t('controls.criteria'),
        isMulti: true,
        accessorFn: ({ criterias }) => {
          return criterias.map(({ criteria }) => ({
            value: criteria.id,
            label: criteria.name,
            colorScheme: 'purple',
          }));
        },
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
        size: 120,
      }),

      columnHelper.columns.tag({
        id: 'category',
        header: t('controls.category'),
        isMulti: true,
        accessorFn: ({ categories }) => {
          return categories.map(({ category }) => ({
            value: category.id,
            label: category.name,
            colorScheme: 'purple',
          }));
        },
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
        size: 120,
      }),

      columnHelper.columns.tag({
        id: 'custom_tags',
        header: t('controls.tags'),
        isMulti: true,
        accessorFn: ({ custom_tags }) => {
          return custom_tags.map(({ tag }) => ({
            value: tag.id,
            label: tag.name,
            colorScheme: 'purple',
          }));
        },
        enableGlobalFilter: true,
        enableColumnFilter: true,
        enableSorting: true,
        size: 120,
      }),

      columnHelper.columns.tag({
        id: 'group',
        header: t('controls.table.columns.group'),
        accessorFn: ({ groups }) => {
          return groups.map(({ group }) => ({
            value: group.id,
            label: group.name,
            colorScheme: 'purple',
          }));
        },
        size: 120,
        enableColumnFilter: true,
        enableGlobalFilter: true,
        enableSorting: true,
        isMulti: true,
        edit:
          !canEditControl || page === 'reports-page'
            ? undefined
            : {
                debounceMs: 500,
                loadOptions: async (input: string) => {
                  const { groups } = await dispatch(
                    getGroupTagsApi.endpoints.GetGroupTags.initiate({
                      orgId: organization.id,
                      ...(input?.trim() ? { nameFilter: { _ilike: `%${input}%` } } : {}),
                    }),
                  ).unwrap();

                  return groups.map((group) => ({
                    value: group.id,
                    label: group.name,
                    colorScheme: 'purple',
                  }));
                },
                getNewOptionData: (label) => ({ label, value: 'new', colorScheme: 'purple' }),
                onChange: (row, group, action) => handleChange(row.original.id, group, action),
              },
      }),

      columnHelper.columns.tag({
        id: 'findings',
        header: t('controls.table.columns.findings'),
        isMulti: true,
        accessorFn: ({ findings }) => {
          return uniqBy(
            findings.map((finding) => {
              if (finding.ignored_at) {
                return {
                  value: 'ignored',
                  label: t('findings.ignored'),
                  colorScheme: 'gray',
                };
              }

              return {
                value: finding.type,
                colorScheme: FINDING_TYPE_COLOR[finding.type],
                label: t(`findings.type.${finding.type}`),
              };
            }),
            'value',
          );
        },
        enableColumnFilter: true,
        size: 100,
      }),

      columnHelper.columns.tag({
        id: programColumnId,
        header: t('controls.table.columns.programs'),
        accessorFn: (control) => {
          return control.programs.map((program) => ({
            value: program.id,
            label: program.name,
            colorScheme: getHashedColor(program.name),
          }));
        },
        size: 120,
        enableColumnFilter: true,
        enableSorting: true,
        isMulti: true,
      }),

      columnHelper.columns.tag({
        id: 'priority',
        header: t('controls.priority.label'),
        accessorFn: (control) => {
          if (!control.priority) {
            return;
          }

          return {
            value: control.priority,
            label: t(`controls.priority.enums.${control.priority}`),
            colorScheme: 'purple',
          };
        },
        size: 150,
        enableColumnFilter: true,
        enableSorting: true,
      }),

      columnHelper.columns.tag({
        id: 'frequency',
        header: t('controls.frequency.label'),
        accessorFn: ({ frequency }) => {
          if (!frequency) {
            return;
          }

          return getControlFrequencyOption(t, frequency);
        },
        size: 100,
        enableColumnFilter: true,
        enableSorting: true,
        edit:
          !canEditControl || page === 'reports-page'
            ? undefined
            : {
                isClearable: false,
                options: getControlFrequencyOptions(t),
                onChange: (row, frequency) => {
                  if (!frequency) {
                    return;
                  }

                  updateControlHandler(
                    updateControlById({
                      controlId: row.original.id,
                      updatePayload: {
                        frequency: frequency.value,
                      },
                    }),
                  );
                },
              },
      }),

      columnHelper.columns.avatar({
        id: 'user',
        header: t('controls.table.columns.user'),
        accessorFn: ({ assignee_id }) => {
          if (!assignee_id) {
            return;
          }

          const user = currentOrgUsers[assignee_id];
          if (!user) {
            return;
          }

          return {
            id: user.id,
            displayName: user.displayName,
          };
        },
        size: 70,
        enableColumnFilter: true,
        enableSorting: true,
        edit:
          !canEditControl || page === 'reports-page'
            ? undefined
            : {
                options: currentOrgNonDisabledUsers.map((user) => ({
                  id: user.id,
                  displayName: user.displayName,
                })),
                onChange: (row, user) => {
                  updateControlHandler(
                    updateControlById({
                      controlId: row.original.id,
                      updatePayload: {
                        assignee_id: user?.id || null,
                      },
                    }),
                  );
                },
              },
      }),

      ...(fieldConfigs?.map(customFieldColumn<OrganizationControl>) || []),

      PAGES_WITH_ACTIONS.includes(page)
        ? columnHelper.columns.actions({
            size: 75,
            PrimaryAction: (context) => (
              <IconButton
                minW={4}
                variant="link"
                aria-label={t('controls.actions.edit')}
                icon={<PencilIcon />}
                onClick={() => openControlDrawer(context.row.original.id)}
              />
            ),
            secondaryActions: ({ row }) =>
              [
                duplicateControl
                  ? {
                      icon: <Icon as={DocumentDuplicateIcon} />,
                      label: t('buttons.duplicate'),
                      onClick: () => {
                        duplicateControl(row.original.id);
                      },
                    }
                  : null,

                page === 'program-page' && canLinkControlToProgram
                  ? {
                      icon: <Icon as={UnlinkIcon} />,
                      label: t('controls.buttons.unlink'),
                      onClick: () =>
                        openDialog({
                          dialogHeader: t('controls.alert.unlink.header'),
                          dialogContent: (
                            <Text>
                              <Trans
                                i18nKey="controls.alert.unlink.content"
                                values={{
                                  internalId: `${row.original.internal_id}`,
                                  name: `${row.original.name}`,
                                }}
                                components={{ bold: <strong /> }}
                              />
                            </Text>
                          ),
                          confirmAction: {
                            children: t('controls.alert.unlink.confirm', {
                              internalId: row.original.internal_id,
                            }),
                            onClick: () => onUnlinkControl(row.original.id),
                          },
                        }),
                    }
                  : null,

                page !== 'program-page' && deleteControl
                  ? {
                      icon: <Icon as={TrashIcon} />,
                      label: t('buttons.delete'),
                      onClick: () => {
                        deleteControl(row.original.id);
                      },
                    }
                  : null,
              ].filter(isNonNullable),
          })
        : null,
    ].filter(isNonNullable);
  }, [
    t,
    canEditControl,
    page,
    currentOrgNonDisabledUsers,
    fieldConfigs,
    drawer,
    dispatch,
    organization.id,
    handleChange,
    updateControlHandler,
    updateControlById,
    currentOrgUsers,
    canLinkControlToProgram,
    deleteControl,
    duplicateControl,
    openDialog,
    onUnlinkControl,
  ]);
}
