import {
  Box,
  Button,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Icon,
  Stack,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { datadogLogs } from '@datadog/browser-logs';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { Client_Questionnaire_Status_Enum } from '@main/graphql/types.generated';
import { isAbortError, toError } from '@main/shared/utils';
import { DrawerSkeleton, errorToast, OverflowContainer, Page404, successToast } from '@main/ui';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { useOrgGuard } from '../../hooks/use-org-guard';
import { getCurrentUserSelectedOrgRole } from '../user/slice';
import { EditClientQuestionnaireModal } from './edit-modal';
import { useGetClientQuestionnaireQuestionsSubscription } from './questionnaire-questions.subs';
import { ClientQquestionsTable } from './questions-table';
import { useExportClientQ } from './use-export';

export function ClientQuestionnaireQuestions({ questionnaireId }: { questionnaireId: string }) {
  const { t } = useTranslation();
  const userRole = useAppSelector(getCurrentUserSelectedOrgRole);
  const canEditClientQ = !!userRole.permissionMap?.write_client_q;
  const editDisclosure = useDisclosure();
  const { data, isLoading } = useOrgGuard(
    useGetClientQuestionnaireQuestionsSubscription({ questionnaireId }),
    ({ data }) => data?.clientQuestionnaire?.organization_id,
  );
  const questionnaire = data?.clientQuestionnaire;
  const isQueued = questionnaire?.status === Client_Questionnaire_Status_Enum.ReadyForExtraction;
  const isProcessing =
    questionnaire?.status === Client_Questionnaire_Status_Enum.ExtractingQuestions;
  const isFailed = questionnaire?.status === Client_Questionnaire_Status_Enum.ExtractionFailed;
  const canExport = !isQueued && !isProcessing && !isFailed;
  const [isExporting, setIsExporting] = useState(false);
  const exportQuestionnaire = useExportClientQ();
  const subheadingColor = useColorModeValue('gray.700', 'gray.400');

  if (isLoading) {
    return <DrawerSkeleton />;
  }

  if (!questionnaire) {
    return <Page404 />;
  }

  async function handleExport() {
    if (!questionnaire) {
      return;
    }
    let exportFileId: string | undefined;

    try {
      setIsExporting(true);
      exportFileId = await exportQuestionnaire(questionnaire);
      successToast(t('clientQuestionnaire.exportSuccessful'));
    } catch (error) {
      if (isAbortError(error)) {
        return;
      }

      datadogLogs.logger.error(
        'Failed to export Client Questionnare file',
        { clientQuestionnaireId: questionnaire.id, exportFileId },
        toError(error),
      );
      errorToast(t('errorMessages.fileDownloadFailed'));
    } finally {
      setIsExporting(false);
    }
  }

  return (
    <>
      <Stack spacing={6}>
        <Box mr={{ base: 0, md: 64 }}>
          <OverflowContainer>
            <OverflowContainer.Tooltip label={questionnaire.name} fontSize="sm" openDelay={500}>
              <Text isTruncated fontWeight="semibold" fontSize="3xl">
                {questionnaire.name}
              </Text>
            </OverflowContainer.Tooltip>
            {canEditClientQ && <OverflowContainer.EditTrigger onClick={editDisclosure.onOpen} />}
          </OverflowContainer>
          <Text color={subheadingColor} fontSize="sm" noOfLines={1}>
            {questionnaire.description}
          </Text>
        </Box>

        <Card variant="table-styles">
          <CardHeader>
            <Box>
              <Heading size="md">{t('clientQuestionnaire.heading')}</Heading>
              <Text variant="subheading">{t('clientQuestionnaire.subheading')}</Text>
            </Box>
            {canExport && (
              <Button
                colorScheme="blue"
                variant="solid"
                leftIcon={<Icon as={ArrowUpTrayIcon} />}
                isLoading={isExporting}
                onClick={handleExport}
              >
                {t('clientQuestionnaire.exportButton')}
              </Button>
            )}
          </CardHeader>
          <CardBody>
            <ClientQquestionsTable
              questions={questionnaire.questions}
              isLoading={isLoading}
              isQueued={isQueued}
              isProcessing={isProcessing}
              isFailed={isFailed}
            />
          </CardBody>
        </Card>
      </Stack>
      <EditClientQuestionnaireModal questionnaireId={questionnaireId} modal={editDisclosure} />
    </>
  );
}
