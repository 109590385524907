import { Tag } from '@chakra-ui/react';
import { PolicyAuditRow } from '@main/graphql/client-scalars';
import { exhaustiveCheck, hasProperty } from '@main/shared/utils';
import { FC, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { ActivityEntry, PaginatedHistoryList, TagActivityEntry } from '../activity-history/shared';
import { POLICY_VERSION_STATUS_COLOR } from '../shared/status-color';
import { getCurrentOrgUsersMap } from '../user/slice';
import { getFormattedPolicyVersionId } from './constants';
import {
  GetPolicyActivityHistoryQuery,
  useGetPolicyActivityHistoryQuery,
} from './manage-policies.generated';

type Activity = GetPolicyActivityHistoryQuery['audit_policy_history_view'][number] & PolicyAuditRow;

export const PolicyHistoryTab: FC<{ policyId: string }> = ({ policyId }) => {
  const { t } = useTranslation();
  const [paginationParams, setPagination] = useState<{ limit: number; offset: number }>();
  const { data: activityData, isLoading: isLoadingHistory } = useGetPolicyActivityHistoryQuery(
    {
      policy_id: policyId,
      ...paginationParams,
    },
    { refetchOnMountOrArgChange: true, skip: !paginationParams },
  );
  const currentOrgUsersById = useAppSelector(getCurrentOrgUsersMap);

  if (isLoadingHistory) {
    return null;
  }

  const getActivityNode = (activity: Activity) => {
    switch (activity.table_name) {
      case 'policies': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('policies.history.policyAdded')}
              />
            );
          }
          case 'U':
            if (hasProperty(activity.changed_fields, 'assignee_id')) {
              const oldId = activity.row_data.assignee_id;
              const newId = activity.changed_fields.assignee_id;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('policies.history.assigneeChanged')}
                  from={oldId ? (currentOrgUsersById[oldId]?.displayName ?? 'N/A') : 'N/A'}
                  to={newId ? (currentOrgUsersById[newId]?.displayName ?? 'N/A') : 'N/A'}
                />
              );
            }
            return null;
          case 'D':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }

      case 'comments': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('policies.history.commentAdded')}
              />
            );
          }
          case 'D': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('policies.history.commentDeleted')}
              />
            );
          }
          case 'U':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }

      case 'policy_versions': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={
                  <Trans
                    i18nKey="policies.history.versionAdded"
                    values={{ version: getFormattedPolicyVersionId(activity.row_data.version_id) }}
                    components={{ tag: <Tag colorScheme="gray" /> }}
                  />
                }
              />
            );
          }
          case 'D': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={
                  <Trans
                    i18nKey="policies.history.versionDeleted"
                    values={{ version: getFormattedPolicyVersionId(activity.row_data.version_id) }}
                    components={{ tag: <Tag colorScheme="gray" /> }}
                  />
                }
              />
            );
          }
          case 'U': {
            if (hasProperty(activity.changed_fields, 'status')) {
              const oldStatus = activity.row_data.status;
              const newStatus = activity.changed_fields.status;

              return (
                <TagActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={
                    <Trans
                      i18nKey="policies.history.changedVersionStatus"
                      values={{
                        version: getFormattedPolicyVersionId(activity.row_data.version_id),
                      }}
                      components={{ tag: <Tag colorScheme="gray" /> }}
                    />
                  }
                  from={oldStatus ? (t(`policies.versions.status.${oldStatus}`) ?? 'N/A') : 'N/A'}
                  fromColorScheme={POLICY_VERSION_STATUS_COLOR[oldStatus]}
                  to={newStatus ? (t(`policies.versions.status.${newStatus}`) ?? 'N/A') : 'N/A'}
                  toColorScheme={POLICY_VERSION_STATUS_COLOR[newStatus]}
                />
              );
            }

            return null;
          }
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }

      case 'policy_approvals': {
        switch (activity.action) {
          case 'I': {
            return (
              <ActivityEntry
                hasuraUser={activity.hasura_user}
                timestamp={activity.action_timestamp}
                title={t('policies.history.versionSentForApproval')}
              />
            );
          }
          case 'D':
          case 'U':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }

      case 'policy_approval_users': {
        switch (activity.action) {
          case 'U': {
            if (hasProperty(activity.changed_fields, 'approved_at')) {
              return (
                <ActivityEntry
                  hasuraUser={activity.hasura_user}
                  timestamp={activity.action_timestamp}
                  title={t('policies.history.userApproved')}
                />
              );
            }

            return null;
          }
          case 'I':
          case 'D':
          case 'T': {
            return null;
          }
          default:
            return exhaustiveCheck(activity.action);
        }
      }
    }
  };

  return (
    <PaginatedHistoryList
      setPaginationParams={setPagination}
      currentPageData={activityData?.audit_policy_history_view as Activity[]}
      isLoadingHistory={isLoadingHistory}
      getActivityNode={getActivityNode}
    />
  );
};
