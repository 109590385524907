import { useFormControl } from '@chakra-ui/react';
import { GroupBase } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';

import { ComboboxComponents, useComboboxComponents } from './combobox-components';
import { ComboboxVariant, getChakraStyles } from './combobox-styles';
import { tagNewOption } from './new-option';
import { Select, SelectProps } from './select';

export type ComboboxProps<
  TOption,
  IsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
> = Omit<
  SelectProps<TOption, IsMulti, TGroup>,
  'ariaLiveMessages' | 'classNames' | 'styles' | 'chakraStyles' | 'components' | 'variant'
> & {
  groupSize?: number;
  variant?: ComboboxVariant;
  singleAsMulti?: boolean;
  components?: ComboboxComponents<TOption>;
};

export function Combobox<
  TOption extends object,
  IsMulti extends boolean = false,
  TGroup extends GroupBase<TOption> = GroupBase<TOption>,
>({ variant = 'inline', ...props }: ComboboxProps<TOption, IsMulti, TGroup>) {
  const { t } = useTranslation('ui');
  const formControlProps = useFormControl({});
  const isReadOnly = formControlProps.readOnly || props.isReadOnly;

  return (
    <Select
      isClearable={false}
      backspaceRemovesValue={false}
      tabSelectsValue={false}
      blurInputOnSelect={!props.isMulti}
      closeMenuOnSelect={!props.isMulti}
      placeholder={t('combobox.placeholder')}
      loadingMessage={() => t('combobox.loading')}
      noOptionsMessage={() => t('combobox.noOptions')}
      {...tagNewOption(props)}
      variant="unstyled"
      chakraStyles={getChakraStyles<TOption, IsMulti, TGroup>(variant)}
      components={useComboboxComponents(props)}
      isDisabled={isReadOnly}
      {...(isReadOnly && { menuIsOpen: false })}
    />
  );
}
