/**
 * Strips from string all new lines, tabs and carriage returns
 * and replaces them with a space and trims the string
 */
export function stripFormatting(str: string, replaceWith = ' ') {
  return str.replace(/(?:\r\n|\r|\n|\t)/g, replaceWith).trim();
}

/**
 * Strips from string all new lines, tabs and carriage returns
 * as regular characters (instead of control characters)
 * and replaces them with a space and trims the string
 */
export function stripPseudoFormatting(str: string, replaceWith = ' ') {
  return str.replace(/(?:\\r\\n|\\r|\\n)/g, replaceWith).trim();
}
