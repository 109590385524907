export function uniqBy<T, K extends keyof T | ((item: T) => string)>(
  array: T[] | undefined,
  key: K,
): T[] {
  if (!array || !Array.isArray(array) || array.length === 0) {
    return [];
  }

  const keyFn =
    typeof key === 'function' ? (key as (item: T) => string) : (item: T) => item[key as keyof T];

  return Array.from(new Map(array.map((item) => [keyFn(item), item])).values());
}
