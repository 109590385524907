/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 *
 * instead, edit one of the `.graphql` files in this project and run
 * `pnpm nx run graphql:codegen `
 * for this file to be re-created
 */
import * as Types from '@main/graphql/types.generated';

import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import * as ClientTypes from '@main/graphql/client-scalars';
import { api } from '@main/graphql/shared-api';
export type UpdateVendorMutationVariables = Types.Exact<{
  id: Types.Scalars['uuid']['input'];
  vendor_input?: Types.InputMaybe<Types.Vendors_Set_Input>;
}>;

export type UpdateVendorMutation = {
  __typename?: 'mutation_root';
  update_vendors_by_pk?: { __typename?: 'vendors'; id: string };
};

export type ToggleTerminateVendorMutationVariables = Types.Exact<{
  vendorId: Types.Scalars['uuid']['input'];
}>;

export type ToggleTerminateVendorMutation = {
  __typename?: 'mutation_root';
  toggle_terminate_vendor?: { __typename?: 'ToggleTerminateVendorOutput'; id: string };
};

export const UpdateVendorDocument = `
    mutation UpdateVendor($id: uuid!, $vendor_input: vendors_set_input = {}) {
  update_vendors_by_pk(pk_columns: {id: $id}, _set: $vendor_input) {
    id
  }
}
    ` as string & TypedDocumentNode<UpdateVendorMutation, UpdateVendorMutationVariables>;
export const ToggleTerminateVendorDocument = `
    mutation ToggleTerminateVendor($vendorId: uuid!) {
  toggle_terminate_vendor(vendorId: $vendorId) {
    id
  }
}
    ` as string &
  TypedDocumentNode<ToggleTerminateVendorMutation, ToggleTerminateVendorMutationVariables>;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    UpdateVendor: build.mutation<UpdateVendorMutation, UpdateVendorMutationVariables>({
      query: (variables) => ({ document: UpdateVendorDocument, variables }),
    }),
    ToggleTerminateVendor: build.mutation<
      ToggleTerminateVendorMutation,
      ToggleTerminateVendorMutationVariables
    >({
      query: (variables) => ({ document: ToggleTerminateVendorDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useUpdateVendorMutation, useToggleTerminateVendorMutation } = injectedRtkApi;
