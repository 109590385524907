import { registerNotificationHandler } from '../notifications/registry';
import {
  MentionedInTaskCommentNotificationHandler,
  TaskAssignedNotificationHandler,
  TasksStatusUpdatedNotificationHandler,
} from './notifications';

registerNotificationHandler(TaskAssignedNotificationHandler);
registerNotificationHandler(MentionedInTaskCommentNotificationHandler);
registerNotificationHandler(TasksStatusUpdatedNotificationHandler);
