import {
  Policy_Approval_Statuses_Enum,
  Policy_Version_Statuses_Enum,
} from '@main/graphql/types.generated';
import { PermissionMap } from '@main/permissions';
import { AvatarOption } from '@main/ui';
import { createSelector } from '@reduxjs/toolkit';

import { AppRootState } from '../../store';
import {
  getCurrentUserId,
  getCurrentUserSelectedOrgId,
  getCurrentUserSelectedOrgRole,
} from '../user/slice';
import { getFormattedPolicyVersionId } from './constants';
import { api as policyApi } from './manage-policies.generated';

export type ApproverOption = AvatarOption & {
  value?: string;
};

const getPolicyApiData = createSelector(
  (state: AppRootState, policyId: string) => ({ state, policyId }),
  ({ state, policyId }) => {
    return policyApi.endpoints.GetPolicy.select({
      id: policyId,
    })(state).data?.policies_by_pk;
  },
);

export const getPolicyApprovers = createSelector(getPolicyApiData, (data) => {
  const approvers = data?.policy_approvers.map<ApproverOption>((approver) => ({
    id: approver.user.id,
    value: approver.id,
    displayName: approver.user.displayName,
  }));

  return approvers;
});

export const getMappedPolicy = createSelector(getPolicyApiData, (policy) => {
  const formattedVersions =
    policy?.policy_versions.map((version) => ({
      ...version,
      formattedVersionId: getFormattedPolicyVersionId(version.version_id),
    })) ?? [];

  return {
    ...policy,
    policy_versions: formattedVersions,
    approvals:
      formattedVersions.flatMap((version) =>
        version.policy_approvals.map((approval) => ({
          ...approval,
          version,
        })),
      ) ?? [],
    acknowledgements:
      formattedVersions.flatMap((version) =>
        version.policy_acknowledgements.map((acknowledgement) => ({
          ...acknowledgement,
          version,
        })),
      ) ?? [],
  };
});

export const getPolicyApprovalUsers = createSelector(
  [getMappedPolicy, (_, __, approvalId: string) => approvalId],
  (policy, approvalId) => {
    const approval = policy.approvals.find((approval) => approval.id === approvalId);
    const approvalUsers =
      approval?.policy_approval_users.map((user) => ({
        ...user,
        approvalStatus: approval.status,
      })) ?? [];

    return approvalUsers;
  },
);

const getOrgPoliciesApiData = createSelector(
  [(state: AppRootState) => state, getCurrentUserSelectedOrgId],
  (state, orgId) => {
    return (
      policyApi.endpoints.GetPolicies.select({
        orgId,
      })(state).data?.policies || []
    );
  },
);

const currentPolicyVersionOrder = [
  Policy_Version_Statuses_Enum.Approved,
  Policy_Version_Statuses_Enum.AwaitingApproval,
  Policy_Version_Statuses_Enum.Draft,
  Policy_Version_Statuses_Enum.Retired,
];

export const getCurrentPolicyVersion = <
  T extends { id: string; status: Policy_Version_Statuses_Enum; created_at: string },
>({
  versions = [],
  preferredVersionId,
  permissionMap,
}: {
  versions?: T[];
  preferredVersionId?: string;
  permissionMap: PermissionMap;
}) => {
  if (preferredVersionId) {
    return versions?.find((version) => version.id === preferredVersionId);
  }

  if (permissionMap.write_policies) {
    return versions[0];
  }

  for (const status of currentPolicyVersionOrder) {
    const version = versions?.find((version) => version.status === status);

    if (version) {
      return version;
    }
  }

  return;
};

export const getCurrentPolicyApproval = <T extends { status: Policy_Approval_Statuses_Enum }>(
  approvals?: T[],
) => approvals?.filter((approval) => approval.status !== Policy_Approval_Statuses_Enum.Retired)[0];

export const getApprovalRatio = <T extends { approved_at?: string }>(approvers?: T[]) => {
  const approvedCount = approvers?.filter((approver) => approver.approved_at).length;
  const totalCount = approvers?.length;

  return totalCount ? `${approvedCount}/${totalCount}` : undefined;
};

export const getAcknowledgementRatio = <T extends { acknowledged_at?: string }>(
  acknowledgers?: T[],
) => {
  const acknowledgedCount = acknowledgers?.filter(
    (acknowledger) => acknowledger.acknowledged_at,
  ).length;
  const totalCount = acknowledgers?.length;

  return totalCount ? `${acknowledgedCount}/${totalCount}` : undefined;
};

export const getMappedOrgPolicies = createSelector(
  [getOrgPoliciesApiData, getCurrentUserSelectedOrgRole],
  (policies, { permissionMap }) => {
    return policies.map((policy) => {
      const currentPolicyVersion = getCurrentPolicyVersion({
        versions: policy.policy_versions,
        permissionMap,
      });

      const currentPolicyApproval = getCurrentPolicyApproval(
        currentPolicyVersion?.policy_approvals,
      );

      const currentPolicyApprovalRatio = currentPolicyApproval?.total_users.aggregate?.count
        ? `${currentPolicyApproval?.approved_users.aggregate?.count}/${currentPolicyApproval?.total_users.aggregate.count}`
        : undefined;

      const currentPolicyVersionAcknowledgements = currentPolicyVersion?.policy_acknowledgements;
      const currentPolicyTotalAcknowledgementCount = currentPolicyVersionAcknowledgements?.reduce(
        (total, acknowledgement) => ({
          acknowledged_users:
            total.acknowledged_users + (acknowledgement.acknowledged_users.aggregate?.count || 0),
          total_users: total.total_users + (acknowledgement.total_users.aggregate?.count || 0),
        }),
        { acknowledged_users: 0, total_users: 0 },
      );
      const currentPolicyAcknowledgementRatio = currentPolicyTotalAcknowledgementCount?.total_users
        ? `${currentPolicyTotalAcknowledgementCount.acknowledged_users}/${currentPolicyTotalAcknowledgementCount.total_users}`
        : undefined;

      return {
        ...policy,
        currentPolicyVersion,
        currentPolicyApproval,
        currentPolicyApprovalRatio,
        currentPolicyAcknowledgementRatio,
      };
    });
  },
);

export const getCanCurrentUserApprove = createSelector(
  [getPolicyApiData, getCurrentUserId, (_, __, versionId?: string) => versionId],
  (data, currentUserId, versionId) => {
    const currentVersion = data?.policy_versions.find((version) => version.id === versionId);
    const currentApproval = getCurrentPolicyApproval(currentVersion?.policy_approvals);
    const currentApprover = currentApproval?.policy_approval_users.find(
      (approver) => approver.user_id === currentUserId,
    );

    return {
      canUserApprove: !!currentApprover && !currentApprover.approved_at,
      currentApprover,
    };
  },
);

export const getCanCurrentUserAcknowledge = createSelector(
  [getPolicyApiData, getCurrentUserId, (_, __, versionId?: string) => versionId],
  (data, currentUserId, versionId) => {
    const currentVersion = data?.policy_versions.find((version) => version.id === versionId);
    const acknowledgementIds = currentVersion?.policy_acknowledgements
      .filter((acknowledgement) => {
        return acknowledgement.policy_acknowledgement_users.find(
          (acknowledger) => acknowledger.user_id === currentUserId && !acknowledger.acknowledged_at,
        );
      })
      .map((acknowledgement) => acknowledgement.id);

    return {
      canUserAcknowledge:
        !!acknowledgementIds?.length &&
        currentVersion?.status === Policy_Version_Statuses_Enum.Approved,
      acknowledgementIds: acknowledgementIds ?? [],
    };
  },
);

const exportablePolicyVersions = [
  Policy_Version_Statuses_Enum.Approved,
  Policy_Version_Statuses_Enum.AwaitingApproval,
];

export const getCanCurrentUserExportPolicy = createSelector(
  [getPolicyApiData, getCurrentUserSelectedOrgRole],
  (data, { permissionMap }) => {
    if (!permissionMap.read_policies) {
      return false;
    }
    return data?.policy_versions.some((version) =>
      exportablePolicyVersions.includes(version.status),
    );
  },
);
