import { LinkIcon } from '@heroicons/react/24/outline';
import { useGetEvidenceByIdQuery } from '@main/graphql/queries/GetEvidenceById.generated';
import { DrawerProperty, successToast } from '@main/ui';
import { ReferenceCard } from '@main/ui/cards';
import { UnlinkIcon } from '@main/ui/icons';
import { useTranslation } from 'react-i18next';

import { useDeleteEvidencePolicyByIdMutation } from './evidence-references.generated';
import { useUpdateEvidenceOnDrawer } from './use-update-evidence-handler';

export const EvidenceReferences = ({ evidenceId }: { evidenceId: string }) => {
  const { t } = useTranslation();

  const { data } = useGetEvidenceByIdQuery({ evidence_id: evidenceId });
  const evidencePolicy = data?.evidences_by_pk?.evidence_policy;

  const [updateEvidenceHandler] = useUpdateEvidenceOnDrawer({ evidenceId });
  const [deleteEvidencePolicyById] = useDeleteEvidencePolicyByIdMutation();

  if (!evidencePolicy) {
    return null;
  }

  const onEvidencePolicyUnlink = async () => {
    const result = await updateEvidenceHandler(
      deleteEvidencePolicyById({
        evidencePolicyId: evidencePolicy.id,
      }),
    );
    if (result) {
      successToast(t('successMessages.unlinkSucceeded', { entity: t('entities.policy') }));
    }
  };

  return (
    <DrawerProperty>
      <DrawerProperty.Label icon={LinkIcon}>{t('evidences.references')}</DrawerProperty.Label>
      <DrawerProperty.Content px={2}>
        <ReferenceCard
          name={evidencePolicy.policy.name}
          owner={evidencePolicy.policy.user?.displayName}
          tags={[
            {
              label: t('entities.policy'),
              colorSchema: 'purple',
            },
          ]}
          actions={[
            {
              icon: UnlinkIcon,
              label: t('buttons.unlink'),
              onClick: onEvidencePolicyUnlink,
            },
          ]}
          navigate={{
            drawerEntity: 'policy',
            drawerEntityId: evidencePolicy.policy.id,
          }}
        />
      </DrawerProperty.Content>
    </DrawerProperty>
  );
};
