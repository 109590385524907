import { Avatar, Box, Flex, Icon, Text, useColorModeValue } from '@chakra-ui/react';
import {
  ArchiveBoxIcon,
  ArchiveBoxXMarkIcon,
  BellAlertIcon,
  EnvelopeIcon,
  EnvelopeOpenIcon,
} from '@heroicons/react/24/outline';
import { FloatingActionButtons, FloatingButtonsContainer, getHashedAvatarColor } from '@main/ui';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../hooks/redux-toolkit-hooks';
import { getAllMembersOfOrgsCurrentUserJoined } from '../user/slice';
import { useToggleReadState } from './drawer-actions';
import { NotificationItem as NotificationType } from './notification-handler';
import { useNotificationHandler } from './use-notification-handler';

export const NotificationItem = ({
  notification,
  onArchive,
  onMarkAsRead,
  onSelect,
}: {
  notification: NotificationType;
  onArchive: () => void;
  onMarkAsRead: () => void;
  onSelect: () => void;
}) => {
  const { t } = useTranslation();
  const users = useAppSelector(getAllMembersOfOrgsCurrentUserJoined);
  const toggleReadState = useToggleReadState();

  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const avatarBgColor = useColorModeValue('blackAlpha.50', 'blackAlpha.300');
  const avatarIconColor = useColorModeValue('gray.500', 'gray.300');
  const unreadIndicatorColor = useColorModeValue('gray.50', 'gray.700');
  const bottomBorderColor = useColorModeValue('gray.200', 'gray.500');
  const dateTextColor = useColorModeValue('gray.400', 'gray.500');

  const assignorId =
    'assignorId' in notification.content.params
      ? notification.content.params.assignorId
      : undefined;

  const assignor = users[assignorId ?? '']?.displayName;

  const handler = useNotificationHandler(notification);

  if (!handler) {
    return null;
  }

  const NotificationContent = handler.getContentComponent();

  return (
    <FloatingButtonsContainer
      as={Flex}
      position="relative"
      borderBottom="1px"
      borderBottomColor={bottomBorderColor}
      px={6}
      py={5}
      bgColor={!notification.read_at ? bgColor : 'transparent'}
      onClick={() => {
        handler.redirect(notification);
        toggleReadState(notification.id, new Date().toISOString());
        onSelect();
      }}
      cursor="pointer"
    >
      <Box position="relative">
        {assignor ? (
          <Avatar name={assignor} size="sm" mr={3} {...getHashedAvatarColor(assignor)} />
        ) : (
          <Avatar
            icon={<Icon as={BellAlertIcon} w={4} h={4} color={avatarIconColor} />}
            size="sm"
            mr={3}
            bg={avatarBgColor}
          />
        )}

        {!notification.read_at && (
          <Box
            position="absolute"
            bgColor="blue.400"
            rounded="full"
            w={3}
            h={3}
            top={0}
            left={-0.5}
            border="2px"
            borderColor={unreadIndicatorColor}
          />
        )}
      </Box>
      <Box>
        <NotificationContent notification={notification} handler={handler} />
        <Text fontSize="xs" textColor={dateTextColor}>
          {dayjs(notification.created_at).fromNow(true)} {t('notification.createdTime')}
        </Text>
      </Box>
      <FloatingActionButtons
        position="absolute"
        top={2}
        right={2}
        buttons={[
          {
            'aria-label': 'Mark as read',
            icon: notification.read_at ? <EnvelopeIcon /> : <EnvelopeOpenIcon />,
            tooltip: notification.read_at
              ? t('notification.actions.markAsUnread')
              : t('notification.actions.markAsRead'),
            onClick: onMarkAsRead,
          },
          {
            'aria-label': 'Mark as archive',
            icon: notification.archived_at ? <ArchiveBoxXMarkIcon /> : <ArchiveBoxIcon />,
            tooltip: notification.archived_at
              ? t('notification.actions.unarchive')
              : t('notification.actions.archive'),
            onClick: onArchive,
          },
        ]}
      />
    </FloatingButtonsContainer>
  );
};
