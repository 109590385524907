import { Route } from '@tanstack/react-router';

import { DocumentsPage } from '../../../../features/settings/organization/trust/documents-page';
import { organizationTrustSettingsLayoutRoute } from './layout';

export const organizationDocumentsSettingsRoute = new Route({
  getParentRoute: () => organizationTrustSettingsLayoutRoute,
  path: '/documents',
  component: DocumentsPage,
});
